import { TemplatedReportsTemplateRoutePath } from "#src/routes/reports/templated-reports/template";
import { RoutePath } from "#src/utils/route";

export const TemplatedReportsTemplateDetailRoutePath =
  TemplatedReportsTemplateRoutePath.concat(
    new RoutePath<"templateName">({
      path: "/:templateName/",
      title: "Template Details",
    })
  );
