import { TemplatedReportsListRoutePath } from "#src/routes/reports/templated-reports";
import { RoutePath } from "#src/utils/route";

export const TemplatedReportsCategoriesListRoutePath =
  TemplatedReportsListRoutePath.concat(
    new RoutePath({
      path: "/categories",
      title: "Categories",
      isPresentational: true,
    })
  );
