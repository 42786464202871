import { getStatusType } from "#common/Table/rendererHelper";
import {
  LegacyBreadcrumbType,
  getBreadcrumbsObject,
} from "#routers/breadcrumbsHelper";
import { useNavigate, useSearchParams } from "#routers/hooks";
import { linkToFormSubmissionDetail } from "#routers/links";
import { BulkDeleteFormSubmissionDialog } from "#src/batteries-included-components/Dialogs/BulkDeleteFormSubmissionsDialog";
import {
  FormSubmissionsTableFilterArea,
  FormSubmissionsTableFilterAreaDrawerContent,
  FormSubmissionsViewFilterArea,
} from "#src/batteries-included-components/FilterAreas/FormsFilterAreas";
import { DraftFormSubmissionsList } from "#src/batteries-included-components/Layouts/Form/Submission/List/DraftFormSubmissionsList";
import { FormSubmissionsFilterType } from "#src/batteries-included-components/Panels/FilterPanels/FormSubmissionsFilterPanel";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useExportFormSubmissionAsPDF } from "#src/components/Forms/exportFormSubmission";
import { useTableSortingAndPagination } from "#src/components/Redux/reducers/tableStateReducer";
import { useGetManyUsers } from "#src/components/hooks/adapters/useUsers";
import { useHasPermission } from "#src/contexts/AuthenticatedContext.helpers";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { linkToFormCategoryDetails } from "#src/routes/forms/categories/[categoryId]";
import { linkToFormTemplateDetail } from "#src/routes/forms/categories/[categoryId]/templates/[formTemplateId]";
import { linkToUpdateFormSubmission } from "#src/routes/forms/categories/[categoryId]/templates/[formTemplateId]/update-form-submission";
import {
  Button,
  DataTable,
  DataTablePanel,
  HeaderType,
  Page,
  Pill,
  Tab,
} from "@validereinc/common-components";
import {
  FormSubmissionAdapter,
  FormSubmissionStatus,
  FormSubmissionType,
  SortDirection,
  UserType,
} from "@validereinc/domain";
import { toFlattenedObject, toStartCaseString } from "@validereinc/utilities";
import * as PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import {
  useBulkDeleteFormSubmissions,
  useListFormSubmissions,
} from "../hooks/adapters/useFormSubmissions";

// TODO: refactor this and the shared component under components/Common/Forms/FormTable into one component
const FormSubmissionList = ({
  breadcrumbs,
}: {
  breadcrumbs: LegacyBreadcrumbType[];
}) => {
  const [canDeleteFormSubmissions] = useHasPermission(
    "form_submissions:delete"
  );
  const [canEditFormSubmissions] = useHasPermission("form_submissions:edit");

  const navigate = useNavigate();

  const {
    tableConfigStorageKey,
    filterConfigStorageKey,
    viewConfigStorageKey,
  } = useStorageKey("forms-submissions");
  const [searchParams, setSearchParams] = useSearchParams<{ tab: string }>();

  const [viewFilters] = useSessionStickyState<FormSubmissionsFilterType>(
    {},
    viewConfigStorageKey
  );
  const [tableFilters] = useSessionStickyState<FormSubmissionsFilterType>(
    {},
    filterConfigStorageKey
  );
  const filters = useMemo(
    () => ({ ...tableFilters, ...viewFilters }),
    [tableFilters, viewFilters]
  );
  const [tableState, updateTableState] = useTableSortingAndPagination(
    {
      sortBy: "created_at",
      sortDirection: SortDirection.DESCENDING,
    },
    filters
  );

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const {
    selectedFormSubmissions,
    setSelectedFormSubmissions,
    isDeleting,
    handleBulkDelete,
  } = useBulkDeleteFormSubmissions();

  const { created_at, status, ...restFilters } = filters;
  const queryPayload: Parameters<typeof FormSubmissionAdapter.getList>[0] = {
    page: tableState.page,
    pageSize: tableState.pageSize,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: {
      created_at,
      ...toFlattenedObject({
        status: !status
          ? Object.values(FormSubmissionStatus).filter(
              (s) => s !== FormSubmissionStatus.DRAFT
            )
          : status,
        "form_schema.status": "active",
        ...restFilters,
      }),
    },
    meta: { answers: true },
  };
  const { data: submissionsResponse, isLoading: isSubmissionsLoading } =
    useListFormSubmissions(queryPayload);
  const createdByUsersQueries = useGetManyUsers(
    Array.from(
      new Set(submissionsResponse?.data.map(({ created_by }) => created_by))
    )
  );
  const usersMap = useMemo(
    () =>
      createdByUsersQueries?.reduce<Record<string, UserType>>((map, query) => {
        if (!query.data) {
          return map;
        }

        map[query.data.id] = query.data;
        return map;
      }, {}) ?? {},
    [createdByUsersQueries]
  );
  const exportPDFMutation = useExportFormSubmissionAsPDF({
    includeEmptyAnswers: false,
    metaUserDataMap: usersMap,
  });

  const actionRowWhenRowsSelected = canDeleteFormSubmissions
    ? [
        <Button
          key="bulk-delete"
          variant="error-outline"
          onClick={() => setShowDeleteDialog(true)}
          disabled={!Object.keys(selectedFormSubmissions).length}
          isLoading={isDeleting}
        >
          Delete
        </Button>,
      ]
    : [];

  const headers: Array<HeaderType<FormSubmissionType>> = [
    {
      label: "Submission Name",
      key: "id",
      renderComponent: ({ item }) => (
        <RoutingLink to={linkToFormSubmissionDetail(item?.id)}>
          {`${item?.form_schema?.name} - ${item?.id?.slice(0, 7)}`}
        </RoutingLink>
      ),
    },
    {
      label: "Status",
      key: "status",
      isSortable: true,
      renderComponent: ({ item }) => (
        <Pill variant={getStatusType(item.status, "form").type}>
          {toStartCaseString(item.status)}
        </Pill>
      ),
    },
    {
      label: "Form Template",
      key: "form_schema_id",
      renderComponent: ({ item }: { item: FormSubmissionType }) => {
        return (
          <RoutingLink
            to={linkToFormTemplateDetail(
              item.form_schema?.form_category_id,
              item.form_schema_id
            )}
          >
            {item?.form_schema?.name}
          </RoutingLink>
        );
      },
    },
    {
      label: "Form Category",
      key: "form_schema_version",
      renderComponent: ({ item }) => {
        return (
          <RoutingLink
            to={linkToFormCategoryDetails(item?.form_schema?.form_category_id)}
          >
            {item?.form_schema?.form_category?.name}
          </RoutingLink>
        );
      },
    },
    {
      label: "Created At",
      key: "created_at",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={item.created_at}
          withTime
        />
      ),
    },
    {
      label: "Last Saved At",
      key: "updated_at",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={item.updated_at}
          withTime
        />
      ),
    },
    {
      label: "Submitted By",
      key: "created_by",
      isSortable: true,
      renderComponent: ({ item }) => usersMap[item.created_by]?.name ?? "-",
    },
  ];

  return (
    <Page
      title="Form Submissions"
      onActiveTabKeyChange={(newKey: string) =>
        setSearchParams({ tab: newKey })
      }
      activeTabKey={searchParams?.tab ?? "submissions"}
      breadcrumbs={getBreadcrumbsObject(breadcrumbs)}
    >
      <Tab
        tabKey="submissions"
        title="Submissions"
      >
        <div style={{ marginBottom: 16 }}>
          <FormSubmissionsViewFilterArea
            viewConfigStorageKey={viewConfigStorageKey}
          />
        </div>
        <DataTablePanel
          storageKey={tableConfigStorageKey}
          filterComponent={
            <FormSubmissionsTableFilterArea
              filterConfigStorageKey={filterConfigStorageKey}
              filterDrawerContentSlot={
                <FormSubmissionsTableFilterAreaDrawerContent
                  hasStatusFilter
                  hasCategoryFilter
                  hasTemplateFilter
                />
              }
            />
          }
          actionRowWhenRowsSelected={actionRowWhenRowsSelected}
          panelProps={{
            title: "Submissions",
          }}
          dataTableProps={{
            variant: "simplicity-first",
            items: submissionsResponse?.data ?? [],
            pagination: {
              page: tableState.page,
              pageSize: tableState.pageSize,
              total: submissionsResponse?.total_entries,
              pageSizeText: "rows per page",
            },
            sorting: {
              sortBy: tableState.sortBy,
              sortDirection: tableState.sortDirection,
            },
            headers,
            isLoading:
              isSubmissionsLoading ||
              createdByUsersQueries.some((q) => q.isLoading),
            selected: selectedFormSubmissions,
            onPaginationChange: updateTableState,
            onSortChange: updateTableState,
            getItemId: canDeleteFormSubmissions ? (item) => item.id : undefined,
            onSelectionChange: setSelectedFormSubmissions,
            emptyStateProps: {
              title: "There are no submissions to display",
            },
            getItemActions: ({ item }: { item: FormSubmissionType }) => [
              ...(item?.status !== FormSubmissionStatus.DRAFT &&
              item?.form_schema?.form_submission_editable &&
              canEditFormSubmissions
                ? [
                    {
                      label: "Edit",
                      buttonProps: {
                        icon: "pencil-simple",
                        onClick: () =>
                          navigate({
                            pathname: linkToUpdateFormSubmission(
                              item?.form_schema?.form_category_id,
                              item?.form_schema?.id,
                              item?.id
                            ),
                          }),
                      },
                    },
                  ]
                : []),
              {
                label: "Export as PDF",
                buttonProps: {
                  icon: "share",
                  isLoading: exportPDFMutation.isLoading,
                  onClick: () => {
                    exportPDFMutation.mutate(item);
                  },
                },
              },
            ],
          }}
        />
        <BulkDeleteFormSubmissionDialog
          isOpen={showDeleteDialog}
          onClose={() => {
            setShowDeleteDialog(false);
          }}
          handleBulkDelete={handleBulkDelete}
          formSubmissionsCount={Object.keys(selectedFormSubmissions).length}
        />
      </Tab>

      <Tab
        tabKey="drafts"
        title="Drafts"
      >
        <DraftFormSubmissionsList />
      </Tab>
    </Page>
  );
};

FormSubmissionList.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
};

export default FormSubmissionList;
