import moment from "moment";
import React, { useMemo } from "react";

import {
  DataTable,
  DateRangeSelector,
  HeaderType,
  MultiDropdownInputWithSearch,
  Pill,
} from "@validereinc/common-components";

import Input from "#common/Input/Input";
import { useSearchParams } from "#routers/hooks";
import { GetAllObjectValue } from "#utils/objectFormatter";
import { EARLIEST_COC_ISSUED_AT } from "./ChainOfCustodyConstants";

const DEFAULT_DATE_RANGE = {
  from: EARLIEST_COC_ISSUED_AT.toDate(),
  to: moment().toDate(),
};

export default ({ chainOfCustodyList, sites, loading }) => {
  const [searchParams] = useSearchParams();

  /**
   * name - The unique identifier used to register it in the internal filters state
   * component - The element that's cloned and given value and onChange props to
   *             connect it to the internal filters state
   * value - Initial value given to the internal filter state, only works
   *                on first mount of the QuickFilter component
   */
  const filters = useMemo(() => {
    return [
      {
        name: "name",
        component: (
          <Input
            key="nameInput"
            icon="search"
            placeholder="Name"
          />
        ),
      },
      {
        name: "site",
        component: (
          <MultiDropdownInputWithSearch
            key="siteFilter"
            label="Sites"
            options={GetAllObjectValue(sites, "name")}
            isClearable
          />
        ),
      },
      {
        name: "type",
        component: (
          <MultiDropdownInputWithSearch
            key="typeFilter"
            label="Issued To"
            options={GetAllObjectValue(chainOfCustodyList, "type")}
            isClearable
          />
        ),
      },
      {
        name: "date",
        component: <DateRangeSelector key="dateRange" />,
        value:
          searchParams?.from && searchParams?.to
            ? {
                from: new Date(searchParams.from),
                to: new Date(searchParams.to),
              }
            : DEFAULT_DATE_RANGE,
      },
    ];
  }, [chainOfCustodyList, sites]);

  /**
   * item.value - What's assigned/added to the internal filter state
   */
  const quickFilters = useMemo(() => {
    return {
      loading,
      items: [
        { label: "All" },
        {
          label: "Issued",
          variant: "warning",
          count: chainOfCustodyList?.reduce(
            (total, { state }) => (state === "issued" ? total + 1 : total),
            0
          ),
          value: "issued",
        },
        {
          label: "Received",
          variant: "success",
          count: chainOfCustodyList?.reduce(
            (total, { state }) => (state === "received" ? total + 1 : total),
            0
          ),
          value: "received",
        },
      ],
    };
  }, [chainOfCustodyList, loading]);

  /**
   * The key should be unique, and should be the dot notation to find the string
   * representation of each item to use the default renderers.
   */
  const headers = useMemo(
    (): Array<HeaderType<any>> => [
      {
        label: "Name",
        key: "name",
        isFixed: true,
        isSortable: true,
      },
      {
        label: "Status",
        key: "state",
        isFixed: true,
        isSortable: true,
        renderComponent: ({ item }) => (
          <Pill variant={item.state === "received" ? "success" : "warning"}>
            {item.state}
          </Pill>
        ),
      },
      {
        label: "Issued By",
        key: "user.email",
        renderComponent: ({ value }) => (
          <span
            data-hj-suppress
            style={{ textTransform: "none" }}
          >
            {value}
          </span>
        ),
        isSortable: true,
      },
      {
        label: "Issued To",
        key: "type",
        isSortable: true,
      },
      { label: "Validere ID", key: "code", isSortable: true },
      {
        label: "Site",
        key: "site.name",
        isSortable: true,
      },
      {
        label: "Date Issued",
        key: "issued_at",
        isSortable: true,
        renderComponent: ({ item }) => (
          <DataTable.DataRow.DateCell
            value={item.issued_at}
            withTime
          />
        ),
      },
    ],
    []
  );

  return { filters, quickFilters, headers };
};
