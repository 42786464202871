const colours = require("./colours-vars.json");

module.exports = {
  colours,
  customProperties: {
    "--validere-colour": colours.validereColour,
    "--link-colour": colours.linkColour,
    "--navLink-colour": colours.navLinkColour,
    "--navButton-colour": colours.navButtonColour,
    "--sidebarText-colour": colours.sidebarTextColour,
    "--sidebarBackground-colour": colours.sidebarBackgroundColour,
    "--light-text-colour": colours.text["600"],
    "--statusCompletedColour": colours.status.success,
    "--statusPendingColour": colours.status.warning,
    "--statusErrorColour": colours.status.error,
    "--borderColour": colours.surface["200"],
    "--menuHighlightColour": colours.surface["100"],
    "--menuItemColour": colours.text["600"],
    "--disabledFontColour": colours.disabledFontColour,
    "--buttonFocusColour": colours.surface["200"],
    "--alarmColour": colours.status.warning,
    "--textColour": colours.text["600"],
    "--disabledRowColour": colours.surface["100"],

    "--colour-primary-50": colours.primary["50"],
    "--colour-primary-100": colours.primary["100"],
    "--colour-primary-200": colours.primary["200"],
    "--colour-primary-300": colours.primary["300"],
    "--colour-primary-400": colours.primary["400"],
    "--colour-primary-500": colours.primary["500"],
    "--colour-primary-600": colours.primary["600"],
    "--colour-primary-700": colours.primary["700"],
    "--colour-primary-800": colours.primary["800"],

    "--colour-teal-100": colours.teal["100"],
    "--colour-teal-200": colours.teal["200"],
    "--colour-teal-300": colours.teal["300"],
    "--colour-teal-400": colours.teal["400"],
    "--colour-teal-500": colours.teal["500"],
    "--colour-teal-600": colours.teal["600"],
    "--colour-teal-700": colours.teal["700"],
    "--colour-teal-800": colours.teal["800"],
    "--colour-teal-900": colours.teal["900"],

    "--colour-error-500": colours.error["500"],

    "--colour-info-700": colours.info["700"],

    "--colour-white": "#FFFFFF",

    "--colour-text-0": colours.text["000"],
    "--colour-text-200": colours.text["200"],
    "--colour-text-400": colours.text["400"],
    "--colour-text-600": colours.text["600"],
    "--colour-text-800": colours.text["800"],

    "--colour-success": colours.status.success,
    "--colour-success-dark": colours.status.successDark,
    "--colour-success-light": colours.status.successLight,
    "--colour-warning": colours.status.warning,
    "--colour-warning-dark": colours.status.warningDark,
    "--colour-warning-light": colours.status.warningLight,
    "--colour-error-dark": colours.status.errorDark,
    "--colour-error": colours.status.error,
    "--colour-error-light": colours.status.errorLight,
    "--colour-default-dark": colours.status.defaultDark,
    "--colour-default": colours.status.default,
    "--colour-default-light": colours.status.defaultLight,

    "--colour-surface-0": colours.surface["0"],
    "--colour-surface-50": colours.surface["50"],
    "--colour-surface-75": colours.surface["75"],
    "--colour-surface-100": colours.surface["100"],
    "--colour-surface-200": colours.surface["200"],
    "--colour-surface-300": colours.surface["300"],
    "--colour-surface-600": colours.surface["600"],
    "--colour-surface-700": colours.surface["700"],
    "--colour-surface-800": colours.surface["800"],
    "--colour-surface-900": colours.surface["900"],
  },
};
