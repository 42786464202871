import AlertService from "#services/AlertService";
import { AssertIsValidMeasurement } from "#utils/assert";
import { ValidateNumberInput } from "#utils/formValidator";
import {
  AlertMessage,
  Button,
  Form,
  FormButton,
  InputStack,
  Panel,
  SelectInput,
  TextInput,
  Title,
  useForm,
  useToast,
} from "@validereinc/common-components";
import isNumber from "lodash/isNumber";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { DEFAULT_ALERT_SPEC_INPUTS } from "./StreamAlertsFormContants";
import {
  formatFormDataForAlertSpecsSubmission,
  getHistoricalRange,
  getHistoricalRangeUnit,
  isLowerGreaterThanUpperLimit,
  isStdValuesExist,
  stdHistoricalRangeValidation,
} from "./StreamAlertsFormHelpers";

const AlertSpecPanel = ({
  measurementInfo,
  disabled,
  existingAlertSpecData,
  checkedStreams,
  refetchStreams,
  isFetchingAPI,
}) => {
  const measurementUnit = measurementInfo.unit;
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();
  const form = useForm({
    defaultValues: DEFAULT_ALERT_SPEC_INPUTS,
  });

  useEffect(() => {
    if (existingAlertSpecData) {
      form.reset({
        ...existingAlertSpecData,
        std_historical_range: getHistoricalRange(existingAlertSpecData),
        std_historical_range_unit: getHistoricalRangeUnit(
          existingAlertSpecData
        ),
      });
    } else {
      form.reset(DEFAULT_ALERT_SPEC_INPUTS);
    }
  }, [existingAlertSpecData, measurementInfo.name]);

  const handleSubmit = () => {
    setIsLoading(true);
    const data = formatFormDataForAlertSpecsSubmission(
      form.getValues(),
      checkedStreams,
      measurementInfo
    );
    AlertService.setAlertSpecs(data)
      .then(() => {
        toast.push({
          intent: "success",
          description: "Successfully created measurement alert.",
        });
        refetchStreams();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDelete = () => {
    setIsLoading(true);
    form.reset(DEFAULT_ALERT_SPEC_INPUTS);
    const data = formatFormDataForAlertSpecsSubmission(
      form.getValues(),
      checkedStreams,
      measurementInfo
    );
    AlertService.setAlertSpecs(data)
      .then(() => {
        toast.push({
          intent: "success",
          description: "Successfully deleted measurement alert.",
        });
        refetchStreams();
      })
      .finally(() => setIsLoading(false));
  };

  const disableForm = disabled || isLoading;
  const sharedProps = {
    isDisabled: disableForm,
  };

  return (
    <Form
      onSubmit={handleSubmit}
      {...form}
    >
      <Panel
        title="Measurement Alerts"
        loaded={!isFetchingAPI}
      >
        {disabled && (
          <AlertMessage type="warning">
            Ensure a measurement and at least one stream is selected above.
          </AlertMessage>
        )}

        <Title
          className="streamAlertsCreate__formSectionTitle"
          type="subheader"
        >
          Limit Alert
        </Title>

        <TextInput
          name="lower_limit"
          label="Lower Limit"
          type="number"
          unit={measurementUnit}
          validate={(value) => {
            const inputs = form.getValues();
            if (isNumber(value) && !AssertIsValidMeasurement(value, 0)) {
              return "Lower measurement limit must be greater than zero.";
            }
            if (isLowerGreaterThanUpperLimit(inputs)) {
              return "Measurement upper limit must be greater than lower limit.";
            }
          }}
          {...sharedProps}
        />

        <TextInput
          name="upper_limit"
          label="Upper Limit"
          type="number"
          unit={measurementUnit}
          validate={(value) => {
            const inputs = form.getValues();
            if (isNumber(value) && !AssertIsValidMeasurement(value, 0)) {
              return "Upper measurement limit must be greater than zero.";
            }
            if (isLowerGreaterThanUpperLimit(inputs)) {
              return "Measurement upper limit must be greater than lower limit.";
            }
          }}
          {...sharedProps}
        />

        <Title
          className="streamAlertsCreate__formSectionTitle"
          type="subheader"
        >
          Statistical Alert
        </Title>

        <TextInput
          name="std_sigma"
          label="Standard Deviations"
          type="number"
          validate={(value) => {
            const inputs = form.getValues();
            if (
              isStdValuesExist(inputs) &&
              !AssertIsValidMeasurement(value, 0)
            ) {
              return "Standard deviations must be greater than zero.";
            }
          }}
          {...sharedProps}
        />

        <InputStack>
          <TextInput
            name="std_historical_range"
            label="Historical Range"
            type="number"
            validate={(value) => {
              const inputs = form.getValues();
              return stdHistoricalRangeValidation(value, inputs);
            }}
            {...sharedProps}
          />

          <SelectInput
            name="std_historical_range_unit"
            label="Unit"
            options={["samples", "days"]}
            isClearable
            validate={(option) => {
              const inputs = form.getValues();
              if (isStdValuesExist(inputs) && !option) {
                return "Requires unit";
              }
            }}
            {...sharedProps}
          />
        </InputStack>

        <TextInput
          name="std_min_num_measurements"
          label="Min Samples"
          type="number"
          validate={(stdMinNumMeasurements) => {
            const inputs = form.getValues();
            if (isStdValuesExist(inputs)) {
              if (!AssertIsValidMeasurement(stdMinNumMeasurements, 3)) {
                return "Minimum number of historical samples must be at least 4.";
              }
              return ValidateNumberInput(
                "Min Samples",
                stdMinNumMeasurements,
                true,
                true
              );
            }
          }}
          {...sharedProps}
        />

        <Title
          className="streamAlertsCreate__formSectionTitle"
          type="subheader"
        >
          Balance Alert
        </Title>

        <TextInput
          name="roq_previous_total_vol_percentage_deviation"
          label="Balance Range"
          type="number"
          validate={(balanceAlert) => {
            if (
              isNumber(balanceAlert) &&
              !AssertIsValidMeasurement(balanceAlert, 0)
            ) {
              return "Balance must be greater than zero.";
            }
          }}
          {...sharedProps}
        />

        <div className="clearfix">
          <FormButton
            disabled={disableForm}
            variant="primary"
            className="pull-right"
            type="submit"
          >
            Save
          </FormButton>

          <Button
            disabled={disableForm}
            variant="error-outline"
            className="pull-right streamAlertsCreate__deleteButton"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </div>
      </Panel>
    </Form>
  );
};

AlertSpecPanel.propTypes = {
  disabled: PropTypes.bool.isRequired,
  existingAlertSpecData: PropTypes.object,
  checkedStreams: PropTypes.array,
  measurementInfo: PropTypes.object,
  refetchStreams: PropTypes.func.isRequired,
  isFetchingAPI: PropTypes.bool.isRequired,
};

export default AlertSpecPanel;
