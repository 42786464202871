import { useEffect, useState } from "react";

type OsanoConsentManagerType = {
  showDrawer: () => void;
  mode: "permissive" | "production" | "discovery";
};

export const useOsano = () => {
  const [osano, setOsano] = useState<OsanoConsentManagerType>();

  useEffect(() => {
    const interval = setInterval(() => {
      const osano = (window as any)?.Osano?.cm;
      if (osano) {
        setOsano(osano);
        clearInterval(interval);
      }
    }, 100);
    return () => clearInterval(interval);
  }, []);

  const showDrawer = () => {
    if (osano) {
      osano.showDrawer();
    }
  };

  return {
    isReady: !!osano,
    isEnabled: ["permissive", "production"].includes(osano?.mode ?? ""),
    showDrawer,
  };
};
