import { clampYearMonth } from "@validereinc/utilities";
import { z } from "zod";
import { refineYearMonthDateString } from "./refinements/date";

export const TimeStateManagementDefaultInputValue = {
  min: "min",
  max: "max",
} as const;
export type TimeStateManagementDefaultInputValueType =
  (typeof TimeStateManagementDefaultInputValue)[keyof typeof TimeStateManagementDefaultInputValue];

export const TimeStateManagementInputValueSchema = z
  .string()
  .superRefine(refineYearMonthDateString)
  .transform(clampYearMonth)
  .describe("Month of the year representation formatted as YYYYMM e.g. 202501");

export const TimeStateManagementInputsSchema = z
  .object({
    start_date: z
      .union([
        TimeStateManagementInputValueSchema,
        z.nativeEnum(TimeStateManagementDefaultInputValue),
      ])
      .optional(),
    end_date: z
      .union([
        TimeStateManagementInputValueSchema,
        z.nativeEnum(TimeStateManagementDefaultInputValue),
      ])
      .optional(),
  })
  .describe("Inputs for state management on any resource in the Node API");
export type TimeStateManagementInputsType = z.infer<
  typeof TimeStateManagementInputsSchema
>;
