import {
  DEFAULT_QUERY_OPTIONS,
  UseQueryOptionsType,
} from "#hooks/adapters/adapterUtils";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@validereinc/common-components";
import { ReportV2Adapter } from "@validereinc/domain";
import { downloadLink } from "@validereinc/utilities";

export const REPORTS_QUERY_KEY = "reports";

export const useGetOneReport = (
  params: Parameters<typeof ReportV2Adapter.getOne>[0],
  options: UseQueryOptionsType = {}
) =>
  useQuery({
    queryKey: [REPORTS_QUERY_KEY, "getOne", params] as const,
    queryFn: ({ queryKey: [_, __, params] }) => ReportV2Adapter.getOne(params),
    enabled: !!params?.id,
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  });

export const useListReports = (
  params: Parameters<typeof ReportV2Adapter.getList>[0],
  options: UseQueryOptionsType = {}
) =>
  useQuery({
    queryKey: [REPORTS_QUERY_KEY, "getList", params] as const,
    queryFn: ({ queryKey: [_, __, params] }) => ReportV2Adapter.getList(params),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  });

export const useDownloadReport = () => {
  const { toast } = useToast();

  return useMutation({
    mutationFn: async ({ id }: { id: string }) => {
      const { data } = await ReportV2Adapter.getOne({ id });
      downloadLink(data?.s3_download_link, data?.name);
    },
    onError: (error) => {
      console.error(error);
      toast.push({
        intent: "error",
        description: "Unable to download report",
      });
    },
  });
};

export const useClearReportsCache = () => {
  const queryClient = useQueryClient();
  return {
    invalidate: () => {
      queryClient.invalidateQueries({
        queryKey: [REPORTS_QUERY_KEY],
      });
    },
  };
};
