import AttributeField from "#common/AttributeField/AttributeField";
import CustomAttributeField from "#components/Common/CustomAttributeField";
import { FacilityDropdownInput } from "#src/batteries-included-components/Dropdowns/FacilityDropdownInput";
import { useListCustomAttributes } from "#src/components/hooks/adapters/useCustomAttributes";
import { convertMapToOpts } from "#src/constants";
import useLocalization from "#src/hooks/useLocalization";
import {
  DropdownInput,
  Form,
  Panel,
  TextInput,
} from "@validereinc/common-components";
import {
  AssetType,
  DeviceStatus,
  DeviceTypeType,
  MeasurementType,
} from "@validereinc/domain";
import startCase from "lodash/startCase";
import React from "react";

export type CreateDeviceFormProps = {
  // TODO: update this when common-components types can be imported reliably
  form: any;
  formType: "create" | "add" | "edit";
  deviceTypes: DeviceTypeType[];
  deviceTypesLoadingState: string;
  measurementTypes: MeasurementType[];
  measurementTypesLoadingState: string;
  // TODO: type the form values
  handleSubmit: (formValues: any) => Promise<void>;
};

export const CreateDeviceForm = ({
  form,
  formType,
  deviceTypes,
  deviceTypesLoadingState,
  measurementTypes,
  measurementTypesLoadingState,
  handleSubmit,
}: CreateDeviceFormProps) => {
  const { localize } = useLocalization();
  const selectedDeviceType = form.watch("type");

  const isFormLoading =
    deviceTypesLoadingState !== "loaded" ||
    measurementTypesLoadingState !== "loaded";

  const sharedProps = {
    isDisabled: form.formState.isSubmitting || isFormLoading,
    showIcon: true,
  };

  const customAttributeQuery = useListCustomAttributes({
    filters: { entity_type: AssetType.DEVICE },
  });
  const customAttributes = customAttributeQuery.data?.data ?? [];

  return (
    <Panel>
      <Form
        {...form}
        onSubmit={handleSubmit}
      >
        <div style={{ width: "272px" }}>
          <DropdownInput
            {...sharedProps}
            name="type"
            label={`${localize("Device")} Type`}
            valueKey="id"
            labelKey="name"
            options={deviceTypes}
            isSortedAlphabetically
            isLoading={deviceTypesLoadingState !== "loaded"}
            isRequired
            isDisabled={formType === "edit" || sharedProps.isDisabled}
          />

          <TextInput
            {...sharedProps}
            name="name"
            label="Name"
            showIcon
            isRequired
          />

          <FacilityDropdownInput
            {...sharedProps}
            name="facility_id"
            isSearchable
            isFluid
            isMulti={false}
            isRequired
            isDisabled={formType === "edit" || formType === "add"}
          />

          <DropdownInput
            {...sharedProps}
            name="status"
            label="Status"
            options={convertMapToOpts(DeviceStatus, ([_, value]) =>
              startCase(value)
            )}
            labelKey="label"
            valueKey="value"
            isRequired
            isFluid
            isDisabled={sharedProps.isDisabled}
          />

          <DropdownInput
            {...sharedProps}
            name="measurementTypes"
            label="Valid Measurement Types"
            labelKey="name"
            valueKey="id"
            options={measurementTypes}
            isSortedAlphabetically
            isLoading={measurementTypesLoadingState !== "loaded"}
            isMulti
            isRequired
          />

          {selectedDeviceType?.id
            ? selectedDeviceType.attributes.map((attribute) => (
                <AttributeField
                  key={attribute.id}
                  attribute={attribute}
                  isDisabled={sharedProps.isDisabled}
                  // Emission Monitor descriptions don't give new info
                  shouldShowDescription={false}
                />
              ))
            : null}

          {customAttributes?.map(({ field_name, ...restAttribute }) => (
            <CustomAttributeField
              key={`custom_attributes.${field_name}`}
              attribute={{ field_name, ...restAttribute }}
              isDisabled={sharedProps.isDisabled}
              subtype={selectedDeviceType ? selectedDeviceType.id : null}
            />
          ))}
        </div>
      </Form>
    </Panel>
  );
};
