import { useListDevices } from "#hooks/adapters/utils/useDevices";
import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import { useNavigate, useParams } from "#routers/hooks";
import { linkToAddDevice, linkToDeviceDetail } from "#routers/links";
import { FacilityDetailPageParams } from "#routes/organization/facilities/[facilityId]";
import { DevicesTableFilterArea } from "#src/batteries-included-components/FilterAreas/AssetsFilterAreas";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink/RoutingLink";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import { useStorageKey } from "#src/hooks/useStorageKey";
import {
  Button,
  DataTable,
  DataTablePanel,
  HeaderType,
  Pill,
} from "@validereinc/common-components";
import { DeviceType, SortDirection } from "@validereinc/domain";
import React from "react";

export const DevicesPanel = () => {
  const { facilityId } = useParams<FacilityDetailPageParams>();
  const { tableConfigStorageKey, filterConfigStorageKey } =
    useStorageKey("facilities-devices");
  const navigate = useNavigate();

  const [tableFilters] = useSessionStickyState({}, filterConfigStorageKey);
  const filters = { ...tableFilters, "facility.id": facilityId };

  const [tableState, updateTableState] = useTableSortingAndPagination(
    {
      sortBy: "name",
      sortDirection: SortDirection.ASCENDING,
      pageSize: 25,
    },
    filters
  );

  const { data, isLoading } = useListDevices({
    page: tableState.page,
    pageSize: tableState.pageSize,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters,
  });

  function handleOnAddDeviceClick() {
    navigate({
      pathname: linkToAddDevice(facilityId),
    });
  }

  const headers: Array<HeaderType<DeviceType>> = [
    {
      key: "name",
      label: "Name",
      isSortable: true,
      renderComponent: ({ item }) => (
        <RoutingLink to={linkToDeviceDetail(item.id)}>{item.name}</RoutingLink>
      ),
    },
    {
      key: "type.name",
      label: "Type",
    },
    {
      label: "Status",
      key: "status",
      isSortable: true,
      renderComponent: ({ item }) => (
        <Pill variant={item.status === "active" ? "success" : "default"}>
          {item.status}
        </Pill>
      ),
    },
    {
      label: "Created Date",
      key: "created_at",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={item.created_at}
          withTime
        />
      ),
    },
  ];

  const actionRow = (
    <Button
      variant="outline"
      onClick={handleOnAddDeviceClick}
    >
      Add Device
    </Button>
  );

  const items = data?.data ?? [];

  return (
    <DataTablePanel
      storageKey={tableConfigStorageKey}
      panelProps={{
        title: "Devices",
        actionRow,
      }}
      dataTableProps={{
        variant: "simplicity-first",
        items,
        headers,
        isLoading,
        sorting: {
          sortBy: tableState.sortBy,
          sortDirection: tableState.sortDirection,
        },
        pagination: {
          page: tableState.page,
          pageSize: tableState.pageSize,
          total: data?.total_entries,
        },
        onSortChange: updateTableState,
        onPaginationChange: updateTableState,
      }}
      filterComponent={
        <DevicesTableFilterArea
          filterConfigStorageKey={filterConfigStorageKey}
        />
      }
    />
  );
};
