import { FilterArea, useFilterAreaContext } from "#src/components/FilterArea";
import {
  PillToggleGroup,
  PillToggleVariants,
  TextInput,
  type StorageKeys,
} from "@validereinc/common-components";
import { UserStatusOptions } from "@validereinc/domain";
import React from "react";

const UsersStatusPillToggles = ({
  onChange,
}: {
  onChange: (val: string[]) => void;
}) => {
  const { storedFilters } = useFilterAreaContext<{
    status: string[];
  }>();
  const pillToggles = [
    {
      name: "All",
      label: "All",
      value: "",
      isSelected:
        !storedFilters.status?.length || storedFilters.status?.includes(""),
      shouldSelectAll: true,
    },
    ...UserStatusOptions.map((option) => ({
      name: option.name,
      label: option.name,
      value: option.status,
      variant:
        option.status === "active"
          ? PillToggleVariants.GOOD
          : PillToggleVariants.NEUTRAL,
      isSelected: storedFilters.status?.includes(option.status) ?? false,
    })),
  ];

  return (
    <PillToggleGroup
      name="status"
      pills={pillToggles}
      onChange={(val) => onChange(val)}
    />
  );
};

export const UsersTableTitleDecorationFilterArea = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  return (
    <FilterArea.Root
      storageKey={filterConfigStorageKey}
      defaultValues={{
        status: ["active"],
      }}
      applyDefaultValues
    >
      <FilterArea.Container aria-label="Filters for Users">
        <FilterArea.Content>
          {({ handleOnChange }) => (
            <div style={{ marginRight: 8, display: "flex", gap: 8 }}>
              <UsersStatusPillToggles
                onChange={(val) => handleOnChange(val, "status")}
              />
            </div>
          )}
        </FilterArea.Content>
      </FilterArea.Container>
    </FilterArea.Root>
  );
};

export const UsersTableFilterArea = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  return (
    <FilterArea.Root
      storageKey={filterConfigStorageKey}
      defaultValues={{}}
    >
      <FilterArea.Container aria-label="Filters for Flow Records">
        <FilterArea.Content>
          {({ handleOnChange }) => (
            <TextInput
              name="name"
              key="name"
              placeholder="Search Users"
              type="search"
              isInline
              onChange={(val) => handleOnChange(val, "name")}
            />
          )}
        </FilterArea.Content>
      </FilterArea.Container>
    </FilterArea.Root>
  );
};
