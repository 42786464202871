import {
  TriggerWorkflowType,
  WorkflowFormSubmissionType,
  WorkflowType,
  type FormSubmissionFilterType,
} from "../schemas";
import {
  FilterObjectType,
  GetListRequestType,
  GetListResponseType,
  ResourceServiceType,
  getFilterObject,
} from "../util";
import { restAPI } from "./api";

export type WorkflowsGetListFilterType = FilterObjectType<
  {
    "workflow_category.id": string;
    "workflow_template.id": string;
    "workflow_template.description": string;
    "facility.id": string;
    "facility.status": string;
    "facility.custom_attributes": Record<string, string>;
  } & Pick<
    WorkflowType,
    | "id"
    | "workflow_template_id"
    | "status"
    | "due_date"
    | "created_by"
    | "created_at"
    | "asset_id"
    | "time_period"
    | "duration"
  >
>;

export const WorkflowAdapter: WorkflowAdapterType = {
  /**
   * Get the details of a single workflow
   * @see {@link https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/workflows/get_workflow}
   */
  getOne: async ({ id }) => {
    const resp = await restAPI.nodeAPI.GET<WorkflowType>({
      endpoint: `/workflows/${id}`,
    });

    return {
      data: resp,
    };
  },
  /**
   * Get all workflows
   * @see {@link https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/workflows/search_workflows}
   */
  getList: async ({ page, pageSize, sortBy, sortDirection, filters }) =>
    restAPI.nodeAPI.POST<GetListResponseType<WorkflowType>>({
      endpoint: "/workflows/search",
      body: {
        page,
        page_size: pageSize,
        sort_by: sortBy,
        sort_direction: sortDirection,
        filter: getFilterObject(filters),
      },
    }),
  getMany: () =>
    Promise.reject({
      success: false,
      error: "Method not implemented",
    }),
  createOne: () =>
    Promise.reject({
      success: false,
      error: "Method not implemented",
    }),
  createMany: () =>
    Promise.reject({
      success: false,
      error: "Method not implemented",
    }),
  /**
   * Trigger a workflow
   * @see {@link https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/workflows/create_workflow}
   */
  trigger: ({
    workflow_template_id,
    asset_id,
    time_period,
    duration,
    variables,
  }) =>
    restAPI.nodeAPI.POST({
      endpoint: "/workflows",
      body: {
        workflow_template_id,
        asset_id,
        ...(time_period && duration
          ? { due_date: { time_period, duration } }
          : {}),
        ...(variables ? { variables } : {}),
      },
    }),
  /**
   * Delete a single workflow
   * @see {@link https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/workflows/delete_workflow}
   */
  deleteOne: ({ id }) => {
    return restAPI.nodeAPI.DELETE({
      endpoint: `/workflows/${id}`,
    });
  },
  deleteMany: () =>
    Promise.reject({
      success: false,
      error: "Method not implemented",
    }),
  /**
   * update a single workflow
   * @see {@link https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/workflows/put_workflow}
   */
  updateOne: ({ id, data }) => {
    const { status } = data;

    return restAPI.nodeAPI.PUT({
      endpoint: `/workflows/${id}`,
      body: {
        status,
      },
    });
  },
  updateMany: () =>
    Promise.reject({
      success: false,
      error: "Method not implemented",
    }),
  forms: {
    /**
     * list of related form submissions to the workflow
     * @see {@link https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/workflows/search_workflow_form_submissions}
     */
    getList: ({ workflowId, page, pageSize, sortBy, sortDirection, filters }) =>
      restAPI.nodeAPI.POST<GetListResponseType<WorkflowFormSubmissionType>>({
        endpoint: `/workflows/${workflowId}/form_submissions/search`,
        body: {
          page,
          page_size: pageSize,
          sort_by: sortBy,
          sort_direction: sortDirection,
          filter: getFilterObject(filters),
        },
      }),
  },
};

export interface WorkflowAdapterType extends ResourceServiceType<WorkflowType> {
  trigger: (args: TriggerWorkflowType) => Promise<void>;
  forms: {
    getList: (
      args: GetListRequestType<Partial<FormSubmissionFilterType>> & {
        workflowId: string;
      }
    ) => Promise<GetListResponseType<WorkflowFormSubmissionType>>;
  };
}
