import { EMISSIONS_HOME_FILTERS } from "#batteries-included-components/Layouts/HomeEmissions/EmissionsHomeFilterPanel";
import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink/RoutingLink";
import { MASS_POLLUTANTS } from "#src/constants";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import useLocalization from "#src/hooks/useLocalization";
import { linkToFacilityDetail } from "#src/routes/organization/facilities/[facilityId]";
import { getIntervalInMonthsFormatted, hydrateDateRange } from "#utils/date";
import { useQuery } from "@tanstack/react-query";
import {
  DataTable,
  DataTablePanel,
  HeaderType,
  StorageKeys,
  useFilters,
} from "@validereinc/common-components";
import {
  CalculatorResultType,
  CalculatorResultsDomain,
  SortDirection,
} from "@validereinc/domain";
import get from "lodash/get";
import React from "react";

const defaultMassPollutant = MASS_POLLUTANTS[0];

export const FacilityEmissionsByPollutantDataTablePanel = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  const [filters] = useFilters(filterConfigStorageKey);
  const {
    [EMISSIONS_HOME_FILTERS.dateRange.name]: dateRange,
    [EMISSIONS_HOME_FILTERS.pollutant.name]: pollutantDataKey,
    [EMISSIONS_HOME_FILTERS.reportingScenario.name]: reportingGroup,
  } = filters;
  const { localize } = useLocalization();
  const {
    getUnitByType,
    getPrecisionByType,
    getUnitName,
    isLoading: isMeasurementsLoading,
  } = useMeasurementTypes();

  const massPollutant = MASS_POLLUTANTS.find(
    (pollutant) => pollutant.dataKey === pollutantDataKey
  );
  const { mass, label } = massPollutant ?? defaultMassPollutant;
  const precision = getPrecisionByType(mass);
  const unit = getUnitName(getUnitByType(mass));

  const [tableState, updateTableState] = useTableSortingAndPagination(
    {
      page: 1,
      pageSize: 10,
      sortBy: `measurement.${mass}`,
      sortDirection: SortDirection.DESCENDING,
    },
    filters
  );

  const request = {
    page: tableState.page,
    pageSize: tableState.pageSize,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: {
      measurement_type: {
        $in: ["mass_ch4", "mass_co2", "mass_co2eq", "mass_n2o"],
      },
      reporting_group: reportingGroup,
      year_month: {
        $in: getIntervalInMonthsFormatted(hydrateDateRange(dateRange)),
      },
      isAlreadyFormatted: true,
    },
    groupBy: ["equipment.facility.id", "equipment.facility.name"],
  };

  const {
    data,
    isLoading: isResultsLoading,
    isFetching: isResultsFetching,
  } = useQuery({
    queryKey: ["calculatorResults", request],
    queryFn: () => CalculatorResultsDomain.getList(request),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const pollutantHeader: HeaderType<CalculatorResultType> = {
    label: `${label}${unit ? ` [${unit}]` : ""}`,
    key: `measurement[${mass}]`,
    isSortable: true,
    alignment: "right",
    renderComponent: ({ item, dataKey }) => (
      <DataTable.DataRow.NumberCell
        value={get(item, dataKey)}
        formattingOpts={
          precision
            ? {
                fractionDigits: precision,
              }
            : {}
        }
      />
    ),
  };

  const headers: Array<HeaderType<CalculatorResultType>> = [
    {
      label: `${localize("Facility")} Name`,
      key: "equipment.facility.name",
      isSortable: true,
      renderComponent: ({ item }) => (
        <RoutingLink to={linkToFacilityDetail(item?.["equipment.facility.id"])}>
          {item?.["equipment.facility.name"]}
        </RoutingLink>
      ),
    },
    pollutantHeader,
  ];

  return (
    <DataTablePanel
      panelProps={{ title: "Emissions by Facility" }}
      dataTableProps={{
        headers,
        items: data?.data ?? [],
        sorting: {
          sortBy: tableState.sortBy,
          sortDirection: tableState.sortDirection,
        },
        isLoading: isResultsLoading || isMeasurementsLoading,
        isBusy: isResultsFetching,
        pagination: {
          page: tableState.page,
          pageSize: tableState.pageSize,
          total: data?.total_entries,
        },
        onSortChange: updateTableState,
        onPaginationChange: updateTableState,
      }}
    />
  );
};
