import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import { linkToDeviceDetail } from "#routers/links";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useStorageKey } from "#src/hooks/useStorageKey";
import {
  DataTablePanel,
  HeaderType,
  Pill,
  SortingType,
} from "@validereinc/common-components";
import type {
  MeasurementSeriesAdapter,
  MeasurementSeriesType,
} from "@validereinc/domain";
import { AssetType, SortDirection } from "@validereinc/domain";
import React, { useMemo } from "react";
import { useListMeasurementSeries } from "#src/components/hooks/adapters/useMeasurementSeries";

const sorting: SortingType = {
  sortBy: "device_name",
  sortDirection: SortDirection.ASCENDING,
};

export const FlowDevicesPanel = ({
  flowId,
  isLoading: isFlowLoading,
}: {
  flowId?: string;
  isLoading: boolean;
}) => {
  const { tableConfigStorageKey } = useStorageKey("flows-devices");
  const [tableState, updateTableState] = useTableSortingAndPagination({
    ...sorting,
    pageSize: 10,
  });
  const params = useMemo<
    Parameters<typeof MeasurementSeriesAdapter.getList>[0]
  >(
    () => ({
      page: tableState.page,
      pageSize: tableState.pageSize,
      sortBy: tableState.sortBy,
      sortDirection: tableState.sortDirection,
      filters: { subjectId: flowId, subjectType: AssetType.FLOW },
    }),
    [tableState, flowId]
  );

  const { data, isLoading, isFetching } = useListMeasurementSeries(params, {
    enabled: !!flowId,
  });

  const headers: Array<HeaderType<MeasurementSeriesType>> = [
    {
      label: "Name",
      key: "device_name",
      isSortable: true,
      renderComponent: ({ item }) =>
        item.device_id ? (
          <RoutingLink to={linkToDeviceDetail(item.device_id)}>
            {item.device_name}
          </RoutingLink>
        ) : (
          "-"
        ),
    },
    {
      label: "Type",
      key: "type_id",
      isSortable: true,
      renderComponent: ({ item: { device } }) => device?.type?.name ?? "-",
    },
    {
      label: "Status",
      key: "status",
      isSortable: true,
      renderComponent: ({ item: { device } }) =>
        device?.status ? (
          <Pill variant={device?.status === "active" ? "success" : "default"}>
            {device.status}
          </Pill>
        ) : (
          "-"
        ),
    },
  ];

  return (
    <DataTablePanel
      storageKey={tableConfigStorageKey}
      panelProps={{
        title: "Devices",
        isFluidY: false,
        loaded: !isFlowLoading,
      }}
      dataTableProps={{
        variant: "simplicity-first",
        headers,
        items: data?.data ?? [],
        isLoading,
        isBusy: isFetching,
        onSortChange: updateTableState,
        onPaginationChange: updateTableState,
        sorting: {
          sortBy: tableState.sortBy,
          sortDirection: tableState.sortDirection,
        },
        pagination: {
          page: tableState.page,
          pageSize: tableState.pageSize,
          total: data?.total_entries,
        },
      }}
    />
  );
};
