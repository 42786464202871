import type { ReactNode } from "react";
import { getSafeFileMimeType, getSafeFileName } from "./logic/files";
import {
  areConditionsSatisfied,
  CalculatedFieldService,
  ConditionFunctions,
  FormCalculatedFieldService,
  getErrorCount,
  getParentQuestionName,
  getSmartDefaultValues,
  getValuePathFromQuestionPath,
  useGenerateFieldsToWatch,
  useGenerateLookupQueries,
  useProcessAttributeLookupQueries,
  useProcessCalculatedFields,
  type QuestionConditionType,
} from "./logic/forms";
import { getQuestionAnswerPairsBySection } from "./logic/forms/details";
import {
  getDisplayedUnit,
  getPreciseUnit,
  NON_DISPLAY_UNITS,
  NON_PRECISION_UNITS,
} from "./logic/measurements";
import {
  AuthQueryOperator,
  createAuthorizationEvaluator,
  type AuthQueryOperatorType,
  type AuthQueryType,
} from "./logic/permissions";
import { sortReportingGroupsByPriority } from "./logic/reporting-groups";
import { getResourceIdForResource } from "./logic/templated-configurations";
import {
  CalculatedFieldEquationController,
  FormSubmissionFormController,
  FormSubmissionsSectionRepeater,
  type FormSubmissionFormControllerType,
  type QuestionRendererPropType,
  type QuestionRenderFunctionType,
  type SectionRendererPropType,
  type SectionRenderFunctionType,
} from "./view/forms";

type ResourceControllersType = {
  view: Record<string, ReactNode>;
  logic: Record<string, any>;
};

const Forms = {
  view: {
    FormSubmissionFormController,
    FormSubmissionsSectionRepeater,
    CalculatedFieldEquationController,
  },
  logic: {
    areConditionsSatisfied,
    CalculatedFieldService,
    ConditionFunctions,
    FormCalculatedFieldService,
    getQuestionAnswerPairsBySection,
    getParentQuestionName,
    getSmartDefaultValues,
    getErrorCount,
    getValuePathFromQuestionPath,
    useGenerateFieldsToWatch,
    useGenerateLookupQueries,
    useProcessAttributeLookupQueries,
    useProcessCalculatedFields,
  },
} satisfies ResourceControllersType;

const Files = {
  view: {},
  logic: {
    getSafeFileName,
    getSafeFileMimeType,
  },
} satisfies ResourceControllersType;

const Measurements = {
  view: {},
  logic: {
    NON_DISPLAY_UNITS,
    NON_PRECISION_UNITS,
    getDisplayedUnit,
    getPreciseUnit,
  },
} satisfies ResourceControllersType;

const TemplatedConfigurations = {
  view: {},
  logic: {
    getResourceIdForResource,
  },
} satisfies ResourceControllersType;

const ReportingGroups = {
  view: {},
  logic: {
    sortReportingGroupsByPriority,
  },
} satisfies ResourceControllersType;

const Permissions = {
  view: {},
  logic: {
    AuthQueryOperator,
    createAuthorizationEvaluator,
  },
} satisfies ResourceControllersType;

export {
  Files,
  Forms,
  Measurements,
  Permissions,
  ReportingGroups,
  TemplatedConfigurations,
};

export type {
  AuthQueryOperatorType,
  AuthQueryType,
  FormSubmissionFormControllerType,
  QuestionConditionType,
  QuestionRendererPropType,
  QuestionRenderFunctionType,
  SectionRendererPropType,
  SectionRenderFunctionType,
};
