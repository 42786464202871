import RecordOfVolumeService from "#services/RecordOfVolumeService";
import {
  Button,
  Form,
  FormButton,
  Modal,
  Title,
  useForm,
  useToast,
} from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useState } from "react";
import { COMPOUND_OPTIONS, TYPE_OPTIONS } from "./AdjustmentFactorConstants";
import AdjustmentFactorForm from "./AdjustmentFactorsForm";
import { getAdjustmentFactorParams } from "./AdjustmentFactorsHelper";

const ADJUSTMENT_FACTOR_DEFAULT_INPUT = {
  name: "",
  type: TYPE_OPTIONS[1], // Total Volume
  compound: COMPOUND_OPTIONS[1], // No
  description: "",
};

const AddAdjustmentFactorsModal = ({
  show,
  updateAdjustmentFactorList,
  onHide,
}) => {
  const [formState, setFormState] = useState("enabled");
  const form = useForm({
    defaultValues: ADJUSTMENT_FACTOR_DEFAULT_INPUT,
  });
  const { toast } = useToast();

  const clearAdjustmentFactorForm = () => {
    setFormState("enabled");
    form.reset(ADJUSTMENT_FACTOR_DEFAULT_INPUT);
  };

  const submitAdjustmentFactor = (formState) => {
    setFormState("loading");

    const params = getAdjustmentFactorParams(formState);

    RecordOfVolumeService.addAdjustmentFactor(params)
      .then(({ data }) => {
        updateAdjustmentFactorList(data);
        setFormState("preview");

        toast.push({
          intent: "success",
          description: "An adjustment factor has been successfully created.",
        });
      })
      .catch(() => {
        setFormState("enabled");
      });
  };

  const onAddAdjustmentFactorClick = (inputs) => {
    submitAdjustmentFactor(inputs);
  };

  const onAddAnotherAdjustmentClick = () => {
    clearAdjustmentFactorForm();
  };

  const onModalClose = () => {
    clearAdjustmentFactorForm();
    onHide();
  };

  return (
    <Modal
      open={show}
      onClose={onModalClose}
    >
      <Form
        onSubmit={onAddAdjustmentFactorClick}
        {...form}
      >
        <Modal.Header>
          <Title>Add Adjustment Factor</Title>
        </Modal.Header>

        <Modal.Body>
          <AdjustmentFactorForm
            form={form}
            formState={formState}
            availableTypeOptions={TYPE_OPTIONS}
            availableCompoundOptions={COMPOUND_OPTIONS}
          />
        </Modal.Body>

        <Modal.Footer className="adjustmentFactors__actionRow clearfix">
          <Button
            className="pull-left"
            onClick={onModalClose}
          >
            Close
          </Button>

          {formState !== "preview" ? (
            <FormButton
              variant="primary"
              type="submit"
              disabled={formState !== "enabled"}
              isLoading={formState === "loading"}
            >
              Add Adjustment Factor
            </FormButton>
          ) : (
            <Button
              variant="primary"
              onClick={() => onAddAnotherAdjustmentClick()}
              disabled={formState !== "enabled" && formState !== "preview"}
              isLoading={formState === "loading"}
            >
              Add Another Adjustment Factor
            </Button>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

AddAdjustmentFactorsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  updateAdjustmentFactorList: PropTypes.func.isRequired,
  addAlertMessage: PropTypes.func.isRequired,
  adjustmentFactors: PropTypes.array,
};

export default AddAdjustmentFactorsModal;
