import { WorkflowNode } from "@validereinc/common-components";
import { Handle, NodeProps, Position } from "@xyflow/react";
import classNames from "classnames/bind";
import React from "react";

import styles from "./WorkflowGraphNode.module.scss";
import { WorkflowGraphNodeType } from "./types";
const cx = classNames.bind(styles);

const WorkflowGraphNode = (props: NodeProps<WorkflowGraphNodeType>) => {
  const { data } = props;

  return (
    <div
      style={{
        opacity: data?.isTranslucent ? 0.3 : 1,
        transition: "opacity 0.2s ease-out",
      }}
    >
      <WorkflowNode {...data} />
      <Handle
        type="target"
        position={Position.Top}
        className={cx("handle", "target")}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="a"
        className={cx("handle", "source")}
      />
    </div>
  );
};
export default WorkflowGraphNode;
