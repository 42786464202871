import { RoutingLink } from "#batteries-included-components/RoutingLink";
import { useListEstimationMethodRuns } from "#hooks/adapters/useEstimationMethods";
import { useGetOneNetwork } from "#hooks/adapters/useNetworks";
import { useGetManyUsers } from "#hooks/adapters/useUsers";
import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import { useParams } from "#routers/hooks";
import { NetworkDetailPageParameters } from "#routes/organization/networks/[networkId]/detail";
import { NetworkCalculationResultRoute } from "#routes/organization/networks/[networkId]/detail/calculation-result/[period]/details";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import {
  DataTable,
  DataTablePanel,
  HeaderType,
  StorageKeys,
  useFilters,
} from "@validereinc/common-components";
import {
  AssetType,
  CalculationOutputType,
  EstimationMethodRunType,
  SortDirection,
  UserType,
} from "@validereinc/domain";
import { yearMonthName } from "@validereinc/utilities";
import React from "react";

export const NetworkResultsTablePanel = ({
  filterConfigStorageKey,
  tableConfigStorageKey,
}: StorageKeys) => {
  const [filters] = useFilters(filterConfigStorageKey);
  const { getUnitName, getUnitByType, getPrecisionByType } =
    useMeasurementTypes();
  const { networkId } = useParams<NetworkDetailPageParameters>();
  const { data: network, isLoading: isNetworkLoading } =
    useGetOneNetwork(networkId);
  const isListEnabled = !!network?.default_estimation_method_id;
  const [tableState, updateTableState] = useTableSortingAndPagination(
    {
      sortBy: "year_month",
      sortDirection: SortDirection.DESCENDING,
    },
    filters
  );

  const { data, isLoading: isListLoading } = useListEstimationMethodRuns(
    {
      page: tableState.page,
      pageSize: tableState.pageSize,
      sortBy: tableState.sortBy,
      sortDirection: tableState.sortDirection,
      filters: {
        methodId: network?.default_estimation_method_id ?? "",
        entityType: AssetType.ASSET_GROUP,
      },
    },
    { enabled: isListEnabled }
  );
  const items = data?.data ?? [];

  const usersQuery = useGetManyUsers(
    Array.from(new Set(items.map(({ updated_by }) => updated_by))).filter(
      (item) => item
    )
  );
  const userMap = usersQuery.reduce(
    (accumulator: Record<string, UserType>, current) => {
      if (current.data?.id) {
        accumulator[current.data.id] = current.data;
      }
      return accumulator;
    },
    {}
  );

  const getOutputValue = (run: EstimationMethodRunType, type: string) => {
    const output = run?.output?.outputs.find(
      ({ measurement_type }: CalculationOutputType) => measurement_type === type
    );
    const precision = getPrecisionByType(type);

    return (
      <DataTable.DataRow.NumberCell
        value={output?.measurement_value}
        formattingOpts={
          precision
            ? {
                fractionDigits: precision,
              }
            : {}
        }
      />
    );
  };

  const getOutputHeaderLabel = (label: string, type: string) => {
    const unit = getUnitName(getUnitByType(type));

    return unit ? `${label} [${unit}]` : label;
  };

  const headers: Array<HeaderType<EstimationMethodRunType>> = [
    {
      key: "year_month",
      label: "Time Period",
      isSortable: true,
      renderComponent: ({ item }) => {
        const period = item?.year_month;
        const pathname = NetworkCalculationResultRoute.toLink({
          pathParams: {
            networkId,
            period,
          },
        });
        return (
          <RoutingLink to={pathname}>
            {period ? yearMonthName(period) : "-"}
          </RoutingLink>
        );
      },
    },
    {
      key: "total_inlet",
      label: getOutputHeaderLabel("Total Inlet", "upstream_volume"),
      isSortable: false,
      alignment: "right",
      renderComponent: ({ item }) => getOutputValue(item, "upstream_volume"),
    },
    {
      key: "total_adjusted",
      label: getOutputHeaderLabel(
        "Total Adjusted Volume",
        "downstream_volume_adjusted"
      ),
      isSortable: false,
      alignment: "right",
      renderComponent: ({ item }: { item: EstimationMethodRunType }) =>
        getOutputValue(item, "downstream_volume_adjusted"),
    },
    {
      key: "total_non_adjustable",
      label: getOutputHeaderLabel(
        "Total Non-Adjustable Volume",
        "downstream_volume_non_adjustable"
      ),
      isSortable: false,
      alignment: "right",
      renderComponent: ({ item }: { item: EstimationMethodRunType }) =>
        getOutputValue(item, "downstream_volume_non_adjustable"),
    },
    {
      key: "total_adjustable",
      label: getOutputHeaderLabel(
        "Total Adjustable Volume",
        "downstream_volume_adjustable"
      ),
      isSortable: false,
      alignment: "right",
      renderComponent: ({ item }: { item: EstimationMethodRunType }) =>
        getOutputValue(item, "downstream_volume_adjustable"),
    },
    {
      key: "adjustment_factor",
      label: getOutputHeaderLabel("Adjustment Factor", "adjustment_factor"),
      isSortable: false,
      alignment: "right",
      renderComponent: ({ item }: { item: EstimationMethodRunType }) =>
        getOutputValue(item, "adjustment_factor"),
    },
    {
      label: "Last Ran By",
      key: "updated_by",
      isSortable: true,
      renderComponent: ({ item }) => userMap[item.updated_by]?.name ?? "-",
    },
    {
      label: "Last Ran At",
      key: "updated_at",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={item.updated_at}
          withTime
        />
      ),
    },
  ];

  const isLoading = isNetworkLoading || (isListEnabled && isListLoading);

  return (
    <DataTablePanel
      storageKey={tableConfigStorageKey}
      panelProps={{
        title: "Calculations",
      }}
      dataTableProps={{
        headers,
        items,
        isLoading,
        sorting: {
          sortBy: tableState.sortBy,
          sortDirection: tableState.sortDirection,
        },
        onSortChange: updateTableState,
        onPaginationChange: updateTableState,
        pagination: {
          page: tableState.page,
          pageSize: tableState.pageSize,
          total: data?.total_entries,
        },
      }}
    />
  );
};
