import { z } from "zod";

import { EventStatus } from "../adapters/EventsDomain";
import { TypeOfObjectValues } from "../util";
import { FormSubmissionStatus } from "./FormSubmissionSchemas.shared";
import { ReportInputValue } from "./ReportSchemas";

// General definitions:

// Two main categories of step types:
export const WorkflowStepType = {
  SYSTEM_ACTION: "system_action",
  USER_TASK: "user_task",
} as const;

export type WorkflowStepTypeType = TypeOfObjectValues<typeof WorkflowStepType>;

// Optional input and output values for each task in the workflow:
export const WorkflowBaseTaskSchema = z.object({
  output: z.record(z.string(), z.string()).optional(),
  input: z.record(z.string(), z.string()).optional(),
});
export type WorkflowBaseTask = z.infer<typeof WorkflowBaseTaskSchema>;

///////////////////////////////////////////////////////////////
//////////////////     System Actions     /////////////////////
///////////////////////////////////////////////////////////////

// General definitions:
export const SystemActionType = {
  CREATE_EVENT: "create_event",
  FORM_CHOICE: "form_choice",
  DELAY: "delay",
  CREATE_FORM: "create_form",
  LOCK_RECORD: "lock_record",
  RUN_TEMPLATED_REPORT: "run_templated_report",
  RUN_DEFAULT_RECORD_VALUE_CONFIGURATION:
    "run_default_record_value_configuration",
  ASSESS_TEMPLATED_REPORT_FOR_COMMENTS: "assess_templated_report_for_comments",
} as const;

export type SystemActionTypeType = TypeOfObjectValues<typeof SystemActionType>;

export const WorkflowTriggerTimePeriod = {
  HOURS: "hours",
  DAYS: "days",
  WEEKS: "weeks",
  MONTHS: "months",
} as const;

const WorkflowTriggerTimePeriodSchema = z.enum([
  WorkflowTriggerTimePeriod.HOURS,
  WorkflowTriggerTimePeriod.DAYS,
  WorkflowTriggerTimePeriod.MONTHS,
  WorkflowTriggerTimePeriod.WEEKS,
]);

// Create event action:
export const CreateEventActionSchema = WorkflowBaseTaskSchema.extend({
  type: z.literal(SystemActionType.CREATE_EVENT),
  event_schema_id: z.string().uuid(),
  event_category_id: z.string().uuid(),
  event_status: z.nativeEnum(EventStatus),
});
export type CreateEventAction = z.infer<typeof CreateEventActionSchema>;

// Form choice action:
export const FormChoiceActionSchema = WorkflowBaseTaskSchema.extend({
  type: z.literal(SystemActionType.FORM_CHOICE),
  form_schema_id: z.string().uuid(),
  choices: z.array(
    z.object({
      answer_filter: z.record(z.string(), z.any()),
      next: z.string().optional(),
      end: z.literal(true).optional(),
    })
  ),
});
export type FormChoiceAction = z.infer<typeof FormChoiceActionSchema>;

// Delay action:
export const DelayActionSchema = WorkflowBaseTaskSchema.extend({
  type: z.literal(SystemActionType.DELAY),
  time_period: WorkflowTriggerTimePeriodSchema,
  duration: z.number().positive(),
  from: z.enum(["now", "previous_workflow"]).optional(),
});
export type DelayActionType = z.infer<typeof DelayActionSchema>;

// Create form action:
export const CreateFormActionSchema = WorkflowBaseTaskSchema.extend({
  type: z.literal(SystemActionType.CREATE_FORM),
  form_schema_id: z.string().uuid(),
  form_status: z.nativeEnum(FormSubmissionStatus),
});
export type CreateFormActionType = z.infer<typeof CreateFormActionSchema>;

// Lock record action:
export const LockRecordActionSchema = WorkflowBaseTaskSchema.extend({
  type: z.literal(SystemActionType.LOCK_RECORD),
  record_filter: z.record(z.string(), z.any()),
});
export type LockRecordActionType = z.infer<typeof LockRecordActionSchema>;

// Run DRVC action:
export const RunDRVCActionSchema = WorkflowBaseTaskSchema.extend({
  type: z.literal(SystemActionType.RUN_DEFAULT_RECORD_VALUE_CONFIGURATION),
  filter: z.record(z.string(), z.any()),
  overwrite_all_values: z.boolean(),
});
export type RunDRVCActionType = z.infer<typeof RunDRVCActionSchema>;

// Run templated report action:
export const RunTemplatedReportActionSchema = WorkflowBaseTaskSchema.extend({
  type: z.literal(SystemActionType.RUN_TEMPLATED_REPORT),
  report_input: z.record(z.string(), ReportInputValue),
});

export type RunTemplatedReportAction = z.infer<
  typeof RunTemplatedReportActionSchema
>;

// Assess templated reports for comments:
export const AssessTemplatedReportFormCommentsActionSchema =
  WorkflowBaseTaskSchema.extend({
    type: z.literal(SystemActionType.ASSESS_TEMPLATED_REPORT_FOR_COMMENTS),
    report_id: z.string(),
    header_name: z.string(),
    styles_to_assess: z.array(z.string()),
    assessment_success_next: z.string(),
    assessment_failure_next: z.string(),
  });

export type AssessTemplatedReportFormCommentsAction = z.infer<
  typeof AssessTemplatedReportFormCommentsActionSchema
>;

///////////////////////////////////////////////////////////////
////////////////////     User Tasks     ///////////////////////
///////////////////////////////////////////////////////////////

///////////////   Attachments in user tasks   /////////////////
export const WorkflowUserTaskAttachmentType = {
  REPORT: "report",
  FORM_SUBMISSION: "form_submission",
  RECORD: "record",
} as const;

export const BaseWorkflowUserTaskAttachmentSchema = z.object({
  type: z.nativeEnum(WorkflowUserTaskAttachmentType),
  title: z.string(),
});

export const WorkflowUserTaskAttachmentReportSchema =
  BaseWorkflowUserTaskAttachmentSchema.extend({
    type: z.literal(WorkflowUserTaskAttachmentType.REPORT),
    report_id: z.string().min(1),
  });
export type WorkflowUserTaskAttachmentReport = z.infer<
  typeof WorkflowUserTaskAttachmentReportSchema
>;

export const WorkflowUserTaskAttachmentRecordsSchema =
  BaseWorkflowUserTaskAttachmentSchema.extend({
    type: z.literal(WorkflowUserTaskAttachmentType.RECORD),
    filter: z
      .record(z.any())
      .refine((filter) => Object.keys(filter).length > 0, {
        message: "Must provide at least one filter",
      }),
  });
export type WorkflowUserTaskAttachmentRecords = z.infer<
  typeof WorkflowUserTaskAttachmentRecordsSchema
>;

export const WorkflowUserTaskAttachmentFormSubmissionSchema =
  BaseWorkflowUserTaskAttachmentSchema.extend({
    type: z.literal(WorkflowUserTaskAttachmentType.FORM_SUBMISSION),
    form_submission_id: z.string().min(1),
  });
export type WorkflowUserTaskAttachmentFormSubmission = z.infer<
  typeof WorkflowUserTaskAttachmentFormSubmissionSchema
>;

export const WorkflowUserTaskAttachmentSchema = z.union([
  WorkflowUserTaskAttachmentReportSchema,
  WorkflowUserTaskAttachmentRecordsSchema,
  WorkflowUserTaskAttachmentFormSubmissionSchema,
]);
export type WorkflowUserTaskAttachment = z.infer<
  typeof WorkflowUserTaskAttachmentSchema
>;
//////////////  End Attachments in user tasks   ///////////////

export const UserTaskType = {
  MANUAL_TASK: "manual_task",
  SUBMIT_FORM: "submit_form",
  COMPLETE_EVENT: "complete_event",
  CHOICE: "choice",
} as const;

export type UserTaskTypeType = TypeOfObjectValues<typeof UserTaskType>;

export const UserTaskTypeSchema = z.nativeEnum(UserTaskType);

// Every user task can potentially have attachments:
export const WorkflowBaseUserTaskSchema = WorkflowBaseTaskSchema.extend({
  attachments: z
    .array(WorkflowUserTaskAttachmentSchema)
    .min(0)
    .max(3)
    .optional(),
});

// Manual user task:
export const ManualTaskSchema = WorkflowBaseUserTaskSchema.extend({
  type: z.literal(UserTaskType.MANUAL_TASK),
});
export type ManualTask = z.infer<typeof ManualTaskSchema>;

// Form submission user task:
export const FormSubmissionTaskSchema = WorkflowBaseUserTaskSchema.extend({
  type: z.literal(UserTaskType.SUBMIT_FORM),
  form_schema_id: z.string().uuid(),
  autocomplete: z.boolean().optional(),
  auto_attach: z.boolean().optional(),
  form_required: z.boolean().optional(),
});
export type FormSubmissionTaskType = z.infer<typeof FormSubmissionTaskSchema>;

// Complete event user task:
export const CompleteEventTaskSchema = WorkflowBaseUserTaskSchema.extend({
  type: z.literal(UserTaskType.COMPLETE_EVENT),
  event_step_id: z.string().uuid(),
});
export type CompleteEventTask = z.infer<typeof CompleteEventTaskSchema>;

// Choice user task:
export const ChoiceTaskSchema = WorkflowBaseUserTaskSchema.extend({
  type: z.literal(UserTaskType.CHOICE),
  choices: z
    .array(
      z.object({
        id: z.string().max(64).min(1),
        name: z.string().max(256),
        description: z.string().max(1024),
        next: z.string().optional(),
        end: z.literal(true).optional(),
      })
    )
    .min(2, "Must have at least 2 choices")
    .max(10, "Must not have more than 10 choices"),
});
export type ChoiceTask = z.infer<typeof ChoiceTaskSchema>;

export const WorkflowTaskAssigneeSchema = z.object({
  assignee_user: z.string().uuid().optional(),
  assignee_group: z.string().uuid().optional(),
});
export type WorkflowTaskAssignee = z.infer<typeof WorkflowTaskAssigneeSchema>;

export const WorkflowTaskAssignmentFilterSchema = z.record(
  z.string(),
  z.record(z.string(), WorkflowTaskAssigneeSchema)
);

export type WorkflowTaskAssignmentFilter = z.infer<
  typeof WorkflowTaskAssignmentFilterSchema
>;

export const WorkflowTaskDueDateSchema = z.object({
  time_period: WorkflowTriggerTimePeriodSchema,
  duration: z.number().positive(),
});
export type WorkflowTaskDueDate = z.infer<typeof WorkflowTaskDueDateSchema>;

export const WorkflowActionInputSchema = z.object({
  type: z.nativeEnum(WorkflowStepType),
  name: z.string(),
  description: z.string().optional(),
  next: z.string().optional(),
  end: z.boolean().optional(),
});
export type WorkflowActionInput = z.infer<typeof WorkflowActionInputSchema>;

export const WorkflowSystemActionSchema = WorkflowActionInputSchema.extend({
  type: z.literal(WorkflowStepType.SYSTEM_ACTION),
  task: z.union([
    CreateEventActionSchema,
    FormChoiceActionSchema,
    DelayActionSchema,
    CreateFormActionSchema,
    LockRecordActionSchema,
    RunDRVCActionSchema,
    RunTemplatedReportActionSchema,
    AssessTemplatedReportFormCommentsActionSchema,
  ]),
});

export type WorkflowSystemActionType = z.infer<
  typeof WorkflowSystemActionSchema
>;

export const WorkflowUserTaskSchema = WorkflowActionInputSchema.extend({
  type: z.literal(WorkflowStepType.USER_TASK),
  task: z.union([
    ManualTaskSchema,
    FormSubmissionTaskSchema,
    CompleteEventTaskSchema,
    ChoiceTaskSchema,
  ]),
  description: z.string().max(1024),
  is_dismissible: z.boolean().optional(),
  assignment_filter: WorkflowTaskAssignmentFilterSchema.optional(),
  due_date: WorkflowTaskDueDateSchema.optional(),
  restrict_assignee_completion: z.boolean().optional(),
}).merge(WorkflowTaskAssigneeSchema);

export type WorkflowUserTask = z.infer<typeof WorkflowUserTaskSchema>;

export const WorkflowConfigSchema = z.object({
  start: z.string(),
  steps: z.record(
    z.string(),
    z.union([WorkflowSystemActionSchema, WorkflowUserTaskSchema])
  ),
  workflow_name: z.string().optional(),
  workflow_description: z.string().optional(),
});
export type WorkflowConfig = z.infer<typeof WorkflowConfigSchema>;
