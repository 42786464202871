import PermissionAwareExportButton from "#src/batteries-included-components/Buttons/PermissionAwareExportButton/PermissionAwareExportButton";
import { type RolePermissionsActivityLogFiltersType } from "#src/batteries-included-components/FilterAreas/RolePermissionsActivityLogFilterAreas.tsx/RolePermissionsActivityLogFilterAreas";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import {
  useExportActivityLogs,
  useListActivityLogs,
} from "#src/components/hooks/adapters/useActivityLogs";
import { useGetManyUsers } from "#src/components/hooks/adapters/useUsers";
import { useTableSortingAndPagination } from "#src/components/Redux/reducers/tableStateReducer";
import {
  getPermissionDisplayLabel,
  useHasPermission,
} from "#src/contexts/AuthenticatedContext.helpers";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import { linkToRoleDetailPage } from "#src/routes/settings/roles-and-permissions/roles/[roleId]/detail";
import { linkToUserDetailPage } from "#src/routes/settings/users/detail";
import { getPropertyAsMap } from "#src/utils/objectFormatter";
import { useQueries, UseQueryOptions } from "@tanstack/react-query";
import {
  ArrayDataDisplay,
  DataTable,
  DataTablePanel,
  type HeaderType,
  Pill,
  StorageKeys,
} from "@validereinc/common-components";
import {
  ActivitiesDomain,
  ActivityAction,
  ActivityExportResource,
  ActivityResource,
  ActivitySchema,
  type ActivityType,
  type PermissionStatementType,
  RolesAdapter,
  type RoleType,
  SortDirection,
  type UserType,
} from "@validereinc/domain";
import isEmpty from "lodash/isEmpty";
import React, { useMemo, useState } from "react";

const activityModelKeys = ActivitySchema.keyof().Enum;

type RolePermissionsActivityLogBeforeAfter = {
  permissions?: PermissionStatementType[];
};

export const RolePermissionsActivityLogTablePanel = ({
  viewConfigStorageKey,
}: Pick<StorageKeys, "viewConfigStorageKey">) => {
  const [canWriteReports] = useHasPermission("reports:write");
  const [viewFilters] =
    useSessionStickyState<RolePermissionsActivityLogFiltersType>(
      {} as RolePermissionsActivityLogFiltersType,
      viewConfigStorageKey
    );

  const { resource_id, logged_by, logged_at } = viewFilters;

  const [tableState, updateTableState] = useTableSortingAndPagination({
    sortBy: "timestamp",
    sortDirection: SortDirection.DESCENDING,
  });
  const [selectedActivities, setSelectedActivities] = useState<
    Record<string, ActivityType>
  >({});

  const viewFiltersToApply = {
    ...(resource_id && { resource_id }),
    ...(logged_by && { author_id: logged_by }),
    ...(logged_at && { timestamp: logged_at }),
  };

  const activitiesQueryPayload = {
    page: tableState.page,
    pageSize: tableState.pageSize,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: {
      ...viewFiltersToApply,
      action: ActivityAction.PERMISSION_UPDATE,
    },
    meta: { resourceType: ActivityResource.ROLE },
  } satisfies Parameters<typeof ActivitiesDomain.getList>[0];

  const { data: activitiesData, isLoading: areActivityLogsLoading } =
    useListActivityLogs(activitiesQueryPayload);

  const activities = activitiesData?.data;

  const processActivityPermissions = useMemo(() => {
    const uniqueAuthorIds = new Set<string>();
    const uniqueRoleIds = new Set<string>();
    const beforePermissions: Record<string, Set<string>> = {};
    const afterPermissions: Record<string, Set<string>> = {};

    activities?.forEach((activity) => {
      uniqueAuthorIds.add(activity.author_id);
      uniqueRoleIds.add(activity.resource_id);

      beforePermissions[activity.id] = new Set(
        (
          activity.before as RolePermissionsActivityLogBeforeAfter
        )?.permissions?.map((p) => getPermissionDisplayLabel(p.name))
      );
      afterPermissions[activity.id] = new Set(
        (
          activity.after as RolePermissionsActivityLogBeforeAfter
        )?.permissions?.map((p) => getPermissionDisplayLabel(p.name))
      );
    });

    return {
      uniqueAuthorIds,
      uniqueRoleIds,
      beforePermissions,
      afterPermissions,
    };
  }, [activities]);

  const {
    uniqueAuthorIds,
    uniqueRoleIds,
    beforePermissions,
    afterPermissions,
  } = processActivityPermissions;

  const associatedUsersQueries = useGetManyUsers(Array.from(uniqueAuthorIds));

  const authorIdsToUserDetailsMap = useMemo(() => {
    return getPropertyAsMap(
      associatedUsersQueries,
      "data",
      "data.id"
    ) as Record<string, UserType>;
  }, [associatedUsersQueries]);

  const associatedRoleQueries = useQueries<
    Array<
      UseQueryOptions<
        Awaited<ReturnType<typeof RolesAdapter.getOne>> | undefined,
        unknown,
        RoleType | undefined
      >
    >
  >({
    queries:
      Array.from(uniqueRoleIds).map((roleId) => ({
        queryKey: ["roles", roleId],
        queryFn: () => {
          if (!roleId) {
            return;
          }

          return RolesAdapter.getOne({ id: roleId });
        },
        enabled: Boolean(roleId),
        staleTime: 3 * 60 * 1000,
        select: (resp) => resp?.data,
      })) ?? [],
  });

  const roleIdsToRoleDetailsMap = useMemo(() => {
    return getPropertyAsMap(associatedRoleQueries, "data", "data.id") as Record<
      string,
      UserType
    >;
  }, [associatedRoleQueries]);

  const exportActivityLogPayload = {
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: {
      ...(!isEmpty(selectedActivities)
        ? { id: Object.keys(selectedActivities) }
        : {}),
      ...viewFiltersToApply,
    },
    meta: { resourceType: ActivityExportResource.ROLE },
  } satisfies Parameters<typeof ActivitiesDomain.exportList>[0];

  const { mutate: handleExport, isLoading: isExporting } =
    useExportActivityLogs(exportActivityLogPayload);

  const isLoading =
    areActivityLogsLoading ||
    associatedUsersQueries.some((query) => query.isLoading) ||
    associatedRoleQueries.some((query) => query.isLoading);

  const headers = useMemo<Array<HeaderType<ActivityType>>>(
    () => [
      {
        key: activityModelKeys.action,
        label: "Type",
        renderComponent: () => {
          return (
            <Pill
              variant="default"
              isCapitalized={false}
              isLoading={isLoading}
            >
              Permission Modified
            </Pill>
          );
        },
      },
      {
        key: activityModelKeys.resource_id,
        label: "Role",
        renderComponent: ({ item }) => {
          const roleId = item.resource_id;
          const roleName = roleIdsToRoleDetailsMap[roleId]?.name;
          return roleId && roleName ? (
            <RoutingLink to={linkToRoleDetailPage(roleId)}>
              {roleName}
            </RoutingLink>
          ) : (
            "-"
          );
        },
      },
      {
        key: "permissions_added",
        label: "Permissions Added",
        renderComponent: ({ item }) => {
          const permissionsAdded = Array.from(
            afterPermissions[item.id].difference(beforePermissions[item.id]) ??
              new Set<string>()
          );

          return <ArrayDataDisplay value={permissionsAdded ?? []} />;
        },
      },
      {
        key: "permissions_removed",
        label: "Permissions Removed",
        renderComponent: ({ item }) => {
          const permissionsRemoved = Array.from(
            beforePermissions[item.id].difference(afterPermissions[item.id]) ??
              new Set<string>()
          );
          return <ArrayDataDisplay value={permissionsRemoved ?? []} />;
        },
      },
      {
        key: activityModelKeys.author_id,
        label: "Logged By",
        renderComponent: ({ item }) => {
          const id = item.author_id;
          const name = authorIdsToUserDetailsMap[id]?.name;
          return id && name ? (
            <RoutingLink to={linkToUserDetailPage(id)}>{name}</RoutingLink>
          ) : (
            "-"
          );
        },
      },
      {
        key: activityModelKeys.timestamp,
        label: "Logged At",
        isSortable: true,
        renderComponent: ({ item }) => (
          <DataTable.DataRow.DateCell
            value={item[activityModelKeys.timestamp]}
            withTime
            isLoading={isLoading}
          />
        ),
      },
    ],
    [
      roleIdsToRoleDetailsMap,
      authorIdsToUserDetailsMap,
      afterPermissions,
      beforePermissions,
    ]
  );

  const actionRow = [
    ...(canWriteReports
      ? [
          <PermissionAwareExportButton
            key="export-roles-permissions-activity-log"
            onClick={handleExport}
            isExporting={isExporting}
          />,
        ]
      : []),
  ];

  return (
    <DataTablePanel
      actionRowWhenNoRowsSelected={actionRow}
      actionRowWhenRowsSelected={actionRow}
      dataTableProps={{
        headers,
        items: activities ?? [],
        isLoading,
        sorting: {
          sortBy: tableState.sortBy,
          sortDirection: tableState.sortDirection,
        },
        pagination: {
          page: tableState.page,
          pageSize: tableState.pageSize,
          total: activitiesData?.total_entries,
          pageSizeText: "logs per page",
        },
        onSortChange: updateTableState,
        onPaginationChange: updateTableState,
        selected: selectedActivities,
        onSelectionChange: setSelectedActivities,
        getItemId: (item) => item.id,
      }}
      panelProps={{
        title: "Change Log",
      }}
    />
  );
};
