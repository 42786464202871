import { useNavigate } from "#src/Routers/hooks";
import { UserGroupCategoryFormDialog } from "#src/batteries-included-components/Dialogs/UserGroupCategoryFormDialog";
import { useTableSortingAndPagination } from "#src/components/Redux/reducers/tableStateReducer";
import { useHasPermission } from "#src/contexts/AuthenticatedContext.helpers";
import { UserGroupCategoryDetailsRoutePath } from "#src/routes/settings/users/groups/categories/[categoryId]/details";
import { useQuery } from "@tanstack/react-query";
import {
  Button,
  DateDataDisplay,
  EmptyState,
  Flex,
  GridCard,
  Pagination,
  Panel,
  Skeleton,
  SortingType,
  StorageKeys,
  useFilters,
} from "@validereinc/common-components";
import {
  SortDirection,
  UserGroupCategoriesAdapter,
  UserGroupCategorySchema,
} from "@validereinc/domain";
import { DateFormats } from "@validereinc/utilities";
import React, { useEffect, useState } from "react";

const { Container, Item } = Flex;

const sorting: SortingType = {
  sortBy: UserGroupCategorySchema.keyof().Enum.name,
  sortDirection: SortDirection.ASCENDING,
};

const UserGroupCategoriesPanel = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState<boolean>(false);

  // TODO: This is temporary. Change to core:roles_permissions when all done with user groups:
  const [hasCreatePermission] = useHasPermission("user_group_categories:write");
  const navigate = useNavigate();
  const [filters] = useFilters<{ search: string }>(filterConfigStorageKey);
  const [pagination, setPagination] = useTableSortingAndPagination(
    {
      ...sorting,
    },
    {}
  );

  const groupCategoriesFilterObject = {
    page: pagination.page,
    pageSize: pagination.pageSize,
    sortBy: pagination.sortBy,
    sortDirection: pagination.sortDirection,
    filters: {
      isAlreadyFormatted: true,
      ...(filters.search
        ? {
            $or: [
              { name: { $like: filters.search } },
              { description: { $like: filters.search } },
            ],
          }
        : {}),
    },
  };

  const query = useQuery({
    queryKey: ["users", "groups", "categories", groupCategoriesFilterObject],
    queryFn: () =>
      UserGroupCategoriesAdapter.getList(groupCategoriesFilterObject),
  });

  useEffect(() => {
    if (query.data?.total_entries)
      setPagination({ total: query.data?.total_entries });
  }, [query.data?.total_entries]);

  const list = query.data?.data ?? [];

  return (
    <>
      <Panel
        title="Categories"
        loaded={!query.isLoading}
        actionRow={
          hasCreatePermission ? (
            <Button
              variant="primary"
              iconPosition="left"
              icon="plus-circle"
              size="small"
              style={{ border: "none" }}
              onClick={() => {
                setIsCreateDialogOpen(true);
              }}
            >
              Create Group Category
            </Button>
          ) : null
        }
      >
        {list.length === 0 ? (
          <EmptyState
            title="There are no categories to display"
            suggestion="Please try adjusting your filters"
          />
        ) : (
          <>
            <Container variant="grid">
              {list.map(({ id, name, description, updated_at }) => (
                <Item key={id}>
                  <GridCard.Container
                    title={name}
                    description={description}
                    onClick={() =>
                      navigate(
                        UserGroupCategoryDetailsRoutePath.toLinkParts({
                          pathParams: { categoryId: id },
                        })
                      )
                    }
                    isLoading={query.isLoading}
                    slotFooter={(props) => (
                      <GridCard.Footer
                        {...props}
                        slotLeft={({ className, isLoading, isBusy }) => (
                          <Skeleton
                            isLoading={isLoading}
                            isBusy={isBusy}
                          >
                            <p className={className}>
                              Last updated on&nbsp;
                              <DateDataDisplay
                                as="span"
                                isInline
                                value={updated_at}
                                displayFormat={DateFormats.DATE}
                              />
                            </p>
                          </Skeleton>
                        )}
                      />
                    )}
                  />
                </Item>
              ))}
            </Container>

            <Pagination
              {...pagination}
              onChange={setPagination}
              style={{ marginTop: 8 }}
            />
          </>
        )}
      </Panel>
      <UserGroupCategoryFormDialog
        isOpen={isCreateDialogOpen}
        onClose={() => {
          setIsCreateDialogOpen(false);
        }}
      />
    </>
  );
};

export default UserGroupCategoriesPanel;
