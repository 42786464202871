import { getTimeStringFromDate } from "#utils/timeFormatter";
import { LegacyDataTable } from "@validereinc/common-components";
import { formatNumberValueAndUnit } from "@validereinc/utilities";
import classNames from "classnames/bind";
import * as PropTypes from "prop-types";
import React from "react";
import { AutoSizer } from "react-virtualized";
import {
  DEFAULT_EMISSION_DECIMAL,
  EMITTED_POLLUTANTS,
} from "../../../EmissionConstants";
import styles from "../EmissionReportDetail.module.scss";

const cx = classNames.bind(styles);

const FacilityDetailTable = ({
  facilitySummary,
  paginationDetail,
  onPaginationChange,
}) => {
  function emissionRenderer(rowData, columnKey) {
    try {
      return (
        formatNumberValueAndUnit(rowData.total_emissions[columnKey], {
          fractionDigits: DEFAULT_EMISSION_DECIMAL,
        }) ?? "-"
      );
    } catch (error) {
      return "-";
    }
  }

  function periodRenderer(rowData) {
    const startMonth = getTimeStringFromDate(rowData.start_date, "MMM");
    const endMonth = getTimeStringFromDate(rowData.end_date, "MMM");

    return `${startMonth} - ${endMonth}`;
  }

  const emissionColumns = EMITTED_POLLUTANTS.map((pollutant) => ({
    label: pollutant,
    key: pollutant,
    width: 150,
    cellRenderer: emissionRenderer,
    rightAlign: true,
  }));

  const headers = [
    {
      label: "Facility",
      key: "name",
      width: 150,
      fixed: true,
      disableSort: true,
    },
    {
      label: "Facility Code",
      key: "facility_code",
      width: 150,
      fixed: true,
      disableSort: true,
    },
    {
      label: "Period",
      key: "start_date",
      width: 150,
      rightAlign: true,
      disableSort: true,
      cellRenderer: periodRenderer,
    },
    ...emissionColumns,
  ];

  return (
    <div className={cx("facilityTable")}>
      <AutoSizer>
        {({ width, height }) => (
          <LegacyDataTable
            width={width}
            height={height}
            headers={headers}
            list={facilitySummary ?? []}
            paginationDetail={paginationDetail}
            onPaginationChange={onPaginationChange}
            rowHeight={55}
            defaultSortDirection="desc"
          />
        )}
      </AutoSizer>
    </div>
  );
};

FacilityDetailTable.propTypes = {
  facilitySummary: PropTypes.array.isRequired,
  paginationDetail: PropTypes.object.isRequired,
  onPaginationChange: PropTypes.func.isRequired,
};

export default FacilityDetailTable;
