import { Accordion, Button, Panel } from "@validereinc/common-components";
import type { SectionRendererPropType } from "@validereinc/domain-controllers/view/forms";
import classNames from "classnames/bind";
import React from "react";
import styles from "../FormSubmissionPage.module.scss";

const cx = classNames.bind(styles);

const { AccordionPanel } = Accordion;

export const FormSubmissionSection = ({
  onAddSection,
  canAddSection,
  addButtonLabel,
  onRemoveSection,
  canRemoveSection,
  hasErrors,
  title,
  children,
  dataKey,
  description,
}: SectionRendererPropType) => {
  return (
    <>
      <div
        className={cx("sectionContainer", canAddSection && "isRepeatable")}
        key={dataKey}
      >
        <Panel className={cx("sectionPanel")}>
          <Accordion defaultActiveKeys={["default_key"]}>
            <AccordionPanel
              title={title}
              dataKey={"default_key"}
              actionRow={
                canRemoveSection
                  ? [
                      <Button
                        key="remove-section-action"
                        onClick={onRemoveSection}
                        icon="trash"
                        variant="ghost"
                      />,
                    ]
                  : []
              }
              isError={hasErrors}
              contentClassName={cx("panelContent")}
            >
              {description && <p>{description}</p>}
              {children}
            </AccordionPanel>
          </Accordion>
        </Panel>
      </div>
      {canAddSection && (
        <Button
          onClick={onAddSection}
          icon="plus-circle"
          className={cx("sectionAddTrigger")}
        >
          {addButtonLabel ?? `Add Section`}
        </Button>
      )}
    </>
  );
};
