import { Button, useForm, useToast } from "@validereinc/common-components";
import {
  AssetTypeType,
  EstimationMethodDomain,
  ReportingGroupDomain,
} from "@validereinc/domain";
import React from "react";

export function useCreateEstimationMethodForm({
  entityId,
  entityType,
  mutationType,
  defaultValues,
  onSubmit,
  onCancel,
}: UseCreateEstimationMethodFormProps) {
  const { toast } = useToast();
  const allDefaultValues = Object.assign({}, defaultValues);
  const formProps = useForm({
    defaultValues: allDefaultValues,
  });
  const { isValid, isSubmitting } = formProps.formState;

  const handleSubmit = async (formValues: CreateEstimationMethodFormValues) => {
    if (!isValid || !entityId) {
      // show feedback
      toast.push({
        intent: "error",
        description: `Cannot ${
          mutationType === "create" ? "create" : "update"
        } estimation method ${
          formValues.name
        }. Something isn't configured right.`,
      });
      return;
    }

    const payload = {
      entity_id: entityId,
      analytics_library_id: formValues.libraryId,
      analytics_calculator_id: formValues.calculatorId,
      name: formValues.name,
    };

    try {
      const createdEstimationMethod = await EstimationMethodDomain.create({
        data: payload,
        meta: {
          entityType,
        },
      });

      // if the reporting group is chosen, create relationship for the
      // reporting group selected with the newly created method
      if (formValues?.reportingGroupId?.length > 0 && createdEstimationMethod) {
        await Promise.all(
          formValues.reportingGroupId.map((reportingGroupId: string) =>
            ReportingGroupDomain.addEstimationMethodToReportingGroup({
              id: reportingGroupId,
              data: { estimationMethodId: createdEstimationMethod.id },
            })
          )
        );
      }

      // show user feedback
      toast.push({
        intent: "success",
        description: `Successfully ${
          mutationType === "create" ? "created" : "updated"
        } estimation method ${formValues.name}`,
      });

      onSubmit?.(formValues);
    } catch (err) {
      console.error(err);
      toast.push({
        intent: "error",
        description: `Could not ${
          mutationType === "create" ? "create" : "update"
        } estimation method ${formValues.name}. Something went wrong.`,
      });
    }
  };

  const handleCancel = () => {
    onCancel?.();
  };

  const footer = (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: 16,
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <Button
        onClick={handleCancel}
        disabled={isSubmitting}
      >
        Cancel
      </Button>
      <Button
        variant="primary"
        type="submit"
        onClick={formProps.handleSubmit(handleSubmit)}
        disabled={isSubmitting}
      >
        Submit
      </Button>
    </div>
  );

  return {
    formProps,
    footer,
  };
}

export type UseCreateEstimationMethodFormProps = {
  entityId: string;
  entityType: AssetTypeType;
  defaultValues?: any; // TODO: use Zod in the future to set safe defaultValues and typing
  mutationType: "create" | "update";
  onSubmit?: (formValues: CreateEstimationMethodFormValues) => void;
  onCancel?: () => void;
};

export interface CreateEstimationMethodFormValues {
  name: string;
  libraryId: string;
  calculatorId: string;
  reportingGroupId?: string[];
}
