import {
  FormSchemaQuestionType,
  FormSchemaType,
  FormSubmissionAnswersBySectionMapType,
  FormSubmissionAnswerType,
  FormSubmissionType,
  MeasurementUnitType,
} from "@validereinc/domain";
import isEmpty from "lodash/isEmpty";
import isPlainObject from "lodash/isPlainObject";
import { type ReactNode } from "react";

export const getQuestionAnswerPairsBySection = (
  formSubmission?: FormSubmissionType,
  formSchema?: FormSchemaType,
  {
    formatQuestionAnswer,
    measurementUnits,
  }: {
    formatQuestionAnswer: (
      question: FormSchemaQuestionType,
      sectionId: string,
      sectionIdx: number,
      answer: FormSubmissionAnswerType,
      {
        allQuestions,
        allAnswers,
        measurementUnits,
      }: {
        allQuestions: FormSchemaType["config"]["questions"];
        allAnswers: FormSubmissionAnswersBySectionMapType;
        measurementUnits: MeasurementUnitType[];
      }
    ) => ReactNode;
    measurementUnits: MeasurementUnitType[];
  } = { formatQuestionAnswer: () => null, measurementUnits: [] }
) => {
  const QuestionAnswerPairsBySection: Array<{
    section: {
      name: string;
      description: string;
      id: string;
      questions: string[];
      is_repeatable?: boolean;
    };
    data: Array<{
      title?: string;
      value: string | number | boolean | string[] | number[] | ReactNode;
    }>;
  }> = [];

  if (!formSchema || !formSubmission) {
    return null;
  }

  /*
        Algo to stitch together the data:
        1. iterate through form_schema.config.sections to maintain original section order
        2. iterate through answers[id] to capture repeated sections and in the correct order
        3. filter out any questions that weren't answered, but maintain correct question order
        4. add a number to panel title similar to create form if multiple repeated sections
      */
  formSchema.config.sections.forEach((uniqueSection) => {
    formSubmission.answers[uniqueSection.id].forEach((section, fieldIndex) => {
      if (!section || !isPlainObject(section)) {
        return;
      }

      const answeredQuestionIds = Object.keys(section);
      const answeredQuestions = uniqueSection.questions.filter((questionId) =>
        answeredQuestionIds.includes(questionId)
      );
      const sectionData = answeredQuestions.map((questionId) => {
        return {
          title: formSchema.config.questions[questionId].prompt,
          value: formatQuestionAnswer(
            formSchema.config.questions[questionId],
            uniqueSection.id,
            fieldIndex,
            section[questionId],
            {
              allQuestions: formSchema.config.questions,
              allAnswers: formSubmission.answers,
              measurementUnits,
            }
          ),
        };
      });

      const displayedSectionName =
        formSubmission.answers[uniqueSection.id].length > 1
          ? `${uniqueSection.name} ${fieldIndex + 1}`
          : uniqueSection.name;

      if (!isEmpty(section)) {
        QuestionAnswerPairsBySection.push({
          section: { ...uniqueSection, name: displayedSectionName },
          data: sectionData,
        });
      }
    });
  });

  return QuestionAnswerPairsBySection;
};
