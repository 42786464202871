import { InputSourceFlair } from "#batteries-included-components/Flairs/InputSource";
import { RoutingLink } from "#batteries-included-components/RoutingLink";
import { QUERY_STATUS } from "#constants";
import {
  useGetOneFlow,
  useListFlowProductCategories,
  useListFlowProductTypes,
  useListFlowTypes,
} from "#hooks/adapters/useFlows";
import { useGetEstimationMethodRun } from "#hooks/useEstimationMethod";
import { useParams } from "#routers/hooks";
import { FlowDetailRoute } from "#routes/organization/flows/[flowId]/detail";
import { FlowEstimationMethodResultDetailRoute } from "#routes/organization/flows/[flowId]/detail/estimation-details/[estimationMethodId]/result/[yearMonth]/index";
import { useActionDetails } from "#src/components/hooks/useActionDetails";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import useLocalization from "#src/hooks/useLocalization";
import type { FlowEstimationMethodDetailParamsType } from "#src/routes/organization/flows/[flowId]/detail/estimation-details/[estimationMethodId]";
import { useBreadcrumbsFromRoute } from "#utils/route";
import { useQuery } from "@tanstack/react-query";
import {
  KeyValuePanel,
  MathDataDisplayEquationsWithBreakdown,
  MetricTileGrid,
  NumberDataDisplay,
  Page,
  Panel,
  Pill,
} from "@validereinc/common-components";
import {
  AssetType,
  CalculatorDomain,
  CalculatorResultsDomain,
  EstimationMethodDomain,
  Resources,
  type CalculationParameterSavedType,
} from "@validereinc/domain";
import {
  DateFormats,
  formatNumberValueAndUnit,
  monthFormatter,
} from "@validereinc/utilities";
import { parse } from "date-fns";
import React, { useMemo } from "react";

export const FlowEstimationMethodResultDetailPage = () => {
  const { estimationMethodId, flowId, yearMonth } = useParams<
    FlowEstimationMethodDetailParamsType & { yearMonth: string }
  >();
  const { localize } = useLocalization();
  const {
    getUnitName,
    getTypeName,
    getPrecisionByType,
    getUnitByType,
    isLoading: isMeasurementTypesLoading,
  } = useMeasurementTypes();

  const { data: flow, isLoading: isFlowLoading } = useGetOneFlow({
    flowId,
    period: yearMonth,
  });
  const flowTypes = useListFlowTypes().data ?? [];
  const productCategories = useListFlowProductCategories().data ?? [];
  const productTypes = useListFlowProductTypes().data ?? [];
  const methodDetailsQuery = useQuery({
    queryKey: [
      Resources.ESTIMATION_METHOD,
      estimationMethodId,
      { assetType: AssetType.FLOW, period: yearMonth },
    ],
    queryFn: () => {
      return EstimationMethodDomain.getOne({
        id: estimationMethodId,
        meta: {
          entityType: AssetType.FLOW,
        },
        ...(yearMonth
          ? {
              data: {
                period: yearMonth,
              },
            }
          : {}),
      });
    },
    enabled: !!estimationMethodId,
  });
  const calculatorDetailsQuery = useQuery({
    queryKey: [
      Resources.CALCULATOR,
      methodDetailsQuery.data?.analytics_calculator_id,
    ],
    queryFn: () => {
      if (!methodDetailsQuery.data?.analytics_calculator_id) return null;

      return CalculatorDomain.getCalculator({
        calculatorId: methodDetailsQuery.data.analytics_calculator_id,
      });
    },
    enabled: !!methodDetailsQuery.data?.analytics_calculator_id,
  });

  const resultPeriod = useMemo(
    () => parse(yearMonth, DateFormats.YEAR_MONTH, new Date()),
    [yearMonth]
  );

  const methodDetail = methodDetailsQuery?.data;
  const calculatorDetail = calculatorDetailsQuery?.data;

  const resultSummaryParams = {
    filters: {
      "estimation_method.id": estimationMethodId,
      year_month: yearMonth,
    },
  };

  const resultSummaryQuery = useQuery({
    queryKey: ["calculatorResults", resultSummaryParams],
    queryFn: () => CalculatorResultsDomain.getList(resultSummaryParams),
  });

  const { data: resultDetail, status: resultDetailFetchStatus } =
    useGetEstimationMethodRun(methodDetail?.id, resultPeriod, AssetType.FLOW);

  const lastRunInfo = useActionDetails({
    type: "run",
    date: resultDetail?.updated_at,
    byUserId: resultDetail?.updated_by,
  });
  const [breadcrumbs] = useBreadcrumbsFromRoute(
    FlowEstimationMethodResultDetailRoute,
    {
      detail: { title: flow?.name },
      estimationMethodId: { title: methodDetail?.name },
      yearMonth: { title: monthFormatter(resultPeriod) },
    }
  );

  const lastCalculatorVersion = calculatorDetail?.versions.find(
    ({ version }) => version === calculatorDetail?.default_version
  );

  const resultsAsMetricCardProps = useMemo(() => {
    const results = resultSummaryQuery.data?.data[0]?.measurement;

    if (
      !results ||
      !resultDetail?.output.outputs ||
      !Array.isArray(resultDetail?.output?.outputs)
    )
      return [];

    return Object.entries(results).map(([key, value]) => ({
      title: getTypeName(key),
      description: resultDetail?.output.outputs.find(
        ({ measurement_type }) => measurement_type === key
      )?.description,
      value: (props) => {
        const precision = getPrecisionByType(key);

        return (
          <NumberDataDisplay
            {...props}
            value={value}
            formattingOpts={
              precision
                ? { fractionDigits: precision }
                : {
                    overrideOpts: {
                      maximumFractionDigits: 3,
                      minimumFractionDigits: 2,
                    },
                  }
            }
          />
        );
      },
      unit: getUnitName(getUnitByType(key)),
    }));
  }, [resultDetail, resultSummaryQuery.data]);

  const flowDetailsAsKeyValuePanelData = useMemo(() => {
    if (!flow) {
      return [];
    }

    const hasReportingGroups =
      methodDetail?.reporting_groups &&
      methodDetail.reporting_groups.length > 0;

    return [
      {
        title: "Reporting Scenarios",
        value: !hasReportingGroups ? null : (
          <>
            {methodDetail?.reporting_groups?.map((group) => (
              <Pill
                isCapitalized={false}
                key={group.name}
                variant="primary"
                isBordered
                hasDot={false}
              >
                {group.name.trim()}
              </Pill>
            ))}
          </>
        ),
        description: hasReportingGroups
          ? undefined
          : "Edit this estimation method to associate reporting scenarios",
      },
      {
        title: `${localize("Flow")}`,
        value: (
          <RoutingLink to={FlowDetailRoute.toLink({ pathParams: { flowId } })}>
            {flow.name ?? "-"}
          </RoutingLink>
        ),
      },
      {
        title: `${localize("Flow")} Type`,
        value: flow.type
          ? flowTypes?.find(({ id }) => id === flow.type)?.name
          : "-",
      },
      {
        title: "Product Type",
        value: flow.product_type
          ? productTypes?.find(({ id }) => id === flow.product_type)?.name
          : "-",
      },
      {
        title: "Product Category",
        value: flow.product_category
          ? productCategories?.find(({ id }) => id === flow.product_category)
              ?.name
          : "-",
      },
      {
        title: "Calculator Name",
        value: lastCalculatorVersion?.title,
      },
    ];
  }, [
    flow,
    methodDetail,
    flowTypes,
    productTypes,
    productCategories,
    lastCalculatorVersion,
  ]);

  const [userInputsAsKeyValuePanelData, defaultInputsAsKeyValuePanelData] =
    useMemo(() => {
      if (
        !resultDetail?.configuration_input ||
        !Array.isArray(resultDetail?.input?.calculation_parameters)
      ) {
        return [[], []];
      }

      const {
        configuration_input: configuredInputs,
        input: { calculation_parameters: calculationParameters },
      } = resultDetail;

      const getDisplayValue = (
        calculationInputAsOutput: CalculationParameterSavedType
      ) => {
        const {
          type,
          measurement_value: value,
          measurement_unit: unit,
        } = calculationInputAsOutput ?? {};

        switch (type) {
          case "number":
            return (
              formatNumberValueAndUnit(
                {
                  value,
                  unit: getUnitName(unit),
                },
                {
                  overrideOpts: {
                    maximumFractionDigits: 3,
                  },
                }
              ) ?? "-"
            );
          case "boolean":
          case "string":
          default:
            return String(value) ?? "-";
        }
      };

      return calculationParameters.reduce<
        [
          Array<{ title: string; value: string }>,
          Array<{ title: string; value: string }>,
        ]
      >(
        ([userData, defaultData], parameter) => {
          const { id } = parameter ?? {};
          // if the current parameter is found in the configured inputs, then this input was user-configured
          const isManuallyConfigured = Object.keys(configuredInputs).find(
            (configuredInputId) => configuredInputId === id
          );
          const item = {
            title: parameter.display_name,
            value: (
              <>
                <span>{getDisplayValue(parameter)}</span>
                <InputSourceFlair
                  inputParameter={parameter}
                  estimationMethodRun={resultDetail}
                />
              </>
            ),
          };

          if (isManuallyConfigured) {
            userData.push(item);
          } else {
            defaultData.push(item);
          }

          return [userData, defaultData];
        },
        [[], []]
      );
    }, [resultDetail]);

  return (
    <Page
      breadcrumbs={breadcrumbs}
      category="Calculation Result"
      title={
        <>
          {monthFormatter(resultPeriod)}&nbsp;
          <Pill
            variant="success"
            hasDot={false}
          >
            Active
          </Pill>
        </>
      }
      isLoading={
        methodDetailsQuery.isLoading ||
        calculatorDetailsQuery.isLoading ||
        isFlowLoading ||
        resultSummaryQuery.isLoading ||
        isMeasurementTypesLoading
      }
      renderMeta={
        !lastRunInfo.description
          ? undefined
          : ({ MetaSegments }) => (
              <MetaSegments values={[lastRunInfo.description]} />
            )
      }
    >
      {resultDetail ? (
        <>
          <Panel
            title="Results"
            isFluidY={false}
          >
            <MetricTileGrid
              data={resultsAsMetricCardProps}
              isLoading={resultDetailFetchStatus === QUERY_STATUS.LOADING}
            />
          </Panel>

          <KeyValuePanel
            panelMaxColumnCount={2}
            panelProps={{
              title: "Flow Details",
              isFluidY: false,
            }}
            data={flowDetailsAsKeyValuePanelData}
          />

          {userInputsAsKeyValuePanelData.length ? (
            <KeyValuePanel
              panelMaxColumnCount={2}
              panelProps={{
                title: "User Inputs",
                isFluidY: false,
              }}
              data={userInputsAsKeyValuePanelData}
            />
          ) : null}

          {defaultInputsAsKeyValuePanelData.length ? (
            <KeyValuePanel
              panelMaxColumnCount={2}
              panelProps={{
                title: "Default Inputs",
                isFluidY: false,
              }}
              data={defaultInputsAsKeyValuePanelData}
            />
          ) : null}

          {typeof lastCalculatorVersion?.documentation === "object" &&
          Object.keys(lastCalculatorVersion?.documentation ?? {}).length ? (
            <Panel isFluidY={false}>
              <MathDataDisplayEquationsWithBreakdown
                title={lastCalculatorVersion?.documentation.title}
                sourceLink={lastCalculatorVersion?.documentation.link}
                equations={lastCalculatorVersion?.documentation.calculations.map(
                  (calc) => ({
                    equation: calc.equation,
                    reference: calc.reference,
                    terms: calc.conditions.map((cond) => ({
                      math: cond.variable_name,
                      description: cond.variable_description,
                    })),
                  })
                )}
                isLoading={calculatorDetailsQuery.isLoading}
              />
            </Panel>
          ) : null}
        </>
      ) : null}
    </Page>
  );
};
