import DataMappingService from "#components/Services/DataMappingService";
import useTableState from "#src/hooks/useTableState";
import { getFrontendTableState } from "#utils/frontendTableActions";
import {
  DataTable,
  Panel,
  Pill,
  SortingType,
} from "@validereinc/common-components";
import { SortDirection } from "@validereinc/domain";
import get from "lodash/get";
import startCase from "lodash/startCase";
import * as PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

const sorting: SortingType = {
  sortBy: "op_type",
  sortDirection: SortDirection.ASCENDING,
};

const TaskPreviewTable = ({ status, title }) => {
  const { taskId } = useParams();
  const [entityCategories, setEntityCategories] = useState([]);
  const [response, setReponse] = useState();

  const onFetchData = useCallback(
    async ({ ...newSearchParams }) => {
      let newResponse = response;

      if (!response) {
        const previewResponse = await DataMappingService.getTaskPlanPreview({
          taskId,
        });

        newResponse = {
          ...previewResponse,
          data: {
            data: previewResponse.data.entity_list.map(
              ({ entity_data, op_type }) => {
                const data =
                  typeof entity_data === "string"
                    ? JSON.parse(entity_data)
                    : entity_data;

                return {
                  ...data,
                  op_type,
                };
              }
            ),
          },
        };

        setReponse(newResponse);
      }

      const searchParamsWithDefaults = {
        ...newSearchParams,
        sort: newSearchParams.sort ?? sorting.sortBy,
        sortDirection: newSearchParams.sortDirection ?? sorting.sortDirection,
      };

      return getFrontendTableState({
        data: newResponse,
        itemsKey: "data.data",
        query: searchParamsWithDefaults,
      });
    },
    [response]
  );

  const { tableProps } = useTableState({
    onFetchData,
    initialSort: sorting,
  });

  useEffect(() => {
    if (!status.mapping_entity) {
      return;
    }

    (async () => {
      const { data } = await DataMappingService.getEntityCategories({
        entityCategory: status.mapping_entity,
        dataMappingId: status.data_mapping_id,
      });

      setEntityCategories(data.attrs);
    })();
  }, [status]);

  const entityHeaders = useMemo(() => {
    return entityCategories.map(({ field_name }) => ({
      label: startCase(field_name),
      key: field_name,
      renderComponent: ({ item }) => {
        const value = get(item, field_name);

        switch (typeof value) {
          case "object":
            if (Array.isArray(value)) {
              return value.join(", ");
            }

            return Object.entries(value)
              .map(([key, value]) => `${key}: ${value}`)
              .join(", ");
          default:
            return value ?? "-";
        }
      },
    }));
  }, [entityCategories]);

  const headers = useMemo(
    () => [
      {
        label: "Row Status",
        key: "op_type",
        renderComponent: ({ item }) => (
          <Pill variant={item.op_type === "new" ? "success" : "error"}>
            {item.op_type === "new" ? "Created" : "Skipped"}
          </Pill>
        ),
        isSortable: true,
      },

      ...entityHeaders,
    ],
    [entityHeaders]
  );

  return (
    <Panel title={title}>
      <DataTable
        headers={headers}
        {...tableProps}
      />
    </Panel>
  );
};

TaskPreviewTable.propTypes = {
  status: PropTypes.object.isRequired,
  title: PropTypes.string,
};

export default TaskPreviewTable;
