import {
  DEFAULT_INVALIDATE_OPTIONS,
  DEFAULT_QUERY_OPTIONS,
  UseMutationCallbackType,
} from "#hooks/adapters/adapterUtils";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { PillProps, useToast } from "@validereinc/common-components";
import {
  AssetGroupDomain,
  AssetGroupStatus,
  AssetGroupStatusType,
  AssetGroupType,
  CreateNetworkType,
  NetworkDomain,
  UpdateNetworkType,
} from "@validereinc/domain";
import pick from "lodash/pick";

export const NETWORKS_QUERY_KEY = "networks";
export const NetworkStatusPillVariants: Record<
  AssetGroupStatusType,
  PillProps["variant"]
> = {
  [AssetGroupStatus.ACTIVE]: "success",
  [AssetGroupStatus.INACTIVE]: "default",
} as const;

export const useGetOneNetwork = (
  networkId?: string,
  meta: { period?: string } = {}
) =>
  useQuery({
    queryKey: [NETWORKS_QUERY_KEY, "getOne", networkId, meta] as const,
    queryFn: ({ queryKey: [_, __, id, meta] }) =>
      NetworkDomain.getNetwork({ id: id ?? "", meta }),
    enabled: !!networkId,
    ...DEFAULT_QUERY_OPTIONS,
  });

export const useListNetworks = (
  apiParams: Parameters<typeof AssetGroupDomain.getList>[0] = {},
  options: {
    select?: any;
    enabled?: boolean;
  } = {}
) =>
  useQuery({
    queryKey: [
      NETWORKS_QUERY_KEY,
      "getList",
      { ...apiParams, type: { $exact: "network" } },
    ] as const,
    queryFn: ({ queryKey: [_, __, params] }) =>
      AssetGroupDomain.getList(params),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  });

export const useCreateNetwork = ({
  onSuccess,
}: UseMutationCallbackType<AssetGroupType> = {}) => {
  const { toast } = useToast();
  const { invalidate } = useClearNetworksCache();

  return useMutation({
    mutationFn: async ({
      name,
      status,
      reporting_group_id,
      custom_attributes,
      flow_ids,
    }: CreateNetworkType) =>
      NetworkDomain.createNetwork({
        name,
        status,
        reporting_group_id,
        custom_attributes,
        flow_ids,
      }),
    onSuccess: (data, variables, context) => {
      toast.push({
        intent: "success",
        description: `Successfully created network`,
      });
      invalidate();
      onSuccess?.(data, variables, context);
    },
    onError: () => {
      toast.push({
        intent: "error",
        description: "Failed to create network",
      });
    },
  });
};

export const useUpdateNetwork = ({
  onSuccess,
}: UseMutationCallbackType<AssetGroupType> = {}) => {
  const { toast } = useToast();
  const { invalidate } = useClearNetworksCache();

  return useMutation({
    mutationFn: async (updateParams: UpdateNetworkType) => {
      const requests: Array<Promise<any>> = [];

      const newPrimaryValues = pick(updateParams, [
        "name",
        "status",
        "reporting_group_id",
        "custom_attributes",
        "start_date",
        "end_date",
      ]);

      if (Object.keys(newPrimaryValues).length) {
        requests.push(
          NetworkDomain.updateNetwork({
            assetGroupId: updateParams.assetGroupId,
            ...newPrimaryValues,
          })
        );
      }

      if (updateParams?.flow_ids) {
        requests.push(
          AssetGroupDomain.assets.update({
            assetGroupId: updateParams.assetGroupId,
            assetIds: updateParams.flow_ids,
          })
        );
      }
      const [updateResult] = await Promise.all(requests);
      return updateResult;
    },
    onSuccess: async (data, variables, context) => {
      toast.push({
        intent: "success",
        description: `Successfully updated network`,
      });
      invalidate();
      onSuccess?.(data, variables, context);
    },
    onError: () => {
      toast.push({
        intent: "error",
        description: "Failed to update network",
      });
    },
  });
};

export const useDeleteNetwork = ({
  onSuccess,
}: UseMutationCallbackType = {}) => {
  const { toast } = useToast();
  const { invalidate } = useClearNetworksCache();

  return useMutation({
    mutationFn: (networkId: string) =>
      NetworkDomain.deleteNetwork({
        assetGroupId: networkId,
      }),
    onSuccess: (data, variables, context) => {
      toast.push({
        intent: "success",
        description: `Network has been successfully deleted.`,
      });
      invalidate();
      onSuccess?.(data, variables, context);
    },
  });
};

export const useMigrateNetwork = () => {
  const { toast } = useToast();
  const { invalidate } = useClearNetworksCache();

  return useMutation({
    mutationFn: (networkId: string) =>
      AssetGroupDomain.migrate({
        assetGroupId: networkId,
      }),
    onSuccess: async () => {
      toast.push({
        intent: "success",
        description: `Network has been successfully migrated.`,
      });
      invalidate();
    },
    onError: () => {
      toast.push({
        intent: "error",
        description: `Network migration failed.`,
      });
    },
  });
};

export const useClearNetworksCache = () => {
  const queryClient = useQueryClient();
  return {
    invalidate: () => {
      queryClient.invalidateQueries({
        queryKey: [NETWORKS_QUERY_KEY],
        ...DEFAULT_INVALIDATE_OPTIONS,
      });
    },
  };
};
