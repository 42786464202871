import AlertingService from "#components/Services/AlertingService";
import {
  Button,
  Form,
  Modal,
  RadioInput,
  Title,
  useForm,
  useToast,
} from "@validereinc/common-components";
import classNames from "classnames/bind";
import truncate from "lodash/truncate";
import * as PropTypes from "prop-types";
import React from "react";
import { MAX_ALERT_ID_LENGTH } from "./AlertDetail";
import styles from "./CloseAlertModal.module.scss";

const cx = classNames.bind(styles);

const CLOSE_REASON_OPTIONS = ["Invalid Alert", "Maintenance in Progress"];

const CloseAlertModal = ({ open, onClose, alertId }) => {
  const { toast } = useToast();

  const form = useForm();

  const handleClose = async (values) => {
    const alertName = `Alert ${truncate(alertId, {
      length: MAX_ALERT_ID_LENGTH,
    })}`;

    try {
      await AlertingService.resolveAlert(alertId, values);

      toast.push({
        intent: "success",
        description: `Successfully closed alert "${alertName}".`,
      });

      onClose();
    } catch (err) {
      toast.push({
        intent: "error",
        description: `Unable to close "${alertName}".`,
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      size="small"
    >
      <Modal.Header>
        <Title>Close Alert</Title>
      </Modal.Header>

      <Form
        {...form}
        onSubmit={handleClose}
      >
        <Modal.Body>
          <div className={cx("formContainer")}>
            <RadioInput
              label="Reason"
              name="reason"
              isRequired
              options={CLOSE_REASON_OPTIONS}
            />
          </div>
        </Modal.Body>

        <Modal.Footer className="clearfix modal-footer">
          <Button onClick={onClose}>Cancel</Button>

          <Button
            type="submit"
            variant="error"
          >
            Close
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

CloseAlertModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  alertId: PropTypes.string.isRequired,
};

export default CloseAlertModal;
