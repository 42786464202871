import { useFetchInventories } from "#components/Inventories/Inventory";
import { ensureStreamListIsFetched, fetchSiteList } from "#redux/actions/index";
import { havePermission } from "#redux/reducers/permissions";
import { getBreadcrumbsObject } from "#routers/breadcrumbsHelper";
import history from "#routers/history";
import { linkToBalances } from "#routers/links";
import BalancesServices from "#services/BalancesService";
import {
  Button,
  Form,
  FormButton,
  Page,
  Panel,
  useForm,
  useToast,
} from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useFetchBalanceList } from "./BalanceHelper";
import BalancesForm from "./BalancesForm";
import {
  DEFAULT_SOURCE_INPUTS,
  DEFAULT_SOURCE_SELECTION,
} from "./BalancesUpsertsConstant";
import "./CreateBalances.scss";
import useManageSourceInput from "./SourceSelection/useManageSourceInput";

const mapStateToProps = ({ permissions, streams, sites }) => {
  return {
    haveWritePermission: havePermission(permissions)(
      "gas:accounting.balances",
      "write"
    ),
    streams: streams?.data.toJS() ?? [],
    sites: sites?.data.toJS() ?? [],
  };
};

const mapDispatchToProps = {
  ensureStreamListIsFetched,
  fetchSiteList,
};

const CreateBalances = ({
  streams,
  sites,
  ensureStreamListIsFetched,
  fetchSiteList,
  match,
  breadcrumbs,
  haveWritePermission,
}) => {
  const [formState, setFormState] = useState("enabled");
  const [balanceId, setBalanceId] = useState("");
  const [lastUpdatedBalanceList, setLastUpdatedBalanceList] = useState(
    new Date()
  );
  const [balancesList] = useFetchBalanceList(lastUpdatedBalanceList);

  const [inventories] = useFetchInventories();
  const { toast } = useToast();

  const form = useForm({
    defaultValues: DEFAULT_SOURCE_INPUTS,
  });

  const [
    lastUpdatedTime,
    onSourceChecked,
    editSourceClick,
    openSourceSelectionModal,
    sourceSelectionModal,
  ] = useManageSourceInput(streams, sites, inventories, form);

  const onBalanceSubmit = (inputs) => {
    setFormState("loading");

    BalancesServices.createBalances(inputs, DEFAULT_SOURCE_SELECTION)
      .then(({ data }) => {
        setBalanceId(data?.id);
        setFormState("preview");

        toast.push({
          intent: "success",
          description: "Balance has been successfully created.",
        });
      })
      .catch(() => {
        setFormState("enabled");
      });
  };

  const onAddAnotherBalanceClick = () => {
    setLastUpdatedBalanceList(new Date());
    form.reset();
    setFormState("enabled");
  };

  const onViewBalanceClick = () => {
    history.push(linkToBalances(balanceId));
  };

  useEffect(() => {
    ensureStreamListIsFetched();
    fetchSiteList();
  }, []);

  return (
    <Page
      title="Create Balance"
      breadcrumbs={getBreadcrumbsObject(breadcrumbs, match.params)}
    >
      <Panel className="createBalanceContainer">
        <Form
          onSubmit={onBalanceSubmit}
          {...form}
        >
          <BalancesForm
            form={form}
            formState={formState}
            balancesList={balancesList}
            sourceSelectionOptions={DEFAULT_SOURCE_SELECTION}
            haveWritePermission={haveWritePermission}
            openSourceSelectionModal={openSourceSelectionModal}
            onSourceChecked={onSourceChecked}
            editSourceClick={editSourceClick}
            lastUpdatedTime={lastUpdatedTime}
            disabled={formState !== "enabled"}
          />

          <div className="createBalanceButtonContainer clearfix">
            {formState === "preview" ? (
              <>
                <Button
                  variant="primary"
                  className="pull-right"
                  onClick={onViewBalanceClick}
                >
                  View Balance
                </Button>
                <Button
                  className="pull-right"
                  onClick={onAddAnotherBalanceClick}
                >
                  Create Another Balance
                </Button>
              </>
            ) : (
              <FormButton
                variant="primary"
                className="pull-right"
                type="submit"
                disabled={formState !== "enabled"}
                isLoading={formState === "loading"}
              >
                Create Balance
              </FormButton>
            )}
          </div>
        </Form>
      </Panel>

      {sourceSelectionModal}
    </Page>
  );
};

CreateBalances.propTypes = {
  haveWritePermission: PropTypes.bool.isRequired,
  streams: PropTypes.array.isRequired,
  sites: PropTypes.array.isRequired,
  ensureStreamListIsFetched: PropTypes.func.isRequired,
  fetchSiteList: PropTypes.func.isRequired,
  addAlertMessage: PropTypes.func.isRequired,
  breadcrumbs: PropTypes.array,
  match: PropTypes.object,
};

const CreateBalancesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateBalances);

export default CreateBalancesContainer;
