import { EMISSIONS_HOME_FILTERS } from "#batteries-included-components/Layouts/HomeEmissions/EmissionsHomeFilterPanel";
import { POLLUTANTS } from "#src/batteries-included-components/Dropdowns/PollutantsDropdown";
import { MASS_POLLUTANTS } from "#src/constants";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { getIntervalInMonthsFormatted, hydrateDateRange } from "#utils/date";
import { useQuery } from "@tanstack/react-query";
import {
  Panel,
  PieChart,
  StorageKeys,
  useFilters,
  useToast,
} from "@validereinc/common-components";
import { CalculatorResultsDomain } from "@validereinc/domain";
import { formatNumberValueAndUnit } from "@validereinc/utilities";
import classNames from "classnames/bind";
import React, { useMemo } from "react";
import { patchMissingDataWithZeros } from "../utils/patch-missing-data-with-zeros";
import styles from "./PollutantPieChartPanel.module.scss";
import { getTopNumberList } from "./top-number-list-util";

type DataPoint = {
  label: string;
  value: number;
};

const cx = classNames.bind(styles);

const defaultTopListConfig = { top: 10, hasOther: true };

export const PollutantPieChartPanel = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  const { toast } = useToast();
  const { getUnitByType, getPrecisionByType, getUnitName } =
    useMeasurementTypes();
  const [
    {
      [EMISSIONS_HOME_FILTERS.dateRange.name]: dateRange,
      [EMISSIONS_HOME_FILTERS.pollutant.name]: pollutant,
      [EMISSIONS_HOME_FILTERS.reportingScenario.name]: reportingGroup,
    },
  ] = useFilters(filterConfigStorageKey);

  const { mass, label } = MASS_POLLUTANTS.find(
    ({ dataKey }) => dataKey === pollutant
  ) || { mass: POLLUTANTS[0].dataKey, label: POLLUTANTS[0].label };
  const precision = getPrecisionByType(mass);
  const unit = getUnitName(getUnitByType(mass));
  const onError = () =>
    toast.push({
      intent: "error",
      description:
        "There was an error fetching your emissions by equipment type",
    });

  const request = {
    sortBy: `measurement.${mass}`,
    sortDirection: "desc",
    pageSize: 25,
    filters: {
      measurement_type: {
        $in: ["mass_ch4", "mass_co2", "mass_co2eq", "mass_n2o"],
      },
      reporting_group: reportingGroup,
      year_month: {
        $in: getIntervalInMonthsFormatted(hydrateDateRange(dateRange)),
      },
      isAlreadyFormatted: true,
    },
    groupBy: ["equipment.type.name"],
  };

  const { data } = useQuery({
    queryKey: ["calculatorResults", request],
    queryFn: () => CalculatorResultsDomain.getList(request),
    select: ({ data }) => data,
    enabled: !!reportingGroup && !!dateRange,
    onError,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const equipmentEmissions = useMemo(() => {
    if (!data) {
      return [];
    }
    const patchedData = patchMissingDataWithZeros(data);
    const filteredData = getTopNumberList(
      patchedData,
      defaultTopListConfig.top,
      defaultTopListConfig.hasOther
    );
    return filteredData.map((record) => ({
      label: record["equipment.type.name"],
      value: record.measurement[mass],
    }));
  }, [data, pollutant]);

  const title = `Emissions Split by ${label}`;

  return (
    <Panel title={title}>
      <div className={cx("pieChart")}>
        <PieChart<DataPoint>
          data={equipmentEmissions}
          labelAccessor={({ label }: DataPoint) => label}
          valueAccessor={({ value }: DataPoint) => value}
          valueFormatter={(value: number) =>
            formatNumberValueAndUnit(
              { value, unit },
              { overrideOpts: { minimumFractionDigits: precision ?? 0 } }
            ) ?? "-"
          }
          orientation="vertical"
          parentWidth={300}
          isPieChartCentered
        />
      </div>
    </Panel>
  );
};
