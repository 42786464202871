import AlertService from "#services/AlertService";
import { ValidateNumberInput } from "#utils/formValidator";
import { getMinutesFromSeconds } from "#utils/stringFormatter";
import {
  AlertMessage,
  Button,
  Form,
  FormButton,
  Panel,
  TextInput,
  useForm,
  useToast,
} from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { DEFAULT_INLINE_MONITOR_INPUTS } from "./StreamAlertsFormContants";
import {
  formatFormDataForInlineMonitorsSubmission,
  inlineLimitValidation,
} from "./StreamAlertsFormHelpers";

const InlineMonitorPanel = ({
  measurementInfo,
  disabled,
  existingInlineMonitorData,
  checkedStreams,
  refetchStreams,
  inlineMonitorIds,
  isFetchingAPI,
  isFetchingInlineIds,
  fetchExistingInlineIds,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();
  const form = useForm({
    defaultValues: DEFAULT_INLINE_MONITOR_INPUTS,
  });

  useEffect(() => {
    if (existingInlineMonitorData) {
      form.reset({
        ...existingInlineMonitorData,
        interval_minutes: getMinutesFromSeconds(
          existingInlineMonitorData.interval_seconds
        ),
      });
    } else {
      form.reset(DEFAULT_INLINE_MONITOR_INPUTS);
    }
  }, [existingInlineMonitorData, measurementInfo.name]);

  const onInlineLimitChange = (value, limitType) => {
    const num = Number(value);
    form.setValue(limitType, num);
    form.trigger(["upper_limit", "lower_limit"]);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    const values = form.getValues();
    // convert any value string to number
    const numValues = Object.keys(values).reduce((acc, key) => {
      acc[key] = Number(values[key]);
      return acc;
    }, {});

    const data = formatFormDataForInlineMonitorsSubmission(
      numValues,
      checkedStreams,
      measurementInfo
    );
    AlertService.setInlineMeasurementSetting(data)
      .then(() => {
        toast.push({
          intent: "success",
          description: "Successfully created inline monitor alert.",
        });
        fetchExistingInlineIds();
        refetchStreams();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDelete = () => {
    const isExistingMonitorsExist =
      (inlineMonitorIds && !!inlineMonitorIds.length) ||
      existingInlineMonitorData?.id;
    if (isExistingMonitorsExist) {
      setIsLoading(true);
      const monitorsToDelete =
        existingInlineMonitorData?.id ?? inlineMonitorIds;
      AlertService.deleteInlineMeasurementSetting(monitorsToDelete)
        .then(() => {
          toast.push({
            intent: "success",
            description: "Successfully deleted inline monitor alert.",
          });
          fetchExistingInlineIds();
          refetchStreams();
        })
        .finally(() => {
          setIsLoading(false);
          form.reset(DEFAULT_INLINE_MONITOR_INPUTS);
        });
    } else {
      form.reset(DEFAULT_INLINE_MONITOR_INPUTS);
      toast.push({
        intent: "success",
        description: "Successfully deleted inline monitor alert.",
      });
    }
  };

  const disableForm = disabled || isLoading || isFetchingInlineIds;
  const sharedProps = {
    isDisabled: disableForm,
  };
  const measurementUnit = measurementInfo.unit;

  return (
    <Form
      onSubmit={handleSubmit}
      {...form}
    >
      <Panel
        title="Inline Alerts"
        loaded={!isFetchingAPI}
      >
        {disabled && (
          <AlertMessage type="warning">
            Ensure a measurement and at least one stream is selected above.
          </AlertMessage>
        )}

        <TextInput
          name="num_offspec_threshold"
          label="Off-spec Threshold"
          type="number"
          unit="measurements or more are out of range"
          validate={(numOffspecThreshold) => {
            return ValidateNumberInput(
              "Off-spec Threshold",
              numOffspecThreshold,
              true,
              true
            );
          }}
          {...sharedProps}
        />

        <TextInput
          name="interval_minutes"
          label="Off-spec Interval"
          type="number"
          unit="mins"
          validate={(intervalMinutes) => {
            return ValidateNumberInput(
              "Off-spec Interval",
              intervalMinutes,
              true,
              true
            );
          }}
          {...sharedProps}
        />

        <TextInput
          name="lower_limit"
          label="Lower Limit"
          type="number"
          unit={measurementUnit}
          onChange={(value) => onInlineLimitChange(value, "lower_limit")}
          validate={(limit) => {
            const inputs = form.getValues();
            return inlineLimitValidation(limit, inputs);
          }}
          {...sharedProps}
        />

        <TextInput
          name="upper_limit"
          label="Upper Limit"
          type="number"
          unit={measurementUnit}
          onChange={(value) => onInlineLimitChange(value, "upper_limit")}
          validate={(limit) => {
            const inputs = form.getValues();
            return inlineLimitValidation(limit, inputs);
          }}
          {...sharedProps}
        />

        <div className="clearfix streamAlertsCreate__submitButton">
          <FormButton
            disabled={disableForm}
            variant="primary"
            className="pull-right"
            type="submit"
          >
            Save
          </FormButton>

          <Button
            variant="error-outline"
            onClick={handleDelete}
            className="pull-right streamAlertsCreate__deleteButton"
            disabled={disableForm}
          >
            Delete
          </Button>
        </div>
      </Panel>
    </Form>
  );
};

InlineMonitorPanel.propTypes = {
  disabled: PropTypes.bool.isRequired,
  existingInlineMonitorData: PropTypes.object,
  checkedStreams: PropTypes.array,
  measurementInfo: PropTypes.object,
  refetchStreams: PropTypes.func.isRequired,
  inlineMonitorIds: PropTypes.array,
  isFetchingAPI: PropTypes.bool.isRequired,
  isFetchingInlineIds: PropTypes.bool.isRequired,
  fetchExistingInlineIds: PropTypes.func.isRequired,
};

export default InlineMonitorPanel;
