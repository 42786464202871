import FileService from "#src/components/Services/FileService";
import { FileUploadInput } from "@validereinc/common-components";
import React from "react";

export const FileField = (props) => {
  const onLoad = async (value?: { ref: string; name: string }) => {
    if (!value) {
      return value;
    }

    const response = await FileService.getFileDownloadUrl(value.ref);

    if (!response) {
      throw new Error("Could not get file url");
    }

    const {
      data: {
        data: [fileData],
      },
    } = response;

    if (!fileData) {
      throw new Error("Could not extract file data");
    }

    if (fileData.file_scan_status !== "safe") {
      throw new Error("The file download link is not available.");
    }

    const { uri: downloadUrl } = fileData;

    if (!downloadUrl) {
      throw new Error("The file download URL does not exist.");
    }

    const data = await fetch(downloadUrl).then((r) => r.blob());

    return [new File([data], value.name)];
  };

  const onUploadFile = async (file, { setStatus, signal }) => {
    const { name, type: contentType, lastModifiedDate } = file;

    setStatus({ percentComplete: 0, label: "Uploading..." });

    const {
      status: createFileStatus,
      data: {
        data: [uploadedFileData],
      },
    } = await FileService.createFile({
      name,
      contentType,
      description: "A user-uploaded file through a file input component",
      tags: {
        lastModified: lastModifiedDate.toISOString(),
      },
      signal,
    });

    if (createFileStatus !== 200 && createFileStatus !== 201) {
      throw new Error("Could not create an entry in the file upload service");
    }

    // extract the ID and upload URL of the file entry in the file upload service
    const { file_id: fileId, uri: uploadUrl } = uploadedFileData;

    // upload the file to the pre-signed upload URL
    const { status: uploadFileStatus } = await FileService.uploadFile({
      url: uploadUrl,
      fileBlob: file,
      signal,
    });

    if (uploadFileStatus !== 200 && uploadFileStatus !== 201) {
      throw new Error(
        "Could not upload file to the created entry in file upload service"
      );
    }

    // poll and check to see if the file scanning in the file upload service has completed
    setStatus({ percentComplete: 33, label: "Scanning..." });

    let scanStatus = "unknown";
    let pollLimit = 10;

    while (pollLimit > 0 && !["safe", "unsafe"].includes(scanStatus)) {
      const {
        data: {
          data: [{ file_scan_status }],
        },
      } = await FileService.getFileDownloadUrl(fileId, signal);

      scanStatus = file_scan_status;

      // scan status re-check polling interval
      await new Promise((res) => setTimeout(res, 1000));
      pollLimit -= 1;
    }

    // verify based on the scan result
    setStatus({ percentComplete: 66, label: "Verifying..." });

    if (scanStatus !== "safe") {
      throw new Error(
        "The uploaded file has been deemed as unsafe or failed to complete verification"
      );
    }

    setStatus({ percentComplete: 100, label: "Complete" });

    return {
      ref: fileId,
      name,
    };
  };

  return (
    <FileUploadInput
      {...props}
      showIcon={true}
      transformer={{
        onChangeTransformer: onUploadFile,
        valueTransformer: onLoad,
      }}
    />
  );
};
