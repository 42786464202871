import { z } from "zod";
import {
  DomainModelMetaExtendedSchema,
  DomainModelMetaSchema,
  DomainModelSchema,
  IdAndNameSchema,
} from "./DomainModelSchemas";
import { toStartCaseString } from "@validereinc/utilities";

// Legacy user types and schemas:

export const LegacyUserClaimSchema = z
  .object({
    name: z.string(),
    email: z.string().email(),
    site_ids: z.array(z.number()),
  })
  .merge(DomainModelSchema);

export type LegacyUserClaimType = z.infer<typeof LegacyUserClaimSchema>;

// User v2 (Node API auth) status types and schemas:

export const UserStatus = {
  active: "active",
  disabled: "disabled",
} as const;

export type UserStatusType = (typeof UserStatus)[keyof typeof UserStatus];

export const UserStatusOptions = Object.values(UserStatus).map((status) => ({
  status,
  name: toStartCaseString(status),
}));

// `company_membership` is an object that's only available on /users/search API:

const CompanyMembershipObjectSchema = z.array(
  z
    .object({
      company_id: z.string().uuid(),
    })
    .merge(DomainModelMetaSchema)
    .merge(DomainModelMetaExtendedSchema)
);

export type CompanyMembershipType = z.infer<
  typeof CompanyMembershipObjectSchema
>;

export const UserSchema = z
  .object({
    name: z.string(),
    quicksight: z.boolean(),
    // IMPROVE: make this an enum
    timezone: z.string(),
    // IMPROVE: add phone number regex (depends on country though...which is a data point we currently don't track)
    phone: z.string().nullable(),
    status: z.enum([UserStatus.active, UserStatus.disabled]),
    email: z.string().email(),
    user_groups: z.array(IdAndNameSchema),
    user_groups_count: z.number(),
    roles: z.array(IdAndNameSchema),
    roles_count: z.number(),
  })
  .merge(DomainModelSchema)
  .merge(DomainModelMetaSchema)
  .merge(DomainModelMetaExtendedSchema)
  .describe("Represents a User model in the Node API");

export const UserCreateSchema = UserSchema.pick({
  name: true,
  email: true,
  phone: true,
  timezone: true,
  quicksight: true,
});

export const UserUpdateSchema = UserSchema.pick({
  name: true,
  phone: true,
  timezone: true,
  quicksight: true,
}).partial();

export const CompanyIdSchema = z.object({
  company_id: z.string().uuid(),
});

export type CompanyIdType = z.infer<typeof CompanyIdSchema>;

// All user fields WITHOUT current company_id, companies, or company_membership:
export type UserType = z.infer<typeof UserSchema>;

// All user fields + company_membership. Response type of /users/search API:
export type UserWithCompanyMembershipType = UserType & CompanyMembershipType;

// All user fields + (current) company_id. Response type of /users/{id} (getOne):
export type UserWithCurrentCompanyIdType = UserType & CompanyIdType;

// All user fields + companies + (current) company_id. Response type of /users/myself:
export type UserWithCompaniesAndCurrentCompanyIdType =
  UserWithCurrentCompanyIdType & {
    companies: Array<{ id: string; name: string }>;
  };

export type UserCreateType = z.infer<typeof UserCreateSchema>;
export type UserUpdateType = z.infer<typeof UserUpdateSchema>;
