import { convertMapToOpts } from "#src/constants";
import {
  ControlledDropdownInputType,
  DropdownInput,
} from "@validereinc/common-components";
import formatInTimeZone from "date-fns-tz/formatInTimeZone";
import kebabCase from "lodash/kebabCase";
import React, { useEffect, useState } from "react";

export const TimezoneOptions = Intl.supportedValuesOf("timeZone").reduce<
  Record<string, string>
>((map, tz) => {
  map[kebabCase(tz)] = tz;

  return map;
}, {});

/**
 * Convert a Timezone identifier into an appropriate label
 * @param value a valid timezone identifier, like the ones returned from the Intl Web API
 * @returns the timezone identifier with offset and local time information
 */
export const getTimezoneLabelFromIdentifier = (value: string) =>
  `${formatInTimeZone(new Date(), value, "O")} | ${value} (${formatInTimeZone(
    new Date(),
    value,
    "E p"
  )})`;

export const TimezoneDropdownInput = ({
  name,
  inputId,
  isRequired = true,
}: Pick<ControlledDropdownInputType, "name" | "inputId" | "isRequired">) => {
  const [options, setOptions] = useState(() =>
    convertMapToOpts(TimezoneOptions, ([_, value]) =>
      getTimezoneLabelFromIdentifier(value)
    )
  );

  useEffect(() => {
    const interval = setInterval(
      () =>
        setOptions((prevOpts) =>
          prevOpts.map(({ value }) => ({
            value,
            label: getTimezoneLabelFromIdentifier(value),
          }))
        ),
      1000
    );

    return () => clearInterval(interval);
  }, []);

  return (
    <DropdownInput
      name={name}
      inputId={inputId}
      label="Timezone"
      options={options}
      isSortedAlphabetically
      labelKey="label"
      valueKey="value"
      description="The timezone the user lives in. All dates and times will be localized for this user in this timezone."
      isRequired={isRequired}
    />
  );
};
