import {
  DEFAULT_INVALIDATE_OPTIONS,
  DEFAULT_QUERY_OPTIONS,
  UseMutationCallbackType,
} from "#hooks/adapters/adapterUtils";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@validereinc/common-components";
import {
  CreateOneRequestType,
  DeleteOneRequestType,
  UnitConfigurationType,
  UpdateOneRequestType,
  UserUnitConfigurationAdapter,
} from "@validereinc/domain";

export const USER_UNIT_CONFIG_KEY = "userUnitConfig";

export const useListUserUnitConfig = (
  params: Parameters<typeof UserUnitConfigurationAdapter.getList>[0] = {},
  options: { enabled?: boolean } = {}
) =>
  useQuery({
    queryKey: [USER_UNIT_CONFIG_KEY, params],
    queryFn: () => UserUnitConfigurationAdapter.getList(params),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  });

export const useUserUnitConfigCache = () => {
  const queryClient = useQueryClient();
  return {
    invalidate: () => {
      queryClient.invalidateQueries({
        queryKey: [USER_UNIT_CONFIG_KEY],
        ...DEFAULT_INVALIDATE_OPTIONS,
      });
    },
  };
};

export const useUpdateUserUnitConfig = ({
  onSuccess,
}: UseMutationCallbackType = {}) => {
  const { toast } = useToast();
  const { invalidate } = useUserUnitConfigCache();

  return useMutation({
    mutationFn: async (params: UpdateOneRequestType<UnitConfigurationType>) => {
      await UserUnitConfigurationAdapter.updateOne(params);
    },
    onSuccess: (data, variables, context) => {
      toast.push({
        intent: "success",
        description: "Successfully updated unit preference.",
      });
      invalidate();
      onSuccess?.(data, variables, context);
    },
    onError: () => {
      toast.push({
        intent: "error",
        description: "Failed to update unit preference.",
      });
    },
  });
};

export const useCreateUserUnitConfig = ({
  onSuccess,
}: UseMutationCallbackType = {}) => {
  const { toast } = useToast();
  const { invalidate } = useUserUnitConfigCache();

  return useMutation({
    mutationFn: async (params: CreateOneRequestType<UnitConfigurationType>) => {
      await UserUnitConfigurationAdapter.createOne(params);
    },
    onSuccess: (data, variables, context) => {
      toast.push({
        intent: "success",
        description: "Successfully added unit preference.",
      });
      invalidate();
      onSuccess?.(data, variables, context);
    },
    onError: () => {
      toast.push({
        intent: "error",
        description: "Failed to add unit preference.",
      });
    },
  });
};

export const useDeleteUserUnitConfig = ({
  onSuccess,
}: UseMutationCallbackType = {}) => {
  const { toast } = useToast();
  const { invalidate } = useUserUnitConfigCache();

  return useMutation({
    mutationFn: async (id: DeleteOneRequestType<UnitConfigurationType>) => {
      await UserUnitConfigurationAdapter.deleteOne(id);
    },
    onSuccess: (data, variables, context) => {
      toast.push?.({
        intent: "success",
        description: `Unit Preference has been successfully deleted.`,
      });
      invalidate();
      onSuccess?.(data, variables, context);
    },
  });
};
