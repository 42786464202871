import {
  CreateUserGroupType,
  UpdateUserGroupType,
  UserGroupMembershipType,
  UserGroupType,
  type ReportWithDownloadV2Type,
} from "../schemas";
import {
  CreateOneRequestType,
  CreateOneResponseType,
  DeleteOneRequestType,
  fetchAndCollate,
  FilterObjectType,
  getFilterObject,
  GetListRequestType,
  GetListResponseType,
  GetOneRequestType,
  GetOneResponseType,
  ResourceServiceType,
  ResponseErrorType,
  ResponseStatusType,
  UpdateOneRequestType,
  UpdateOneResponseType,
} from "../util";
import { restAPI } from "./api";

export const UserGroupsAdapter: UserGroupsAdapterType = {
  createOne: async ({ data }) => {
    const resp = await restAPI.nodeAPI.POST<UserGroupType>({
      endpoint: "/user_groups",
      body: data,
    });

    return {
      data: resp,
    };
  },
  getList: ({ page, pageSize, sortBy, sortDirection, filters }) =>
    fetchAndCollate(
      ({ page, pageSize }) =>
        restAPI.nodeAPI.POST<GetListResponseType<UserGroupType>>({
          endpoint: "/user_groups/search",
          body: {
            page,
            page_size: pageSize,
            sort_by: sortBy,
            sort_direction: sortDirection,
            filter: getFilterObject(filters),
          },
        }),
      page,
      pageSize
    ),
  getOne: async ({ id }) => {
    const resp = await restAPI.nodeAPI.GET<UserGroupType>({
      endpoint: `/user_groups/${id}`,
    });

    return {
      data: resp,
    };
  },
  updateOne: async ({ id, data }) => {
    const resp = await restAPI.nodeAPI.PUT<UserGroupType>({
      endpoint: `/user_groups/${id}`,
      body: data,
    });

    return {
      data: resp,
    };
  },
  deleteOne: ({ id }) =>
    restAPI.nodeAPI.DELETE({ endpoint: `/user_groups/${id}` }),

  /**
   * Export user groups list
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/user_groups/export_user_groups
   */
  exportList: ({
    filters,
    sortBy,
    sortDirection,
  }: GetListRequestType & { period?: string }) => {
    return restAPI.nodeAPI.POST<ReportWithDownloadV2Type>({
      endpoint: "/user_groups/export",
      body: {
        sort_by: sortBy,
        sort_direction: sortDirection,
        filter: getFilterObject(filters),
      },
    });
  },

  members: {
    getList: async ({ meta }) => {
      const resp = await restAPI.nodeAPI.GET<UserGroupMembershipType[]>({
        endpoint: `/user_groups/${meta?.userGroupId}/members`,
      });

      return {
        data: resp,
      };
    },
    add: ({ id, meta }) =>
      restAPI.nodeAPI.PUT({
        endpoint: `/user_groups/${meta?.userGroupId}/members/${id}`,
      }),
    remove: ({ id, meta }) =>
      restAPI.nodeAPI.DELETE({
        endpoint: `/user_groups/${meta?.userGroupId}/members/${id}`,
      }),
  },
};

export interface UserGroupsAdapterType
  extends Pick<
    ResourceServiceType<UserGroupType>,
    | "createOne"
    | "getList"
    | "getOne"
    | "updateOne"
    | "deleteOne"
    | "exportList"
  > {
  getList: (
    params: GetListRequestType<FilterObjectType<UserGroupType>>
  ) => Promise<GetListResponseType<UserGroupType>>;
  getOne: (
    params: GetOneRequestType
  ) => Promise<GetOneResponseType<UserGroupType>>;
  updateOne: (
    params: UpdateOneRequestType<UpdateUserGroupType>
  ) => Promise<UpdateOneResponseType<UserGroupType>>;
  createOne: (
    params: CreateOneRequestType<CreateUserGroupType>
  ) => Promise<CreateOneResponseType<UserGroupType>>;
  deleteOne: (
    params: DeleteOneRequestType<UserGroupType>
  ) => Promise<ResponseStatusType | ResponseErrorType>;
  members: {
    getList: (
      params: GetListRequestType<null, { userGroupId: string }>
    ) => Promise<{ data: UserGroupMembershipType[] }>;
    add: (
      params: UpdateOneRequestType<
        null,
        {
          userGroupId: string;
        }
      >
    ) => Promise<void>;
    remove: (
      params: DeleteOneRequestType<
        null,
        {
          userGroupId: string;
        }
      >
    ) => Promise<void>;
  };
}
