export const NON_PRECISION_UNITS = new Set([
  "unitless",
  "decimal",
  "determined by selected fuel unit",
  "hours",
]);

export const NON_DISPLAY_UNITS: Record<string, null> = {
  unitless: null,
  decimal: null,
  "determined by selected fuel unit": null,
};

/**
 * Some units are not for display purposes - use this fn to filter them out.
 * @param unit the unit from the Node back-end
 * @returns the unit or undefined if it shouldn't be displayed
 */
export const getDisplayedUnit = (unit: string) => {
  if (unit && NON_DISPLAY_UNITS[unit] === null) return;

  return unit;
};

/**
 * Some units are not meant to be precise (formatted) - use this fn to filter them out.
 * @param unit the unit from the Node back-end
 * @returns the unit or undefined if it shouldn't be precise
 */
export const getPreciseUnit = (unit?: string | null) => {
  if (unit && NON_PRECISION_UNITS.has(unit)) return;

  return unit;
};
