import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import {
  FilterArea,
  useFilterAreaContentContext,
  useFilterAreaContext,
} from "#src/components/FilterArea";
import { FilterDrawer } from "#src/components/FilterDrawer";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { TemplatedReportsCategoriesDetailRoutePath } from "#src/routes/reports/templated-reports/categories/[categoryId]";
import { TemplatedReportsTemplateDetailRoutePath } from "#src/routes/reports/templated-reports/template/[templateName]";
import { useQueries, useQuery } from "@tanstack/react-query";
import {
  BooleanDataDisplay,
  DataTable,
  DataTablePanel,
  DateSelectorInput,
  DropdownInput,
  IntegerDataDisplay,
  Pill,
  PillToggleGroup,
  PillToggleVariants,
  Tag,
  TextDataDisplay,
  TextInput,
  type HeaderType,
  type PillToggleProps,
} from "@validereinc/common-components";
import {
  ReportV3Adapter,
  Resources,
  SortDirection,
  TemplatedReportAdapter,
  TemplatedReportStatus,
  TemplatedReportWithCategorySchema,
  type TemplatedReportWithCategoryType,
} from "@validereinc/domain";
import { toStartCaseString } from "@validereinc/utilities";
import classNames from "classnames/bind";
import isPlainObject from "lodash/isPlainObject";
import React, { useMemo } from "react";
import styles from "./TemplatedReportTemplatesTablePanel.module.scss";

const cx = classNames.bind(styles);

export const TemplatedReportTemplatesTablePanel = ({
  templatesListFilters,
}: {
  templatesListFilters: Parameters<
    typeof TemplatedReportAdapter.company.getList
  >[0]["filters"];
}) => {
  const { filterConfigStorageKey, tableConfigStorageKey } = useStorageKey(
    "templated-report-all-templates"
  );
  const { filterConfigStorageKey: quickFilterConfigStorageKey } = useStorageKey(
    "templated-report-all-templates-quick-filters"
  );
  const [coreFilters] = useSessionStickyState({}, filterConfigStorageKey);
  const [quickFilters] = useSessionStickyState({}, quickFilterConfigStorageKey);
  const filters = useMemo(
    () => ({ ...coreFilters, ...quickFilters }),
    [coreFilters, quickFilters]
  );
  const [tableState, updateTableState] = useTableSortingAndPagination(
    {
      sortBy: "display_name",
      sortDirection: SortDirection.ASCENDING,
    },
    filters
  );

  const listQueryParams: Parameters<
    typeof TemplatedReportAdapter.company.getList
  >[0] = {
    page: tableState.page,
    pageSize: tableState.pageSize,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: {
      ...templatesListFilters,
      ...filters,
    },
  };
  const { data, isLoading } = useQuery({
    queryKey: [Resources.TEMPLATED_REPORT, listQueryParams],
    queryFn: () => TemplatedReportAdapter.company.getList(listQueryParams),
    staleTime: 10 * 60 * 1000,
  });
  const associatedReportsQueries = useQueries({
    queries:
      data?.data.map((templateDetails) => ({
        queryKey: [
          Resources.REPORT,
          {
            page: 1,
            pageSize: 1,
            sortBy: "created_at",
            sortDirection: "desc",
            filters: {
              "templated_report.name": templateDetails.name,
            },
          } satisfies Parameters<typeof ReportV3Adapter.getList>[0],
        ],
        queryFn: () => {
          return ReportV3Adapter.getList({
            page: 1,
            pageSize: 1,
            sortBy: "created_at",
            sortDirection: "desc",
            filters: {
              "templated_report.name": templateDetails.name,
            },
          });
        },
      })) ?? [],
  });

  const associatedReportsCounts = useMemo(() => {
    return associatedReportsQueries.reduce<Record<string, number>>(
      (mapping, query) => {
        if (
          !!query.data?.data?.[0]?.templated_report_name &&
          !mapping[query.data.data[0].templated_report_name]
        ) {
          mapping[query.data.data[0].templated_report_name] =
            query.data.total_entries;
        }

        return mapping;
      },
      {}
    );
  }, [associatedReportsQueries]);

  const headers: Array<HeaderType<TemplatedReportWithCategoryType>> = [
    {
      label: "Name",
      key: "display_name",
      isSortable: true,
      renderComponent: ({ item }) => (
        <RoutingLink
          to={TemplatedReportsTemplateDetailRoutePath.toLinkParts({
            pathParams: { templateName: item.name },
          })}
        >
          {item.display_name}
        </RoutingLink>
      ),
    },
    {
      label: "Description",
      key: "description",
      isSortable: true,
      renderComponent: ({ item }) => (
        <TextDataDisplay value={item.description} />
      ),
    },
    {
      label: "Status",
      key: "status",
      isSortable: true,
      renderComponent: ({ item }) => (
        <Pill variant={item.status === "active" ? "success" : "default"}>
          {item.status}
        </Pill>
      ),
    },
    {
      label: "Is Embedded?",
      key: "is_embedded",
      isSortable: true,
      renderComponent: ({ item }) => (
        <BooleanDataDisplay value={item.is_embedded} />
      ),
    },
    ...(!templatesListFilters?.["category.id"]
      ? [
          {
            label: "Category",
            key: "category.id",
            isSortable: true,
            renderComponent: ({ item }) =>
              item.category?.id ? (
                <RoutingLink
                  to={TemplatedReportsCategoriesDetailRoutePath.toLinkParts({
                    pathParams: {
                      categoryId: item.category.id,
                    },
                  })}
                >
                  {item.category.name}
                </RoutingLink>
              ) : (
                "-"
              ),
          },
        ]
      : []),
    // IMPROVE: show a report version or applicable year here in the future for more context
    {
      label: "Inputs",
      key: "input_schema",
      renderComponent: ({ item }) => {
        const value = item.input_schema;

        if (!isPlainObject(value)) return null;

        return (
          <ul className={cx("tag-container")}>
            {Object.entries(value).map(([k, v]) => {
              return (
                <Tag
                  as="li"
                  value={v.display_name}
                  key={k}
                />
              );
            })}
          </ul>
        );
      },
    },
    {
      label: "Reports",
      key: "reports_count",
      isSortable: false,
      alignment: "right",
      renderComponent: ({ item }) => (
        <IntegerDataDisplay value={associatedReportsCounts[item.name]} />
      ),
    },
    {
      label: "Updated At",
      key: "updated_at",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={item.updated_at}
          withTime
        />
      ),
    },
  ];

  const StatusPillToggleGroup = ({ name }: { name: string }) => {
    const { handleOnChange } = useFilterAreaContentContext();
    const { storedFilters: { status } = {} } = useFilterAreaContext<{
      status: string[];
    }>();
    const pillToggles: PillToggleProps[] = [
      {
        name: "All",
        label: "All",
        value: "",
        isSelected: !status?.length || status?.includes(""),
        shouldSelectAll: true,
      },
      {
        name: TemplatedReportStatus.ACTIVE,
        label: toStartCaseString(TemplatedReportStatus.ACTIVE),
        value: TemplatedReportStatus.ACTIVE,
        variant: PillToggleVariants.GOOD,
        isSelected: status?.includes(TemplatedReportStatus.ACTIVE) ?? false,
      },
      {
        name: TemplatedReportStatus.ARCHIVED,
        label: toStartCaseString(TemplatedReportStatus.ARCHIVED),
        value: TemplatedReportStatus.ARCHIVED,
        variant: PillToggleVariants.ATTENTION,
        isSelected: status?.includes(TemplatedReportStatus.ARCHIVED) ?? false,
      },
    ];

    return (
      <PillToggleGroup
        name={name}
        pills={pillToggles}
        onChange={(val) => handleOnChange(val, name)}
      />
    );
  };

  const filterKeys = TemplatedReportWithCategorySchema.keyof().Enum;

  return (
    <DataTablePanel
      storageKey={tableConfigStorageKey}
      filterComponent={
        <FilterArea.Root storageKey={filterConfigStorageKey}>
          <FilterArea.Container>
            <FilterArea.Content>
              {({ handleOnChange }) => (
                <div style={{ marginBottom: 0, marginRight: 8 }}>
                  <TextInput
                    name="name"
                    type="search"
                    placeholder="Search by Name..."
                    isLabelShown={false}
                    isInline
                    onChange={(val) => handleOnChange(val, "name")}
                  />
                </div>
              )}
            </FilterArea.Content>
            <FilterDrawer.Root>
              <FilterDrawer.Trigger />
              <FilterDrawer.Content>
                {!templatesListFilters?.["category.id"] ? (
                  <DropdownInput
                    name={`${filterKeys.category}.id`}
                    onFetchData={async (payload) => {
                      let { data } =
                        await TemplatedReportAdapter.categories.getList({
                          ...payload,
                          filters: {
                            ...(payload.searchTerm
                              ? {
                                  name: payload.searchTerm,
                                }
                              : {}),
                          },
                        });

                      // REVIEW: not a fan of the fact that we need to do this. A refactor of DropdownInput is needed.
                      if (Array.isArray(payload.value)) {
                        data = data.filter((d) => payload.value.includes(d.id));
                      }

                      return data;
                    }}
                    labelKey="name"
                    valueKey="id"
                    placeholder="Select Categories..."
                    isMulti
                    label="Category"
                    isOptionalTextShown={false}
                  />
                ) : null}
                <DateSelectorInput
                  name={filterKeys.updated_at}
                  label="Updated At"
                  placeholder="Enter Updated At..."
                  isRange
                  variant="time"
                  isFluid
                  isInline={false}
                />
              </FilterDrawer.Content>
            </FilterDrawer.Root>
          </FilterArea.Container>
        </FilterArea.Root>
      }
      panelProps={{
        title: "Templates",
        titleDecorator: (
          <FilterArea.Root storageKey={quickFilterConfigStorageKey}>
            <FilterArea.Container>
              <FilterArea.Content>
                {() => <StatusPillToggleGroup name={filterKeys.status} />}
              </FilterArea.Content>
            </FilterArea.Container>
          </FilterArea.Root>
        ),
      }}
      dataTableProps={{
        variant: "simplicity-first",
        isLoading,
        items: data?.data ?? [],
        headers,
        onSortChange: updateTableState,
        onPaginationChange: updateTableState,
        pagination: {
          page: tableState.page,
          pageSize: tableState.pageSize,
          total: data?.total_entries,
        },
        sorting: {
          sortBy: tableState.sortBy,
          sortDirection: tableState.sortDirection,
        },
      }}
    />
  );
};
