import { z } from "zod";
import { TypeOfObjectValues } from "../util";
import { AssetTypeSchema } from "./AssetSchemas";
import { AttributeDataType } from "./AttributeSchemas";
import {
  DomainModelMetaExtendedSchema,
  DomainModelMetaSchema,
  DomainModelSchema,
} from "./DomainModelSchemas";
import { NotificationChannelSchema } from "./NotificationSchema";
import {
  WorkflowSystemActionSchema,
  WorkflowUserTaskSchema,
} from "./WorkflowConfigSchemas";
import {
  WorkflowTaskNotificationEventType,
  WorkflowTaskNotificationRecipientType,
} from "./WorkflowTaskSchemas";

const DueDateSchema = z.object({
  time_period: z.enum(["hours", "days", "weeks", "months"]),
  duration: z.number(),
});

const WorkflowTriggerFormCreateSchema = z.object({
  type: z.enum(["scheduled", "system"]),
  event: z.literal("form_submission_create"),
  form_category_id: z.string(),
  form_schema_id: z.string(),
  due_date: DueDateSchema,
});

const WorkflowTriggerWorkflowCompleteSchema = z.object({
  type: z.literal("system"),
  event: z.literal("workflow_complete"),
  workflow_template_id: z.string(),
  due_date: DueDateSchema,
});

const WorkflowTriggerScheduledSchema = z.object({
  type: z.enum(["scheduled", "system"]),
  cron: z.string(),
  due_date: DueDateSchema,
});

const StepsSchema = z.record(
  z.union([WorkflowSystemActionSchema, WorkflowUserTaskSchema])
);

const ConfigSchema = z.object({
  start: z.string(),
  steps: StepsSchema,
});

export const WorkflowTemplateStatus = {
  ACTIVE: "active",
  DRAFT: "draft",
  ARCHIVE: "archive",
} as const;

export const WorkflowNotificationEventType = {
  CREATED: "created",
  STATUS_CHANGED: "status_changed",
  DELETED: "deleted",
} as const;

export const WorkflowNotificationRecipientType = {
  OWNER: "owner",
  ALL_ASSIGNEES: "all_assignees",
  USERS: "users",
} as const;

export type WorkflowTemplateStatusType = TypeOfObjectValues<
  typeof WorkflowTemplateStatus
>;

const WorkflowTemplateTypeSchema = z
  .object({
    name: z.string(),
    description: z.string(),
    status: z.enum([
      WorkflowTemplateStatus.ACTIVE,
      WorkflowTemplateStatus.DRAFT,
      WorkflowTemplateStatus.ARCHIVE,
    ]),
    version: z.number(),
    category_id: z.string(),
    config: ConfigSchema,
    triggers: z.array(
      z.union([
        WorkflowTriggerFormCreateSchema,
        WorkflowTriggerScheduledSchema,
        WorkflowTriggerWorkflowCompleteSchema,
      ])
    ),
    company_id: z.string(),
    workflow_category: z.object({
      id: z.string(),
      description: z.string(),
      name: z.string(),
    }),
    asset_type: AssetTypeSchema,
    asset_filter: z.any().nullable(),
    workflow_owner_id: z.string().nullable(),
    workflow_notifications: z.object({
      events: z.array(
        z.object({
          type: z.enum([
            WorkflowNotificationEventType.CREATED,
            WorkflowNotificationEventType.STATUS_CHANGED,
            WorkflowNotificationEventType.DELETED,
          ]),
        })
      ),
      recipients: z.array(
        z.union([
          z.object({
            type: z.enum([
              WorkflowNotificationRecipientType.ALL_ASSIGNEES,
              WorkflowNotificationRecipientType.OWNER,
            ]),
          }),
          z.object({
            type: z.enum([WorkflowNotificationRecipientType.USERS]),
            users: z.array(z.string()),
          }),
        ])
      ),
      channels: z.array(NotificationChannelSchema),
    }),

    workflow_task_notifications: z.object({
      events: z.array(
        z.object({
          type: z.enum([
            WorkflowTaskNotificationEventType.CREATED,
            WorkflowTaskNotificationEventType.STATUS_CHANGED,
            WorkflowTaskNotificationEventType.ASSIGNEE_CHANGED,
          ]),
        })
      ),
      recipients: z.array(
        z.union([
          z.object({
            type: z.enum([
              WorkflowTaskNotificationRecipientType.ASSIGNEE,
              WorkflowTaskNotificationRecipientType.ASSIGNEE_USER,
              WorkflowTaskNotificationRecipientType.ASSIGNEE_GROUP,
              WorkflowTaskNotificationRecipientType.WORKFLOW_OWNER,
            ]),
          }),
          z.object({
            type: z.enum([WorkflowNotificationRecipientType.USERS]),
            users: z.array(z.string()),
          }),
        ])
      ),
      channels: z.array(NotificationChannelSchema),
    }),

    initial_variables: z.record(
      z.string(),
      z.object({
        data_type: z.enum([AttributeDataType.DATE]),
        format: z.enum(["YYYYMM", "YYYY"]),
        display_name: z.string().min(3).max(64),
        description: z.string().max(256),
        is_required: z.boolean(),
        order: z.number().int(),
      })
    ),
  })
  .merge(DomainModelSchema)
  .merge(DomainModelMetaSchema)
  .merge(DomainModelMetaExtendedSchema)
  .describe("The domain model schema for a Workflow Template");

export type WorkflowTemplateType = z.infer<typeof WorkflowTemplateTypeSchema>;
export type WorkflowTriggerFormCreateType = z.infer<
  typeof WorkflowTriggerFormCreateSchema
>;
export type WorkflowTriggerWorkflowCompleteType = z.infer<
  typeof WorkflowTriggerWorkflowCompleteSchema
>;
export type WorkflowTriggerScheduledSchemaType = z.infer<
  typeof WorkflowTriggerScheduledSchema
>;

export const isTriggerFormSubmissionCreate = (
  trigger: WorkflowTemplateType["triggers"][0]
): trigger is WorkflowTriggerFormCreateType =>
  "event" in trigger && trigger.event === "form_submission_create";

export const isTriggerCron = (
  trigger: WorkflowTemplateType["triggers"][0]
): trigger is WorkflowTriggerScheduledSchemaType =>
  trigger.type === "scheduled" && "cron" in trigger;
