import { linkToFacilities } from "#routers/links";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import {
  useClientSideSortingAndPagination,
  useTableSortingAndPagination,
} from "#src/components/Redux/reducers/tableStateReducer";
import { DataTable } from "@validereinc/common-components";
import type { FlowType } from "@validereinc/domain";
import React, { useMemo } from "react";

export const FlowFacilityTable = ({
  variant,
  flow,
  isLoading,
}: {
  variant: "upstream" | "downstream";
  flow?: FlowType;
  isLoading: boolean;
}) => {
  const facilityDetail = useMemo(
    () => flow?.[`${variant}_facility`] ?? { id: undefined },
    [flow]
  );
  const [tableState, updateTableState] = useTableSortingAndPagination();
  const { items, pagination, sorting } = useClientSideSortingAndPagination(
    [{ ...facilityDetail, type: "Facility" }],
    tableState
  );

  return (
    <DataTable
      isLoading={isLoading}
      items={items}
      headers={[
        {
          label: "Name",
          key: "name",
          renderComponent: ({ item }) => (
            <RoutingLink to={linkToFacilities(facilityDetail?.id)}>
              {item.name}
            </RoutingLink>
          ),
        },
        {
          label: "Type",
          key: "type",
        },
      ]}
      pagination={{
        ...pagination,
        showPageSizeControls: false,
      }}
      sorting={sorting}
      onPaginationChange={updateTableState}
      onSortChange={updateTableState}
    />
  );
};
