import {
  DateSelectorInput,
  DropdownInput,
  GeoPointInput,
  RadioInput,
  TextAreaInput,
  TextInput,
} from "@validereinc/common-components";
import {
  AttributeDataType,
  type AttributeDataTypeType,
  type FormSchemaNormalQuestionType,
} from "@validereinc/domain";
import { DateTimeSubstitutions } from "@validereinc/domain-controllers/logic/forms";
import {
  validateDateBounds,
  validateLength,
  validateNumericBounds,
} from "@validereinc/utilities";
import React from "react";
import { FileField } from "./FileField";
import { LookupField } from "./LookupField";

export const QuestionField = (
  props: Omit<FormSchemaNormalQuestionType, "type"> & { name: string }
) => {
  const {
    data_type,
    prompt,
    is_required,
    is_long_string,
    date_resolution,
    ...restProps
  } = props;

  const commonProps = {
    label: prompt,
    isRequired: is_required,
    isFluid: true,
    placeholder: `${([AttributeDataType.PICK_LIST, AttributeDataType.MULTI_PICK_LIST, AttributeDataType.LOOKUP, AttributeDataType.DATE, AttributeDataType.DATE_TIME, AttributeDataType.DATE_TIME_RANGE] as AttributeDataTypeType[]).includes(data_type) ? "Select" : "Enter"} ${prompt}...`,
    ...restProps,
  };
  const getStringLengthValidation = (
    value: string,
    dataType: string,
    minLength?: number,
    maxLength?: number
  ) => {
    return value &&
      dataType === "string" &&
      (minLength !== undefined || maxLength !== undefined)
      ? (validateLength(value, {
          min: minLength,
          max: maxLength,
        }) as string | true)
      : true;
  };

  return (
    <>
      {["number", "integer"].includes(data_type) || !data_type ? (
        <TextInput
          {...commonProps}
          type={data_type === "string" ? "text" : "number"}
          validate={{
            numericBounds: (value: number) =>
              value &&
              ["number", "integer"].includes(data_type) &&
              (restProps.minimum !== undefined ||
                restProps.maximum !== undefined)
                ? (validateNumericBounds(value, {
                    min: restProps.minimum,
                    max: restProps.maximum,
                  }) as string | true)
                : true,
          }}
        />
      ) : null}
      {data_type === "string" && !is_long_string ? (
        <TextInput
          {...commonProps}
          type="text"
          validate={{
            length: (value: string) =>
              getStringLengthValidation(
                value,
                data_type,
                restProps.min_length,
                restProps.max_length
              ),
          }}
        />
      ) : null}
      {data_type === "string" && is_long_string ? (
        <TextAreaInput
          {...commonProps}
          validate={{
            length: (value: string) =>
              getStringLengthValidation(
                value,
                data_type,
                restProps.min_length,
                restProps.max_length
              ),
          }}
        />
      ) : null}
      {["date", "date-time", "date-time-range"].includes(data_type) ? (
        <DateSelectorInput
          {...commonProps}
          {...(data_type === "date-time-range"
            ? { isRange: true, isArraySyntax: true }
            : {})}
          variant={
            data_type === "date"
              ? date_resolution === "year"
                ? "year"
                : date_resolution === "year_month"
                  ? "month"
                  : "day"
              : "time"
          }
          validate={{
            dateBounds: (value: string) =>
              value &&
              ["date", "date-time"].includes(data_type) &&
              (restProps.before !== undefined || restProps.after !== undefined)
                ? (validateDateBounds(new Date(value), {
                    before: restProps.before
                      ? restProps.before !== DateTimeSubstitutions.$NOW
                        ? new Date(restProps.before)
                        : restProps.before
                      : undefined,
                    after: restProps.after
                      ? restProps.after !== DateTimeSubstitutions.$NOW
                        ? new Date(restProps.after)
                        : restProps.after
                      : undefined,
                    fallbackBeforeDate:
                      restProps.before === DateTimeSubstitutions.$NOW
                        ? new Date()
                        : undefined,
                    fallbackAfterDate:
                      restProps.after === DateTimeSubstitutions.$NOW
                        ? new Date()
                        : undefined,
                    fallbackDateLabel: [
                      restProps.before,
                      restProps.after,
                    ].includes("$now")
                      ? data_type
                      : undefined,
                  }) as string | true)
                : true,
          }}
        />
      ) : null}

      {data_type === "boolean" ? (
        <RadioInput
          {...commonProps}
          options={[
            { label: "Yes", value: true },
            { label: "No", value: false },
          ]}
          labelKey="label"
          valueKey="value"
        />
      ) : null}

      {["pick-list", "multi-pick-list"].includes(data_type) ? (
        <DropdownInput
          {...commonProps}
          options={(props as FormSchemaNormalQuestionType).pick_list_values}
          isMulti={data_type === "multi-pick-list"}
        />
      ) : null}

      {data_type === "lookup" ? (
        <LookupField
          lookup_entity_display_attributes={props.lookup_entity_attributes}
          {...commonProps}
        />
      ) : null}

      {data_type === "geo_point" ? (
        <GeoPointInput
          {...commonProps}
          isFluid
        />
      ) : null}

      {data_type === "file" ? <FileField {...commonProps} /> : null}
    </>
  );
};
