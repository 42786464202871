import { DateRange } from "#src/batteries-included-components/Forms/DateRange";
import CustomAttributeField from "#src/components/Common/CustomAttributeField";
import { useListCustomAttributes } from "#src/components/hooks/adapters/useCustomAttributes";
import { getArrayAdditionsAndExclusions } from "#src/utils/arrayFormatter";
import {
  Button,
  Dialog,
  DropdownInput,
  Form,
  GeoPointInput,
  Icon,
  useForm,
  usePrevious,
} from "@validereinc/common-components";
import {
  AssetType,
  AttributeDataType,
  EquipmentType,
  FlowType,
  TimeStateManagementDefaultInputValue,
} from "@validereinc/domain";
import {
  isDateRangeValue,
  parseAsLatitudeDegree,
  parseAsLongitudeDegree,
  yearMonthFormatter,
} from "@validereinc/utilities";
import classNames from "classnames/bind";
import startOfMonth from "date-fns/startOfMonth";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isPlainObject from "lodash/isPlainObject";
import mapValues from "lodash/mapValues";
import pick from "lodash/pick";
import pickBy from "lodash/pickBy";
import React, { useEffect, useMemo, useState } from "react";
import styles from "./AssetEditDialog.module.scss";
import { AssetEditDialogPropTypes } from "./types";

const cx = classNames.bind(styles);

type FormValues = {
  // Utility form values; These keys are not sent to the API:
  hasDateRange: boolean;
  hasDateRangeStart?: boolean;
  hasDateRangeEnd?: boolean;
  dateRange:
    | {
        from?: string | Date;
        to?: string | Date;
      }
    | undefined;
} & {
  geo: [lat?: string, long?: string];
} & Record<string, any>; // Default values of the asset when dialog is opened:

const AttributeNotStateManagedBanner = ({
  display_name,
}: {
  display_name: string;
}) => (
  <div className={cx("banner-container")}>
    <div className={cx("icon")}>
      <Icon variant="warning" />
    </div>
    <div className={cx("description")}>
      {`The value for “${display_name}” will apply to all time and cannot be changed for a specific date range.`}
    </div>
  </div>
);

const AssetEditDialog = (props: AssetEditDialogPropTypes) => {
  const {
    assetId,
    onClose,
    isLoading: isLoadingProp,
    primaryFields,
    readOnlyFields,
    assetType,
    onUpdate,
    assetDetails,
    dialogProps,
    hasGeoPointField,
  } = props;

  const form = useForm<FormValues>({
    defaultValues: {
      hasDateRange: false,
    },
  });
  const [selectedAttributes, setSelectedAttributes] = useState<string[]>([]);

  const customAttributesQuery = useListCustomAttributes(
    { filters: { entity_type: assetType } },
    {
      enabled: !!assetId,
    }
  );

  const customAttributes = customAttributesQuery.data?.data ?? [];

  // Filter custom attributes by their entity_subtypes (=== asset.type.id) before rendering:
  const relevantCustomAttributes = useMemo(() => {
    if (
      assetType === AssetType.EQUIPMENT &&
      (assetDetails as EquipmentType)?.type_id
    ) {
      return customAttributes.filter(
        (customAttribute) =>
          !customAttribute.entity_subtypes?.length ||
          customAttribute.entity_subtypes.includes(assetDetails?.type_id)
      );
    }
    if (assetType === AssetType.FLOW && (assetDetails as FlowType)?.type) {
      return customAttributes.filter(
        (customAttribute) =>
          !customAttribute.entity_subtypes ||
          customAttribute.entity_subtypes.includes(assetDetails?.type_id)
      );
    }
    return customAttributes;
  }, [customAttributes, assetDetails, assetType]);

  const { hasDateRange, hasDateRangeStart, hasDateRangeEnd, dateRange } =
    form.watch();

  // when popup opens
  useEffect(() => {
    if (!assetId) return;
    setSelectedAttributes([]);
    form.reset();
  }, [assetId]);

  // If an attribute gets selected and gets a value and then removed from the selected attributes, its value should be wiped.
  // Also when an attribute is added (primary or custom attribute), its value should be prepopulated:
  const previousSelectedAttributes = usePrevious(selectedAttributes) ?? [];
  useEffect(() => {
    const diff = getArrayAdditionsAndExclusions(
      previousSelectedAttributes,
      selectedAttributes
    );
    diff.removed.forEach((removedAttributes) => {
      form.setValue(removedAttributes, undefined);
    });
    diff.added.forEach((addedAttributes) => {
      let valueFromDetails =
        get(assetDetails, addedAttributes) ??
        get(assetDetails, `attributes.${addedAttributes}`) ??
        get(assetDetails, `custom_attributes.${addedAttributes}`);

      if (valueFromDetails !== null || valueFromDetails !== undefined) {
        if (isPlainObject(valueFromDetails) && "id" in valueFromDetails) {
          valueFromDetails = valueFromDetails.id;
        }

        form.setValue(addedAttributes, valueFromDetails);
      }
    });
  }, [selectedAttributes, assetDetails]);

  const isLoading =
    !!isLoadingProp || (customAttributesQuery.isLoading && !!assetId);

  const sharedAttributeInputProps = {
    isDisabled: isLoading,
  };

  const selectableAttributesToEdit = [
    ...primaryFields,
    /* IMPROVEMENT: For now, only one Geo point input is available in primary fields: */
    ...(hasGeoPointField
      ? [
          {
            display_name: "Latitude & Longitude",
            field_name: "geo",
            customRenderer: () => (
              <GeoPointInput
                {...sharedAttributeInputProps}
                name="geo"
              />
            ),
          },
        ]
      : []),
    ...relevantCustomAttributes.map((custom_attribute) => {
      return {
        ...custom_attribute,
        field_name: `custom_attributes.${custom_attribute.field_name}`,
      } as typeof custom_attribute;
    }),
  ];

  const isSaveButtonDisabled =
    selectedAttributes.length === 0 ||
    (hasDateRange && !hasDateRangeStart && !hasDateRangeEnd) ||
    ((hasDateRangeStart || hasDateRangeEnd) && !dateRange) ||
    sharedAttributeInputProps.isDisabled ||
    isLoading;

  const handleSubmit = form.handleSubmit((values) => {
    const allowedFields = [
      ...primaryFields.map((primaryField) => primaryField.field_name),
      "custom_attributes",
      "attributes",
    ];

    const refinedValues = pick(values, ...allowedFields);

    if (values?.geo) {
      refinedValues.latitude =
        parseAsLatitudeDegree(String(values?.geo?.[0])) ?? undefined;
      refinedValues.longitude =
        parseAsLongitudeDegree(String(values?.geo?.[1])) ?? undefined;
    }

    if (values?.type) {
      refinedValues.type_id = values.type.id;
    }

    refinedValues.start_date = TimeStateManagementDefaultInputValue.min;
    refinedValues.end_date = TimeStateManagementDefaultInputValue.max;

    if (hasDateRange) {
      const { from: dateRangeFrom, to: dateRangeTo } = {
        from: "",
        to: "",
        ...dateRange,
      };
      const startDate = new Date(dateRangeFrom);
      const endDate = new Date(dateRangeTo);

      if (hasDateRangeStart) {
        refinedValues.start_date = yearMonthFormatter(startDate);
      }

      if (hasDateRangeEnd) {
        refinedValues.end_date = hasDateRangeStart
          ? yearMonthFormatter(startOfMonth(endDate))
          : yearMonthFormatter(startDate);
      }
    }

    // Do a cleanup on attributes and custom_attributes to remove any "undefined" values,
    // and get rid of them if result in a completely empty object.
    if (refinedValues.attributes) {
      refinedValues.attributes = pickBy(
        refinedValues.attributes,
        (value) => value !== undefined
      );
      refinedValues.attributes = mapValues(
        refinedValues.attributes,
        (val, key) => {
          const associatedDataType = selectableAttributesToEdit.find(
            (attr) => attr.field_name === `attributes.${key}`
          )?.data_type;

          if (
            associatedDataType === AttributeDataType.DATE_TIME_RANGE &&
            isDateRangeValue(val)
          ) {
            return [val.from, val.to];
          } else {
            return val;
          }
        }
      );

      if (isEmpty(refinedValues.attributes)) delete refinedValues.attributes;
    }
    if (refinedValues.custom_attributes) {
      refinedValues.custom_attributes = pickBy(
        refinedValues.custom_attributes,
        (value) => value !== undefined
      );

      refinedValues.custom_attributes = mapValues(
        refinedValues.custom_attributes,
        (val, key) => {
          const associatedDataType = selectableAttributesToEdit.find(
            (attr) => attr.field_name === `custom_attributes.${key}`
          )?.data_type;

          if (
            associatedDataType === AttributeDataType.DATE_TIME_RANGE &&
            isDateRangeValue(val)
          ) {
            return [val.from, val.to];
          } else {
            return val;
          }
        }
      );

      if (isEmpty(refinedValues.custom_attributes))
        delete refinedValues.custom_attributes;
    }

    if (!assetId) return;

    onUpdate(refinedValues);
  });

  return (
    <Form
      {...form}
      onSubmit={handleSubmit}
    >
      <Dialog
        key="asset-edit-dialog"
        title="Edit Asset Attributes"
        isOpen={!!assetId}
        onClose={onClose}
        actionRow={[
          <Button
            key="asset-edit-dialog-save-button"
            variant="primary"
            disabled={isSaveButtonDisabled}
            onClick={handleSubmit}
          >
            Save
          </Button>,
        ]}
        {...dialogProps}
      >
        <p>
          Please select the attributes you wish to edit and specify the date
          range for which the values should be applied.
        </p>

        <div className={cx("section-container")}>
          <section>
            <span className={cx("title")}>Attribute Selection</span>
            <DropdownInput
              name={"attribute_selector"}
              options={selectableAttributesToEdit}
              isSortedAlphabetically
              isLoading={isLoading}
              labelKey="display_name"
              valueKey="field_name"
              placeholder="Select attributes..."
              isFluid
              isMulti
              label="Attributes to Edit"
              isRequired
              // TODO: extract the value directly from react hook form and
              // forego manual control here, since this input is already using
              // Controller underneath. this is an anti-pattern.
              onChange={(selected: string[]) => {
                setSelectedAttributes(selected);
              }}
              value={selectedAttributes}
            />
          </section>

          {Array.isArray(readOnlyFields) && readOnlyFields.length > 0 && (
            <section>
              <span className={cx("title")}>Unchangeable Values</span>
              <div className={cx("readonly-container")}>
                {readOnlyFields.map((readOnlyField) => (
                  <div
                    key={readOnlyField.field_name}
                    className={cx("readonly-wrapper")}
                  >
                    <Icon variant="lock" />
                    <b>{readOnlyField.display_name}: </b>
                    {readOnlyField.value}
                  </div>
                ))}
              </div>
            </section>
          )}

          {selectedAttributes.length > 0 && (
            <section>
              <span className={cx("title")}>Attribute Values</span>
              {selectableAttributesToEdit
                .filter(
                  (attribute) =>
                    selectedAttributes.includes(
                      `custom_attributes.${attribute.field_name}`
                    ) || selectedAttributes.includes(attribute.field_name)
                )
                .map(
                  ({
                    field_name,
                    display_name,
                    customRenderer,
                    is_state_managed = true,
                    ...restAttribute
                  }: {
                    field_name: string;
                    is_state_managed?: boolean;
                    display_name: string;
                    customRenderer?: (
                      propsObject: Record<string, any>
                    ) => React.ReactElement;
                  }) => (
                    <>
                      {customRenderer?.(sharedAttributeInputProps) ?? (
                        <CustomAttributeField
                          {...sharedAttributeInputProps}
                          key={field_name}
                          name={field_name}
                          attribute={{
                            field_name,
                            display_name,
                            ...restAttribute,
                          }}
                          subtype={assetDetails?.type?.id}
                        />
                      )}
                      {!is_state_managed && (
                        <AttributeNotStateManagedBanner
                          display_name={display_name}
                        />
                      )}
                    </>
                  )
                )}
            </section>
          )}

          {selectedAttributes.length > 0 && (
            <section>
              <DateRange isDisabled={sharedAttributeInputProps.isDisabled} />
            </section>
          )}
        </div>
      </Dialog>
    </Form>
  );
};

export default AssetEditDialog;
