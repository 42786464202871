import { z } from "zod";
import { TypeOfObjectValues } from "../util";
import { AssetTypeSchema } from "./AssetSchemas";
import { AttributeDataType } from "./AttributeSchemas";
import {
  DomainModelMetaExtendedSchema,
  DomainModelMetaSchema,
  DomainModelSchema,
} from "./DomainModelSchemas";
import { FormSubmissionAnswerSchema } from "./FormSubmissionSchemas";
import { FormSubmissionStatus } from "./FormSubmissionSchemas.shared";
import { WorkflowConfigSchema } from "./WorkflowConfigSchemas";

export const WorkflowStatus = {
  IN_PROGRESS: "in_progress",
  COMPLETE: "complete",
  OVERDUE: "overdue",
  DISMISSED: "dismissed",
  MISSED: "missed",
  ERROR: "error",
} as const;

export type WorkflowStatusType = TypeOfObjectValues<typeof WorkflowStatus>;

export const WorkflowStepState = {
  PENDING: "pending",
  FINISHED: "finished",
  SKIPPED: "skipped",
  FAILED: "failed",
} as const;

export type WorkflowStepStateType = TypeOfObjectValues<
  typeof WorkflowStepState
>;

const ChoiceSchema = z.object({
  id: z.string().max(64).min(1),
  name: z.string().max(256),
  description: z.string().max(1024),
  next: z.string().optional(),
  end: z.literal(true).optional(),
});
export type ChoiceType = z.infer<typeof ChoiceSchema>;

export const WorkflowSchema = z
  .object({
    name: z.string(),
    description: z.string(),
    company_id: z.string().uuid(),
    workflow_template_id: z.string().uuid(),
    workflow_template: z.object({
      id: z.string(),
      name: z.string(),
      description: z.string(),
      workflow_category: z.object({
        id: z.string(),
        name: z.string(),
      }),
    }),
    status: z.nativeEnum(WorkflowStatus),
    due_date: z.date().or(z.string().datetime()),
    step_states: z.record(z.string(), z.nativeEnum(WorkflowStepState)),
    facility_id: z.string().nullish(),
    asset_id: z.string().nullish(),
    time_period: z.string().nullish(),
    duration: z.number().nullish(),
    facility: z
      .object({
        id: z.string(),
        name: z.string(),
      })
      .nullish(),
    asset: z
      .object({ id: z.string(), name: z.string(), asset_type: AssetTypeSchema })
      .nullish(),
    config: WorkflowConfigSchema,
    variables: z.record(z.string(), z.string()),
    asset_trigger: z
      .object({
        metadata: z.object({
          asset_type: AssetTypeSchema,
          asset_id: z.string(),
          facility_id: z.string().optional(),
          action: z.string(),
        }),
      })
      .nullable()
      .optional(),
    form_trigger: z
      .object({
        workflow_id: z.string(),
        form_submission_id: z.string(),
        form_submission_version: z.number().int(),
        metadata: FormSubmissionAnswerSchema,
      })
      .nullable()
      .optional(),
    workflow_trigger: z
      .object({
        triggered_workflow_id: z.string(),
        triggering_workflow_id: z.string(),
        triggering_workflow_name: z.string().optional(),
        metadata: z.object({
          triggering_workflow_id: z.string(),
          triggering_workflow_template_id: z.string(),
        }),
      })
      .nullable()
      .optional(),
  })
  // Merge id, created_at, updated_at, created_by, updated_by:
  .merge(DomainModelSchema)
  .merge(DomainModelMetaSchema)
  .merge(DomainModelMetaExtendedSchema);

export type WorkflowType = z.infer<typeof WorkflowSchema>;

export const WorkflowFormSubmissionSchema = z
  .object({
    created_by: z.string(),
    form_schema_id: z.string(),
    form_schema_version: z.number(),
    status: z.enum([
      FormSubmissionStatus.DRAFT,
      FormSubmissionStatus.INVALIDATED,
      FormSubmissionStatus.PENDING,
      FormSubmissionStatus.SUBMITTED,
      FormSubmissionStatus.VALIDATED,
    ]),
    form_schema: z.object({
      id: z.string(),
      name: z.string(),
      form_category: z
        .object({
          id: z.string(),
          name: z.string(),
        })
        .nullable(),
    }),
    associated_tasks: z.array(
      z.object({
        workflow_task_name: z.string(),
        workflow_task_id: z.string(),
        form_submission_id: z.string(),
      })
    ),
    associated_actions: z.array(
      z.object({
        workflow_action_name: z.string(),
        workflow_action_id: z.string(),
        form_submission_id: z.string(),
      })
    ),
    is_workflow_trigger: z.boolean(),
  })
  .merge(DomainModelSchema)
  .merge(DomainModelMetaSchema);

export type WorkflowFormSubmissionType = z.infer<
  typeof WorkflowFormSubmissionSchema
>;

export const TriggerWorkflowVariablesSchema = z.record(
  z.string(),
  z.string().or(
    z.object({
      data_type: z.enum([AttributeDataType.DATE]),
      format: z.enum(["YYYYMM", "YYYY"]),
      value: z.string(),
    })
  )
);

export type TriggerWorkflowVariablesType = z.infer<
  typeof TriggerWorkflowVariablesSchema
>;

export const TriggerWorkflowSchema = WorkflowSchema.pick({
  workflow_template_id: true,
  asset_id: true,
  time_period: true,
  duration: true,
}).merge(
  z.object({
    variables: TriggerWorkflowVariablesSchema.optional(),
  })
);

export type TriggerWorkflowType = z.infer<typeof TriggerWorkflowSchema>;

export const TriggerWorkflowFormSchema = z.object({
  asset_ids: z.array(z.string()),
  has_due_date: z.boolean(),
  time_period: z.string(),
  duration: z.number(),
  has_initial_variables: z.boolean(),
  initial_variables: z.record(
    z.string(),
    z.object({
      from: z.date(),
      to: z.date(),
    })
  ),
});

export type TriggerWorkflowFormType = z.infer<typeof TriggerWorkflowFormSchema>;
