import { z } from "zod";
import { AssetType } from "./AssetSchemas";
import { FilterObjectType, ResourceType } from "../util";
import { DeviceType } from "./DeviceSchemas";
import { DomainModelSchema } from "./DomainModelSchemas";
import { EquipmentType } from "./EquipmentSchemas";
import { FacilityType } from "./FacilitySchemas";
import { Resources } from "../util";

export const ActivityAction = {
  CREATE: "create",
  UPDATE: "update",
  DELETE: "delete",
  ARCHIVE: "archive",
  PERMISSION_UPDATE: "permission_update",
  USER_ADD: "user_add",
  USER_REMOVE: "user_remove",
} as const;

export const ActivityActionSchema = z.union([
  z.literal(ActivityAction.CREATE),
  z.literal(ActivityAction.UPDATE),
  z.literal(ActivityAction.DELETE),
  z.literal(ActivityAction.ARCHIVE),
  z.literal(ActivityAction.PERMISSION_UPDATE),
  z.literal(ActivityAction.USER_ADD),
  z.literal(ActivityAction.USER_REMOVE),
]);

export const ActivityResource = {
  FACILITY: Resources.FACILITY,
  EQUIPMENT: Resources.EQUIPMENT,
  FLOW: Resources.FLOW,
  DEVICE: Resources.DEVICE,
  RECORD_VALUE: Resources.RECORD_VALUE,
  ROLE: Resources.ROLE,
  USER_GROUP: Resources.USER_GROUP,
} as const;

// IMPROVE: there will be a better place for this once we figure out resource name standardization
export const ActivityResourceSchema = z.union([
  z.literal(ActivityResource.RECORD_VALUE),
  z.literal(ActivityResource.FLOW),
  z.literal(ActivityResource.FACILITY),
  z.literal(ActivityResource.EQUIPMENT),
  z.literal(ActivityResource.DEVICE),
  z.literal(ActivityResource.ROLE),
  z.literal(ActivityResource.USER_GROUP),
]);

/**
 * Note, this schema is only used to reference keys safely
 */
export const ActivitySchema = z
  .object({
    company_id: z.string().uuid(),
    resource_id: z.string().uuid(),
    // IMPROVE: this will likely be it's own schema once we figure out resource name standardization
    resource_type: ActivityResourceSchema,
    action: ActivityActionSchema,
    // can be anything depending on the resource the log was for
    before: z.any().optional(),
    // can be anything depending on the resource the log was for
    after: z.any().optional(),
    author_id: z.string().uuid(),
    timestamp: z.string().datetime(),
  })
  .merge(DomainModelSchema)
  .describe("Describes an Activity model in the Node API");

export type ActivityActionType = z.infer<typeof ActivityActionSchema>;
export type ActivityResourceType = z.infer<typeof ActivityResourceSchema>;

export const ActivityExportResource = {
  ROLE: Resources.ROLE,
  USER_GROUP: Resources.USER_GROUP,
  USER: Resources.USER,
} as const;
export type ActivityExportResourceType =
  (typeof ActivityExportResource)[keyof typeof ActivityExportResource];

/**
 * Describes an Activity model for a specific resource in the Node API. Note,
 * generic ActivityType is not derived from the ActivitySchema on purpose as the
 * type is primarily used and needs generic flexibility
 */
export type ActivityType<
  BeforeAfter = unknown,
  Resource extends string = ResourceType,
> = {
  id: string;
  company_id: string;
  resource_id: string;
  // IMPROVE: this will likely be it's own schema once we figure out resource name standardization
  resource_type: Resource;
  action: ActivityActionType;
  // can be anything depending on the resource the log was for
  before?: BeforeAfter;
  // can be anything depending on the resource the log was for
  after?: BeforeAfter;
  author_id: string;
  timestamp: string;
};
export type AnyActivityType = ActivityType;
export type FacilityActivityType = ActivityType<
  FacilityType,
  typeof AssetType.FACILITY
>;
export type EquipmentActivityType = ActivityType<
  EquipmentType,
  typeof AssetType.EQUIPMENT
>;
export type DeviceActivityType = ActivityType<
  DeviceType,
  typeof AssetType.DEVICE
>;
export type ResourceActivityType =
  | FacilityActivityType
  | EquipmentActivityType
  | DeviceActivityType;

export type ActivityFilterType = FilterObjectType<
  Partial<{
    id: string;
    resource_id: string;
    author_id: string;
    action: ActivityActionType;
    timestamp: { from: Date; to: Date };
  }>
>;
