import {
  DEFAULT_QUERY_OPTIONS,
  UseMutationCallbackType,
} from "#hooks/adapters/adapterUtils";
import useLocalization from "#src/hooks/useLocalization";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@validereinc/common-components";
import {
  FacilityDomain,
  FacilityType,
  GetListRequestType,
  HTTPNotFoundErrorCtx,
  ResourceServiceType,
} from "@validereinc/domain";
import { DEFAULT_INVALIDATE_OPTIONS } from "./adapterUtils";

const FACILITY_QUERY_KEY = "facilities";

export const useGetOneFacility = (
  id?: string,
  meta: { period?: string } = {},
  options: { enabled?: boolean } = {}
) => {
  const { localize } = useLocalization();

  return useQuery({
    queryKey: [FACILITY_QUERY_KEY, id!, meta] as const,
    queryFn: ({ queryKey: [_, id, meta] }) =>
      FacilityDomain.getOne({ id, meta }),
    enabled: options.enabled ?? Boolean(id),
    select: (resp) => resp?.data,
    meta: {
      errorCtx: {
        resource: localize("Facility"),
        resourceId: id,
      } satisfies HTTPNotFoundErrorCtx,
      hideErrorToasts: true,
    },
    ...DEFAULT_QUERY_OPTIONS,
  });
};

export const useListFacilities = (
  params: GetListRequestType & { period?: string; isExcelFile?: boolean } = {},
  options: {
    select?: any;
    enabled?: boolean;
  } = {}
) =>
  useQuery({
    queryKey: [FACILITY_QUERY_KEY, "getList", params] as const,
    queryFn: ({ queryKey: [_, __, params] }) => FacilityDomain.getList(params),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  });

export const useClearFacilityCache = () => {
  const queryClient = useQueryClient();
  return {
    invalidate: () => {
      queryClient.invalidateQueries({
        queryKey: [FACILITY_QUERY_KEY],
        ...DEFAULT_INVALIDATE_OPTIONS,
      });
    },
  };
};

export const useUpdateFacility = ({
  onSuccess,
}: UseMutationCallbackType = {}) => {
  const { toast } = useToast();
  const { invalidate } = useClearFacilityCache();
  const { localize } = useLocalization();

  return useMutation({
    mutationFn: (
      data: Parameters<ResourceServiceType<FacilityType>["updateOne"]>[0]
    ) => FacilityDomain.updateOne(data),
    onSuccess: (data, variables, context) => {
      toast.push({
        intent: "success",
        description: `Successfully updated ${localize("facility")} attribute(s)`,
      });
      invalidate();
      onSuccess?.(data, variables, context);
    },
    onError: () => {
      toast.push({
        intent: "error",
        description: `Failed to update facility`,
      });
    },
  });
};
