import { validateDate, yearMonthParser } from "@validereinc/utilities";
import { z } from "zod";

/**
 * Zod super refinement predicate to check if a string is a valid year month
 * date string
 * @see {@link https://zod.dev/?id=superrefine}
 */
export function refineYearMonthDateString(
  yearMonth: string | undefined,
  ctx: z.RefinementCtx
) {
  if (!yearMonth) {
    return;
  }

  if (!validateDate(yearMonth, yearMonthParser)) {
    ctx.addIssue({
      code: z.ZodIssueCode.invalid_date,
      message: "Invalid month of the year date string",
    });
  }
}
