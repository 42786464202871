import styles from "#batteries-included-components/Banners/ImportDataActionStatus/ImportDataActionStatus.module.scss";
import { useClearCalculationCache } from "#hooks/adapters/useEstimationMethods";
import { useGetOneJob } from "#hooks/adapters/useJobs";
import { useStickyState } from "#src/hooks/useStickyState";
import { Banner, useToast } from "@validereinc/common-components";
import { JobStatus } from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { useEffect } from "react";
import { useTimeoutHandler } from "./Banners.helpers";

const cx = classNames.bind(styles);

export const CalculationsProgressBanner = ({
  jobStorageKey,
}: {
  jobStorageKey: string;
}) => {
  const { invalidate } = useClearCalculationCache();
  const { toast } = useToast();
  const [jobId, setJobId] = useStickyState<string>("", jobStorageKey);
  const job = useGetOneJob(jobId).data?.data;

  const resetJob = () => {
    invalidate();
    setJobId("");
  };

  useTimeoutHandler(job, "Failed to run calculations due to timeout", resetJob);

  useEffect(() => {
    if ([JobStatus.FINISHED, JobStatus.PARTIAL].includes(job?.status)) {
      if (job?.status === JobStatus.PARTIAL) {
        toast.push({
          intent: "error",
          description: `Processed ${job?.processed_items}/${job?.total_items} row(s)`,
        });
      }
      const { success: successCount, failed: failureCount } =
        job?.item_status_counts ?? {};
      if (successCount) {
        toast.push({
          intent: "success",
          description: `Successfully completed calculations on ${successCount} row(s)`,
        });
      }
      if (failureCount) {
        toast.push({
          intent: "error",
          description: `Failed to run calculations on ${failureCount} row(s)`,
        });
      }
      resetJob();
    }

    if (job?.status === JobStatus.FAILED) {
      toast.push({
        intent: "error",
        description: "Failed to run calculations",
      });
      resetJob();
    }
  }, [job?.status]);

  return jobId ? (
    <Banner
      className={cx("banner")}
      titleText="Calculation Running"
      actionContent={
        <>{job?.total_items ? `${job.total_items} row(s) calculating` : ""}</>
      }
      color="loading"
      variant="loading"
    />
  ) : null;
};
