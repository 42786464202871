import OrderedList from "#common/List/OrderedList";
import RecordOfVolumeService from "#services/RecordOfVolumeService";
import { updateArray } from "#utils/arrayFormatter";
import {
  Button,
  Form,
  FormButton,
  Modal,
  SelectInput,
  TextInput,
  Title,
  useForm,
  useToast,
} from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import AddAdjustmentFactorsModal from "../AdjustmentFactors/AddAdjustmentFactorsModal";
import {
  ENTRY_TYPE_OPTIONS,
  INVENTORY_SOURCE_OPTIONS,
  SOURCE_OPTIONS,
  UNIT_OPTIONS,
  VOLUME_TYPE_OPTIONS,
} from "./VolumeEntryConstant";
import { getInitialInputs, getUsableVolumeEntries } from "./VolumeEntryHelper";

const DEFAULT_INPUTS = {
  source: INVENTORY_SOURCE_OPTIONS[0],
  dates: [],
  volumeType: VOLUME_TYPE_OPTIONS[0],
  unit: UNIT_OPTIONS[0],
  entryType: ENTRY_TYPE_OPTIONS[0],
  adjustmentFactors: [],
  totalVolume: "",
};

const VolumeEntryForm = ({
  volumeEntries,
  setVolumeEntries,
  adjustmentFactors,
  updateAdjustmentFactorList,
  streamId,
  action,
  setShouldRefetchDailyVolume,
  streamAdjustmentConfigs,
  isInventoryStream,
  haveAdjustmentFactorWritePermission,
  onHide,
}) => {
  const [addedAdjustmentFactorId, setAddedAdjustmentFactorId] = useState(null);
  const [showAdjustmentFactorModal, setShowAdjustmentFactorModal] =
    useState(false);
  const [formState, setFormState] = useState("enabled");
  const [usableVolumeEntries] = useState(
    getUsableVolumeEntries(action, volumeEntries)
  );
  const { toast } = useToast();
  const form = useForm({
    defaultValues: getInitialInputs(
      DEFAULT_INPUTS,
      usableVolumeEntries,
      adjustmentFactors,
      action,
      streamId,
      streamAdjustmentConfigs
    ),
  });

  const addAdjustmentFactor = (newAdjustmentFactor) => {
    setAddedAdjustmentFactorId(newAdjustmentFactor.id);
    updateAdjustmentFactorList();
  };

  const submitVolumeEntry = (formState) => {
    setFormState("loading");

    RecordOfVolumeService.upsertVolumeEntries(
      streamId,
      formState,
      isInventoryStream
    )
      .then(({ data }) => {
        setVolumeEntries((volumeEntries) =>
          updateArray(volumeEntries, data, "date")
        );

        setShouldRefetchDailyVolume(true);

        toast.push({
          intent: "success",
          description: "The Volume Entries have been saved",
        });
      })
      .finally(() => {
        setFormState("enabled");
      });
  };

  useEffect(() => {
    if (adjustmentFactors.length && addedAdjustmentFactorId) {
      const addedAdjustmentFactor = adjustmentFactors.find(
        (factor) => factor.id === addedAdjustmentFactorId
      );

      if (addedAdjustmentFactor) {
        form.setValue("adjustmentFactors", [
          ...form.getValues("adjustmentFactors"),
          addedAdjustmentFactor,
        ]);
        setAddedAdjustmentFactorId(null);
      }
    }
  }, [adjustmentFactors]);

  const formDisabled = action === "view" || formState !== "enabled";

  const closingVolumeDisabled = form.getValues("source")?.id === "field";

  const sourceOption = isInventoryStream
    ? INVENTORY_SOURCE_OPTIONS
    : SOURCE_OPTIONS;

  const sharedProps = {
    isDisabled: formDisabled,
    showIcon: true,
  };

  // Re-render form component when the those input change
  form.watch(["unit", "adjustmentFactors"]);

  return (
    <>
      <Form
        onSubmit={submitVolumeEntry}
        {...form}
      >
        <Modal.Body className="volumeEntryModal__form">
          <Title
            type="subheader"
            className="form__header"
          >
            Information
          </Title>

          <SelectInput
            name="source"
            label="Source"
            labelKey="name"
            options={sourceOption}
            isRequired
            {...sharedProps}
          />

          <SelectInput
            name="dates"
            label="Dates"
            labelKey="date"
            options={usableVolumeEntries}
            isMulti
            closeMenuOnSelect={false}
            isRequired
            {...sharedProps}
          />

          <Title
            type="subheader"
            className="form__header"
          >
            Properties
          </Title>

          <SelectInput
            name="volumeType"
            label="Volume Type"
            options={VOLUME_TYPE_OPTIONS}
            isRequired
            {...sharedProps}
          />

          <SelectInput
            name="unit"
            label="Unit"
            options={UNIT_OPTIONS}
            isRequired
            {...sharedProps}
          />

          <SelectInput
            name="entryType"
            label="Entry Type"
            labelKey="name"
            options={ENTRY_TYPE_OPTIONS}
            isRequired
            {...sharedProps}
          />

          <SelectInput
            name="adjustmentFactors"
            label={
              <div>
                Adjustment Factor{" "}
                {haveAdjustmentFactorWritePermission ? (
                  <span>
                    (
                    <a
                      onClick={() => setShowAdjustmentFactorModal(true)}
                      className="buttonLink"
                      disabled={formDisabled}
                    >
                      Create New Adjustment Factor
                    </a>
                    )
                  </span>
                ) : null}
              </div>
            }
            labelKey="name"
            options={adjustmentFactors}
            closeMenuOnSelect={false}
            isMulti
            {...sharedProps}
          />

          {isInventoryStream ? (
            <>
              <TextInput
                name="openingVolume"
                label="Opening Volume"
                type="number"
                unit={form.getValues("openingVolumeUnit")}
                {...sharedProps}
                isDisabled={true}
              />

              <TextInput
                name="totalVolume"
                label="Closing Volume"
                type="number"
                unit={form.getValues("unit")}
                {...sharedProps}
                isDisabled={formDisabled || closingVolumeDisabled}
                isRequired
              />
            </>
          ) : (
            <TextInput
              name="totalVolume"
              label="Total Volume"
              type="number"
              unit={form.getValues("unit")}
              isRequired
              {...sharedProps}
            />
          )}

          {form.getValues("adjustmentFactors")?.length > 1 &&
            action !== "view" && (
              <>
                <Title
                  type="subheader"
                  className="form__header"
                >
                  Adjustment Order
                </Title>

                <div className="volumeEntryModal__adjustmentOrderHint">
                  Drag and drop to reorder the priorities
                </div>

                <OrderedList
                  className="volumeEntryModal__adjustmentOrderList"
                  list={form.getValues("adjustmentFactors") ?? []}
                  onChange={(value) =>
                    form.setValue("adjustmentFactors", value)
                  }
                  labelKey="name"
                  rowHeight={60}
                  maxVisibleRows={5}
                  disabled={formDisabled}
                />
              </>
            )}
        </Modal.Body>

        <Modal.Footer className="clearfix">
          <Button
            className="pull-left"
            onClick={() => onHide()}
          >
            Close
          </Button>

          {action !== "view" && (
            <FormButton
              variant="primary"
              type="submit"
              className="pull-right"
              isLoading={formState === "loading"}
              disabled={formDisabled}
            >
              Update Volume
            </FormButton>
          )}
        </Modal.Footer>
      </Form>

      <AddAdjustmentFactorsModal
        show={showAdjustmentFactorModal}
        onHide={() => setShowAdjustmentFactorModal(false)}
        updateAdjustmentFactorList={addAdjustmentFactor}
      />
    </>
  );
};

VolumeEntryForm.propTypes = {
  volumeEntries: PropTypes.arrayOf(PropTypes.object),
  setVolumeEntries: PropTypes.func,
  adjustmentFactors: PropTypes.arrayOf(PropTypes.object),
  updateAdjustmentFactorList: PropTypes.func,
  havePermission: PropTypes.func,
  streamId: PropTypes.string,
  action: PropTypes.string,
  setShouldRefetchDailyVolume: PropTypes.func,
  streamAdjustmentConfigs: PropTypes.array,
  isInventoryStream: PropTypes.bool,
  haveAdjustmentFactorWritePermission: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default VolumeEntryForm;
