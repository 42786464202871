import { getMeasurementValue } from "#components/Instrument/InstrumentHelper";
import config from "#config";
import { formatNumber } from "@validereinc/utilities";
import round from "lodash/round";
import startCase from "lodash/startCase";
import upperFirst from "lodash/upperFirst";
import moment from "moment";
import { getDaysFromSeconds } from "./inputFormatter";

/** @deprecated outdated and smelly Ops Hub dependency */
export function getStringWithUnitFormat(value, unit) {
  if (unit) {
    return value + " " + unit;
  }
  return value;
}

/** @deprecated outdated and smelly Ops Hub dependency */
export function getUserString(name) {
  if (name) {
    return name;
  }

  return "Unavailable";
}

/** @deprecated outdated and smelly Ops Hub dependency */
export function getUserStringFromEmail(email) {
  if (email) {
    // Converting email to a string in case it is a number
    return `${email}`.split("@")[0];
  }

  return "-";
}

/** @deprecated outdated and smelly Ops Hub dependency */
export function getRiskLevelString(risk) {
  if (risk !== "info") {
    return risk + " risk";
  }

  return null;
}

/** @deprecated outdated and smelly Ops Hub dependency */
export function getLabelsString(string) {
  if (string) {
    return string.split(/_/g).join(" ");
  }

  return "";
}

/** @deprecated outdated and smelly Ops Hub dependency */
export function getProfileTitle(name, role) {
  if (name && role) {
    return name + ` (${role})`;
  } else if (name) {
    return name;
  } else {
    return "Unavailable";
  }
}

/** @deprecated outdated and smelly Ops Hub dependency */
export function getMagnitudeNumber(number, maxDigits) {
  return new Intl.NumberFormat(config.locale, {
    notation: "compact",
    maximumFractionDigits: maxDigits,
  }).format(number);
}

/** @deprecated outdated and smelly Ops Hub dependency */
export function getDollarValue(value, currency) {
  // this will format estimated Value to be CA$13,000,000
  if (value) {
    value = new Intl.NumberFormat(config.locale, {
      style: "currency",
      currency: currency,
      currencyDisplay: "symbol",
    }).format(value);

    // Internet Explorer 6-11
    const isIE = /*@cc_on!@*/ false || !!document.documentMode;

    // Edge 15 - 18
    const isEdge = !isIE && !!window.StyleMedia;

    // For IE and Edge (15 - 18) they use Windows Globalization libraries for the currency
    // string in comparison with other browsers that uses internationalization API
    // Under Windows Globalization they feel that it's unnatural for readers who
    // use to seeing symbol alone to mean their local currency to also see a country
    // prefix. That is why currencies like USD, CAD, MXN on IE and Edge display
    // without country prefix in front. However, on the latest Edge 80+ version
    // because of chromium they abandon Windows Globalization libraries so they
    // display with country prefix as well.
    if (isIE || isEdge) {
      return `${currency}${value}`;
    }
  }

  return value;
}

/**
 * If a component starts with `Mole Percentage`, `Mass Accumulation`, etc. and
 * it has its short form in brackets, use that else use the full name
 * Examples:
 *  Mass Percentage Helium (He) -> He
 *  Volume Percentage Isobutane (iC4) -> iC4
 *  Distillation Recovery (IBP-204 °C) -> Distillation Recovery (IBP-204 °C)
 *
 * Warning: If multiple C7 components used, will be unable to tell between them
 * e.g. Mass Percentage 2,2-dimethylpentane (*C7)
 *      Mass Percentage 2,2,3 - trimethylbutane (*C7)
 * @deprecated outdated and smelly Ops Hub dependency
 */
export function getComponentShortForm(component) {
  try {
    const componentShortForm =
      component.match(
        /(Mole|Volume|Mass) (Percentage|Accumulation).*\((.*?)\)/
      )?.[3] ?? component;

    return componentShortForm;
  } catch {
    return "-";
  }
}

/** @deprecated outdated and smelly Ops Hub dependency */
export function getAbsoluteValue(value, decimal = 2, errorMessage = "-") {
  if (typeof value === "number" || typeof value === "string") {
    const number = parseFloat(value);

    if (isNaN(number)) {
      return errorMessage;
    }

    if (typeof decimal !== "number" || isNaN(decimal)) {
      return errorMessage;
    }

    return Math.abs(number).toFixed(decimal);
  } else {
    return errorMessage;
  }
}

/** @deprecated outdated and smelly Ops Hub dependency */
export function getRoundedValue(value, decimal = 2, errorMessage = "-") {
  if (typeof value === "number" || typeof value === "string") {
    const number = parseFloat(value);

    if (isNaN(number) || !Number.isFinite(number)) {
      return errorMessage;
    }

    if (typeof decimal !== "number" || isNaN(decimal)) {
      return errorMessage;
    }

    return round(value, decimal);
  } else {
    return errorMessage;
  }
}

/** @deprecated outdated and smelly Ops Hub dependency */
export const getRangeString = (lowerLimit, upperLimit, unit) => {
  const formattedUnit = unit ? ` ${unit}` : "";
  if (lowerLimit !== "-" && upperLimit !== "-") {
    return `${lowerLimit} - ${upperLimit}${formattedUnit}`;
  }

  if (lowerLimit !== "-") {
    return `${lowerLimit}${formattedUnit} - N/A`;
  }

  if (upperLimit !== "-") {
    return `N/A - ${upperLimit}${formattedUnit}`;
  }

  return null;
};

/** @deprecated outdated and smelly Ops Hub dependency */
export const getSTDString = (sigma, min_samples, max_samples, daysRange) => {
  const STDStringArray = [];
  let STDString = "";

  if (sigma) {
    STDStringArray.push(sigma + "σ");
  }

  if (max_samples) {
    STDStringArray.push("n=" + max_samples);
  }

  if (daysRange && getDaysFromSeconds(daysRange) >= 1) {
    STDStringArray.push(getDaysFromSeconds(daysRange) + " days");
  }

  STDStringArray.forEach(function (value, index) {
    if (index === 0) {
      STDString += "(";
    }

    STDString += value;

    if (STDStringArray[index + 1]) {
      STDString += ", ";
    }

    if (index === STDStringArray.length - 1) {
      STDString += ")";
    }
  }, STDString);

  return STDString;
};

/** @deprecated outdated and smelly Ops Hub dependency */
export const getBalanceString = (deviation) => {
  if (deviation) {
    return `+/- ${deviation} %`;
  }
  return null;
};

/** @deprecated outdated and smelly Ops Hub dependency */
export const getMinutesFromSeconds = (seconds) => {
  return seconds / 60;
};

/** @deprecated outdated and smelly Ops Hub dependency */
export const getInlineMonitorString = (
  range,
  numOffSpecThreshold,
  intervalSeconds
) => {
  if (!range) {
    return null;
  }
  const intervalInMinutes = getMinutesFromSeconds(intervalSeconds);
  return `${range}, ${numOffSpecThreshold} samples over ${intervalInMinutes} mins`;
};

/** @deprecated outdated and smelly Ops Hub dependency */
export const getAlertParameter = (alert, alertType) => {
  let lowerLimit, upperLimit, range;
  switch (alertType) {
    case "limit_alerts":
      lowerLimit = getMeasurementValue(alert.lower_limit);
      upperLimit = getMeasurementValue(alert.upper_limit);
      return getRangeString(lowerLimit, upperLimit, alert.measurement_unit);
    case "statistical_alerts":
      return getSTDString(
        alert.std_sigma,
        alert.std_min_num_measurements,
        alert.std_num_measurements,
        alert.std_seconds_in_past
      );
    case "balance_alerts":
      return getBalanceString(
        alert.roq_previous_total_vol_percentage_deviation
      );
    case "inline_monitors":
      lowerLimit = getMeasurementValue(alert.lower_limit);
      upperLimit = getMeasurementValue(alert.upper_limit);
      range = getRangeString(lowerLimit, upperLimit, "");
      return getInlineMonitorString(
        range,
        alert.num_offspec_threshold,
        alert.interval_seconds
      );
    default:
      return null;
  }
};

/** @deprecated outdated and smelly Ops Hub dependency */
export const getBeautifiedAlertType = (alertType) => {
  if (alertType === "inline_monitors") {
    return "Inline Alerts";
  }
  return startCase(alertType);
};

/** @deprecated outdated and smelly Ops Hub dependency */
export const getPluralizedString =
  (singularForm, pluralForm = `${singularForm}s`) =>
  (count) =>
    count === 1 ? singularForm : pluralForm;

export function getFirstName(fullName) {
  if (fullName) {
    return fullName.split(" ")[0];
  }

  return "";
}

/** @deprecated outdated and smelly Ops Hub dependency */
export function getDateFromYearMonth(yearMonth, format) {
  // YearMonth string example 202201 for January, 2022
  const LAST_YEAR_STRING_INDEX = 4;
  const LAST_MONTH_STRING_INDEX = 5;

  if (yearMonth && format) {
    const year = yearMonth.split("").splice(0, LAST_YEAR_STRING_INDEX).join("");
    const month =
      yearMonth
        .split("")
        .splice(LAST_YEAR_STRING_INDEX, LAST_MONTH_STRING_INDEX)
        .join("") - 1; // moment month starts with 0

    return moment().set({ year, month, date: 1 }).startOf("day").format(format);
  }

  return undefined;
}

export const getYearMonthAsObject = (yearMonth) => {
  // YearMonth string example 202209 for September, 2022
  const LAST_YEAR_CHAR_STRING_INDEX = 4;

  const year = yearMonth?.substring?.(0, LAST_YEAR_CHAR_STRING_INDEX) ?? ""; // 2022
  const month = yearMonth?.substring?.(LAST_YEAR_CHAR_STRING_INDEX) ?? ""; // 09

  return { year, month };
};

export function getShortMonth(month) {
  if (month) {
    return moment(month, "MMMM").format("MMM");
  }

  return undefined;
}

/** @deprecated outdated and smelly Ops Hub dependency */
export const getConvertedUnitString = (
  value,
  converterFunction,
  unit,
  numDecimalsRounded = 2
) => {
  const convertedValue = converterFunction(value);
  const formattedNumber = formatNumber(convertedValue, {
    fractionDigits: numDecimalsRounded,
  });

  return !Number.isNaN(convertedValue)
    ? formattedNumber !== null
      ? `${formattedNumber} ${unit}`
      : "-"
    : "-";
};

/** @deprecated outdated and smelly Ops Hub dependency */
const operatingConditionsKeyFormatters = {
  n2o: "N2O",
  hhv: "HHV",
  co2: "CO2",
};

/** @deprecated outdated and smelly Ops Hub dependency */
// using lodash startCase on n20 => N 2 O and similar results with "molecules"
const splitAndCapitalize = (string: string, separator = "_") => {
  return string
    .split(separator)
    .map((str) => upperFirst(str))
    .join(" ");
};

/** @deprecated outdated and smelly Ops Hub dependency */
// NEOC5
const isMolecule = (string) => {
  return string.length < 6;
};

/** @deprecated outdated and smelly Ops Hub dependency */
export const getFormattedNestedProperty = (property) => {
  let result = property;

  Object.entries(operatingConditionsKeyFormatters).forEach(([key, value]) => {
    result = result.replace(key, value);
  });

  const splitResult = result.split(".");

  switch (splitResult.length) {
    case 1:
      return splitAndCapitalize(splitResult[0]);
    case 2: {
      const category = splitAndCapitalize(splitResult[0]);
      const type = isMolecule(splitResult[1])
        ? splitResult[1].toUpperCase()
        : splitAndCapitalize(splitResult[1]);

      return `${category} (${type})`;
    }
    case 3: {
      const category = splitAndCapitalize(splitResult[0]);
      const subCategory = splitAndCapitalize(splitResult[1]);
      const type = isMolecule(splitResult[2])
        ? splitResult[2].toUpperCase()
        : splitAndCapitalize(splitResult[2]);

      return `${category} - ${subCategory} (${type})`;
    }
    default:
      return result;
  }
};
