import { KeyValuePanel } from "@validereinc/common-components";
import React, { ReactNode } from "react";

const EstimationMethodDetailsPanel = ({
  data,
  isLoading,
  templatedConfigurationLink,
}: EstimationMethodDetailsPanelProps) => {
  const panelData = [
    ...(templatedConfigurationLink
      ? [
          {
            title: "Templated Configuration",
            value: templatedConfigurationLink,
          },
        ]
      : []),
    ...(data ?? []),
  ];

  return (
    <KeyValuePanel
      panelMaxColumnCount={2}
      panelKeyValueListProps={{ maxRowCount: 6, variant: "shaded" }}
      panelProps={{
        isFluidY: false,
        loaded: !isLoading,
        style: { marginBottom: 0, alignSelf: "flex-start" },
      }}
      data={panelData}
    />
  );
};

// TODO: reference type from KeyValuePanel component
export type EstimationMethodDetailsPanelProps = {
  data?: Array<{
    title: string | React.ReactNode;
    value: string | React.ReactNode;
    description?: string;
  }>;
  isLoading?: boolean;
  templatedConfigurationLink?: ReactNode;
};

export default EstimationMethodDetailsPanel;
