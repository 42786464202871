import { getBarWidth } from "#components/Records/Volume/DailyVolume/DailyVolumeChartHelper";
import commonStyles from "@validereinc/common-components/constants";
import { formatNumberValueAndUnit } from "@validereinc/utilities";
import classNames from "classnames/bind";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { AutoSizer } from "react-virtualized";
import {
  ChartLabel,
  Hint,
  HorizontalGridLines,
  VerticalBarSeries,
  XAxis,
  XYPlot,
  YAxis,
} from "react-vis";
import { DEFAULT_EMISSION_DECIMAL } from "../../../EmissionConstants";
import styles from "../EmissionReportDetail.module.scss";

const cx = classNames.bind(styles);

const BAR_WIDTH = 30;

const FacilityDetailChart = ({ facilitySummary, pollutant }) => {
  const [hintData, setHintData] = useState();
  const [facilityBarData, setFacilityBarData] = useState([]);
  const [pollutantUnit, setPollutantUnit] = useState("");

  function getHintFormat(data) {
    return [
      {
        title: "Facility",
        value: data.x,
      },
      {
        title: "Value",
        value: formatNumberValueAndUnit(data.value, {
          fractionDigits: DEFAULT_EMISSION_DECIMAL,
        }),
      },
    ];
  }

  useEffect(() => {
    if (facilitySummary.length > 0) {
      const chartData = facilitySummary.map((facility) => {
        return {
          title: facility.name,
          value: facility.total_emissions[pollutant],
          x: facility.facility_code,
          y: facility.total_emissions[pollutant].value,
        };
      });

      setFacilityBarData(chartData);
      setPollutantUnit(chartData?.[0]?.value?.unit ?? "-");
    }
  }, [facilitySummary, pollutant]);

  return (
    <div className={cx("emissionChart")}>
      <AutoSizer>
        {({ width, height }) => (
          <XYPlot
            width={width}
            height={height}
            xType="ordinal"
            colorType="literal"
            margin={{ left: 100 }}
          >
            <HorizontalGridLines />

            <VerticalBarSeries
              data={facilityBarData}
              barWidth={getBarWidth(width, facilityBarData.length, BAR_WIDTH)}
              onValueMouseOver={(value) => setHintData(value)}
              onValueMouseOut={() => setHintData(null)}
              style={{
                stroke: commonStyles.primary["500"],
                fill: commonStyles.primary["500"],
              }}
            />

            <YAxis
              tickFormat={(data) => data}
              style={{ line: { stroke: "none" } }}
            />

            <XAxis />

            <ChartLabel
              text={`${pollutant} (${pollutantUnit})`}
              xPercent={0.01}
              yPercent={0.25}
              style={{
                transform: "rotate(-90)",
                style: {
                  textAnchor: "middle",
                },
              }}
            />

            {hintData && (
              <Hint
                value={hintData}
                format={(data) => getHintFormat(data)}
                style={{
                  width: "200px",
                }}
              />
            )}
          </XYPlot>
        )}
      </AutoSizer>
    </div>
  );
};

FacilityDetailChart.propTypes = {
  facilitySummary: PropTypes.array.isRequired,
  pollutant: PropTypes.string.isRequired,
};

export default FacilityDetailChart;
