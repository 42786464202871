import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Dialog,
  DialogProps,
  Form,
  TextInput,
  useForm,
  useToast,
} from "@validereinc/common-components";
import { FormCategoryAdapter, Resources } from "@validereinc/domain";
import React from "react";

type FormCategoryFormValues = {
  name: string;
};

export const CREATE_FORM_CATEGORY_DIALOG_TITLE = "Create Form Category";

export const CreateFormCategoryDialog = ({
  isOpen,
  onClose,
}: Pick<DialogProps, "isOpen" | "onClose">) => {
  const { toast } = useToast();
  const form = useForm<FormCategoryFormValues>();
  const queryClient = useQueryClient();

  const mutationFn = async (formValues: FormCategoryFormValues) =>
    await FormCategoryAdapter.createOne({ data: formValues });

  const onCloseCallback = () => {
    form.reset();

    onClose();
  };

  const onSuccess = () => {
    queryClient.invalidateQueries({
      queryKey: [Resources.FORM_CATEGORY],
    });

    toast.push({
      intent: "success",
      description: "Successfully created form category.",
    });

    onCloseCallback();
  };

  const onError = () => {
    console.error("Error creating form category");

    toast.push({
      intent: "error",
      description: "There was an error creating the form category.",
    });
  };

  const mutation = useMutation({
    mutationFn,
    onSuccess,
    onError,
  });

  const onSubmit = form.handleSubmit((formValues) =>
    mutation.mutate(formValues)
  );

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onCloseCallback}
      title={CREATE_FORM_CATEGORY_DIALOG_TITLE}
      actionRow={[
        <Button
          key="create-form-category-action"
          onClick={onSubmit}
          variant="primary"
        >
          {CREATE_FORM_CATEGORY_DIALOG_TITLE}
        </Button>,
      ]}
    >
      <Form {...form}>
        <TextInput
          name="name"
          isRequired
          label="Name"
        />
      </Form>
    </Dialog>
  );
};
