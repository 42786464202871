import { Tooltip } from "@validereinc/common-components";
import { formatNumber } from "@validereinc/utilities";
import * as PropTypes from "prop-types";
import React from "react";
import FontAwesome from "react-fontawesome";
import { getModelName } from "../Instrument/InstrumentHelper";
import { getTypeName } from "./LabResultsHelper";
import "./LabResultsTest.scss";

const LabResultsTest = (props) => {
  const { sampleDetail, test, measurementType, showType } = props;

  const hasTestNote = test && test.note_count > 0;

  const measurement = test.measurements[measurementType.name];
  const measurementValue =
    formatNumber(measurement.value, {
      fractionDigits: measurementType.decimals,
    }) ?? "-";

  const testNoteIcon = hasTestNote && (
    <FontAwesome
      className="icon"
      name="comments"
    />
  );

  const label = getModelName(test.instrument?.model) ?? getTypeName(test.type);

  return (
    <div className="labResultsTest">
      {showType && (
        <div className="labResultsTest__label">
          <Tooltip
            content={test.instrument?.name ?? label}
            className="labResultsTest__tooltip"
          >
            {label}
          </Tooltip>

          {testNoteIcon}
        </div>
      )}

      <div className="labResultsTest__value">
        <div
          className={`buttonLink ${measurement.is_offspec ? "alert" : null}`}
          onClick={() =>
            props.onTestClick(test.id, sampleDetail, measurementType.name)
          }
        >
          {measurementValue}
        </div>
      </div>
    </div>
  );
};

LabResultsTest.propTypes = {
  analyzeBookmarks: PropTypes.array,
  analyzeBookmarkClicked: PropTypes.func,
  sampleDetail: PropTypes.object,
  test: PropTypes.object,
  measurementType: PropTypes.object,
  showType: PropTypes.bool,
  redirectToAnalyze: PropTypes.func,
  getFormattedMeasurementValue: PropTypes.func,
  onTestClick: PropTypes.func.isRequired,
};

export default LabResultsTest;
