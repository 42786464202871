import { useNavigate } from "#src/Routers/hooks";
import PermissionAwareExportButton from "#src/batteries-included-components/Buttons/PermissionAwareExportButton/PermissionAwareExportButton";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useTableSortingAndPagination } from "#src/components/Redux/reducers/tableStateReducer";
import { useExportUsers } from "#src/components/hooks/adapters/useUsers";
import { useAuthenticatedContext } from "#src/contexts/AuthenticatedContext.helpers";
import { linkToRoleDetailPage } from "#src/routes/settings/roles-and-permissions/roles/[roleId]/detail";
import { linkToCreateUserPage } from "#src/routes/settings/users/create";
import { linkToUserDetailPage } from "#src/routes/settings/users/detail";
import { UserGroupDetailsRoutePath } from "#src/routes/settings/users/groups/[groupId]/details";
import { useQuery } from "@tanstack/react-query";
import {
  Button,
  DataTable,
  DataTablePanel,
  HeaderType,
  Pill,
  StorageKeys,
  Tag,
} from "@validereinc/common-components";
import {
  FilterObjectType,
  SortDirection,
  UserType,
  UsersAdapter,
} from "@validereinc/domain";
import { toStartCaseString } from "@validereinc/utilities";
import React from "react";
import {
  UsersTableFilterArea,
  UsersTableTitleDecorationFilterArea,
} from "../../FilterAreas/UsersFilterAreas/UsersFilterAreas";
import { useSessionStickyState } from "#src/hooks/useStickyState";

export const UserListTablePanel = ({
  filterConfigStorageKey,
  tableConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey" | "tableConfigStorageKey">) => {
  const {
    claims: {
      user: { id: currentUserId },
    },
  } = useAuthenticatedContext();
  const navigate = useNavigate();
  const [tableFilters] = useSessionStickyState<
    FilterObjectType<Partial<UserType>>
  >({}, filterConfigStorageKey);
  const [tableState, updateTableState] = useTableSortingAndPagination(
    {
      sortBy: "name",
      sortDirection: SortDirection.ASCENDING,
    },
    tableFilters
  );
  const queryPayload: Parameters<typeof UsersAdapter.getList>[0] = {
    page: tableState.page,
    pageSize: tableState.pageSize,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: tableFilters,
  };

  const users = useQuery(
    ["users", queryPayload],
    () => UsersAdapter.getList(queryPayload),
    {
      staleTime: 3 * 60 * 1000,
    }
  );

  const { mutate: handleExport, isLoading: isExporting } = useExportUsers({
    filters: tableFilters,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
  });

  const headers: Array<HeaderType<UserType>> = [
    {
      label: "Name",
      key: "name",
      isSortable: true,
      renderComponent: ({ item }) => {
        const userId = item?.id;

        if (!userId) {
          return null;
        }

        return (
          <RoutingLink
            to={linkToUserDetailPage(userId)}
          >{`${item.name}${currentUserId === userId ? " (You)" : ""}`}</RoutingLink>
        );
      },
    },
    {
      label: "Status",
      key: "status",
      isSortable: true,
      renderComponent: ({ item }) => (
        <Pill variant={item.status === "active" ? "success" : "default"}>
          {toStartCaseString(item.status)}
        </Pill>
      ),
    },
    {
      label: "Email",
      key: "email",
      isSortable: true,
      renderComponent: ({ item }) => item.email,
    },
    {
      label: "Roles",
      key: "roles",
      renderComponent: ({ item }) => {
        if (!item.roles?.length) {
          return "-";
        }

        const rolesToRender = item.roles.map((role) => (
          <Tag
            key={role.id}
            value={
              <RoutingLink to={linkToRoleDetailPage(role.id)}>
                {role?.name ?? "-"}
              </RoutingLink>
            }
          />
        ));

        if (item.roles_count > item.roles.length) {
          rolesToRender.push(
            <Tag value={`+${item.roles_count - item.roles.length} more`} />
          );
        }

        return (
          <div
            style={{
              display: "flex",
              gap: 8,
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            {rolesToRender}
          </div>
        );
      },
    },
    {
      label: "Groups",
      key: "groups",
      renderComponent: ({ item }) => {
        if (!item.user_groups?.length) {
          return "-";
        }

        const groupsToRender = item.user_groups.map((group) => (
          <Tag
            key={group.id}
            value={
              <RoutingLink
                to={UserGroupDetailsRoutePath.toLink({
                  pathParams: { groupId: group.id },
                })}
              >
                {group?.name ?? "-"}
              </RoutingLink>
            }
          />
        ));

        if (item.user_groups_count > item.user_groups.length) {
          groupsToRender.push(
            <Tag
              value={`+${item.user_groups_count - item.user_groups.length} more`}
            />
          );
        }

        return (
          <div
            style={{
              display: "flex",
              gap: 8,
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            {groupsToRender}
          </div>
        );
      },
    },
    {
      label: "Created At",
      key: "created_at",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={item.created_at}
          withTime
        />
      ),
    },
  ];

  const actionRowWhenNoRowsSelected = [
    <PermissionAwareExportButton
      key="export-users"
      onClick={handleExport}
      isExporting={isExporting}
    />,
    <Button
      key="add-user"
      variant="primary"
      onClick={() => navigate({ pathname: linkToCreateUserPage() })}
      iconPosition="left"
      icon="plus-circle"
      size="small"
      style={{ border: "none" }}
    >
      Create User
    </Button>,
  ];
  return (
    <DataTablePanel
      storageKey={tableConfigStorageKey}
      actionRowWhenNoRowsSelected={actionRowWhenNoRowsSelected}
      dataTableProps={{
        headers,
        items: users.data?.data ?? [],
        isLoading: users.isLoading,
        pagination: {
          page: tableState.page,
          pageSize: tableState.pageSize,
          total: users.data?.total_entries,
        },
        sorting: {
          sortBy: tableState.sortBy,
          sortDirection: tableState.sortDirection,
        },
        onPaginationChange: updateTableState,
        onSortChange: updateTableState,
      }}
      filterComponent={
        <UsersTableFilterArea filterConfigStorageKey={filterConfigStorageKey} />
      }
      panelProps={{
        title: "Users",
        titleDecorator: (
          <UsersTableTitleDecorationFilterArea
            filterConfigStorageKey={filterConfigStorageKey}
          />
        ),
      }}
    />
  );
};
