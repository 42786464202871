import Map from "#components/Map/Map";
import Marker from "#components/Map/Marker/Marker";
import { DeviceType } from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { type ReactNode } from "react";
import OverviewPanel from "./DeviceOverviewPanel";
import styles from "./OverviewPanel.module.scss";
import { MeasurementSeriesTablePanel } from "#src/batteries-included-components/Panels/TablePanels/MeasurementSeriesTablePanel";

const cx = classNames.bind(styles);

const DeviceDetailOverviewTab = ({
  deviceDetail,
  isLoading,
  viewFilterAreaSlot,
}: {
  deviceDetail?: DeviceType;
  isLoading: boolean;
  viewFilterAreaSlot: ReactNode;
}) => {
  const deviceLocation = deviceDetail?.attributes?.location;
  const initialViewState = {
    latitude: deviceLocation?.[0],
    longitude: deviceLocation?.[1],
  };

  const isDisplayMap = deviceLocation && !isLoading;

  return (
    <>
      {viewFilterAreaSlot}
      <div className={cx("overviewAndMapSection")}>
        <OverviewPanel
          deviceDetail={deviceDetail}
          isLoading={isLoading}
          isDisplayMap={isDisplayMap}
        />
        {isDisplayMap &&
        initialViewState.latitude &&
        initialViewState.longitude ? (
          <Map
            initialViewState={initialViewState}
            subtractFromHeightOnResize={16}
          >
            <Marker
              latitude={initialViewState.latitude}
              longitude={initialViewState.longitude}
              label={deviceDetail.name}
            />
          </Map>
        ) : null}
      </div>
      <MeasurementSeriesTablePanel deviceId={deviceDetail?.id} />
    </>
  );
};

export default DeviceDetailOverviewTab;
