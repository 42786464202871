import { convertDotsToUnderscores } from "@validereinc/utilities";
import mime from "mime";

/**
 * A map of extensions to mimetypes that are not supported by the current mime package.
 */
const extensionsToMimetypes: Record<string, string> = {
  log: "text/plain",
  vsdx: "application/vnd.visio",
  vsd: "application/vnd.visio",
  vsdm: "application/vnd.visio",
  vdw: "application/vnd.visio",
  pmx: "application/x-pmx",
  jsonl: "application/json",
};

/**
 * Given a file name, convert it to a format compatible with the data platform
 * back-end. The data platform (specifically the file upload service), currently
 * has a restriction that the file name only have a single dot in it
 * corresponding to the file extension e.g. "file.jpg" or
 * "file_month_day_year.jpg", but not "file" or "file_month.day.year.jpg" or
 * "file month.day.year.jpg" etc.
 * @see https://sources-staging.dataplatform.validere.xyz/docs#/data/get_file_upload_url_data_file_upload_url_get
 * @param fileName a file name string with or without the extension
 * @param mimeType if the extension is not guaranteed to be in the file name,
 * provide a valid MIME type string so the extension can be guessed. If the
 * extension is not in the fileName and the MIME type isn't provided, the
 * fileName will be returned as-is.
 * @returns a file name safe to use with the file upload service
 */
export const getSafeFileName = (fileName: string, mimeType?: string) => {
  if (!fileName) {
    return fileName;
  }

  const matches = fileName.match(/\./g);

  if (!matches || matches.length <= 1) {
    if (!mimeType) {
      return fileName;
    }

    const guessedExtension = mime.getExtension(mimeType);

    if (!guessedExtension) {
      return fileName;
    }

    return `${fileName}.${guessedExtension}`;
  }

  // replace the very last "_" with a "." with the assumption that the text after that is the extension
  return convertDotsToUnderscores(fileName).replace(/_(?=[^_]*$)/, ".");
};

/**
 * Ensure that the MIME type based on a given file name matches the guessed version from the mime library and always fallback to the MIME library if the provided mimeType is invalid or unknown
 * @param fileName a file name string direct from a File type object
 * @param mimeType the MIME type string direct from a File type object
 * @returns the string MIME type if valid, null otherwise
 */
export const getSafeFileMimeType = (fileName: string, mimeType?: string) => {
  const guessedMimeType = mime.getType(fileName);

  if (!guessedMimeType) {
    const extension = fileName.split(".")[1];
    if (Object.hasOwn(extensionsToMimetypes, extension)) {
      return extensionsToMimetypes[extension];
    }
  }

  return mimeType !== guessedMimeType ? guessedMimeType : mimeType;
};
