import {
  chartTickDateTimeFormatter,
  dateFormatter,
  DateTimeRangeStrictType,
  fromUTC,
  monthFormatter,
  yearFormatter,
  yearMonthFormatter,
} from "@validereinc/utilities";
import formatDistanceStrict from "date-fns/formatDistanceStrict";
import parseISO from "date-fns/parseISO";
import eachMonthOfInterval from "date-fns/eachMonthOfInterval";
import isBefore from "date-fns/isBefore";
import isSameMonth from "date-fns/isSameMonth";
import moment from "moment";

const ONE_MINUTE = {
  id: "1minute",
  name: "1 Minute",
  formatter: chartTickDateTimeFormatter,
  showTime: true,
};

export const FIVE_MINUTES = {
  id: "5minute",
  name: "5 Minutes",
  formatter: chartTickDateTimeFormatter,
  showTime: true,
};

export const FIFTEEN_MINUTES = {
  id: "15minute",
  name: "15 Minutes",
  formatter: chartTickDateTimeFormatter,
  showTime: true,
};

export const ONE_HOUR = {
  id: "1hour",
  name: "1 Hour",
  formatter: chartTickDateTimeFormatter,
  showTime: true,
};

const ONE_DAY = {
  id: "1day",
  name: "1 Day",
  tickFormat: "MMM DD, YYYY",
  formatter: dateFormatter,
  showTime: false,
};

const SEVEN_DAYS = {
  id: "7day",
  name: "1 Week",
  tickFormat: "MMM DD, YYYY",
  formatter: dateFormatter,
  showTime: false,
};

const THIRTY_DAYS = {
  id: "30day",
  name: "30 Days",
  tickFormat: "YYYY MMM",
  formatter: monthFormatter,
  showTime: false,
};

const ONE_YEAR = {
  id: "365day",
  name: "1 year",
  tickFormat: "YYYY",
  formatter: yearFormatter,
  showTime: false,
};

export const getIntervalOptions = (dateRange: DateTimeRangeStrictType) => {
  const { from, to } = dateRange;

  const diff = moment(to, "DD-MM-YYYY").diff(
    moment(from, "DD-MM-YYYY"),
    "days"
  );

  // 1 day
  if (diff <= 1) {
    return [ONE_MINUTE, FIVE_MINUTES, FIFTEEN_MINUTES, ONE_HOUR];
  }

  // 1 week
  if (diff <= 7) {
    return [FIFTEEN_MINUTES, ONE_HOUR, ONE_DAY];
  }

  // ~1 month
  if (diff <= 30) {
    return [ONE_HOUR, ONE_DAY];
  }

  // 1 year
  if (diff <= 365) {
    return [ONE_DAY, SEVEN_DAYS, THIRTY_DAYS];
  }

  // 5 years
  if (diff <= 1825) {
    return [SEVEN_DAYS, THIRTY_DAYS];
  }

  if (diff > 1825) {
    return [THIRTY_DAYS, ONE_YEAR];
  }
};

/**
 * Checks if a Date object is holding a valid date value
 * @param date any date
 * @returns true if the Date is valid, false otherwise
 */
export const isValidDate = (date: Date) =>
  date instanceof Date && !isNaN(date.valueOf());

/**
 * Given two dates, return all the months between them
 * @returns an array of dates b/w the provided from and to dates representing a
 * single unique month each inc. the from and to
 */
export const getIntervalInMonths = ({
  from,
  to,
}: {
  /** the date the interval starts from */
  from: Date;
  /** the date the interval ends at */
  to: Date;
}) => {
  if (!isValidDate(from) || !isValidDate(to)) {
    return [];
  }

  if (isSameMonth(from, to)) {
    return [from];
  }

  // if the to is before the from, swap the dates
  if (isBefore(to, from)) {
    [from, to] = [new Date(to.getTime()), from];
  }

  return eachMonthOfInterval({ start: from, end: to });
};

export const hydrateDateRange = (dateRange: { from: string; to: string }) => ({
  from: new Date(dateRange?.from),
  to: new Date(dateRange?.to),
});

/**
 * Given two dates, return all the months in their interval, and run them through a formatter predicate
 * @see {@link getIntervalInMonths}
 * @returns
 */
export const getIntervalInMonthsFormatted = ({
  from,
  to,
  formatter = (intervalDate) => yearMonthFormatter(intervalDate),
}: {
  /** the date the interval starts from */
  from: Date;
  /** the date the interval ends at */
  to: Date;
  /** the formatter fn that takes in each date in the interval and converts it to a string date */
  formatter?: (intervalDate: Date) => string;
}) => getIntervalInMonths({ from, to }).map(formatter);

export const getRelativeTimeDifference = (
  laterDate: string | Date,
  earlierDate: string | Date
) => {
  const later =
    laterDate instanceof Date ? laterDate : fromUTC(parseISO(laterDate));
  const earlier =
    earlierDate instanceof Date ? earlierDate : fromUTC(parseISO(earlierDate));

  const diff = later.getTime() - earlier.getTime();
  if (diff === 0) {
    return "Due now";
  } else {
    return `Due ${formatDistanceStrict(later, earlier, { addSuffix: true })}`;
  }
};
