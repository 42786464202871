import { useBreadcrumbs } from "#src/Routers/breadcrumbsHelper";
import { useNavigate, useParams } from "#src/Routers/hooks";
import { linkToFormSubmissionDetail } from "#src/Routers/links";
import { DeleteDraftFormSubmissionDialog } from "#src/batteries-included-components/Dialogs/DeleteDraftFormSubmissionDialog";
import { FormSubmissionField } from "#src/batteries-included-components/Forms/FormSubmissionForm/FormSubmissionSections/FormSubmissionField";
import { FormSubmissionSection } from "#src/batteries-included-components/Forms/FormSubmissionForm/FormSubmissionSections/FormSubmissionSection";
import { useExportFormSubmissionAsPDF } from "#src/components/Forms/exportFormSubmission";
import {
  useCreateOneFormSubmissionRevision,
  useGetOneFormSubmission,
} from "#src/components/hooks/adapters/useFormSubmissions";
import { useGetManyUsers } from "#src/components/hooks/adapters/useUsers";
import { useAuthenticatedContext } from "#src/contexts/AuthenticatedContext.helpers";
import { useBlockNavigation } from "#src/hooks/useBlockNavigation";
import { FORMS_BREADCRUMB } from "#src/routes/forms";
import { FORMS_CATEGORIES_BREADCRUMB } from "#src/routes/forms/categories";
import { ExceptionUtils } from "#src/utils/exception";
import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  Banner,
  Button,
  Dialog,
  Form,
  Page,
  Tooltip,
  useForm,
  useToast,
} from "@validereinc/common-components";
import {
  AttributeDataType,
  AttributeDataTypeType,
  BaseError,
  FormCategoryAdapter,
  FormSchemaAdapter,
  FormSubmissionAdapter,
  FormSubmissionStatus,
  FormSubmissionType,
  Resources,
  UserType,
} from "@validereinc/domain";
import {
  getErrorCount,
  getSmartDefaultValues,
  useGenerateFieldsToWatch,
  useGenerateLookupQueries,
  useProcessAttributeLookupQueries,
  useProcessCalculatedFields,
} from "@validereinc/domain-controllers/logic/forms";
import { FormSubmissionFormController } from "@validereinc/domain-controllers/view/forms";
import { dateFormatter } from "@validereinc/utilities";
import classNames from "classnames/bind";
import { isValid as isValidDate } from "date-fns";
import React, { useMemo, useState } from "react";
import { UPDATE_FORM_SUBMISSION_BREADCRUMB } from ".";
import { FORM_TEMPLATE_DETAILS_BREADCRUMB, linkToFormTemplateDetail } from "..";
import { FORM_CATEGORY_DETAILS_BREADCRUMB } from "../../..";
import { DEFAULT_QUERY_OPTIONS } from "../../../../../../../components/hooks/adapters/adapterUtils";
import { ConfirmEditFormSubmissionDialog } from "./ConfirmEditFormSubmissionDialog";
import styles from "./UpdateFormSubmissionPage.module.scss";

const cx = classNames.bind(styles);

const BANNER_TITLE_TEXT =
  "You are editing an existing form submission. Saving any changes will create a new form submission and mark the existing one as outdated.";
const now = new Date().toISOString();

function getValueFromType(dataType: AttributeDataTypeType, value: any): any {
  switch (dataType) {
    // if it is a plain date there is no timezone
    // read it back in the local timezone so the date stays the same as it was serialized
    // if precision is required the underlying type must be a datetime and not a date
    case AttributeDataType.DATE: {
      const local = new Date(value + "T00:00:00");
      return isValidDate(local) ? local : value;
    }
    case AttributeDataType.DATE_TIME: {
      const date = new Date(value);
      return isValidDate(date) ? date : value;
    }
    case AttributeDataType.DATE_TIME_RANGE: {
      if (Array.isArray(value) && value.length === 2) {
        const dates = value.map((v: any) => new Date(v));
        return dates.some((date) => !isValidDate(date)) ? value : dates;
      } else {
        return value;
      }
    }
    default:
      return value;
  }
}

export const UpdateFormSubmissionPage = () => {
  const navigate = useNavigate();

  const {
    v2: {
      userInfo: { user },
    },
  } = useAuthenticatedContext();

  const { categoryId, formTemplateId, formSubmissionId } = useParams<{
    categoryId: string;
    formTemplateId: string;
    formSubmissionId: string;
  }>();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isCancelSaveDialogOpen, setIsCancelSaveDialogOpen] = useState(false);
  const [isConfirmEditDialogOpen, setIsConfirmEditDialogOpen] = useState(false);
  const [isBannerDismissed, setIsBannerDismissed] = useState(false);
  const [editedFormValues, setEditedFormValues] =
    useState<Pick<FormSubmissionType, "answers">>();
  const { toast } = useToast();

  const formSubmissionQuery = useGetOneFormSubmission({
    id: formSubmissionId,
  });

  const formSubmission = formSubmissionQuery.data?.data;
  const isFormSubmissionDraft =
    formSubmission?.status === FormSubmissionStatus.DRAFT;

  const templateQuery = useQuery({
    queryKey: [
      Resources.FORM_SCHEMA,
      formTemplateId,
      formSubmission?.form_schema_version,
    ] as const,
    queryFn: ({ queryKey: [_, formSchemaId, formSchemaVersion] }) =>
      FormSchemaAdapter.getOne({
        id: formSchemaId,
        meta: { version: formSchemaVersion },
      }),
    ...DEFAULT_QUERY_OPTIONS,
    select: (resp) => resp?.data,
  });

  const categoryQuery = useQuery({
    queryKey: [Resources.FORM_CATEGORY, categoryId],
    queryFn: async ({ queryKey }) => {
      const [_, id] = queryKey;

      return await FormCategoryAdapter.getOne({
        id,
      });
    },
  });

  const metaUsersQueries = useGetManyUsers(
    Array.from(
      new Set(
        [formSubmission?.created_by, formSubmission?.updated_by].filter(
          (id) => !!id
        ) as string[]
      )
    )
  );

  const metaUsersMap = useMemo(() => {
    return (
      metaUsersQueries?.reduce<Record<string, UserType>>((map, query) => {
        if (!query.data) {
          return map;
        }

        map[query.data.id] = query.data;
        return map;
      }, {}) ?? {}
    );
  }, [metaUsersQueries]);

  const form = useForm<Pick<FormSubmissionType, "answers">>({
    defaultValues: {
      // IMPROVE: split into it's own function
      answers: formSubmission?.answers
        ? Object.fromEntries(
            Object.entries(formSubmission?.answers).map(
              ([key, answersArray]) => [
                key,
                answersArray?.length
                  ? answersArray
                      .map((answers) =>
                        answers
                          ? Object.fromEntries(
                              Object.entries(answers).map(
                                ([key, { value }]) => {
                                  const dataType =
                                    templateQuery?.data?.config?.questions?.[
                                      key
                                    ]?.data_type;

                                  return [
                                    key,
                                    {
                                      value: getValueFromType(dataType, value),
                                    },
                                  ];
                                }
                              )
                            )
                          : null
                      )
                      .filter((answers) => !!answers)
                  : [{}],
              ]
            )
          )
        : {},
    },
  });

  const formValues = form.watch();

  const formSchema = templateQuery.data;

  const calculatedDefaultAnswers = getSmartDefaultValues(formSchema, {
    now,
    currentUserName: user?.name,
  });

  const { fieldsToWatch, evaluationOrder } = useGenerateFieldsToWatch({
    formSchema,
    formValues,
  });

  const attributeLookupQueryDetails = useGenerateLookupQueries({
    watchedFields: fieldsToWatch,
    formValues,
    dirtyFields: form.formState.dirtyFields,
  });

  const attributeLookupQueries = useQueries({
    queries: attributeLookupQueryDetails.map((q) => q.queryProps),
  });

  const { fieldsNamesToDisable } = useProcessAttributeLookupQueries({
    queries: attributeLookupQueries,
    queriesDetails: attributeLookupQueryDetails,
    watchedFields: fieldsToWatch,
    form,
    formValues,
    formSchema,
  });

  const watchedFieldsState = useProcessCalculatedFields({
    evaluationOrder,
    watchedFields: fieldsToWatch,
    form,
    formValues,
    formSchema,
  });

  const { isDirty, dirtyFields } = form.formState;
  const queryClient = useQueryClient();
  const [, unblockNavigation] = useBlockNavigation(
    isDirty || !!Object.keys(dirtyFields?.answers ?? {}).length,
    () => setIsCancelSaveDialogOpen(true)
  );

  const breadcrumbs = useBreadcrumbs(
    [
      FORMS_BREADCRUMB,
      FORMS_CATEGORIES_BREADCRUMB,
      FORM_CATEGORY_DETAILS_BREADCRUMB,
      FORM_TEMPLATE_DETAILS_BREADCRUMB,
      UPDATE_FORM_SUBMISSION_BREADCRUMB,
    ],
    {
      2: categoryQuery?.data?.name,
      3: templateQuery?.data?.name,
    }
  );

  const mutation = useMutation({
    mutationFn: (
      params: Parameters<typeof FormSubmissionAdapter.updateOne>[0]
    ) => {
      return FormSubmissionAdapter.updateOne(params);
    },
    onSuccess: (data, ogData) => {
      unblockNavigation({ redirect: false });
      toast.push({
        intent: "success",
        description:
          ogData.data.status !== "draft"
            ? "Successfully created form submission."
            : "Successfully saved form as draft.",
        slotRight: ({ buttonProps }) => (
          <Button
            {...buttonProps}
            onClick={() =>
              navigate({
                pathname: linkToFormSubmissionDetail(
                  (data as { data: FormSubmissionType }).data.id
                ),
              })
            }
          >
            View Details
          </Button>
        ),
      });
    },
    onError: (err, ogData) => {
      unblockNavigation({ redirect: false });
      ExceptionUtils.reportException(err, "error", {
        hint:
          ogData.data.status !== "draft"
            ? "Failed to create form submission from draft."
            : "Failed to save form draft.",
      });
      toast.push({
        intent: "error",
        description:
          ogData.data.status !== "draft"
            ? "Failed to create form submission from draft."
            : "Failed to save form draft.",
      });
    },
  });

  const revisionMutation = useCreateOneFormSubmissionRevision({
    onSuccess: () => {
      unblockNavigation({ redirect: false });
    },
    noAlerts: true,
  });

  const patchFormValuesForMutation = (
    formValues: Parameters<typeof FormSubmissionAdapter.updateOne>[0]["data"]
  ) => {
    let status = formValues.status;

    if (!status) {
      status =
        templateQuery?.data?.form_submission_default_status ?? "submitted";
    }
    return {
      ...formValues,
      status,
    };
  };

  const exportPDFMutation = useExportFormSubmissionAsPDF({
    includeEmptyAnswers: true,
    showUpdatedAt: true,
    metaUserDataMap: metaUsersMap,
  });

  const onCancel = () => {
    navigate.goBack();
  };
  const onSubmit = form.handleSubmit(
    (formValues) => {
      if (isFormSubmissionDraft) {
        mutation.mutate(
          {
            id: formSubmissionId,
            data: patchFormValuesForMutation(formValues),
          },
          {
            onSuccess: () => {
              queryClient.invalidateQueries({
                queryKey: ["forms", "submissions"],
              });

              navigate({
                pathname: linkToFormTemplateDetail(categoryId, formTemplateId),
                query: {
                  "submission-type": "all",
                },
                replace: true,
              });
            },
          }
        );
      } else {
        setEditedFormValues(formValues);
        setIsConfirmEditDialogOpen(true);
      }
    },
    (err) => {
      unblockNavigation({ redirect: false });
      toast.push({
        intent: "error",
        description: "Failed to submit form",
      });
      ExceptionUtils.reportException(err.root, "error", {
        hint: "Failed to submit form at the react hook form level",
        sourceComponent: "UpdateFormSubmissionPage.tsx",
      });
    }
  );
  const onSaveAsDraft = () => {
    const formValues = form.getValues();

    unblockNavigation({ redirect: false });

    return mutation.mutateAsync(
      {
        id: formSubmissionId,
        data: patchFormValuesForMutation({ ...formValues, status: "draft" }),
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: ["forms", "submissions"],
          });

          navigate({
            pathname: linkToFormTemplateDetail(categoryId, formTemplateId),
            query: {
              "submission-type": "draft",
            },
            replace: true,
          });
        },
      }
    );
  };
  const onDelete = () => {
    unblockNavigation({ redirect: false });
    navigate({
      pathname: linkToFormTemplateDetail(categoryId, formTemplateId),
      replace: true,
      query: { "submission-type": "draft" },
    });
  };

  const onSaveEdits = (
    categoryId: string,
    formValues: Pick<FormSubmissionType, "answers">,
    shouldTriggerWorkflow: boolean
  ) => {
    unblockNavigation({ redirect: false });

    revisionMutation.mutate(
      {
        data: {
          ...patchFormValuesForMutation(formValues),
          retrigger_workflow: shouldTriggerWorkflow,
        },
        meta: {
          id: formSubmissionId,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: ["forms", "submissions"],
          });

          queryClient.invalidateQueries({
            queryKey: [Resources.FORM_SUBMISSION],
          });

          navigate({
            pathname: linkToFormTemplateDetail(categoryId, formTemplateId),
            query: {
              "submission-type": "all",
            },
            replace: true,
          });

          toast.push({
            intent: "success",
            description:
              "Successfully invalidated previous version of the form submission",
          });
          toast.push({
            intent: "success",
            description: "Successfully submitted form",
          });
        },
      }
    );
  };

  const errorCount = getErrorCount(form);
  const isPageLoading =
    templateQuery?.isLoading || formSubmissionQuery.isLoading;
  const shouldDisplayBanner = !isPageLoading && !isFormSubmissionDraft;
  const isBannerOnScreen = shouldDisplayBanner && !isBannerDismissed;

  return (
    <>
      <Page
        isLoading={isPageLoading}
        category={UPDATE_FORM_SUBMISSION_BREADCRUMB.title}
        title={templateQuery.data?.name}
        className={cx(
          isBannerOnScreen ? "pageWithBanner" : "pageWithoutBanner"
        )}
        renderMeta={({ MetaSegments }) => (
          <MetaSegments
            values={[
              formSubmission?.updated_at
                ? `Last edited ${dateFormatter(
                    new Date(formSubmission.updated_at ?? "")
                  )}${
                    metaUsersMap[formSubmission.updated_by]
                      ? ` by ${metaUsersMap[formSubmission.updated_by].name}`
                      : ""
                  }`
                : "",
              formSubmission?.created_at
                ? `Created ${dateFormatter(
                    new Date(formSubmission.created_at ?? "")
                  )}${
                    metaUsersMap[formSubmission.created_by]
                      ? ` by ${metaUsersMap[formSubmission.created_by].name}`
                      : ""
                  }`
                : "",
            ]}
          />
        )}
        breadcrumbs={breadcrumbs}
        footer={
          <div className={cx("footerContainer")}>
            <Button
              key="cancel-action"
              onClick={onCancel}
            >
              Cancel
            </Button>

            <div className={cx("footerActionsContainer")}>
              {errorCount ? (
                <p style={{ color: "red", margin: 0 }}>
                  {errorCount
                    ? `${errorCount} field${
                        errorCount > 1 ? "s have" : " has"
                      } an error. Please fix the error${
                        errorCount > 1 ? "s" : ""
                      } to submit the form.`
                    : ""}
                </p>
              ) : null}

              {isFormSubmissionDraft ? (
                <>
                  <Button
                    key="delete-draft-action"
                    onClick={() => setIsDeleteDialogOpen(true)}
                    isLoading={isDeleteDialogOpen}
                    disabled={mutation.isLoading}
                    variant="error-outline"
                  >
                    Delete Draft
                  </Button>
                  <Button
                    key="save-as-draft-action"
                    onClick={onSaveAsDraft}
                    isLoading={
                      mutation.isLoading &&
                      mutation.variables?.data.status === "draft"
                    }
                    disabled={mutation.isLoading}
                  >
                    Save as Draft
                  </Button>
                  <Tooltip content="Form will be saved as draft before the export generates">
                    <Button
                      icon="file-pdf"
                      iconPosition="left"
                      disabled={formSubmissionQuery.isLoading}
                      isLoading={exportPDFMutation.isLoading}
                      onClick={() => {
                        if (!formSubmission) return;

                        const formValues = form.getValues();

                        mutation
                          .mutateAsync({
                            id: formSubmissionId,
                            data: patchFormValuesForMutation({
                              ...formValues,
                              status: "draft",
                            }),
                          })
                          .then(() => formSubmissionQuery.refetch())
                          .then((data) => {
                            if (!data?.data)
                              throw new BaseError(
                                "Failed to refetch draft form submission"
                              );

                            return exportPDFMutation.mutateAsync(
                              data.data.data
                            );
                          })
                          .catch(() => {
                            toast.push({
                              intent: "error",
                              description:
                                "Failed to save draft. Exporting using last saved state.",
                            });
                            exportPDFMutation.mutate(formSubmission);
                          });
                      }}
                    >
                      Export PDF
                    </Button>
                  </Tooltip>
                  <Button
                    key="submit-action"
                    variant="primary"
                    onClick={onSubmit}
                    isLoading={
                      mutation.isLoading &&
                      mutation.variables?.data.status !== "draft"
                    }
                    disabled={mutation.isLoading}
                  >
                    Submit
                  </Button>
                </>
              ) : null}
              {!isFormSubmissionDraft ? (
                <Button
                  key="submit-action"
                  variant="primary"
                  onClick={onSubmit}
                  isLoading={
                    mutation.isLoading &&
                    mutation.variables?.data.status !== "draft"
                  }
                  disabled={mutation.isLoading}
                >
                  Save Edits
                </Button>
              ) : null}
            </div>
          </div>
        }
      >
        <>
          {shouldDisplayBanner && !isBannerDismissed ? (
            <Banner
              className={cx("banner")}
              isDismissable
              onDismiss={() => {
                setIsBannerDismissed(true);
              }}
              titleText={BANNER_TITLE_TEXT}
              variant="info"
            />
          ) : null}
          <div className={cx(isBannerOnScreen ? "bannerOffset" : "")}>
            <Form {...form}>
              <FormSubmissionFormController
                form={form}
                formSchema={templateQuery?.data}
                sectionRenderFunction={(all) => (
                  <FormSubmissionSection {...all} />
                )}
                questionRenderFunction={(all) => {
                  return (
                    <FormSubmissionField
                      {...all}
                      key={all.name}
                      isDisabled={fieldsNamesToDisable.has(all.name)}
                      isReady={watchedFieldsState[all.dataKey]?.areSourcesReady}
                      isBusy={watchedFieldsState[all.dataKey]?.isCalculating}
                      isValid={watchedFieldsState[all.dataKey]?.isCalculatable}
                      {...(watchedFieldsState[all.dataKey]?.value.calculation ??
                        {})}
                      questionsMap={templateQuery.data!.config.questions}
                    />
                  );
                }}
                defaultAnswers={calculatedDefaultAnswers}
              />
            </Form>
          </div>
        </>
      </Page>

      <DeleteDraftFormSubmissionDialog
        isOpen={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        onDelete={onDelete}
        formSubmissionId={formSubmissionId}
      />

      <Dialog
        isOpen={isCancelSaveDialogOpen}
        onClose={() => setIsCancelSaveDialogOpen(false)}
        title="Discard changes?"
        actionRow={[
          <Button
            key="yes"
            variant="error"
            onClick={() => {
              unblockNavigation({ redirect: true });
            }}
          >
            Yes
          </Button>,
        ]}
      >
        You&apos;ve made changes to this draft submission. Are you sure you want
        to leave your changes unsaved? They will be discarded.
      </Dialog>

      <ConfirmEditFormSubmissionDialog
        isOpen={isConfirmEditDialogOpen}
        onClose={() => setIsConfirmEditDialogOpen(false)}
        handleSaveEdits={onSaveEdits}
        formValues={editedFormValues}
      />
    </>
  );
};
