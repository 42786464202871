import RecordOfVolumeService from "#services/RecordOfVolumeService";
import {
  Button,
  Form,
  FormButton,
  Modal,
  Title,
  useForm,
  useToast,
} from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { COMPOUND_OPTIONS, TYPE_OPTIONS } from "./AdjustmentFactorConstants";
import AdjustmentFactorForm from "./AdjustmentFactorsForm";
import {
  getAdjustmentFactorParams,
  getFormattedData,
} from "./AdjustmentFactorsHelper";

const EditAdjustmentFactorsModal = ({
  show,
  unboundedSelectedAdjustmentFactor,
  haveWritePermissions,
  onHide,
  updateAdjustmentFactorList,
}) => {
  const [adjustmentFactorDetail, setAdjustmentFactorDetail] = useState({});
  const [formState, setFormState] = useState("enabled");
  const { toast } = useToast();
  const form = useForm({
    defaultValues: adjustmentFactorDetail,
  });

  const onDeleteAdjustmentFactor = () => {
    setFormState("loading");

    RecordOfVolumeService.deleteAdjustmentFactor(adjustmentFactorDetail.id)
      .then(() => {
        onHide();

        toast.push({
          intent: "success",
          description: "Adjustment factor has been successfully removed.",
        });

        updateAdjustmentFactorList();
      })
      .finally(() => {
        setFormState("enabled");
      });
  };

  const onUpdateAdjustmentFactor = (formState) => {
    setFormState("loading");

    const params = getAdjustmentFactorParams(formState);

    RecordOfVolumeService.updateAdjustmentFactor(params)
      .then(() => {
        toast.push({
          intent: "success",
          description: "Adjustment factor has been successfully updated.",
        });

        setFormState("enabled");
        updateAdjustmentFactorList();
      })
      .catch(() => {
        setFormState("enabled");
      });
  };

  useEffect(() => {
    if (unboundedSelectedAdjustmentFactor) {
      setAdjustmentFactorDetail(
        getFormattedData(unboundedSelectedAdjustmentFactor)
      );
    }

    if (!haveWritePermissions) {
      setFormState("disabled");
    }
  }, [unboundedSelectedAdjustmentFactor]);

  return (
    <Modal
      open={show}
      onClose={onHide}
    >
      <Form
        onSubmit={onUpdateAdjustmentFactor}
        {...form}
      >
        <Modal.Header>
          <Title>Update Adjustment Factor</Title>
        </Modal.Header>

        <Modal.Body>
          <AdjustmentFactorForm
            form={form}
            formState={formState}
            availableTypeOptions={TYPE_OPTIONS}
            availableCompoundOptions={COMPOUND_OPTIONS}
          />
        </Modal.Body>

        <Modal.Footer className="adjustmentFactors__actionRow clearfix">
          <Button
            className="pull-left"
            onClick={onHide}
          >
            Close
          </Button>

          <Button
            variant="error-outline"
            onClick={onDeleteAdjustmentFactor}
            disabled={formState !== "enabled"}
            isLoading={formState === "loading"}
          >
            Delete
          </Button>

          <FormButton
            variant="primary"
            type="submit"
            disabled={formState !== "enabled"}
            isLoading={formState === "loading"}
          >
            Update Adjustment Factor
          </FormButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

EditAdjustmentFactorsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  unboundedSelectedAdjustmentFactor: PropTypes.object,
  haveWritePermissions: PropTypes.bool.isRequired,
  adjustmentFactors: PropTypes.array,
  onHide: PropTypes.func.isRequired,
  updateAdjustmentFactorList: PropTypes.func.isRequired,
};

export default EditAdjustmentFactorsModal;
