import store from "#components/Redux/store";
import Routers from "#src/Routers";
import history from "#src/Routers/history";
import { UnknownErrorLayout } from "#src/batteries-included-components/Layouts/Errors/UnknownError";
import { AuthenticatedContextProvider } from "#src/contexts/AuthenticatedContext";
import { MeasurementTypeProvider } from "#src/contexts/MeasurementTypeContext";
import LocalizationProvider from "#src/hooks/LocalizationProvider";
import { ExceptionUtils } from "#src/utils/exception";
import { ErrorBoundary } from "@sentry/react";
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Button, Toaster, useToast } from "@validereinc/common-components";
import {
  HTTPForbiddenError,
  HTTPRequestError,
  HTTPResponseError,
} from "@validereinc/domain";
import "airbnb-js-shims";
import { History } from "history";
import React, { useEffect, useRef } from "react";
import { hot } from "react-hot-loader";
import { Provider } from "react-redux";

// IMPROVE: better typing
const AppContent = ({ store, history }: { store: any; history: History }) => {
  const { toast } = useToast();
  const queryClient = useRef<QueryClient>(
    new QueryClient({
      defaultOptions: {
        queries: {
          retry: (failureCount, err) => {
            // custom errors have appropriate UI likely shown, so we won't be
            // retrying after the first request
            if (
              err instanceof HTTPResponseError ||
              err instanceof HTTPRequestError
            ) {
              return false;
            }

            return failureCount <= 3;
          },
          refetchOnWindowFocus: (q) => {
            // no refetching on window focus change will occur when error is a
            // custom error (as appropriate UI is likely shown and we don't want
            // that to change until the user acts and likely navigates away)
            if (
              q.state.error instanceof HTTPResponseError ||
              q.state.error instanceof HTTPRequestError
            ) {
              return false;
            }

            // https://validere.atlassian.net/browse/CHB-2614
            // Users' use our app between tabs, and the refreshOnWindowFocus
            // causes a lot of unnecessary loading and waiting
            // Enable this on a case-by-case basis
            return false;
          },
        },
      },
      queryCache: new QueryCache({
        onError: (err, query) => {
          if (query.meta?.hideErrorToasts) {
            return;
          }

          ExceptionUtils.reportException(err, "error", {
            hint: `Captured by react query global query cache onError handler (query key: ${JSON.stringify(query.queryKey)})`,
          });

          if (err instanceof HTTPForbiddenError) {
            const errDetails = HTTPForbiddenError.normalize(err);

            toast.push({
              intent: "error",
              description: errDetails.getExplanation(query.meta?.errorCtx),
            });
            return;
          }
        },
      }),
      mutationCache: new MutationCache({
        onError: (err, _, __, mtx) => {
          if (mtx.meta?.hideErrorToasts) {
            return;
          }

          ExceptionUtils.reportException(err, "error", {
            hint: "Captured by react query global mutation cache onError handler",
          });

          if (err instanceof HTTPForbiddenError) {
            const errDetails = HTTPForbiddenError.normalize(err);

            toast.push({
              intent: "error",
              description: errDetails.getExplanation(mtx.meta?.errorCtx),
            });
            return;
          }
        },
      }),
    })
  );

  useEffect(() => {
    ExceptionUtils.registerLifecycleEvent({
      category: "app",
      level: "info",
      type: "info",
      message: "Initialized query client and app shell",
    });
  }, []);

  if (!queryClient.current) {
    return null;
  }

  return (
    <QueryClientProvider client={queryClient.current}>
      <Provider store={store}>
        <AuthenticatedContextProvider>
          <MeasurementTypeProvider>
            <LocalizationProvider>
              <Routers history={history} />
            </LocalizationProvider>
          </MeasurementTypeProvider>
        </AuthenticatedContextProvider>
      </Provider>
      {process.env.NODE_ENV === "development" ? (
        <ReactQueryDevtools initialIsOpen={false} />
      ) : null}
    </QueryClientProvider>
  );
};

const App = () => (
  <ErrorBoundary
    beforeCapture={(scope) => {
      scope.setTag(
        ExceptionUtils.ExceptionContextKeys.isAppShellFatalFailure,
        true
      );
    }}
    fallback={({ error }) => (
      <UnknownErrorLayout
        error={error}
        action={[
          <Button
            key="go-back"
            variant="primary"
            onClick={() => {
              history.goBack();
            }}
          >
            Go Back
          </Button>,
          <Button
            key="reload"
            style={{ marginLeft: "8px" }}
            onClick={() => window.location.reload()}
          >
            Reload Page
          </Button>,
        ]}
      />
    )}
  >
    <>
      <AppContent
        store={store}
        history={history}
      />
      <Toaster
        position="bottom-right"
        visibleToasts={5}
        expand
      />
    </>
  </ErrorBoundary>
);

export default hot(module)(App);
