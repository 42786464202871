import { useGetOneNetwork } from "#hooks/adapters/useNetworks";
import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import { useNavigate, useParams } from "#routers/hooks";
import { linkToFacilities } from "#routers/links";
import { NetworkDetailPageParameters } from "#routes/organization/networks/[networkId]/detail";
import { NETWORK_ENTITY_INITIAL_SORT } from "#routes/organization/networks/[networkId]/detail/NetworkDetailsTab/NetworkEntityTablePanel/NetworkEntityTablePanel";
import { FacilitiesTableFilterArea } from "#src/batteries-included-components/FilterAreas/AssetsFilterAreas";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import useLocalization from "#src/hooks/useLocalization";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import { useQuery } from "@tanstack/react-query";
import {
  Button,
  DataTablePanel,
  HeaderType,
  Pill,
  type StorageKeys,
} from "@validereinc/common-components";
import {
  AssetType,
  AssetTypeType,
  FacilityDomain,
  FacilityType,
} from "@validereinc/domain";
import React from "react";
import { NetworkEditRoute } from "../../edit";

export const NetworkFacilitiesPanel = ({
  filterConfigStorageKey,
  tableConfigStorageKey,
}: StorageKeys) => {
  const { localize } = useLocalization();
  const { networkId } = useParams<NetworkDetailPageParameters>();
  const navigate = useNavigate();
  const { data: assetGroup } = useGetOneNetwork(networkId);
  const getAssetIdsByType = (assetType: AssetTypeType) =>
    assetGroup?.assets
      ?.filter(({ asset_type }) => asset_type === assetType)
      .map(({ id }) => id) ?? [];
  const facilityIds = getAssetIdsByType?.(AssetType.FACILITY);
  const [tableFilters] = useSessionStickyState({}, filterConfigStorageKey);
  const filters = { ...tableFilters, id: facilityIds };
  const [tableState, updateTableState] = useTableSortingAndPagination(
    NETWORK_ENTITY_INITIAL_SORT,
    filters
  );

  const facilityParams = {
    page: tableState.page,
    pageSize: tableState.pageSize,
    sort_by: tableState.sortBy,
    sort_direction: tableState.sortDirection,
    filters,
  };

  const isFacilityQueryEnabled = !!facilityIds?.length;

  const { data, isLoading } = useQuery({
    queryKey: ["facilities", facilityParams],
    queryFn: () => FacilityDomain.getList(facilityParams),
    enabled: isFacilityQueryEnabled,
  });
  const headers: Array<HeaderType<FacilityType>> = [
    {
      key: "name",
      label: "Name",
      isSortable: true,
      renderComponent: ({ item }) => (
        <RoutingLink to={linkToFacilities(item.id)}>{item.name}</RoutingLink>
      ),
    },
    {
      key: "status",
      label: "Status",
      isSortable: true,
      renderComponent: ({ item }) => (
        <Pill variant={item.status === "active" ? "success" : "default"}>
          {item.status}
        </Pill>
      ),
    },
  ];

  return (
    <DataTablePanel
      storageKey={tableConfigStorageKey}
      panelProps={{
        title: `Network ${localize("facility_plural")}`,
        actionRow: (
          <>
            <Button
              onClick={() => {
                navigate({
                  pathname: NetworkEditRoute.toLink({
                    pathParams: { networkId },
                  }),
                });
              }}
            >
              Edit
            </Button>
          </>
        ),
      }}
      dataTableProps={{
        variant: "simplicity-first",
        isLoading: isFacilityQueryEnabled && isLoading,
        headers,
        items: data?.data ?? [],
        sorting: {
          sortBy: tableState.sortBy,
          sortDirection: tableState.sortDirection,
        },
        onSortChange: updateTableState,
        onPaginationChange: updateTableState,
        pagination: {
          page: tableState.page,
          pageSize: tableState.pageSize,
          total: data?.total_entries,
        },
      }}
      filterComponent={
        <FacilitiesTableFilterArea
          filterConfigStorageKey={filterConfigStorageKey}
        />
      }
    />
  );
};
