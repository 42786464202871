import { useListLibrary } from "#components/hooks/useLibrary";
import { QUERY_STATUS } from "#src/constants";
import { Info, Warning } from "@phosphor-icons/react";
import { useQuery } from "@tanstack/react-query";
import {
  DropdownInput,
  EmptyState,
  Form,
  MathDataDisplayEquationsWithBreakdown,
  Panel,
  TextInput,
} from "@validereinc/common-components";
import {
  CalculatorDomain,
  LibraryNodeType,
  ReportingGroupDomain,
  Resources,
} from "@validereinc/domain";
import classNames from "classnames/bind";
import camelCase from "lodash/camelCase";
import dropRightWhile from "lodash/dropRightWhile";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import styles from "./CreateEstimationMethodForm.module.scss";

const cx = classNames.bind(styles);

const CreateEstimationMethodForm = ({
  formProps,
}: CreateEstimationMethodFormProps) => {
  const { watch, unregister } = formProps;
  const [dynamicFilters, setDynamicFilters] = useState<DynamicFilter[]>([]);
  const [libraries, librariesFetchState] = useListLibrary();
  const reportingGroupsQuery = useQuery({
    queryKey: ["reportingGroups"],
    queryFn: ReportingGroupDomain.getList,
  });
  const reportingGroups = reportingGroupsQuery?.data?.data ?? [];

  const selectedCalculatorId = watch("calculatorId");
  const selectedCalculatorQuery = useQuery({
    queryKey: [Resources.CALCULATOR, selectedCalculatorId],
    queryFn: () => {
      return CalculatorDomain.getCalculator({
        calculatorId: selectedCalculatorId,
      });
    },
    enabled: !!selectedCalculatorId,
  });

  // computed
  const lastSelectedCalculatorVersion =
    selectedCalculatorQuery.data?.versions.find(
      ({ version }: { version: string }) =>
        version === selectedCalculatorQuery.data?.default_version
    );

  // methods
  /**
   * Get a HTML form-friendly name out of a library data node
   * @param node a library data node
   * @returns a string to use in "name" HTML attribute for an input
   */
  // REVIEW: this needs cleanup
  const getDynamicFilterName = (node: LibraryNodeType) =>
    !node.children?.[0]?.child_type
      ? "calculatorId"
      : camelCase(node.child_type + " ID" ?? node.name + " ID");
  /**
   * Drops and unregisters a dynamic filter from an array of dynamic filters from the end until it encounters the specified filter - stops and returns the remaining filters
   * @param filters an array of dynamic filters
   * @param targetFilter the target filter to keep dropping filters until (but including)
   * @returns what's left of the provided filters
   */
  const dropFiltersUntilTarget = (
    filters: DynamicFilter[],
    targetFilter: DynamicFilter
  ) =>
    dropRightWhile(filters, (filterToEvaluate) => {
      const isNotTargetFilter = filterToEvaluate.name !== targetFilter.name;

      if (isNotTargetFilter) {
        unregister(filterToEvaluate.name);
      }

      return isNotTargetFilter;
    });
  const onDynamicFilterUnset = (filter: DynamicFilter) => {
    setDynamicFilters(dropFiltersUntilTarget(dynamicFilters, filter));
  };
  const onDynamicFilterChange = (
    _: string,
    filter: DynamicFilter,
    valueAsNode: LibraryNodeType
  ) => {
    // if the current filter isn't the last filter, we have to drop all downstream filters. Otherwise, keep all upstream filters.
    const toKeepFilters =
      dynamicFilters[dynamicFilters.length - 1].name === filter.name
        ? dynamicFilters
        : dropFiltersUntilTarget(dynamicFilters, filter);

    // add the new filter
    setDynamicFilters([
      ...toKeepFilters,
      {
        node: valueAsNode,
        name: getDynamicFilterName(valueAsNode),
      },
    ]);
  };

  // effects
  useEffect(() => {
    // clear everything if the dynamic filter data source isn't what we expect
    if (
      !libraries ||
      typeof libraries !== "object" ||
      !Array.isArray(libraries?.children)
    ) {
      setDynamicFilters([]);
      return;
    }

    // otherwise, set the first filter once we have data
    setDynamicFilters([
      {
        node: libraries,
        name: getDynamicFilterName(libraries),
      },
    ]);
  }, [libraries]);

  // render helpers
  const getCalculationPreview = () => {
    if (!selectedCalculatorId) {
      return (
        <EmptyState
          title="No preview available"
          suggestion="A preview of the estimation method will appear here once a calculator or equation is selected."
          icon={<Info />}
        />
      );
    }

    const isDocumentationAvailable =
      !selectedCalculatorQuery.isLoading &&
      typeof lastSelectedCalculatorVersion?.documentation === "object" &&
      Object.keys(lastSelectedCalculatorVersion?.documentation ?? {}).length;

    // if the calculator data is available and the data matches the selected calculator
    if (
      (selectedCalculatorQuery.data &&
        selectedCalculatorId === selectedCalculatorQuery.data.id &&
        isDocumentationAvailable) ||
      selectedCalculatorQuery.isLoading
    ) {
      return (
        <MathDataDisplayEquationsWithBreakdown
          title={lastSelectedCalculatorVersion?.documentation.title}
          sourceLink={lastSelectedCalculatorVersion?.documentation.link}
          equations={lastSelectedCalculatorVersion?.documentation.calculations.map(
            (calc) => ({
              equation: calc.equation,
              reference: calc.reference,
              terms: calc.conditions.map((cond) => ({
                math: cond.variable_name,
                description: cond.variable_description,
              })),
            })
          )}
          isLoading={selectedCalculatorQuery.isLoading}
          className={cx("formulaPreview")}
        />
      );
    } else {
      return (
        <EmptyState
          title="No documentation available"
          suggestion="Documentation for this estimation method is not available"
          icon={<Warning />}
        />
      );
    }
  };

  return (
    <Panel title="Estimation Method Details">
      <Form {...formProps}>
        <div className={cx("formContainer")}>
          <div className={cx("configContainer")}>
            <TextInput
              name="name"
              label="Estimation Method Name"
              isRequired
            />
            {librariesFetchState === QUERY_STATUS.LOADING ? (
              <div className={cx("skeletonLoader")}>
                {Array(6)
                  .fill(null)
                  .map((_, idx) => (
                    <FilterLoader key={idx} />
                  ))}
              </div>
            ) : null}
            {librariesFetchState === QUERY_STATUS.SUCCESS ? (
              <fieldset className={cx("calculatorFieldset")}>
                <legend className={cx("calculatorLegend")}>
                  Select Calculator
                </legend>
                {dynamicFilters.map((filter) => (
                  <DynamicFilter
                    key={uuidv4()}
                    filter={filter}
                    onChange={onDynamicFilterChange}
                    onUnset={onDynamicFilterUnset}
                  />
                ))}
              </fieldset>
            ) : null}
            {reportingGroupsQuery.isLoading ? (
              <div className={cx("skeletonLoader")}>
                <FilterLoader />
              </div>
            ) : null}
            {reportingGroupsQuery.status === QUERY_STATUS.SUCCESS ? (
              <fieldset className={cx("calculatorFieldset")}>
                <legend className={cx("calculatorLegend")}>
                  Applicable Report
                </legend>
                <DropdownInput
                  name="reportingGroupId"
                  label="Reporting Scenario"
                  options={reportingGroups}
                  labelKey="name"
                  valueKey="id"
                  isMulti={true}
                  isFluid
                  isSortedAlphabetically={false}
                  isSearchable
                />
              </fieldset>
            ) : null}
          </div>
          <div className={cx("previewContainer")}>
            {getCalculationPreview()}
          </div>
        </div>
      </Form>
    </Panel>
  );
};

const DynamicFilter = ({ filter, onChange, onUnset }: DynamicFilterProps) => {
  const { name, node } = filter;

  // determine if this filter has any downstream filters - if it doesn't, don't render anything
  if (!node.children || !node.child_type) {
    return null;
  }

  const options = node.children
    .sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    })
    .map((child) => ({
      label: child.name,
      // the last filter in the waterfall always determines the selected calculator
      value: child.calculator_id ? child.calculator_id : child.name,
    }));

  return (
    <DropdownInput
      name={name}
      label={node.child_type}
      placeholder="Select one"
      options={options}
      labelKey="label"
      valueKey="value"
      isFluid
      isSearchable
      isRequired
      onChange={(value: string) => {
        // if the value was unset
        if (!value) {
          onUnset?.(filter);
          return;
        }

        // get the selected node (it should exist) this will give us the dependent filter to generate
        const selectedNode = node.children?.find(
          (c) => c.name === value || c.calculator_id === value
        );

        // node in the data corresponding to the selected value should always be there (since the options for the dropdown are from the same data)
        if (!selectedNode) {
          onUnset?.(filter);
          return;
        }

        // if the value was changed
        onChange?.(value, filter, selectedNode);
      }}
    />
  );
};

/**
 * A simple loader that acts as a placeholder for a filter on this form
 * @returns a skeleton loader
 */
const FilterLoader = () => (
  <div className={cx("skeletonFilter")}>
    <div
      className={cx("skeleton")}
      style={{ width: "50%", height: "16px" }}
    ></div>
    <div
      className={cx("skeleton")}
      style={{ width: "100%" }}
    ></div>
  </div>
);

type DynamicFilter = {
  /** the HTML-friendly name to use for the filter input */
  name: string;
  /** the data node that the filter will get its data from */
  node: LibraryNodeType;
};

type CreateEstimationMethodFormProps = {
  formProps: any; // TODO: get from react hook form
};

type DynamicFilterProps = {
  /** the filter to render */
  filter: DynamicFilter;
  /** fires when the underlying filter has a change in value (only when the value maps to a valid option + associated data node) */
  onChange?: (
    value: string,
    filter: DynamicFilter,
    valueAsNode: LibraryNodeType
  ) => void;
  /** fires when the underlying filter has a change in value and that value is falsy */
  onUnset?: (filter: DynamicFilter) => void;
};

export default CreateEstimationMethodForm;
