import {
  MUTATION_STATUS,
  MutationStatusType,
  QUERY_STATUS,
  QueryStatusType,
} from "#constants";
import { isNumeric } from "#src/utils/unitConverter";
import {
  AssetType,
  AssetTypeType,
  EstimationMethodConfigurationType,
  EstimationMethodDomain,
  EstimationMethodInputType,
  EstimationMethodRunType,
  EstimationMethodType,
  UserType,
  UsersAdapter,
} from "@validereinc/domain";
import { yearMonthFormatter, yearMonthFromISO } from "@validereinc/utilities";
import { useEffect, useState } from "react";

// IMPROVE: all the hooks and functions in this file were written before the react-query data fetching patterns. Re-write these to clean them up.

// Helper method to get user details to enrich est method details
const getUsersDetails = async (
  createdByUserId: string,
  updatedByUserId: string
) => {
  // if it's the same user, we can avoid a duplicate response
  const userIds = (
    createdByUserId === updatedByUserId
      ? [createdByUserId]
      : [createdByUserId, updatedByUserId]
  ).filter((id) => !!id);
  const usersQueries = await Promise.allSettled(
    userIds.map((id) => UsersAdapter.getOne({ id }))
  );
  const usersQueryMap = usersQueries.reduce<Record<string, UserType>>(
    (map, q) => {
      if (q.status !== "fulfilled") {
        return map;
      }

      map[q.value.data.id] = q.value.data;
      return map;
    },
    {}
  );

  const createdBy = usersQueryMap[createdByUserId];
  const updatedBy = usersQueryMap[updatedByUserId];

  return { createdBy, updatedBy };
};

/**
 * Use this hook to fetch an estimation method's details with enrichment
 * @param methodId the ID of an estimation method in the back-end
 * @returns enriched estimation method details and the fetch status
 * @deprecated use react query pattern
 */
export function useGetEstimationMethod(
  methodId?: string,
  entityType: AssetTypeType = AssetType.EQUIPMENT,
  month?: string
): [
  (
    | (EstimationMethodType & {
        createdBy?: UserType;
        updatedBy?: UserType;
      })
    | undefined
  ),
  QueryStatusType,
  () => void,
] {
  const [methodDetail, setMethodDetail] = useState<
    EstimationMethodType & {
      createdBy?: UserType;
      updatedBy?: UserType;
    }
  >();
  const [fetchState, setFetchState] = useState<QueryStatusType>(
    QUERY_STATUS.LOADING
  );
  const [lastFetchDate, setLastFetchDate] = useState<Date | null>(null);

  const refetch = () => {
    setLastFetchDate(new Date());
  };

  // get est. method details
  const getMethodDetails = async () => {
    if (!methodId) {
      return;
    }

    const methodDetailData = await EstimationMethodDomain.getOne({
      id: methodId,
      meta: {
        entityType,
      },
      ...(month
        ? {
            data: {
              period: yearMonthFromISO(month),
            },
          }
        : {}),
    });

    return methodDetailData;
  };

  const getData = async () => {
    const methodDetail = await getMethodDetails();
    const usersDetails = await getUsersDetails(
      methodDetail?.created_by,
      methodDetail?.updated_by
    );

    return { ...methodDetail, ...usersDetails };
  };

  useEffect(() => {
    if (!methodId) {
      return;
    }

    setFetchState(QUERY_STATUS.LOADING);
    getData()
      .then((data) => {
        setMethodDetail(data);
        setFetchState(QUERY_STATUS.SUCCESS);
      })
      .catch((err) => {
        console.error(err);
        setFetchState(QUERY_STATUS.ERROR);
      });
  }, [methodId, lastFetchDate, month]);

  return [methodDetail, fetchState, refetch];
}

/**
 * @deprecated use react query directly
 */
export function useDeleteEstimationMethod(): [
  (methodId: string, entityType: AssetTypeType) => Promise<void>,
  MutationStatusType,
] {
  const [mutationState, setMutationState] = useState<MutationStatusType>(
    MUTATION_STATUS.IDLE
  );
  const deleteRequest = async (methodId: string, entityType: AssetTypeType) => {
    setMutationState(MUTATION_STATUS.LOADING);

    try {
      if (!methodId || !entityType) {
        throw new Error("Data required for request not provided");
      }

      await EstimationMethodDomain.delete({
        id: methodId,
        meta: {
          entityType,
        },
      });

      setMutationState(MUTATION_STATUS.SUCCESS);
    } catch (err) {
      console.error(err);
      setMutationState(MUTATION_STATUS.ERROR);

      throw {
        status: mutationState,
        error: err,
      };
    }
  };

  return [deleteRequest, mutationState];
}

/**
 * @deprecated use react query directly
 */
export function useGetEstimationMethodConfiguration(
  methodId?: string,
  month?: Date,
  entityType: AssetTypeType = AssetType.EQUIPMENT
): [
  (
    | (EstimationMethodConfigurationType & {
        createdBy?: UserType;
        updatedBy?: UserType;
      })
    | undefined
  ),
  QueryStatusType,
  () => void,
] {
  const [methodConfig, setMethodConfig] = useState<
    EstimationMethodConfigurationType & {
      createdBy?: UserType;
      updatedBy?: UserType;
    }
  >();
  const [fetchState, setFetchState] = useState<QueryStatusType>(
    QUERY_STATUS.LOADING
  );
  const [lastFetchDate, setLastFetchDate] = useState<Date | null>(null);

  const refetch = () => {
    setLastFetchDate(new Date());
  };

  const getConfig = async (methodId: string, month: Date) => {
    const configData = await EstimationMethodDomain.configuration.getOne({
      id: methodId,
      meta: {
        yearMonth: yearMonthFormatter(month),
        entityType,
      },
    });

    if (configData.estimation_method_id !== methodId) {
      throw new Error(
        `The estimation method configuration for ${methodId} does not match the ID.`
      );
    }

    const usersDetails = await getUsersDetails(
      configData.created_by,
      configData.updated_by
    );

    return { ...configData, ...usersDetails };
  };

  useEffect(() => {
    if (!methodId || !month || !entityType) {
      return;
    }

    setFetchState(QUERY_STATUS.LOADING);
    getConfig(methodId, month)
      .then((data) => {
        setMethodConfig(data);
        setFetchState(QUERY_STATUS.SUCCESS);
      })
      .catch((err) => {
        console.error(err);
        setFetchState(QUERY_STATUS.ERROR);
      });
  }, [methodId, month, entityType, lastFetchDate]);

  return [methodConfig, fetchState, refetch];
}

/**
 * @deprecated use react query directly
 */
export function useRunEstimationMethod(
  methodId?: string,
  month?: Date,
  entityType: AssetTypeType = AssetType.EQUIPMENT,
  isPreview = true,
  enabled = false
) {
  const [results, setResults] = useState<EstimationMethodRunType>();
  const [mutationState, setMutationState] = useState<MutationStatusType>(
    MUTATION_STATUS.IDLE
  );
  const runCalcAndGetResults = async (
    methodId: string,
    month: Date,
    isPreview = true
  ) => {
    setMutationState(MUTATION_STATUS.LOADING);

    try {
      if (!methodId || !month) {
        throw new Error("Data required for request not provided");
      }

      const runResults = await EstimationMethodDomain.run.create({
        meta: {
          methodId,
          yearMonth: yearMonthFormatter(month),
          entityType,
          isPreview,
          promoteToRecord: !isPreview && entityType !== AssetType.EQUIPMENT,
        },
      });

      setResults(runResults);
      setMutationState(MUTATION_STATUS.SUCCESS);

      return {
        data: runResults,
        status: mutationState,
      };
    } catch (err) {
      console.error(err);
      setMutationState(MUTATION_STATUS.ERROR);

      throw {
        data: null,
        status: mutationState,
        error: err,
      };
    }
  };

  useEffect(() => {
    if (!enabled || !methodId || !month) {
      return;
    }

    runCalcAndGetResults(methodId, month, isPreview).catch(() =>
      console.error("Could not auto-create estimation method run", {
        methodId,
        month,
      })
    );
  }, [methodId, month, isPreview, enabled]);

  return {
    data: results,
    status: mutationState,
    update: runCalcAndGetResults,
    setData: setResults,
  };
}

/**
 * @deprecated use react query directly
 */
export function useGetEstimationMethodRun(
  methodId?: string,
  month?: Date,
  entityType: AssetTypeType = AssetType.EQUIPMENT
) {
  const [record, setRecord] = useState<
    EstimationMethodRunType & {
      createdBy?: UserType;
      updatedBy?: UserType;
    }
  >();
  const [fetchState, setFetchState] = useState<QueryStatusType>(
    QUERY_STATUS.LOADING
  );

  const getRecord = async (methodId: string, month: Date) => {
    const runResults = await EstimationMethodDomain.run.getOne({
      id: methodId,
      meta: {
        yearMonth: yearMonthFormatter(month),
        entityType,
      },
    });

    if (runResults.estimation_method_id !== methodId) {
      throw new Error(
        `The estimation method configuration for ${methodId} does not match the ID.`
      );
    }

    const usersDetails = await getUsersDetails(
      runResults.created_by,
      runResults.updated_by
    );

    return { ...runResults, ...usersDetails };
  };
  const getRecordAndUpdateState = (methodId: string, month: Date) => {
    setFetchState(QUERY_STATUS.LOADING);

    return getRecord(methodId, month)
      .then((record) => {
        setRecord(record);
        setFetchState(QUERY_STATUS.SUCCESS);
      })
      .catch((err) => {
        console.error(err);
        setFetchState(QUERY_STATUS.ERROR);

        return Promise.reject({
          data: null,
          status: fetchState,
          error: err,
        });
      });
  };

  useEffect(() => {
    if (!methodId || !month) {
      return;
    }

    getRecordAndUpdateState(methodId, month).catch(() =>
      console.error("Could not auto-fetch estimation method run", {
        methodId,
        month,
      })
    );
  }, [methodId, month]);

  return {
    data: record,
    status: fetchState,
    query: getRecordAndUpdateState,
  };
}

/**
 * @deprecated use react query directly
 */
export function useUpdateEstimationMethodConfiguration() {
  const [methodConfig, setMethodConfig] =
    useState<EstimationMethodConfigurationType>();
  const [status, setStatus] = useState<MutationStatusType>(
    MUTATION_STATUS.IDLE
  );

  const update = async (
    methodId: string,
    month: Date,
    entityType: AssetTypeType,
    {
      calculatorVersion,
      inputs,
    }: {
      calculatorVersion: string;
      inputs: Record<string, EstimationMethodInputType>;
    }
  ) => {
    setStatus(MUTATION_STATUS.LOADING);

    try {
      if (!methodId || !month) {
        throw new Error("Data required for request not provided");
      }

      const validInputs = Object.entries(inputs).reduce<
        Record<string, EstimationMethodInputType | null>
      >((formValues, [key, entry]) => {
        // the value is set to null intentionally as the back-end will eliminate values set to null in the saved configuration roll-up
        formValues[key] =
          typeof entry.value === "string" && entry.value.trim() === ""
            ? null
            : {
                value: isNumeric(entry.value) ? +entry.value : entry.value,
                unit: entry.unit,
              };
        return formValues;
      }, {});

      const configData = await EstimationMethodDomain.configuration.update({
        id: methodId,
        data: {
          calculator_version: calculatorVersion,
          inputs: validInputs,
        },
        meta: {
          yearMonth: yearMonthFormatter(month),
          entityType,
        },
      });

      if (configData.estimation_method_id !== methodId) {
        throw new Error(
          `The estimation method configuration for ${methodId} does not match the ID.`
        );
      }

      const usersDetails = await getUsersDetails(
        configData.created_by,
        configData.updated_by
      );

      setMethodConfig({ ...configData, ...usersDetails });
      setStatus(MUTATION_STATUS.SUCCESS);

      return {
        data: configData,
        status: status,
      };
    } catch (err) {
      console.error(err);
      setStatus(MUTATION_STATUS.ERROR);

      throw {
        data: null,
        status: status,
        error: err,
      };
    }
  };

  return { update, status, data: methodConfig };
}
