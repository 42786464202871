import { DEFAULT_QUERY_OPTIONS } from "#hooks/adapters/adapterUtils";
import { useParams } from "#routers/hooks";
import { type DataSetSubmissionDetailRoutePath } from "#routes/data-tools/submissions/datasets/[transactionId]/detail";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@validereinc/common-components";
import {
  GetListRequestType,
  Resources,
  TransactionAdapter,
  TransactionGetListFilterType,
  TransactionStatus,
} from "@validereinc/domain";
import { downloadFile } from "@validereinc/utilities";

export const useListTransactions = (
  apiParams: GetListRequestType<TransactionGetListFilterType>
) =>
  useQuery({
    queryKey: ["transaction", "list", apiParams],
    queryFn: () => TransactionAdapter.getList(apiParams),
    ...DEFAULT_QUERY_OPTIONS,
  });

export const useGetOneTransaction = () => {
  const { transactionId } =
    useParams<
      Exclude<typeof DataSetSubmissionDetailRoutePath.pathParams, undefined>
    >();

  const params = {
    id: transactionId,
    meta: { history: true },
  };

  return useQuery({
    queryKey: [Resources.TRANSACTION, params],
    queryFn: () => TransactionAdapter.getOne(params),
    enabled: !!params.id,
    ...DEFAULT_QUERY_OPTIONS,
    refetchOnWindowFocus: true,
    refetchInterval: (data) => {
      const lastTx = data?.data[data.data.length - 1];

      return lastTx?.status === TransactionStatus.PENDING ||
        lastTx?.status === TransactionStatus.FAILED
        ? lastTx?.status === TransactionStatus.FAILED
          ? 15000
          : 5000
        : false;
    },
  });
};

export const useGetOneTransactionDetail = () => {
  const { transactionId } =
    useParams<
      Exclude<typeof DataSetSubmissionDetailRoutePath.pathParams, undefined>
    >();

  const params = {
    id: transactionId,
  };

  return useQuery({
    queryKey: [Resources.TRANSACTION, params],
    queryFn: () => TransactionAdapter.details.getOne(params),
    enabled: !!params.id,
    ...DEFAULT_QUERY_OPTIONS,
    refetchOnWindowFocus: true,
  });
};

export const useDownloadErrors = () => {
  const { toast } = useToast();

  return useMutation({
    mutationFn: async ({
      transactionId,
      fileName,
    }: {
      transactionId: string;
      fileName: string;
    }) => {
      const data = await TransactionAdapter.details.getOne({
        id: transactionId,
        meta: { accept: "text/csv" },
      });
      downloadFile(fileName, data);
    },
    onSuccess: () => {
      toast.push({
        intent: "success",
        description: "Successfully downloaded submission errors",
      });
    },
    onError: (err) => {
      toast.push({
        intent: "error",
        description: "Could not download submission errors",
      });
      console.error(err);
    },
  });
};

export const useOriginalSubmissionFileDownload = () => {
  const { toast } = useToast();

  return useMutation({
    mutationFn: async ({
      transactionId,
      fileName,
    }: {
      transactionId: string;
      fileName: string;
    }) => {
      const data = await TransactionAdapter.download.getOne({
        transactionId,
        hasTransformed: false,
      });

      // download the blob as a file
      downloadFile(fileName, data);
    },
    onSuccess: () => {
      toast.push({
        intent: "success",
        description: "Successfully downloaded original submission",
      });
    },
    onError: (err) => {
      toast.push({
        intent: "error",
        description: "Could not download the original submission",
      });
      console.error(err);
    },
  });
};

export const useInvalidateTransaction = () => {
  const queryClient = useQueryClient();

  return {
    invalidate: () =>
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === Resources.TRANSACTION,
      }),
  };
};
