export const EMISSIONS_MEASUREMENT_TYPES = [
  "mass_co2eq",
  "mass_co2",
  "mass_ch4",
  "mass_n2o",
  "mass_nox",
  "mass_co",
  "mass_benzene",
  "mass_voc",
];
