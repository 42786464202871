import {
  useGetOneEstimationMethodConfiguration,
  useGetOneEstimationMethodRun,
} from "#hooks/adapters/useEstimationMethods";
import { useListFlows } from "#hooks/adapters/useFlows";
import { useGetOneNetwork } from "#hooks/adapters/useNetworks";
import { useGetManyUsers } from "#hooks/adapters/useUsers";
import { useParams } from "#routers/hooks";
import { NetworkCalculationResultRoute } from "#routes/organization/networks/[networkId]/detail/calculation-result/[period]/details/index";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import {
  AssetType,
  CalculationOutputType,
  UserType,
} from "@validereinc/domain";
import { formatNumberValueAndUnit } from "@validereinc/utilities";

export const useFormatOutput = () => {
  const { getUnitName, getPrecisionByType } = useMeasurementTypes();
  return (output?: CalculationOutputType) => {
    const precision = getPrecisionByType(output?.measurement_type ?? "");

    return output
      ? formatNumberValueAndUnit(
          {
            value: Number(output.measurement_value),
            unit: getUnitName(output.measurement_unit),
          },
          precision
            ? {
                fractionDigits: precision,
              }
            : {
                overrideOpts: {
                  maximumFractionDigits: 3,
                  minimumFractionDigits: 2,
                },
              }
        ) ?? "-"
      : "-";
  };
};

export const useNetworkCalculationResult = () => {
  const { networkId, period } =
    useParams<
      Exclude<typeof NetworkCalculationResultRoute.pathParams, undefined>
    >();
  const { data: network, isLoading: isNetworkLoading } =
    useGetOneNetwork(networkId);

  const enabled = !!network?.default_estimation_method_id;
  const estimationMethodParams = {
    id: network?.default_estimation_method_id,
    assetType: AssetType.ASSET_GROUP,
    yearMonth: period,
  };

  const { data: configuration, isLoading: isConfigurationLoading } =
    useGetOneEstimationMethodConfiguration(estimationMethodParams, {
      enabled,
    });

  const { data: run, isLoading: isRunLoading } = useGetOneEstimationMethodRun(
    estimationMethodParams,
    { enabled }
  );

  const usersQuery = useGetManyUsers(
    [
      network?.created_by,
      network?.updated_by,
      run?.created_by,
      run?.updated_by,
      configuration?.created_by,
      configuration?.updated_by,
    ].filter((item) => !!item)
  );

  const flowIds =
    network?.assets
      ?.filter(({ asset_type }) => asset_type === AssetType.FLOW)
      .map(({ id }) => id) ?? [];

  const { data: flowsData, isLoading: isFlowsLoading } = useListFlows(
    { page: 1, pageSize: flowIds.length, filters: { id: flowIds } },
    { enabled: !!flowIds.length }
  );
  const flows = flowsData?.data ?? [];

  const userMap = usersQuery.reduce(
    (accumulator: Record<string, UserType>, current) => {
      if (current.data?.id) {
        accumulator[current.data.id] = current.data;
      }
      return accumulator;
    },
    {}
  );

  const isLoading =
    isNetworkLoading ||
    (enabled && (isRunLoading || isConfigurationLoading)) ||
    (flowIds.length && isFlowsLoading) ||
    usersQuery.some(({ isLoading }) => isLoading);

  return {
    run,
    configuration,
    network,
    flows,
    userMap,
    isLoading,
  };
};
