import { useAuthenticatedContext } from "#src/contexts/AuthenticatedContext.helpers";
import { ExceptionUtils } from "#src/utils/exception";
import { useQuery } from "@tanstack/react-query";
import {
  Button,
  FileUploadInput,
  useFormContext,
  useToast,
} from "@validereinc/common-components";
import {
  DatasetAdapter,
  TemplateAdapter,
  type ResourceDefinitionType,
} from "@validereinc/domain";
import { downloadBlob } from "@validereinc/utilities";
import { snakeCase } from "lodash";
import React, { useEffect } from "react";
import { useImportDataActionContext } from "../ImportDataActionContext/ImportDataActionContext";

const SingleEntityImportAction = () => {
  const {
    resource,
    resourceId,
    status,
    acceptedMimeTypes,
    datasetId,
    setDatasetId,
  } = useImportDataActionContext();
  const { claims } = useAuthenticatedContext();
  const { toast } = useToast();
  const form = useFormContext();

  const { data: dataset, isLoading: isDatasetLoading } = useQuery(
    ["datasets", "default", resource.id, resourceId],
    () =>
      DatasetAdapter.default.getList({
        filters: {
          resource_name: resource.id,
          ...(resourceId && { resource_id: resourceId }),
          company_id: claims.company?.id,
        },
      }),
    {
      select: (resp) => (resp.total_entries >= 1 ? resp.data[0] : null),
      enabled:
        resource &&
        Boolean(claims.company?.id) &&
        Boolean(resourceId ? resource.id && resourceId : resource.id),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
  const { data: templateBlob, isLoading: isTemplateBlobLoading } = useQuery(
    ["datasets", "template", dataset?.id, claims?.company?.id],
    () =>
      TemplateAdapter.dataset.getOne({
        datasetId: dataset?.id ?? "",
        companyId: claims.company.id!,
      }),
    {
      enabled: Boolean(dataset?.id) && Boolean(claims?.company?.id),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const handleDownloadTemplateClick = () => {
    if (!templateBlob) {
      toast.push({
        intent: "error",
        description: "Template not available!",
      });
      return;
    }

    const fileName = `${snakeCase(resource.label.singular)}${
      resourceId ? `_${resourceId}_` : "_"
    }import_template`.toLowerCase();

    try {
      downloadBlob(fileName, templateBlob);
      toast.push({
        intent: "success",
        description: `Template ${fileName}.xlsx downloaded.`,
      });
    } catch (err) {
      toast.push({
        intent: "error",
        description: `Template failed to download.`,
      });
      ExceptionUtils.reportException(err, "error", {
        hint: "Failed to download import template for templated configuration",
      });
    }
  };

  useEffect(() => {
    if (!dataset) {
      setDatasetId("");
      return;
    }
    if (dataset?.id === datasetId) return;

    setDatasetId(dataset.id);
  }, [dataset?.id, datasetId]);

  const getInstruction = (resource: ResourceDefinitionType) =>
    `Upload a file to bulk import ${resource.label.plural} based off the defined ${resource.label.plural} template. You may download a blank template to structure your data below.`;

  return (
    <>
      <p>{getInstruction(resource)}</p>
      <Button
        icon="download-simple"
        variant="outline"
        onClick={handleDownloadTemplateClick}
        isLoading={isDatasetLoading || isTemplateBlobLoading}
      >
        Download Import Template
      </Button>
      <FileUploadInput
        name="file"
        label="Select file to upload"
        placeholder="Upload a .csv, .xls, .xlsx, or .json file"
        isRequired={true}
        rules={{
          required: "A file with data to import is required",
        }}
        acceptedFileTypes={acceptedMimeTypes}
        showIcon={true}
        style={{ marginTop: 12 }}
        isDisabled={form.formState.isSubmitting || !!status?.percentComplete}
      />
    </>
  );
};

export default SingleEntityImportAction;
