import AlertingService from "#components/Services/AlertingService";
import useCarbonFacilities from "#hooks/useCarbonFacilities";
import useDevices from "#hooks/useDevices";
import { useFilterPanel } from "#hooks/useFilterPanel";
import { useSearchParams } from "#routers/hooks";
import {
  linkToAlertDetail,
  linkToDeviceDetail,
  linkToFacilities,
} from "#routers/links";
import MeasurementsService from "#services/MeasurementsService";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import useLocalization from "#src/hooks/useLocalization";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { uniqueArray } from "#utils/arrayFormatter";
import { getFrontendTableState } from "#utils/frontendTableActions";
import { getPropertyAsMap } from "#utils/objectFormatter";
import {
  DataTable,
  DataTablePanel,
  DropdownInput,
  FilterPanel,
  HeaderType,
  Page,
  Pill,
  SortingType,
  TextInput,
} from "@validereinc/common-components";
import { SortDirection } from "@validereinc/domain";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import useTableState from "../../../hooks/useTableState";
import {
  ALERT_DURATION_TOOLTIP,
  ALERT_THRESHOLD_TOOLTIP,
  ALERT_WINDOW_TOOLTIP,
} from "../AlertConstants";
import {
  displayConditionValue,
  getAlertName,
  getAlertTimestamp,
  getMeasurementIds,
} from "./AlertListHelpers";

const sorting: SortingType = {
  sortBy: "started_at",
  sortDirection: SortDirection.DESCENDING,
};

const filter = ({ value, item }) => {
  if (Array.isArray(item)) {
    if (Array.isArray(value)) {
      return item.some((element) => value.includes(element));
    } else {
      return item.includes(value);
    }
  }
  return (
    item === value ||
    (value?.toLowerCase?.() ?? value)?.includes(item?.toLowerCase?.() ?? item)
  );
};

const AlertList = ({ breadcrumbs }) => {
  const { tableConfigStorageKey } = useStorageKey("alerts");
  const [searchParams] = useSearchParams();
  const { localize } = useLocalization();
  const [facilities] = useCarbonFacilities();
  const [devices] = useDevices();

  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState(undefined);

  const onFetchData = useCallback(async (newSearchParams) => {
    let newResponse = response;

    if (!response) {
      const alertResponse = await AlertingService.getAlerts();

      const {
        data: { data: seriesData },
      } = await MeasurementsService.getMeasurementSources({
        measurement_series_ids: getMeasurementIds(alertResponse.data.data),
        rowPerPage: 1000,
      });

      const measurementSeriesMap = getPropertyAsMap(seriesData);

      newResponse = {
        data: alertResponse.data.data.map((alert) => {
          const {
            monitor: { conditions },
          } = alert;

          const measurementSeriesData = uniqueArray(
            conditions,
            "measurement_series_id"
          )
            .filter((data) => data)
            .map(
              ({ measurement_series_id }) =>
                measurementSeriesMap[measurement_series_id]
            );

          const isSingleAlert = measurementSeriesData.length === 1;

          const alertedMeasurement = isSingleAlert
            ? measurementSeriesData[0]?.measurement_type_name
            : "Many";

          return {
            ...alert,
            alertedMeasurement,
            measurementSeriesData,
          };
        }),
      };
      setResponse(newResponse);
      setIsLoading(false);
    }

    const searchParamsWithDefaults = {
      ...newSearchParams,
      sort: newSearchParams.sort ?? sorting.sortBy,
      sortDirection: newSearchParams.sortDirection ?? sorting.sortDirection,
    };

    return getFrontendTableState({
      data: newResponse,
      itemsKey: "data",
      query: searchParamsWithDefaults,
      filterMapping: {
        facility: {
          itemKey: "measurementSeriesData",
          filter: ({ value, item }) => {
            const facilityIdsToKeep = Array.isArray(item) ? item : [item];
            return value
              ?.map((data) => data?.device?.facility_id)
              ?.some((id) => facilityIdsToKeep.includes(id));
          },
        },
        device: {
          itemKey: "measurementSeriesData",
          filter: ({ value, item }) => {
            const deviceIdsToKeep = Array.isArray(item) ? item : [item];
            return value
              ?.map((data) => data?.device?.id)
              ?.some((id) => deviceIdsToKeep.includes(id));
          },
        },
        alertedMeasurement: {
          itemKey: "alertedMeasurement",
          filter,
        },
        name: {
          itemKey: "monitor.name",
          sort: (a, b) => a?.monitor?.name.localeCompare(b?.monitor?.name),
          filter,
        },
      },
    });
  }, []);

  const headers: Array<HeaderType<any>> = [
    {
      label: "Alert Name",
      key: "name",
      renderComponent: ({ item }) => (
        <RoutingLink to={linkToAlertDetail(item?.id)}>
          {getAlertName(item)}
        </RoutingLink>
      ),
      isSortable: true,
    },
    {
      label: "Status",
      key: "resolved",
      renderComponent: ({ item }) => (
        <Pill variant={item?.resolved ? "default" : "error"}>
          {item?.resolved ? "Closed" : "Open"}
        </Pill>
      ),
      isSortable: true,
    },
    {
      label: "Aggregation Function",
      key: "aggregation_function",
      renderComponent: displayConditionValue,
    },
    {
      label: "Alerted Measurement",
      key: "alertedMeasurement",
    },
    {
      label: "Operator",
      key: "operator",
      renderComponent: displayConditionValue,
    },
    {
      label: "Threshold",
      tooltip: ALERT_THRESHOLD_TOOLTIP,
      key: "threshold",
      renderComponent: displayConditionValue,
    },
    {
      label: "Window",
      tooltip: ALERT_WINDOW_TOOLTIP,
      key: "window",
      renderComponent: displayConditionValue,
    },
    {
      label: "Duration (mins)",
      tooltip: ALERT_DURATION_TOOLTIP,
      key: "duration",
      renderComponent: displayConditionValue,
    },
    {
      label: "Started At",
      key: "started_at",
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={moment.utc(getAlertTimestamp(item)).format()}
          withTime
        />
      ),
      isSortable: true,
    },
    {
      label: "Device Type",
      key: "device_type",
      renderComponent: ({ item }) => {
        const devices = uniqueArray(
          item?.measurementSeriesData?.map(({ device }) => device)
        );
        const isSingleCondition = devices.length === 1;
        return isLoading ? (
          <div className="skeleton" />
        ) : isSingleCondition ? (
          devices[0]?.type?.name
        ) : (
          `${devices.length} devices`
        );
      },
    },
    {
      label: "Device",
      key: "device_id",
      renderComponent: ({ item }) => {
        const devices = uniqueArray(
          item?.measurementSeriesData?.map(({ device }) => device)
        );
        const isSingleCondition = devices.length === 1;

        if (isLoading) {
          return <div className="skeleton" />;
        }

        if (isSingleCondition) {
          return (
            <RoutingLink to={linkToDeviceDetail(devices[0]?.id)}>
              {item?.measurementSeriesData[0]?.device_name}
            </RoutingLink>
          );
        }

        return `${devices.length} devices`;
      },
    },
    {
      label: localize("facility"),
      key: "facility_id",
      renderComponent: ({ item }) => {
        const facilities = uniqueArray(
          item?.measurementSeriesData?.map(
            ({ device: { facility } }) => facility
          )
        );
        const isSingleCondition = facilities.length === 1;

        if (isLoading) {
          return <div className="skeleton" />;
        }

        if (isSingleCondition) {
          return (
            <RoutingLink to={linkToFacilities(facilities[0]?.id)}>
              {facilities[0]?.name}
            </RoutingLink>
          );
        }

        return `${facilities.length} facilities`;
      },
    },
  ];

  const { tableProps, fetchData } = useTableState({
    onFetchData,
    initialSort: sorting,
  });

  const { filterProps } = useFilterPanel(headers);

  useEffect(() => {
    fetchData(searchParams);
  }, [response, searchParams]);

  return (
    <Page
      title="Alerts"
      breadcrumbs={breadcrumbs}
    >
      <FilterPanel
        {...filterProps}
        defaultActiveKeys={["Filters"]}
        isLoading={isLoading}
        filters={[
          {
            section: "Filters",
            component: (
              <DropdownInput
                label="Device"
                name="device"
                options={devices}
                labelKey="name"
                valueKey="id"
                isMulti
                isFluid
                isSearchable
                isOptionalTextShown={false}
              />
            ),
          },
          {
            section: "Filters",
            component: (
              <DropdownInput
                label={`${localize("facility")}`}
                name="facility"
                options={facilities}
                labelKey="name"
                valueKey="id"
                isMulti
                isFluid
                isSearchable
                isOptionalTextShown={false}
              />
            ),
          },
          {
            section: "Filters",
            component: (
              <TextInput
                name="alertedMeasurement"
                label="Alerted Measurement"
                placeholder="Measurement"
                type="search"
                isOptionalTextShown={false}
              />
            ),
          },
        ]}
      />
      <DataTablePanel
        storageKey={tableConfigStorageKey}
        dataTableProps={{
          ...tableProps,
          headers,
          isLoading,
        }}
      />
    </Page>
  );
};

export default AlertList;
