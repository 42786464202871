import { Breadcrumb } from "@sentry/react";

export const isPrimitiveString = (value: unknown): value is string =>
  typeof value === "string";
export const isPrimitiveStringArray = (value: unknown): value is string[] =>
  Array.isArray(value) && value.every((v) => typeof v === "string");

/**
 * Category that the lifecycle event falls under
 * @see https://docs.sentry.io/product/data-management-settings/scrubbing/server-side-scrubbing/ consider scrubbed terms
 * @see https://docs.sentry.io/platforms/javascript/data-management/sensitive-data/#scrubbing-data scrubbing before sending to Sentry
 */
export const LifecycleEventCategory = {
  /** events related to the app */
  APP: "app",
  /** events related to a user's session */
  SESSION: "session",
  /** events related to a user's session and their team/company/organization */
  TENANT: "tenant",
} as const;

export type LifecycleEventCategoryType =
  (typeof LifecycleEventCategory)[keyof typeof LifecycleEventCategory];

export interface LifecycleEvent
  extends Pick<Breadcrumb, "level" | "message" | "data"> {
  /** the grouping that the event falls under */
  category: LifecycleEventCategoryType;
  /** the concern of the lifecycle event. Note, http and navigation events are
   * automatically captured at the moment due to the underlying Sentry
   * integration so they don't need to be registered */
  type: "info" | "debug" | "error" | "query" | "http" | "navigation" | "user";
}

/**
 * Additional details about an extension are tracked under these keys.
 */
export const ExceptionContextKeys = {
  timezone_preferred: "validere.timezone_preferred",
  timezone_actual: "validere.timezone_actual",
  locale_preferred: "validere.locale_preferred",
  locale_actual: "validere.locale_actual",
  company: "validere.company_id",
  hasQuicksightIntegration: "validere.has_quicksight_integration",
  activeAuthVersion: "validere.active_auth_version",
  sessionStart: "validere.session_start",
  isAppShellFatalFailure: "validere.is_app_shell_fatal_failure",
  isValidereDefined: "validere.is_validere_defined",
  exceptionCode: "validere.exception_code",
  networkRequestEndpoint: "validere.network_endpoint",
  sourceUI: "validere.sourceUI",
} as const satisfies Record<string, `validere.${string}`>;
