import { AddRecordDialog } from "#batteries-included-components/Dialogs/AddRecordDialog";
import {
  useEnhanceRecordResources,
  useListRecords,
  useRecordMeasurementTypeSpecificTableHeader,
} from "#hooks/adapters/useRecords";
import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import { useParams } from "#routers/hooks";
import BulkRecordStatusChangeButton from "#src/batteries-included-components/Buttons/BulkRecordStatusChangeButton/BulkRecordStatusChangeButton";
import { RecordValueDrawer } from "#src/batteries-included-components/Drawers/RecordValueDrawer";
import { useRecordValueDrawer } from "#src/batteries-included-components/Drawers/RecordValueDrawer/RecordValueDrawer.helper";
import { RecordsTableFilterArea } from "#src/batteries-included-components/FilterAreas/RecordsFilterAreas";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import InlineRecordValueStatusPicker from "#src/components/Common/InlineRecordValueStatusPicker/InlineRecordValueStatusPicker";
import { RecordValueCell } from "#src/components/Common/RecordValueCell/RecordValueCell";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { DEFAULT_DATE_RANGES } from "#src/hooks/useDateRange";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import { useApplyDefaultRecordConfiguration } from "#src/queries/recordQueries";
import { linkToRecordDetail } from "#utils/links";
import {
  Accordion,
  Button,
  CheckboxInput,
  DataTablePanel,
  Dialog,
  Form,
  HeaderType,
  StorageKeys,
  useForm,
} from "@validereinc/common-components";
import type { AssetTypeType, RecordType } from "@validereinc/domain";
import { AssetType, SortDirection } from "@validereinc/domain";
import {
  fromUTC,
  monthFormatter,
  yearMonthFormatter,
  yearMonthParser,
} from "@validereinc/utilities";
import parseISO from "date-fns/parseISO";
import React, { useMemo, useState } from "react";

export const RecordsTablePanel = ({
  type,
  filterConfigStorageKey,
  viewConfigStorageKey,
  tableConfigStorageKey,
}: {
  type: AssetTypeType;
} & StorageKeys) => {
  const { getTypeName } = useMeasurementTypes();
  const [showApplyWarning, setShowApplyWarning] = useState(false);

  const [viewFilters] = useSessionStickyState<{
    reporting_group_id: string;
    date_range: { from: string; to: string };
  }>({}, viewConfigStorageKey);
  const [tableFilters] = useSessionStickyState<{
    measurement_type?: string;
  }>({}, filterConfigStorageKey);
  const filters = useMemo(
    () => ({ ...tableFilters, ...viewFilters }),
    [tableFilters, viewFilters]
  );
  const {
    reporting_group_id,
    date_range,
    measurement_type: measurement_type_filter,
  } = filters;

  const [tableState, updateTableState] = useTableSortingAndPagination(
    {
      sortBy: "year_month",
      sortDirection: SortDirection.DESCENDING,
    },
    filters
  );

  const { flowId, equipmentId, facilityId } = useParams<{
    flowId: string;
    equipmentId: string;
    facilityId: string;
  }>();

  const [isAddDialogOpen, setAddDialogOpen] = useState(false);
  const [selectedRecords, setSelectedRecords] = useState({});

  const applyAutomation = useApplyDefaultRecordConfiguration();

  let asset_id: string;
  switch (type) {
    case AssetType.FACILITY:
      asset_id = facilityId;
      break;
    case AssetType.EQUIPMENT:
      asset_id = equipmentId;
      break;
    case AssetType.FLOW:
    default:
      asset_id = flowId;
  }

  const minYearMonth = date_range?.from
    ? yearMonthFormatter(fromUTC(parseISO(date_range?.from)))
    : yearMonthFormatter(DEFAULT_DATE_RANGES.lastTwelveMonths.from);
  const maxYearMonth = date_range?.to
    ? yearMonthFormatter(fromUTC(parseISO(date_range?.to)))
    : yearMonthFormatter(DEFAULT_DATE_RANGES.lastTwelveMonths.to);

  const isRecordQueryEnabled = !!reporting_group_id;
  const recordQuery = useListRecords(
    {
      page: tableState.page,
      pageSize: tableState.pageSize,
      sortBy: tableState.sortBy,
      sortDirection: tableState.sortDirection,
      groupBy: ["id", "year_month"],
      filters: {
        asset_id,
        reporting_group_id: { $exact: reporting_group_id },
        $and: [
          {
            year_month: {
              $gte: minYearMonth,
            },
          },
          {
            year_month: {
              $lte: maxYearMonth,
            },
          },
        ],
      },
    },
    {
      enabled: isRecordQueryEnabled,
    }
  );

  const isPropertyFilterEnabled = !!measurement_type_filter;

  // This hook enhances record values with calculator_result configuration to have estimation_method_entity_id:
  const { recordOrRecords: items, isLoading: isEnhancing } =
    useEnhanceRecordResources(recordQuery?.data?.data ?? [], {
      enabled: isPropertyFilterEnabled,
    });

  const unfilteredRecordValueTypes: string[] = Array.from(
    new Set(
      items?.flatMap(({ values }: RecordType) =>
        values.map(
          ({ measurement_type }: { measurement_type: string }) =>
            measurement_type
        )
      )
    )
  );

  const isLoading =
    (isRecordQueryEnabled && recordQuery.isLoading) ||
    (isPropertyFilterEnabled && isEnhancing);

  const recordValueTypes = isPropertyFilterEnabled
    ? [measurement_type_filter]
    : unfilteredRecordValueTypes;

  const actionRow = [
    <Button
      key="add-month"
      variant="outline"
      onClick={() => {
        setAddDialogOpen(true);
      }}
    >
      Add Month
    </Button>,
  ];

  const actionRowWithSelection = [
    <BulkRecordStatusChangeButton
      key="status-picker"
      filter={{ id: Object.keys(selectedRecords) }}
      recordsCount={Object.keys(selectedRecords).length}
    />,
    <Button
      key="apply-automation"
      isLoading={applyAutomation.isLoading}
      onClick={() => setShowApplyWarning(true)}
    >
      Apply Record Automation
    </Button>,
  ];

  const form = useForm({});

  const handleSubmit = form.handleSubmit((values) => {
    const { overwrite_all_values } = values;
    setShowApplyWarning(false);
    applyAutomation.mutate({
      filters: { id: Object.keys(selectedRecords) },
      overwriteAllValues: overwrite_all_values,
    });
  });

  const propertySpecificHeaders = useRecordMeasurementTypeSpecificTableHeader(
    measurement_type_filter
  );

  const { openDrawer, drawerState, navigationProps, onClose, recordValue } =
    useRecordValueDrawer({
      items,
      itemToAssetIdFunction: () => asset_id,
    });

  const headers: Array<HeaderType<RecordType>> = [
    {
      key: "year_month",
      label: "Time Period",
      isSortable: true,
      renderComponent: ({ item: { year_month, id } }: { item: RecordType }) =>
        year_month ? (
          <RoutingLink to={linkToRecordDetail(type, asset_id, id)}>
            {monthFormatter(yearMonthParser(year_month))}
          </RoutingLink>
        ) : (
          "-"
        ),
    },
    {
      key: "status",
      label: "Record Status",
      isSortable: false,
      renderComponent: ({ item }) => {
        const { values, id } = item;
        return (
          <InlineRecordValueStatusPicker
            recordId={id}
            values={values}
          />
        );
      },
    },
    ...recordValueTypes.map((key) => ({
      key,
      label: getTypeName(key),
      alignment: "right",
      renderComponent: ({
        item: { values, year_month, id },
      }: {
        item: RecordType;
      }) => {
        const findValue = values.find(
          ({ measurement_type }: { measurement_type: string }) =>
            measurement_type === key
        );

        return (
          <RecordValueCell
            value={findValue}
            onClick={() => openDrawer(asset_id, id, year_month, key)}
          />
        );
      },
    })),
    ...propertySpecificHeaders,
  ];

  const getItemActions = ({ item }: { item: RecordType }) => {
    return [
      {
        label: "Apply Automation",
        buttonProps: {
          onClick: () =>
            applyAutomation.mutate({
              filters: { id: item.id },
              overwriteAllValues: false,
            }),
        },
      },
    ];
  };

  return (
    <>
      <DataTablePanel
        actionRowWhenNoRowsSelected={actionRow}
        actionRowWhenRowsSelected={actionRowWithSelection}
        storageKey={tableConfigStorageKey}
        filterComponent={
          <RecordsTableFilterArea
            filterConfigStorageKey={filterConfigStorageKey}
            selectableProperties={unfilteredRecordValueTypes}
          />
        }
        dataTableProps={{
          headers,
          items,
          isLoading,
          selected: selectedRecords,
          getItemId: (item) => item.id,
          onSelectionChange: setSelectedRecords,
          onSortChange: updateTableState,
          onPaginationChange: updateTableState,
          getItemActions,
          sorting: {
            sortBy: tableState.sortBy,
            sortDirection: tableState.sortDirection,
          },
          pagination: {
            page: tableState.page,
            pageSize: tableState.pageSize,
            total: recordQuery.data?.total_entries,
          },
        }}
        panelProps={{ title: "Records" }}
      />
      <AddRecordDialog
        isOpen={isAddDialogOpen}
        onClose={() => setAddDialogOpen(false)}
        onSubmit={() => {
          recordQuery.refetch();
        }}
        assetId={asset_id}
        assetType={type}
        reportingGroupId={reporting_group_id}
      />
      <RecordValueDrawer
        recordValue={recordValue}
        state={drawerState}
        onClose={onClose}
        assetType={type}
        applyAutomation={(id) => applyAutomation.mutate({ id })}
        navgivationProps={navigationProps}
      />
      <Dialog
        key="apply-automation"
        title="Apply Record Automation?"
        isOpen={showApplyWarning}
        onClose={() => setShowApplyWarning(false)}
        actionRow={[
          <Button
            key="apply-automation-action"
            variant="primary"
            onClick={handleSubmit}
          >
            Apply Automation
          </Button>,
        ]}
      >
        <p>You have selected to apply record automation.</p>

        <Accordion>
          <Accordion.AccordionPanel
            title="Advanced Configuration"
            dataKey="advanced-configuration"
          >
            <p style={{ width: 484 }}>
              Applying record automation will populate record values based off
              predefined automations to all applicable empty record value
              fields. Manually modified values will not be overwritten unless
              otherwise specified.
            </p>
            <Form {...form}>
              <CheckboxInput
                name="overwrite_all_values"
                label="Overwrite manual values with automation?"
                isLabelShown={false}
                isFluid
              />
            </Form>
          </Accordion.AccordionPanel>
        </Accordion>
      </Dialog>
    </>
  );
};
