import { TypeOfObjectValues } from "@validereinc/domain";
import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getSmoothStepPath,
} from "@xyflow/react";
import classNames from "classnames/bind";
import React, { useMemo } from "react";
import { WorkflowGraphEdgeType } from "./types";
import { WorkflowGraphEdgeMarkerEndMap } from "./WorkflowGraphEdgeMarkers";

import { Icon, TextDataDisplay, Tooltip } from "@validereinc/common-components";
import { WorkflowGraphCanvasOuterBoundaryId } from "../WorkflowGraphCanvas/WorkflowGraphCanvas";
import styles from "./WorkflowGraphEdge.module.scss";
const cx = classNames.bind(styles);

export const WorkflowGraphEdgeOffset = 32;

const WorkflowGraphEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
}: EdgeProps<WorkflowGraphEdgeType>) => {
  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    offset: WorkflowGraphEdgeOffset,
  });

  const hovered = !!data?.isHovered;

  const markerStart:
    | TypeOfObjectValues<typeof WorkflowGraphEdgeMarkerEndMap>
    | undefined = useMemo(() => {
    if (hovered) return WorkflowGraphEdgeMarkerEndMap.HOVERED;
    return;
  }, [data]);

  const markerEnd: TypeOfObjectValues<typeof WorkflowGraphEdgeMarkerEndMap> =
    useMemo(() => {
      if (data?.isDisabled) return WorkflowGraphEdgeMarkerEndMap.DISABLED;
      if (hovered) return WorkflowGraphEdgeMarkerEndMap.HOVERED;
      return WorkflowGraphEdgeMarkerEndMap.DEFAULT;
    }, [data]);

  const stateStyles = { disabled: data?.isDisabled, hover: data?.isHovered };

  const shouldShowEdgeLabel = !!data?.label || !!data?.icon;

  return (
    <>
      <BaseEdge
        id={id}
        path={edgePath}
        className={cx({
          default: true,
          ...stateStyles,
        })}
        markerEnd={`url('#${markerEnd}')`}
        markerStart={`url('#${markerStart}')`}
      />
      {shouldShowEdgeLabel && (
        <EdgeLabelRenderer>
          <div
            style={{
              transform: `translate(-50%, -40px) translate(${targetX}px,${targetY}px)`,
            }}
            className={`"nodrag nopan " ${cx({ label: true, ...stateStyles })}`}
          >
            <Tooltip
              content={data.label}
              show={!!data?.isHovered && !!data.label}
              boundaryElementId={WorkflowGraphCanvasOuterBoundaryId}
            >
              <div className={cx("label-content")}>
                {data?.icon && (
                  <div className={cx("icon-container")}>
                    <Icon
                      variant={data.icon}
                      size={16}
                    />
                  </div>
                )}
                {data?.label && (
                  <TextDataDisplay
                    clamp={1}
                    value={data.label}
                  />
                )}
              </div>
            </Tooltip>
          </div>
        </EdgeLabelRenderer>
      )}
    </>
  );
};

export default WorkflowGraphEdge;
