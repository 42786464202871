import { Icon, type IconProps } from "@validereinc/common-components";
import classNames from "classnames/bind";
import React from "react";
import styles from "./Marker.module.scss";

const cx = classNames.bind(styles);

const MarkerIcon = ({ iconProps, colorVariant = "navy" }: MarkerIconProps) => {
  return (
    <div
      className={cx(colorVariant && `marker--color-${colorVariant}`, "marker")}
    >
      <Icon
        size={24}
        {...iconProps}
        weight="regular"
      />
    </div>
  );
};

export type MarkerIconProps = {
  iconProps: Omit<IconProps, "weight" | "colour">;
  colorVariant: "navy" | "poppy";
};

export default MarkerIcon;
