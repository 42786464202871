import {
  DataTable,
  DropdownInput,
  RadioInput,
  TextInput,
} from "@validereinc/common-components";
import type {
  CalculationParameterSavedType,
  CalculationParameterType,
} from "@validereinc/domain";
import React from "react";

export const FILTER_CONFIG = {
  month: {
    name: "month",
  },
};
export const FILTER_CONFIG_NAME_KEYS = Object.entries(FILTER_CONFIG).map(
  ([_, filter]) => filter.name
);
export const TRANSLATIONS = {
  actions: {
    applyDefaults: "Apply Defaults",
    preview: "Calculate",
    saveRecord: "Save",
    month: {
      previous: "Previous Month",
      next: "Next Month",
    },
  },
  content: {
    inputPlayground: {
      title: "Calculation Inputs",
      empty: {
        title: "Calculation parameters unavailable",
        suggestion:
          "This estimation method doesn't have a valid calculator associated with it",
      },
      saved: (lastSavedDate?: string, lastSavedByName?: string) =>
        lastSavedDate
          ? `Last saved ${lastSavedDate}${
              lastSavedByName ? ` by ${lastSavedByName}` : ""
            }`
          : null,
    },
    previewPlayground: {
      title: "Preview Results",
      empty: {
        title: "There are no preview results",
        suggestion: "Enter inputs and calculate to generate preview results",
      },
      sync: {
        title: "Preview results are out of date",
        suggestion: "Run calculate again to generate new preview results",
      },
    },
    contextPlayground: {
      title: "Saved Results",
      empty: {
        title: "There is no saved record",
        suggestion:
          "Save results for the current selected period to create a record",
      },
      savedInfoPanel: {
        lastSaved: "Last Saved",
        savedBy: "Saved By",
        period: "Period",
      },
    },
    results: {
      unit: "tonnes",
    },
  },
  messaging: {
    inputConfig: {
      update: {
        success: "Saved input configuration",
        error: "Failed to save input configuration",
      },
    },
    calculation: {
      update: {
        error: "Failed to calculate preview",
      },
      create: {
        success: "Saved results as record",
        error: "Failed to save as record",
      },
      validation: {
        error: "Failed to run calculation. Inputs don't look right!",
      },
    },
    applyDefaults: {
      success: "Applied default values to calculator configuration",
      error: "Failed to apply default values",
    },
  },
};

export const getCalculationParameterInput = (
  calcParameter: CalculationParameterType
) => {
  const {
    required,
    enum: stringOpts,
    display_name: label,
    description,
    id,
    minimum: min,
    maximum: max,
    exclusive_minimum: exclusiveMin,
    exclusive_maximum: exclusiveMax,
    measurement_unit: unit,
  } = calcParameter;

  switch (calcParameter.type) {
    case "boolean":
      return (
        <RadioInput
          key={id}
          // TODO: is this what the back-end expects?
          options={[
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" },
          ]}
          labelKey="label"
          valueKey="value"
          isRequired={required}
          label={label}
          description={description}
          name={id}
        />
      );
    case "number":
    case "integer":
      return (
        <TextInput
          key={id}
          type="number"
          step={calcParameter.type === "number" ? 0.1 : 1}
          unit={unit}
          isRequired={required}
          label={label}
          description={description}
          name={id}
          validate={{
            min: (value: number | string) => {
              if (isNaN(Number(value)) || value === "") {
                return true;
              }

              if (min !== undefined && !isNaN(Number(min))) {
                return (
                  value >= min || `should be greater than or equal to ${min}`
                );
              }

              if (exclusiveMin !== undefined && !isNaN(Number(exclusiveMin))) {
                return (
                  value > exclusiveMin ||
                  `should be greater than ${exclusiveMin}`
                );
              }

              return true;
            },
            max: (value: number | string) => {
              if (isNaN(Number(value)) || value === "") {
                return true;
              }

              if (max !== undefined && !isNaN(Number(max))) {
                return value <= max || `should be less than or equal to ${max}`;
              }

              if (exclusiveMax !== undefined && !isNaN(Number(exclusiveMax))) {
                return (
                  value < exclusiveMax || `should be less than ${exclusiveMax}`
                );
              }

              return true;
            },
            number: (value: number | string) => {
              if (isNaN(Number(value)) || value === "") {
                return true;
              }

              // validation for "integer" type only as "number" type can be both a float or an integer
              const isInteger = Number.isInteger(Number(value));

              if (calcParameter.type === "integer" && !isInteger) {
                return "should be a whole number";
              }

              return true;
            },
          }}
        />
      );
    case "string":
      return (
        <DropdownInput
          key={id}
          options={stringOpts}
          isRequired={required}
          label={label}
          description={description}
          name={id}
          isFluid
        />
      );
    default:
      return null;
  }
};

export const getCalculationInputTableCellDisplayValue = (
  calculationInputAsOutput?: Pick<
    CalculationParameterSavedType,
    "type" | "measurement_value"
  >,
  alignment: "left" | "right" = "right"
) => {
  const { type, measurement_value: value } = calculationInputAsOutput ?? {};

  switch (type) {
    case "number":
      return (
        <DataTable.DataRow.NumberCell
          value={value}
          formattingOpts={{
            overrideOpts: {
              maximumFractionDigits: 3,
            },
          }}
        />
      );
    case "boolean":
    case "string":
    default:
      return <p style={{ display: "block", textAlign: alignment }}>{value}</p>;
  }
};
