import { z } from "zod";
import { ResourcesSchema, Resources } from "../util";
import {
  DomainModelMetaExtendedSchema,
  DomainModelMetaSchema,
} from "./DomainModelSchemas";
import { TemplatedConfigurationSchema } from "./TemplatedConfigurationSchemas";

export const TemplatedConfigurationResourceStatus = {
  SUBMITTED: "submitted",
  SUCCESS: "success",
  FAILED: "failed",
} as const;

export type TemplatedConfigurationResourceStatusType =
  (typeof TemplatedConfigurationResourceStatus)[keyof typeof TemplatedConfigurationResourceStatus];

export const TemplatedConfigurationRunStatus = {
  SUBMITTED: "submitted",
  STARTED: "started",
  SUCCESS: "success",
  FAILED: "failed",
  PARTIAL: "partial",
} as const;

export type TemplatedConfigurationRunStatusType =
  (typeof TemplatedConfigurationRunStatus)[keyof typeof TemplatedConfigurationRunStatus];

export const TemplatedConfigurationResourceTypes = {
  equipment: Resources.EQUIPMENT,
  flow: Resources.FLOW,
  asset_group: Resources.ASSET_GROUP,
  asset_group_asset: Resources.ASSET_GROUP_ASSET,
  form_submission: Resources.FORM_SUBMISSION,
  default_record_value_configuration:
    Resources.DEFAULT_RECORD_VALUE_CONFIGURATION,
  estimation_method: Resources.ESTIMATION_METHOD,
  default_calculator_input_record: Resources.DEFAULT_CALCULATOR_INPUT_RECORD,
  reporting_group_estimation_method:
    Resources.REPORTING_GROUP_ESTIMATION_METHOD,
  reporting_group: Resources.REPORTING_GROUP,
} as const;

export type TemplatedConfigurationResourceTypeType =
  (typeof TemplatedConfigurationResourceTypes)[keyof typeof TemplatedConfigurationResourceTypes];

export const TemplateInputValuesSchema = z.record(
  z.string(),
  z.union([z.string(), z.number(), z.boolean(), z.null()])
);

export const TemplateCustomAttributeInputSchema = z.record(
  z.string(),
  z.record(
    z.string(),
    z.union([
      z.string(),
      z.array(z.string()),
      z.number(),
      z.array(z.number()),
      z.boolean(),
      z.null(),
    ])
  )
);

export const TemplateCustomAttributeConfigurationSchema = z.record(
  z.string(),
  z.object({ entity_subtype: z.string() })
);

export const TemplatedConfigurationRunResource = z.object({
  template_resource_id: z.string(),
  templated_configuration_run_id: z.string(),
  status: z.enum([
    TemplatedConfigurationResourceStatus.SUBMITTED,
    TemplatedConfigurationResourceStatus.SUCCESS,
    TemplatedConfigurationResourceStatus.FAILED,
  ]),
  name: z.string().nullish(),
  type: ResourcesSchema.nullish(),
  id: z.string().nullish(),
  errors: z.record(z.string(), z.any()),
  is_primary: z.boolean(),
});

const TemplatedConfigurationRunResourceSchema = z.object({
  template_resource_id: z.string(),
  templated_configuration_run_id: z.string().uuid(),
  status: z.nativeEnum(TemplatedConfigurationResourceStatus),
  name: z.string().nullish(),
  type: z.nativeEnum(TemplatedConfigurationResourceTypes),
  id: z.string().uuid().nullish(),
  errors: z.record(z.string(), z.any()),
  is_primary: z.boolean(),
  metadata: z
    .object({
      facility_name: z.string(),
      facility_id: z.string().uuid(),
      dependencies: z.array(z.string()),
    })
    .nullish(),
});

export const TemplatedConfigurationRun = z
  .object({
    id: z.string(),
    templated_configuration_name: z.string(),
    company_id: z.string(),
    inputs: TemplateInputValuesSchema,
    custom_attribute_inputs: TemplateCustomAttributeInputSchema,
    custom_attribute_configuration: TemplateCustomAttributeConfigurationSchema,
    status: z.nativeEnum(TemplatedConfigurationRunStatus),
    results: z
      .record(z.string(), z.any())
      .describe("Schema for run results, such as error messages"),
    primary_resource: TemplatedConfigurationRunResourceSchema,
  })
  .merge(DomainModelMetaSchema)
  .merge(DomainModelMetaExtendedSchema);

export const TemplatedConfigurationRunCreatedSchema = z
  .object({
    resources: z.array(TemplatedConfigurationRunResource),
    templated_configuration: TemplatedConfigurationSchema.pick({
      name: true,
      display_name: true,
      primary_resource_type: true,
    }),
  })
  .merge(TemplatedConfigurationRun);

export const TemplatedConfigurationRunCreateSchema =
  TemplatedConfigurationRun.pick({
    inputs: true,
    custom_attribute_inputs: true,
    custom_attribute_configuration: true,
  }).describe(
    "Schema for creating a new Templated Configuration Run resource type"
  );

export type TemplatedConfigurationRunType = z.infer<
  typeof TemplatedConfigurationRun
>;

export type TemplatedConfigurationRunCreateType = z.infer<
  typeof TemplatedConfigurationRunCreateSchema
>;

export type TemplatedConfigurationRunCreatedType = z.infer<
  typeof TemplatedConfigurationRunCreatedSchema
>;

type TemplatedConfigurationDependencyGraphResourceNodeRecursiveType = {
  id: string;
  resource_id: string;
  type: TemplatedConfigurationResourceTypeType;
  name: string;
  status: TemplatedConfigurationResourceStatusType;
  associated_resources: Record<
    string,
    TemplatedConfigurationDependencyGraphResourceNodeRecursiveType[]
  >;
};

export const TemplatedConfigurationDependencyGraphResourceNodeSchema: z.ZodSchema<TemplatedConfigurationDependencyGraphResourceNodeRecursiveType> =
  z.lazy(() =>
    z.object({
      id: z.string(),
      resource_id: z.string(),
      type: z.nativeEnum(TemplatedConfigurationResourceTypes),
      name: z.string(),
      status: z.nativeEnum(TemplatedConfigurationResourceStatus),
      associated_resources: z.record(
        z.array(TemplatedConfigurationDependencyGraphResourceNodeSchema)
      ),
    })
  );

export type TemplatedConfigurationDependencyGraphResourceNodeType = z.infer<
  typeof TemplatedConfigurationDependencyGraphResourceNodeSchema
>;

export type TemplatedConfigurationRunResource = z.infer<
  typeof TemplatedConfigurationRunResource
>;

export const TemplatedConfigurationRunDependencyGraphSchema = z.object({
  name: z.string(),
  status: z.enum([
    TemplatedConfigurationRunStatus.SUCCESS,
    TemplatedConfigurationRunStatus.FAILED,
    TemplatedConfigurationRunStatus.PARTIAL,
  ]),
  updated_by: z.string(),
  created_at: z.string(),
  primary_resource: TemplatedConfigurationDependencyGraphResourceNodeSchema,
  additional_resources: z.record(
    z.array(TemplatedConfigurationDependencyGraphResourceNodeSchema)
  ),
  resource_status_counts: z.object({
    success: z.number(),
    failed: z.number(),
    submitted: z.number(),
  }),
});

export type TemplatedConfigurationRunDependencyGraphResponseType = z.infer<
  typeof TemplatedConfigurationRunDependencyGraphSchema
>;
