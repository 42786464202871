import {
  DEFAULT_INVALIDATE_OPTIONS,
  DEFAULT_QUERY_OPTIONS,
  UseMutationCallbackType,
} from "#hooks/adapters/adapterUtils";
import { useClearRecordCache } from "#hooks/adapters/useRecords";
import { ExceptionUtils } from "#src/utils/exception";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@validereinc/common-components";
import {
  BaseError,
  CalculatorResultsDomain,
  CreateRequestType,
  GetListCalculatorResultsFiltersType,
  GetListRequestType,
  SavedFilterType,
  UpdateRequestType,
} from "@validereinc/domain";
import { downloadLink } from "@validereinc/utilities";

export const CALCULATOR_RESULTS_QUERY_KEY = "calculatorResults";

export const useListCalculatorResults = (
  params: Parameters<typeof CalculatorResultsDomain.getList>[0],
  options: { enabled?: boolean } = {}
) =>
  useQuery({
    queryKey: [CALCULATOR_RESULTS_QUERY_KEY, params] as const,
    queryFn: ({ queryKey: [_, queryParams] }) =>
      CalculatorResultsDomain.getList(queryParams),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  });

export const useExportCalculatorResults = (
  apiParams: GetListRequestType<GetListCalculatorResultsFiltersType> = {}
) => {
  const { toast } = useToast();

  return useMutation({
    mutationFn: async () => {
      const report = await CalculatorResultsDomain.export(apiParams);
      if (!report?.s3_download_link) {
        throw new BaseError(
          `Could not fetch download URL for calculator results export`,
          { cause: report }
        );
      }

      downloadLink(report.s3_download_link, "");
    },
    onError: (error) => {
      ExceptionUtils.reportException(error, "error");
      toast.push({
        intent: "error",
        description: "Failed to export calculator results list.",
      });
    },
  });
};

export const useListCalculatorResultSavedFilters = (
  params = {},
  options: { enabled?: boolean } = {}
) =>
  useQuery({
    queryKey: [CALCULATOR_RESULTS_QUERY_KEY, "savedFilters", "getList", params],
    queryFn: ({ queryKey: [_, __, ___, apiParams] }) =>
      CalculatorResultsDomain.savedFilters.getList(apiParams),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  });

export const useCreateCalculatorResultSavedFilter = ({
  onSuccess,
}: UseMutationCallbackType = {}) => {
  const { toast } = useToast();
  const { invalidateSavedFilters } = useClearCalculatorResultsCache();

  return useMutation({
    mutationFn: (data: CreateRequestType["data"]) =>
      CalculatorResultsDomain.savedFilters.createOne({ data }),
    onSuccess: (data, variables, context) => {
      toast.push({
        intent: "success",
        description: "Successfully saved custom report",
      });
      invalidateSavedFilters();
      onSuccess?.(data, variables, context);
    },
    onError: () => {
      toast.push({
        intent: "error",
        description: "Failed to save custom report",
      });
    },
  });
};

export const useUpdateCalculatorResultSavedFilter = ({
  onSuccess,
}: UseMutationCallbackType = {}) => {
  const { toast } = useToast();
  const { invalidateSavedFilters } = useClearRecordCache();

  return useMutation({
    mutationFn: (
      params: UpdateRequestType<
        SavedFilterType<GetListCalculatorResultsFiltersType>
      >
    ) => CalculatorResultsDomain.savedFilters.updateOne(params),
    onSuccess: (data, variables, context) => {
      toast.push({
        intent: "success",
        description: "Successfully updated custom report",
      });
      invalidateSavedFilters();
      onSuccess?.(data, variables, context);
    },
    onError: () => {
      toast.push({
        intent: "error",
        description: "Failed to update custom report",
      });
    },
  });
};

export const useDeleteCalculatorResultSavedFilter = ({
  onSuccess,
}: UseMutationCallbackType = {}) => {
  const { toast } = useToast();
  const { invalidateSavedFilters } = useClearCalculatorResultsCache();

  return useMutation({
    mutationFn: (savedFilterId: string) =>
      CalculatorResultsDomain.savedFilters.deleteOne({ id: savedFilterId }),
    onSuccess: (data, variables, context) => {
      toast.push({
        intent: "success",
        description: "Successfully deleted custom report",
      });
      invalidateSavedFilters();
      onSuccess?.(data, variables, context);
    },
    onError: () => {
      toast.push({
        intent: "error",
        description: "Failed to delete custom report",
      });
    },
  });
};

export const useClearCalculatorResultsCache = () => {
  const queryClient = useQueryClient();
  return {
    invalidate: () => {
      queryClient.invalidateQueries({
        queryKey: [CALCULATOR_RESULTS_QUERY_KEY],
        ...DEFAULT_INVALIDATE_OPTIONS,
      });
    },
    invalidateSavedFilters: () => {
      queryClient.invalidateQueries({
        queryKey: [CALCULATOR_RESULTS_QUERY_KEY, "savedFilters"],
        ...DEFAULT_INVALIDATE_OPTIONS,
      });
    },
  };
};
