import { havePermission } from "#redux/reducers/permissions";
import CalibrationService from "#services/CalibrationService";
import SampleService from "#services/SampleService";
import StreamService from "#services/StreamService";
import {
  AlertMessage,
  Button,
  Form,
  FormButton,
  Modal,
  Title,
  useForm,
  useToast,
} from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { TEST_ASSIGN_OPTIONS } from "./AssignTestConstant";
import "./AssignTestModal.scss";
import AssignTestModalForm from "./AssignTestModalForm";
import {
  getAssignToSampleErrorMessage,
  getAssignToStreamErrorMessage,
} from "./AssignTestModalHelper";

// Assigning tests to samples is preferred so that sample date, sample
// type, and workflows are correctly recorded.
const DEFAULT_INPUTS = {
  tests: [],
  assignTo: TEST_ASSIGN_OPTIONS[0],
  sample: null,
  stream: null,
};

const mapStateToProps = ({ permissions }) => {
  return {
    canCreateSamples: havePermission(permissions)("360:field_labs", "write"),
  };
};

const useFetchShortCodes = () => {
  const [shortCodes, setShortCodes] = useState([]);
  const [state, setState] = useState("loading");

  useEffect(() => {
    setState("loading");

    CalibrationService.getShortCodes()
      .then(({ data }) => {
        setShortCodes(data);
        setState("loaded");
      })
      .catch(() => setState("error"));
  }, []);

  return [shortCodes, state];
};

const AssignTestModal = ({
  show,
  tests,
  onTestClick,
  hasWriteAccess,
  onModalClose,
  allTests,
  setShowNewSampleModal,
  canCreateSamples,
  samplesTableLastUpdatedAt,
}) => {
  const [showStreamInfo, setShowStreamInfo] = useState(true);

  const [formState, setFormState] = useState("enabled");
  const [hasAssigned, setHasAssigned] = useState(false);
  const [assignToErrorMessage, setAssignToErrorMessage] = useState(null);

  const [shortCodes] = useFetchShortCodes();
  const { toast } = useToast();

  const form = useForm({
    defaultValues: {
      ...DEFAULT_INPUTS,
    },
  });

  useEffect(() => {
    if (show && tests) {
      form.setValue("tests", tests);
    }
  }, [show, tests]);

  const onTestCheck = (checkedTests) => {
    form.setValue("tests", checkedTests);
  };

  const assignTest = (inputs, assignToID, assignTestService) => {
    setFormState("loading");

    const testIds = inputs.tests.map((test) => test.id);

    assignTestService(assignToID, testIds)
      .then(() => {
        toast.push({
          intent: "success",
          description: "Your tests have been assigned to a sample",
        });

        setHasAssigned(true);
      })
      .catch(() => {
        setFormState("enabled");
      })
      .finally(() => {
        if (hasWriteAccess) {
          setFormState("enabled");
        } else {
          setFormState("preview");
        }
      });
  };

  const onAssignSubmit = (inputs) => {
    if (inputs?.tests?.length > 0) {
      // TEST_ASSIGN_OPTIONS[0] is "sample"
      if (form.getValues("assignTo") === TEST_ASSIGN_OPTIONS[0]) {
        const errorMessage = getAssignToSampleErrorMessage(inputs);

        if (!errorMessage) {
          assignTest(inputs, inputs.sample?.id, SampleService.assignToSample);
          setAssignToErrorMessage(null);
        } else {
          setAssignToErrorMessage(errorMessage);
        }
      } else {
        const errorMessage = getAssignToStreamErrorMessage(inputs);

        if (!errorMessage) {
          assignTest(inputs, inputs.stream?.id, StreamService.assignToStream);
          setAssignToErrorMessage(null);
        } else {
          setAssignToErrorMessage(errorMessage);
        }
      }
    }
  };

  const onHide = () => {
    form.reset({ ...DEFAULT_INPUTS });
    onModalClose(hasAssigned);
  };

  const inputs = form.watch();

  return (
    <Modal
      className="assignTestModal"
      open={show}
      onClose={onHide}
    >
      <Form
        onSubmit={onAssignSubmit}
        {...form}
      >
        <Modal.Header>
          <Title>
            Assign Tests to{" "}
            {form.getValues("assignTo") === "stream" ? "Stream" : "Sample"}
          </Title>
        </Modal.Header>

        <Modal.Body>
          {form.getValues("assignTo") === "stream" && showStreamInfo && (
            <AlertMessage
              type="info"
              onClose={() => setShowStreamInfo(false)}
              style={{ marginBottom: "15px" }}
            >
              Assigning tests to samples is preferred so that sample date,
              sample type, and workflows are correctly recorded.
            </AlertMessage>
          )}

          <AssignTestModalForm
            form={form}
            inputs={inputs}
            formState={formState}
            testList={tests}
            shortCodes={shortCodes}
            hasWriteAccess={hasWriteAccess}
            onTestClick={onTestClick}
            onTestCheck={onTestCheck}
            allTests={allTests}
            setShowNewSampleModal={
              canCreateSamples ? setShowNewSampleModal : null
            }
            samplesTableLastUpdatedAt={samplesTableLastUpdatedAt}
            assignToErrorMessage={assignToErrorMessage}
          />
        </Modal.Body>

        <Modal.Footer className="clearfix">
          <Button
            className="pull-left"
            onClick={onModalClose}
          >
            Close
          </Button>

          <FormButton
            className="pull-right"
            variant="primary"
            disabled={formState !== "enabled"}
            type="submit"
          >
            Assign Test
          </FormButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

AssignTestModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  hasWriteAccess: PropTypes.bool,
  tests: PropTypes.array,
  refetchTests: PropTypes.func,
  addAlertMessage: PropTypes.func,
  allTests: PropTypes.array,
  canCreateSamples: PropTypes.bool,
  setShowNewSampleModal: PropTypes.func,
  samplesTableLastUpdatedAt: PropTypes.instanceOf(Date),
  onTestClick: PropTypes.func,
  loadingState: PropTypes.string,
};

const AssignTestModalContainer = connect(
  mapStateToProps,
  null
)(AssignTestModal);

export default AssignTestModalContainer;
