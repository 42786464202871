import { getFacilityKey } from "#batteries-included-components/Panels/TablePanels/CalculationsTablePanel/CalculationsTablePanel.helper";
import { CALCULATIONS_FILTER_CONFIG } from "#src/batteries-included-components/FilterAreas/CalculationsFilterAreas";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { DEFAULT_DATE_RANGES } from "#src/hooks/useDateRange";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import { getIntervalInMonthsFormatted } from "#utils/date";
import { useQuery } from "@tanstack/react-query";
import {
  Button,
  Drawer,
  MetricTileGrid,
  MetricTileProps,
  NumberDataDisplay,
  Panel,
  StorageKeys,
} from "@validereinc/common-components";
import {
  AssetTypeType,
  CalculatorResultsDomain,
  MeasurementTypeType,
} from "@validereinc/domain";
import { toFlattenedObject, type Unpacked } from "@validereinc/utilities";
import React, { useMemo, useState } from "react";

export const CalculationsSummaryPanel = ({
  assetType,
  filterConfigStorageKey,
  viewConfigStorageKey,
  defaultMeasurementTypes = [],
  measurementTypeFilter = () => true,
}: {
  assetType: AssetTypeType;
  defaultMeasurementTypes?: string[];
  measurementTypeFilter: (measurementType: MeasurementTypeType) => boolean;
} & Pick<StorageKeys, "filterConfigStorageKey" | "viewConfigStorageKey">) => {
  const {
    getTypeName,
    getUnitName,
    getUnitByType,
    getPrecisionByType,
    measurementTypes,
  } = useMeasurementTypes();
  const [viewFilters] = useSessionStickyState({}, viewConfigStorageKey);
  const [tableFilters] = useSessionStickyState({}, filterConfigStorageKey);
  const filters = useMemo(
    () => ({ ...tableFilters, ...viewFilters }) as Record<string, any>,
    [viewFilters, tableFilters]
  );

  // IMPROVE: this component / page's filtering logic is a prime example of why we need a better logical filtering architecture
  const {
    [CALCULATIONS_FILTER_CONFIG.facilityName.name]: facilityName,
    [CALCULATIONS_FILTER_CONFIG.equipmentName.name]: equipmentName,
    [CALCULATIONS_FILTER_CONFIG.equipmentFacilityStatus.name]:
      _equipmentFacilityStatus,
    [CALCULATIONS_FILTER_CONFIG.flowFacilityStatus.name]: _flowFacilityStatus,
    [CALCULATIONS_FILTER_CONFIG.equipmentStatus.name]: _equipmentStatus,
    [CALCULATIONS_FILTER_CONFIG.flowEquipmentStatus.name]: _flowEquipmentStatus,
  } = toFlattenedObject(filters);
  const {
    [CALCULATIONS_FILTER_CONFIG.dateRange.name]: dateRange,
    [CALCULATIONS_FILTER_CONFIG.facility.name]: facility,
    [CALCULATIONS_FILTER_CONFIG.reportingScenario.name]: reportingGroup,
    [CALCULATIONS_FILTER_CONFIG.calculatorName.name]: calculatorName,
    [CALCULATIONS_FILTER_CONFIG.status.name]: _status, // TODO: Can't filter results on status
    [CALCULATIONS_FILTER_CONFIG.estimationMethodStatus.name]: _estMethodStatus,
    id,
    ...restFilters
  } = filters;

  /** We shouldn't need to apply defaults here, but sometimes a race condition overwrites the default date */
  const year_month = getIntervalInMonthsFormatted(
    !!dateRange?.from && !!dateRange?.to
      ? {
          from: new Date(dateRange?.from),
          to: new Date(dateRange?.to),
        }
      : DEFAULT_DATE_RANGES.currentWholeMonth
  );

  const calculationSummaryParams = useMemo(
    () => ({
      page: 1,
      pageSize: 1,
      filters: {
        ...toFlattenedObject(restFilters),
        ["estimation_method.id"]: id,
        ...(facility ? { [getFacilityKey(assetType)]: facility } : {}),
        ["equipment.facility.name"]: facilityName,
        ["equipment.name"]: equipmentName,
        ["estimation_method.analytics_calculator_id"]: calculatorName,
        entity_type: { $exact: assetType },
        reporting_group: { $exact: reportingGroup },
        year_month,
      },
    }),
    [
      restFilters,
      id,
      facility,
      facilityName,
      equipmentName,
      calculatorName,
      assetType,
      reportingGroup,
      year_month,
    ]
  );

  const [isDetailDrawerOpen, setIsDetailDrawerOpen] = useState(false);
  const onCloseDetailDrawer = () => setIsDetailDrawerOpen(false);
  const onOpenDetailDrawer = () => setIsDetailDrawerOpen(true);

  const { data, isLoading } = useQuery({
    queryKey: ["calculatorResults", calculationSummaryParams],
    queryFn: () => {
      return CalculatorResultsDomain.getList(calculationSummaryParams);
    },
    select: ({ data }) => data[0]?.measurement,
    enabled: !!reportingGroup,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const defaultMeasurementKeysMap = useMemo(
    () =>
      defaultMeasurementTypes.reduce<Record<string, string>>((acc, key) => {
        acc[key] = key;
        return acc;
      }, {}),
    [defaultMeasurementTypes]
  );
  const measurementKeys = useMemo(
    () => [
      ...defaultMeasurementTypes,
      ...Object.keys(data ?? {}).filter((key) => {
        const measurementType = measurementTypes.find(({ id }) => id === key);
        return (
          measurementType &&
          measurementTypeFilter(measurementType) &&
          !defaultMeasurementKeysMap[key]
        );
      }),
    ],
    [
      data,
      defaultMeasurementTypes,
      measurementTypes,
      measurementTypeFilter,
      defaultMeasurementKeysMap,
    ]
  );
  const summaryData = useMemo(() => {
    return measurementKeys.map<Unpacked<MetricTileProps[]>>((key) => ({
      key,
      title: `Total ${getTypeName(key)}`,
      value: (props) => {
        const precision = getPrecisionByType(key);

        return (
          <NumberDataDisplay
            {...props}
            value={data?.[key]}
            formattingOpts={
              precision
                ? { fractionDigits: precision }
                : {
                    overrideOpts: {
                      maximumFractionDigits: 3,
                      minimumFractionDigits: 2,
                    },
                  }
            }
          />
        );
      },
      unit: getUnitName(getUnitByType(key)),
    }));
  }, [data, measurementKeys]);
  return (
    <Panel
      title="Summary"
      actionRow={
        measurementKeys.length > defaultMeasurementTypes.length && (
          <Button
            key="view-full-summary-action"
            onClick={onOpenDetailDrawer}
            variant="outline"
          >
            View Full Summary
          </Button>
        )
      }
    >
      <MetricTileGrid
        data={summaryData.filter(
          ({ key }) =>
            typeof key !== "undefined" && defaultMeasurementKeysMap[key]
        )}
        isLoading={isLoading}
      />
      {measurementKeys.length > defaultMeasurementTypes.length && (
        <Drawer
          isOpen={isDetailDrawerOpen}
          onClose={onCloseDetailDrawer}
          size="md"
          title="Full Summary"
        >
          <MetricTileGrid
            data={summaryData}
            isLoading={isLoading}
          />
        </Drawer>
      )}
    </Panel>
  );
};
