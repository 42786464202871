import { TemplatedReportsTemplateDetailRoutePath } from "#src/routes/reports/templated-reports/template/[templateName]";
import { RoutePath } from "#src/utils/route";

/** @deprecated */
export const linkToExportTemplatedReport = (reportName = ":reportName") =>
  `/app/templated-reports/${reportName}/export`;

/** @deprecated */
export type TemplatedReportExportParamType = {
  reportName: string;
};

export const TemplatedReportsExportRoutePath =
  TemplatedReportsTemplateDetailRoutePath.concat(
    new RoutePath({
      path: "/export",
      title: "Templated Report Export",
    })
  );

export * from "./TemplatedReportExportPage";
