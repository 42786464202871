import { dataPlatformRequestServicesPromise } from "#services/ServiceHelper";
import {
  getSafeFileMimeType,
  getSafeFileName,
} from "@validereinc/domain-controllers/logic/files";
import axios from "axios";

const FileService = {
  createFile({
    name,
    contentType,
    datasetId,
    companyId,
    signal,
    ...rest
  }: {
    name: string;
    contentType: string;
    datasetId?: string;
    companyId?: string;
    signal?: AbortSignal | null;
    [key: string]: any;
  }) {
    if (!name) {
      return;
    }
    return dataPlatformRequestServicesPromise(
      `/data/file_upload_url`,
      {
        // NOTE: the file upload service needs the file name to only contain one dot for extension validation
        file_name: getSafeFileName(name),
        content_type: getSafeFileMimeType(name) ?? contentType,
        ...(datasetId ? { dataset_id: datasetId } : {}),
        ...(companyId ? { company_id: companyId } : {}),
        ...rest,
      },
      { ...(signal ? { signal } : {}) }
    );
  },
  uploadFile({
    url,
    fileBlob,
    signal,
  }: {
    url: string;
    fileBlob: File;
    signal?: AbortSignal | null;
  }) {
    if (!url || !fileBlob) {
      return;
    }
    // if the MIME type is deemed to be invalid, don't upload the file
    const overrideContentType = getSafeFileMimeType(
      fileBlob.name,
      fileBlob.type
    );

    if (!overrideContentType) {
      return;
    }

    return axios.put(url, fileBlob, {
      withCredentials: false,
      headers: {
        "Content-Type": overrideContentType,
      },
      ...(signal ? { signal } : {}),
    });
  },
  getFileDownloadUrl(fileId: string, signal?: AbortSignal | null) {
    if (!fileId) {
      return;
    }

    return dataPlatformRequestServicesPromise(
      `/data/file_download_url/${fileId}`,
      {},
      { ...(signal ? { signal } : {}) }
    );
  },
};

export default FileService;
