import { restAPI } from ".";
import {
  RoleCreateType,
  RolePermissionType,
  RoleType,
  RoleUserMembershipType,
  UserPermissionType,
  type ReportWithDownloadV2Type,
} from "../schemas";
import {
  CreateOneRequestType,
  CreateOneResponseType,
  DeleteOneRequestType,
  FilterObjectType,
  GetListRequestType,
  GetListResponseType,
  GetOneRequestType,
  ResourceServiceType,
  UpdateOneRequestType,
  getFilterObject,
} from "../util";

export const RolesAdapter: RolesResourceServiceType = {
  /**
   * Search roles
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/roles/search_roles
   * @returns the list of roles that satisfy the filters
   */
  getList: ({ page, pageSize, sortBy, sortDirection, filters }) =>
    restAPI.nodeAPI.POST<GetListResponseType<RoleType>>({
      endpoint: "/roles/search",
      body: {
        page,
        page_size: pageSize,
        sort_by: sortBy,
        sort_direction: sortDirection,
        filter: getFilterObject(filters),
      },
    }),
  getMany: () =>
    Promise.reject({
      success: false,
      error: "Method not implemented",
    }),
  /**
   * Get a single role
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/roles/get_role
   */
  getOne: async ({ id }: { id: string }) => {
    const resp = await restAPI.nodeAPI.GET<RoleType>({
      endpoint: `/roles/${id}`,
    });

    return {
      data: resp,
    };
  },
  /**
   * Create a role
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/roles/create_role
   */
  createOne: async ({ data }) => {
    const resp = await restAPI.nodeAPI.POST<RoleType>({
      endpoint: "/roles",
      body: data,
    });

    return {
      data: resp,
    };
  },
  createMany: () =>
    Promise.reject({
      success: false,
      error: "Method not implemented",
    }),
  /**
   * Update a role
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/roles/update_role
   */
  updateOne: async ({ id, data }) => {
    const resp = await restAPI.nodeAPI.PUT<RoleType>({
      endpoint: `/roles/${id}`,
      body: data,
    });

    return {
      data: resp,
    };
  },
  updateMany: () =>
    Promise.reject({
      success: false,
      error: "Method not implemented",
    }),
  /**
   * Delete a single role
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/roles/delete_role
   */
  deleteOne: ({ id }: { id: string }) => {
    return restAPI.nodeAPI.DELETE({
      endpoint: `/roles/${id}`,
    });
  },
  /**
   * Export roles list
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/roles/export_roles
   */
  exportList: ({
    filters,
    sortBy,
    sortDirection,
  }: GetListRequestType & { period?: string }) => {
    return restAPI.nodeAPI.POST<ReportWithDownloadV2Type>({
      endpoint: "/roles/export",
      body: {
        sort_by: sortBy,
        sort_direction: sortDirection,
        filter: getFilterObject(filters),
      },
    });
  },
  deleteMany: () =>
    Promise.reject({
      success: false,
      error: "Method not implemented",
    }),
  permissions: {
    /**
     * get permissions associated to role
     * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/roles/get_role_permission
     */
    getMany: ({ id }) =>
      restAPI.nodeAPI.GET<RolePermissionType[]>({
        endpoint: `/roles/${id}/permissions`,
      }),
    /**
     * update all permissions or a single permission associated to role
     * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/roles/update_role_permission
     */
    update: ({ id, data, meta }) =>
      restAPI.nodeAPI.PUT<RolePermissionType>({
        endpoint: `/roles/${id}/permissions${
          meta?.permissionName ? `/${meta.permissionName}` : ""
        }`,
        body: data,
      }),
    /**
     * delete all permissions or a single permission associated to role
     * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/roles/delete_role_permission
     */
    delete: ({ id, meta }) =>
      restAPI.nodeAPI.DELETE({
        endpoint: `/roles/${id}/permissions${
          meta?.permissionName ? `/${meta.permissionName}` : ""
        }`,
      }),
  },
  members: {
    /**
     * get all users assigned to a role
     * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/roles/list_role_members
     */
    getMany: ({ roleId }) =>
      restAPI.nodeAPI.GET({
        endpoint: `/roles/${roleId}/members`,
      }),
    /**
     * add a user to a role
     * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/roles/add_member
     */
    update: ({ roleId, userId }) =>
      restAPI.nodeAPI.PUT({
        endpoint: `/roles/${roleId}/members/${userId}`,
      }),
    /**
     * remove a user from a role
     * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/roles/remove_member
     */
    delete: ({ roleId, userId }) =>
      restAPI.nodeAPI.DELETE({
        endpoint: `/roles/${roleId}/members/${userId}`,
      }),
  },
};

export interface RolesResourceServiceType
  extends ResourceServiceType<RoleType> {
  getList: (
    props: GetListRequestType<FilterObjectType<RoleType>>
  ) => Promise<GetListResponseType<RoleType>>;
  createOne: (
    props: CreateOneRequestType<RoleCreateType>
  ) => Promise<CreateOneResponseType<RoleType>>;
  permissions: {
    getMany: ({ id }: GetOneRequestType) => Promise<RolePermissionType[]>;
    update: ({
      id,
    }: UpdateOneRequestType<
      UserPermissionType[],
      { permissionName: string }
    >) => Promise<RolePermissionType>;
    delete: ({
      id,
    }: DeleteOneRequestType<
      RolePermissionType,
      { permissionName: string }
    >) => Promise<void>;
  };
  members: {
    getMany: ({
      roleId,
    }: {
      roleId: string;
    }) => Promise<RoleUserMembershipType[]>;
    update: ({
      roleId,
      userId,
    }: {
      roleId: string;
      userId: string;
    }) => Promise<void>;
    delete: ({
      roleId,
      userId,
    }: {
      roleId: string;
      userId: string;
    }) => Promise<void>;
  };
}
