import { useListCalculators } from "#hooks/adapters/useCalculators";
import { useListEquipmentTypes } from "#hooks/adapters/useEquipment";
import {
  useExportEstimationMethods,
  useListEstimationMethods,
} from "#hooks/adapters/useEstimationMethods";
import { useListFlowTypes } from "#hooks/adapters/useFlows";
import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import { linkToFacilityDetail } from "#routes/organization/facilities/[facilityId]";
import { ImportDataAction } from "#src/batteries-included-components";
import PermissionAwareExportButton from "#src/batteries-included-components/Buttons/PermissionAwareExportButton/PermissionAwareExportButton";
import {
  EstimationMethodDetailFilterConfigFilterType,
  EstimationMethodDetailViewConfigFilterType,
  EstimationMethodsTableFilterArea,
  EstimationMethodTableTitleDecorationFilterArea,
} from "#src/batteries-included-components/FilterAreas/EstimationMethodsFilterAreas/EstimationMethodsFilterAreas";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import useLocalization from "#src/hooks/useLocalization";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import {
  linkToAssetDetailPage,
  linkToEstimationMethodDetailPage,
} from "#utils/links";
import {
  ArrayDataDisplay,
  DataTable,
  DataTablePanel,
  HeaderType,
  Pill,
  StorageKeys,
} from "@validereinc/common-components";
import {
  AssetType,
  AssetTypeType,
  EstimationMethodStatus,
  EstimationMethodType,
  ResourceDefinitions,
  SortDirection,
} from "@validereinc/domain";
import {
  toFlattenedObject,
  toStartCaseString,
  yearMonthFromISO,
} from "@validereinc/utilities";
import React from "react";

export const EstimationMethodListTablePanel = ({
  filterConfigStorageKey,
  tableConfigStorageKey,
  viewConfigStorageKey,
  assetType,
}: StorageKeys & {
  assetType: AssetTypeType;
}) => {
  const isFilteredByEntity = !!assetType;
  const { localize } = useLocalization();
  const [isDataIngestionEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:data_pipeline",
  });
  const equipmentTypes = useListEquipmentTypes({}).data?.data ?? [];
  const flowTypes = useListFlowTypes()?.data ?? [];
  const calculators = useListCalculators().data?.calculators ?? [];
  const [filters] =
    useSessionStickyState<EstimationMethodDetailFilterConfigFilterType>(
      {},
      filterConfigStorageKey
    );
  const [{ month }] =
    useSessionStickyState<EstimationMethodDetailViewConfigFilterType>(
      {},
      viewConfigStorageKey
    );
  const [tableState, updateTableState] = useTableSortingAndPagination(
    {
      sortBy: "name",
      sortDirection: SortDirection.ASCENDING,
    },
    filters
  );

  const estimationMethodParams = {
    page: tableState.page,
    pageSize: tableState.pageSize,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: {
      ...toFlattenedObject(filters),
      ...(isFilteredByEntity
        ? { entity_type: { $exact: assetType } }
        : { entity_type: [AssetType.EQUIPMENT, AssetType.FLOW] }),
      ...(filters.status
        ? {
            status: filters.status,
          }
        : {}),
    },
    ...(month?.from ? { period: yearMonthFromISO(month.from) } : {}),
  };

  const { data, isLoading } = useListEstimationMethods(estimationMethodParams);
  const { mutate: exportEstimationMethods, isLoading: isExporting } =
    useExportEstimationMethods(estimationMethodParams);

  const headers: Array<HeaderType<EstimationMethodType>> = [
    {
      label: "Estimation Method",
      key: "name",
      isSortable: true,
      renderComponent: ({ item }) => (
        <RoutingLink
          to={linkToEstimationMethodDetailPage(
            item.entity_type,
            item.entity_id,
            item.id
          )}
        >
          {item.name}
        </RoutingLink>
      ),
    },
    {
      label: "Status",
      key: "status",
      isSortable: true,
      renderComponent: ({ item }: { item: EstimationMethodType }) => {
        return (
          <Pill
            variant={
              item.status === EstimationMethodStatus.ACTIVE
                ? "success"
                : "default"
            }
          >
            {item.status}
          </Pill>
        );
      },
    },
    {
      label: "Reporting Scenario",
      key: "reporting_groups",
      renderComponent: ({ item: { reporting_groups: reportingGroups } }) => (
        <ArrayDataDisplay value={reportingGroups?.map(({ name }) => name)} />
      ),
    },
    {
      label: isFilteredByEntity ? "Asset" : toStartCaseString(assetType),
      key: "equipment.name",
      renderComponent: ({ item }) => {
        const equipmentLink = linkToAssetDetailPage(
          item.entity_type,
          item.entity_id
        );
        const equipmentName = item.equipment?.name ?? item.flow?.name;

        return equipmentLink && equipmentName ? (
          <RoutingLink to={equipmentLink}>{equipmentName}</RoutingLink>
        ) : (
          "-"
        );
      },
    },
    ...(isFilteredByEntity
      ? [
          {
            label: "Asset Type",
            key: "entity_type",
            isSortable: true,
            renderComponent: ({ item }) => localize(item?.entity_type) ?? "-",
          },
        ]
      : []),
    {
      label: isFilteredByEntity
        ? "Asset Subtype"
        : `${toStartCaseString(assetType)} Type`,
      key: "equipment.type_id",
      renderComponent: ({ item }) => {
        const getText = () => {
          switch (item.entity_type) {
            case AssetType.EQUIPMENT:
              return equipmentTypes.find(
                ({ id }) => id === item.equipment?.type_id
              )?.name;
            case AssetType.FLOW:
              return flowTypes.find(({ id }) => id === item.flow?.type)?.name;
            default:
              return toStartCaseString(
                item?.equipment?.type_id ?? item.flow?.type
              );
          }
        };

        return <DataTable.DataRow.TextCell value={getText()} />;
      },
    },
    {
      label: "Facility",
      key: "equipment.facility.name",
      renderComponent: ({ item }) => {
        const facilityId =
          item.flow?.associated_facility_id ?? item.equipment?.facility_id;
        const facilityName =
          item.flow?.associated_facility?.name ??
          item.equipment?.facility?.name;

        return facilityId && facilityName ? (
          <RoutingLink to={linkToFacilityDetail(facilityId)}>
            {facilityName}
          </RoutingLink>
        ) : (
          "-"
        );
      },
    },
    {
      label: "Calculation",
      key: "analytics_calculator_id",
      isSortable: true,
      renderComponent: ({ item }) => {
        const calculator = calculators.find(
          ({ id }) => id === item.analytics_calculator_id
        );

        return (
          <DataTable.DataRow.TextCell
            value={
              calculator?.versions.find(
                ({ version }) => version === calculator?.default_version
              )?.title ?? "-"
            }
          />
        );
      },
    },
  ];
  const actionRow = [
    <PermissionAwareExportButton
      key="export-estimation-methods"
      onClick={exportEstimationMethods}
      isExporting={isExporting}
    />,
    isDataIngestionEnabled ? (
      <ImportDataAction
        key="import-estimation-methods"
        resource={ResourceDefinitions.estimation_method}
      />
    ) : null,
  ];

  return (
    <DataTablePanel
      storageKey={tableConfigStorageKey}
      panelProps={{
        actionRow,
        title: `${
          isFilteredByEntity ? "All" : toStartCaseString(assetType)
        } ${ResourceDefinitions.estimation_method.label.plural}`,
        titleDecorator: (
          <EstimationMethodTableTitleDecorationFilterArea
            filterConfigStorageKey={filterConfigStorageKey}
          />
        ),
      }}
      dataTableProps={{
        variant: "simplicity-first",
        headers,
        items: data?.data ?? [],
        isLoading,
        isFluid: false,
        onSortChange: updateTableState,
        onPaginationChange: updateTableState,
        sorting: {
          sortBy: tableState.sortBy,
          sortDirection: tableState.sortDirection,
        },
        pagination: {
          page: tableState.page,
          total: data?.total_entries,
          pageSize: tableState.pageSize,
        },
      }}
      filterComponent={
        <EstimationMethodsTableFilterArea
          filterConfigStorageKey={filterConfigStorageKey}
          assetType={assetType}
        />
      }
    />
  );
};
