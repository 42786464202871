import TextBoxInput from "#common/Form/TextBoxInput";
import {
  AlertMessage,
  Button,
  Modal,
  Title,
  useToast,
} from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Form from "../../Common/Form/Form";
import SelectInput from "../../Common/Form/SelectInput";
import OrderedList from "../../Common/List/OrderedList";
import RecordOfVolumeService from "../../Services/RecordOfVolumeService";
import "./RecordOfVolume.scss";
import { getErrorMessage } from "./RecordOfVolumeHelper";

const TITLE_COLUMN = 3;

const DEFAULT_FORM_INPUT = {
  streams: [],
  adjustmentFactors: [],
};

export const EditStreamAdjustmentSettingModal = (props) => {
  const { show, adjustmentFactors, streams, streamAdjustmentConfigs } = props;
  const [alertMessage, setAlertMessage] = useState(null);
  const [formState, setFormState] = useState("enabled");
  const [input, setInput] = useState({ ...DEFAULT_FORM_INPUT });
  const { toast } = useToast();

  const onInputChange = (key, value) => {
    setInput((input) => ({ ...input, [key]: value }));
  };

  const onModalClose = () => {
    props.onHide();
    setInput({ ...DEFAULT_FORM_INPUT });
    setFormState("enabled");
  };

  const onUpdateStreamClick = () => {
    setAlertMessage(null);

    const errorMessage = getErrorMessage(input);

    if (!errorMessage) {
      updateStreamAdjustmentFactors();
    } else {
      setAlertMessage(errorMessage);
    }
  };

  const updateStreamAdjustmentFactors = () => {
    setFormState("disabled");
    const streamIds = input.streams.map((stream) => stream.id);
    const adjustmentFactorIds = input.adjustmentFactors.map(
      (factor) => factor.id
    );
    const upperLimit = input.totalVolumeUpperLimit ?? null;

    RecordOfVolumeService.updateStreamAdjustmentFactors(
      streamIds,
      adjustmentFactorIds,
      upperLimit
    )
      .then(() => {
        toast.push({
          intent: "success",
          description: "Streams have been successfully updated",
        });

        setFormState("enabled");
        props.updateStreams();
      })
      .catch(() => {
        setFormState("enabled");
      });
  };

  useEffect(() => {
    if (props.checkedStreams.length > 0 && formState === "enabled") {
      const checkedStreams = props.checkedStreams;
      const newInput = { ...DEFAULT_FORM_INPUT };

      newInput.streams = checkedStreams;

      if (checkedStreams.length === 1) {
        const selectedStreamConfig = streamAdjustmentConfigs.find(
          (config) => config.stream_id == checkedStreams[0].id
        );
        const selectedAdjustmentFactorIds =
          selectedStreamConfig?.adjustment_factor_ids ?? [];

        newInput.adjustmentFactors = selectedAdjustmentFactorIds.map(
          (selectedId) =>
            adjustmentFactors.find(
              (adjustmentFactor) => adjustmentFactor.id === selectedId
            )
        );

        newInput.totalVolumeUpperLimit =
          selectedStreamConfig?.upper_limit ?? null;
      }

      setInput(newInput);
    }
  }, []);

  const sharedProps = {
    titleCol: TITLE_COLUMN,
    pullLeft: true,
    isDisabled: formState !== "enabled",
    disabled: formState !== "enabled",
  };

  return (
    <Modal
      open={show}
      onClose={onModalClose}
    >
      <Modal.Header>
        <Title>Update Stream Adjustment Factors</Title>
      </Modal.Header>

      <Modal.Body>
        {alertMessage && (
          <AlertMessage
            type={"warning"}
            style={{ marginBottom: "15px" }}
          >
            <b>{alertMessage}</b>
          </AlertMessage>
        )}

        <Form>
          <SelectInput
            title="Streams"
            labelKey="name"
            value={input.streams}
            options={streams}
            onChange={(value) => onInputChange("streams", value)}
            ignoreCase
            isMulti
            {...sharedProps}
          />

          <SelectInput
            title="Adjustment Factors"
            labelKey="name"
            value={input.adjustmentFactors}
            options={adjustmentFactors}
            onChange={(value) => onInputChange("adjustmentFactors", value)}
            ignoreCase
            isMulti
            {...sharedProps}
          />

          <Title
            type="subheader"
            className="form__header"
          >
            Total Volume
          </Title>

          <TextBoxInput
            title="Adjustment Upper Limit"
            type="number"
            onChange={(event) =>
              onInputChange("totalVolumeUpperLimit", event.target.value)
            }
            value={input.totalVolumeUpperLimit ?? ""}
            {...sharedProps}
          />

          {input.adjustmentFactors?.length > 1 && (
            <div>
              <div className="form__header">Adjustment Order</div>

              <div className="volumeEntryModal__adjustmentOrderHint">
                Drag and drop to reorder the priorities
              </div>

              <OrderedList
                list={input.adjustmentFactors ?? []}
                onChange={(value) => onInputChange("adjustmentFactors", value)}
                labelKey="name"
                rowHeight={60}
                maxVisibleRows={5}
                disabled={formState !== "enabled"}
              />
            </div>
          )}
        </Form>
      </Modal.Body>

      <Modal.Footer className="clearfix">
        <Button
          className="pull-left"
          onClick={onModalClose}
        >
          Close
        </Button>
        {formState === "enabled" && (
          <Button
            variant="primary"
            onClick={onUpdateStreamClick}
            disabled={formState !== "enabled"}
          >
            Update Streams
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

EditStreamAdjustmentSettingModal.propTypes = {
  show: PropTypes.bool.isRequired,
  adjustmentFactors: PropTypes.array.isRequired,
  streams: PropTypes.array.isRequired,
  checkedStreams: PropTypes.array.isRequired,
  addAlertMessage: PropTypes.func.isRequired,
  updateStreams: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  streamAdjustmentConfigs: PropTypes.array.isRequired,
};

export default EditStreamAdjustmentSettingModal;
