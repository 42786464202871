import { z } from "zod";
import { AssetType } from "./AssetSchemas";
import { UploadedFileSchema } from "./FileSchemas";

export const ResourceLookup = {
  ...AssetType,
  FORM_SUBMISSION: "form_submission",
} as const;

export const AttributeDataType = {
  NUMBER: "number",
  STRING: "string",
  GEO_POINT: "geo_point",
  BOOLEAN: "boolean",
  INTEGER: "integer",
  PICK_LIST: "pick-list",
  MULTI_PICK_LIST: "multi-pick-list",
  NUMBER_ARRAY: "number-array",
  LOOKUP: "lookup",
  DATE: "date",
  DATE_TIME: "date-time",
  DATE_TIME_RANGE: "date-time-range",
  FILE: "file",
} as const;

// REVIEW: verify if this is correct as this list is not verifiable through Swagger
export const AttributeId = {
  emissions_recovered: "emissions_recovered",
  compressibility: "compressibility",
  device_type: "device_type",
  vessel_height: "vessel_height",
  vessel_inside_diameter: "vessel_inside_diameter",
  gas_composition: "gas_composition",
  recovery_fraction: "recovery_fraction",
  unlit_feed_gas: "unlit_feed_gas",
  pressure: "pressure",
  temperature: "temperature",
  fuel_hhv: "fuel_hhv",
  fuel_unit: "fuel_unit",
  n2o_mass_emission_factor: "n2o_mass_emission_factor",
  flare_combustion_efficiency: "flare_combustion_efficiency",
  device_bleed_type: "device_bleed_type",
  device_count: "device_count",
  pneumatic_device_efs: "pneumatic_device_efs",
} as const;

export const AttributeBaseSchema = z.object({
  id: z.string(),
  data_type: z.nativeEnum(AttributeDataType),
  name: z.string(),
  description: z.string(),
  is_required: z.boolean(),
  units: z.string().optional(), // REVIEW: "units" here but "unit" in custom attributes. talk to BE about this mismatch.
  substitutions: z.string().optional(),
});

export const AttributeStringSchema = z
  .object({
    data_type: z.literal(AttributeDataType.STRING),
  })
  .merge(AttributeBaseSchema.omit({ data_type: true }));

export const AttributeNumberSchema = z
  .object({
    data_type: z.literal(AttributeDataType.NUMBER),
    minimum: z.number().optional(),
    maximum: z.number().optional(),
  })
  .merge(AttributeBaseSchema.omit({ data_type: true }));

export const AttributeIntegerSchema = z
  .object({
    data_type: z.literal(AttributeDataType.INTEGER),
    minimum: z.number().optional(),
    maximum: z.number().optional(),
  })
  .merge(AttributeBaseSchema.omit({ data_type: true }));

export const AttributeNumberArraySchema = z
  .object({
    data_type: z.literal(AttributeDataType.NUMBER_ARRAY),
  })
  .merge(AttributeBaseSchema.omit({ data_type: true }));

export const AttributeBooleanSchema = z
  .object({
    data_type: z.literal(AttributeDataType.BOOLEAN),
  })
  .merge(AttributeBaseSchema.omit({ data_type: true }));

export const AttributeGeoPointSchema = z
  .object({
    data_type: z.literal(AttributeDataType.GEO_POINT),
  })
  .merge(AttributeBaseSchema.omit({ data_type: true }));

export const AttributeDateSchema = z
  .object({
    data_type: z.literal(AttributeDataType.DATE),
    date_resolution: z
      .enum(["year", "year_month", "year_month_day"])
      .default("year_month_day"),
  })
  .merge(AttributeBaseSchema.omit({ data_type: true }));

export const AttributeDateTimeSchema = z
  .object({
    data_type: z.literal(AttributeDataType.DATE_TIME),
  })
  .merge(AttributeBaseSchema.omit({ data_type: true }));

export const AttributeDateTimeRangeSchema = z
  .object({
    data_type: z.literal(AttributeDataType.DATE_TIME_RANGE),
  })
  .merge(AttributeBaseSchema.omit({ data_type: true }));

export const AttributeFileSchema = z
  .object({
    data_type: z.literal(AttributeDataType.FILE),
  })
  .merge(AttributeBaseSchema.omit({ data_type: true }));

export const AttributePicklistSchema = z
  .object({
    data_type: z.literal(AttributeDataType.PICK_LIST),
    pick_list_values: z.array(z.string()).optional(),
  })
  .merge(AttributeBaseSchema.omit({ data_type: true }));

export const AttributeMultiPicklistSchema = z
  .object({
    data_type: z.literal(AttributeDataType.MULTI_PICK_LIST),
    pick_list_values: z.array(z.string()).optional(),
  })
  .merge(AttributeBaseSchema.omit({ data_type: true }));

export const AttributeLookupSchema = z
  .object({
    data_type: z.literal(AttributeDataType.LOOKUP),
    lookup_entity_type: z.nativeEnum(ResourceLookup).optional(),
  })
  .merge(AttributeBaseSchema.omit({ data_type: true }));

export const AttributeStrictSchema = z.discriminatedUnion("data_type", [
  AttributeStringSchema,
  AttributeNumberSchema,
  AttributeIntegerSchema,
  AttributeNumberArraySchema,
  AttributeBooleanSchema,
  AttributeGeoPointSchema,
  AttributeDateSchema,
  AttributeDateTimeSchema,
  AttributeDateTimeRangeSchema,
  AttributeFileSchema,
  AttributePicklistSchema,
  AttributeMultiPicklistSchema,
  AttributeLookupSchema,
]);

// IMPROVE: rely on discriminated union on "data_type" above when Zod supports
// nested discriminated unions better
export const AttributeSchema = AttributeBaseSchema.merge(
  z.object({
    pick_list_values: AttributePicklistSchema.shape.pick_list_values,
    minimum: AttributeNumberSchema.shape.minimum,
    maximum: AttributeNumberSchema.shape.maximum,
    lookup_entity_type: AttributeLookupSchema.shape.lookup_entity_type,
    date_resolution: AttributeDateSchema.shape.date_resolution.optional(),
  })
);

export const IntegerSchema = z.number().int();
export const GeocoordinateSchema = z.tuple([
  z.number().nullable(),
  z.number().nullable(),
]);
export const DateYearMonthSchema = z.string().regex(/^(\d{4})(\d{2})$/g);
export const DateSchema = z.string().date();
export const DateTimeSchema = z.string().datetime();
export const DateTimeRangeSchema = z.tuple([DateTimeSchema, DateTimeSchema]);
export const LookupSchema = z.string().uuid();
export const LookupWithDetailsSchema = z
  .object({
    entity_type: z.nativeEnum(ResourceLookup),
    name: z.string(),
    value: LookupSchema,
  })
  .describe(
    "The lookup value for specific endpoints of the Node API, like Form Submission Answers"
  );
export const LookupWithSingleAttributeRefSchema = z
  .object({
    id: LookupSchema,
    value: z
      .any()
      .describe("The value of the specified attribute on the looked up entity"),
  })
  .describe(
    "The lookup value for specific endpoints of the Node API, like Reports"
  );

export const AttributeValueSchema = z.union([
  z.string(),
  z.number(),
  IntegerSchema,
  z.boolean(),
  z.array(z.string()),
  z.array(z.number()),
  DateSchema,
  DateTimeSchema,
  DateTimeRangeSchema,
  LookupSchema,
  GeocoordinateSchema,
  UploadedFileSchema,
]);
export type AttributeValueType = z.infer<typeof AttributeValueSchema>;

export type ResourceLookupType =
  (typeof ResourceLookup)[keyof typeof ResourceLookup];
export type AttributeDataTypeType =
  (typeof AttributeDataType)[keyof typeof AttributeDataType];
export type AttributeType = z.infer<typeof AttributeSchema>;
export type AttributeIdType = (typeof AttributeId)[keyof typeof AttributeId];
export type GeocoordinateType = z.infer<typeof GeocoordinateSchema>;
export type DateTimeRangeType = z.infer<typeof DateTimeRangeSchema>;
export type LookupType = z.infer<typeof LookupSchema>;
export type LookupWithDetailsType = z.infer<typeof LookupWithDetailsSchema>;
export type LookupWithSingleAttributeRefType = z.infer<
  typeof LookupWithSingleAttributeRefSchema
>;

export const AttributeSchemaHelpers = {
  getDataTypeForDisplay: (value: AttributeType["data_type"]) => {
    switch (value) {
      case "string":
        return "Plain Text";
      case "boolean":
        return "Yes / No or True / False";
      case "date":
        return "Date";
      case "date-time":
        return "Date and Time";
      case "date-time-range":
        return "Date and Time Range";
      case "file":
        return "File";
      case "number":
        return "Number (can have decimals)";
      case "number-array":
        return "Number Array";
      case "integer":
        return "Integer";
      case "lookup":
        return "Lookup";
      case "geo_point":
        return "Geo Coordinates";
      case "pick-list":
        return "Single Pick List";
      case "multi-pick-list":
        return "Multi Pick List";
    }
  },
};
