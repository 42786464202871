import { ExceptionUtils } from "#src/utils/exception";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@validereinc/common-components";
import {
  BaseError,
  CreateUserGroupCategoryType,
  CreateUserGroupType,
  UserGroupCategoriesAdapter,
  UserGroupsAdapter,
  UserType,
} from "@validereinc/domain";
import { downloadLink } from "@validereinc/utilities";
import {
  DEFAULT_INVALIDATE_OPTIONS,
  DEFAULT_QUERY_OPTIONS,
  UseMutationCallbackType,
} from "./adapterUtils";

export const USER_GROUP_QUERY_KEY = ["users", "groups"];
export const USER_GROUP_CATEGORY_QUERY_KEY = ["users", "groups", "categories"];

export const useClearUserGroupCache = () => {
  const queryClient = useQueryClient();

  const invalidateUserGroup = () => {
    queryClient.invalidateQueries({
      queryKey: [...USER_GROUP_QUERY_KEY],
      ...DEFAULT_INVALIDATE_OPTIONS,
    });
  };
  const invalidateUserGroupCategories = () => {
    queryClient.invalidateQueries({
      queryKey: [...USER_GROUP_CATEGORY_QUERY_KEY],
      ...DEFAULT_INVALIDATE_OPTIONS,
    });
  };

  return {
    invalidate: () => {
      invalidateUserGroup();
      invalidateUserGroupCategories();
    },
    invalidateUserGroup,
    invalidateUserGroupCategories,
  };
};

////////////////////////////////
//    User Group Categories   //
////////////////////////////////

export const useListUserGroupCategories = (
  params = {},
  options: { enabled?: boolean } = {}
) =>
  useQuery({
    queryKey: [...USER_GROUP_CATEGORY_QUERY_KEY, params],
    queryFn: ({ queryKey: [_, __, params] }) =>
      UserGroupCategoriesAdapter.getList(params),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  });

export const useCreateOneUserGroupCategory = ({
  onSuccess,
}: UseMutationCallbackType = {}) => {
  const { toast } = useToast();
  const { invalidateUserGroupCategories } = useClearUserGroupCache();

  return useMutation({
    mutationFn: async (params: CreateUserGroupCategoryType) =>
      await UserGroupCategoriesAdapter.createOne({ data: params }),
    onSuccess: (data, variables, context) => {
      toast.push({
        intent: "success",
        description: "Successfully created user group category.",
      });
      invalidateUserGroupCategories();
      onSuccess?.(data, variables, context);
    },
    onError: () => {
      toast.push({
        intent: "error",
        description: "Failed to create user group category.",
      });
    },
  });
};

export const useGetOneUserGroupCategory = (
  params: {
    id: string;
  },
  options: { enabled?: boolean } = {}
) =>
  useQuery({
    queryKey: [...USER_GROUP_CATEGORY_QUERY_KEY, params],
    queryFn: () => UserGroupCategoriesAdapter.getOne(params),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  });

////////////////////////////////
//         User Groups        //
////////////////////////////////

export const useListUserGroups = (
  params = {},
  options: { enabled?: boolean } = {}
) =>
  useQuery({
    queryKey: [...USER_GROUP_QUERY_KEY, params],
    queryFn: ({ queryKey: [_, __, params] }) =>
      UserGroupsAdapter.getList(params),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  });

export const useCreateOneUserGroup = ({
  onSuccess,
}: UseMutationCallbackType = {}) => {
  const { toast } = useToast();
  const { invalidateUserGroup } = useClearUserGroupCache();

  return useMutation({
    mutationFn: async (params: CreateUserGroupType) =>
      await UserGroupsAdapter.createOne({ data: params }),
    onSuccess: (data, variables, context) => {
      toast.push({
        intent: "success",
        description: "Successfully created user group.",
      });
      invalidateUserGroup();
      onSuccess?.(data, variables, context);
    },
  });
};

export const useGetOneUserGroup = (
  params: {
    id: string;
  },
  options: { enabled?: boolean } = {}
) =>
  useQuery({
    queryKey: [...USER_GROUP_QUERY_KEY, params],
    queryFn: () => UserGroupsAdapter.getOne(params),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  });

export const useAddMemberToUserGroup = ({
  onSuccess,
}: UseMutationCallbackType = {}) => {
  const { toast } = useToast();
  const { invalidateUserGroup } = useClearUserGroupCache();

  return useMutation({
    mutationFn: ({ user, groupId }: { user: UserType; groupId: string }) => {
      return UserGroupsAdapter.members.add({
        id: user.id,
        data: null,
        meta: { userGroupId: groupId },
      });
    },
    onSuccess: (data, variables, context) => {
      invalidateUserGroup();
      onSuccess?.(data, variables, context);
      toast.push({
        intent: "success",
        description: `Successfully added ${variables.user.name} to group.`,
      });
    },
    onError: () => {
      toast.push({
        intent: "error",
        description: "Failed to add user to User Group.",
      });
    },
  });
};

export const useRemoveMemberFromUserGroup = ({
  onSuccess,
}: UseMutationCallbackType = {}) => {
  const { toast } = useToast();
  const { invalidateUserGroup } = useClearUserGroupCache();

  return useMutation({
    mutationFn: ({ user, groupId }: { user: UserType; groupId: string }) => {
      return UserGroupsAdapter.members.remove({
        id: user.id,
        meta: { userGroupId: groupId },
      });
    },
    onSuccess: (data, variables, context) => {
      invalidateUserGroup();
      onSuccess?.(data, variables, context);
      toast.push({
        intent: "success",
        description: `Successfully removed ${variables.user.name} from User Group.`,
      });
    },
    onError: () => {
      toast.push({
        intent: "error",
        description: "Failed to remove user from User Group.",
      });
    },
  });
};

export const useExportUserGroups = (apiParams = {}) => {
  const { toast } = useToast();

  return useMutation({
    mutationFn: async () => {
      const report = await UserGroupsAdapter?.exportList?.(apiParams);
      if (!report?.s3_download_link) {
        throw new BaseError(
          `Could not fetch download URL for user groups export`,
          { cause: report }
        );
      }
      downloadLink(report.s3_download_link, report.name);
    },
    onError: (error) => {
      ExceptionUtils.reportException(error, "error");
      toast.push({
        intent: "error",
        description: "Failed to export user groups list.",
      });
    },
  });
};
