import { havePermission } from "#redux/reducers/permissions";
import RoleService from "#services/RoleService";
import history from "#src/Routers/history";
import { linkToCompanySettings } from "#src/Routers/links";
import {
  Button,
  Form,
  FormButton,
  Page,
  Panel,
  useForm,
  useToast,
} from "@validereinc/common-components";
import find from "lodash/find";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import RoleForm from "./RoleForm";

const DEFAULT_INPUT = {
  name: "",
  permissions: {},
};

const mapStateToProps = (state) => {
  return {
    hasWritePermissions: havePermission(state.permissions)(
      "core:user.management",
      "write"
    ),
  };
};

const EditRole = (props) => {
  const roleId = props.match.params.roleId;
  const [currentRoles, setCurrentRoles] = useState([]);
  const [roleDetail, setRoleDetail] = useState({});
  const [formState, setFormState] = useState("disabled");
  const { toast } = useToast();

  const form = useForm({ defaultValues: DEFAULT_INPUT });

  const onPermissionChange = (key, input) => {
    const permissions = form.getValues("permissions");

    permissions[key] = input;

    form.setValue("permissions", permissions);
  };

  const onUpdateRole = (input) => {
    setFormState("loading");

    RoleService.requestUpdateRole(input, roleId)
      .then(() => {
        toast.push({
          intent: "success",
          description: "Role detail successfully updated.",
        });
      })
      .finally(() => {
        setFormState("enabled");
      });
  };

  const onRemoveClick = () => {
    setFormState("loading");

    RoleService.requestRemoveRole(roleId)
      .then(() => {
        toast.push({
          intent: "success",
          description: "Role detail successfully removed.",
        });

        history.push(linkToCompanySettings("roles"));
      })
      .catch(() => {
        setFormState("enabled");
      });
  };

  useEffect(() => {
    if (roleId) {
      setFormState("loading");

      RoleService.getRoles()
        .then(({ data }) => {
          setCurrentRoles(data);

          const roleDetail = find(data, { id: roleId });
          setRoleDetail(roleDetail);

          if (roleDetail) {
            form.reset({
              name: roleDetail.name,
              permissions: roleDetail.permissions,
            });
          }

          if (roleDetail.editable) {
            setFormState("enabled");
          } else {
            setFormState("preview");
          }
        })
        .catch(() => {
          setFormState("error");
        });
    }
  }, []);

  form.watch("permissions");

  return (
    <Page title={`Edit ${form.getValues("name") || ""} Role`}>
      <Panel className="editRole">
        {formState !== "error" ? (
          <Form
            onSubmit={onUpdateRole}
            {...form}
          >
            <RoleForm
              roleDetail={roleDetail}
              form={form}
              formState={formState}
              currentRoles={currentRoles}
              onPermissionChange={onPermissionChange}
            />

            <div className="clearfix">
              <FormButton
                className="pull-right"
                variant="primary"
                type="submit"
                isLoading={formState === "loading"}
                disabled={formState !== "enabled"}
              >
                Update Role
              </FormButton>

              <Button
                variant="error-outline"
                onClick={onRemoveClick}
                isLoading={formState === "loading"}
                disabled={formState !== "enabled"}
              >
                Remove Role
              </Button>
            </div>
          </Form>
        ) : (
          <div>Role not found.</div>
        )}
      </Panel>
    </Page>
  );
};

EditRole.propTypes = {
  profile: PropTypes.object,
  match: PropTypes.object,
  disabled: PropTypes.bool,
  hasWritePermissions: PropTypes.bool,
};

const SettingsContainer = connect(mapStateToProps, null)(EditRole);

export default SettingsContainer;
