import { convertMapToOpts } from "#src/constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Dialog,
  Form,
  RadioInput,
  useForm,
  useToast,
} from "@validereinc/common-components";
import {
  WorkflowAdapter,
  WorkflowSchema,
  WorkflowStatus,
  WorkflowType,
} from "@validereinc/domain";
import startCase from "lodash/startCase";
import React from "react";

export const WorkflowDetailStatusDialog = ({
  setIsStatusModalOpen,
  isStatusModalOpen,
  workflowDetails,
}: {
  setIsStatusModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isStatusModalOpen: boolean;
  workflowDetails: WorkflowType;
}) => {
  const { id: workflowId, status } = workflowDetails;
  const form = useForm({ defaultValues: { status } });
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const { isLoading, mutate: updateStatus } = useMutation({
    mutationFn: async ({ status }: Partial<WorkflowType>) => {
      await WorkflowAdapter.updateOne({
        id: workflowId,
        data: { status },
        previousData: workflowDetails,
      });
    },
  });

  const closeDialog = () => {
    form.reset(); // Reset form on dialog close
    setIsStatusModalOpen(false);
  };

  const onFormSubmit = form.handleSubmit((values: unknown) => {
    const validatedSchema = WorkflowSchema.partial().safeParse(values);

    if (!validatedSchema.success) {
      console.error(validatedSchema.error);
      toast.push({
        intent: "error",
        description: "Failed to update status. Please try again.",
      });
      return;
    }

    updateStatus(validatedSchema.data, {
      onSuccess: () => {
        closeDialog();

        queryClient.invalidateQueries({
          queryKey: ["workflows"],
        });

        toast.push({
          intent: "success",
          description: "Successfully updated workflow status",
        });
      },
      onError: (err) => {
        console.error(err);
        toast.push({
          intent: "error",
          description: "Unable to update workflow status",
        });
      },
    });
  });

  return (
    <Dialog
      isOpen={isStatusModalOpen}
      onClose={() => setIsStatusModalOpen(false)}
      title="Change Status"
      actionRow={[
        <Button
          key="status-dialog-action"
          onClick={onFormSubmit}
          variant="primary"
          isLoading={isLoading}
        >
          Save
        </Button>,
      ]}
    >
      <Form {...form}>
        <RadioInput
          name="status"
          isRequired
          options={convertMapToOpts(WorkflowStatus, ([_, value]) =>
            startCase(value)
          )}
          valueKey="value"
          labelKey="label"
        />
      </Form>
    </Dialog>
  );
};
