import DeleteModal from "#components/Common/DeleteModal/DeleteModal";
import AlertingService from "#components/Services/AlertingService";
import MeasurementsService from "#components/Services/MeasurementsService";
import { NODE_API_MAX_PAGE_SIZE } from "#components/Services/ServiceHelper";
import useAlertConfigurations from "#components/hooks/useAlertConfigurations";
import { havePermission } from "#redux/reducers/permissions";
import { useNavigate } from "#routers/hooks";
import {
  linkToAlertConfigurationDetail,
  linkToCreateAlertConfiguration,
  linkToDeviceDetail,
  linkToFacilities,
} from "#routers/links";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import useLocalization from "#src/hooks/useLocalization";
import { getPropertyAsMap } from "#utils/objectFormatter";
import { UseQueryOptions, useQueries } from "@tanstack/react-query";
import {
  Button,
  DataTable,
  HeaderType,
  Page,
  Panel,
  Pill,
} from "@validereinc/common-components";
import { UserType, UsersAdapter } from "@validereinc/domain";
import uniq from "lodash/uniq";
import * as PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { getCorrespondingMeasurementSeries } from "./alertConfigurationListHelpers";

const mapStateToProps = (state) => ({
  isAllowedToCreate: havePermission(state.permissions)(
    "carbon:alerts",
    "write"
  ),
});

// IMPROVE: this entire component needs a re-write with proper typing, leveraging new data fetching patterns, table rendering patterns, etc. Out of scope at time of writing.
const AlertConfigurationList = ({ breadcrumbs, isAllowedToCreate }) => {
  const navigate = useNavigate();
  const { localize } = useLocalization();

  const [alertConfigs, loadingState, refetchAlertConfigs] =
    useAlertConfigurations();
  const [selectedAlertConfiguration, setSelectedAlertConfiguration] =
    useState(undefined);
  const [measurementSeries, setMeasurementSeries] = useState([]);
  const [isMeasurementSeriesLoading, setIsMeasurementSeriesLoading] =
    useState(true);

  const alertConfigsWithMeasurementSeriesData = useMemo(() => {
    return measurementSeries?.length > 0
      ? alertConfigs.map((alert) => ({
          ...alert,
          measurementSeriesData: getCorrespondingMeasurementSeries(
            alert,
            measurementSeries
          ),
        }))
      : alertConfigs;
  }, [alertConfigs, measurementSeries]);

  const measurementSeriesIds = useMemo(() => {
    const ids = [];
    alertConfigs?.forEach((config) => {
      config?.conditions.forEach((condition) => {
        if (condition.measurement_series_id) {
          ids.push(condition.measurement_series_id);
        }
      });
    });
    return uniq(ids);
  }, [alertConfigs]);

  const createdByUserIds = useMemo<string[]>(() => {
    const ids = alertConfigs?.map(({ created_by_id }) => created_by_id);
    return uniq(ids);
  }, [alertConfigs]);

  const createdByUserQueries = useQueries<
    Array<
      UseQueryOptions<
        Awaited<ReturnType<typeof UsersAdapter.getOne>> | undefined,
        unknown,
        UserType | undefined
      >
    >
  >({
    queries: createdByUserIds.map((id) => ({
      queryKey: ["users", id],
      queryFn: () => UsersAdapter.getOne({ id }),
      staleTime: 3 * 60 * 1000,
    })),
  });

  const createdByUsersMap = useMemo(() => {
    return getPropertyAsMap(createdByUserQueries, "data", "data.id") as Record<
      string,
      UserType
    >;
  }, [createdByUserQueries]);

  useEffect(() => {
    (async () => {
      if (measurementSeriesIds && measurementSeriesIds?.length > 0) {
        const response = await MeasurementsService.getMeasurementSources({
          measurementSeriesIds,
          rowPerPage: NODE_API_MAX_PAGE_SIZE,
        });
        setMeasurementSeries(response.data.data);
        setIsMeasurementSeriesLoading(false);
      }
    })();
  }, [measurementSeriesIds]);

  const headers: Array<HeaderType<any>> = [
    {
      label: "Configuration Name",
      key: "name",
      renderComponent: ({ item }) => (
        <RoutingLink to={linkToAlertConfigurationDetail(item?.id)}>
          {item.name}
        </RoutingLink>
      ),
    },
    {
      label: "Number of Conditions",
      key: "numConditions",
      renderComponent: ({ item }) => item?.conditions?.length,
    },
    {
      label: "Measurement",
      key: "measurementType",
      renderComponent: ({ item }) =>
        isMeasurementSeriesLoading ? (
          <div className="skeleton" />
        ) : (
          item?.measurementSeriesData?.measurement_type_name ??
          item?.measurementSeriesData
        ),
    },
    {
      label: "Status",
      key: "active",
      renderComponent: ({ item }) => (
        <Pill variant={item.active === "active" ? "success" : "default"}>
          {item.active ? "Active" : "Archived"}
        </Pill>
      ),
    },
    {
      label: "Device",
      key: "device",
      renderComponent: ({ item }) =>
        item.isMeasurementSeriesLoading ? (
          <div className="skeleton" />
        ) : item?.measurementSeriesData?.device_id ? (
          <RoutingLink
            to={linkToDeviceDetail(item?.measurementSeriesData?.device_id)}
          >
            {item?.measurementSeriesData?.device_name ??
              item?.measurementSeriesData}
          </RoutingLink>
        ) : (
          "-"
        ),
    },
    {
      label: localize("facility"),
      key: "facility",
      renderComponent: ({ item }) =>
        isMeasurementSeriesLoading ? (
          <div className="skeleton" />
        ) : item?.measurementSeriesData?.device?.facility_id ? (
          <RoutingLink
            to={linkToFacilities(
              item?.measurementSeriesData?.device?.facility_id
            )}
          >
            {item?.measurementSeriesData?.device?.facility_name ??
              item?.measurementSeriesData}
          </RoutingLink>
        ) : (
          "-"
        ),
    },
    {
      label: "Created By",
      key: "created_by_id",
      renderComponent: ({ item }) =>
        createdByUsersMap[item.created_by_id]?.name ?? "-",
    },
  ];

  const actionRow = isAllowedToCreate ? (
    <Button
      key="primary"
      variant="primary"
      onClick={() => navigate({ pathname: linkToCreateAlertConfiguration() })}
    >
      Create Alert Configuration
    </Button>
  ) : undefined;

  return (
    <Page
      title="Alert Configuration"
      breadcrumbs={breadcrumbs}
      actionRow={actionRow}
    >
      <Panel>
        <DataTable
          headers={headers}
          items={alertConfigsWithMeasurementSeriesData}
          isLoading={loadingState === "loading"}
          getItemActions={({ item }) =>
            item?.active
              ? [
                  {
                    label: "Archive",
                    buttonProps: {
                      onClick: () => setSelectedAlertConfiguration(item),
                      icon: "archive",
                    },
                  },
                ]
              : []
          }
        />
      </Panel>

      <DeleteModal
        open={!!selectedAlertConfiguration?.id}
        instanceName={selectedAlertConfiguration?.name}
        entityName="Alert Configuration"
        onClose={() => setSelectedAlertConfiguration(undefined)}
        onDelete={() => {
          refetchAlertConfigs();
        }}
        doDelete={() =>
          AlertingService.archiveConfiguration(selectedAlertConfiguration.id)
        }
        action={{ present: "archive", past: "archived" }}
      />
    </Page>
  );
};

AlertConfigurationList.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
  isAllowedToCreate: PropTypes.boolean,
};

export default connect(mapStateToProps, undefined)(AlertConfigurationList);
