import { WorkflowTaskUpdateAssigneeDialog } from "#src/batteries-included-components/Dialogs/WorkflowTaskUpdateAssigneeDialog";
import { AssetTypeSelection } from "#src/batteries-included-components/FilterAreas/assetFilters.helpers";
import {
  FilterConfig,
  WorkflowTasksTableFilterArea,
  WorkflowTasksTableFiltersType,
  WorkflowTasksTableTitleDecorationFilterArea,
  type WorkflowTasksViewFiltersType,
} from "#src/batteries-included-components/FilterAreas/WorkflowTasksFilterAreas";
import { WorkflowTaskStatusToPillVariantMap } from "#src/batteries-included-components/Panels/TablePanels/WorkflowsTablePanel/WorkflowsTablePanel.helpers";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useListUserGroups } from "#src/components/hooks/adapters/useUserGroups";
import { useGetOneWorkflow } from "#src/components/hooks/adapters/useWorkflows";
import {
  useListWorkflowActions,
  useListWorkflowTasks,
} from "#src/components/hooks/adapters/useWorkflowTasks";
import { useTableSortingAndPagination } from "#src/components/Redux/reducers/tableStateReducer";
import { useAuthenticatedContext } from "#src/contexts/AuthenticatedContext.helpers";
import useLocalization from "#src/hooks/useLocalization";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import { linkToFacilityDetail } from "#src/routes/organization/facilities/[facilityId]";
import { linkToUserDetailPage } from "#src/routes/settings/users/detail";
import { UserGroupDetailsRoutePath } from "#src/routes/settings/users/groups/[groupId]/details";
import { WorkflowDetailsRoutePath } from "#src/routes/workflows/all/[workflowId]";
import { linkToAssetDetailPage } from "#src/utils/links";
import {
  useMutation,
  useQueries,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import {
  CellArguments,
  DataTable,
  DataTablePanel,
  HeaderType,
  Icon,
  IconVariants,
  Link,
  Pill,
  StorageKeys,
  TextDataDisplay,
  useToast,
} from "@validereinc/common-components";
import {
  AssetType,
  Resources,
  SortDirection,
  UserGroupsAdapter,
  UserGroupType,
  UsersAdapter,
  UserType,
  WorkflowTaskAdapter,
  WorkflowTaskBaseSchema,
  WorkflowTaskStatus,
  WorkflowTaskType,
  WorkflowTaskTypes,
  type AssetTypeType,
} from "@validereinc/domain";
import { toFlattenedObject, toStartCaseString } from "@validereinc/utilities";
import classNames from "classnames/bind";
import React, { useMemo, useState } from "react";
import styles from "./WorkflowTasksTablePanel.module.scss";
import { WorkflowTaskDetailDrawer } from "#batteries-included-components/Drawers/Workflows/WorkflowTaskDetailDrawer/WorkflowTaskDetailDrawer";
const cx = classNames.bind(styles);

export type WorkflowTasksTablePanelProps = {
  isDisplayingMyTasks?: boolean;
  isDisplayingActions?: boolean;
  taskFilters?: Partial<WorkflowTaskType>;
  onRowClick?: (data: WorkflowTaskType) => void;
  onReassignTask?: (task: WorkflowTaskType) => void;
  /** turns off certain columns as they're duplicated in the workflow details */
  isWithinWorkflowDetail?: boolean;
  defaultAssetType?: AssetTypeType;
} & StorageKeys;

const getAssetFilters = ({
  assetType,
  facilityId,
  equipmentId,
  deviceId,
  flowId,
  assetGroupId,
  workflow,
  ...rest
}: Partial<WorkflowTasksTableFiltersType> &
  Partial<WorkflowTasksViewFiltersType>) => {
  const isValidId = (assetIds: string[] | undefined) => !!assetIds?.length;

  switch (assetType) {
    case AssetTypeSelection.FACILITY:
      return {
        ...rest,
        ...(isValidId(facilityId)
          ? { "workflow.facility.id": facilityId }
          : { "workflow_template.asset_type": assetType }),
        ...(workflow?.facility
          ? toFlattenedObject(workflow.facility, {
              prefix: "workflow.facility",
            })
          : {}),
      };
    case AssetTypeSelection.EQUIPMENT:
      return {
        ...rest,
        ...(isValidId(equipmentId)
          ? { "workflow.equipment.id": equipmentId }
          : { "workflow_template.asset_type": assetType }),
        ...(workflow?.equipment
          ? toFlattenedObject(workflow.equipment, {
              prefix: "workflow.equipment",
            })
          : {}),
      };
    case AssetTypeSelection.DEVICE:
      return {
        ...rest,
        ...(isValidId(deviceId)
          ? { "workflow.device.id": deviceId }
          : { "workflow_template.asset_type": assetType }),
        ...(workflow?.device
          ? toFlattenedObject(workflow.device, { prefix: "workflow.device" })
          : {}),
      };
    case AssetTypeSelection.FLOW:
      return {
        ...rest,
        ...(isValidId(flowId)
          ? { "workflow.flow.id": flowId }
          : { "workflow_template.asset_type": assetType }),
        ...(workflow?.flow
          ? toFlattenedObject(workflow.flow, { prefix: "workflow.flow" })
          : {}),
      };
    case AssetTypeSelection.ASSET_GROUP:
      return {
        ...rest,
        ...(isValidId(assetGroupId)
          ? { "workflow.asset_group.id": assetGroupId }
          : { "workflow_template.asset_type": assetType }),
        ...(workflow?.asset_group
          ? toFlattenedObject(workflow.asset_group, {
              prefix: "workflow.asset_group",
            })
          : {}),
      };
    default:
      return {};
  }
};

export const WorkflowTasksTablePanel = ({
  isDisplayingMyTasks,
  isDisplayingActions,
  filterConfigStorageKey,
  viewConfigStorageKey,
  tableConfigStorageKey,
  taskFilters,
  onRowClick,
  onReassignTask,
  isWithinWorkflowDetail,
  defaultAssetType,
}: WorkflowTasksTablePanelProps) => {
  const [viewFilters] = useSessionStickyState<WorkflowTasksViewFiltersType>(
    {} as WorkflowTasksViewFiltersType,
    viewConfigStorageKey
  );
  const [tableFilters] = useSessionStickyState<WorkflowTasksTableFiltersType>(
    {} as WorkflowTasksTableFiltersType,
    filterConfigStorageKey
  );
  const { assignee_type, assignee, templateId, ...applicableViewFilters } =
    viewFilters;
  const {
    name,
    status,
    workflow: workflowTableFilter,
    categoryId,
    step_type,
    type,
    ...applicableTableFilters
  } = tableFilters;
  const { due_date, ...applicableWorkflowTableFilters } =
    workflowTableFilter ?? {};

  const [selectedTask, setSelectedTask] = useState<WorkflowTaskType | null>(
    null
  );
  const [reassignTask, setReassignTask] = useState<WorkflowTaskType | null>(
    null
  );
  const minDate = due_date?.from;
  const maxDate = due_date?.to;

  const filters: Parameters<typeof WorkflowTaskAdapter.getList>[0]["filters"] =
    {
      name,
      ...(status
        ? {
            status: Array.isArray(status) ? status : { $exact: status },
          }
        : {}),
      ...getAssetFilters({
        ...toFlattenedObject({
          workflow: applicableWorkflowTableFilters,
        }),
        ...applicableTableFilters,
        ...applicableViewFilters,
      }),
      "workflow_category.id": categoryId,
      "workflow_template.id": templateId,
      $and: [
        taskFilters,
        ...(!isWithinWorkflowDetail
          ? [
              {
                ...(assignee_type === "users" && assignee
                  ? { assignee_user: assignee }
                  : assignee_type === "groups" && assignee
                    ? { assignee_group: assignee }
                    : null),
                ...(minDate || maxDate
                  ? {
                      $or: [
                        {
                          $and: [
                            ...(minDate
                              ? [
                                  {
                                    [FilterConfig.dueDate.name]: {
                                      $gte: minDate,
                                    },
                                  },
                                ]
                              : []),
                            ...(maxDate
                              ? [
                                  {
                                    [FilterConfig.dueDate.name]: {
                                      $lte: maxDate,
                                    },
                                  },
                                ]
                              : []),
                          ],
                        },
                      ],
                    }
                  : {}),
              },
            ]
          : [
              {
                ...(step_type ? { step_type } : {}),
                ...(type ? { type } : {}),
              },
            ]),
      ],
    };

  const [tableState, updateTableState] = useTableSortingAndPagination(
    {
      sortBy: "created_at",
      sortDirection: SortDirection.DESCENDING,
    },
    filters
  );

  const requestPayload: Parameters<typeof WorkflowTaskAdapter.getList>[0] = {
    page: tableState.page,
    pageSize: tableState.pageSize,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters,
  };
  const {
    v2: {
      userInfo: { user },
    },
  } = useAuthenticatedContext();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const { localize } = useLocalization();

  const { data: userGroupsData } = useListUserGroups(
    { filters: { "user.id": [user?.id] } },
    { enabled: !!user }
  );
  const userGroups = userGroupsData?.data ?? [];

  const actionsQuery = useListWorkflowActions(requestPayload, {
    enabled: !!isDisplayingActions,
  });
  const tasksQuery = useListWorkflowTasks(requestPayload, {
    enabled: !isDisplayingActions,
  });
  const { data, isLoading: isTasksLoading } = isDisplayingActions
    ? actionsQuery
    : tasksQuery;

  // Fetch workflow from one of the steps:
  const workflowId = data?.data?.[0]?.workflow_id ?? "";
  const workflowQuery = useGetOneWorkflow(
    { id: workflowId },
    { enabled: Boolean(workflowId) && !!isDisplayingActions }
  );

  const workflow = useMemo(() => workflowQuery.data?.data, [workflowQuery]);

  const assignedToUserQueries = useQueries<
    Array<
      UseQueryOptions<
        Awaited<ReturnType<typeof UsersAdapter.getOne>> | undefined,
        unknown,
        UserType | undefined
      >
    >
  >({
    queries:
      data?.data.map((task) => {
        return {
          queryKey: ["users", task.assignee_user],
          queryFn: () => {
            if (!task.assignee_user) {
              return;
            }

            return UsersAdapter.getOne({ id: task.assignee_user });
          },
          enabled: Boolean(task.assignee_user),
          select: (resp) => resp?.data,
        };
      }) ?? [],
  });
  const assignedToUserGroupQueries = useQueries({
    queries:
      data?.data.map((task) => {
        return {
          queryKey: ["users", "groups", task.assignee_group],
          queryFn: () => {
            if (!task.assignee_group) return;
            return UserGroupsAdapter.getOne({ id: task.assignee_group });
          },
          enabled: !!task.assignee_group,
        };
      }) ?? [],
  });

  const { mutate: updateTask } = useMutation({
    mutationFn: (
      payload: Parameters<typeof WorkflowTaskAdapter.updateOne>[0]
    ) => WorkflowTaskAdapter.updateOne(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["workflows"],
      });
      queryClient.invalidateQueries({
        queryKey: [Resources.WORKFLOW],
      });

      setTimeout(() => {
        queryClient.refetchQueries({
          queryKey: ["workflows"],
        });
        queryClient.refetchQueries({
          queryKey: [Resources.WORKFLOW],
        });
      }, 4000);
      toast.push({
        intent: "success",
        description: "Successfully updated task",
      });
    },
    onError: (err) => {
      console.error(err);
      toast.push({
        intent: "error",
        description: "Unable to update task",
      });
    },
  });

  const assignedToUserMap = useMemo(
    () =>
      assignedToUserQueries.reduce<Record<string, UserType>>((map, q) => {
        if (!q.data) {
          return map;
        }

        map[q.data.id] = q.data;
        return map;
      }, {}),
    [assignedToUserQueries]
  );
  const assignedToUserGroupMap = useMemo(
    () =>
      assignedToUserGroupQueries.reduce<Record<string, UserGroupType>>(
        (map, q) => {
          if (!q.data) {
            return map;
          }

          map[q.data.data.id] = q.data.data;
          return map;
        },
        {}
      ),
    [assignedToUserGroupQueries]
  );

  const formatDuration = (timeMs: number) => {
    const days = Math.floor(timeMs / (1000 * 60 * 60 * 24));
    let hours = (timeMs - days * 24 * 60 * 60 * 1000) / (1000 * 60 * 60);
    hours = Math.round(hours * 10) / 10;
    const durations = [];
    if (days > 0) {
      days == 1
        ? durations.push(`${days} Day`)
        : durations.push(`${days} Days`);
    }
    if (hours > 0) {
      hours == 1
        ? durations.push(`${hours} Hour`)
        : durations.push(`${hours} Hours`);
    }
    return durations.join(", ");
  };

  const renderRemainingDelayTime = (item: WorkflowTaskType) => {
    const delayDate = Date.parse(item?.description);
    if (!isNaN(delayDate)) {
      const now = Date.now();
      const timeRemaining = delayDate - now;
      if (timeRemaining < 0) {
        return "Completed";
      } else {
        return `Time Remaining: ${formatDuration(timeRemaining)}`;
      }
    } else {
      return item?.description;
    }
  };

  const getTaskIcon = (task: WorkflowTaskType) => {
    let variant: IconVariants = "validere";
    if (task?.type == WorkflowTaskTypes.MANUAL) {
      variant = "wrench";
    } else if (task?.type == WorkflowTaskTypes.SUBMIT_FORM) {
      variant = "clipboard-text";
    } else if (task?.type == WorkflowTaskTypes.COMPLETE_EVENT) {
      variant = "clock-counter-clockwise";
    } else if (task?.type == WorkflowTaskTypes.CHOICE) {
      variant = "check-square-offset";
    }
    return (
      <Icon
        variant={variant}
        weight="light"
      />
    );
  };

  const checkIfUserAssignedToTask = (task: WorkflowTaskType) => {
    const isUserInAssigneeGroup = userGroups.some(
      (userGroup) => userGroup.id === task?.assignee_group
    );
    const isUserAssignee = user?.id === task?.assignee_user;
    const isUserAssignedToTask = isUserAssignee || isUserInAssigneeGroup;

    return isUserAssignedToTask;
  };

  const renderAssignedTo = ({ item }: CellArguments<WorkflowTaskType>) => {
    if (item.assignee_group && assignedToUserGroupMap[item.assignee_group]) {
      return (
        <RoutingLink
          to={UserGroupDetailsRoutePath.toLink({
            pathParams: { groupId: item.assignee_group },
          })}
        >
          <Pill
            variant="default"
            isBordered
            isCapitalized={false}
            iconLeft={<Icon variant="users" />}
          >
            {assignedToUserGroupMap[item.assignee_group].name}
          </Pill>
        </RoutingLink>
      );
    } else if (item.assignee_user && assignedToUserMap[item.assignee_user]) {
      return (
        <RoutingLink to={linkToUserDetailPage(item.assignee_user)}>
          {assignedToUserMap[item.assignee_user].name}
        </RoutingLink>
      );
    } else {
      return <>-</>;
    }
  };

  const actionsHeaders: Array<HeaderType<WorkflowTaskType>> = [
    {
      label: "Title",
      key: WorkflowTaskBaseSchema.keyof().Enum.name,
      renderComponent: ({ item }) => (
        <Link
          label={item?.name}
          onClick={() => {
            setSelectedTask(item);
            onRowClick?.(item);
          }}
        />
      ),
    },
    {
      label: "Description",
      key: WorkflowTaskBaseSchema.keyof().Enum.description,
      renderComponent: ({ item }) => {
        if (
          [
            WorkflowTaskTypes.FORM_CHOICE,
            WorkflowTaskTypes.CREATE_EVENT,
          ].includes(item?.type)
        ) {
          return <DataTable.DataRow.TextCell value={item?.entity_name} />;
        } else if (item?.type == "delay") {
          return (
            <DataTable.DataRow.TextCell
              value={renderRemainingDelayTime(item)}
            />
          );
        } else {
          return <DataTable.DataRow.TextCell value={item?.description} />;
        }
      },
    },
    {
      label: "Status",
      key: WorkflowTaskBaseSchema.keyof().Enum.status,
      renderComponent: ({ item }) => {
        let status = item.status;

        // Since system actions don't return a status from BE, we need to fake as complete if:
        // 1. It's next step is in the list of actions: meaning that current system action was successfully executed
        // 2. OR, current system action is the last step and the workflow is not in progress.
        if (item.step_type === "system_action") {
          const myNextStepId = workflow?.config.steps?.[item.step_id].next;
          const isNextStepPresentInActions =
            !!myNextStepId &&
            !!data?.data.find((action) => action.step_id === myNextStepId);

          const amILastStep = !!workflow?.config.steps?.[item.step_id].end;
          if (
            (workflow?.status !== "in_progress" && amILastStep) ||
            isNextStepPresentInActions
          )
            status = "complete";
        }

        return (
          status && (
            <Pill
              variant={WorkflowTaskStatusToPillVariantMap[status] || "default"}
            >
              {toStartCaseString(status)}
            </Pill>
          )
        );
      },
    },
    {
      label: "Due Date",
      key: "due_date",
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell value={item.due_date} />
      ),
    },
    {
      label: "Action Type",
      key: WorkflowTaskBaseSchema.keyof().Enum.type,
      renderComponent: ({ item }) => (
        <div style={{ display: "inline-flex", alignItems: "center" }}>
          <div style={{ paddingRight: 8 }}>
            <div className={cx("container")}>{getTaskIcon(item)}</div>
          </div>
          <TextDataDisplay value={toStartCaseString(item.type)} />
        </div>
      ),
    },
    {
      label: "Assigned to",
      key: WorkflowTaskBaseSchema.keyof().Enum.assignee_user,
      renderComponent: renderAssignedTo,
    },
  ];

  const tasksHeaders: Array<HeaderType<WorkflowTaskType>> = [
    {
      label: "Title",
      key: WorkflowTaskBaseSchema.keyof().Enum.name,
      isSortable: true,
      renderComponent: ({ item }) => (
        <Link
          label={item?.name}
          onClick={() => {
            setSelectedTask(item);
            onRowClick?.(item);
          }}
        />
      ),
    },
    {
      label: "Status",
      key: WorkflowTaskBaseSchema.keyof().Enum.status,
      isSortable: true,
      renderComponent: ({ item }) => (
        <Pill
          variant={WorkflowTaskStatusToPillVariantMap[item.status] || "default"}
        >
          {toStartCaseString(item.status)}
        </Pill>
      ),
    },
    {
      label: "Description",
      key: WorkflowTaskBaseSchema.keyof().Enum.description,
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.TextCell value={item.description} />
      ),
    },
    {
      label: "Assigned to",
      key: WorkflowTaskBaseSchema.keyof().Enum.assignee_user,
      isSortable: true,
      renderComponent: renderAssignedTo,
    },
    {
      label: "Task Type",
      key: WorkflowTaskBaseSchema.keyof().Enum.type,
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.TextCell
          value={toStartCaseString(item.type) ?? null}
        />
      ),
    },
    {
      label: "Task Due Date",
      key: WorkflowTaskBaseSchema.keyof().Enum.due_date,
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell value={item.due_date} />
      ),
    },
    ...(!isWithinWorkflowDetail
      ? [
          {
            label: "Workflow",
            key: WorkflowTaskBaseSchema.keyof().Enum.workflow_id,
            isSortable: false,
            renderComponent: ({ item }: { item: WorkflowTaskType }) => (
              <RoutingLink
                to={WorkflowDetailsRoutePath.toLink({
                  pathParams: {
                    workflowId: item.workflow_id,
                  },
                })}
              >
                {item.workflow.name ?? item.workflow.workflow_template.name}
              </RoutingLink>
            ),
          },
          {
            label: "Workflow Due Date",
            key: "workflow.due_date",
            isSortable: false,
            renderComponent: ({ item }: { item: WorkflowTaskType }) => (
              <DataTable.DataRow.DateCell
                value={item.workflow.due_date}
                convertToUTC={false}
              />
            ),
          },
          {
            label: "Asset",
            isSortable: false,
            key: "asset.name",
            renderComponent: ({ item }: { item: WorkflowTaskType }) =>
              item.workflow.asset ? (
                <RoutingLink
                  to={linkToAssetDetailPage(
                    item.workflow.asset.asset_type,
                    item.workflow.asset.id
                  )}
                >
                  {item.workflow.asset.name}
                </RoutingLink>
              ) : (
                "-"
              ),
          },
          {
            label: "Asset Type",
            isSortable: false,
            key: "asset.type",
            renderComponent: ({ item }: { item: WorkflowTaskType }) => (
              <DataTable.DataRow.TextCell
                value={
                  item.workflow.asset
                    ? localize(item.workflow.asset.asset_type)
                    : null
                }
              />
            ),
          },
          {
            label: `Associated ${localize("facility")}`,
            key: "workflow.facility.id",
            isSortable: false,
            renderComponent: ({ item }: { item: WorkflowTaskType }) =>
              item.workflow.asset &&
              item.workflow.asset.asset_type !== AssetType.FACILITY &&
              item.workflow.facility ? (
                <RoutingLink
                  to={linkToFacilityDetail(item.workflow.facility.id)}
                >
                  {item.workflow.facility.name}
                </RoutingLink>
              ) : (
                "-"
              ),
          },
        ]
      : []),
    {
      key: WorkflowTaskBaseSchema.keyof().Enum.created_at,
      label: "Created At",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={item.created_at}
          convertToUTC={false}
        />
      ),
    },
  ];

  const taskStepIds = data?.data.map((task) => task.step_id);

  const isLoading =
    isTasksLoading ||
    assignedToUserQueries.some((q) => q.isFetching && !q.isRefetching) ||
    assignedToUserGroupQueries.some((q) => q.isFetching && !q.isRefetching);

  return (
    <>
      <DataTablePanel
        storageKey={tableConfigStorageKey}
        panelProps={{
          title: `${isDisplayingActions ? "Actions" : isDisplayingMyTasks ? "My Tasks" : "All Tasks"}`,
          titleDecorator: (
            <WorkflowTasksTableTitleDecorationFilterArea
              filterConfigStorageKey={filterConfigStorageKey}
            />
          ),
        }}
        filterComponent={
          <WorkflowTasksTableFilterArea
            viewConfigStorageKey={viewConfigStorageKey}
            filterConfigStorageKey={filterConfigStorageKey}
            defaultAssetType={defaultAssetType}
            isWithinAWorkflow={isWithinWorkflowDetail}
          />
        }
        dataTableProps={{
          variant: "simplicity-first",
          showRowNumbers: isWithinWorkflowDetail,
          headers: isDisplayingActions ? actionsHeaders : tasksHeaders,
          items: data?.data ?? [],
          sorting: {
            sortBy: tableState.sortBy,
            sortDirection: tableState.sortDirection,
          },
          isLoading,
          isBusy: tasksQuery.isFetching || actionsQuery.isFetching,
          pagination: {
            page: tableState.page,
            pageSize: tableState.pageSize,
            total: data?.total_entries,
          },
          onSortChange: updateTableState,
          onPaginationChange: updateTableState,
          getItemActions: ({ item }: { item: WorkflowTaskType }) =>
            item.status === WorkflowTaskStatus.OPEN ||
            item.status === WorkflowTaskStatus.OVERDUE
              ? [
                  {
                    label: `${
                      (item?.restrict_assignee_completion &&
                        !checkIfUserAssignedToTask(item)) ??
                      false
                        ? "Only assignees can action this task"
                        : "Complete"
                    }`,
                    buttonProps: {
                      icon: "check-circle",
                      disabled:
                        (item?.restrict_assignee_completion &&
                          !checkIfUserAssignedToTask(item)) ??
                        false,
                      onClick: () =>
                        updateTask({
                          id: item.id,
                          data: {
                            status: WorkflowTaskStatus.COMPLETE,
                          },
                          previousData: item,
                        }),
                    },
                  },
                  ...(item.is_dismissible
                    ? [
                        {
                          label: "Dismiss",
                          buttonProps: {
                            icon: "x-circle",
                            onClick: () =>
                              updateTask({
                                id: item.id,
                                data: {
                                  status: WorkflowTaskStatus.DISMISSED,
                                },
                                previousData: item,
                              }),
                          },
                        },
                      ]
                    : []),
                  {
                    label: "Reassign",
                    buttonProps: {
                      icon: "user-circle-gear",
                      onClick: () => {
                        setReassignTask(item);
                        onReassignTask?.(item);
                      },
                    },
                  },
                ]
              : [],
        }}
      />
      <WorkflowTaskDetailDrawer
        setSelectedTask={setSelectedTask}
        selectedTask={selectedTask}
        onReassignTask={(task) => setReassignTask(task)}
        taskStepIds={taskStepIds ?? []}
      />
      <WorkflowTaskUpdateAssigneeDialog
        isOpen={Boolean(reassignTask)}
        task={reassignTask}
        onClose={() => setReassignTask(null)}
      />
    </>
  );
};
