import { getEventSchemaId } from "#src/batteries-included-components/Drawers/Workflows/WorkflowTaskDetailDrawer/WorkflowTaskDetailDrawer.helpers";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { linkToEventCategoryDetail } from "#src/routes/events/event-categories/[eventCategoryId]";
import { linkToEventTemplateDetail } from "#src/routes/events/event-categories/[eventCategoryId]/templates/[templateId]/details";
import { linkToEventDetails } from "#src/routes/events/event-categories/[eventCategoryId]/templates/[templateId]/events/[eventId]";
import { useQueries, useQuery } from "@tanstack/react-query";
import {
  KeyValueList,
  Pill,
  PillProps,
  Skeleton,
} from "@validereinc/common-components";
import {
  EventCategoryDomain,
  EventDetailsType,
  EventSchemaDomain,
  EventsDomain,
  EventStatusType,
  UsersAdapter,
  WorkflowTaskType,
  WorkflowType,
} from "@validereinc/domain";
import { datetimeFormatter } from "@validereinc/utilities";
import classNames from "classnames/bind";
import startCase from "lodash/startCase";
import React from "react";
import styles from "./WorkflowTaskCompleteEvent.module.scss";

const cx = classNames.bind(styles);

export const WorkflowTaskCompleteEvent = ({
  workflowTask,
  workflow,
}: {
  workflowTask?: WorkflowTaskType;
  workflow?: WorkflowType;
}) => {
  const events = useQueries({
    queries:
      workflowTask?.emission_event?.map((event) => ({
        queryKey: ["events", event.id],
        queryFn: () => EventsDomain.getOne({ eventId: event.id }),
        enabled: Boolean(event.id),
        staleTime: 3 * 60 * 1000,
      })) ?? [],
  });

  return (
    <>
      {events?.map(({ data, isLoading }) => (
        <Skeleton
          key={data?.id}
          isLoading={isLoading}
          style={{ minHeight: 200 }}
        >
          {data && workflow && workflowTask && (
            <EventDetails
              event={data}
              workflowTask={workflowTask}
              workflow={workflow}
            />
          )}
        </Skeleton>
      ))}
    </>
  );
};

const EventDetails = ({
  event,
  workflowTask,
  workflow,
}: {
  event: EventDetailsType;
  workflowTask: WorkflowTaskType;
  workflow: WorkflowType;
}) => {
  const { eventSchemaId } = getEventSchemaId(workflowTask, workflow);

  const { data: eventSchema } = useQuery({
    queryKey: ["events", "schemas", eventSchemaId],
    queryFn: () => {
      if (!eventSchemaId) {
        return;
      }

      return EventSchemaDomain.getOne({ eventSchemaId: eventSchemaId });
    },
    enabled: Boolean(eventSchemaId),
    staleTime: 3 * 60 * 1000,
  });
  const { data: eventCategory } = useQuery(
    ["events", "categories", eventSchema?.event_category_id],
    () => {
      if (!eventSchema?.event_category_id) {
        return;
      }

      return EventCategoryDomain.getOne({
        eventCategoryId: eventSchema.event_category_id,
      });
    },
    {
      enabled: Boolean(eventSchema?.event_category_id),
      staleTime: 3 * 60 * 1000,
    }
  );
  const { data: createdByUser } = useQuery(
    ["users", event.created_by],
    () => {
      if (!event.created_by) return;
      return UsersAdapter.getOne({ id: event.created_by });
    },
    {
      enabled: Boolean(event.created_by),
      staleTime: 3 * 60 * 1000,
      select: (resp) => resp?.data,
    }
  );

  const data = [
    {
      title: "Event",
      value: eventSchema ? (
        <RoutingLink
          to={{
            pathname: linkToEventDetails(
              eventCategory?.id,
              eventSchema?.id,
              event.id
            ),
            search: `?workflowTaskId=${workflowTask.id}`,
          }}
        >
          {event.name}
        </RoutingLink>
      ) : (
        "-"
      ),
    },
    {
      title: "Description",
      value: event.description,
    },
    {
      title: "Status",
      value: (
        <Pill
          variant={
            (
              {
                open: "warning",
                overdue: "error",
                dismissed: "default",
                resolved: "success",
              } satisfies Record<EventStatusType, PillProps["variant"]>
            )[event.status]
          }
        >
          {startCase(event.status)}
        </Pill>
      ),
    },
    {
      title: "Template",
      value: eventSchema ? (
        <RoutingLink
          to={linkToEventTemplateDetail(
            eventSchema.event_category_id,
            eventSchema.id
          )}
        >
          {eventSchema.name}
        </RoutingLink>
      ) : (
        "-"
      ),
    },
    {
      title: "Category",
      value: eventCategory ? (
        <RoutingLink to={linkToEventCategoryDetail(eventCategory.id)}>
          {eventCategory.name}
        </RoutingLink>
      ) : (
        "-"
      ),
    },
    {
      title: "Created By",
      value: createdByUser?.name ?? "-",
    },
    {
      title: "Created At",
      value: datetimeFormatter(new Date(event.created_at)),
    },
  ];

  return (
    <KeyValueList
      className={cx("keyValueListContainer")}
      data={data}
    />
  );
};
