import { moveItem } from "#src/utils/moveItem";
import {
  Button,
  DataTable,
  Link,
  Panel,
  useFieldArray,
  useFormContext,
  type DataTableProps,
} from "@validereinc/common-components";
import {
  FormSchemaQuestionType,
  type FormSchemaQuestionDisplayType,
} from "@validereinc/domain";
import {
  ReorderDirection,
  type ReorderDirectionType,
} from "@validereinc/utilities";
import startCase from "lodash/startCase";
import React, { useState } from "react";
import { CreateFormTemplateAddQuestionDrawer } from "./CreateFormTemplateAddQuestionDrawer";
import { DeleteQuestionDialog } from "./DeleteQuestionDialog";

export const CreateFormTemplateQuestionTable = ({
  formFieldSectionQuestionsName,
  sectionName,
}: {
  formFieldSectionQuestionsName: string;
  sectionName: string;
}) => {
  // formFieldSectionQuestionsName is in the shape of config.sections.<section_number>.questions, where <section_number> starts at 0
  const sectionNumber =
    parseInt(formFieldSectionQuestionsName.split(".")[2]) + 1;

  const parentForm = useFormContext();

  const [isAddQuestionDrawerOpen, setIsAddQuestionDrawerOpen] = useState(false);
  const [questionIdToDelete, setQuestionIdToDelete] = useState<string>("");

  const { fields, move } = useFieldArray({
    control: parentForm.control,
    name: formFieldSectionQuestionsName,
  });
  const [questionToEdit, setQuestionToEdit] =
    useState<FormSchemaQuestionDisplayType | null>(null);

  const sectionQuestionIds = parentForm.watch(formFieldSectionQuestionsName);

  const questions = parentForm.watch("config.questions");

  const displayQuestions = sectionQuestionIds.reduce(
    (total: FormSchemaQuestionType[], questionId: string, index: number) => {
      const questionDetails: FormSchemaDisplayQuestionType = {
        question_id: questionId,
        type: null,
        question_number: questions[questionId].question_number ?? index + 1,
        ...questions[questionId],
      };

      if (!questionDetails) {
        return total;
      } else {
        return [...total, questionDetails];
      }
    },
    [] as FormSchemaQuestionType[]
  );

  const moveQuestion = (
    indexToSwapFrom: number,
    direction: ReorderDirectionType
  ) => {
    const { originalItemId, adjacentItemId, indexToMoveTo } = moveItem(
      sectionQuestionIds,
      move,
      indexToSwapFrom,
      direction
    );

    if (originalItemId && adjacentItemId && indexToMoveTo !== undefined) {
      const newQuestions = { ...questions };
      newQuestions[originalItemId].question_number = indexToMoveTo + 1;
      newQuestions[adjacentItemId].question_number = indexToSwapFrom + 1;
      parentForm.setValue("config.questions", { ...newQuestions });
    }
  };

  const deleteQuestionfromSectionQuestionIds = (questionIdToDelete: string) => {
    let newSectionQuestionIds = [...sectionQuestionIds];
    newSectionQuestionIds = newSectionQuestionIds.filter(
      (questionId) => questionId !== questionIdToDelete
    );
    parentForm.setValue(formFieldSectionQuestionsName, [
      ...newSectionQuestionIds,
    ]);
  };
  const deleteQuestionFromConfigQuestionIds = (questionIdToDelete: string) => {
    let newQuestions = { ...questions };

    newQuestions = Object.fromEntries(
      Object.entries(newQuestions).map(([questionId, question]) => {
        const questionNumberToDelete = displayQuestions.find(
          (displayQuestion) =>
            displayQuestion.question_id === questionIdToDelete
        ).question_number;

        const currentQuestionNumber =
          question.question_number ??
          displayQuestions.filter((displayQuestion) => {
            return displayQuestion.question_id === questionId;
          })[0]?.question_number;

        const newQuestionNumber =
          currentQuestionNumber > questionNumberToDelete
            ? currentQuestionNumber - 1
            : currentQuestionNumber;

        return [
          questionId,
          { ...question, question_number: newQuestionNumber },
        ];
      })
    );

    delete newQuestions[questionIdToDelete];
    parentForm.setValue("config.questions", { ...newQuestions });
  };

  const handleDeleteQuestion = (questionIdToDelete: string) => {
    deleteQuestionfromSectionQuestionIds(questionIdToDelete);
    deleteQuestionFromConfigQuestionIds(questionIdToDelete);
    onCloseAddQuestionDrawer();
  };

  const getItemActions = ({
    item,
  }: {
    item: FormSchemaQuestionDisplayType;
  }) => {
    const questionIndex = item.question_number - 1;
    return [
      ...(questionIndex !== 0
        ? [
            {
              label: "Move Up",
              buttonProps: {
                onClick: (e: React.ChangeEvent<HTMLInputElement>) => {
                  e.stopPropagation();
                  moveQuestion(questionIndex, ReorderDirection.UP);
                },
                icon: "arrow-circle-up",
              },
            },
          ]
        : []),
      ...(questionIndex !== displayQuestions.length - 1
        ? [
            {
              label: "Move Down",
              buttonProps: {
                onClick: (e: React.ChangeEvent<HTMLInputElement>) => {
                  e.stopPropagation();
                  moveQuestion(questionIndex, ReorderDirection.DOWN);
                },
                icon: "arrow-circle-down",
              },
            },
          ]
        : []),
      ...[
        {
          label: "Delete",
          buttonProps: {
            onClick: (e: React.ChangeEvent<HTMLInputElement>) => {
              e.stopPropagation();
              setQuestionIdToDelete(item.question_id);
            },
            icon: "trash",
            className: "button--error-outline",
          },
        },
      ],
    ];
  };

  const onCloseAddQuestionDrawer = () => {
    setQuestionToEdit(null);
    setIsAddQuestionDrawerOpen(false);
  };

  const onOpenAddQuestionDrawer = () => {
    setIsAddQuestionDrawerOpen(true);
  };

  const onSaveQuestionDrawer = (newQuestionId: string) => {
    parentForm.setValue(formFieldSectionQuestionsName, [
      ...sectionQuestionIds,
      newQuestionId,
    ]);
  };

  const onEditQuestion: DataTableProps<FormSchemaQuestionDisplayType>["onRowClick"] =
    (question) => {
      setQuestionToEdit(question);
      onOpenAddQuestionDrawer();
    };

  const CreateQuestionButton = () => (
    <Button
      icon="plus-circle"
      onClick={onOpenAddQuestionDrawer}
    >
      Create Question
    </Button>
  );

  return (
    <>
      <Panel
        title={`Section ${sectionNumber}: ${sectionName} Questions `}
        actionRow={
          displayQuestions?.length
            ? [<CreateQuestionButton key="create-question-button" />]
            : undefined
        }
      >
        <DataTable
          showRowNumbers
          headers={[
            {
              key: "prompt",
              label: "Prompt",
              renderComponent: ({ item }) => (
                <Link
                  label={item.prompt}
                  onClick={() => onEditQuestion(item)}
                />
              ),
            },
            {
              key: "description",
              label: "Description",
              renderComponent: ({ item }) => (
                <DataTable.DataRow.TextCell value={item.description} />
              ),
            },
            {
              key: "type",
              label: "Question Type",
              renderComponent: ({ item }) => startCase(item.type),
            },
          ]}
          items={displayQuestions}
          emptyStateProps={{
            title: "There are no questions in this section",
            suggestion: 'Press the "Create Question" button to add a question',
            action: <CreateQuestionButton />,
          }}
          getItemActions={getItemActions}
          onRowClick={onEditQuestion}
        />
      </Panel>

      <CreateFormTemplateAddQuestionDrawer
        isOpen={isAddQuestionDrawerOpen}
        onClose={onCloseAddQuestionDrawer}
        onSave={onSaveQuestionDrawer}
        questionToEdit={questionToEdit}
        isDeleteQuestionDialogOpen={!!questionIdToDelete}
        handleDeleteQuestion={() => {
          setQuestionIdToDelete(questionToEdit?.question_id ?? "");
        }}
        sectionNumber={sectionNumber}
        sectionQuestionsLength={sectionQuestionIds.length}
        displayQuestions={displayQuestions}
      />
      <DeleteQuestionDialog
        isOpen={!!questionIdToDelete}
        onClose={() => {
          setQuestionIdToDelete("");
        }}
        handleDeleteQuestion={handleDeleteQuestion}
        questionIdToDelete={questionIdToDelete}
      />
    </>
  );
};
