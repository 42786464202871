import { useParams } from "#routers/hooks";
import { enhanceSource } from "#utils/recordUtils";
import { UseQueryOptions, useQueries, useQuery } from "@tanstack/react-query";
import type { RecordType, UserType } from "@validereinc/domain";
import {
  EstimationMethodDomain,
  RecordDomain,
  RecordValueConfigurationSourceType,
  RecordValueConfigurationTypeType,
  UsersAdapter,
} from "@validereinc/domain";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

export const RecordContext = createContext<{
  record?: RecordType;
  users?: UserType[];
  isLoading: boolean;
  refetch: () => void;
} | null>(null);

export const RecordProvider = ({
  children,
}: {
  children: React.ReactElement | React.ReactElement[];
}) => {
  const parentContext = useContext(RecordContext);
  const { recordId } = useParams<{ recordId: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [record, setRecord] = useState<RecordType>();
  const [lastFetchDate, setLastFetchDate] = useState<Date | null>(null);
  const metaFieldUsersQueries = useQueries<
    Array<
      UseQueryOptions<
        Awaited<ReturnType<typeof UsersAdapter.getOne>> | undefined,
        unknown,
        UserType | undefined
      >
    >
  >({
    queries: record
      ? Array.from(
          new Set([
            record.updated_by,
            ...(record.values.map((d) => d.updated_by) ?? []),
          ])
        ).map((id) => ({
          queryKey: ["users", id],
          queryFn: () => {
            if (!id) {
              return;
            }

            return UsersAdapter.getOne({ id });
          },
          enabled: Boolean(id),
          staleTime: 3 * 60 * 1000,
          select: (resp) => resp?.data,
        }))
      : [],
  });

  const refetch = () => {
    setLastFetchDate(new Date());
  };

  const fetchRecord = async (recordId: string) => {
    try {
      setIsLoading(true);
      const newRecord = await RecordDomain.getOne({ recordId });
      setRecord(newRecord);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (recordId && !parentContext) {
      fetchRecord(recordId);
    }
  }, [recordId, lastFetchDate]);

  /** TODO: Remove these lookups when the backend can provide the entity type as well */
  const estimationMethodParams = {
    filters: {
      year_month: record?.year_month ? [record?.year_month] : [],
      id: record?.values
        .filter(
          ({ configuration: { configuration_type } }) =>
            configuration_type ===
            RecordValueConfigurationTypeType.CALCULATION_RESULT
        )
        .flatMap(({ configuration: { sources } }) => sources)
        .map(
          ({ estimation_method_id }: RecordValueConfigurationSourceType) =>
            estimation_method_id
        ),
    },
  };

  const estimationMethodQuery = useQuery({
    queryKey: ["estimationMethodConfigurations", estimationMethodParams],
    queryFn: () =>
      EstimationMethodDomain.configuration.getList(estimationMethodParams),
    enabled: !!estimationMethodParams.filters.id?.length,
    select: ({ data }) => data,
  });

  const recordWithSources = useMemo(
    () =>
      record
        ? {
            ...record,
            values: record?.values?.map((value) => ({
              ...value,
              configuration: {
                ...value.configuration,
                sources: value.configuration.sources?.map(
                  (source: RecordValueConfigurationSourceType) =>
                    enhanceSource(source, estimationMethodQuery.data ?? [])
                ),
              },
            })),
          }
        : undefined,
    [record, estimationMethodQuery.data]
  );
  /** TODO: Remove these lookups when the backend can provide the entity type as well */

  return (
    <RecordContext.Provider
      value={
        parentContext ?? {
          record: recordWithSources,
          users: metaFieldUsersQueries.reduce<UserType[]>((list, q) => {
            if (!q.data) {
              return list;
            }

            list.push(q.data);
            return list;
          }, []),
          isLoading:
            isLoading ||
            (!!estimationMethodParams.filters.id?.length &&
              estimationMethodQuery.isLoading),
          refetch,
        }
      }
    >
      {children}
    </RecordContext.Provider>
  );
};
