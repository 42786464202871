import { useHasPermission } from "#src/contexts/AuthenticatedContext.helpers";
import { ButtonWithPopover } from "@validereinc/common-components";
import React from "react";
import type { PermissionAwareExportButtonProps } from "./types";

/**
 * Export buttons in the app require `"reports:read"` and `"reports:write"` permissions.
 * This button doesn't get rendered if these permissions are missing.
 */
const PermissionAwareExportButton = (
  props: PermissionAwareExportButtonProps
) => {
  const { onClick, isExporting, label, isDisabled } = props;

  const [hasPermission] = useHasPermission({
    $and: ["reports:read", "reports:write"],
  });

  if (!hasPermission) return null;

  return (
    <ButtonWithPopover
      buttonProps={{
        icon: "arrow-square-out",
        variant: "outline",
        onClick: () => {
          onClick?.();
        },
        isLoading: isExporting,
        disabled: isDisabled,
      }}
      label={label ?? "Export"}
    />
  );
};

export default PermissionAwareExportButton;
