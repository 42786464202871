import { TemplatedReportTemplatesTablePanel } from "#src/routes/reports/templated-reports/TemplatedReportTemplatesTablePanel";
import React from "react";

export const TemplatedReportTemplatesTab = () => {
  return (
    <>
      <TemplatedReportTemplatesTablePanel />
    </>
  );
};
