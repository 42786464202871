import { Panel, Tooltip, useToast } from "@validereinc/common-components";
import React from "react";
import FontAwesome from "react-fontawesome";
import { LinkContainer } from "react-router-bootstrap";
import { AutoSizer, Column } from "react-virtualized";
import config from "../../../../config";
import { linkToEditMember } from "../../../../Routers/links";
import { getTimeStringFromDate } from "../../../../utils/timeFormatter";
import CustomTable from "../../../Common/Table/CustomTable";
import UserService from "../../../Services/UserService";
import MemberNameCell from "./MemberNameCell";

/* eslint-disable react/prop-types */

const TABLE_HEIGHT_OFFSET = 300;

const MIN_TABLE_HEIGHT = 150;

const MemberTable = (props) => {
  const { filterMembers, filterRow, height, writePermission } = props;
  const { toast } = useToast();

  function nameRenderer({ rowData }) {
    return <MemberNameCell memberInfo={rowData} />;
  }

  function siteRenderer({ rowData }) {
    const sites = rowData.sites;

    if (sites && sites.length > 1) {
      const tooltip = getMultipleSitesToolTip(sites);

      return (
        <Tooltip content={tooltip}>
          <div>Multiple Sites</div>
        </Tooltip>
      );
    }

    if (sites && sites.length === 1) {
      const siteName = sites[0].name;

      return (
        <Tooltip content={siteName}>
          <div>{siteName}</div>
        </Tooltip>
      );
    }

    return <div>No sites</div>;
  }

  function lastActivityTimeRenderer({ rowData, dataKey }) {
    const value = rowData[dataKey];

    if (value) {
      return getTimeStringFromDate(value, config.DATETIME_FORMAT);
    }

    return "No activity";
  }

  function editRenderer({ rowData }) {
    return (
      <div className="membersActionCell">
        <LinkContainer to={linkToEditMember(rowData.id)}>
          <FontAwesome name={writePermission ? "pencil-square-o" : "eye"} />
        </LinkContainer>
      </div>
    );
  }

  function getMultipleSitesToolTip(sites) {
    return (
      <Tooltip id="tooltip">
        <ul>
          {sites.map((site, index) => (
            <li key={index}>{site.name}</li>
          ))}
        </ul>
      </Tooltip>
    );
  }

  function onCSVDownloadClick() {
    toast.push({
      intent: "success",
      description: "Your files will start downloading shortly.",
    });

    UserService.getUsersCSVLink().then(({ data }) => {
      const link = document.createElement("a");
      link.download = "inline";
      link.href = data.url;
      document.body.appendChild(link);
      link.click();
    });
  }

  return (
    <Panel
      className="memberTable"
      style={{
        height: Math.max(height - TABLE_HEIGHT_OFFSET, MIN_TABLE_HEIGHT),
      }}
    >
      <AutoSizer>
        {({ width, height }) => (
          <CustomTable
            width={width}
            height={height}
            list={filterMembers}
            filterKey="name"
            filterTitle="Name"
            defaultSortBy="name"
            defaultSortDirection="asc"
            rowHeight={55}
            csvDownload={onCSVDownloadClick}
            filterRow={filterRow}
          >
            <Column
              label="Name"
              dataKey="name"
              cellRenderer={nameRenderer}
              width={width / 2}
            />
            <Column
              label="Role"
              dataKey="role"
              style={{ textTransform: "capitalize" }}
              width={width / 6}
            />
            <Column
              label="Sites"
              dataKey="sites"
              cellRenderer={siteRenderer}
              style={{ textTransform: "capitalize" }}
              width={width / 3}
              disableSort
            />
            <Column
              label="Last Activity"
              dataKey="last_activity_time"
              cellDataGetter={lastActivityTimeRenderer}
              width={width / 3}
            />
            <Column
              label="Status"
              dataKey="state"
              style={{ textTransform: "capitalize" }}
              width={width / 6}
            />
            <Column
              label="Action"
              dataKey=""
              cellRenderer={editRenderer}
              width={width / 6}
              disableSort
            />
          </CustomTable>
        )}
      </AutoSizer>
    </Panel>
  );
};

export default MemberTable;
