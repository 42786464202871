import { ReportWithDownloadV2Type } from "../schemas";
import {
  ActivityType,
  ActivityResourceType,
  ActivityResource,
  ActivityExportResourceType,
  ActivityFilterType,
} from "../schemas/ActivitiesSchemas";
import {
  GetListRequestType,
  GetListResponseType,
  getFilterObject,
} from "../util";
import { restAPI } from "./api";

export const ActivitiesDomain = {
  /**
   * Get activity list
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/activities
   * @returns the list of activities
   */
  getList: ({
    page,
    pageSize,
    sortBy = "timestamp",
    sortDirection = "desc",
    filters,
    meta,
  }: GetListRequestType<
    ActivityFilterType,
    { resourceType?: ActivityResourceType }
  >) => {
    if (
      meta?.resourceType &&
      Object.values(ActivityResource).includes(meta?.resourceType)
    ) {
      return restAPI.nodeAPI.POST<GetListResponseType<ActivityType>>({
        endpoint: `/activities/${meta?.resourceType}/search`,
        body: {
          sort_by: sortBy,
          sort_direction: sortDirection,
          page,
          page_size: pageSize,
          filter: getFilterObject(filters),
        },
      });
    } else {
      return restAPI.nodeAPI.POST<GetListResponseType<ActivityType>>({
        endpoint: "/activities/search",
        body: {
          sort_by: sortBy,
          sort_direction: sortDirection,
          page,
          page_size: pageSize,
          filter: getFilterObject(filters),
        },
      });
    }
  },
  /**
   * Export activity logs
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/activities/export_activity_log
   */
  exportList: ({
    filters,
    sortBy,
    sortDirection,
    meta,
  }: GetListRequestType<
    ActivityFilterType & { period?: string },
    { resourceType: ActivityExportResourceType }
  >) => {
    return restAPI.nodeAPI.POST<ReportWithDownloadV2Type>({
      endpoint: `/activities/${meta?.resourceType}/export`,
      body: {
        sort_by: sortBy,
        sort_direction: sortDirection,
        filter: getFilterObject(filters),
      },
    });
  },
};
