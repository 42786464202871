import { DEFAULT_QUERY_OPTIONS } from "#hooks/adapters/adapterUtils";
import { ExceptionUtils } from "#src/utils/exception";
import {
  UseQueryOptions,
  useMutation,
  useQueries,
  useQuery,
} from "@tanstack/react-query";
import { useToast } from "@validereinc/common-components";
import { BaseError, UserType, UsersAdapter } from "@validereinc/domain";
import { downloadLink } from "@validereinc/utilities";

export const USERS_QUERY_KEY = "users";
export const MYSELF_QUERY_KEY = "myself";

export const useGetOneUser = (apiParams: { id: string | null | undefined }) =>
  useQuery({
    queryKey: [USERS_QUERY_KEY, apiParams.id],
    queryFn: () => UsersAdapter.getOne({ id: apiParams.id ?? "" }),
    enabled: !!apiParams.id,
    select: (resp) => resp?.data,
    ...DEFAULT_QUERY_OPTIONS,
  });

export const useGetManyUsers = (ids: string[]) =>
  useQueries<
    Array<
      UseQueryOptions<
        Awaited<ReturnType<typeof UsersAdapter.getOne>>,
        unknown,
        UserType | undefined
      >
    >
  >({
    queries: ids.map((id) => ({
      queryKey: [USERS_QUERY_KEY, id],
      queryFn: () => UsersAdapter.getOne({ id }),
      select: (resp) => resp?.data,
      ...DEFAULT_QUERY_OPTIONS,
    })),
  });

export const useExportUsers = (apiParams = {}) => {
  const { toast } = useToast();

  return useMutation({
    mutationFn: async () => {
      const report = await UsersAdapter?.exportList?.(apiParams);
      if (!report?.s3_download_link) {
        throw new BaseError(`Could not fetch download URL for users export`, {
          cause: report,
        });
      }

      downloadLink(report.s3_download_link, report.name);
    },
    onError: (error) => {
      ExceptionUtils.reportException(error, "error");
      toast.push({
        intent: "error",
        description: "Failed to export users list.",
      });
    },
  });
};
