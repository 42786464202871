import { useCreateOneUserGroupCategory } from "#src/components/hooks/adapters/useUserGroups";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Button,
  Dialog,
  Form,
  SubmitHandler,
  TextAreaInput,
  TextInput,
  useForm,
  useToast,
} from "@validereinc/common-components";
import {
  UserGroupCategoriesAdapter,
  UserGroupCategorySchema,
  UserGroupCategoryType,
} from "@validereinc/domain";
import React, { useEffect } from "react";

// Form schema:
type FormValues = Pick<UserGroupCategoryType, "name" | "description">;

export const UserGroupCategoryFormDialog = ({
  isOpen,
  onClose: onCloseProp,
  onSubmit,
  userGroupCategoryId,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSubmit?: () => void;
  userGroupCategoryId?: UserGroupCategoryType["id"];
}) => {
  const { toast } = useToast();
  // Fetch user group category if userGroupCategoryId is provided:

  const initialFormValues = useQuery({
    queryFn: () =>
      UserGroupCategoriesAdapter.getOne({ id: userGroupCategoryId ?? "" }),
    enabled: !!userGroupCategoryId,
  });

  const form = useForm<FormValues>({
    defaultValues: initialFormValues.data?.data ?? {},
  });

  const createGroupCategoryMutation = useCreateOneUserGroupCategory({
    onSuccess: () => {
      onSubmit?.();
      onCloseProp();
    },
  });

  const updateGroupCategoryMutation = useMutation({
    mutationFn: (data: FormValues) =>
      UserGroupCategoriesAdapter.updateOne({
        id: userGroupCategoryId ?? "",
        data,
      }),
    onSuccess: () => {
      toast.push({
        intent: "success",
        description: "Successfully updated user group category.",
      });
      onSubmit?.();
      onCloseProp();
    },
    onError: () => {
      toast.push({
        intent: "error",
        description: "Failed to updated user group category.",
      });
    },
  });

  const isFormLoading =
    createGroupCategoryMutation.isLoading ||
    updateGroupCategoryMutation.isLoading ||
    (initialFormValues.isLoading && !!userGroupCategoryId);

  const onClose = () => {
    if (isFormLoading) return;
    onCloseProp();
  };

  useEffect(() => {
    form.reset();
  }, [isOpen]);

  const submitHandler: SubmitHandler<FormValues> = (formInput) => {
    if (userGroupCategoryId) {
      updateGroupCategoryMutation.mutate(formInput);
    } else {
      createGroupCategoryMutation.mutate(formInput);
    }
  };

  return (
    <Form
      {...form}
      onSubmit={form.handleSubmit(submitHandler)}
    >
      <Dialog
        key="group-category-form"
        title={`${userGroupCategoryId ? "Edit" : "Create"} User Group Category`}
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        actionRow={[
          <Button
            key="save-group-category"
            variant="primary"
            onClick={form.handleSubmit(submitHandler)}
            disabled={isFormLoading}
            type="submit"
          >
            {userGroupCategoryId ? "Save" : "Create"}
          </Button>,
        ]}
      >
        <TextInput
          name={UserGroupCategorySchema.keyof().Enum.name}
          label="Name"
          placeholder="Enter a name..."
          isRequired
          isDisabled={isFormLoading}
        />

        <TextAreaInput
          name={UserGroupCategorySchema.keyof().Enum.description}
          label="Description"
          placeholder="Enter a description..."
          isDisabled={isFormLoading}
          isRequired
        />
      </Dialog>
    </Form>
  );
};
