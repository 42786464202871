import { UsersDropdownInput } from "#src/batteries-included-components/Dropdowns/UsersDropdownInput";
import { USER_GROUP_QUERY_KEY } from "#src/components/hooks/adapters/useUserGroups";
import { ExceptionUtils } from "#src/utils/exception";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Dialog,
  Form,
  useForm,
  useToast,
} from "@validereinc/common-components";
import { UserGroupsAdapter } from "@validereinc/domain";
import React from "react";

type AddMembersToUserGroupFormType = {
  user_ids: string[];
};

export const AddMembersToUserGroupDialog = ({
  isOpen = false,
  onClose,
  currentMemberIds,
  userGroupId,
}: {
  isOpen: boolean;
  onClose: () => void;
  currentMemberIds: string[];
  userGroupId: string;
}) => {
  const form = useForm<AddMembersToUserGroupFormType>({
    defaultValues: {
      user_ids: [],
    },
  });
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const updateMembershipsMutation = useMutation({
    mutationFn: ({ membersToAdd }: { membersToAdd: string[] }) => {
      return Promise.all(
        membersToAdd.map((mid) =>
          UserGroupsAdapter.members.add({
            id: mid,
            data: null,
            meta: { userGroupId },
          })
        )
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [...USER_GROUP_QUERY_KEY],
      });
      toast.push({
        intent: "success",
        description: "Successfully added members to group",
      });
    },
    onError: (err) => {
      toast.push({
        intent: "error",
        description: "Failed to add members to group.",
      });
      ExceptionUtils.reportException(err, "error", {
        hint: "Failed to add user memberships for user group.",
      });
    },
  });

  const handleClose = () => {
    form.reset();
    onClose();
  };
  const handleSubmit = ({
    user_ids: membersToAdd,
  }: AddMembersToUserGroupFormType) => {
    return updateMembershipsMutation
      .mutateAsync({ membersToAdd })
      .finally(() => {
        handleClose();
      });
  };

  const selectedUserIds = form.watch("user_ids");

  return (
    <Dialog
      title="Add Members"
      isOpen={isOpen}
      onClose={handleClose}
      actionRow={[
        <Button
          key="add"
          variant="primary"
          onClick={form.handleSubmit(handleSubmit)}
          isLoading={updateMembershipsMutation.isLoading}
          disabled={!selectedUserIds.length}
        >
          Add{selectedUserIds.length ? ` (${selectedUserIds.length})` : ""}
        </Button>,
      ]}
    >
      <Form {...form}>
        <UsersDropdownInput
          id="user_ids"
          name="user_ids"
          label="Users"
          isMulti
          isFluid
          isRequired
          description="Choose the users you would like to add to the group. Users already in the group are excluded."
          excludeUserIds={currentMemberIds}
        />
      </Form>
    </Dialog>
  );
};
