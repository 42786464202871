import { List, Map } from "immutable";

/**
 * Determines if a value is mergeable by checking if it:
 * - Is not null/undefined
 * - Is an object
 * - Has a mergeWith function, only true when an Immutable Map/List
 * - Is not an Immutable List
 *
 * @param {any} value - The value to check
 * @returns {boolean} True if the value is mergeable, false otherwise
 * @private
 */
const isMergeable = (value: any) =>
  Boolean(value) &&
  typeof value === "object" &&
  typeof value.mergeWith === "function" &&
  !List.isList(value);

/**
 * Deep merges two Immutable Maps with special handling for Lists.
 * An alternative to Immutable's native `mergeDeep` which overwrites lists with the same key instead of concatenating them.
 * When merging, it:
 * - Overwrites Lists entirely rather than merging them
 * - Preserves deep nested structures
 * - Handles null values correctly
 * - Merges objects recursively
 *
 * @example
 * ```typescript
 * const a = Map({ nested: Map({ list: List([1, 2]) }) });
 * const b = Map({ nested: Map({ list: List([3]) }) });
 * mergeDeepOverwriteLists(a, b);
 * Returns: Map({ nested: Map({ list: List([3]) }) })
 * ```
 *
 * @param {Map<string, any>} a - Source Immutable Map
 * @param {Map<string, any>} b - Target Immutable Map to merge into source, overrides values in a when matching keys
 * @returns {Map<string, any> | null} Merged Immutable Map or null if target is null
 */
export const mergeDeepOverwriteLists = (
  a: Map<string, any>,
  b: Map<string, any>
) => {
  // If b is null, it would overwrite a, even if a is mergeable
  if (b === null) return b;

  if (isMergeable(a) && !List.isList(a)) {
    return a.mergeWith(mergeDeepOverwriteLists, b);
  }

  return b;
};
