import { RecordValueConfigurationContext } from "#batteries-included-components/Layouts/RecordConfiguration/RecordConfigurationContext";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import {
  DropdownInput,
  MetricTile,
  Panel,
} from "@validereinc/common-components";
import { AggregationFunctionType } from "@validereinc/domain";
import { formatNumber } from "@validereinc/utilities";
import React, { useContext, useMemo } from "react";

export const FunctionSelector = ({
  values,
  isLoading,
}: {
  values: number[];
  isLoading?: boolean;
}) => {
  const { measurementType, form, record } =
    useContext(RecordValueConfigurationContext) || {};
  const { getUnitName } = useMeasurementTypes();

  const initialAggregateFunction = record?.values.find(
    (v) => v.measurement_type === measurementType?.id
  )?.configuration.aggregate_function;

  const aggregateFunction =
    form?.watch("aggregate_function") ?? AggregationFunctionType.SUM;

  const value = useMemo(() => {
    const totalValue = values.reduce((a, b) => a + b, 0);
    return aggregateFunction === AggregationFunctionType.AVERAGE
      ? totalValue
        ? totalValue / values.length
        : 0
      : totalValue;
  }, [values, aggregateFunction]);

  return (
    <Panel
      title={measurementType?.name}
      isFluidY={false}
    >
      <DropdownInput
        label="Function"
        name="aggregate_function"
        options={[
          {
            label: "Sum",
            value: AggregationFunctionType.SUM,
          },
          {
            label: "Average",
            value: AggregationFunctionType.AVERAGE,
          },
        ]}
        labelKey="label"
        valueKey="value"
        isRequired
        isClearable={false}
        value={initialAggregateFunction}
      />
      <MetricTile
        title="Preview"
        value={formatNumber(value) ?? "-"}
        unit={getUnitName(form?.watch("measurement_unit"), value)}
        isLoading={isLoading}
      />
    </Panel>
  );
};
