import useDeviceTypes from "#components/hooks/useDeviceTypes";
import useMeasurementTypes from "#components/hooks/useMeasurementTypes";
import { useNavigate } from "#routers/hooks";
import { linkToDeviceDetail } from "#routers/links";
import { CreateDeviceForm } from "#src/batteries-included-components/Forms/Devices/Create";
import useLocalization from "#src/hooks/useLocalization";
import { DevicesListRoutePath } from "#src/routes/organization/devices";
import { DevicesCreateRoutePath } from "#src/routes/organization/devices/create";
import { useBreadcrumbsFromRoute } from "#src/utils/route";
import {
  Button,
  Page,
  useForm,
  useToast,
} from "@validereinc/common-components";
import { Device, DeviceStatus, MeasurementTypeType } from "@validereinc/domain";
import isNil from "lodash/isNil";
import pick from "lodash/pick";
import React, { useEffect } from "react";

const DEFAULT_INPUTS = {
  status: DeviceStatus.ACTIVE,
};

export const CreateDevicePage = () => {
  const { localize } = useLocalization();
  const [breadcrumbs] = useBreadcrumbsFromRoute(DevicesCreateRoutePath);
  const navigate = useNavigate();
  const [deviceTypes, deviceTypesLoadingState] = useDeviceTypes();
  const [measurementTypes, measurementTypesLoadingState] =
    useMeasurementTypes();
  const { toast } = useToast();
  const form = useForm({
    defaultValues: DEFAULT_INPUTS,
  });

  const handleSubmit = async (formValues: any) => {
    const locationData = formValues?.attribute?.location;

    if (
      (isNil(locationData?.[0]) || locationData?.[0] === "") &&
      (isNil(locationData?.[1]) || locationData?.[1] === "")
    ) {
      formValues.attribute.location = null;
    }

    try {
      let body = {
        ...formValues,
        type_id: formValues.type.id,
        status: formValues.status.toLowerCase(),
        measurement_types: formValues.measurementTypes.map(
          ({ id }: MeasurementTypeType) => id
        ),
        attributes: formValues.attribute,
      };

      body = pick(body, [
        "facility_id",
        "name",
        "type_id",
        "status",
        "measurement_types",
        "attributes",
        "custom_attributes",
      ]);

      const { data } = await Device.createOne({ data: body });
      toast.push({
        intent: "success",
        description: `Successfully created new ${formValues.type.name} "${data.name}".`,
      });

      navigate({ pathname: linkToDeviceDetail(data.id) });
    } catch {
      toast.push({
        intent: "error",
        description: `Unable to create ${formValues.type.name} "${formValues.name}".`,
      });
    }
  };

  const handleCancelClick = () => {
    navigate(DevicesListRoutePath.toLinkParts());
  };

  // Default to select emissions monitor since we only have 1 device
  useEffect(() => {
    const emissionsMonitor = deviceTypes.find(
      ({ id }) => id === "emissions_monitor"
    );

    if (emissionsMonitor) {
      form.reset({ ...DEFAULT_INPUTS, type: emissionsMonitor });
    }
  }, [deviceTypes]);

  const isLoaded =
    deviceTypesLoadingState === "loaded" &&
    measurementTypesLoadingState === "loaded";

  const footer = (
    <>
      <Button
        onClick={handleCancelClick}
        disabled={!isLoaded}
      >
        Cancel
      </Button>

      <Button
        variant="primary"
        onClick={() => form.handleSubmit(handleSubmit)()}
        disabled={!isLoaded || form.formState.isSubmitting}
      >
        Create
      </Button>
    </>
  );

  return (
    <Page
      title={localize(DevicesCreateRoutePath.title)}
      category={localize(DevicesCreateRoutePath.previous?.title ?? "Device")}
      breadcrumbs={breadcrumbs}
      footer={footer}
      footerAlign="space-between"
    >
      <CreateDeviceForm
        form={form}
        formType="create"
        deviceTypes={deviceTypes}
        deviceTypesLoadingState={deviceTypesLoadingState}
        measurementTypes={measurementTypes}
        measurementTypesLoadingState={measurementTypesLoadingState}
        handleSubmit={handleSubmit}
      />
    </Page>
  );
};
