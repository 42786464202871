import { getComponentShortForm } from "#utils/stringFormatter";
import {
  LegacyDataTable,
  Tooltip,
  useToast,
} from "@validereinc/common-components";
import axios from "axios";
import max from "lodash/max";
import min from "lodash/min";
import uniq from "lodash/uniq";
import * as PropTypes from "prop-types";
import React, { useMemo } from "react";
import FontAwesome from "react-fontawesome";
import { AutoSizer } from "react-virtualized";
import { dateRenderer } from "../Instrument/InstrumentHelper";
import { MeasurementType } from "../Redux/reducers/measurements";
import SampleService from "../Services/SampleService";
import "./AnalyzeTable.scss";

const AnalyzeTable = (props) => {
  const { toast } = useToast();
  /**
   * A sample can have many measurements taken from it (e.g. Density, Vapour Pressure ).
   * These measurements are represented as different points in submeasurements.graphData
   * but combined into one sample for AnalyzeTable. Each sample in props.samples does not
   * store every measurement, only `average_value` and `submeasurement` which represents
   * the value of the primary and first submeasurement. This is not enough when there are
   * more than 2 measurements. Thus use the graphData to store the submeasurement value
   * by its sample id then by measurement type name e.g.
   * { sid-1234: { Density: 856.78, Water: 0.13 }, sid-5678: { Density: 900 } }.
   */
  const submeasurementsMap = useMemo(() => {
    const submeasurements = {};

    if (props.submeasurements?.length) {
      props.submeasurements.forEach((submeasurement) => {
        submeasurement?.graphDataSeries?.forEach((graphData) => {
          const id = graphData?.sample?.id;
          // take the average value or the first data point, like AnalyzeGraph hint
          const value =
            graphData?.sample?.average_value ??
            graphData?.sample?.data_points?.[0]?.originalValue;
          const submeasurementType = graphData?.sample?.stream?.measurementType;

          if (id && value && submeasurementType) {
            submeasurements[id] = submeasurements[id]
              ? { ...submeasurements[id], [submeasurementType]: value }
              : { [submeasurementType]: value };
          }
        });
      });
    }

    return submeasurements;
  }, [props.submeasurements]);

  const labelRenderer = (rowData) => {
    const style = { color: [rowData.stream.color] };
    let Label = null;

    switch (rowData.type) {
      case "spot":
      case "unknown":
      case "Crude Monitor":
        Label = (
          <FontAwesome
            name="circle"
            style={style}
          />
        );
        break;

      case "composite":
        Label = (
          <FontAwesome
            name="minus"
            style={style}
          />
        );
        break;

      case "inline":
        Label = (
          <FontAwesome
            name="circle-o"
            style={style}
          />
        );
        break;

      case "virtual":
        Label = (
          <div
            className="analyzeTable__circle"
            style={{ background: [rowData.stream.color] }}
          >
            V
          </div>
        );
        break;
    }

    return (
      <div className="analyzeTable__label capitalized">
        {Label} {rowData.type}
      </div>
    );
  };

  const instrumentNameRenderer = (rowData, columnKey) => {
    const instrumentNames = rowData[columnKey];

    if (instrumentNames.length) {
      const tooltip = (
        <div className="analyzeTable__tooltipContent">
          <ul>
            {instrumentNames.map((name) => (
              <li key={name}>{name}</li>
            ))}
          </ul>
        </div>
      );
      return (
        <Tooltip
          content={tooltip}
          trigger="click"
        >
          <div className="clipText">{instrumentNames.join(", ")}</div>
        </Tooltip>
      );
    }
    return "-";
  };

  const valueRenderer = (sample, measurementName) => {
    const unit = props.getMeasurementUnit(measurementName);
    let value;

    if (measurementName === props.measurementType?.name) {
      value = sample.average_value;
    } else {
      value = submeasurementsMap[sample.id]?.[measurementName] ?? null;
    }

    return value
      ? `${props.getFormattedMeasurementValue(measurementName, value)} ${unit}`
      : "-";
  };

  const getValueHeaders = () => {
    return (
      props.measurementTypeListNames?.map((measurementName) => ({
        label: getComponentShortForm(measurementName),
        key: measurementName,
        cellRenderer: valueRenderer,
        width: 150,
        rightAlign: true,
      })) ?? []
    );
  };

  const onCellClick = (sample) => {
    if (sample.type !== "inline") {
      props.onSampleModalToggle(sample);
    }
  };

  const csvDownload = (samples) => {
    if (samples.length === 0) {
      return toast.push({
        intent: "warning",
        description: "No data to export.",
      });
    }

    const streams = uniq(samples.map((sample) => sample.stream.id));
    const sampleTypes = uniq(samples.map((sample) => sample.type));
    const instrumentTypes = uniq(
      samples.flatMap((sample) => sample.source_types)
    );

    const measurementTypes = props.submeasurementType
      ? [props.measurementType.name, props.submeasurementType.name]
      : [props.measurementType.name];

    const sampleDates = samples.flatMap((sample) => [
      new Date(sample.start_date),
      new Date(sample.end_date),
    ]);

    const startDate = min(sampleDates);
    const endDate = max(sampleDates);

    SampleService.getAnalyzeCSVDownloadLink(
      streams,
      sampleTypes,
      instrumentTypes,
      measurementTypes,
      startDate,
      endDate
    ).then(({ data }) => {
      axios
        .get(data.url)
        .then(() => {
          toast.push({
            intent: "success",
            description:
              "Your Analyze CSV Export will be sent to your email shortly.",
          });
        })
        .catch(() => {
          toast.push({
            intent: "warning",
            description:
              "Failed to generate the report. Please try again later.",
          });
        });
    });
  };

  const highlightSelected = (samples) => {
    return (
      samples.findIndex(
        (sample) => sample.id === props.highlightedSample?.id
      ) ?? -1
    );
  };

  const getRowClassName = (sample) => {
    if (sample.type === "inline") {
      return "analyzeTable__inlineRow";
    }
  };

  const headers = [
    {
      label: "Sample Type",
      key: "label",
      width: 150,
      cellRenderer: labelRenderer,
      fixed: true,
    },
    {
      label: "Sample Start Date",
      key: "start_date",
      width: 180,
      cellRenderer: dateRenderer,
    },
    {
      label: "Sample End Date",
      key: "end_date",
      width: 180,
      cellRenderer: dateRenderer,
    },
    {
      label: "Source Type",
      key: "source_types",
      width: 125,
    },

    ...getValueHeaders(),

    {
      label: "Instrument Names",
      key: "instrument_names",
      cellRenderer: instrumentNameRenderer,
      width: 300,
    },
  ];

  return (
    <div className="analyzeTable">
      <AutoSizer disableHeight>
        {({ width }) => (
          <LegacyDataTable
            width={width}
            height={350}
            headers={headers}
            list={props.samples}
            defaultSortBy="start_date"
            defaultSortDirection="asc"
            csvDownload={csvDownload}
            onCellClick={onCellClick}
            highlightSelected={highlightSelected}
            highlightRow={true}
            getRowClassName={getRowClassName}
          />
        )}
      </AutoSizer>
    </div>
  );
};

AnalyzeTable.propTypes = {
  measurementType: PropTypes.instanceOf(MeasurementType).isRequired,
  submeasurementType: PropTypes.instanceOf(MeasurementType),
  highlightedSample: PropTypes.object,
  samples: PropTypes.array,
  onSampleModalToggle: PropTypes.func.isRequired,
  measurementTypeListNames: PropTypes.array,
  getFormattedMeasurementValue: PropTypes.func.isRequired,
  getMeasurementUnit: PropTypes.func.isRequired,
  submeasurements: PropTypes.array,
};

export default AnalyzeTable;
