import { EstimationMethodsTab } from "#batteries-included-components/Tabs/EstimationMethod/ListTab";
import { useNavigate, useParams } from "#routers/hooks";
import { FlowCreateEstimationMethodRoute } from "#routes/organization/flows/[flowId]/detail/create-estimation-method";
import { FlowDetailParamsType } from "#routes/organization/flows/[flowId]/detail/index";
import { useGetOneFlow } from "#src/components/hooks/adapters/useFlows";
import { Tab } from "@validereinc/common-components";
import { AssetType } from "@validereinc/domain";
import React from "react";

export const FlowEstimationMethodsTab = () => {
  const navigate = useNavigate();
  const { flowId } = useParams<FlowDetailParamsType>();
  const { data: flow } = useGetOneFlow({ flowId });

  return (
    <Tab
      title="Estimation Methods"
      tabKey="estimation_methods"
    >
      {flow ? (
        <EstimationMethodsTab
          isWithinEntityDetail
          entityId={flow?.id}
          entityType={AssetType.FLOW}
          onAdd={() =>
            navigate(
              FlowCreateEstimationMethodRoute.toLinkParts({
                pathParams: { flowId },
              })
            )
          }
        />
      ) : null}
    </Tab>
  );
};
