import { EstimationMethodEditDialog } from "#batteries-included-components/Dialogs/EstimationMethodEditDialog";
import { EstimationMethodCalculationsTab } from "#batteries-included-components/Tabs/EstimationMethod/CalculationsTab";
import { ConfigurationTab } from "#batteries-included-components/Tabs/EstimationMethod/ConfigurationTab/ConfigurationTab";
import { EstimationMethodOverviewTab } from "#batteries-included-components/Tabs/EstimationMethod/OverviewTab";
import { EstimationMethodRecordsTab } from "#batteries-included-components/Tabs/EstimationMethod/RecordsTab";
import { MUTATION_STATUS } from "#constants";
import { useDeleteEstimationMethod } from "#hooks/useEstimationMethod";
import { useNavigate, useParams, useSearchParams } from "#routers/hooks";
import { FlowDetailRoute } from "#routes/organization/flows/[flowId]/detail";
import {
  FlowEstimationMethodDetailParamsType,
  FlowEstimationMethodDetailRoute,
} from "#routes/organization/flows/[flowId]/detail/estimation-details/[estimationMethodId]/index";
import { FlowEstimationMethodResultDetailRoute } from "#routes/organization/flows/[flowId]/detail/estimation-details/[estimationMethodId]/result/[yearMonth]";
import type { EstimationMethodDetailViewFilterType } from "#src/batteries-included-components/FilterAreas/EstimationMethodsFilterAreas/EstimationMethodsFilterAreas";
import { useGetOneFlow } from "#src/components/hooks/adapters/useFlows";
import { useActionDetails } from "#src/components/hooks/useActionDetails";
import { DEFAULT_DATE_RANGES } from "#src/hooks/useDateRange";
import useLocalization from "#src/hooks/useLocalization";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { hydrateDateRange } from "#src/utils/date";
import { useBreadcrumbsFromRoute } from "#utils/route";
import { useQuery } from "@tanstack/react-query";
import {
  Button,
  Dialog,
  Link,
  Page,
  Pill,
  Tab,
} from "@validereinc/common-components";
import {
  AssetType,
  CalculatorDomain,
  EstimationMethodDomain,
  ReportingGroupDomain,
  Resources,
} from "@validereinc/domain";
import { getYearMonthFromDateRange } from "@validereinc/utilities";
import React, { useEffect, useState } from "react";

export const FlowEstimationMethodDetailPage = () => {
  const { estimationMethodId, flowId } =
    useParams<FlowEstimationMethodDetailParamsType>();
  const { localize } = useLocalization();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { viewConfigStorageKey } = useStorageKey("estimation-method-overview");
  const [viewFilters] =
    useSessionStickyState<EstimationMethodDetailViewFilterType>(
      {
        month: {},
      },
      viewConfigStorageKey
    );

  const period = getYearMonthFromDateRange(
    viewFilters.month?.from && viewFilters.month?.to
      ? hydrateDateRange(viewFilters.month)
      : DEFAULT_DATE_RANGES.currentWholeMonth
  );

  const methodDetailsQuery = useQuery({
    queryKey: [
      Resources.ESTIMATION_METHOD,
      estimationMethodId,
      { assetType: AssetType.FLOW, period },
    ],
    queryFn: () => {
      return EstimationMethodDomain.getOne({
        id: estimationMethodId,
        meta: {
          entityType: AssetType.FLOW,
        },
        ...(period
          ? {
              data: {
                period,
              },
            }
          : {}),
      });
    },
    enabled: !!estimationMethodId,
  });
  const calculatorDetailsQuery = useQuery({
    queryKey: [
      Resources.CALCULATOR,
      methodDetailsQuery.data?.analytics_calculator_id,
    ],
    queryFn: () => {
      if (!methodDetailsQuery.data?.analytics_calculator_id) return null;

      return CalculatorDomain.getCalculator({
        calculatorId: methodDetailsQuery.data.analytics_calculator_id,
      });
    },
    enabled: !!methodDetailsQuery.data?.analytics_calculator_id,
  });

  const { data: flow } = useGetOneFlow({
    flowId,
    period,
  });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteEstimationMethod, deleteEstimationMethodStatus] =
    useDeleteEstimationMethod();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const reportingGroupsQuery = useQuery({
    queryKey: ["reportingGroups"],
    queryFn: ReportingGroupDomain.getList,
  });

  const reportingGroups = reportingGroupsQuery?.data?.data ?? [];

  const methodDetail = methodDetailsQuery.data;
  const calculatorDetail = calculatorDetailsQuery.data;
  const createInfo = useActionDetails({
    type: "create",
    date: methodDetail?.created_at,
    byUserId: methodDetail?.created_by,
  });

  const editInfo = useActionDetails({
    type: "edit",
    date: methodDetail?.updated_at,
    byUserId: methodDetail?.updated_by,
  });
  const [breadcrumbs] = useBreadcrumbsFromRoute(
    FlowEstimationMethodDetailRoute,
    {
      detail: { title: flow?.name },
      estimationMethodId: { title: methodDetail?.name },
    }
  );

  const handleDelete = () => {
    if (!methodDetail?.id) {
      return;
    }
    deleteEstimationMethod(methodDetail.id, AssetType.FLOW);
    navigate(
      FlowDetailRoute.toLinkParts({
        pathParams: { flowId },
        queryParams: { tab: "estimation_methods" },
      })
    );
  };
  const hasReportingGroups =
    methodDetail?.reporting_groups && methodDetail.reporting_groups.length > 0;
  const calculatorVersion = calculatorDetailsQuery.data?.versions.find(
    ({ version }) => version === calculatorDetailsQuery.data?.default_version
  );

  const detailsPanelData = [
    {
      title: "Created Date",
      value: createInfo.formattedDate,
    },
    {
      title: "Created By",
      value: createInfo.userName,
    },
    {
      title: "Reporting Scenarios",
      value: !hasReportingGroups ? null : (
        <>
          {methodDetail?.reporting_groups?.map((group) => (
            <Pill
              isCapitalized={false}
              key={group.name}
              variant="primary"
              isBordered
              hasDot={false}
            >
              {group.name.trim()}
            </Pill>
          ))}
        </>
      ),
      description: hasReportingGroups
        ? undefined
        : "Edit this estimation method to associate reporting scenarios",
    },
    {
      title: `${localize("Flow")}`,
      value: (
        <Link
          onClick={() => {
            navigate(
              FlowDetailRoute.toLinkParts({
                pathParams: { flowId },
              })
            );
          }}
          label={flow?.name ?? "-"}
        />
      ),
    },
    {
      title: "Library",
      value: methodDetail?.analytics_library_id ?? calculatorDetail?.library,
    },
    {
      title: "Calculator Name",
      value: calculatorVersion?.title,
    },
  ];

  const pageActionRow = (
    <>
      <Button
        variant="error-outline"
        onClick={() => setIsDeleteModalOpen(true)}
        isLoading={deleteEstimationMethodStatus === MUTATION_STATUS.LOADING}
        disabled={!methodDetail?.id || !methodDetail?.name}
      >
        Delete
      </Button>
      <Button
        variant="outline"
        onClick={() => setIsEditDialogOpen(true)}
        isLoading={deleteEstimationMethodStatus === MUTATION_STATUS.LOADING}
        disabled={!methodDetail?.id || !methodDetail?.name}
      >
        Edit
      </Button>
    </>
  );

  const onActiveTabKeyChange = (tabKey: string) => {
    setSearchParams({ ...searchParams, tab: tabKey });
  };

  const onSavedResultRecordClick = (
    estimationMethodId: string,
    flowId: string,
    yearMonth: string
  ) =>
    navigate(
      FlowEstimationMethodResultDetailRoute.toLinkParts({
        pathParams: {
          flowId,
          estimationMethodId,
          yearMonth,
        },
      })
    );

  useEffect(() => {
    if (!searchParams?.tab) {
      onActiveTabKeyChange("overview");
    }
  }, []);

  return (
    <>
      <Page
        category="Estimation Method"
        title={
          methodDetail ? (
            <>
              {methodDetail?.name}&nbsp;
              <Pill
                variant={
                  methodDetail.status === "active" ? "success" : "default"
                }
                hasDot={false}
              >
                {methodDetail?.status}
              </Pill>
            </>
          ) : (
            ""
          )
        }
        isLoading={!flow || !methodDetail}
        breadcrumbs={breadcrumbs}
        activeTabKey={searchParams?.tab}
        onActiveTabKeyChange={onActiveTabKeyChange}
        actionRow={pageActionRow}
        renderMeta={
          !editInfo.description
            ? undefined
            : ({ MetaSegments }) => (
                <MetaSegments values={[editInfo.description]} />
              )
        }
      >
        <Tab
          tabKey="overview"
          title="Overview"
        >
          <EstimationMethodOverviewTab
            detailsPanelProps={{
              data: detailsPanelData,
              isLoading: false,
            }}
            assetType={AssetType.FLOW}
            methodId={estimationMethodId}
            period={period}
          />
        </Tab>

        <Tab
          tabKey="configuration"
          title="Configuration"
        >
          <ConfigurationTab
            viewConfigStorageKey={viewConfigStorageKey}
            methodId={estimationMethodId}
            assetType={AssetType.FLOW}
            period={period}
          />
        </Tab>

        <Tab
          tabKey="calculations"
          title="Calculations"
        >
          <EstimationMethodCalculationsTab
            methodId={estimationMethodId}
            assetId={flowId}
            assetType={AssetType.FLOW}
            onClickSavedResultRecord={onSavedResultRecordClick}
          />
        </Tab>

        <Tab
          tabKey="results"
          title="Results"
        >
          <EstimationMethodRecordsTab
            methodId={estimationMethodId}
            assetId={flowId}
            assetType={AssetType.FLOW}
            onClickRow={onSavedResultRecordClick}
          />
        </Tab>
      </Page>
      <Dialog
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title={`Delete "${methodDetail?.name ?? "this estimation method"}"`}
        actionRow={[
          <Button
            key="delete-dialog-action"
            onClick={handleDelete}
            variant="error"
          >
            Delete
          </Button>,
        ]}
      >
        <div>
          Are you sure you want to delete this estimation method? This action
          cannot be undone.
        </div>
      </Dialog>

      <EstimationMethodEditDialog
        reportingGroups={reportingGroups}
        method={methodDetail}
        entityType={AssetType.FLOW}
        onClose={() => setIsEditDialogOpen(false)}
        onSubmit={() => methodDetailsQuery.refetch()}
        isEditDialogOpen={isEditDialogOpen}
      />
    </>
  );
};
