import { CustomAttributeRoute } from "#routes/settings/custom-attributes";
import { useNavigate } from "#src/Routers/hooks";
import { AccountChip } from "#src/batteries-included-components/Layouts/Authentication/AccountChip";
import config from "#src/config";
import {
  useAuthenticatedContext,
  useIsFeatureAvailable,
} from "#src/contexts/AuthenticatedContext.helpers";
import { useOsano } from "#src/hooks/useOsano";
import { linkToActivityLogs } from "#src/routes/change-log";
import { linkToScenarioList } from "#src/routes/scenarios";
import { linkToMeasurementTypesSettingsPage } from "#src/routes/settings/measurement-types/index";
import { linkToRolesListPage } from "#src/routes/settings/roles-and-permissions";
import { linkToUsersListPage } from "#src/routes/settings/users";
import { linkToUserDetailPage } from "#src/routes/settings/users/detail";
import { linkToUserEditPage } from "#src/routes/settings/users/edit";
import {
  Button,
  Dialog,
  DropdownMenu,
  DropdownMenuOption,
  Icon,
  Link,
  Popover,
  Tag,
  Tooltip,
  colours,
  useToast,
} from "@validereinc/common-components";
import { copyTextToClipboard } from "@validereinc/utilities";
import classNames from "classnames/bind";
import React, { useState } from "react";
import { CompanySelector } from "../CompanySelector/CompanySelector";
import styles from "./AccountNavBar.module.scss";

const cx = classNames.bind(styles);

const bannerColours = [
  colours.colours.chartsHover["100"],
  colours.colours.chartsHover["400"],
  colours.colours.chartsHover["300"],
  colours.colours.chartsHover["200"],
];

export const LegacyAccountNavBarSettings = {
  ACCOUNT: "account",
  COMPANY: "company",
  HELP: "help",
  DEFAULT: "settings",
} as const;

export const AccountNavBarSettings = {
  USERS: "users",
  ROLES_AND_PERMISSIONS: "roles-and-permissions",
  CHANGE_LOG: "change-log",
  KNOWLEDGE_BASE: "knowledge-base",
  CONTACT_SUPPORT: "contact-support",
  MEASUREMENT_TYPES: "measurement-types",
  CUSTOM_ATTRIBUTES: "custom-attributes",
} as const;

export const AccountNavBar = () => {
  const {
    appVersion,
    activeAuthVersion,
    logout,
    sentryFeedbackInstance,
    v1: { user: legacyUser },
    v2: {
      userInfo: { user },
      companyInfo: { company },
    },
  } = useAuthenticatedContext();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [isSupportModalOpen, setIsSupportModalOpen] = useState(false);
  const [isUserRolesPermsAvailable] = useIsFeatureAvailable({
    featureFlagQuery: "core:roles_permissions",
  });
  const [isActivityLogsAvailable] = useIsFeatureAvailable({
    featureFlagQuery: "core:activity_logs",
    permissionQuery: "activities:read_all",
  });
  const userCompanies = user?.companies ?? [];
  const currentCompanyIndex = userCompanies.findIndex(
    (userCompany) => userCompany.id === company?.id
  );
  const { isEnabled, showDrawer } = useOsano();

  const getSettingsOptions = (): DropdownMenuOption[] => {
    const applicationOptions: DropdownMenuOption[] = [
      {
        label: "Application",
        isGroup: true,
        options: [
          {
            label: "Release Notes",
            onClick: () => window.open(config.RELEASE_NOTE_URL, "__blank"),
            slotLeft: ({ iconProps }) => (
              <Icon
                variant="package"
                {...iconProps}
              />
            ),
          },
          {
            label: "Public API",
            onClick: () => window.open(config.API_DOCS_URL, "__blank"),
            slotLeft: ({ iconProps }) => (
              <Icon
                variant="code"
                {...iconProps}
              />
            ),
          },
          {
            label: "Terms & Conditions",
            onClick: () => window.open(config.TERMS_OF_SERVICE_URL, "__blank"),
            slotLeft: ({ iconProps }) => (
              <Icon
                variant="file-text"
                {...iconProps}
              />
            ),
          },
          ...(isEnabled
            ? [
                {
                  label: "Cookie Settings",
                  onClick: () => showDrawer(),
                  slotLeft: ({ iconProps }) => (
                    <Icon
                      variant="cookie"
                      {...iconProps}
                    />
                  ),
                },
              ]
            : []),
          {
            label: "Privacy Policy",
            onClick: () => window.open(config.PRIVACY_POLICY_URL, "__blank"),
            slotLeft: ({ iconProps }) => (
              <Icon
                variant="file-text"
                {...iconProps}
              />
            ),
          },
        ],
      },
      {
        label: "Version",
        isPresentational: true,
        flair: appVersion,
      },
    ];

    if (!isUserRolesPermsAvailable && !isActivityLogsAvailable) {
      return applicationOptions;
    }

    return [
      {
        label: "Company",
        isGroup: true,
        options: [
          ...(isActivityLogsAvailable
            ? ([
                {
                  label: "Change Log",
                  onClick: () => navigate({ pathname: linkToActivityLogs() }),
                  slotLeft: ({ iconProps }) => (
                    <Icon
                      variant="clock-counter-clockwise"
                      {...iconProps}
                    />
                  ),
                },
              ] satisfies DropdownMenuOption[])
            : []),
          ...(isUserRolesPermsAvailable
            ? ([
                {
                  label: "Users",
                  onClick: () => navigate({ pathname: linkToUsersListPage() }),
                  slotLeft: ({ iconProps }) => (
                    <Icon
                      variant="users-three"
                      {...iconProps}
                    />
                  ),
                },
                {
                  label: "Roles & Permissions",
                  onClick: () => navigate({ pathname: linkToRolesListPage() }),
                  slotLeft: ({ iconProps }) => (
                    <Icon
                      variant="seal-check"
                      {...iconProps}
                    />
                  ),
                },
                {
                  label: "Measurement Types",
                  onClick: () =>
                    navigate({
                      pathname: linkToMeasurementTypesSettingsPage(),
                    }),
                  slotLeft: ({ iconProps }) => (
                    <Icon
                      variant="hourglass"
                      {...iconProps}
                    />
                  ),
                },
                {
                  label: "Custom Attributes",
                  onClick: () =>
                    navigate({
                      pathname: CustomAttributeRoute.path,
                    }),
                  slotLeft: ({ iconProps }) => (
                    <Icon
                      variant="database"
                      {...iconProps}
                    />
                  ),
                },
                {
                  label: "Scenarios",
                  onClick: () =>
                    navigate({
                      pathname: linkToScenarioList(),
                    }),
                  slotLeft: ({ iconProps }) => (
                    <Icon
                      variant="chart-bar"
                      {...iconProps}
                    />
                  ),
                },
              ] satisfies DropdownMenuOption[])
            : []),
        ],
      },
      ...applicationOptions,
    ];
  };

  const accountName =
    activeAuthVersion !== "unknown" &&
    activeAuthVersion.length === 1 &&
    activeAuthVersion.includes("v1")
      ? legacyUser?.name
      : user?.name;
  const accountEmail =
    activeAuthVersion !== "unknown" &&
    activeAuthVersion.length === 1 &&
    activeAuthVersion.includes("v1")
      ? legacyUser?.email
      : user?.email;
  const companyName =
    activeAuthVersion !== "unknown" &&
    activeAuthVersion.length === 1 &&
    activeAuthVersion.includes("v1")
      ? legacyUser?.company_name
      : company?.name;

  return (
    <nav className={cx("container")}>
      <div className={cx("settings-container")}>
        <div className={cx("org")}>
          {companyName ? (
            <DropdownMenu
              contentProps={{
                sideOffset: 8,
              }}
              options={[
                {
                  label: "Copy ID",
                  slotLeft: ({ iconProps }) => (
                    <Icon
                      {...iconProps}
                      variant="copy-simple"
                    />
                  ),
                  onClick: () => {
                    if (!company?.id) return;

                    copyTextToClipboard(company.id).then(() =>
                      toast.push({
                        intent: "success",
                        description:
                          "Successfully copied company ID to your clipboard.",
                      })
                    );
                  },
                },
              ]}
            >
              <button className={cx("org-trigger")}>
                {companyName}
                <Icon
                  variant="caret-down"
                  size={16}
                  weight="bold"
                />
              </button>
            </DropdownMenu>
          ) : (
            <Tooltip
              title="You're not part of a company"
              content="Talk to our support team to configure a company for your account"
            >
              <div className={cx("text-with-icon", "warning")}>
                <Icon
                  variant="warning"
                  size={16}
                />
                No Company
              </div>
            </Tooltip>
          )}
        </div>
        <DropdownMenu
          options={[
            {
              label: "Knowledge Base",
              slotLeft: ({ iconProps }) => (
                <Icon
                  variant="lifebuoy"
                  {...iconProps}
                />
              ),
              onClick: () => window.open(config.KNOWLEDGE_BASE_URL, "__blank"),
            },
            {
              label: "Report a Bug",
              isDisabled: !sentryFeedbackInstance || !user,
              slotLeft: ({ iconProps }) => (
                <Icon
                  variant="megaphone"
                  {...iconProps}
                />
              ),
              onClick: () => {
                if (!sentryFeedbackInstance) return;

                sentryFeedbackInstance.createForm().then((f) => {
                  f.appendToDom();
                  f.open();
                });
              },
            },
            {
              label: "Contact Support",
              slotLeft: ({ iconProps }) => (
                <Icon
                  variant="headset"
                  {...iconProps}
                />
              ),
              onClick: () => setIsSupportModalOpen(true),
            },
          ]}
        >
          <Button
            iconProps={{ size: 18 }}
            icon="question"
            variant="no-outline"
            size="xx-small"
          />
        </DropdownMenu>
        <DropdownMenu options={getSettingsOptions()}>
          <Button
            iconProps={{ size: 18 }}
            icon="gear-six"
            variant="no-outline"
            size="xx-small"
          />
        </DropdownMenu>
        <Popover
          contentRender={() => (
            <div className={cx("account-menu-container")}>
              <div
                className={cx("account-menu-banner")}
                style={{
                  backgroundColor:
                    bannerColours[currentCompanyIndex % bannerColours.length],
                }}
              ></div>
              <div className={cx("account-menu-chip-container")}>
                <AccountChip
                  name={accountName}
                  isPresentational
                  className="account-menu-chip"
                />
              </div>
              <div className={cx("account-menu-user-information-container")}>
                {accountName ? (
                  <p className={cx("account-menu-account-name")}>
                    {accountName}
                  </p>
                ) : (
                  <p
                    className={cx("account-menu-account-name update-name")}
                    onClick={() =>
                      navigate({ pathname: linkToUserEditPage(user?.id) })
                    }
                  >
                    Update your name
                  </p>
                )}

                <p className={cx("account-menu-account-email")}>
                  {accountEmail}
                </p>
                <p className={cx("account-menu-account-organization")}>
                  {companyName}
                </p>
              </div>

              <div className={cx("account-menu-button-container")}>
                <Button
                  key="manage-account-action"
                  variant="outline"
                  className={cx("account-menu-account-button")}
                  onClick={() =>
                    navigate({
                      pathname: linkToUserDetailPage(user?.id),
                    })
                  }
                  slotLeft={<Icon variant="user-circle-gear" />}
                >
                  Manage Account
                </Button>
              </div>
              <CompanySelector currentCompanyIndex={currentCompanyIndex} />
              <div className={cx("account-log-out-button-container")}>
                <Button
                  key="log-out-action"
                  variant="outline"
                  className={cx("account-menu-account-button")}
                  onClick={logout}
                  slotLeft={
                    <Icon
                      variant="sign-out"
                      className="account-menu-organization-icon"
                    />
                  }
                >
                  Log out
                </Button>
              </div>
            </div>
          )}
          shouldDisplayArrow={false}
        >
          <AccountChip name={accountName} />
        </Popover>
      </div>
      <Dialog
        title="Contact our Support Team"
        isOpen={isSupportModalOpen}
        onClose={() => setIsSupportModalOpen(false)}
        actionRow={[
          <Button
            key="dismiss"
            variant="primary"
            onClick={() => setIsSupportModalOpen(false)}
          >
            Done
          </Button>,
        ]}
      >
        <>
          <p>
            You can reach us easiest by email. If possible please include a
            detailed explanation, relevant links, screenshots/video
            demonstrating the problem, replicable processes, and any other
            information that can assist you with your help request.
          </p>
          <ul className={cx("support-options")}>
            <Tag
              as="li"
              label="Email"
              value={
                <Link
                  anchorTagProps={{
                    href: `mailto:${
                      config.SUPPORT_EMAIL
                    }?subject=Need Product Help (app: Validere Private Web)${
                      companyName ? ` (company: ${companyName})` : ""
                    }${
                      accountEmail ? ` (account email: ${accountEmail})` : ""
                    }`,
                  }}
                  label={config.SUPPORT_EMAIL}
                />
              }
            />
          </ul>
        </>
      </Dialog>
    </nav>
  );
};
