import {
  Button,
  Dialog,
  Form,
  useForm,
  useToast,
} from "@validereinc/common-components";
import React, { useState } from "react";
import InstrumentService from "../../../Services/InstrumentService";
import EditInstrumentDetailsForm from "./EditInstrumentDetailsForm";

export const EditInstrumentDetailsModal = (props: {
  show: boolean;
  serialNumber?: string;
  availableSerialNumbers?: any[];
  instrumentId: string;
  instrumentName: string;
  onHide: () => void;
  refetchCalibrationData: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();

  const createOption = (label) => ({
    label,
    value: label,
  });

  const [serialNumberOptions, setSerialNumberOptions] = useState(
    props.availableSerialNumbers?.map((option) => createOption(option))
  );

  const formMethods = useForm({
    defaultValues: {
      name: props.instrumentName,
      serialNumber: props.serialNumber
        ? createOption(props.serialNumber)
        : props.serialNumber,
    },
  });

  const handleCreateOption = (inputValue) => {
    const newOption = createOption(inputValue);
    formMethods.setValue("serialNumber", newOption);
    setSerialNumberOptions([...serialNumberOptions, newOption]);
  };

  const handleSubmit = (formState) => {
    setIsLoading(true);

    InstrumentService.updateDetails(
      props.instrumentId,
      formState.name,
      formState.serialNumber?.value
    )
      .then(() => {
        props.refetchCalibrationData();
        toast.push({
          intent: "success",
          description: "Instrument details updated successfully.",
        });
        props.onHide();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Dialog
      isOpen={props.show}
      onClose={props.onHide}
      title={props.instrumentName}
      actionRow={[
        <Button
          key="submit"
          variant="primary"
          type="submit"
          isLoading={isLoading}
          onClick={formMethods.handleSubmit(handleSubmit)}
        >
          Update Instrument Details
        </Button>,
      ]}
    >
      <Form
        onSubmit={handleSubmit}
        {...formMethods}
      >
        <EditInstrumentDetailsForm
          availableSerialNumbers={serialNumberOptions}
          handleCreateOption={handleCreateOption}
          isLoading={isLoading}
          formMethods={formMethods}
        />
      </Form>
    </Dialog>
  );
};

export default EditInstrumentDetailsModal;
