import { getFormSchemaId } from "#src/batteries-included-components/Drawers/Workflows/WorkflowTaskDetailDrawer/WorkflowTaskDetailDrawer.helpers";
import { useGetOneFormSchema } from "#src/components/hooks/adapters/useFormSchema";
import { useListUserGroups } from "#src/components/hooks/adapters/useUserGroups";
import { useGetOneWorkflow } from "#src/components/hooks/adapters/useWorkflows";
import {
  useGetOneWorkflowTask,
  useListWorkflowActions,
} from "#src/components/hooks/adapters/useWorkflowTasks";
import {
  useCheckCommentPermissions,
  useCommentsStore,
} from "#src/components/hooks/useComments";
import { useAuthenticatedContext } from "#src/contexts/AuthenticatedContext.helpers";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Accordion,
  Button,
  ButtonWithPopover,
  Drawer,
  Icon,
  Pill,
  Tooltip,
  useTimelinePanelStore,
  useToast,
  TimelinePanel,
  Skeleton,
  IconVariants,
} from "@validereinc/common-components";
import {
  FormSubmissionStatus,
  FormSubmissionStatusType,
  FormSubmissionTaskType,
  isUserTask,
  Resources,
  WorkflowStepType,
  WorkflowTaskAdapter,
  WorkflowTaskStatus,
  WorkflowTaskType,
  WorkflowTaskTypes,
  WorkflowTaskTypesType,
} from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import TaskCommentsDrawer from "../TaskCommentsDrawer/TaskCommentsDrawer";
import styles from "./WorkflowTaskDetailDrawer.module.scss";
import { WorkflowTaskUserChoice } from "./WorkflowTaskUserChoice";
import upperFirst from "lodash/upperFirst";
import startCase from "lodash/startCase";
import { toStartCaseString } from "@validereinc/utilities";
import { WorkflowTaskStatusToPillVariantMap } from "#src/batteries-included-components/Panels/TablePanels/WorkflowsTablePanel/WorkflowsTablePanel.helpers";
import { WorkflowTaskDetailKeyValueList } from "./WorkflowTaskDetailKeyValueList";
import {
  getAttachmentTitle,
  ReportAttachmentHeader,
  WorkflowTaskAttachment,
} from "./WorkflowTaskAttachment";
import { WorkflowTaskFormSubmission } from "./WorkflowTaskFormSubmission";
import { WorkflowTaskCompleteEvent } from "./WorkflowTaskCompleteEvent";
import { getRelativeTimeDifference } from "#utils/date";

const { AccordionPanel } = Accordion;
const cx = classNames.bind(styles);

type WorkflowDetailsDrawerProps = {
  setSelectedTask: React.Dispatch<
    React.SetStateAction<WorkflowTaskType | null>
  >;
  selectedTask: WorkflowTaskType | null;
  onReassignTask?: (task: WorkflowTaskType) => void;
  taskStepIds: string[];
};

const TASK_COMMENTS_DRAWER_KEY = "task-comments";
const validCompletedFormStatus: FormSubmissionStatusType[] = [
  FormSubmissionStatus.PENDING,
  FormSubmissionStatus.SUBMITTED,
  FormSubmissionStatus.VALIDATED,
  FormSubmissionStatus.INVALIDATED,
];

const getTaskIcon = (type?: WorkflowTaskTypesType): IconVariants => {
  switch (type) {
    case WorkflowTaskTypes.CHOICE:
    case WorkflowTaskTypes.FORM_CHOICE:
      return "check-square-offset";
    case WorkflowTaskTypes.SUBMIT_FORM:
      return "clipboard-text";
    case WorkflowTaskTypes.COMPLETE_EVENT:
      return "plus-clock-counter-clockwise-offset";
    case WorkflowTaskTypes.MANUAL_TASK:
      return "wrench";
    default:
      return "validere";
  }
};

export const WorkflowTaskDetailDrawer = ({
  setSelectedTask,
  selectedTask,
  onReassignTask,
  taskStepIds,
}: WorkflowDetailsDrawerProps) => {
  const {
    v2: {
      userInfo: { user },
    },
  } = useAuthenticatedContext();
  const queryClient = useQueryClient();
  const { toast } = useToast();
  const {
    isOpen: isTaskCommentsDrawerOpen,
    setIsOpen: setIsTaskCommentsDrawerOpen,
    setTargetDrawer: setTimelinePanelTargetDrawer,
  } = useTimelinePanelStore();
  const { commentCounts } = useCommentsStore();
  const [selectedChoiceId, setSelectedChoiceId] = useState<string | null>(null);

  const workflowDetailsEnabled = Boolean(selectedTask?.workflow_id);
  const workflowId = selectedTask?.workflow_id ?? "";
  const workflowQuery = useGetOneWorkflow(
    { id: workflowId },
    { enabled: Boolean(workflowId) }
  );
  const workflowDetails = workflowQuery.data?.data;
  const workflowDetailsLoading = workflowQuery.isLoading;
  const { canReadComments, canReadAssetType } = useCheckCommentPermissions(
    workflowDetails?.asset?.asset_type
  );

  const filters: Parameters<typeof WorkflowTaskAdapter.getList>[0]["filters"] =
    {
      workflow_id: workflowId,
    };
  const allWorkflowTasksPayload: Parameters<
    typeof WorkflowTaskAdapter.getList
  >[0] = {
    filters,
  };
  const actionsQuery = useListWorkflowActions(allWorkflowTasksPayload, {
    enabled: !!workflowId,
  });

  const taskId = selectedTask?.id ?? "";
  const workflowTaskQuery = useGetOneWorkflowTask(
    { id: taskId },
    { enabled: Boolean(taskId) }
  );
  const workflowTaskDetails = workflowTaskQuery.data?.data;
  const workflowTaskDetailsLoading = workflowTaskQuery.isLoading;

  const { formSchemaId } = getFormSchemaId(selectedTask, workflowDetails);
  const formSchemaQuery = useGetOneFormSchema(
    { id: formSchemaId ?? "" },
    { enabled: Boolean(formSchemaId) }
  );
  const formSchemaDetails = formSchemaQuery.data?.data;

  const { data: userGroupsData } = useListUserGroups(
    { filters: { "user.id": [user?.id] } },
    { enabled: !!user }
  );
  const userGroups = userGroupsData?.data ?? [];

  const { isLoading: isLoadingComplete, mutate: completeTask } = useMutation({
    mutationFn: async () => {
      if (!selectedTask) {
        return;
      }

      await WorkflowTaskAdapter.updateOne({
        id: selectedTask.id,
        data: {
          status: WorkflowTaskStatus.COMPLETE,
          ...(selectedChoiceId ? { choice: selectedChoiceId } : {}),
        },
        previousData: selectedTask,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["workflows"] });
      queryClient.invalidateQueries({ queryKey: [Resources.WORKFLOW] });
      setTimeout(() => {
        queryClient.refetchQueries({
          queryKey: ["workflows"],
        });
        queryClient.refetchQueries({
          queryKey: [Resources.WORKFLOW],
        });
      }, 4000);
      onClose();
      toast.push({
        intent: "success",
        description: "Successfully updated task status to complete",
      });
    },
    onError: () => {
      toast.push({
        intent: "error",
        description: "Failed to update status. Please try again.",
      });
    },
  });

  const { isLoading: isLoadingDismissed, mutate: dismissTask } = useMutation({
    mutationFn: async () => {
      if (!selectedTask) {
        return;
      }

      await WorkflowTaskAdapter.updateOne({
        id: selectedTask.id,
        data: {
          status: WorkflowTaskStatus.DISMISSED,
        },
        previousData: selectedTask,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["workflows"] });
      queryClient.invalidateQueries({ queryKey: [Resources.WORKFLOW] });
      setTimeout(() => {
        queryClient.refetchQueries({
          queryKey: ["workflows"],
        });
        queryClient.refetchQueries({
          queryKey: [Resources.WORKFLOW],
        });
      }, 4000);
      toast.push({
        intent: "success",
        description: "Successfully updated task status to dismissed",
      });
    },
    onError: () => {
      toast.push({
        intent: "error",
        description: "Failed to dismiss task.",
      });
    },
  });

  const onClose = () => {
    setSelectedTask(null);
    setIsTaskCommentsDrawerOpen(false);
    setTimelinePanelTargetDrawer(null);
  };

  const getChoices = useCallback(() => {
    const stepId = workflowTaskDetails?.step_id;
    if (
      stepId &&
      workflowDetails?.config.steps[stepId].task.type ===
        WorkflowTaskTypes.CHOICE
    ) {
      return workflowDetails?.config.steps[stepId].task.choices;
    }
    return [];
  }, [workflowDetails, workflowTaskDetails]);
  const choices = useMemo(() => getChoices(), [getChoices]);

  const getAttachments = useCallback(() => {
    const stepId = workflowTaskDetails?.step_id;
    if (
      stepId &&
      workflowDetails?.config.steps[stepId].type === WorkflowStepType.USER_TASK
    ) {
      return workflowDetails?.config.steps[stepId].task?.attachments ?? [];
    }
    return [];
  }, [workflowDetails, workflowTaskDetails]);
  const attachments = useMemo(() => getAttachments(), [getAttachments]);

  useEffect(() => {
    if (choices.length > 0) {
      if (
        workflowTaskDetails?.status === WorkflowTaskStatus.OPEN ||
        workflowTaskDetails?.status === WorkflowTaskStatus.OVERDUE
      ) {
        setSelectedChoiceId(choices[0].id);
      } else if (workflowTaskDetails?.status === WorkflowTaskStatus.COMPLETE) {
        const selectedChoice = choices.find(
          (choice) => choice.next && taskStepIds.includes(choice.next)
        );
        setSelectedChoiceId(selectedChoice?.id ?? null);
      }
    } else {
      setSelectedChoiceId(null);
    }
  }, [choices]);

  const isLoading = isLoadingComplete || isLoadingDismissed;

  const isTaskFormRequiredWithNoRelevantSubmissions = useMemo(() => {
    const workflowTask = workflowTaskDetails ?? selectedTask;
    const hasTaskAnySubmissionsOfSameSchema =
      !!workflowTask &&
      workflowTask?.type === WorkflowTaskTypes.SUBMIT_FORM &&
      Array.isArray(workflowTask?.form_submission) &&
      workflowTask?.form_submission.length > 0 &&
      workflowTask?.form_submission.some((submission) => {
        return submission.form_schema.id === formSchemaId;
      });

    const doesTaskHaveAnyValidSubmissions =
      !!workflowTask?.form_submission?.some((submission) =>
        validCompletedFormStatus.includes(submission.status)
      );

    const doesCurrentStepRequireFormSubmission = !!(
      workflowDetails?.config.steps?.[selectedTask?.step_id ?? ""]
        ?.task as FormSubmissionTaskType
    )?.form_required;

    return (
      doesCurrentStepRequireFormSubmission &&
      (!hasTaskAnySubmissionsOfSameSchema || !doesTaskHaveAnyValidSubmissions)
    );
  }, [workflowTaskDetails, workflowDetails, selectedTask, formSchemaId]);

  const checkIfUserAssignedToTask = () => {
    const isUserInAssigneeGroup = userGroups.some(
      (userGroup) => userGroup.id === selectedTask?.assignee_group
    );
    const isUserAssignee = user?.id === selectedTask?.assignee_user;

    return isUserAssignee || isUserInAssigneeGroup;
  };

  const commentCount = commentCounts[`taskComments-${taskId}`] || 0;

  const isTaskAssigneeRestrictedAndUserNotAssignee =
    (selectedTask?.restrict_assignee_completion &&
      !checkIfUserAssignedToTask()) ??
    false;

  const isCompleteButtonDisabled =
    (workflowDetailsEnabled && workflowDetailsLoading) ||
    (selectedTask?.status !== WorkflowTaskStatus.OPEN &&
      selectedTask?.status !== WorkflowTaskStatus.OVERDUE) ||
    isTaskFormRequiredWithNoRelevantSubmissions ||
    isTaskAssigneeRestrictedAndUserNotAssignee;

  const completeButton = (
    <ButtonWithPopover
      key="complete-task-button"
      label={
        isTaskAssigneeRestrictedAndUserNotAssignee
          ? "Only assignees can action this task"
          : ""
      }
      variant="primary"
      onClick={() => completeTask()}
      isLoading={isLoading}
      buttonProps={{
        disabled: isCompleteButtonDisabled,
      }}
      popoverProps={{ positions: ["top"] }}
    >
      Complete
    </ButtonWithPopover>
  );

  const completeButtonComponent =
    isTaskFormRequiredWithNoRelevantSubmissions ? (
      <Tooltip
        content={`At least one form submission ${formSchemaDetails?.name ? `of form template "${formSchemaDetails?.name}"` : ""} with a status of ${validCompletedFormStatus
          .map((s, idx) => {
            if (idx === validCompletedFormStatus.length - 1) {
              return `or ${upperFirst(s)}`;
            }
            return upperFirst(s);
          })
          .join(", ")} is required to complete the task.`}
      >
        {completeButton}
      </Tooltip>
    ) : (
      completeButton
    );

  const [prevTask, nextTask] = useMemo(() => {
    const currTaskIndex = actionsQuery.data?.data.findIndex(
      (task) => task.id === selectedTask?.id
    );
    if (currTaskIndex !== undefined && currTaskIndex !== -1) {
      return [
        actionsQuery.data?.data[currTaskIndex + 1],
        actionsQuery.data?.data[currTaskIndex - 1],
      ];
    } else {
      return [undefined, undefined];
    }
  }, [actionsQuery.data?.data, selectedTask]);

  return (
    <Drawer
      size="md"
      isOpen={!!selectedTask}
      shouldAllowClose={!isTaskCommentsDrawerOpen}
      onClose={onClose}
      title="User Task Details"
      titleAction={
        <div className={cx("buttonContainer")}>
          <Button
            icon="caret-up"
            iconProps={{
              size: 24,
            }}
            size="small"
            className={cx("caretButton")}
            disabled={!nextTask}
            onClick={() => {
              if (nextTask) {
                setSelectedTask(nextTask);
              }
            }}
          />
          <Button
            icon="caret-down"
            iconProps={{
              size: 24,
            }}
            size="small"
            className={cx("caretButton")}
            disabled={!prevTask}
            onClick={() => {
              if (prevTask) {
                setSelectedTask(prevTask);
              }
            }}
          />
        </div>
      }
      actionRow={
        <div className={cx("buttonContainer")}>
          {canReadComments && canReadAssetType && workflowTaskDetails ? (
            <TimelinePanel.Trigger targetDrawer={TASK_COMMENTS_DRAWER_KEY}>
              {({ onClick }) => (
                <Button
                  className={cx("commentsButton", {
                    "commentsButton--active": isTaskCommentsDrawerOpen,
                  })}
                  onClick={onClick}
                  slotLeft={
                    <Icon
                      variant="chat-text"
                      size={24}
                    />
                  }
                  slotRight={<Pill variant="default">{commentCount}</Pill>}
                />
              )}
            </TimelinePanel.Trigger>
          ) : null}
          {selectedTask?.is_dismissible ? (
            <Button
              isLoading={isLoading}
              variant="error-outline"
              onClick={() => dismissTask()}
              disabled={
                selectedTask?.status !== WorkflowTaskStatus.OPEN &&
                selectedTask?.status !== WorkflowTaskStatus.OVERDUE
              }
            >
              Dismiss
            </Button>
          ) : null}
          {selectedTask ? (
            <Button
              isLoading={isLoading}
              onClick={() => {
                onReassignTask?.(selectedTask);
                setSelectedTask(null);
              }}
              disabled={
                selectedTask?.status !== WorkflowTaskStatus.OPEN &&
                selectedTask?.status !== WorkflowTaskStatus.OVERDUE
              }
            >
              Re-Assign
            </Button>
          ) : null}
          {selectedTask?.status === WorkflowTaskStatus.COMPLETE ? (
            <Button
              isLoading={isLoading}
              onClick={() => {
                setSelectedTask(null);
              }}
              variant="primary"
            >
              Done
            </Button>
          ) : (
            completeButtonComponent
          )}
        </div>
      }
      {...(canReadComments &&
      canReadAssetType &&
      workflowDetails &&
      workflowTaskDetails
        ? {
            rightSlot: (
              <TaskCommentsDrawer
                workflow={workflowDetails}
                task={workflowTaskDetails}
                drawerKey={TASK_COMMENTS_DRAWER_KEY}
              />
            ),
          }
        : {})}
    >
      <div className={cx("contentContainer")}>
        <div className={cx("descriptor")}>
          <Skeleton isLoading={workflowTaskDetailsLoading}>
            <div
              className={cx("icon", {
                open: workflowTaskDetails?.status === WorkflowTaskStatus.OPEN,
                overdue:
                  workflowTaskDetails?.status === WorkflowTaskStatus.OVERDUE,
                complete:
                  workflowTaskDetails?.status === WorkflowTaskStatus.COMPLETE ||
                  !isUserTask(selectedTask?.type),
                dismissed:
                  workflowTaskDetails?.status === WorkflowTaskStatus.DISMISSED,
              })}
            >
              <Icon
                variant={getTaskIcon(selectedTask?.type)}
                size={24}
              />
            </div>
          </Skeleton>
          <div className={cx("content")}>
            <div className={cx("task")}>
              <Skeleton
                isLoading={workflowTaskDetailsLoading}
                style={{ minWidth: 100 }}
              >
                <p className={cx("type")}>{startCase(selectedTask?.type)}</p>
              </Skeleton>
              <div className={cx("titleContainer")}>
                <Skeleton
                  isLoading={workflowTaskDetailsLoading}
                  style={{ minWidth: 200, minHeight: 35 }}
                >
                  <p className={cx("title")}>{selectedTask?.name}</p>
                </Skeleton>
                {isUserTask(selectedTask?.type) && (
                  <Pill
                    variant={
                      workflowTaskDetails?.status
                        ? WorkflowTaskStatusToPillVariantMap[
                            workflowTaskDetails.status
                          ]
                        : "default"
                    }
                    isLoading={workflowTaskDetailsLoading}
                  >
                    {toStartCaseString(workflowTaskDetails?.status)}
                  </Pill>
                )}
              </div>
              {workflowTaskDetails?.status !== "complete" && (
                <div>
                  <p className={cx("dueDate")}>
                    {workflowTaskDetails?.due_date &&
                      getRelativeTimeDifference(
                        new Date(workflowTaskDetails.due_date),
                        new Date()
                      )}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <Skeleton
          isLoading={workflowTaskDetailsLoading}
          style={{ minHeight: 200 }}
        >
          {selectedTask?.type === WorkflowTaskTypes.CHOICE &&
          selectedChoiceId &&
          workflowTaskDetails ? (
            <WorkflowTaskUserChoice
              choices={choices}
              selectedChoiceId={selectedChoiceId}
              setSelectedChoiceId={setSelectedChoiceId}
              taskStatus={workflowTaskDetails.status}
            />
          ) : null}
        </Skeleton>
        {selectedTask?.type === WorkflowTaskTypes.SUBMIT_FORM ? (
          <WorkflowTaskFormSubmission
            workflowTask={workflowTaskDetails ?? selectedTask}
            formSchema={formSchemaDetails}
            workflow={workflowDetails}
          />
        ) : null}
        {selectedTask?.type === WorkflowTaskTypes.COMPLETE_EVENT ? (
          <Accordion
            key={"event"}
            defaultActiveKeys={["event"]}
          >
            <AccordionPanel
              dataKey={"event"}
              title={"Event"}
            >
              <WorkflowTaskCompleteEvent
                workflowTask={workflowTaskDetails ?? selectedTask}
                workflow={workflowDetails}
              />
            </AccordionPanel>
          </Accordion>
        ) : null}
        {attachments.length > 0 && (
          <Accordion defaultActiveKeys={["attachment.0"]}>
            {attachments.map((attachment, index) => (
              <AccordionPanel
                key={`attachment.${index}`}
                dataKey={`attachment.${index}`}
                title={getAttachmentTitle(attachment)}
                actionRow={
                  <>
                    {attachment.type === "report" ? (
                      <ReportAttachmentHeader attachment={attachment} />
                    ) : null}
                  </>
                }
              >
                <WorkflowTaskAttachment attachment={attachment} />
              </AccordionPanel>
            ))}
          </Accordion>
        )}
        <Accordion
          key={"task-overview"}
          defaultActiveKeys={["task-overview"]}
        >
          <AccordionPanel
            dataKey={"task-overview"}
            title={"Task Overview"}
          >
            <WorkflowTaskDetailKeyValueList
              selectedTask={selectedTask}
              formSchema={formSchemaDetails}
              isLoading={workflowTaskDetailsLoading}
              workflowDetails={workflowDetails}
            />
          </AccordionPanel>
        </Accordion>
      </div>
    </Drawer>
  );
};
