import { Radio } from "@validereinc/common-components";
import {
  ChoiceType,
  WorkflowTaskStatus,
  WorkflowTaskStatusType,
} from "@validereinc/domain";
import classNames from "classnames/bind";
import React from "react";
import styles from "./WorkflowTaskUserChoice.module.scss";

const cx = classNames.bind(styles);

export const WorkflowTaskUserChoice = ({
  choices,
  selectedChoiceId,
  setSelectedChoiceId,
  taskStatus,
}: {
  choices: ChoiceType[];
  selectedChoiceId: string;
  setSelectedChoiceId: (choiceId: string) => void;
  taskStatus: WorkflowTaskStatusType;
}) => {
  const isTaskComplete = taskStatus === WorkflowTaskStatus.COMPLETE;

  const options = choices.map((choice) => {
    return {
      title: choice.name,
      description: choice.description,
      value: choice.id,
    };
  });

  return (
    <div
      className={cx(
        "choice-section-container",
        isTaskComplete ? "taskComplete" : ""
      )}
    >
      <p className={cx("choice-section-title")}>Choices</p>
      <Radio
        className={cx("choice-card-container")}
        optionClassName={cx("choice-card")}
        name="user-choice"
        value={selectedChoiceId}
        onChange={setSelectedChoiceId}
        options={options}
        isLoading={!choices?.length}
        isDisabled={isTaskComplete}
        shouldUseRadioSelectionCard
      />
    </div>
  );
};
