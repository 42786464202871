import { colours } from "@validereinc/common-components";
import React from "react";

export const WorkflowGraphEdgeMarkerEndMap = {
  DEFAULT: "workflow_marker_end",
  DISABLED: "workflow_marker_end_disabled",
  HOVERED: "workflow_marker_end_hover",
} as const;

const BaseEdgeMarker = ({ id, color }: { id: string; color: string }) => (
  <marker
    id={id}
    viewBox="0 0 10 10"
    refX="7"
    refY="5"
    markerUnits="strokeWidth"
    markerWidth="10"
    markerHeight="10"
    orient="auto"
  >
    <path
      d="M 0 0 L 10 5 L 0 10 z"
      fill={color}
    />
  </marker>
);

const WorkflowGraphEdgeMarkers = () => {
  return (
    <svg style={{ position: "absolute", top: 0, left: 0, zIndex: -100 }}>
      <defs>
        <BaseEdgeMarker
          id={WorkflowGraphEdgeMarkerEndMap.DEFAULT}
          color={colours.colours.text[400]}
        />
        <BaseEdgeMarker
          id={WorkflowGraphEdgeMarkerEndMap.DISABLED}
          color={colours.colours.text[200]}
        />
        <BaseEdgeMarker
          id={WorkflowGraphEdgeMarkerEndMap.HOVERED}
          color={colours.colours.text[600]}
        />
      </defs>
    </svg>
  );
};

export default WorkflowGraphEdgeMarkers;
