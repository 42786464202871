import { TemplatedReportProgressBanner } from "#batteries-included-components/Banners/TemplatedReportProgressBanner";
import { useSearchParams } from "#routers/hooks";
import { TemplatedReportsListRoutePath } from "#routes/reports/templated-reports";
import { TemplatedReportGeneratedReportsTab } from "#src/routes/reports/templated-reports/TemplatedReportGeneratedReports/TemplatedReportGeneratedReportsTab";
import { TemplatedReportsCategoriesTab } from "#src/routes/reports/templated-reports/TemplatedReportsCategoriesTab";
import { TemplatedReportTemplatesTab } from "#src/routes/reports/templated-reports/TemplatedReportTemplatesTab";
import { useBreadcrumbsFromRoute } from "#src/utils/route";
import { Page, Tab } from "@validereinc/common-components";
import React from "react";

export const TemplatedReportListPage = () => {
  const [{ tab }, setSearchParams] = useSearchParams();
  const [breadcrumbs] = useBreadcrumbsFromRoute(TemplatedReportsListRoutePath);

  return (
    <Page
      title={TemplatedReportsListRoutePath.title}
      breadcrumbs={breadcrumbs}
      activeTabKey={tab ?? "templates"}
      onActiveTabKeyChange={(tab) => setSearchParams({ tab })}
    >
      <Tab
        title="Categories"
        tabKey="categories"
      >
        <TemplatedReportsCategoriesTab />
      </Tab>
      <Tab
        title="Templates"
        tabKey="templates"
      >
        <TemplatedReportProgressBanner />
        <TemplatedReportTemplatesTab />
      </Tab>
      <Tab
        title="All Reports"
        tabKey="reports"
      >
        <TemplatedReportProgressBanner />
        <TemplatedReportGeneratedReportsTab />
      </Tab>
    </Page>
  );
};
