import { toStartCaseString } from "@validereinc/utilities";
import {
  AssetType,
  CustomAttributeRecordType,
  ReportWithDownloadV2Type,
} from "../schemas";
import type {
  DomainModelMetaExtendedType,
  DomainModelType,
  GetListRequestType,
  GetListResponseType,
  GetOneRequestType,
} from "../util";
import { FilterObjectType, fetchAndCollate, getFilterObject } from "../util";
import { MeasurementSeriesType, restAPI } from "./";
import { SavedFiltersDomain } from "./composable";

export const FlowStatus = {
  ACTIVE: "active",
  INACTIVE: "inactive",
} as const;

export const FlowStatusOptions = Object.values(FlowStatus).map((id) => ({
  id,
  name: toStartCaseString(id),
}));

export type FlowStatusType = (typeof FlowStatus)[keyof typeof FlowStatus];

export type FlowTypes =
  | "fuel"
  | "flare"
  | "vent"
  | "fugitive"
  | "production_volume"
  | "supply_chain"
  | "receipt"
  | "disposition";

export type FlowProductType =
  | "natural_gas"
  | "electricity"
  | "steam"
  | "crude_oil";

// REVIEW: this should be replaced with Equipment type directly
export interface FlowEquipmentType extends DomainModelType {
  facility_id: string;
  name: string;
  type_id: string;
  created_at: Date;
  updated_at: Date;
  method: string;
  effective_date: string;
  custom_attributes: CustomAttributeRecordType;
  measurement_subject_id: string;
  latitude: number;
  longitude: number;
  status: "active" | "inactive";
}

// REVIEW: this should be replaced with Facility type directly
export interface FlowFacilityType
  extends DomainModelType,
    Omit<DomainModelMetaExtendedType, "updated_by"> {
  name: string;
  status: "active" | "inactive";
  company_id: string;
  custom_attributes: Record<string, string>;
  measurement_subject_id: string;
  latitude: number;
  longitude: number;
}

export interface FlowType extends DomainModelType, DomainModelMetaExtendedType {
  name: string;
  type: FlowTypes;
  status: FlowStatusType;
  product_category: FlowProductType;
  product_type: FlowProductType;
  custom_attributes: CustomAttributeRecordType;
  associated_facility_id?: string;
  associated_facility?: FlowFacilityType;
  associated_equipment_id?: string;
  associated_equipment?: FlowEquipmentType;
  upstream_flow_ids?: string[];
  upstream_flows?: FlowType[];
  downstream_flow_ids?: string[];
  downstream_flows?: FlowType[];
  upstream_facility_id?: string;
  upstream_facility?: FlowFacilityType;
  downstream_facility_id?: string;
  downstream_facility?: FlowFacilityType;
  upstream_equipment_id?: string;
  upstream_equipment?: FlowEquipmentType;
  downstream_equipment_id?: string;
  downstream_equipment?: FlowEquipmentType;
}

export interface FlowV2Type
  extends DomainModelType,
    DomainModelMetaExtendedType {
  name: string;
  type: FlowTypes;
  status: FlowStatusType;
  product_category: FlowProductType;
  product_type: FlowProductType;
  associated_facility_id?: string;
  associated_facility?: FlowFacilityType;
  associated_equipment_id?: string;
  associated_equipment?: FlowEquipmentType;
  upstream_flows_count?: number;
  downstream_flows_count?: number;
  upstream_facility_id?: string;
  upstream_facility?: FlowFacilityType;
  downstream_facility_id?: string;
  downstream_facility?: FlowFacilityType;
  upstream_equipment_id?: string;
  upstream_equipment?: FlowEquipmentType;
  downstream_equipment_id?: string;
  downstream_equipment?: FlowEquipmentType;
}

export type CreateFlowType = {
  name: string;
  type: FlowTypes;
  status: FlowStatusType;
  product_category: FlowProductType;
  product_type: FlowProductType;
  custom_attributes: Record<string, string>;
} & (
  | {
      upstream_flow_ids?: string[];
      upstream_facility_id: undefined;
      upstream_equipment_id: undefined;
    }
  | {
      upstream_flow_ids: undefined;
      upstream_facility_id?: string;
      upstream_equipment_id: undefined;
    }
  | {
      upstream_flow_ids: undefined;
      upstream_facility_id: undefined;
      upstream_equipment_id?: string;
    }
) &
  (
    | {
        downstream_flow_ids?: string[];
        downstream_facility_id: undefined;
        downstream_equipment_id: undefined;
      }
    | {
        downstream_flow_ids: undefined;
        downstream_facility_id?: string;
        downstream_equipment_id: undefined;
      }
    | {
        downstream_flow_ids: undefined;
        downstream_facility_id: undefined;
        downstream_equipment_id?: string;
      }
  );

export type EditFlowType = Partial<CreateFlowType>;

export type FlowMetaDataType = Array<{ id: string; name: string }>;

export type FlowFilterType = FilterObjectType<{
  id?: string;
  type?: FlowTypes;
  status?: FlowStatusType;
  product_type?: FlowProductType;
  product_category?: FlowProductType;
}> & { period?: string };

export const FlowDomain = {
  getFlows: ({
    filters,
    page,
    pageSize,
    sortBy,
    sortDirection,
  }: GetListRequestType<FlowFilterType>) => {
    const { period, ...otherFilters } = filters ?? {};

    return fetchAndCollate(
      ({ page, pageSize }) =>
        restAPI.nodeAPI.POST<GetListResponseType<FlowV2Type>>({
          version: 2,
          endpoint: "/flows/search",
          query: period ? { period } : undefined,
          body: {
            page,
            page_size: pageSize,
            sort_by: sortBy,
            sort_direction: sortDirection,
            filter: getFilterObject(otherFilters),
          },
        }),
      page,
      pageSize
    );
  },

  exportFlows: ({
    filters,
    sortBy,
    sortDirection,
  }: GetListRequestType<FlowFilterType>) => {
    const { period, ...otherFilters } = filters ?? {};

    return restAPI.nodeAPI.POST<ReportWithDownloadV2Type>({
      endpoint: "/flows/export",
      query: period ? { period } : undefined,
      body: {
        sort_by: sortBy,
        sort_direction: sortDirection,
        filter: getFilterObject(otherFilters),
      },
    });
  },

  createFlow: async ({
    upstream_flow_ids,
    upstream_facility_id,
    upstream_equipment_id,
    downstream_flow_ids,
    downstream_facility_id,
    downstream_equipment_id,
    ...restCreateFlowBody
  }: CreateFlowType) =>
    restAPI.nodeAPI.POST<FlowType>({
      endpoint: "/flows",
      body: {
        ...restCreateFlowBody,
        ...(upstream_flow_ids ? { upstream_flow_ids } : {}),
        ...(upstream_facility_id ? { upstream_facility_id } : {}),
        ...(upstream_equipment_id ? { upstream_equipment_id } : {}),
        ...(downstream_flow_ids ? { downstream_flow_ids } : {}),
        ...(downstream_facility_id ? { downstream_facility_id } : {}),
        ...(downstream_equipment_id ? { downstream_equipment_id } : {}),
      },
    }),

  getFlow: ({ flowId, period }: { flowId: string; period?: string }) =>
    restAPI.nodeAPI.GET<FlowType>({
      endpoint: `/flows/${flowId}`,
      query: period ? { period } : undefined,
    }),

  getOne: async ({ id, meta }: GetOneRequestType<{ period?: string }>) =>
    restAPI.nodeAPI
      .GET<FlowType>({
        endpoint: `/flows/${id}`,
        query: meta?.period ? { period: meta.period } : undefined,
      })
      .then((resp) => ({
        data: resp,
      })),

  updateFlow: async (
    { flowId }: { flowId: string },
    {
      upstream_flow_ids,
      upstream_facility_id,
      upstream_equipment_id,
      downstream_flow_ids,
      downstream_facility_id,
      downstream_equipment_id,
      ...restEditFlowBody
    }: EditFlowType
  ) =>
    restAPI.nodeAPI.PUT({
      endpoint: `/flows/${flowId}`,
      body: {
        ...restEditFlowBody,
        ...(upstream_flow_ids ? { upstream_flow_ids } : {}),
        ...(upstream_facility_id ? { upstream_facility_id } : {}),
        ...(upstream_equipment_id ? { upstream_equipment_id } : {}),
        ...(downstream_flow_ids ? { downstream_flow_ids } : {}),
        ...(downstream_facility_id ? { downstream_facility_id } : {}),
        ...(downstream_equipment_id ? { downstream_equipment_id } : {}),
      },
    }),

  deleteFlow: async ({ flowId }: { flowId: string }) =>
    restAPI.nodeAPI.DELETE({ endpoint: `/flows/${flowId}` }),

  getFlowLevels: (): Promise<Array<{ label: string; value: string }>> =>
    Promise.resolve([
      { label: "Facility", value: AssetType.FACILITY },
      { label: "Equipment", value: AssetType.EQUIPMENT },
    ]),

  getFlowTypes: () =>
    restAPI.nodeAPI.GET<FlowMetaDataType>({
      endpoint: "/flow_types",
    }),

  getFlowProductCategories: () =>
    restAPI.nodeAPI.GET<FlowMetaDataType>({
      endpoint: "/product_categories",
    }),

  getFlowProductTypes: async () =>
    await restAPI.nodeAPI.GET<FlowMetaDataType>({
      endpoint: "/product_types",
    }),

  getMeasurementSeries: async ({ ...restQuery }) =>
    await restAPI.nodeAPI.GET<MeasurementSeriesType[]>({
      endpoint: "/measurement_series",
      query: { ...restQuery },
    }),

  savedFilters: SavedFiltersDomain<FlowFilterType>({
    endpoint: "/flows/filters",
  }),
};
