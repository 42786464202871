import { type TemplatedReportType } from "@validereinc/domain";
import { DateTimeSchemas } from "@validereinc/utilities";
import isPlainObject from "lodash/isPlainObject";

export const getGeneratedReportQueueStorageKey = (
  templatedReportName: string
) => `templated-report-${templatedReportName}-template-details-export`;

export const sanitizeAndTransformReportInputs = (
  // IMPROVE: this can be typed better when we have a mapping between data types
  // and the values of the inputs rendered for said data types
  inputs: Record<string, any>,
  inputsSchema: TemplatedReportType["input_schema"]
): Record<string, string | undefined> =>
  Object.fromEntries(
    Object.entries(inputs)
      .filter(([_, value]) => !!value && value !== "")
      .map(([key, value]) => {
        const associatedSchema = inputsSchema[key];

        if (associatedSchema?.data_type === "date") {
          const dateTimeRangeAsDatesSchemaResult =
            DateTimeSchemas.dateTimeRangeStrict.safeParse(value);

          if (dateTimeRangeAsDatesSchemaResult.success) {
            switch (associatedSchema.date_resolution) {
              case "year":
                return [
                  key,
                  DateTimeSchemas.dateTimeToYear.safeParse(
                    dateTimeRangeAsDatesSchemaResult.data.from.toISOString()
                  ).data,
                ];
              case "year_month":
                return [
                  key,
                  DateTimeSchemas.dateTimeToYearMonth.safeParse(
                    dateTimeRangeAsDatesSchemaResult.data.from.toISOString()
                  ).data,
                ];
              default:
                return [
                  key,
                  dateTimeRangeAsDatesSchemaResult.data.from.toISOString(),
                ];
            }
          }
        }

        if (isPlainObject(value)) {
          return [key, sanitizeAndTransformReportInputs(value, inputsSchema)];
        }

        if (value instanceof Date) {
          return [key, value.toISOString()];
        }

        return [key, value];
      })
  );
