import { getFilterObject, GetListResponseType } from "../util";
import { restAPI } from "./api";

export const ProjectGroupsStatus = {
  active: "active",
  closed: "closed",
} as const;

export type ProjectGroupsStatusType =
  (typeof ProjectGroupsStatus)[keyof typeof ProjectGroupsStatus];

export type ProjectGroupsType = {
  id: string;
  name: string;
  description: string;
  project_developer: string;
  protocol: string;
  start_date: string;
  end_date: string;
  country: string;
  status: ProjectGroupsStatusType;
};

export type ProjectGroupsListFilterType = {
  companyId?: string;
  sort_by?: string;
  sort_direction?: string;
  page?: number;
  page_size?: number;
  filter?: Partial<ProjectGroupsType>;
};

export type GetProjectGroupsListResponseType =
  GetListResponseType<ProjectGroupsType>;

export type ProjectGroupsDetailType = ProjectGroupsType & {
  summary: {
    total_active_credits: number;
    total_retired_credits: number;
    total_periods: number;
    buffer_credits: number;
  };
};

export type ProjectGroupSummaryType = {
  project_count: number;
  active_projects: number;
  total_active_credits: number;
  total_retired_credits: number;
};

export const ProjectGroupsAdapter = {
  getList: ({
    companyId,
    page,
    page_size,
    sort_by,
    sort_direction,
    filter,
  }: ProjectGroupsListFilterType) =>
    restAPI.publicNodeAPI.POST<GetProjectGroupsListResponseType>({
      endpoint: `/project_groups/${companyId}/search`,
      body: {
        sort_by: sort_by,
        sort_direction: sort_direction,
        page: page,
        page_size: page_size,
        filter: getFilterObject(filter),
      },
    }),

  getOne: ({
    companyId,
    projectGroupId,
  }: {
    companyId: string;
    projectGroupId: string;
  }) =>
    restAPI.publicNodeAPI.GET<ProjectGroupsDetailType>({
      endpoint: `/project_groups/${companyId}/${projectGroupId}`,
    }),

  summary: {
    getOne: ({ companyId }: { companyId: string }) =>
      restAPI.publicNodeAPI.GET<ProjectGroupSummaryType>({
        endpoint: `/project_groups/${companyId}/summary`,
      }),
  },
};
