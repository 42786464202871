import {
  useCreateOne,
  useDeleteOne,
  useGetList,
  useGetOne,
  useUpdateOne,
} from "#src/components/hooks/adapters/adapterUtils";
import { ExceptionUtils } from "#src/utils/exception";
import { useMutation } from "@tanstack/react-query";
import { useToast } from "@validereinc/common-components";
import {
  BaseError,
  FormSubmissionAdapter,
  FormSubmissionFilterType,
  FormSubmissionType,
  GetListRequestType,
  Resources,
} from "@validereinc/domain";
import { downloadLink } from "@validereinc/utilities";
import pLimit from "p-limit";
import { useState } from "react";

export const useGetOneFormSubmission = useGetOne<FormSubmissionType>(
  FormSubmissionAdapter.getOne,
  Resources.FORM_SUBMISSION
);

export const useListFormSubmissions = useGetList<FormSubmissionType>(
  FormSubmissionAdapter.getList,
  Resources.FORM_SUBMISSION
);

export const useDeleteOneFormSubmission = useDeleteOne<FormSubmissionType>(
  FormSubmissionAdapter.deleteOne,
  Resources.FORM_SUBMISSION
);

export const useCreateOneFormSubmission = useCreateOne<FormSubmissionType>(
  FormSubmissionAdapter.createOne,
  Resources.FORM_SUBMISSION
);

export const useUpdateOneFormSubmission = useUpdateOne<FormSubmissionType>(
  FormSubmissionAdapter.updateOne,
  Resources.FORM_SUBMISSION
);

export const useCreateOneFormSubmissionRevision =
  useCreateOne<FormSubmissionType>(
    FormSubmissionAdapter.revision.createOne,
    Resources.FORM_SUBMISSION
  );

// Exports all form submissions for a template. This is different from useForms useExportFormSubmission
export const useExportFormSubmissions = (
  apiParams: GetListRequestType<FormSubmissionFilterType> = {}
) => {
  const { toast } = useToast();

  return useMutation({
    mutationFn: async () => {
      const report = await FormSubmissionAdapter.exportList?.(apiParams);
      if (!report?.s3_download_link) {
        throw new BaseError(
          `Could not fetch download URL for form submissions export`,
          { cause: report }
        );
      }
      downloadLink(report.s3_download_link);
    },
    onError: (error) => {
      ExceptionUtils.reportException(error, "error");
      toast.push({
        intent: "error",
        description: "Failed to export form submissions.",
      });
    },
  });
};

export const useBulkDeleteFormSubmissions = () => {
  const { toast } = useToast();

  const [selectedFormSubmissions, setSelectedFormSubmissions] = useState<
    Record<string, FormSubmissionType>
  >({});
  const [isDeleting, setIsDeleting] = useState(false);

  const formSubmissionsToDeleteCount = Object.keys(
    selectedFormSubmissions
  ).length;

  const deleteMutation = useDeleteOneFormSubmission({
    noAlerts: true,
  });

  const handleBulkDelete = async () => {
    setIsDeleting(true);

    const limit = pLimit(8);

    const promises = Object.keys(selectedFormSubmissions).map(
      async (selectedFormSubmissionId) =>
        limit(async () => {
          const result = await deleteMutation.mutateAsync({
            id: selectedFormSubmissionId,
          });
          return { ...result, selectedFormSubmissionId };
        })
    );

    const results = await Promise.allSettled(promises);

    const allDeletionsSuccessful = results.every(
      (result) => result.status === "fulfilled"
    );
    if (allDeletionsSuccessful) {
      toast.push({
        intent: "success",
        description: `Successfully deleted ${formSubmissionsToDeleteCount} submissions`,
      });
    } else {
      toast.push({
        intent: "error",
        description: `Failed to delete some submission(s)`,
      });
    }

    setSelectedFormSubmissions((prevSelectedFormSubmissions) => {
      const newSelectedFormSubmissions = {
        ...prevSelectedFormSubmissions,
      };

      Object.keys(selectedFormSubmissions).forEach(
        (selectedFormSubmissionId) => {
          if (
            results.find(
              (result) =>
                result.status === "fulfilled" &&
                result.value.selectedFormSubmissionId ===
                  selectedFormSubmissionId
            )
          ) {
            delete newSelectedFormSubmissions[selectedFormSubmissionId];
          }
        }
      );

      return newSelectedFormSubmissions;
    });

    setIsDeleting(false);
  };

  return {
    selectedFormSubmissions,
    setSelectedFormSubmissions,
    isDeleting,
    handleBulkDelete,
  };
};
