import { useCreateDefaultRecordValueConfiguration } from "#src/components/hooks/adapters/useDefaultRecordValueConfig";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import useLocalization from "#src/hooks/useLocalization";
import {
  Button,
  Dialog,
  DropdownInput,
  Form,
  RadioInput,
  TextInput,
  useForm,
} from "@validereinc/common-components";
import {
  AssetType,
  DefaultAggregationSort,
  DefaultDeviceDRVCFilter,
  DefaultRecordValueConfigurationFunction,
  DefaultRecordValueConfigurationFunctionNames,
  DefaultRecordValueConfigurationMeasurementSeriesSchema,
  DefaultRecordValueConfigurationSchema,
  DefaultRecordValueConfigurationType,
  DefaultRecordValueConfigurationTypeNames,
  FormCategoryAdapter,
  getFilterObject,
  RecordValueConfigurationTypeType,
} from "@validereinc/domain";
import { toStartCaseString } from "@validereinc/utilities";
import classNames from "classnames/bind";
import React, { useEffect, useMemo } from "react";
import {
  DeviceDropdownInput,
  EquipmentDropdownInput,
  FlowDropdownInput,
  TimezoneDropdownInput,
} from "../../Dropdowns";
import { FormSchemaDropdownInput } from "../../Dropdowns/FormSchemaDropdownInput";
import styles from "./AddDefaultRecordValueConfiguration.module.scss";

const cx = classNames.bind(styles);

export const AddDefaultRecordAutomationDialog = ({
  isOpen,
  onClose,
  assetId,
  repoortingGroupId,
}: {
  isOpen: boolean;
  onClose: () => void;
  assetId: string;
  repoortingGroupId: string;
}) => {
  const form = useForm({});
  const { localize } = useLocalization();
  const { measurementTypes, getUnitsByQuantity } = useMeasurementTypes();

  const createDefaultRecordValueConfiguration =
    useCreateDefaultRecordValueConfiguration({});
  const handleSubmit = form.handleSubmit((values) => {
    const {
      measurement_type,
      measurement_unit,
      configuration_type,
      form_granularity,
      aggregate_function,
      window,
      timezone,
      form_schema_id,
      form_category_name,
      device,
      subject,
      default_value,
    } = values;
    let configuration;
    switch (configuration_type) {
      case DefaultRecordValueConfigurationType.FORM_MEASUREMENT:
        form_granularity === "template"
          ? (configuration = {
              filter: getFilterObject({
                "form_schema.id": form_schema_id,
                "measurement.measurement_type": { $exact: measurement_type },
                "measurement.subject_id": subject,
                status: ["validated", "submitted"],
              }),
              configuration_type,
              aggregate_function,
              sort: DefaultAggregationSort.FIRST_SORT,
              second_sort: DefaultAggregationSort.SECOND_SORT,
              sort_direction: DefaultAggregationSort.SORT_DIRECTION,
              window,
              timezone,
            })
          : (configuration = {
              filter: {
                "form_category.name": form_category_name,
                "measurement.measurement_type": measurement_type,
                ...getFilterObject({
                  "measurement.subject_id": subject,
                  status: ["validated", "submitted"],
                }),
              },
              configuration_type,
              aggregate_function,
              sort: DefaultAggregationSort.FIRST_SORT,
              second_sort: DefaultAggregationSort.SECOND_SORT,
              sort_direction: DefaultAggregationSort.SORT_DIRECTION,
              window,
              timezone,
            });
        break;
      case DefaultRecordValueConfigurationType.MEASUREMENT_SERIES:
        configuration = {
          filter: {
            device_id: device,
            measurement_type,
            interval: DefaultDeviceDRVCFilter.INTERVAL,
            func: DefaultDeviceDRVCFilter.FUNC,
            start: DefaultDeviceDRVCFilter.START,
            end: DefaultDeviceDRVCFilter.END,
          },
          configuration_type,
          aggregate_function,
          sort: DefaultAggregationSort.FIRST_SORT,
          sort_direction: DefaultAggregationSort.SORT_DIRECTION,
          window,
          timezone,
        };
        break;
    }
    createDefaultRecordValueConfiguration.mutate({
      asset_id: assetId,
      reporting_group_id: repoortingGroupId,
      measurement_type,
      measurement_unit,
      default_value: default_value ? Number(default_value) : null,
      configuration,
    });
    form.reset();
    onClose?.();
  });

  useEffect(() => {
    if (isOpen) form.reset();
  }, [isOpen]);

  const measurementTypeId = form.watch("measurement_type");
  const measurementType = useMemo(
    () => measurementTypes.find(({ id }) => id === measurementTypeId),
    [measurementTypes, measurementTypeId]
  );
  const units = getUnitsByQuantity(measurementType?.quantity);

  const configOptions = Object.entries(DefaultRecordValueConfigurationTypeNames)
    .filter(
      ([key]) =>
        key !== RecordValueConfigurationTypeType.CALCULATION_RESULT &&
        key !== RecordValueConfigurationTypeType.RECORD
    )
    .map(([key, value]) => ({
      label: toStartCaseString(value),
      name: value,
      id: key,
    }));

  const aggregationFunctionOptions = Object.entries(
    DefaultRecordValueConfigurationFunction
  ).map(([_, value]) => ({
    label:
      DefaultRecordValueConfigurationFunctionNames[value] ||
      toStartCaseString(value),
    name: value,
    id: value,
  }));

  const assetTypeOptions = Object.entries(AssetType)
    .filter(([key]) => ["EQUIPMENT", "FLOW"].includes(key))
    .map(([key, value]) => ({
      label: localize(value),
      name: value,
      id: key,
    }));

  const isDevice =
    form.watch("configuration_type") ==
    DefaultRecordValueConfigurationType.MEASUREMENT_SERIES;
  const isForm =
    form.watch("configuration_type") ==
    DefaultRecordValueConfigurationType.FORM_MEASUREMENT;
  const isAssetSubjectEquipment =
    form.watch("asset_type") == AssetType.EQUIPMENT;
  const isAssetSubjectFlow = form.watch("asset_type") == AssetType.FLOW;
  const formGranularity = form.watch("form_granularity");

  return (
    <Dialog
      title="Configure Automation"
      isOpen={isOpen}
      onClose={onClose}
      actionRow={[
        <Button
          key="add-record-automation"
          variant="primary"
          onClick={handleSubmit}
        >
          Add
        </Button>,
      ]}
    >
      <Form {...form}>
        <div className={cx("section-container")}>
          <section>
            <DropdownInput
              label="Record Value Type"
              name={
                DefaultRecordValueConfigurationSchema.keyof().Enum
                  .measurement_type
              }
              options={measurementTypes ?? []}
              valueKey="id"
              labelKey="name"
              isRequired
              isClearable={false}
            />

            <DropdownInput
              label="Unit"
              name={
                DefaultRecordValueConfigurationSchema.keyof().Enum
                  .measurement_unit
              }
              options={units}
              valueKey="id"
              labelKey="name.symbol"
              isRequired
              isClearable={false}
            />
          </section>
          <section>
            <span className={cx("title")}>Default Automation</span>
            <DropdownInput
              label="Source Type"
              name="configuration_type"
              options={configOptions ?? []}
              valueKey="id"
              labelKey="label"
              isRequired={false}
              isClearable={false}
            />
            <DropdownInput
              label="Function"
              name={
                DefaultRecordValueConfigurationMeasurementSeriesSchema.keyof()
                  .enum.aggregate_function
              }
              options={aggregationFunctionOptions ?? []}
              valueKey="id"
              labelKey="label"
              isRequired={false}
              isClearable={false}
            />
          </section>

          {isForm ? (
            <>
              <section>
                <span className={cx("title")}>Target</span>
                <RadioInput
                  name="form_granularity"
                  label="Form Category or Template"
                  isRequired
                  options={[
                    {
                      label: "Category",
                      value: "category",
                    },
                    {
                      label: "Template",
                      value: "template",
                    },
                  ]}
                  valueKey="value"
                  labelKey="label"
                />
                {formGranularity == "template" ? (
                  <FormSchemaDropdownInput
                    label="Form Template"
                    name="form_schema_id"
                    fetchFilters={{ status: "active" }}
                    isOptionalTextShown={false}
                  />
                ) : (
                  []
                )}
                {formGranularity == "category" ? (
                  <DropdownInput
                    name="form_category_name"
                    label="Form Category"
                    onFetchData={async (payload) => {
                      let { data } = await FormCategoryAdapter.getList({
                        ...payload,
                        filters: {
                          name: payload.searchTerm,
                        },
                      });

                      // REVIEW: not a fan of the fact that we need to do this. A refactor of DropdownInput is needed.
                      if (Array.isArray(payload.value)) {
                        data = data.filter((d) => payload.value.includes(d.id));
                      }

                      return data;
                    }}
                    labelKey="name"
                    valueKey="name"
                    isOptionalTextShown={false}
                  />
                ) : (
                  []
                )}
              </section>
              <section>
                <span className={cx("title")}>Asset</span>
                <DropdownInput
                  name="asset_type"
                  label="Asset Type"
                  options={assetTypeOptions}
                  valueKey="name"
                  labelKey="label"
                  isRequired
                />
                {isAssetSubjectEquipment ? (
                  <EquipmentDropdownInput
                    name="subject"
                    label={`${localize(AssetType.EQUIPMENT)}`}
                    isMulti
                  />
                ) : undefined}
                {isAssetSubjectFlow ? (
                  <FlowDropdownInput
                    name="subject"
                    label={`${localize(AssetType.FLOW)}`}
                    isMulti
                  />
                ) : undefined}
              </section>
            </>
          ) : (
            []
          )}
          {isDevice ? (
            <section>
              <span className={cx("title")}>Target</span>
              <DeviceDropdownInput
                label="Device"
                name="device"
                isMulti={false}
              />
            </section>
          ) : (
            []
          )}
          {isDevice || isForm ? (
            <section>
              <span className={cx("title")}>Date Range</span>
              <DropdownInput
                label="Window"
                name={
                  DefaultRecordValueConfigurationMeasurementSeriesSchema.keyof()
                    .enum.window
                }
                options={[
                  {
                    label: "1 Month",
                    value: "1month",
                  },
                  {
                    label: "3 Months",
                    value: "3month",
                  },
                  {
                    label: "6 Months",
                    value: "6month",
                  },
                  {
                    label: "1 Year",
                    value: "1year",
                  },
                  {
                    label: "3 Years",
                    value: "3year",
                  },
                  {
                    label: "5 Years",
                    value: "5year",
                  },
                  {
                    label: "10 Years",
                    value: "10year",
                  },
                ]}
                valueKey="value"
                labelKey="label"
                isRequired
                isClearable={false}
              />
              <TimezoneDropdownInput
                name={
                  DefaultRecordValueConfigurationMeasurementSeriesSchema.keyof()
                    .enum.timezone
                }
                inputId="timezone"
                isRequired={false}
              />
            </section>
          ) : (
            []
          )}
          <section>
            <span className={cx("title")}>Default Value</span>
            <TextInput
              name="default_value"
              label="Default Value"
              isRequired={false}
              type="number"
            />
          </section>
        </div>
      </Form>
    </Dialog>
  );
};
