import config from "#config";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import { LegacyDataTable } from "@validereinc/common-components";
import { formatNumberValueAndUnit } from "@validereinc/utilities";
import classNames from "classnames/bind";
import * as PropTypes from "prop-types";
import React from "react";
import { AutoSizer } from "react-virtualized";
import {
  DEFAULT_EMISSION_DECIMAL,
  EMITTED_POLLUTANTS,
  INTENSITY,
} from "../../../EmissionConstants";
import styles from "../EmissionReportDetail.module.scss";

const cx = classNames.bind(styles);

const SummaryTable = ({ emissionReportSummary }) => {
  function periodRenderer(rowData, columnKey) {
    return getTimeStringFromDate(
      rowData[columnKey],
      config.SHORT_DATEMONTH_FORMAT
    );
  }

  function volumeRenderer(rowData, columnKey) {
    return (
      formatNumberValueAndUnit(rowData[columnKey], {
        fractionDigits: DEFAULT_EMISSION_DECIMAL,
      }) ?? "-"
    );
  }

  function emissionRenderer(rowData, columnKey) {
    return (
      formatNumberValueAndUnit(rowData.emissions[columnKey], {
        fractionDigits: DEFAULT_EMISSION_DECIMAL,
      }) ?? "-"
    );
  }

  function intensityRenderer(rowData, columnKey) {
    return (
      formatNumberValueAndUnit(rowData.intensities[columnKey], {
        fractionDigits: DEFAULT_EMISSION_DECIMAL,
      }) ?? "-"
    );
  }

  const emissionColumns = EMITTED_POLLUTANTS.map((pollutant) => ({
    label: pollutant,
    key: pollutant,
    width: 150,
    cellRenderer: emissionRenderer,
    rightAlign: true,
  }));

  const intensityColumns = INTENSITY.map((intensity) => ({
    label: `${intensity} Intensity`,
    key: intensity,
    width: 150,
    cellRenderer: intensityRenderer,
    rightAlign: true,
  }));

  const headers = [
    {
      label: "Sales Stream",
      key: "sales_stream.name",
      width: 250,
      fixed: true,
    },
    {
      label: "Period",
      key: "start_date",
      width: 100,
      cellRenderer: periodRenderer,
    },
    {
      label: "Sales Volume",
      key: "sales_volume",
      width: 150,
      cellRenderer: volumeRenderer,
      rightAlign: true,
    },
    ...emissionColumns,
    ...intensityColumns,
  ];

  return (
    <div className={cx("emissionTable")}>
      <AutoSizer>
        {({ width, height }) => (
          <LegacyDataTable
            width={width}
            height={height}
            headers={headers}
            list={emissionReportSummary ?? []}
            rowHeight={55}
          />
        )}
      </AutoSizer>
    </div>
  );
};

SummaryTable.propTypes = {
  emissionReportSummary: PropTypes.array.isRequired,
};

export default SummaryTable;
