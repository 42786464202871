import { linkToFacilities } from "#routers/links";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { GeocoordinateDataDisplay } from "#src/components/DataDisplay/GeocoordinateDataDisplay";
import { useListCustomAttributes } from "#src/components/hooks/adapters/useCustomAttributes";
import config from "#src/config";
import useLocalization from "#src/hooks/useLocalization";
import { displayValueByDataType } from "#src/utils/display";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import {
  KeyValuePanel,
  type KeyValuePanelProps,
} from "@validereinc/common-components";
import { AssetType } from "@validereinc/domain";
import React, { ReactNode } from "react";

const EquipmentDetailsPanel = ({
  equipmentDetail,
  isMapDisplayed,
  templatedConfigurationLink,
}: EquipmentDetailsPanelProps) => {
  const { localize, isLoading: isMappingLoading } = useLocalization();
  const customAttributesQuery = useListCustomAttributes({
    filters: {
      entity_type: AssetType.EQUIPMENT,
    },
  });

  const data: KeyValuePanelProps["data"] = [
    ...(templatedConfigurationLink
      ? [
          {
            title: "Templated Configuration",
            value: templatedConfigurationLink,
          },
        ]
      : []),
    {
      title: localize("Facility"),
      value: (
        <RoutingLink to={linkToFacilities(equipmentDetail?.facility?.id)}>
          {equipmentDetail?.facility?.name ?? "-"}
        </RoutingLink>
      ),
    },
    {
      title: "Effective Date",
      value: getTimeStringFromDate(
        equipmentDetail?.effective_date,
        config.DATEMONTH_FORMAT
      ),
    },
    { title: "Type", value: equipmentDetail?.type?.name },
    {
      title: "Location",
      value: (
        <GeocoordinateDataDisplay
          coordinates={[equipmentDetail?.latitude, equipmentDetail?.longitude]}
        />
      ),
    },

    ...(customAttributesQuery.data?.data
      ?.filter(
        ({ entity_subtypes }) =>
          !entity_subtypes?.length ||
          entity_subtypes.includes(equipmentDetail?.type_id)
      )
      .map(({ display_name, field_name, data_type }) => {
        const value = equipmentDetail?.custom_attributes?.[field_name];

        return {
          title: display_name,
          value: displayValueByDataType(value, data_type, {
            alignment: "right",
          }),
        };
      }) ?? []),
  ];

  return (
    <KeyValuePanel
      panelMaxColumnCount={isMapDisplayed ? 2 : 3}
      panelKeyValueListProps={{ maxRowCount: 6, variant: "shaded" }}
      panelProps={{
        isFluidY: false,
        loaded: !isMappingLoading && !customAttributesQuery.isLoading,
      }}
      data={data}
    />
  );
};

type EquipmentDetailsPanelProps = {
  equipmentDetail: any;
  isMapDisplayed?: boolean;
  templatedConfigurationLink?: ReactNode;
};

export default EquipmentDetailsPanel;
