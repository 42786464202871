import useDeviceTypes from "#hooks/useDeviceTypes";
import useMeasurementTypes from "#hooks/useMeasurementTypes";
import { useBreadcrumbs } from "#routers/breadcrumbsHelper";
import { useNavigate } from "#routers/hooks";
import { linkToFacilities } from "#routers/links";
import FacilityService from "#services/FacilityService";
import { CreateDeviceForm } from "#src/batteries-included-components/Forms/Devices/Create/CreateDeviceForm";
import {
  Button,
  Page,
  useForm,
  useToast,
} from "@validereinc/common-components";
import {
  Device,
  DeviceStatus,
  FacilityType,
  MeasurementTypeType,
} from "@validereinc/domain";
import isNil from "lodash/isNil";
import pick from "lodash/pick";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const DEFAULT_INPUTS = {
  status: DeviceStatus.ACTIVE,
};

// IMPROVE: this should be merged with CreateDevicePage when the Page component form composition is fuxed
const AddDevice = ({ breadcrumbs }) => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { facilityId } = useParams<{ facilityId: string }>();

  const [facility, setFacility] = useState<FacilityType>();
  const [facilityLoadingState, setFacilityLoadingState] = useState("loading");
  const [deviceTypes, deviceTypesLoadingState] = useDeviceTypes();
  const [measurementTypes, measurementTypesLoadingState] =
    useMeasurementTypes();
  const updatedBreadcrumbs = useBreadcrumbs(breadcrumbs, {
    2: facility?.name ?? "",
  });

  useEffect(() => {
    setFacilityLoadingState("loading");

    FacilityService.getFacilityDetail(facilityId).then(({ data }) => {
      setFacility(data);
      setFacilityLoadingState("loaded");
    });
  }, []);

  const form = useForm({
    defaultValues: { ...DEFAULT_INPUTS, facility_id: facilityId },
  });

  useEffect(() => {
    const type = deviceTypes.length && deviceTypes[0];

    form.reset({
      ...DEFAULT_INPUTS,
      ...(type ? { type } : {}),
      ...(facility ? { facility_id: facilityId } : {}),
    });
  }, [facility, deviceTypes]);

  const handleSubmit = async (formValues: any) => {
    const locationData = formValues?.attribute?.location;
    if (
      (isNil(locationData?.[0]) || locationData?.[0] === "") &&
      (isNil(locationData?.[1]) || locationData?.[1] === "")
    ) {
      formValues.attribute.location = null;
    }

    try {
      let body = {
        ...formValues,
        type_id: formValues.type.id,
        status: formValues.status.toLowerCase(),
        measurement_types: formValues.measurementTypes.map(
          ({ id }: MeasurementTypeType) => id
        ),
        attributes: formValues.attribute,
      };

      body = pick(body, [
        "facility_id",
        "name",
        "type_id",
        "status",
        "measurement_types",
        "attributes",
        "custom_attributes",
      ]);

      const { data } = await Device.createOne({ data: body });

      toast.push({
        intent: "success",
        description: `Successfully created new ${formValues.type.name} "${data.name}".`,
      });
      navigate({
        pathname: linkToFacilities(facilityId),
        query: { section: "device" },
      });
    } catch {
      toast.push({
        intent: "error",
        description: `Unable to create ${formValues.type.name} "${formValues.name}".`,
      });
    }
  };

  const handleCancel = () =>
    navigate({ pathname: linkToFacilities(facilityId), replace: true });

  const isLoaded =
    deviceTypesLoadingState === "loaded" &&
    measurementTypesLoadingState === "loaded" &&
    facilityLoadingState === "loaded";

  return (
    <Page
      title="Add Device"
      category="Facility"
      breadcrumbs={updatedBreadcrumbs}
      footer={
        <>
          <Button
            onClick={handleCancel}
            disabled={!isLoaded}
          >
            Cancel
          </Button>

          <Button
            variant="primary"
            type="submit"
            onClick={form.handleSubmit((values) => handleSubmit(values))}
            disabled={!isLoaded || form.formState.isSubmitting}
          >
            Add Device
          </Button>
        </>
      }
    >
      <CreateDeviceForm
        form={form}
        formType="add"
        deviceTypes={deviceTypes}
        deviceTypesLoadingState={deviceTypesLoadingState}
        measurementTypes={measurementTypes}
        measurementTypesLoadingState={measurementTypesLoadingState}
        handleSubmit={handleSubmit}
      />
    </Page>
  );
};

AddDevice.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
};

export default AddDevice;
