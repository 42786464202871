import { useNavigate } from "#src/Routers/hooks";
import PermissionAwareExportButton from "#src/batteries-included-components/Buttons/PermissionAwareExportButton/PermissionAwareExportButton";
import { UserGroupCategoryFormDialog } from "#src/batteries-included-components/Dialogs/UserGroupCategoryFormDialog";
import { UsersDropdownInput } from "#src/batteries-included-components/Dropdowns/UsersDropdownInput";
import { PageErrorContent } from "#src/batteries-included-components/Layouts/Errors/PageError";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useTableSortingAndPagination } from "#src/components/Redux/reducers/tableStateReducer";
import { useExportUserGroups } from "#src/components/hooks/adapters/useUserGroups";
import { useHasPermission } from "#src/contexts/AuthenticatedContext.helpers";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { UsersRoutePath } from "#src/routes/settings/users";
import { UsersListPageTabs } from "#src/routes/settings/users/UserListPage";
import { UserGroupDetailsRoutePath } from "#src/routes/settings/users/groups/[groupId]/details";
import { UserGroupCategoryDetailsRoutePath } from "#src/routes/settings/users/groups/categories/[categoryId]/details";
import { useBreadcrumbsFromRoute } from "#src/utils/route";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  DataTable,
  DataTablePanel,
  DateSelectorInput,
  FilterPanel,
  Icon,
  KeyValuePanel,
  Page,
  TextInput,
  useFilters,
  useToast,
} from "@validereinc/common-components";
import {
  UserGroupCategoriesAdapter,
  UserGroupSchema,
  UserGroupType,
  UserGroupsAdapter,
  UserType,
  UsersAdapter,
} from "@validereinc/domain";
import { dateFormatter } from "@validereinc/utilities";
import endOfDay from "date-fns/endOfDay";
import parseISO from "date-fns/parseISO";
import startOfDay from "date-fns/startOfDay";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router";
import { GroupCreateRoutePath } from "../../../create";

export const UserGroupCategoryDetailsPage = () => {
  const { categoryId } = useParams<{ categoryId: string }>();
  const [userGroupCategoryToEdit, setUserGroupCategoryToEdit] =
    useState<string>();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [isDeleteAllowed] = useHasPermission("user_group_categories:delete");
  const [isEditAllowed] = useHasPermission("user_group_categories:write");
  const [isUserGroupsReadAllowed] = useHasPermission("user_groups:read");
  const query = useQuery({
    queryKey: ["users", "groups", "categories", categoryId],
    queryFn: () => UserGroupCategoriesAdapter.getOne({ id: categoryId }),
    enabled: !!categoryId,
  });
  const [breadcrumbs] = useBreadcrumbsFromRoute(
    UserGroupCategoryDetailsRoutePath,
    {
      "/details": {
        title: query.data?.data.name,
      },
    }
  );
  const [tableState, setTableState] = useTableSortingAndPagination();
  const { tableConfigStorageKey, filterConfigStorageKey } = useStorageKey(
    "user-group-category-user-groups"
  );
  const [filters] = useFilters<{
    search: string;
    updated_at: { from: string; to: string };
    created_at: { from: string; to: string };
    created_by: string;
    updated_by: string;
  }>(filterConfigStorageKey);
  const groupsQueryPayload: Parameters<typeof UserGroupsAdapter.getList>[0] = {
    page: tableState.page,
    pageSize: tableState.pageSize,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: {
      isAlreadyFormatted: true,
      group_category_id: categoryId,
      ...(filters.search
        ? {
            $or: [
              { name: { $like: filters.search } },
              { description: { $like: filters.search } },
            ],
          }
        : {}),
      ...(filters.updated_at?.from && filters.updated_at.to
        ? {
            $and: [
              {
                updated_at: {
                  $gte: startOfDay(parseISO(filters.updated_at.from)),
                },
              },
              {
                updated_at: { $lte: endOfDay(parseISO(filters.updated_at.to)) },
              },
            ],
          }
        : {}),
      ...(filters.created_at?.from && filters.created_at.to
        ? {
            $and: [
              {
                created_at: {
                  $gte: startOfDay(parseISO(filters.created_at.from)),
                },
              },
              {
                created_at: { $lte: endOfDay(parseISO(filters.created_at.to)) },
              },
            ],
          }
        : {}),
      ...(filters.created_by ? { created_by: filters.created_by } : {}),
      ...(filters.updated_by ? { updated_by: filters.updated_by } : {}),
    },
  };
  const groupsQuery = useQuery({
    queryKey: ["users", "groups", groupsQueryPayload],
    queryFn: () => UserGroupsAdapter.getList(groupsQueryPayload),
    enabled: !!categoryId,
  });
  const userIdsToFetchDetailsFor = useMemo<string[]>(
    () =>
      (
        groupsQuery.data?.data.flatMap((i) => [i.created_by, i.updated_by]) ??
        []
      )
        .concat([
          query.data?.data.created_by ?? null,
          query.data?.data.updated_by ?? null,
        ])
        .filter((id) => !!id) as string[],
    [groupsQuery, query]
  );
  const metaUsersQuery = useQuery({
    queryKey: ["users", { filters: { id: userIdsToFetchDetailsFor } }],
    queryFn: () =>
      UsersAdapter.getList({ filters: { id: userIdsToFetchDetailsFor } }),
    enabled: !!userIdsToFetchDetailsFor.length,
  });

  const { mutate: handleExport, isLoading: isExporting } =
    useExportUserGroups(groupsQueryPayload);

  const metaUsersQueryMap = useMemo(() => {
    return (
      metaUsersQuery.data?.data.reduce<Record<string, UserType>>(
        (map, data) => {
          if (!data || map[data.id]) return map;

          map[data.id] = data;
          return map;
        },
        {}
      ) ?? {}
    );
  }, [metaUsersQuery]);
  const deleteMutation = useMutation({
    mutationFn: () => {
      return UserGroupCategoriesAdapter.deleteOne({ id: categoryId });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["users", "groups", "categories"],
      });
      toast.push({
        intent: "success",
        description: "Successfully deleted user group category",
      });
      navigate({
        pathname: UsersRoutePath.toLink(),
        query: {
          tab: UsersListPageTabs.UserGroupsTab,
        },
      });
    },
    onError: () => {
      toast.push({
        intent: "error",
        description: "Failed to delete user group category",
      });
    },
  });

  const data = query.data?.data;

  return (
    <Page
      category={UserGroupCategoryDetailsRoutePath.previous?.title}
      title={data?.name ?? UserGroupCategoryDetailsRoutePath.title}
      breadcrumbs={breadcrumbs}
      isLoading={query.isLoading}
      actionRow={[
        <Button
          key="delete"
          variant="error-outline"
          onClick={() => deleteMutation.mutate()}
          isLoading={deleteMutation.isLoading}
          icon={!isDeleteAllowed ? "lock" : ""}
          disabled={!isDeleteAllowed}
        >
          Delete
        </Button>,
        <Button
          key="edit"
          variant="outline"
          icon={!isEditAllowed ? "lock" : ""}
          disabled={!isEditAllowed}
          onClick={() => {
            setUserGroupCategoryToEdit(categoryId);
          }}
        >
          Edit
        </Button>,
      ]}
      renderMeta={({ MetaSegments }) => (
        <MetaSegments
          values={[
            data?.updated_at
              ? `Last edited ${dateFormatter(new Date(data.updated_at ?? ""))}${
                  data.updated_by && metaUsersQueryMap[data.updated_by]
                    ? ` by ${metaUsersQueryMap[data.updated_by ?? ""].name}`
                    : ""
                }`
              : "",
            data?.updated_at
              ? `Created ${dateFormatter(new Date(data.updated_at ?? ""))}${
                  data.updated_by && metaUsersQueryMap[data.updated_by]
                    ? ` by ${metaUsersQueryMap[data.updated_by].name}`
                    : ""
                }`
              : "",
          ]}
        />
      )}
      error={query.error}
      onErrorRender={({ error }) => <PageErrorContent error={error} />}
    >
      <KeyValuePanel
        panelProps={{
          title: "Details",
        }}
        panelKeyValueListProps={{
          isLoading: query.isLoading,
        }}
        data={[
          {
            title: "Description",
            value: data?.description.trim() ? data?.description : "-",
          },
        ]}
      />
      <FilterPanel
        defaultActiveKeys={["Filters"]}
        storageKey={filterConfigStorageKey}
        filters={[
          {
            component: (
              <TextInput
                type="search"
                name="search"
                placeholder="Search Groups..."
                isInline
              />
            ),
          },
          {
            section: "Filters",
            component: (
              <DateSelectorInput
                name={UserGroupSchema.keyof().Enum.updated_at}
                variant="day"
                isRange
                placeholder="Search by last updated..."
                label="Updated At"
                isFluid
              />
            ),
          },
          {
            section: "Filters",
            component: (
              <UsersDropdownInput
                name={UserGroupSchema.keyof().Enum.updated_by}
                label="Updated By"
                isFluid
              />
            ),
          },
          {
            section: "Filters",
            component: (
              <DateSelectorInput
                name={UserGroupSchema.keyof().Enum.created_at}
                variant="day"
                isRange
                placeholder="Search by creation date..."
                label="Created At"
                isFluid
              />
            ),
          },
          {
            section: "Filters",
            component: (
              <UsersDropdownInput
                name={UserGroupSchema.keyof().Enum.created_by}
                label="Created By"
                isFluid
              />
            ),
          },
        ]}
      />
      <DataTablePanel<UserGroupType>
        storageKey={tableConfigStorageKey}
        panelProps={{
          title: "Groups",
        }}
        actionRowWhenNoRowsSelected={[
          <PermissionAwareExportButton
            key="export-user-groups"
            onClick={handleExport}
            isExporting={isExporting}
          />,
          <Button
            key="create"
            iconPosition="left"
            variant="primary"
            size="small"
            style={{ border: "none" }}
            icon={!isEditAllowed ? "lock" : "plus-circle"}
            disabled={!isEditAllowed}
            onClick={() =>
              navigate({
                pathname: GroupCreateRoutePath.toLink(),
                query: { defaultCategoryId: categoryId },
              })
            }
          >
            Create Group
          </Button>,
        ]}
        dataTableProps={{
          isLoading: groupsQuery.isLoading || metaUsersQuery.isLoading,
          headers: [
            {
              key: "name",
              label: "Name",
              isSortable: true,
              renderComponent: ({ item }) => {
                return (
                  <RoutingLink
                    to={UserGroupDetailsRoutePath.toLink({
                      pathParams: { groupId: item.id },
                    })}
                  >
                    {item.name}
                  </RoutingLink>
                );
              },
            },
            {
              key: "description",
              label: "Description",
              isSortable: true,
              renderComponent: ({ item }) => (
                <DataTable.DataRow.TextCell value={item.description} />
              ),
            },
            {
              key: "updated_at",
              label: "Updated At",
              isSortable: true,
              renderComponent: ({ item }) => (
                <DataTable.DataRow.DateCell
                  value={item.updated_at}
                  withTime
                />
              ),
            },
            {
              key: "updated_by",
              label: "Updated By",
              isSortable: true,
              renderComponent: ({ item }) =>
                metaUsersQueryMap[item.updated_by ?? ""]?.name ?? "-",
            },
            {
              key: "created_at",
              label: "Created At",
              isSortable: true,
              renderComponent: ({ item }) => (
                <DataTable.DataRow.DateCell
                  value={item.created_at}
                  withTime
                />
              ),
            },
            {
              key: "created_by",
              label: "Created By",
              isSortable: true,
              renderComponent: ({ item }) =>
                metaUsersQueryMap[item.created_by ?? ""]?.name ?? "-",
            },
          ],
          items: groupsQuery.data?.data ?? [],
          ...(!isUserGroupsReadAllowed
            ? {
                emptyStateProps: {
                  title: "You don't have the right permissions",
                  icon: <Icon variant="lock" />,
                  suggestion: "Talk to your administrator",
                },
              }
            : {}),
          pagination: {
            page: tableState.page,
            pageSize: tableState.pageSize,
            total: groupsQuery.data?.total_entries,
          },
          sorting: {
            sortBy: tableState.sortBy,
            sortDirection: tableState.sortDirection,
          },
          onSortChange: setTableState,
          onPaginationChange: setTableState,
        }}
      />

      <UserGroupCategoryFormDialog
        isOpen={!!userGroupCategoryToEdit}
        userGroupCategoryId={userGroupCategoryToEdit}
        onClose={() => {
          setUserGroupCategoryToEdit(undefined);
        }}
        onSubmit={() => {
          query.refetch();
        }}
      />
    </Page>
  );
};
