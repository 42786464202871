import { QUERY_STATUS } from "#constants";
import {
  useGetEstimationMethod,
  useGetEstimationMethodRun,
} from "#hooks/useEstimationMethod";
import { useBreadcrumbs } from "#routers/breadcrumbsHelper";
import { linkToFacilities } from "#routers/links";
import { ORGANIZATION_BREADCRUMB } from "#routes/organization";
import { EQUIPMENT_LIST_BREADCRUMB } from "#routes/organization/equipment";
import {
  EQUIPMENT_DETAIL_BREADCRUMB,
  linkToEquipmentDetail,
} from "#routes/organization/equipment/[equipmentId]";
import { ESTIMATION_METHOD_LIST_BREADCRUMB } from "#routes/organization/equipment/[equipmentId]/estimation-method";
import {
  ESTIMATION_METHOD_DETAIL_BREADCRUMB,
  linkToEstimationMethodDetail,
} from "#routes/organization/equipment/[equipmentId]/estimation-method/[methodId]/detail";
import { CALCULATION_RESULT_LIST_BREADCRUMB } from "#routes/organization/equipment/[equipmentId]/estimation-method/[methodId]/result";
import { InputSourceFlair } from "#src/batteries-included-components/Flairs/InputSource";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useActionDetails } from "#src/components/hooks/useActionDetails";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import useLocalization from "#src/hooks/useLocalization";
import { useQuery } from "@tanstack/react-query";
import {
  KeyValuePanel,
  MathDataDisplayEquationsWithBreakdown,
  MetricTileGrid,
  NumberDataDisplay,
  Page,
  Panel,
  Pill,
  type MetricTileGridProps,
} from "@validereinc/common-components";
import {
  AssetType,
  CalculationParameterSavedType,
  CalculatorDomain,
  CalculatorResultsDomain,
  EquipmentDomainV2,
  Resources,
} from "@validereinc/domain";
import {
  DateFormats,
  formatNumberValueAndUnit,
  monthFormatter,
  yearMonthFormatter,
} from "@validereinc/utilities";
import classNames from "classnames/bind";
import parse from "date-fns/parse";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./CalculationResultDetail.module.scss";
import {
  CALCULATION_RESULT_DETAIL_BREADCRUMB,
  CALCULATION_RESULT_DETAIL_FALLBACK_PAGE_TITLE,
} from "./index";

const cx = classNames.bind(styles);

const CalculationResultDetail = () => {
  // state
  const { equipmentId, methodId, resultId } = useParams<{
    equipmentId: string;
    methodId: string;
    resultId: string;
  }>();
  const [resultPeriod] = useState(() =>
    parse(resultId, DateFormats.YEAR_MONTH, new Date())
  );
  const {
    getUnitName,
    getTypeName,
    getPrecisionByType,
    getUnitByType,
    isLoading,
  } = useMeasurementTypes();
  const { data: equipmentDetail, isLoading: isEquipmentLoading } = useQuery({
    queryKey: [Resources.EQUIPMENT, equipmentId, resultId],
    queryFn: () =>
      EquipmentDomainV2.getOne({
        id: equipmentId,
        meta: { period: yearMonthFormatter(resultPeriod) },
      }),
    select: (resp) => resp.data,
    enabled: !!equipmentId && !!resultPeriod,
  });
  const [methodDetail, methodDetailFetchStatus] = useGetEstimationMethod(
    methodId,
    AssetType.EQUIPMENT
  );
  const selectedCalculatorQuery = useQuery({
    queryKey: [Resources.CALCULATOR, methodDetail?.analytics_calculator_id],
    queryFn: () => {
      if (!methodDetail?.analytics_calculator_id) return null;

      return CalculatorDomain.getCalculator({
        calculatorId: methodDetail?.analytics_calculator_id,
      });
    },
    enabled: !!methodDetail?.analytics_calculator_id,
  });
  const { data: resultDetail, status: resultDetailFetchStatus } =
    useGetEstimationMethodRun(
      methodDetail?.id,
      resultPeriod,
      AssetType.EQUIPMENT
    );

  // effects & hooks
  const { localize, isLoading: isMappingLoading } = useLocalization();

  const resultSummaryParams = {
    filters: {
      "estimation_method.id": methodId,
      year_month: yearMonthFormatter(resultPeriod),
    },
  };

  const resultSummaryQuery = useQuery({
    queryKey: ["calculatorResults", resultSummaryParams],
    queryFn: () => CalculatorResultsDomain.getList(resultSummaryParams),
  });

  const lastRunInfo = useActionDetails({
    type: "run",
    date: resultDetail?.updated_at,
    byUserId: resultDetail?.updated_by,
  });

  // computed
  const breadcrumbs = useBreadcrumbs(
    [
      ORGANIZATION_BREADCRUMB,
      EQUIPMENT_LIST_BREADCRUMB,
      EQUIPMENT_DETAIL_BREADCRUMB,
      ESTIMATION_METHOD_LIST_BREADCRUMB,
      ESTIMATION_METHOD_DETAIL_BREADCRUMB,
      CALCULATION_RESULT_LIST_BREADCRUMB,
      CALCULATION_RESULT_DETAIL_BREADCRUMB,
    ],
    { 2: equipmentDetail?.name, 4: methodDetail?.name }
  );

  const lastCalculatorVersion = selectedCalculatorQuery.data?.versions.find(
    ({ version }) => version === selectedCalculatorQuery.data?.default_version
  );
  const resultsAsMetricCardProps = useMemo<MetricTileGridProps["data"]>(() => {
    const results = resultSummaryQuery.data?.data[0]?.measurement;

    if (
      !results ||
      !resultDetail?.output.outputs ||
      !Array.isArray(resultDetail?.output?.outputs)
    )
      return [];

    return Object.entries(results).map(([key, value]) => ({
      title: getTypeName(key),
      description: resultDetail?.output.outputs.find(
        ({ measurement_type }) => measurement_type === key
      )?.description,
      value: (props) => {
        const precision = getPrecisionByType(key);

        return (
          <NumberDataDisplay
            {...props}
            value={value}
            formattingOpts={
              precision
                ? { fractionDigits: precision }
                : {
                    overrideOpts: {
                      maximumFractionDigits: 3,
                      minimumFractionDigits: 2,
                    },
                  }
            }
          />
        );
      },
      unit: getUnitName(getUnitByType(key)),
    }));
  }, [resultDetail, resultSummaryQuery.data, isLoading]);

  const equipmentDetailsAsKeyValuePanelData = useMemo(() => {
    if (!equipmentDetail) {
      return [];
    }

    const hasReportingGroups =
      methodDetail?.reporting_groups &&
      methodDetail.reporting_groups.length > 0;

    return [
      {
        title: "Reporting Scenarios",
        value: !hasReportingGroups ? null : (
          <>
            {methodDetail?.reporting_groups?.map((group) => (
              <Pill
                isCapitalized={false}
                key={group.name}
                variant="primary"
                isBordered
                hasDot={false}
              >
                {group.name.trim()}
              </Pill>
            ))}
          </>
        ),
        description: hasReportingGroups
          ? undefined
          : "Edit the estimation method of this calculation result, to associate reporting scenarios",
      },
      {
        title: localize("Facility"),
        value: (
          <RoutingLink to={linkToFacilities(equipmentDetail.facility_id)}>
            {equipmentDetail?.facility?.name ?? "-"}
          </RoutingLink>
        ),
      },
      {
        title: "Library",
        value: methodDetail?.analytics_library_id,
      },
      {
        title: localize("Equipment"),
        value: (
          <RoutingLink to={linkToEquipmentDetail(equipmentDetail.id)}>
            {equipmentDetail?.name ?? "-"}
          </RoutingLink>
        ),
      },
      {
        title: "Estimation Method",
        value: (
          <RoutingLink
            to={linkToEstimationMethodDetail(
              methodDetail?.id,
              equipmentDetail?.id
            )}
          >
            {methodDetail?.name ?? "-"}
          </RoutingLink>
        ),
      },
      {
        title: `${localize("Equipment")} Type`,
        value: equipmentDetail?.type.name,
      },
      {
        title: "Calculator Name",
        value: lastCalculatorVersion?.title,
      },
    ];
  }, [equipmentDetail, methodDetail, lastCalculatorVersion]);
  const [userInputsAsKeyValuePanelData, defaultInputsAsKeyValuePanelData] =
    useMemo(() => {
      if (
        !resultDetail?.configuration_input ||
        !Array.isArray(resultDetail?.input?.calculation_parameters)
      ) {
        return [[], []];
      }

      const {
        configuration_input: configuredInputs,
        input: { calculation_parameters: calculationParameters },
      } = resultDetail;

      const getDisplayValue = (
        calculationInputAsOutput: CalculationParameterSavedType
      ) => {
        const {
          type,
          measurement_value: value,
          measurement_unit: unit,
        } = calculationInputAsOutput ?? {};

        switch (type) {
          case "number":
            return (
              formatNumberValueAndUnit(
                {
                  value,
                  unit: getUnitName(unit),
                },
                {
                  fractionDigits: getPrecisionByType(type),
                  overrideOpts: {
                    maximumFractionDigits: 3,
                  },
                }
              ) ?? "-"
            );
          case "boolean":
          case "string":
          default:
            return String(value) ?? "-";
        }
      };

      return calculationParameters.reduce<
        [
          Array<{ title: string; value: string | React.ReactNode }>,
          Array<{ title: string; value: string | React.ReactNode }>,
        ]
      >(
        ([userData, defaultData], parameter) => {
          const { id } = parameter ?? {};
          // if the current parameter is found in the configured inputs, then this input was user-configured
          const isManuallyConfigured = Object.keys(configuredInputs).find(
            (configuredInputId) => configuredInputId === id
          );
          const item = {
            title: parameter.display_name,
            value: (
              <div className={cx("detailValueWithContext")}>
                <span>{getDisplayValue(parameter)}</span>
                {/* TODO: Confirm if back-end changes match what this component needs. At the time of writing, it should be. */}
                <InputSourceFlair
                  inputParameter={parameter}
                  estimationMethodRun={resultDetail}
                />
              </div>
            ),
          };

          if (isManuallyConfigured) {
            userData.push(item);
          } else {
            defaultData.push(item);
          }

          return [userData, defaultData];
        },
        [[], []]
      );
    }, [resultDetail]);

  return (
    <Page
      isLoading={isLoading || isEquipmentLoading}
      breadcrumbs={breadcrumbs}
      category="Calculation Result"
      title={
        monthFormatter(resultPeriod) ??
        CALCULATION_RESULT_DETAIL_FALLBACK_PAGE_TITLE
      }
      renderMeta={
        !lastRunInfo.description
          ? undefined
          : ({ MetaSegments }) => (
              <MetaSegments values={[lastRunInfo.description]} />
            )
      }
    >
      <div className={cx("container")}>
        <Panel
          title="Results"
          isFluidY={false}
          style={{ alignSelf: "flex-start", marginBottom: 0 }}
        >
          <MetricTileGrid
            data={resultsAsMetricCardProps}
            isLoading={resultDetailFetchStatus === QUERY_STATUS.LOADING}
          />
        </Panel>
        <KeyValuePanel
          panelMaxColumnCount={2}
          panelKeyValueListContainerProps={{
            style: { padding: 0 },
          }}
          panelProps={{
            title: "Details",
            isFluidY: false,
            loaded: !isMappingLoading,
            style: { alignSelf: "flex-start", marginBottom: 0 },
          }}
          data={equipmentDetailsAsKeyValuePanelData}
        />
        {userInputsAsKeyValuePanelData.length ? (
          <KeyValuePanel
            panelMaxColumnCount={2}
            panelKeyValueListContainerProps={{
              style: { padding: 0 },
            }}
            panelProps={{
              title: "User Inputs",
              isFluidY: false,
              loaded: !isMappingLoading,
              style: { alignSelf: "flex-start", marginBottom: 0 },
            }}
            data={userInputsAsKeyValuePanelData}
          />
        ) : null}
        {defaultInputsAsKeyValuePanelData.length ? (
          <KeyValuePanel
            panelMaxColumnCount={2}
            panelKeyValueListContainerProps={{
              style: { padding: 0 },
            }}
            panelProps={{
              title: "Default Inputs",
              isFluidY: false,
              loaded: !isMappingLoading,
              style: { alignSelf: "flex-start", marginBottom: 0 },
            }}
            data={defaultInputsAsKeyValuePanelData}
          />
        ) : null}
        {typeof lastCalculatorVersion?.documentation === "object" &&
        Object.keys(lastCalculatorVersion?.documentation ?? {}).length ? (
          <Panel
            isFluidY={false}
            style={{ marginBottom: 0 }}
          >
            <MathDataDisplayEquationsWithBreakdown
              title={lastCalculatorVersion?.documentation.title}
              sourceLink={lastCalculatorVersion?.documentation.link}
              equations={lastCalculatorVersion?.documentation.calculations.map(
                (calc) => ({
                  equation: calc.equation,
                  reference: calc.reference,
                  terms: calc.conditions.map((cond) => ({
                    math: cond.variable_name,
                    description: cond.variable_description,
                  })),
                })
              )}
              isLoading={selectedCalculatorQuery.isLoading}
            />
          </Panel>
        ) : null}
      </div>
    </Page>
  );
};

export default CalculationResultDetail;
