import { TemplatedConfigurationRunDrawer } from "#src/batteries-included-components/Drawers/TemplatedConfigurationRunDrawer";
import { EstimationMethodDetailViewFilterArea } from "#src/batteries-included-components/FilterAreas/EstimationMethodsFilterAreas/EstimationMethodsFilterAreas";
import { IsFeatureAvailable } from "#src/batteries-included-components/Layouts/Authorization/IsFeatureAvailable";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { useQuery } from "@tanstack/react-query";
import {
  MathDataDisplayEquationsWithBreakdown,
  Panel,
  Link,
} from "@validereinc/common-components";
import {
  CalculatorDomain,
  EstimationMethodDomain,
  Resources,
  TemplatedConfigurationRunAdapter,
  type AssetTypeType,
} from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { useState } from "react";
import EstimationMethodDetailsPanel, {
  EstimationMethodDetailsPanelProps,
} from "./EstimationMethodDetailsPanel";
import styles from "./EstimationMethodOverviewTab.module.scss";

const cx = classNames.bind(styles);

const EstimationMethodOverviewTab = ({
  detailsPanelProps = {},
  methodId,
  assetType,
  period,
}: {
  detailsPanelProps?: EstimationMethodDetailsPanelProps;
  methodId: string;
  assetType: AssetTypeType;
  period?: string;
}) => {
  const [isTemplatedConfigsAvailable] = useIsFeatureAvailable({
    featureFlagQuery: "core:templated_configurations",
    permissionQuery: "templated_configurations:read",
  });
  const { viewConfigStorageKey } = useStorageKey("estimation-method-overview");

  const [
    isTemplatedConfigurationRunDrawerOpen,
    setIsTemplatedConfigurationRunDrawerOpen,
  ] = useState(false);

  const methodDetailsQuery = useQuery({
    queryKey: [Resources.ESTIMATION_METHOD, methodId, { assetType, period }],
    queryFn: () => {
      return EstimationMethodDomain.getOne({
        id: methodId,
        meta: {
          entityType: assetType,
        },
        ...(period
          ? {
              data: {
                period,
              },
            }
          : {}),
      });
    },
    enabled: !!methodId,
  });
  const calculatorDetailsQuery = useQuery({
    queryKey: [
      Resources.CALCULATOR,
      methodDetailsQuery.data?.analytics_calculator_id,
    ],
    queryFn: () => {
      if (!methodDetailsQuery.data?.analytics_calculator_id) return null;

      return CalculatorDomain.getCalculator({
        calculatorId: methodDetailsQuery.data.analytics_calculator_id,
      });
    },
    enabled: !!methodDetailsQuery.data?.analytics_calculator_id,
  });

  const templatedConfigurationRunDependencyGraphQuery = useQuery({
    queryKey: [
      Resources.TEMPLATED_CONFIGURATION_RUN,
      "dependencyGraph",
      methodDetailsQuery.data?.id,
    ],
    queryFn: () => {
      if (!methodDetailsQuery.data?.id) return null;

      return TemplatedConfigurationRunAdapter.dependencyGraph.getOne({
        id: methodDetailsQuery.data.id,
      });
    },
    enabled: isTemplatedConfigsAvailable && !!methodDetailsQuery.data?.id,
  });

  const templatedConfigurationRunDependencyGraph =
    templatedConfigurationRunDependencyGraphQuery?.data?.data;

  const templatedConfigurationLink =
    templatedConfigurationRunDependencyGraph?.name ? (
      <Link
        onClick={() => setIsTemplatedConfigurationRunDrawerOpen(true)}
        label={templatedConfigurationRunDependencyGraph.name}
      />
    ) : undefined;

  const lastCalculatorVersion = calculatorDetailsQuery.data?.versions.find(
    ({ version }: { version: string }) =>
      version === calculatorDetailsQuery.data?.default_version
  );

  return (
    <div className={cx("container")}>
      <EstimationMethodDetailViewFilterArea
        viewConfigStorageKey={viewConfigStorageKey}
      />
      <div className={cx("container")}>
        <EstimationMethodDetailsPanel
          {...detailsPanelProps}
          templatedConfigurationLink={templatedConfigurationLink}
        />
        {typeof lastCalculatorVersion?.documentation === "object" &&
        Object.keys(lastCalculatorVersion?.documentation ?? {}).length ? (
          <Panel
            isFluidY={false}
            style={{ marginBottom: 0 }}
          >
            <MathDataDisplayEquationsWithBreakdown
              title={lastCalculatorVersion?.documentation.title}
              sourceLink={lastCalculatorVersion?.documentation.link}
              equations={lastCalculatorVersion?.documentation.calculations.map(
                (calc) => ({
                  equation: calc.equation,
                  reference: calc.reference,
                  terms: calc.conditions.map((cond) => ({
                    math: cond.variable_name,
                    description: cond.variable_description,
                  })),
                })
              )}
              isLoading={calculatorDetailsQuery.isLoading}
            />
          </Panel>
        ) : null}
      </div>
      <IsFeatureAvailable
        featureFlagQuery="core:templated_configurations"
        permissionQuery="templated_configurations:read"
        fallbackChildren={null}
      >
        <TemplatedConfigurationRunDrawer
          isOpen={isTemplatedConfigurationRunDrawerOpen}
          onClose={() => setIsTemplatedConfigurationRunDrawerOpen(false)}
          templatedConfigurationRunDependencyGraph={
            templatedConfigurationRunDependencyGraph
          }
          resourceId={methodDetailsQuery.data?.id ?? methodId}
        />
      </IsFeatureAvailable>
    </div>
  );
};

export default EstimationMethodOverviewTab;
