import { WorkflowGraphEdgeType } from "#src/batteries-included-components/Graph/WorkflowGraph/WorkflowGraphEdge/types";
import WorkflowGraphEdge, {
  WorkflowGraphEdgeOffset,
} from "#src/batteries-included-components/Graph/WorkflowGraph/WorkflowGraphEdge/WorkflowGraphEdge";
import {
  WorkflowGraphNodeDataType,
  WorkflowGraphNodeType,
} from "#src/batteries-included-components/Graph/WorkflowGraph/WorkflowGraphNode/types";
import WorkflowGraphNode from "#src/batteries-included-components/Graph/WorkflowGraph/WorkflowGraphNode/WorkflowGraphNode";
import { useGetOneWorkflowTemplate } from "#src/components/hooks/adapters/useWorkflowTemplates";
import { useQuery } from "@tanstack/react-query";
import { IconVariants } from "@validereinc/common-components";
import {
  FormSchemaAdapter,
  FormSchemaType,
  isTriggerCron,
  isTriggerFormSubmissionCreate,
  SystemActionTypeType,
  TypeOfObjectValues,
  UserTaskTypeType,
  WorkflowStepTypeType,
  WorkflowTemplateType,
} from "@validereinc/domain";
import { cronToSimpleDisplayText } from "@validereinc/utilities";
import {
  Edge,
  EdgeMouseHandler,
  Node,
  NodeMouseHandler,
  Position,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "@xyflow/react";
import dagre from "dagre";
import { isObject } from "lodash";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { WorkflowVisualizationProp } from "./WorkflowGraphCanvas";

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 296;
const nodeHeight = 110 + WorkflowGraphEdgeOffset;

// IMPROVE: This algorithm can use an improvement:
const getLayoutedElements = (
  nodes: WorkflowGraphNodeType[] = [],
  edges: WorkflowGraphEdgeType[] = [],
  direction = "LR"
): {
  nodes: WorkflowGraphNodeType[];
  edges: WorkflowGraphEdgeType[];
} => {
  const isHorizontal = direction === "LR";
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  const newNodes = nodes.map((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    const newNode = {
      ...node,
      targetPosition: (isHorizontal ? "left" : "top") as Position,
      sourcePosition: (isHorizontal ? "right" : "bottom") as Position,
      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      position: {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      },
    };

    return newNode;
  });

  return { nodes: newNodes, edges };
};

const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements();

const workflowTemplateStepToGraphNode = (
  stepId: string,
  step: TypeOfObjectValues<WorkflowTemplateType["config"]["steps"]>,
  { formSchemas: _ }: { formSchemas: Record<string, FormSchemaType> } = {
    formSchemas: {},
  }
): WorkflowGraphNodeType => {
  const stepTypeToIconMap: Record<
    UserTaskTypeType | SystemActionTypeType,
    IconVariants
  > = {
    create_event: "plus-clock-offset",
    form_choice: "clipboard-check",
    delay: "clock-countdown",
    create_form: "clipboard-plus",
    lock_record: "file-lock-simple",
    run_templated_report: "presentation-plus",
    run_default_record_value_configuration: "file-play",
    manual_task: "wrench",
    submit_form: "clipboard-text",
    complete_event: "clock",
    choice: "check-square-offset",
    assess_templated_report_for_comments: "comment-plus",
  };

  const stepTypeToTitleMap: Record<
    UserTaskTypeType | SystemActionTypeType,
    string
  > = {
    create_event: "Create Event",
    form_choice: "Form Choice",
    delay: "Delay",
    create_form: "Create Form",
    lock_record: "Lock Records",
    run_templated_report: "Run Templated Report",
    run_default_record_value_configuration: "Run Default Automation",
    manual_task: "Manual Task",
    submit_form: "Submit Form",
    complete_event: "Complete Event",
    choice: "Choice",
    assess_templated_report_for_comments:
      "Assess Templated Report for Comments",
  };

  const stepTypeToVariantMap: Record<
    WorkflowStepTypeType,
    WorkflowGraphNodeType["data"]["variant"]
  > = {
    system_action: "system",
    user_task: "generic",
  };

  const nodeData: WorkflowGraphNodeType = {
    id: stepId,
    position: { x: 0, y: 0 },
    type: "workflowNode",
    data: {
      icon: stepTypeToIconMap[step.task.type],
      iconTitle: stepTypeToTitleMap[step.task.type],
      variant: stepTypeToVariantMap[step.type],
      title: step.name,
    },
  };

  if (step.description) {
    nodeData.data.description = step.description;
  }

  if (step.task.type === "delay") {
    const { duration, time_period } = step.task;
    // All available values for "time_period" look plural, and that's how it's defined in BE.
    // Remove last letter ("s") if duration = 1
    nodeData.data.title = `Wait for ${duration} ${duration === 1 ? time_period.slice(0, -1) : time_period}`;
  }

  return nodeData;
};

const workflowTemplateToTriggerNode = (
  stepId: string,
  trigger: WorkflowTemplateType["triggers"][0],
  { formSchemas }: { formSchemas: Record<string, FormSchemaType> } = {
    formSchemas: {},
  }
): WorkflowGraphNodeType => {
  const node: WorkflowGraphNodeType = {
    id: stepId,
    position: { x: 0, y: 0 },
    type: "workflowNode",
    data: {
      icon: "cube",
      variant: "trigger",
      title: "Miscellaneous",
    },
  };

  if (isTriggerFormSubmissionCreate(trigger)) {
    const associatedFormSchemaName =
      formSchemas?.[trigger.form_schema_id]?.name;
    node.data.title = `"${associatedFormSchemaName}" Submission`;
    node.data.description =
      formSchemas?.[trigger.form_schema_id]?.form_category.name;
    node.data.icon = "clipboard-text";
  }

  if (isTriggerCron(trigger)) {
    node.data.title = "Scheduled";
    node.data.description = cronToSimpleDisplayText(trigger.cron);
    node.data.icon = "clock";
  }

  return node;
};

const workflowTemplateToNodes = (
  template: WorkflowTemplateType,
  formSchemas: Record<string, FormSchemaType> = {}
): WorkflowGraphNodeType[] => {
  const nodes: WorkflowGraphNodeType[] = [];

  // Add trigger nodes
  template.triggers.forEach((trigger, index) => {
    const triggerNode = workflowTemplateToTriggerNode(
      `trigger-${trigger.type}-${index}`,
      trigger,
      {
        formSchemas,
      }
    );
    nodes.push(triggerNode);
  });

  Object.entries(template.config.steps).forEach(([stepId, stepDefinition]) => {
    // Add step nodes
    const node = workflowTemplateStepToGraphNode(stepId, stepDefinition, {
      formSchemas,
    });

    // Add finish nodes
    if (stepDefinition.end) {
      const finishWorkflowNode: WorkflowGraphNodeType = {
        id: `workflow-node-${stepId}-end`,
        position: { x: 0, y: 0 },
        type: "workflowNode",
        data: {
          icon: "seal-check",
          title: "Workflow Complete",
          variant: "finish",
        },
      };
      nodes.push(finishWorkflowNode);
    }

    nodes.push(node);
  });

  return nodes;
};

const workflowTemplateToEdges = (
  template: WorkflowTemplateType,
  { formSchemas: _ }: { formSchemas: Record<string, FormSchemaType> }
): WorkflowGraphEdgeType[] => {
  const finalEdges: WorkflowGraphEdgeType[] = [];

  Object.entries(template.config.steps).forEach(([stepId, step]) => {
    // Edges from triggers to start node
    if (stepId === template.config.start) {
      template.triggers.forEach((trigger, index) => {
        finalEdges.push({
          id: `trigger-edge-${index}-${stepId}`,
          source: `trigger-${trigger.type}-${index}`,
          target: stepId,
          type: "workflowEdge",
          data: {},
        });
      });
    }

    // Edges end nodes to their associated "Finish" nodes
    if (step.end) {
      finalEdges.push({
        id: `workflow-edge-${stepId}-to-end`,
        source: stepId,
        target: `workflow-node-${stepId}-end`,
        type: "workflowEdge",
        data: {},
      });
    }

    // Handling multi-way edges:
    if (step.task.type === "form_choice" || step.task.type === "choice") {
      const choices = step.task.choices;

      const uniqueChoices = Array.from(
        new Set(choices.map((c) => JSON.stringify(c)))
      ).map((c) => JSON.parse(c) as (typeof step.task.choices)[0]);

      uniqueChoices.forEach((choice, index) => {
        let label = "";
        let icon: IconVariants | undefined = undefined;

        if (step.task.type === "form_choice") {
          const answer_filter = (choice as (typeof step.task.choices)[0])
            .answer_filter;

          icon = "clipboard-check";
          if (answer_filter["answer.value"]?.$in) {
            label = (answer_filter["answer.value"].$in as string[]).join(", ");
          } else if (isObject(answer_filter["answer.value"])) {
            label = JSON.stringify(answer_filter["answer.value"]);
          } else {
            label = answer_filter["answer.value"];
          }
        }

        if (step.task.type === "choice") {
          label = step.task.choices[index].name;
          icon = "check-square-offset";
        }

        if (choice?.next) {
          finalEdges.push({
            id: `${stepId}-${choice?.next ? choice.next : "end"}-${index}`,
            source: stepId,
            target: choice.next,
            type: "workflowEdge",
            data: { label, icon },
          });
        }
      });

      if (
        !finalEdges.find((e) => e.id.startsWith(`${stepId}-${step.next}-`)) &&
        step.next
      ) {
        finalEdges.push({
          id: `${stepId}-${step.next}`,
          source: stepId,
          target: step.next,
          type: "workflowEdge",
          data: { label: "Default answer" },
        });
      } else {
        const find = finalEdges.findIndex((e) =>
          e.id.startsWith(`${stepId}-${step.next}-`)
        );
        if (finalEdges[find]?.data)
          finalEdges[find].data.label += " & Other answers";
      }
    } else if (step.task.type === "assess_templated_report_for_comments") {
      finalEdges.push({
        id: `${stepId}-${step.task.assessment_success_next}`,
        source: stepId,
        target: step.task.assessment_success_next,
        type: "workflowEdge",
        data: { label: "Success", icon: "check-circle" },
      });
      finalEdges.push({
        id: `${stepId}-${step.task.assessment_failure_next}`,
        source: stepId,
        target: step.task.assessment_failure_next,
        type: "workflowEdge",
        data: { label: "Failure", icon: "x-circle" },
      });
    } else {
      if (step.next)
        finalEdges.push({
          id: `${stepId}-${step.next}`,
          source: stepId,
          target: step.next,
          type: "workflowEdge",
          data: {},
        });
    }
  });

  return finalEdges;
};

const extractFormSchemaIdsFromWorkflowTemplate = (
  workflowTemplate?: WorkflowTemplateType
): string[] => {
  if (!workflowTemplate) return [];

  const neededFormSchemas: string[] = [];

  Object.values(workflowTemplate.config.steps).map((step) => {
    if (
      (step.task.type === "submit_form" || step.task.type === "form_choice") &&
      step.task.form_schema_id
    ) {
      neededFormSchemas.push(step.task.form_schema_id);
    }
  });

  workflowTemplate.triggers.forEach((trigger) => {
    if (isTriggerFormSubmissionCreate(trigger)) {
      neededFormSchemas.push(trigger.form_schema_id);
    }
  });

  return neededFormSchemas;
};

const useWorkflowGraph = (props: WorkflowVisualizationProp) => {
  const { fitView, updateNode } = useReactFlow();
  const [nodes, setNodes, onNodesChange] =
    useNodesState<WorkflowGraphNodeType>(layoutedNodes);
  const [edges, setEdges, onEdgesChange] =
    useEdgesState<WorkflowGraphEdgeType>(layoutedEdges);

  // There's currently only one type of node and edge to render in this graph:
  const nodeTypes = {
    workflowNode: WorkflowGraphNode,
  };
  const edgeTypes = {
    workflowEdge: WorkflowGraphEdge,
  };

  const { templateId } = props;

  const workflowTemplateQuery = useGetOneWorkflowTemplate(
    {
      id: templateId,
    },
    {
      enabled: !!templateId,
    }
  );
  const workflowTemplate = workflowTemplateQuery.data?.data;

  const formSchemaIdsToFetch = useMemo(
    () => extractFormSchemaIdsFromWorkflowTemplate(workflowTemplate),
    [workflowTemplate]
  );

  const formSchemasQuery = useQuery({
    queryKey: ["formSchema", { $in: formSchemaIdsToFetch }],
    queryFn: async () =>
      FormSchemaAdapter.getList({
        filters: {
          id: formSchemaIdsToFetch,
        },
        pageSize: 500,
      }),
    enabled: formSchemaIdsToFetch.length > 0,
  });

  const sortedFormSchemasById = useMemo(() => {
    const sorted: Record<string, FormSchemaType> = {};

    (formSchemasQuery.data?.data ?? []).forEach((formSchema) => {
      sorted[formSchema.id] = formSchema;
    });

    return sorted;
  }, [formSchemasQuery.data?.data]);

  const isLoading =
    (formSchemaIdsToFetch.length > 0 && formSchemasQuery.isFetching) ||
    (!!templateId && workflowTemplateQuery.isFetching);

  const generatedNodesAndEdges = useMemo(() => {
    if (isLoading || !workflowTemplate)
      return { finalNodes: [], finalEdges: [] };

    const finalNodes = workflowTemplateToNodes(
      workflowTemplate,
      sortedFormSchemasById
    );
    const finalEdges = workflowTemplateToEdges(workflowTemplate, {
      formSchemas: sortedFormSchemasById,
    });

    return { finalNodes, finalEdges };
  }, [sortedFormSchemasById, workflowTemplate, isLoading]);

  const initialSetupVariables = useRef({
    initialNodesAndEdgesSet: false,
    initialFitViewRan: false,
  });

  useEffect(() => {
    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      generatedNodesAndEdges.finalNodes,
      generatedNodesAndEdges.finalEdges,
      "TB"
    );
    setEdges([...layoutedEdges]);
    setNodes([...layoutedNodes]);
    initialSetupVariables.current.initialNodesAndEdgesSet = true;
  }, [JSON.stringify(generatedNodesAndEdges)]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | undefined;
    if (
      initialSetupVariables.current.initialNodesAndEdgesSet &&
      !initialSetupVariables.current.initialFitViewRan
    ) {
      timeout = setTimeout(() => {
        window.requestAnimationFrame(() => {
          fitView();
          initialSetupVariables.current.initialFitViewRan = true;
        });
      }, 100);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [JSON.stringify({ nodes, edges })]);

  const onLayout = useCallback(
    (direction) => {
      const { nodes: layoutedNodes, edges: layoutedEdges } =
        getLayoutedElements(nodes, edges, direction);
      setNodes([...layoutedNodes]);
      setEdges([...layoutedEdges]);
    },
    [nodes, edges]
  );

  const onEdgeMouseEnter: EdgeMouseHandler = (_, hoveredEdge: Edge) => {
    setNodes((oldNodes) =>
      oldNodes.map((newNode) => {
        const isConnected = [hoveredEdge.source, hoveredEdge.target].includes(
          newNode.id
        );

        return {
          ...newNode,
          data: {
            ...newNode.data,
            isTranslucent: !isConnected,
            isHovered: isConnected,
          },
        } as WorkflowGraphNodeType;
      })
    );

    setEdges((oldEdges) =>
      oldEdges.map((newEdge) => {
        return {
          ...newEdge,
          data: {
            ...newEdge.data,
            isDisabled: newEdge.id !== hoveredEdge.id,
            isHovered: newEdge.id === hoveredEdge.id,
          },
        };
      })
    );
  };

  const onEdgeMouseLeave: EdgeMouseHandler = () => {
    setNodes((oldNodes) =>
      oldNodes.map((newNode) => {
        const newData = { ...newNode.data };
        delete newData.isTranslucent;
        delete newData.isHovered;
        return {
          ...newNode,
          data: newData,
        } as WorkflowGraphNodeType;
      })
    );

    setEdges((oldEdges) =>
      oldEdges.map((newEdge) => {
        const newData: WorkflowGraphEdgeType["data"] = { ...newEdge.data };
        delete newData.isDisabled;
        delete newData.isHovered;

        return {
          ...newEdge,
          data: newData,
        } as WorkflowGraphEdgeType;
      })
    );
  };

  const onNodeMouseEnter: NodeMouseHandler = (_, node: Node) => {
    updateNode(node.id, {
      data: {
        ...node.data,
        isHovered: true,
      } as WorkflowGraphNodeDataType,
    });
  };

  const onNodeMouseLeave: NodeMouseHandler = (_, node: Node) => {
    updateNode(node.id, {
      data: { ...node.data, isHovered: false } as WorkflowGraphNodeDataType,
    });
  };

  return {
    nodeTypes,
    edgeTypes,
    nodes,
    setNodes,
    onNodesChange,
    edges,
    setEdges,
    onEdgesChange,
    onLayout,
    onEdgeMouseEnter,
    onEdgeMouseLeave,
    onNodeMouseEnter,
    onNodeMouseLeave,
    fitView,
    isLoading,
  };
};

export { useWorkflowGraph };
