import RecordOfQualityService from "#services/RecordOfQualityService";
import { Button, useToast } from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useState } from "react";
import { Checkbox } from "react-bootstrap";
import { useSelectionModalContext } from "./Context/selectionModalContext";
import "./RecordOfQualityModalFooter.scss";

const RecordOfQualityModalFooter = (props) => {
  const {
    recordOfQualityDetail,
    selectionDataLoadingState,
    approveSelection,
    hasWriteAccess,
    resultLoadingState,
    actionState,
    setActionState,
  } = useSelectionModalContext();

  const { approved_by, id } = recordOfQualityDetail;
  const { toast } = useToast();

  const [buttonState, setButtonState] = useState("enabled");

  const onGenerateRecordOfQualityReportClick = () => {
    setButtonState("disabled");

    RecordOfQualityService.generateRecordOfQualityReport(id)
      .then(() => {
        toast.push({
          intent: "success",
          description:
            "Your Record of Quality report will be sent to your email shortly.",
        });
      })
      .finally(() => {
        setButtonState("enabled");
      });
  };

  const onResetClick = () => {
    props.setShowResetSelectionModal(true);
  };

  const onApproveClick = (event) => {
    // Stops function from running twice due to nested elements (propagate)
    event.preventDefault();

    setActionState("disabled");

    approveSelection().finally(() => {
      setActionState("enabled");
    });
  };

  const allFetchesDone =
    selectionDataLoadingState === "loaded" && resultLoadingState === "loaded";
  const disableButton = actionState !== "enabled" || !allFetchesDone;

  return (
    <div className="recordOfQualityModalFooter">
      <Button
        className="pull-left"
        onClick={props.onClose}
      >
        Close
      </Button>
      <section className="action">
        <Button
          onClick={onGenerateRecordOfQualityReportClick}
          disabled={buttonState !== "enabled" || !id}
        >
          Generate Record of Quality report
        </Button>

        {hasWriteAccess && (
          <>
            <Button
              onClick={onResetClick}
              disabled={disableButton}
            >
              Reset Selection
            </Button>

            <Button
              className="checkboxButton"
              onClick={onApproveClick}
              disabled={disableButton}
            >
              <Checkbox
                readOnly
                checked={!!approved_by}
              >
                Approved
              </Checkbox>
            </Button>
          </>
        )}
      </section>
    </div>
  );
};

RecordOfQualityModalFooter.propTypes = {
  addAlertMessage: PropTypes.func,
  actionState: PropTypes.string,
  setActionState: PropTypes.func,
  setShowResetSelectionModal: PropTypes.func,
  onClose: PropTypes.func,
};

export default RecordOfQualityModalFooter;
