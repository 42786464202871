import classNames from "classnames/bind";
import truncate from "lodash/truncate";
import React, { useState } from "react";
import { Marker as MapboxMarker, type MarkerProps } from "react-map-gl";
import styles from "./Marker.module.scss";
import MarkerIcon, { type MarkerIconProps } from "./MarkerIcon";

const cx = classNames.bind(styles);

const Marker = ({
  iconProps,
  colorVariant,
  label,
  isLabelToggleable = false,
  ...mapboxMarkerProps
}: {
  label: string;
  isLabelToggleable?: boolean;
} & MarkerProps &
  MarkerIconProps) => {
  const [showLabel, setShowLabel] = useState(!isLabelToggleable);

  return (
    <>
      <MapboxMarker
        onClick={() =>
          isLabelToggleable ? setShowLabel((prevState) => !prevState) : null
        }
        clickTolerance={10}
        anchor="bottom"
        {...mapboxMarkerProps}
      >
        <MarkerIcon
          iconProps={iconProps}
          colorVariant={colorVariant}
        />
      </MapboxMarker>
      {showLabel && label ? (
        <MapboxMarker
          anchor="top"
          {...mapboxMarkerProps}
        >
          <div className={cx("label")}>{truncate(label, { length: 25 })}</div>
        </MapboxMarker>
      ) : null}
    </>
  );
};

export default Marker;
