import { FilterArea } from "#src/components/FilterArea";
import { useTableSortingAndPagination } from "#src/components/Redux/reducers/tableStateReducer";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { useNavigate } from "#src/Routers/hooks";
import { TemplatedReportsCategoriesDetailRoutePath } from "#src/routes/reports/templated-reports/categories/[categoryId]";
import { ExceptionUtils } from "#src/utils/exception";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  DateDataDisplay,
  Dialog,
  EmptyState,
  Flex,
  Form,
  GridCard,
  Pagination,
  Panel,
  Skeleton,
  TextAreaInput,
  TextInput,
  useForm,
  useToast,
  type SubmitHandler,
} from "@validereinc/common-components";
import {
  BaseError,
  CreateOneTemplatedReportCategorySchema,
  Resources,
  TemplatedReportAdapter,
  type CreateOneTemplatedReportCategoryType,
} from "@validereinc/domain";
import { DateFormats } from "@validereinc/utilities";
import React, { useState } from "react";

export const TemplatedReportsCategoriesTab = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const { filterConfigStorageKey } = useStorageKey(
    "templated-reports-all-categories"
  );
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const createCategoryForm = useForm<CreateOneTemplatedReportCategoryType>();
  const [filters] = useSessionStickyState({}, filterConfigStorageKey);
  const [tableState, updateTableState] = useTableSortingAndPagination(
    {
      sortBy: "name",
      sortDirection: "asc",
    },
    filters
  );
  const listQueryParams: Parameters<
    typeof TemplatedReportAdapter.categories.getList
  >[0] = {
    page: tableState.page,
    pageSize: tableState.pageSize,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters: filters.search
      ? {
          $or: [
            {
              name: { $like: filters.search },
            },
            {
              description: { $like: filters.search },
            },
          ],
          isAlreadyFormatted: true,
        }
      : {},
  };
  const listQuery = useQuery({
    queryKey: [Resources.TEMPLATED_REPORT, "categories", listQueryParams],
    queryFn: () => TemplatedReportAdapter.categories.getList(listQueryParams),
    staleTime: 5 * 60 * 1000,
  });
  const createCategoryMutation = useMutation({
    mutationFn: (params: CreateOneTemplatedReportCategoryType) => {
      const vResult = CreateOneTemplatedReportCategorySchema.safeParse(params);

      if (vResult.error) {
        throw new BaseError(
          "Failed to validate create templated report category form data.",
          {
            errors: [vResult.error],
          }
        );
      }

      return TemplatedReportAdapter.categories.createOne({
        data: params,
      });
    },
    onSuccess: (data) => {
      setIsCreateDialogOpen(false);
      toast.push({
        intent: "success",
        description: `Successfully created category ${data.data.name}.`,
      });
      queryClient.invalidateQueries({
        queryKey: [Resources.TEMPLATED_REPORT, "categories"],
      });
      createCategoryForm.reset();
    },
    onError: (err, vars) => {
      toast.push({
        intent: "error",
        description: `Failed to create category${vars.name ? ` ${vars.name}` : ""}.`,
      });
      ExceptionUtils.reportException(err, "error", {
        sourceComponent: "TemplatedReportsCategoriesTab",
      });
    },
  });

  const handleCreateCategorySubmit: SubmitHandler<
    CreateOneTemplatedReportCategoryType
  > = (formValues) => {
    createCategoryMutation.mutate(formValues);
  };

  const createCategoryFormInputKeys =
    CreateOneTemplatedReportCategorySchema.keyof().Enum;

  return (
    <>
      <Panel
        title="Categories"
        actionRow={[
          <Button
            variant="primary"
            icon="plus-circle"
            key="create"
            onClick={() => setIsCreateDialogOpen(true)}
          >
            Create Category
          </Button>,
          <FilterArea.Root
            storageKey={filterConfigStorageKey}
            key="filters"
          >
            <FilterArea.Container aria-label="Filters for Templated Report Categories">
              <FilterArea.Content>
                {({ handleOnChange }) => {
                  return (
                    <div style={{ marginLeft: 8 }}>
                      <TextInput
                        name="search"
                        type="search"
                        label="Search"
                        placeholder="Search..."
                        isInline
                        isLabelShown={false}
                        onChange={(val) => handleOnChange(val, "search")}
                      />
                    </div>
                  );
                }}
              </FilterArea.Content>
            </FilterArea.Container>
          </FilterArea.Root>,
        ]}
        loaded={!listQuery.isLoading}
      >
        {listQuery?.data?.data.length === 0 ? (
          <EmptyState
            title="There are no categories to display"
            suggestion="Please try adjusting your filters"
          />
        ) : (
          <>
            <Flex.Container variant="grid">
              {listQuery.data?.data.map(
                ({ id, name, description, updated_at }) => (
                  <Flex.Item key={id}>
                    <GridCard.Container
                      title={name}
                      description={description}
                      isLoading={listQuery?.isLoading}
                      onClick={() =>
                        navigate(
                          TemplatedReportsCategoriesDetailRoutePath.toLinkParts(
                            {
                              pathParams: {
                                categoryId: id,
                              },
                            }
                          )
                        )
                      }
                      slotFooter={(props) => (
                        <GridCard.Footer
                          {...props}
                          slotLeft={({ className, isLoading, isBusy }) => (
                            <Skeleton
                              isLoading={isLoading}
                              isBusy={isBusy}
                            >
                              <p className={className}>
                                Last updated on&nbsp;
                                <DateDataDisplay
                                  as="span"
                                  isInline
                                  value={updated_at}
                                  displayFormat={DateFormats.DATE}
                                />
                              </p>
                            </Skeleton>
                          )}
                        />
                      )}
                    />
                  </Flex.Item>
                )
              )}
            </Flex.Container>

            <Pagination
              page={tableState.page}
              pageSize={tableState.pageSize}
              onChange={updateTableState}
              style={{ marginTop: 8 }}
            />
          </>
        )}
      </Panel>
      <Dialog
        title="Create Category"
        isOpen={isCreateDialogOpen}
        onClose={() => setIsCreateDialogOpen(false)}
        actionRow={[
          <Button
            key="create"
            variant="primary"
            isLoading={createCategoryMutation.isLoading}
            onClick={() => {
              handleCreateCategorySubmit(createCategoryForm.getValues());
            }}
            disabled={!createCategoryForm.formState.isValid}
          >
            Create
          </Button>,
        ]}
      >
        <Form {...createCategoryForm}>
          <TextInput
            name={createCategoryFormInputKeys.name}
            label="Name"
            placeholder="Enter Name..."
            isRequired
            validate={{
              length: (val) => {
                const validation =
                  CreateOneTemplatedReportCategorySchema.shape.name.safeParse(
                    val
                  );

                if (validation.success) {
                  return true;
                } else {
                  switch (validation.error.issues?.[0].code) {
                    case "invalid_string":
                      return "Name has invalid characters";
                    case "too_small":
                      return `Name must be at least ${validation.error.issues[0].minimum} characters long.`;
                    case "too_big":
                      return `Name must be at most ${validation.error.issues[0].maximum} characters long.`;
                    default:
                      return "Invalid name.";
                  }
                }
              },
            }}
          />
          <TextAreaInput
            name={createCategoryFormInputKeys.description}
            label="Description"
            isRequired={false}
            placeholder="Enter Description..."
            validate={{
              length: (val) => {
                const validation =
                  CreateOneTemplatedReportCategorySchema.shape.description.safeParse(
                    val
                  );

                if (validation.success || !val) {
                  return true;
                } else {
                  switch (validation.error.issues?.[0].code) {
                    case "too_big":
                      return `Description must be at most ${validation.error.issues[0].maximum} characters long.`;
                    default:
                      return "Invalid description.";
                  }
                }
              },
            }}
          />
        </Form>
      </Dialog>
    </>
  );
};
