import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { Stack } from "@phosphor-icons/react";
import {
  EmptyState,
  MetricTileGrid,
  NumberDataDisplay,
  Panel,
} from "@validereinc/common-components";
import type { CalculatorResultType } from "@validereinc/domain";
import React, { useMemo } from "react";

const CalculatorResultsSummaryPanel = ({
  data,
  isLoading: isLoadingProp,
  loadingGridSize = 6,
  isError,
  title = "Emissions Summary",
}: CalculatorResultsSummaryPanelProps) => {
  const {
    getTypeName,
    getUnitName,
    getUnitByType,
    getPrecisionByType,
    isLoading: isMeasurementsLoading,
  } = useMeasurementTypes();

  const isLoading = isMeasurementsLoading || isLoadingProp;

  const aggregatedMeasurementResultMetricTileGridData = useMemo(() => {
    if (isError || !data?.measurement) {
      return [];
    }

    return (
      Object.entries(data.measurement)?.map(
        ([measurementKey, measurementValue]) => ({
          title: `Total ${getTypeName(measurementKey)} Emissions`,
          value: (props) => {
            const precision = getPrecisionByType(measurementKey);

            return (
              <NumberDataDisplay
                {...props}
                value={measurementValue}
                formattingOpts={
                  precision
                    ? { fractionDigits: precision }
                    : {
                        overrideOpts: {
                          maximumFractionDigits: 3,
                          minimumFractionDigits: 2,
                        },
                      }
                }
              />
            );
          },
          unit: getUnitName(getUnitByType(measurementKey)),
        })
      ) ?? []
    );
  }, [data, isError]);

  return (
    <Panel
      title={title}
      ariaLabel={title}
    >
      {aggregatedMeasurementResultMetricTileGridData.length ? (
        <MetricTileGrid
          data={aggregatedMeasurementResultMetricTileGridData}
          isLoading={isLoading}
        />
      ) : (
        <EmptyState
          title="There are no results to summarize"
          suggestion="Please try adjusting your filters"
          icon={<Stack />}
        />
      )}
    </Panel>
  );
};

type CalculatorResultsSummaryPanelProps = {
  data: CalculatorResultType | null;
  isLoading?: boolean;
  loadingGridSize?: number;
  isError?: boolean;
  title?: string;
};

export default CalculatorResultsSummaryPanel;
