import type { FilterAreaContentProps } from "#src/components/FilterArea";
import { FilterPanel } from "#src/components/FilterPanel";
import { convertMapToOpts } from "#src/constants";
import { DEFAULT_DATE_RANGES } from "#src/hooks/useDateRange";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import { useInfiniteQuery } from "@tanstack/react-query";
import {
  DateSelectorInput,
  DropdownInput,
  StorageKeys,
} from "@validereinc/common-components";
import {
  AssetType,
  Resources,
  TemplatedConfigurationAdapter,
  TemplatedConfigurationRunCreatedSchema,
  TemplatedConfigurationRunStatusType,
  type ResourcesSchema,
  type TemplatedConfigurationRunAdapter,
} from "@validereinc/domain";
import startCase from "lodash/startCase";
import React, { useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import type { z } from "zod";

export type ConfigurationTemplateRunsFiltersType = Partial<
  Omit<
    Parameters<
      typeof TemplatedConfigurationRunAdapter.getList
    >[0]["filters"] & {
      created_at: { from?: string; to?: string };
    },
    "status"
  > & { status: Array<TemplatedConfigurationRunStatusType | ""> }
>;

const AssetTypeOptions = convertMapToOpts(AssetType, ([_, val]) =>
  startCase(val)
);

const ConfigurationTemplateRunsFilterPanelContent = ({
  handleOnChange,
  viewConfigStorageKey,
}: Pick<StorageKeys, "viewConfigStorageKey"> &
  Parameters<FilterAreaContentProps["children"]>[0]) => {
  const [filters] = useSessionStickyState<ConfigurationTemplateRunsFiltersType>(
    {},
    viewConfigStorageKey
  );
  const [templateNameSearchTerm, setTemplateNameSearchTerm] =
    useState<string>("");
  const debouncedSetTemplateNameSearchTerm = useDebouncedCallback(
    setTemplateNameSearchTerm,
    500
  );
  const templateConfigFilters: Partial<
    Parameters<typeof TemplatedConfigurationAdapter.getList>[0]["filters"]
  > = {
    display_name: templateNameSearchTerm,
    primary_resource_type:
      (filters["resources.type"] as z.infer<typeof ResourcesSchema>) ?? "",
  };
  const configurationTemplatesInfiniteQuery = useInfiniteQuery({
    queryKey: [Resources.TEMPLATED_CONFIGURATION, templateConfigFilters],
    queryFn: ({ pageParam }) => {
      return TemplatedConfigurationAdapter.getList({
        page: pageParam,
        pageSize: 25,
        sortBy: "display_name",
        sortDirection: "asc",
        filters: templateConfigFilters,
      });
    },
    getNextPageParam: (resp) =>
      resp.page_number <= resp.total_pages
        ? resp.page_number + 1
        : resp.page_number,
    getPreviousPageParam: (resp) =>
      resp.page_number > 0 ? resp.page_number - 1 : resp.page_number,
  });

  return (
    <div style={{ display: "flex", gap: 8, flexWrap: "wrap" }}>
      <DropdownInput
        isInline
        inputId={
          TemplatedConfigurationRunCreatedSchema.keyof().Enum
            .templated_configuration_name
        }
        name={
          TemplatedConfigurationRunCreatedSchema.keyof().Enum
            .templated_configuration_name
        }
        description="A Configuration Template is a blueprint to quickly setup an asset and any/all of it's associated information."
        placeholder="Search by Configuration Template"
        options={configurationTemplatesInfiniteQuery.data?.pages.flatMap((p) =>
          p.data.map((ct) => ({
            label: ct.display_name,
            value: ct.name,
          }))
        )}
        onScroll={({ isBottom }) => {
          if (!isBottom) return;

          configurationTemplatesInfiniteQuery.fetchNextPage();
        }}
        labelKey="label"
        valueKey="value"
        searchTerm={templateNameSearchTerm}
        onSearchTermChange={debouncedSetTemplateNameSearchTerm}
        isBusy={configurationTemplatesInfiniteQuery.isFetching}
        isLoading={configurationTemplatesInfiniteQuery.isLoading}
        onChange={(val) =>
          handleOnChange(
            val,
            TemplatedConfigurationRunCreatedSchema.keyof().Enum
              .templated_configuration_name
          )
        }
      />
      <DropdownInput
        isInline
        inputId={`${TemplatedConfigurationRunCreatedSchema.keyof().Enum.resources}.${TemplatedConfigurationRunCreatedSchema.shape.resources.element.keyof().Enum.type}`}
        name={`${TemplatedConfigurationRunCreatedSchema.keyof().Enum.resources}.${TemplatedConfigurationRunCreatedSchema.shape.resources.element.keyof().Enum.type}`}
        description="An Asset is any data under the 'Organization' tab."
        placeholder="Search by Primary Asset Type"
        options={AssetTypeOptions}
        isSortedAlphabetically
        labelKey="label"
        valueKey="value"
        isMulti
        onChange={(val) =>
          handleOnChange(
            val,
            `${TemplatedConfigurationRunCreatedSchema.keyof().Enum.resources}.${TemplatedConfigurationRunCreatedSchema.shape.resources.element.keyof().Enum.type}`
          )
        }
      />
      <DateSelectorInput
        name={TemplatedConfigurationRunCreatedSchema.keyof().Enum.created_at}
        variant="day"
        max={new Date()}
        isRange
        isInline
        inputId="form_submissions.filters.created_at"
        description="Filter by 'Created On'"
        placeholder="Select a date range"
        onChange={(val) =>
          handleOnChange(
            val,
            TemplatedConfigurationRunCreatedSchema.keyof().Enum.created_at
          )
        }
      />
    </div>
  );
};

export const ConfigurationTemplateRunsFilterPanel = ({
  viewConfigStorageKey,
}: Pick<StorageKeys, "viewConfigStorageKey">) => {
  return (
    <FilterPanel
      ariaLabel="All Filters for Configuration Template Runs"
      viewFilters={{
        label: "View Filters for Configuration Template Runs",
        storageKey: viewConfigStorageKey,
        defaultValues: {
          created_at: {
            ...DEFAULT_DATE_RANGES.lastThreeMonths,
            to: new Date(),
          },
        },
        children: (props) => (
          <ConfigurationTemplateRunsFilterPanelContent
            {...props}
            viewConfigStorageKey={viewConfigStorageKey}
          />
        ),
      }}
    />
  );
};
