import { getDifference, getPercentChange } from "#utils/calculator";
import { getAbsoluteValue } from "#utils/stringFormatter";
import { getNumberColorBySign } from "#utils/styleCalculator";
import { formatNumber } from "@validereinc/utilities";
import * as PropTypes from "prop-types";
import React from "react";
import "./ResultDifference.scss";

export function computeDifference(current, original) {
  if (current?.value && original?.value) {
    const difference = getDifference(current.value, original.value);
    if (difference === 0) {
      return {};
    }

    const percentChange = getPercentChange(difference, original.value);

    return { percentChange, difference };
  }

  return {};
}

const ResultDifference = ({
  difference,
  percentChange,
  unit,
  showUnitOnLeft = false,
  showPercentChange = false,
}) => {
  const sign = difference > 0 ? "+" : "-";
  const percentChangeString = showPercentChange
    ? `${sign}${getAbsoluteValue(percentChange)}%`
    : "";

  const absoluteDifference = formatNumber(getAbsoluteValue(difference)) ?? "-";
  const actualDifferenceString = `${sign}${
    showUnitOnLeft ? unit : ""
  }${absoluteDifference}`;

  const differenceWithParenthesis = showUnitOnLeft ? (
    <div className="resultDifference__value">
      {`${percentChangeString} (${actualDifferenceString})`}
    </div>
  ) : (
    <>
      <div className="resultDifference__value">
        {`${percentChangeString} (${actualDifferenceString}`}
      </div>

      <div className="resultDifference__unit">{`${unit})`}</div>
    </>
  );

  return (
    <div
      className="resultDifference"
      style={{ color: getNumberColorBySign(difference) }}
    >
      {differenceWithParenthesis}
    </div>
  );
};

ResultDifference.propTypes = {
  difference: PropTypes.number.isRequired,
  percentChange: PropTypes.number,
  unit: PropTypes.string,
  showUnitOnLeft: PropTypes.bool,
  showPercentChange: PropTypes.bool,
};

export default ResultDifference;
