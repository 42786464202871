import {
  fetchInstrumentList,
  fetchSiteList,
  fetchUserProfile,
} from "#redux/actions/index";
import {
  filterFieldInstruments,
  sortInstrumentByName,
} from "#redux/reducers/instruments";
import { havePermission } from "#redux/reducers/permissions";
import UserService from "#services/UserService";
import {
  Button,
  Form,
  FormButton,
  Page,
  Panel,
  useForm,
  useToast,
} from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AccessDefinitionsModal from "../../AccessDefinitionsModal/AccessDefinitionsModal";
import ProfileForm from "../../User/ProfileForm";

import { getAvailableTimezone } from "../../User/ProfileHelpers";

const ACCESS_DEFINITION_MODAL_KEY = "ACCESS_DEFINITION_MODAL_KEY";

const TIMEZONE_OPTIONS = Object.keys(getAvailableTimezone());

const DEFAULT_INPUT = {
  name: "",
  company_name: "",
  email: "",
  phone_number: "",
  job_title: "",
  timezone_preference: "",
  sites: [],
  role: [],
  restrict_calibration_access: [],
  restricted_calibration_instruments: [],
};

const mapStateToProps = (state) => {
  return {
    profile: state.profiles?.data?.toJS() ?? {},
    hasWritePermissions: havePermission(state.permissions)(
      "core:user.management",
      "write"
    ),
    sites: state.sites.data?.toJS() ?? [],
    instrumentsList:
      state?.instruments?.data
        .toJS()
        .filter(filterFieldInstruments)
        .sort(sortInstrumentByName) ?? [],
  };
};

const mapDispatchToProps = {
  fetchUserProfile,
  fetchSiteList,
  fetchInstrumentList,
};

const CreateMember = ({
  sites,
  instrumentsList,
  hasWritePermissions,
  fetchInstrumentList,
  fetchSiteList,
}) => {
  const [formState, setFormState] = useState("enabled");
  const [availableRoles, setAvailableRoles] = useState([]);
  const [modalKey, setModalKey] = useState(null);
  const { toast } = useToast();

  const form = useForm({
    defaultValues: DEFAULT_INPUT,
  });

  const onUpdateClick = (inputs) => {
    setFormState("loading");

    UserService.requestInviteNewMember(inputs)
      .then(() => {
        setFormState("preview");
        toast.push({
          intent: "success",
          description: "Your invitation has been sent.",
        });
      })
      .catch(() => {
        setFormState("enabled");
      });
  };

  const onModalClose = () => {
    setModalKey(null);
  };

  const onInviteAnotherUserClick = () => {
    setFormState("enabled");
    form.reset({ ...DEFAULT_INPUT });
  };

  useEffect(() => {
    fetchSiteList();
    fetchInstrumentList();

    if (hasWritePermissions) {
      UserService.getRoles().then(({ data }) => {
        setAvailableRoles(data);
      });
    }
  }, []);

  const inputs = form.watch();

  return (
    <Page title="Invite User">
      <Panel>
        <Form
          onSubmit={onUpdateClick}
          {...form}
        >
          <ProfileForm
            isInvite={true}
            inputs={inputs}
            formState={formState}
            hasWritePermissions={hasWritePermissions}
            availableSites={sites}
            availableRoles={availableRoles}
            availableInstruments={instrumentsList}
            availableTimezones={TIMEZONE_OPTIONS}
            handleAccessDefinitionClick={() => {
              setModalKey(ACCESS_DEFINITION_MODAL_KEY);
            }}
          />

          <div className="clearfix">
            {formState === "preview" ? (
              <Button
                variant="primary"
                className="pull-right"
                onClick={onInviteAnotherUserClick}
              >
                Invite Another User
              </Button>
            ) : (
              <FormButton
                variant="primary"
                className="pull-right"
                type="submit"
                isLoading={formState === "loading"}
                disabled={formState !== "enabled" || !hasWritePermissions}
              >
                Send Invitation
              </FormButton>
            )}
          </div>
        </Form>

        {modalKey === ACCESS_DEFINITION_MODAL_KEY && (
          <AccessDefinitionsModal
            show={true}
            onHide={onModalClose}
          />
        )}
      </Panel>
    </Page>
  );
};

CreateMember.propTypes = {
  hasWritePermissions: PropTypes.bool.isRequired,
  sites: PropTypes.array,
  instrumentsList: PropTypes.array,
  addAlertMessage: PropTypes.func.isRequired,
  fetchInstrumentList: PropTypes.func.isRequired,
  fetchSiteList: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateMember);
