import RecordOfQualityService from "#services/RecordOfQualityService";
import SampleService from "#services/SampleService";
import { useToast } from "@validereinc/common-components";
import { downloadLink } from "@validereinc/utilities";
import * as PropTypes from "prop-types";
import React from "react";
import FontAwesome from "react-fontawesome";

const DownloadableReports = ({
  testDetail,
  pdfReportLink,
  sampleId,
  labReportId,
  testImageUrls,
  onPreviewClick,
}) => {
  const { toast } = useToast();
  const getImageName = (index) => {
    if (testDetail?.instrument?.type === "water") {
      if (index === 0) {
        return "Regular Image";
      }

      if (index === 1) {
        return "UV Image";
      }
    }

    return `Image ${index + 1}`;
  };

  const createSuccessfulDownloadAlertMessage = () => {
    toast.push({
      intent: "success",
      description: "Your files will start downloading shortly.",
    });
  };

  const onViolationLetterDownloadLinkClick = (sampleId) => {
    RecordOfQualityService.getDownloadSampleCompositionalLink(
      sampleId,
      "violation_letter"
    ).then(({ data }) => {
      downloadLink(data.url);
      createSuccessfulDownloadAlertMessage();
    });
  };

  const onLabResultDownloadLinkClick = (labReportId) => {
    SampleService.getLabReportFile(labReportId).then(({ data }) => {
      downloadLink(data.url);
      createSuccessfulDownloadAlertMessage();
    });
  };

  return (
    <div className="testAnalysis__downloadableReports">
      {testImageUrls?.map((url, index) => {
        return (
          <div key={index}>
            <a
              href={url?.original ?? ""}
              download
              onClick={createSuccessfulDownloadAlertMessage}
            >
              <FontAwesome
                name="file-image-o"
                className="icon fa-fw"
              />
              {getImageName(index)}
            </a>{" "}
            <a
              className="buttonLink"
              onClick={() => onPreviewClick(url)}
            >
              (Preview)
            </a>
          </div>
        );
      })}

      {sampleId && (
        <a
          className="link"
          onClick={() => onViolationLetterDownloadLinkClick(sampleId)}
        >
          <FontAwesome
            name="plus"
            className="icon fa-fw"
          />
          Generate Violation Letter
        </a>
      )}

      {pdfReportLink && (
        <div>
          <a
            href={pdfReportLink}
            download
            onClick={createSuccessfulDownloadAlertMessage}
          >
            <FontAwesome
              name="download"
              className="icon fa-fw"
            />
            PDF
          </a>
        </div>
      )}

      {labReportId && (
        <div>
          <a
            className="link"
            onClick={() => onLabResultDownloadLinkClick(labReportId)}
          >
            <FontAwesome
              name="download"
              className="icon fa-fw"
            />
            Lab Report
          </a>
        </div>
      )}
    </div>
  );
};

DownloadableReports.propTypes = {
  testDetail: PropTypes.object.isRequired,
  pdfReportLink: PropTypes.string,
  sampleId: PropTypes.string,
  labReportId: PropTypes.string,
  testImageUrls: PropTypes.array,
  onPreviewClick: PropTypes.func.isRequired,
};

export default DownloadableReports;
