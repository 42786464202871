import CustomInput from "#common/Form/CustomInput";
import Form from "#common/Form/Form";
import TextBoxInput from "#common/Form/TextBoxInput";
import RecordOfQualityService from "#services/RecordOfQualityService";
import {
  AlertMessage,
  Button,
  LegacyDataTable,
  Modal,
  Title,
  Tooltip,
  useToast,
} from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useState } from "react";
import { AutoSizer } from "react-virtualized";
import { getStatus } from "../RecordOfQualityHelper";
import {
  searchByStreamName,
  sortByStream,
} from "../Records/Quality/RecordOfQualityTableHelper";
import { getErrorMessage } from "./RecombindedReportModalHelper";
import "./RecombinedReportModal.scss";
import useFilterInputs from "./RecombinedReportTableFilter";

const TITLE_COLUMN = 2;

const nameRenderer = (rowData) => {
  const name = rowData?.stream?.name;
  if (name) {
    return <Tooltip content={name}>{name}</Tooltip>;
  }

  return "-";
};

const statusRenderer = (rowData) => {
  return getStatus(rowData.state);
};

const productRenderer = (rowData, columnKey) => {
  return rowData.stream?.[columnKey] ?? "-";
};

const categoryRenderer = (rowData, columnKey) => {
  return rowData[columnKey] ?? "-";
};

const Headers = [
  {
    label: "Stream",
    key: "name",
    width: 300,
    cellRenderer: nameRenderer,
    fixed: true,
    sort: sortByStream,
  },
  {
    label: "Status",
    key: "state",
    width: 110,
    cellRenderer: statusRenderer,
  },
  {
    label: "Product",
    key: "phase",
    width: 100,
    cellRenderer: productRenderer,
    sort: sortByStream,
  },
  {
    label: "Category",
    key: "category",
    cellRenderer: categoryRenderer,
    width: 100,
  },
];

const RecombinedReportModal = (props) => {
  const { show, recordOfQualities } = props;

  const [name, setName] = useState(null);
  const [selectedRecordIds, setSelectedRecordIds] = useState([]);

  const [alertMessage, setAlertMessage] = useState(null);
  const [formState, setFormState] = useState("enabled");
  const { toast } = useToast();

  const [filterRow, filterPillbox, filteredRecordOfQualities, clearAllFilters] =
    useFilterInputs(recordOfQualities);

  const onStreamChecked = (selectedRecordIds) => {
    setSelectedRecordIds(selectedRecordIds.map((record) => record?.id));
  };

  const onSubmitClick = () => {
    setFormState("disabled");

    const errorMessage = getErrorMessage(name, selectedRecordIds);

    if (!errorMessage) {
      setAlertMessage(null);

      RecordOfQualityService.generateRecombinedReport(name, selectedRecordIds)
        .then(() => {
          toast.push({
            intent: "success",
            description:
              "Your recombined report will be sent to your email shortly.",
          });
        })
        .finally(() => setFormState("enabled"));
    } else {
      setAlertMessage(errorMessage);
      setFormState("enabled");
    }
  };

  const onModalClose = () => {
    setAlertMessage(null);
    setName(null);
    setSelectedRecordIds([]);
    clearAllFilters();

    props.onModalClose();
  };

  const sharedProps = {
    titleCol: TITLE_COLUMN,
    pullLeft: true,
    disabled: formState !== "enabled",
  };

  return (
    <Modal
      className="recombinedReportModal"
      open={show}
      onClose={onModalClose}
    >
      <Modal.Header>
        <Title>Generate Recombined Report</Title>
      </Modal.Header>

      <Modal.Body>
        {alertMessage && (
          <AlertMessage
            type="warning"
            style={{ marginBottom: "15px" }}
          >
            <b>{alertMessage}</b>
          </AlertMessage>
        )}

        <Form horizontal>
          <TextBoxInput
            title="Report Name"
            type="text"
            value={name}
            placeholder="Report Name"
            onChange={(e) => setName(e.target.value)}
            {...sharedProps}
          />

          <CustomInput
            title="Streams"
            {...sharedProps}
          >
            <div className="recombinedReportModal__table">
              <AutoSizer>
                {({ height, width }) => (
                  <LegacyDataTable
                    width={width}
                    height={height}
                    headers={Headers}
                    list={filteredRecordOfQualities}
                    onCheckboxClick={onStreamChecked}
                    filterPillbox={filterPillbox}
                    filterRow={filterRow}
                    customSearch={searchByStreamName}
                    defaultSortBy="name"
                    defaultSortDirection="desc"
                    noFilterListCount={recordOfQualities.length}
                  />
                )}
              </AutoSizer>
            </div>
          </CustomInput>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button
          className="pull-left"
          onClick={onModalClose}
        >
          Close
        </Button>

        <Button
          variant="primary"
          icon="download"
          disabled={formState !== "enabled"}
          onClick={onSubmitClick}
        >
          Generate Recombined Report
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

RecombinedReportModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  recordOfQualities: PropTypes.array,
  addAlertMessage: PropTypes.func,
};

export default RecombinedReportModal;
