import { Map, Marker } from "#components/Map";
import { useBreadcrumbs } from "#src/Routers/breadcrumbsHelper";
import { useParams, useSearchParams } from "#src/Routers/hooks";
import { ChangeEventStatusDialog } from "#src/batteries-included-components/Dialogs/ChangeEventStatusDialog";
import { EditEventDialog } from "#src/batteries-included-components/Dialogs/EditEventDialog";
import { PredefinedEventFormTemplatesPanel } from "#src/batteries-included-components/Panels/CardPanels/PredefinedEventFormTemplatesPanel";
import { EventStatusPanel } from "#src/batteries-included-components/Panels/StatusPanels/EventStatusPanel";
import { EventSummaryPanel } from "#src/batteries-included-components/Panels/SummaryPanels/EventSummaryPanel";
import { EventAlertsTablePanel } from "#src/batteries-included-components/Panels/TablePanels/EventAlertsPanel";
import { EventDevicesPanel } from "#src/batteries-included-components/Panels/TablePanels/EventDevicesPanel";
import { EventEquipmentPanel } from "#src/batteries-included-components/Panels/TablePanels/EventEquipmentPanel";
import { EventFormSubmissionsTablePanel } from "#src/batteries-included-components/Panels/TablePanels/EventFormSubmissionsPanel";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useGetOneWorkflowTask } from "#src/components/hooks/adapters/useWorkflowTasks";
import {
  EventCategoryContext,
  EventCategoryProvider,
} from "#src/contexts/EventCategoryContext";
import { EventContext, EventProvider } from "#src/contexts/EventContext";
import {
  EventTemplateContext,
  EventTemplateProvider,
} from "#src/contexts/EventTemplateContext";
import { EVENTS_BREADCRUMB } from "#src/routes/events";
import { EVENT_CATEGORIES_BREADCRUMB } from "#src/routes/events/event-categories";
import { EVENT_CATEGORY_DETAIL_BREADCRUMB } from "#src/routes/events/event-categories/[eventCategoryId]";
import { EVENT_TEMPLATE_DETAIL_BREADCRUMB } from "#src/routes/events/event-categories/[eventCategoryId]/templates/[templateId]/details";
import { WorkflowDetailsRoutePath } from "#src/routes/workflows/all/[workflowId]";
import { Banner, Button, Page } from "@validereinc/common-components";
import classNames from "classnames/bind";
import React, { useContext, useState } from "react";
import { EVENT_DETAIL_BREADCRUMB, EVENT_DETAIL_PAGE_TITLE } from "../";
import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

type SharedHeaderType = {
  key: string;
  label: string;
};

export type SharedHeadersType = SharedHeaderType[];

export const EventPageContent = () => {
  const { eventCategory } = useContext(EventCategoryContext) || {};
  const { eventTemplate } = useContext(EventTemplateContext) || {};
  const { event, isLoading, onRefetchEvent } = useContext(EventContext) || {};
  const [searchParams] = useSearchParams<{
    workflowTaskId: string;
  }>();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isUpdateStatusDialogOpen, setIsUpdateStatusDialogOpen] =
    useState(false);

  const taskId = searchParams.workflowTaskId ?? "";
  const { data: relatedWorkflowTask } = useGetOneWorkflowTask(
    { id: taskId },
    { enabled: Boolean(taskId) }
  );

  const relatedWorkflowName =
    relatedWorkflowTask?.data?.workflow.name ??
    relatedWorkflowTask?.data?.workflow.workflow_template.name;

  const breadcrumbs = useBreadcrumbs(
    [
      EVENTS_BREADCRUMB,
      EVENT_CATEGORIES_BREADCRUMB,
      EVENT_CATEGORY_DETAIL_BREADCRUMB,
      EVENT_TEMPLATE_DETAIL_BREADCRUMB,
      EVENT_DETAIL_BREADCRUMB,
    ],
    { 2: eventCategory?.name, 3: eventTemplate?.name, 4: event?.name }
  );

  // REVIEW: this sort of asset relationship is slated to be removed. Remove
  // when BE support is dropped and/or BE migrations have completed.
  const initialMapViewState = {
    latitude:
      event?.associated_facility?.latitude ?? event?.facility?.latitude ?? 0,
    longitude:
      event?.associated_facility?.longitude ?? event?.facility?.longitude ?? 0,
  };

  const onOpenEditDialog = () => setIsEditDialogOpen(true);
  const onCloseEditDialog = (refetchData = false) => {
    if (refetchData) {
      onRefetchEvent?.();
    }

    setIsEditDialogOpen(false);
  };

  const onOpenUpdateStatusDialog = () => setIsUpdateStatusDialogOpen(true);
  const onCloseUpdateStatusDialog = (refetchData = false) => {
    if (refetchData) {
      onRefetchEvent?.();
    }

    setIsUpdateStatusDialogOpen(false);
  };

  return (
    <>
      <Page
        title={event?.name ?? EVENT_DETAIL_PAGE_TITLE}
        breadcrumbs={breadcrumbs}
        isLoading={isLoading}
        actionRow={[
          <Button
            key="edit-event-button"
            onClick={onOpenEditDialog}
          >
            Edit
          </Button>,
          <Button
            key="update-status-button"
            onClick={onOpenUpdateStatusDialog}
            variant="primary"
          >
            Update Status
          </Button>,
        ]}
      >
        {searchParams.workflowTaskId && relatedWorkflowTask ? (
          <Banner
            titleText="This event is associated to a workflow task."
            descriptionText={`After completing your required actions for this event, return to the task "${relatedWorkflowTask?.data?.name}" in workflow "${relatedWorkflowName}" and mark the task complete.`}
            color="info"
            actionContent={
              <RoutingLink
                to={WorkflowDetailsRoutePath.toLinkParts({
                  pathParams: {
                    workflowId: relatedWorkflowTask?.data?.workflow_id,
                  },
                })}
              >
                View Workflow
              </RoutingLink>
            }
            className={cx("banner")}
          />
        ) : null}
        <div className={cx("container")}>
          <div className={cx("leftContainer")}>
            <EventStatusPanel />
          </div>

          <div className={cx("rightContainer")}>
            <div className={cx("detailsContainer")}>
              <EventSummaryPanel />

              {event?.associated_facility || event?.facility ? (
                <Map
                  initialViewState={initialMapViewState}
                  subtractFromHeightOnResize={16}
                >
                  <Marker
                    {...initialMapViewState}
                    label={
                      event?.associated_facility?.name ?? event?.facility?.name
                    }
                  />
                </Map>
              ) : null}
            </div>

            {eventTemplate?.config?.has_alerts ? (
              <EventAlertsTablePanel
                eventCategoryId={eventCategory?.id}
                eventTemplateId={eventTemplate?.id}
                eventId={event?.id}
              />
            ) : null}

            {eventTemplate?.config?.has_forms ? (
              <EventFormSubmissionsTablePanel
                eventCategoryId={eventCategory?.id}
                eventTemplateId={eventTemplate?.id}
                eventId={event?.id}
              />
            ) : null}

            {eventTemplate?.config?.has_devices ? (
              <EventDevicesPanel
                eventCategoryId={eventCategory?.id}
                eventTemplateId={eventTemplate?.id}
                eventId={event?.id}
              />
            ) : null}

            {eventTemplate?.config?.has_equipment ? (
              <EventEquipmentPanel
                eventCategoryId={eventCategory?.id}
                eventTemplateId={eventTemplate?.id}
                eventId={event?.id}
              />
            ) : null}

            {event?.id && eventTemplate?.form_schemas?.length ? (
              <PredefinedEventFormTemplatesPanel
                eventId={event?.id}
                formTemplates={eventTemplate?.form_schemas}
                formSubmissions={event?.form_submissions?.data ?? []}
              />
            ) : null}
          </div>
        </div>
      </Page>

      {event ? (
        <>
          <EditEventDialog
            isOpen={isEditDialogOpen}
            onClose={onCloseEditDialog}
            event={event}
            eventTemplate={eventTemplate}
          />

          <ChangeEventStatusDialog
            isOpen={isUpdateStatusDialogOpen}
            onClose={onCloseUpdateStatusDialog}
            event={event}
          />
        </>
      ) : null}
    </>
  );
};

export const EventPage = () => {
  const { eventCategoryId, eventTemplateId, eventId } = useParams<{
    eventCategoryId: string;
    eventTemplateId: string;
    eventId: string;
  }>();

  return (
    <EventCategoryProvider eventCategoryId={eventCategoryId}>
      <EventTemplateProvider eventTemplateId={eventTemplateId}>
        <EventProvider eventId={eventId}>
          <EventPageContent />
        </EventProvider>
      </EventTemplateProvider>
    </EventCategoryProvider>
  );
};
