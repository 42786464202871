import React, { useEffect, useMemo } from "react";

import {
  DataTable,
  DateRangeSelector,
  HeaderType,
  MultiDropdownInputWithSearch,
  Pill,
} from "@validereinc/common-components";
import moment from "moment";

import intersection from "lodash/intersection";

import { GetAllObjectValue } from "#utils/objectFormatter";

import { useSearchParams } from "#routers/hooks";
import styles from "@validereinc/common-components/constants";
import { getNumMeasurementsWithAlerts } from "./SampleComparisonTableHelper";

const statusTypeMapping = {
  validated: "success",
  invalidated: "error",
  pending: "warning",
};

const DEFAULT_MONTHLY_DATE_RANGE = 1;

const DEFAULT_DATE_RANGE = {
  from: moment().subtract(DEFAULT_MONTHLY_DATE_RANGE, "month").toDate(),
  to: moment().toDate(),
};

export default ({
  streams,
  sites,
  loading,
  measurementType,
  getFormattedMeasurementValue,
  supportedMeasurementTypes,
  items,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!searchParams?.from || !searchParams?.to) {
      setSearchParams({
        ...searchParams,
        from: searchParams.from || DEFAULT_DATE_RANGE.from,
        to: searchParams.to || DEFAULT_DATE_RANGE.to,
      });
    }
  }, [searchParams]);

  const filters = useMemo(
    () => [
      {
        name: "date",
        component: <DateRangeSelector allowOneDayRange />,
        value:
          searchParams?.from && searchParams?.to
            ? {
                from: new Date(searchParams.from),
                to: new Date(searchParams.to),
              }
            : DEFAULT_DATE_RANGE,
      },
      {
        name: "stream",
        component: (
          <MultiDropdownInputWithSearch
            label={"Stream"}
            labelKey={"name"}
            dropdownKey={"name"}
            options={streams}
            selectLimit={10}
            isClearable
          />
        ),
      },
      {
        name: "site",
        component: (
          <MultiDropdownInputWithSearch
            label={"Site"}
            labelKey={"name"}
            dropdownKey={"name"}
            options={sites}
            selectLimit={10}
            isClearable
          />
        ),
      },
      {
        name: "samplePoint",
        component: (
          <MultiDropdownInputWithSearch
            label={"Sample Point ID"}
            options={GetAllObjectValue(streams, "sample_point_ids")}
            selectLimit={10}
            isClearable
          />
        ),
      },
    ],
    [streams, sites]
  );

  const quickFilters = useMemo(() => {
    return {
      loading,
      items: [
        { label: "All" },
        {
          label: "Pending",
          variant: "warning",
          value: "pending",
        },
        {
          label: "Validated",
          variant: "success",
          value: "validated",
        },
        {
          label: "Invalidated",
          variant: "error",
          value: "invalidated",
        },
      ],
    };
  }, [loading]);

  const headers: Array<HeaderType<any>> = useMemo(() => {
    const uniqueMeasurements = new Set();

    items
      .flatMap((sample) => sample.tests)
      .flatMap((test) => test.measurements)
      .forEach((measurement) => uniqueMeasurements.add(measurement.type));

    const measurements = intersection(
      supportedMeasurementTypes ? supportedMeasurementTypes : [],
      uniqueMeasurements ? Array.from(uniqueMeasurements) : []
    );

    const measurementColumns: Array<HeaderType<any>> = measurements
      .reduce(
        (total, current) =>
          total.includes(current) ? total : [...total, current],
        []
      )
      .map((dataKey) => {
        const unit = measurementType(dataKey)?.unit;

        return {
          label: `${dataKey}${unit ? ` (${unit})` : ""}`,
          key: dataKey,
          renderComponent: ({ item }) => {
            if (item?.tests) {
              const measurements = item?.tests?.reduce(
                (total, { measurements }) => {
                  if (measurements?.length) {
                    return [
                      ...total,
                      ...measurements.reduce(
                        (total, { id, type, value, alerts }) => {
                          const style = alerts?.length
                            ? { color: styles.statusErrorColour }
                            : undefined;

                          return type === dataKey
                            ? [
                                ...total,
                                <span
                                  key={id}
                                  style={style}
                                >
                                  {getFormattedMeasurementValue(type, value)}
                                </span>,
                                ", ",
                              ]
                            : total;
                        },
                        []
                      ),
                    ];
                  }

                  return total;
                },
                []
              );

              if (measurements?.length) {
                measurements.pop();

                return measurements;
              }
            }
          },
        };
      });

    return [
      {
        label: "Stream",
        key: "stream_id",
        isFixed: true,
        isSortable: true,
        renderComponent: ({ item }) =>
          streams?.find((site) => site.id === item.stream_id)?.name ?? "-",
      },
      {
        label: "Site",
        key: "site_id",
        isFixed: true,
        renderComponent: ({ item }) =>
          sites?.find((site) => site.id === item.site_id)?.name ?? "-",
      },
      {
        label: "Sample ID",
        key: "sample_point_id",
        renderComponent: ({ item }) =>
          item.sample_point_id ? (
            <Pill variant="default">{item.sample_point_id}</Pill>
          ) : (
            "-"
          ),
        isFixed: true,
      },
      {
        label: "Start Date",
        key: "started_at",
        isFixed: true,
        isSortable: true,
        renderComponent: ({ item }) => (
          <DataTable.DataRow.DateCell
            value={item.started_at}
            withTime
          />
        ),
      },
      {
        label: "End Date",
        key: "ended_at",
        isFixed: true,
        isSortable: true,
        renderComponent: ({ item }) => (
          <DataTable.DataRow.DateCell
            value={item.ended_at}
            withTime
          />
        ),
      },
      {
        label: "Status",
        key: "state",
        renderComponent: ({ item }) => (
          <Pill variant={statusTypeMapping[item.state]}>{item.state}</Pill>
        ),
      },
      {
        label: "Alerts",
        key: "alerted_measurements",
        renderComponent: ({ item }) => {
          const value = item?.tests ? getNumMeasurementsWithAlerts(item) : 0;
          return <Pill variant={value > 0 ? "error" : "info"}>{value}</Pill>;
        },
      },
      {
        label: "Product Type",
        key: "product_type",
        isFixed: true,
      },
      {
        label: "Short Code",
        key: "short_code",
        isFixed: true,
      },
      {
        label: "Batch ID",
        key: "batch_id",
        isFixed: true,
      },
      {
        label: "Source",
        key: "type",
      },
      {
        label: "Validated Date",
        key: "validated_at",
        renderComponent: ({ item }) => (
          <DataTable.DataRow.DateCell
            value={item.validated_at}
            withTime
          />
        ),
      },
      {
        label: "Validated By",
        key: "validated_by",
        renderComponent: ({ item }) => item.validated_by?.email ?? "-",
      },
      ...measurementColumns,
    ];
  }, [sites, streams, items]);

  return { headers, filters, quickFilters };
};
