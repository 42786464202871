import ColumnSelect from "#components/Common/ColumnSelect/ColumnSelect";
import {
  ensureStreamListIsFetched,
  fetchInstrumentList,
  fetchSiteList,
} from "#redux/actions/index";
import {
  getFormattedMeasurementValueWithUnit,
  measurementNames,
} from "#redux/reducers/measurements";
import { havePermission } from "#redux/reducers/permissions";
import { getBreadcrumbsObject } from "#routers/breadcrumbsHelper";
import { useNavigate, useSearchParams } from "#routers/hooks";
import { linkToTestDetail } from "#routers/links";
import useTableState from "#src/hooks/useTableState";
import { getPropertyAsMap } from "#utils/objectFormatter";
import {
  Button,
  DataTable,
  Filters,
  Page,
  Panel,
  useToast,
} from "@validereinc/common-components";
import { localStorageToJSON } from "@validereinc/utilities";
import differenceBy from "lodash/differenceBy";
import * as PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import CreateSampleModal from "../ChainOfCustody/CreateChainOfCustody/CreateSampleModal";
import SampleService from "../Services/SampleService";
import AssignTestModal from "./AssignTestModal/AssignTestModal";
import TestDetailModal from "./TestDetailModal/TestDetailModal";
import "./TestManagement.scss";
import useTestsConfig from "./useTestsConfig";

const TEST_LOCAL_STORAGE_KEY = "test_management_headers";
const mapStateToProps = ({
  permissions,
  streams,
  sites,
  instruments,
  measurements,
}) => {
  return {
    hasWriteAccess: havePermission(permissions)("360:test_management", "write"),
    streams: streams.data?.toJS() ?? [],
    sites: sites.data?.toJS() ?? [],
    instruments: instruments.data.toJS(),
    measurementTypes: measurements.measurementTypesList,
    getFormattedMeasurementValueWithUnit: (measurementKey, measurementValue) =>
      getFormattedMeasurementValueWithUnit(measurements)(
        measurementKey,
        measurementValue
      ),
    supportedMeasurementTypes: measurementNames(measurements),
  };
};

const mapDispatchToProps = {
  ensureStreamListIsFetched,
  fetchSiteList,
  fetchInstrumentList,
};

const TestManagement = ({
  sites,
  streams,
  instruments,
  ensureStreamListIsFetched,
  fetchSiteList,
  fetchInstrumentList,
  hasWriteAccess,
  breadcrumbs,
  getFormattedMeasurementValueWithUnit,
  supportedMeasurementTypes,
}) => {
  const testListPageBreadcrumbs = getBreadcrumbsObject(breadcrumbs);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [showNewSampleModal, setShowNewSampleModal] = useState(false);
  const [isMarkTestLoading, setIsMarkTestLoading] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const closeTestDetail = () => {
    setSearchParams({ testId: undefined });
  };
  const onFetchData = useCallback(async (newSearchParams) => {
    const {
      page,
      rowPerPage,
      sort: _sort,
      sortDirection,
      ...restQuery
    } = newSearchParams;

    return await SampleService.getPaginatedTests(
      {
        page,
        rowPerPage,
        sort: { ["date"]: sortDirection },
      },
      restQuery
    );
  }, []);

  const { fetchData, tableProps } = useTableState({
    onFetchData,
    itemsKey: "data.data",
    subscription: [
      "page",
      "rowPerPage",
      "sort",
      "sortDirection",
      "from",
      "to",
      "site",
      "stream",
      "samplePoint",
      "instrument",
      "orphan",
      "sampleType",
      "measurements",
    ],
    selectable: true,
  });

  const selectedTests = useMemo(
    () => Object.values(tableProps.selected),
    // inefficent compromise for table update on legacy code
    [tableProps.selected]
  );

  const testsWithStreamNames = useMemo(() => {
    const streamMap = getPropertyAsMap(streams);

    return tableProps?.items?.map((test) => {
      const stream = streamMap[test.sample?.stream_id];

      if (stream) {
        return {
          ...test,
          sample: {
            ...test.sample,
            streamName: stream.name,
            samplePointIds: stream.sample_point_ids,
          },
        };
      } else {
        return test;
      }
    });
  }, [streams, tableProps?.items]);

  const nonDefaultHeaders = [{ key: "type" }, { key: "sample.samplePointIds" }];

  const { filters, quickFilters, headers } = useTestsConfig({
    streams,
    sites,
    instruments,
    items: tableProps?.items,
    getFormattedMeasurementValueWithUnit,
    supportedMeasurementTypes,
  });

  const [displayHeaders, setDisplayHeaders] = useState(() => {
    let defaultSelectedHeaders = localStorageToJSON(TEST_LOCAL_STORAGE_KEY);

    if (defaultSelectedHeaders?.length) {
      defaultSelectedHeaders = defaultSelectedHeaders.map(({ key }) => key);

      return headers.filter(({ key }) => defaultSelectedHeaders.includes(key));
    } else {
      return differenceBy(headers, nonDefaultHeaders, "key");
    }
  });

  const onRowClick = ({ id }) => {
    navigate({
      pathname: linkToTestDetail(id),
    });
  };

  useEffect(() => {
    ensureStreamListIsFetched();
    fetchSiteList();
    fetchInstrumentList();
  }, []);

  const onHideAssignToModal = (hasTestsBeenAssigned) => {
    setShowAssignModal(false);

    if (hasTestsBeenAssigned) {
      tableProps.onSelectionChange({}); //for clearing checkbox after successful assignment
      fetchData(searchParams);
    }
  };

  const onHideSampleModal = () => {
    setShowNewSampleModal(false);
  };

  const { toast } = useToast();

  const onMarkSelectedTestsAsError = () => {
    const testRequests = selectedTests.map((testDetail) => {
      if (testDetail.status !== "error") {
        return SampleService.markTestAsError(testDetail.id, "");
      }
    });

    setIsMarkTestLoading(true);

    Promise.allSettled(testRequests).then((responseDetails) => {
      responseDetails.map((response) => {
        if (response.status === "fulfilled") {
          toast.push({
            intent: "success",
            description: "The test has been marked as an error.",
          });
        } else if (response.status === "rejected") {
          toast.push({
            intent: "error",
            description: "The test failed to be marked as an error.",
          });
        }
      });
      tableProps.onSelectionChange({});
      setIsMarkTestLoading(false);
      fetchData(searchParams);
    });
  };
  const onFiltersChange = (values) => {
    const {
      date,
      site,
      stream,
      samplePoint,
      instrumentName,
      quickFilters,
      sampleType,
      measurements,
    } = values;

    let streamIds = stream?.reduce((total, current) => {
      return [...total, current.id];
    }, []);

    if (samplePoint?.length) {
      streamIds = streamIds ? streamIds : [];
      streamIds.push(
        samplePoint.reduce((total, current) => {
          return [...total, current.streamId];
        }, [])
      );
    }
    setSearchParams({
      ...searchParams,
      ...date,
      site: site?.length
        ? site.map((current) => {
            return current.id;
          })
        : undefined,
      stream: streamIds?.length ? streamIds : undefined,
      instrument: instrumentName?.length
        ? instrumentName.map((current) => {
            return current.id;
          })
        : undefined,
      sampleType: sampleType?.length ? sampleType : undefined,
      measurements: measurements?.length
        ? measurements.map((current) => {
            return current;
          })
        : undefined,
      orphan: quickFilters?.length
        ? quickFilters.includes("orphan")
          ? "yes"
          : "no"
        : undefined,
    });
  };

  return (
    <Page
      title="Test List"
      breadcrumbs={testListPageBreadcrumbs}
    >
      <Filters
        onChange={onFiltersChange}
        filters={filters}
        quickFilters={quickFilters}
        actions={[
          <ColumnSelect
            key={"column-select"}
            value={displayHeaders}
            options={headers}
            onChange={setDisplayHeaders}
            localStorageKey={TEST_LOCAL_STORAGE_KEY}
          />,
          <Button
            key={"mark-as-error-button"}
            variant="error-outline"
            onClick={onMarkSelectedTestsAsError}
            disabled={selectedTests.length === 0}
            isLoading={isMarkTestLoading}
            className="errorButton"
          >
            {`Mark as Error ${
              selectedTests.length ? `(${selectedTests.length} selected)` : ""
            }`}
          </Button>,
          <Button
            key={"assign-tests"}
            variant="primary"
            onClick={() => setShowAssignModal(true)}
            disabled={selectedTests.length === 0}
          >
            {`Assign Test ${
              selectedTests.length ? `(${selectedTests.length} selected)` : ""
            }`}
          </Button>,
        ]}
      />

      <Panel>
        <DataTable
          headers={displayHeaders}
          {...tableProps}
          getItemId={(item) => item?.id}
          items={testsWithStreamNames}
          onRowClick={onRowClick}
          isFluid={false}
        />
      </Panel>

      {searchParams?.testId ? (
        <TestDetailModal
          showModal={true}
          testId={searchParams.testId}
          onHide={closeTestDetail}
        />
      ) : null}
      {showAssignModal && (
        <AssignTestModal
          show={true}
          onModalClose={onHideAssignToModal}
          tests={selectedTests}
          hasWriteAccess={hasWriteAccess}
          allTests={tableProps.items}
          setShowNewSampleModal={setShowNewSampleModal}
          samplesTableLastUpdatedAt={new Date()}
          onTestClick={onRowClick}
        />
      )}
      <CreateSampleModal
        show={showNewSampleModal}
        hide={onHideSampleModal}
        tests={selectedTests}
      />
    </Page>
  );
};

TestManagement.propTypes = {
  hasWriteAccess: PropTypes.bool.isRequired,
  ensureStreamListIsFetched: PropTypes.func.isRequired,
  fetchSiteList: PropTypes.func.isRequired,
  fetchInstrumentList: PropTypes.func.isRequired,
  streams: PropTypes.array.isRequired,
  sites: PropTypes.array.isRequired,
  instruments: PropTypes.array.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
  getFormattedMeasurementValueWithUnit: PropTypes.func.isRequired,
  supportedMeasurementTypes: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(TestManagement);
