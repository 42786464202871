import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { linkToUserDetailPage } from "#src/routes/settings/users/detail";
import { UserGroupDetailsRoutePath } from "#src/routes/settings/users/groups/[groupId]/details";
import { useQuery } from "@tanstack/react-query";
import {
  Chip,
  Icon,
  KeyValueList,
  Pill,
  Skeleton,
} from "@validereinc/common-components";
import {
  type FormSchemaType,
  UserGroupsAdapter,
  UsersAdapter,
  WorkflowTaskType,
  WorkflowType,
} from "@validereinc/domain";
import { datetimeFormatter, getNameAbbreviation } from "@validereinc/utilities";
import classNames from "classnames/bind";
import startCase from "lodash/startCase";
import React, { ReactElement } from "react";
import styles from "./WorkflowTaskDetailDrawer.module.scss";
import { WorkflowDetailsRoutePath } from "#routes/workflows/all/[workflowId]";

const cx = classNames.bind(styles);

export const WorkflowTaskDetailKeyValueList = ({
  selectedTask,
  formSchema,
  isLoading = false,
  workflowDetails,
}: {
  selectedTask: WorkflowTaskType | null;
  formSchema?: FormSchemaType;
  isLoading?: boolean;
  workflowDetails?: WorkflowType;
}) => {
  const { data: assignedToUser } = useQuery(
    ["users", selectedTask?.assignee_user],
    () => {
      if (!selectedTask?.assignee_user) {
        return;
      }

      return UsersAdapter.getOne({ id: selectedTask.assignee_user });
    },
    {
      enabled: Boolean(selectedTask?.assignee_user),
      select: (resp) => resp?.data,
      staleTime: 5 * 60 * 1000,
    }
  );
  const { data: assignedToUserGroup } = useQuery(
    ["users", "groups", selectedTask?.assignee_group],
    () => {
      if (!selectedTask?.assignee_group) {
        return;
      }

      return UserGroupsAdapter.getOne({ id: selectedTask.assignee_group });
    },
    {
      enabled: Boolean(selectedTask?.assignee_group),
      select: (resp) => resp?.data,
      staleTime: 3 * 60 * 1000,
    }
  );
  const { data: updatedByUser } = useQuery(
    ["users", selectedTask?.updated_by],
    () => {
      if (!selectedTask?.updated_by) {
        return;
      }

      return UsersAdapter.getOne({ id: selectedTask.updated_by });
    },
    {
      enabled: Boolean(selectedTask?.updated_by),
      select: (resp) => resp?.data,
    }
  );

  if (!selectedTask) {
    return null;
  }

  const data: Array<{ title: string; value: string | number | ReactElement }> =
    [
      {
        title: "Workflow",
        value: (
          <RoutingLink
            disabled={!workflowDetails}
            to={
              workflowDetails?.id
                ? WorkflowDetailsRoutePath.toLinkParts({
                    pathParams: { workflowId: workflowDetails?.id },
                  })
                : ""
            }
          >
            {workflowDetails?.name}
          </RoutingLink>
        ),
      },
      {
        title: "Action Type",
        value: selectedTask?.type ? startCase(selectedTask.type) : "",
      },
      {
        title: "Description",
        value: selectedTask.description,
      },
      {
        title: "Assigned To",
        value: assignedToUserGroup ? (
          <RoutingLink
            to={UserGroupDetailsRoutePath.toLink({
              pathParams: { groupId: assignedToUserGroup.id },
            })}
          >
            <Pill
              variant="default"
              isBordered
              iconLeft={<Icon variant="users" />}
            >
              {assignedToUserGroup.name}
            </Pill>
          </RoutingLink>
        ) : assignedToUser ? (
          <div className={cx("assignedToUser")}>
            <Chip
              className={cx("chip")}
              text={getNameAbbreviation(assignedToUser?.name ?? "") ?? ""}
            />
            <RoutingLink to={linkToUserDetailPage(assignedToUser.id)}>
              {assignedToUser.name}
            </RoutingLink>
          </div>
        ) : (
          "-"
        ),
      },
      {
        title: "Updated At",
        value: datetimeFormatter(new Date(selectedTask.updated_at)),
      },
      {
        title: "Updated By",
        value: updatedByUser?.name ?? "-",
      },
    ];

  return (
    <Skeleton isLoading={isLoading}>
      <KeyValueList
        data={data}
        color="neutral"
        variant="shaded"
      />
    </Skeleton>
  );
};
