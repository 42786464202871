import UserService from "#services/UserService";
import {
  Button,
  Form,
  FormButton,
  Modal,
  Title,
  useForm,
  useToast,
} from "@validereinc/common-components";
import { copyTextToClipboard } from "@validereinc/utilities";
import * as PropTypes from "prop-types";
import React, { useState } from "react";
import CreateApiTokenForm from "./CreateApiTokenForm";
import "./CreateApiTokenModal.scss";

const CreateApiTokenModal = ({ userId, show, onHide, updateApiTokenList }) => {
  const [showSecret, setShowSecret] = useState(false);
  const [apiTokenDetail, setApiTokenDetail] = useState(null);
  const { toast } = useToast();

  const [generatingApiTokenState, setGenerateApiTokenState] =
    useState("loaded");

  const createApiTokenButtonClick = (formState) => {
    setGenerateApiTokenState("loading");

    UserService.createApiTokens(userId, formState)
      .then(({ data }) => {
        setApiTokenDetail(data);
        updateApiTokenList();
      })
      .finally(() => {
        setGenerateApiTokenState("loaded");
      });
  };

  const onShowClick = () => {
    setShowSecret(!showSecret);
  };

  const onCopyClick = () => {
    copyTextToClipboard(apiTokenDetail.secret);

    toast.push({
      intent: "success",
      description: "API Token has successfully copied to your clipboard.",
    });
  };
  const formMethods = useForm();
  return (
    <Modal
      className="createApiTokenModal"
      open={show}
      onClose={onHide}
    >
      <Modal.Header>
        <Title>Create API Token</Title>
      </Modal.Header>
      <Form
        onSubmit={createApiTokenButtonClick}
        {...formMethods}
      >
        <Modal.Body>
          <CreateApiTokenForm
            apiTokenDetail={apiTokenDetail}
            isLoading={generatingApiTokenState === "loading"}
            showSecret={showSecret}
            onShowClick={onShowClick}
            onCopyClick={onCopyClick}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="clearfix">
            <Button
              className={apiTokenDetail ? "pull-right" : "pull-left"}
              onClick={onHide}
            >
              Close
            </Button>

            {!apiTokenDetail && (
              <FormButton
                className="pull-right"
                variant="primary"
                isLoading={generatingApiTokenState === "loading"}
                type="submit"
              >
                {!formMethods.watch("name")
                  ? "Create API Token without name"
                  : "Create API Token"}
              </FormButton>
            )}
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

CreateApiTokenModal.propTypes = {
  show: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,
  updateApiTokenList: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default CreateApiTokenModal;
