import { ExceptionUtils } from "#src/utils/exception";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useToast } from "@validereinc/common-components";
import { ActivitiesDomain, BaseError, Resources } from "@validereinc/domain";
import { downloadLink } from "@validereinc/utilities";
import { DEFAULT_QUERY_OPTIONS } from "./adapterUtils";

export const useListActivityLogs = (
  params: Parameters<typeof ActivitiesDomain.getList>[0],
  options: { enabled?: boolean } = {}
) =>
  useQuery({
    queryKey: [...Resources.ACTIVITY, params],
    queryFn: () => ActivitiesDomain.getList(params),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  });

export const useExportActivityLogs = (params = {}) => {
  const { toast } = useToast();

  return useMutation({
    mutationFn: async () => {
      const report = await ActivitiesDomain?.exportList?.(params);
      if (!report?.s3_download_link) {
        throw new BaseError(
          `Could not fetch download URL for activity log export`,
          { cause: report }
        );
      }
      downloadLink(report.s3_download_link, report.name);
    },
    onError: (error) => {
      ExceptionUtils.reportException(error, "error");
      toast.push({
        intent: "error",
        description: "Failed to export activity log.",
      });
    },
  });
};
