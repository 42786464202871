import { UsersDropdownInput } from "#src/batteries-included-components/Dropdowns/UsersDropdownInput";
import {
  FilterArea,
  useFilterAreaContentContext,
} from "#src/components/FilterArea";
import { DEFAULT_DATE_RANGES } from "#src/hooks/useDateRange";
import {
  DateSelectorInput,
  DropdownInput,
  type StorageKeys,
} from "@validereinc/common-components";
import { RolesAdapter } from "@validereinc/domain";
import React from "react";
import { z } from "zod";

export const RolePermissionsActivityLogFilterSchema = z
  .object({
    resource_id: z.string(),
    logged_by: z.string(),
    logged_at: z.object({
      from: z.string().datetime(),
      to: z.string().datetime(),
    }),
  })
  .partial();
export const RolePermissionsActivityLogFilterSchemaKeys =
  RolePermissionsActivityLogFilterSchema.keyof().Enum;
export type RolePermissionsActivityLogFiltersType = z.infer<
  typeof RolePermissionsActivityLogFilterSchema
>;

export const RolePermissionsActivityLogFilterAreaContent = () => {
  const { handleOnChange } = useFilterAreaContentContext();

  return (
    <>
      <DropdownInput
        name={RolePermissionsActivityLogFilterSchemaKeys.resource_id}
        onFetchData={async (payload) => {
          let { data } = await RolesAdapter.getList({
            ...payload,
            pageSize: 500,
            filters: {
              ...(payload.searchTerm
                ? {
                    name: payload.searchTerm,
                  }
                : {}),
            },
          });

          // REVIEW: not a fan of the fact that we need to do this. A refactor of DropdownInput is needed.
          if (Array.isArray(payload.value)) {
            data = data.filter((d) => payload.value.includes(d.id));
          }

          return data;
        }}
        labelKey="name"
        valueKey="id"
        placeholder="Select Roles..."
        label="Roles"
        description="Filter by Role"
        isOptionalTextShown={false}
        isLabelShown={false}
        isInline
        isMulti
        onChange={(val) =>
          handleOnChange(
            val,
            RolePermissionsActivityLogFilterSchemaKeys.resource_id
          )
        }
      />
      <UsersDropdownInput
        style={{ marginBottom: 0 }}
        name={RolePermissionsActivityLogFilterSchemaKeys.logged_by}
        label="Logged By"
        placeholder="Select Logged By..."
        description="Filter by Logged By"
        isInline
        isOptionalTextShown={false}
        isLabelShown={false}
        onChange={(val: string) =>
          handleOnChange(
            val,
            RolePermissionsActivityLogFilterSchemaKeys.logged_by
          )
        }
      />
      <DateSelectorInput
        name={RolePermissionsActivityLogFilterSchemaKeys.logged_at}
        variant="day"
        label="Logged At"
        description="Filter by Logged At"
        isLabelShown={false}
        isRange
        isInline
        onChange={(val) =>
          handleOnChange(
            val,
            RolePermissionsActivityLogFilterSchemaKeys.logged_at
          )
        }
      />
    </>
  );
};

export const RolePermissionsActivityLogFilterArea = ({
  viewConfigStorageKey,
}: Pick<StorageKeys, "viewConfigStorageKey">) => {
  return (
    <FilterArea.Root
      storageKey={viewConfigStorageKey}
      defaultValues={{
        logged_at: DEFAULT_DATE_RANGES.lastThreeMonths,
      }}
      applyDefaultValues
      shouldPrioritizeStoredFiltersWhenApplyingDefaultValues={false}
    >
      <FilterArea.Container style={{ marginBottom: 16 }}>
        <FilterArea.Content>
          {() => (
            <div
              style={{
                display: "flex",
                gap: 8,
                flexWrap: "wrap",
                justifyContent: "flex-start",
              }}
            >
              <RolePermissionsActivityLogFilterAreaContent />
            </div>
          )}
        </FilterArea.Content>
      </FilterArea.Container>
    </FilterArea.Root>
  );
};
