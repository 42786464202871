import SiteService from "#services/SiteService";
import {
  Button,
  Form,
  FormButton,
  Modal,
  Title,
  useForm,
  useToast,
} from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import SiteSettingForm from "./SiteSettingForm";

const SiteSettingModal = ({
  show,
  selectedSite,
  updateSiteList,
  onCloseSiteSetting,
}) => {
  const form = useForm();
  const [formState, setFormState] = useState("enabled");
  const { toast } = useToast();

  const onSiteSubmit = (inputs) => {
    setFormState("loading");

    SiteService.updateSiteSetting(inputs)
      .then(() => {
        updateSiteList();

        toast.push({
          intent: "success",
          description: "Site updated successfully.",
        });
      })
      .finally(() => {
        setFormState("enabled");
      });
  };

  useEffect(() => {
    if (selectedSite) {
      form.reset({ ...selectedSite });
    }
  }, [selectedSite]);

  return (
    <Modal
      className="serialNumberModal"
      open={show}
      onClose={onCloseSiteSetting}
    >
      <Form
        onSubmit={onSiteSubmit}
        {...form}
      >
        <Modal.Header>
          <Title>Edit Site</Title>
        </Modal.Header>

        <Modal.Body>
          <SiteSettingForm
            form={form}
            formState={formState}
          />
        </Modal.Body>

        <Modal.Footer className="clearfix">
          <Button
            className="pull-left"
            onClick={onCloseSiteSetting}
          >
            Close
          </Button>

          <FormButton
            variant="primary"
            type="submit"
            isLoading={formState === "loading"}
            disabled={formState !== "enabled"}
          >
            Update Site Settings
          </FormButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

SiteSettingModal.propTypes = {
  show: PropTypes.bool,
  selectedSite: PropTypes.object,
  onCloseSiteSetting: PropTypes.func,
  updateSiteList: PropTypes.func,
};

export default SiteSettingModal;
