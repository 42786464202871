import {
  CalculationOutputType,
  CalculationParameterSavedType,
  CalculatorInputSourceConfigType,
  EstimationMethodInputType,
  EstimationMethodRunFilterType,
  EstimationMethodRunType,
  EstimationMethodType,
  restAPI,
} from "../../adapters";
import {
  FilterObjectType,
  GetListRequestType,
  GetListResponseType,
  getFilterObject,
  BulkActionResultType,
} from "../../util";
import {
  AssetType,
  AssetTypeType,
  ReportWithDownloadV2Type,
  JobStatusType,
  JobType,
} from "../../schemas";

export type EstimationMethodConfigurationType = {
  estimation_method_id: string;
  asset_inputs: Record<string, Record<string, EstimationMethodInputType>>;
  asset_input_sources: Record<
    string,
    Record<string, CalculatorInputSourceConfigType>
  >;
  inputs: Record<string, EstimationMethodInputType>;
  input_sources: Record<string, CalculatorInputSourceConfigType>;
  calculator_version: string;
  created_at: string;
  updated_at: string;
  created_by: string;
  updated_by: string;
  company_id: string;
  year_month: string;
};

export type EstimationMethodConfigurationMetaType = {
  /** a month represented as a string in the format YYYYMM e.g. 202210 for October 2022 */
  yearMonth: string;
  /** the entity type under which this estimation method exists. Default is "equipment" */
  entityType: AssetTypeType;
};

export type EstimationMethodConfigurationKeyType = {
  estimation_method_id: string;
  year_month: string;
};

export type ApplyEstimationMethodConfigurationResultType =
  EstimationMethodConfigurationKeyType & { calculation_status: string };

export type EstimationMethodConfigurationFilterType = {
  id?: string;
  name?: string;
  year_month?: string;
  entity_id?: string;
  entity_type?: string;
  analytics_library_id?: string;
  analytics_calculator_id?: string;
  created_at?: string;
  created_by?: string;
  updated_at?: string;
  updated_by?: string;
};

export type EstimationMethodCalculatorRunType = Omit<
  EstimationMethodRunType,
  "input" | "output"
> & {
  run_output: Record<string, CalculationOutputType[]>;
  run_input: {
    calculation_id: string;
    calculation_parameters: CalculationParameterSavedType[];
  };
};

export type EstimationMethodWithConfigurationAndRunType =
  EstimationMethodType & {
    calculator_configuration?: EstimationMethodConfigurationType;
    calculator_run?: EstimationMethodCalculatorRunType;
  };

export type EstimationMethodApplyDefaultsFilterType = {
  filters: Omit<EstimationMethodRunFilterType, "year_month">;
  year_months: string[];
};

export const EstimationMethodConfiguration: EstimationMethodConfigurationDomainType =
  {
    getOne: ({
      id,
      meta = {
        // TODO: this should be the current month
        yearMonth: "",
        entityType: AssetType.EQUIPMENT,
      },
    }) =>
      restAPI.nodeAPI.GET({
        endpoint: `/estimation_methods/${meta.entityType}/${id}/configuration/${meta.yearMonth}`,
      }),
    update: ({
      id,
      data,
      meta = {
        // TODO: this should be the current month
        yearMonth: "",
        entityType: AssetType.EQUIPMENT,
      },
    }) =>
      restAPI.nodeAPI.PUT({
        endpoint: `/estimation_methods/${meta.entityType}/${id}/configuration/${meta.yearMonth}`,
        body: data,
      }),
    applyDefaults: ({ id, meta }) =>
      restAPI.nodeAPI.PUT({
        endpoint: `/estimation_methods/${meta?.entityType}/${id}/configuration/${meta?.yearMonth}/apply_defaults`,
      }),
    bulkApplyDefaults: (body) =>
      restAPI.nodeAPI.PUT({
        endpoint: "/estimation_methods/configurations/apply_defaults",
        body,
      }),
    bulkApplyDefaultsAsync: ({ filters, year_months }) =>
      restAPI.nodeAPI.POST({
        endpoint: "/estimation_methods/configurations/apply_defaults_async",
        body: {
          filter: getFilterObject(filters),
          year_months,
        },
      }),
    getList: ({
      filters,
      pageSize,
      sortBy,
      sortDirection,
      isExcelFile = false,
      ...rest
    }) =>
      restAPI.nodeAPI.POST({
        endpoint: "/estimation_methods/search/configurations",
        body: {
          page_size: pageSize,
          sort_by: sortBy,
          sort_direction: sortDirection,
          ...rest,
          filter: {
            ...getFilterObject(filters),
          },
        },
        ...(isExcelFile
          ? {
              headers: {
                Accept:
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              },
              responseType: "arraybuffer",
            }
          : {}),
      }),
    exportList: ({ filters, sortBy, sortDirection }) => {
      return restAPI.nodeAPI.POST<ReportWithDownloadV2Type>({
        endpoint: "/estimation_methods/export/configurations",
        body: {
          sort_by: sortBy,
          sort_direction: sortDirection,
          filter: getFilterObject(filters),
        },
      });
    },
  };

export interface EstimationMethodConfigurationDomainType {
  /**
   * Get an estimation method configuration
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/estimation_methods/get_estimation_method_configuration
   * @returns the standard wrapped API response of this endpoint
   */
  getOne: (params: {
    id: string;
    meta?: EstimationMethodConfigurationMetaType;
  }) => Promise<EstimationMethodConfigurationType>;
  /**
   * Update an estimation method configuration
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/estimation_methods/update_estimation_method_configuration
   * @returns the standard wrapped API response of this endpoint
   */
  update: (params: {
    id: string;
    data: Partial<
      Omit<EstimationMethodConfigurationType, "inputs" | "asset_inputs"> & {
        inputs: Record<string, EstimationMethodInputType | null>;
        asset_inputs: Record<
          string,
          Record<string, EstimationMethodInputType | null> | null
        >;
      }
    >;
    meta?: EstimationMethodConfigurationMetaType;
  }) => Promise<EstimationMethodConfigurationType>;
  /**
   * Apply default values to an estimation method configuration
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/estimation_methods/apply_defaults_estimation_method_configuration
   * @returns the standard wrapped API response of this endpoint
   */
  applyDefaults: (params: {
    id: string;
    meta?: EstimationMethodConfigurationMetaType;
  }) => Promise<EstimationMethodConfigurationType>;
  /**
   * Apply default values to many estimation method configurations
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/estimation_methods/bulk_apply_defaults_estimation_method_configuration
   * @returns the standard wrapped API response of this endpoint
   */
  bulkApplyDefaults: (
    params: EstimationMethodConfigurationKeyType[]
  ) => Promise<
    BulkActionResultType<ApplyEstimationMethodConfigurationResultType>
  >;
  /**
   * Apply default values to many estimation method configurations
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/estimation_methods/bulk_apply_defaults_estimation_method_configuration_async
   * @returns the standard wrapped API response of this endpoint
   */
  bulkApplyDefaultsAsync: (
    params: EstimationMethodApplyDefaultsFilterType
  ) => Promise<{ status: JobStatusType; job: JobType }>;
  /**
   * Search for record configurations
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/estimation_methods/search_estimation_method_configurations
   * @returns the standard wrapped API response of this endpoint
   */
  getList: (
    params: GetListRequestType<
      FilterObjectType<EstimationMethodConfigurationFilterType>
    > & { isExcelFile?: boolean }
  ) => Promise<
    GetListResponseType<EstimationMethodWithConfigurationAndRunType>
  >;
  /**
   * Search and export a list of record configurations
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/estimation_methods/export_estimation_method_configurations
   * @returns the standard wrapped API response of this endpoint
   */
  exportList: (
    params: GetListRequestType<
      FilterObjectType<EstimationMethodConfigurationFilterType>
    >
  ) => Promise<ReportWithDownloadV2Type>;
}
