import {
  useBulkDeleteFormSubmissions,
  useExportFormSubmissions,
} from "#hooks/adapters/useFormSubmissions";
import { useNavigate } from "#src/Routers/hooks";
import { linkToFormSubmissionDetail } from "#src/Routers/links";
import { ImportDataAction } from "#src/batteries-included-components";
import { BulkDeleteFormSubmissionDialog } from "#src/batteries-included-components/Dialogs/BulkDeleteFormSubmissionsDialog";
import {
  FormSubmissionsTableFilterArea,
  FormSubmissionsTableFilterAreaDrawerContent,
} from "#src/batteries-included-components/FilterAreas/FormsFilterAreas";
import { FormSubmissionsFilterType } from "#src/batteries-included-components/Panels/FilterPanels/FormSubmissionsFilterPanel";
import {
  getFormattedFiltersBasedOnFormSchema,
  getHeadersFromSchema,
} from "#src/batteries-included-components/Panels/TablePanels/FormSubmissionsTablePanel.helpers";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { getStatusType } from "#src/components/Common/Table/rendererHelper";
import { useExportFormSubmissionAsPDF } from "#src/components/Forms/exportFormSubmission";
import { useTableSortingAndPagination } from "#src/components/Redux/reducers/tableStateReducer";
import {
  useHasPermission,
  useIsFeatureAvailable,
} from "#src/contexts/AuthenticatedContext.helpers";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import { linkToUpdateFormSubmission } from "#src/routes/forms/categories/[categoryId]/templates/[formTemplateId]/update-form-submission";
import {
  UseQueryOptions,
  useInfiniteQuery,
  useQueries,
  useQuery,
} from "@tanstack/react-query";
import {
  Button,
  DataTable,
  DataTablePanel,
  DropdownMenu,
  HeaderType,
  Pill,
  StorageKeys,
} from "@validereinc/common-components";
import {
  FormSchemaAdapter,
  FormSubmissionAdapter,
  FormSubmissionStatus,
  FormSubmissionType,
  ResourceDefinitions,
  Resources,
  SortDirection,
  UserType,
  UsersAdapter,
} from "@validereinc/domain";
import { toFlattenedObject, toStartCaseString } from "@validereinc/utilities";
import isEmpty from "lodash/isEmpty";
import React, { useEffect, useMemo, useState } from "react";

export const FormSubmissionsTablePanel = ({
  isDraft = false,
  formSchemaId,
  filterConfigStorageKey,
  viewConfigStorageKey,
  tableConfigStorageKey,
}: {
  isDraft?: boolean;
  formSchemaId: string;
} & StorageKeys) => {
  const [isDataIngestionEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:data_pipeline",
  });
  const [canDeleteFormSubmissions] = useHasPermission(
    "form_submissions:delete"
  );
  const [canEditFormSubmissions] = useHasPermission("form_submissions:edit");

  const navigate = useNavigate();

  const [viewFilters] = useSessionStickyState<FormSubmissionsFilterType>(
    {},
    viewConfigStorageKey
  );
  const [tableFilters] = useSessionStickyState<FormSubmissionsFilterType>(
    {},
    filterConfigStorageKey
  );

  const filters = useMemo(
    () => ({ ...tableFilters, ...viewFilters }),
    [tableFilters, viewFilters]
  );
  const { created_at, status, created_by, ...restFilters } = filters;
  const formSchemaIdFilter = formSchemaId ?? restFilters["form_schema.id"];
  const [{ sortBy, sortDirection, ...pagination }, setTableState] =
    useTableSortingAndPagination({
      sortBy: "created_at",
      sortDirection: SortDirection.DESCENDING,
    });
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [filteredAnswers, setFilteredAnswers] = useState<Set<string> | null>(
    null
  );

  const {
    selectedFormSubmissions,
    setSelectedFormSubmissions,
    isDeleting,
    handleBulkDelete,
  } = useBulkDeleteFormSubmissions();

  const schemaQuery = useQuery({
    queryKey: [Resources.FORM_SCHEMA, formSchemaId],
    queryFn: () => {
      if (!formSchemaId) return;

      return FormSchemaAdapter.getOne({
        id: formSchemaId,
      });
    },
    enabled: Boolean(formSchemaId),
    select: (resp) => resp?.data,
    staleTime: 2 * 60 * 1000,
  });

  const formattedAnswerFilters = useMemo(
    () =>
      schemaQuery.data
        ? getFormattedFiltersBasedOnFormSchema(restFilters, schemaQuery.data)
        : null,
    [restFilters, schemaQuery]
  );

  const hasFormattedAnswerFilters = !isEmpty(formattedAnswerFilters);

  const answersQueryFilter = {
    created_at,
    created_by,
    ...toFlattenedObject({
      status: isDraft
        ? FormSubmissionStatus.DRAFT
        : status
          ? status
          : Object.values(FormSubmissionStatus).filter(
              (s) => s !== FormSubmissionStatus.DRAFT
            ),
    }),
    ...formattedAnswerFilters,
  };

  const {
    data: answersQuery,
    hasNextPage: answersQueryHasNextPage,
    isFetching: answersQueryIsFetching,
    isLoading: answersQueryIsLoading,
    fetchNextPage: answersQueryFetchNextPage,
  } = useInfiniteQuery({
    queryKey: [
      "forms",
      "submissions",
      "answers",
      formSchemaId,
      answersQueryFilter,
    ],
    queryFn: ({ pageParam }) => {
      if (!formSchemaId || !hasFormattedAnswerFilters) return;

      return FormSubmissionAdapter.answers.getListV2({
        page: pageParam ?? 1,
        pageSize: 500,
        sortBy,
        sortDirection,
        filters: answersQueryFilter,
        meta: {
          form_schema_id: formSchemaId,
        },
      });
    },
    getNextPageParam: (resp) => {
      return resp
        ? resp.page_number < resp.total_pages
          ? resp.page_number + 1
          : undefined
        : undefined;
    },
    enabled: Boolean(formSchemaId) && hasFormattedAnswerFilters,
    staleTime: 2 * 60 * 1000,
  });

  const queryPayload: Parameters<typeof FormSubmissionAdapter.getList>[0] = {
    page: pagination.page,
    pageSize: pagination.pageSize,
    sortBy,
    sortDirection,
    filters: {
      created_by,
      created_at,
      ...toFlattenedObject({
        status: isDraft
          ? FormSubmissionStatus.DRAFT
          : status
            ? status
            : Object.values(FormSubmissionStatus).filter(
                (s) => s !== FormSubmissionStatus.DRAFT
              ),
        "form_schema.status": "active",
      }),
      ...(hasFormattedAnswerFilters && filteredAnswers
        ? { id: Array.from(filteredAnswers) }
        : {}),
      ...(formSchemaId ? { "form_schema.id": formSchemaId } : {}),
    },
    meta: { answers: true },
  };

  const formSubmissionsQuery = useQuery({
    queryKey: [Resources.FORM_SUBMISSION, queryPayload],
    queryFn: () => {
      if (
        !formSchemaId ||
        (hasFormattedAnswerFilters &&
          (answersQueryIsLoading || answersQueryIsFetching))
      )
        return;

      return FormSubmissionAdapter.getList(queryPayload);
    },
    enabled:
      Boolean(formSchemaId) &&
      (!hasFormattedAnswerFilters ||
        (!answersQueryIsLoading && !answersQueryIsFetching)),
    staleTime: 2 * 60 * 1000,
  });

  const formSubmissionsCreatedByUsers = useQueries<
    Array<
      UseQueryOptions<
        Awaited<ReturnType<typeof UsersAdapter.getOne>> | undefined,
        unknown,
        UserType | undefined
      >
    >
  >({
    queries:
      formSubmissionsQuery?.data?.data.map((submission) => ({
        queryKey: ["users", submission.created_by],
        queryFn: () =>
          UsersAdapter.getOne({
            id: submission.created_by,
          }),
        enabled: Boolean(submission.created_by),
        staleTime: 3 * 60 * 1000,
        select: (resp) => resp?.data,
      })) ?? [],
  });

  const formSubmissionsCreatedByUsersMap = useMemo(() => {
    return formSubmissionsCreatedByUsers.reduce<Record<string, UserType>>(
      (map, q) => {
        if (!q.data?.id || map[q.data.id]) {
          return map;
        }

        map[q.data.id] = q.data;
        return map;
      },
      {}
    );
  }, [formSubmissionsCreatedByUsers]);

  const bulkExportXLSXMutation = useExportFormSubmissions(queryPayload);
  const exportPDFMutation = useExportFormSubmissionAsPDF({
    includeEmptyAnswers: isDraft,
    showUpdatedAt: isDraft,
    metaUserDataMap: formSubmissionsCreatedByUsersMap,
  });
  const { measurementUnits } = useMeasurementTypes();

  const actionRowWhenRowsSelected = [
    ...(canDeleteFormSubmissions
      ? [
          <Button
            key="bulk-delete"
            variant="error-outline"
            onClick={() => setShowDeleteDialog(true)}
            disabled={!Object.keys(selectedFormSubmissions).length}
            isLoading={isDeleting}
          >
            Delete
          </Button>,
        ]
      : []),
    <DropdownMenu
      key="export"
      options={[
        {
          label: "As XLSX",
          isDisabled: bulkExportXLSXMutation.isLoading,
          onClick: () => bulkExportXLSXMutation.mutate(),
        },
      ]}
    >
      <Button
        key="export-equipment"
        variant="outline"
        isLoading={bulkExportXLSXMutation.isLoading}
        icon="caret-down"
        iconPosition="right"
      >
        Export{" "}
        {formSubmissionsQuery.data?.total_entries ? (
          <>({formSubmissionsQuery.data.total_entries})</>
        ) : (
          ""
        )}
      </Button>
    </DropdownMenu>,
    isDraft || !isDataIngestionEnabled ? null : (
      <ImportDataAction
        key="import-form-template-submission"
        resource={{
          ...ResourceDefinitions.form_schema,
          label: {
            singular: "Form Template Submission",
            plural: "Form Template Submissions",
          },
        }}
        resourceId={formSchemaIdFilter}
      />
    ),
  ];

  // remove Delete action when no rows are selected
  const actionRowWhenNoRowsSelected = [
    <DropdownMenu
      key="export"
      options={[
        {
          label: "As XLSX",
          isDisabled: bulkExportXLSXMutation.isLoading,
          onClick: () => bulkExportXLSXMutation.mutate(),
        },
      ]}
    >
      <Button
        key="export-equipment"
        variant="outline"
        isLoading={bulkExportXLSXMutation.isLoading}
        icon="caret-down"
        iconPosition="right"
      >
        Export{" "}
        {formSubmissionsQuery.data?.total_entries ? (
          <>({formSubmissionsQuery.data.total_entries})</>
        ) : (
          ""
        )}
      </Button>
    </DropdownMenu>,
    isDraft || !isDataIngestionEnabled ? null : (
      <ImportDataAction
        key="import-form-template-submission"
        resource={{
          ...ResourceDefinitions.form_schema,
          label: {
            singular: "Form Template Submission",
            plural: "Form Template Submissions",
          },
        }}
        resourceId={formSchemaIdFilter}
      />
    ),
  ];

  const dynamicSubmissionsHeaders = useMemo(
    () => getHeadersFromSchema(measurementUnits, schemaQuery.data),
    [schemaQuery.data]
  );

  const submissionsHeaders: Array<HeaderType<FormSubmissionType>> = [
    {
      label: "Name",
      key: "id",
      renderComponent: ({ item }) => (
        <RoutingLink
          to={
            isDraft
              ? linkToUpdateFormSubmission(
                  item?.form_schema?.form_category_id,
                  item?.form_schema?.id,
                  item?.id
                )
              : linkToFormSubmissionDetail(item.id)
          }
        >
          {`${item?.form_schema?.name} - ${item?.id?.slice(0, 7)}`}
        </RoutingLink>
      ),
    },
    ...(!isDraft
      ? [
          {
            label: "Status",
            key: "status",
            isSortable: true,
            renderComponent: ({ item }: { item: FormSubmissionType }) => (
              <Pill variant={getStatusType(item.status, "form").type}>
                {toStartCaseString(item.status)}
              </Pill>
            ),
          },
        ]
      : []),
    {
      label: "Created At",
      key: "created_at",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={item.created_at}
          withTime
        />
      ),
    },
    {
      label: "Last Saved At",
      key: "updated_at",
      isSortable: true,
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={item.updated_at}
          withTime
        />
      ),
    },
    {
      label: isDraft ? "Saved By" : "Submitted By",
      key: "created_by",
      isSortable: true,
      renderComponent: ({ item }) =>
        formSubmissionsCreatedByUsersMap[item.created_by]?.name ?? "-",
    },
    ...dynamicSubmissionsHeaders,
  ];

  useEffect(() => {
    setTableState({ page: 1, pageSize: 25 });
  }, [isDraft]);

  useEffect(() => {
    if (answersQueryHasNextPage && !answersQueryIsFetching) {
      answersQueryFetchNextPage();
    }
  }, [answersQueryHasNextPage, answersQueryIsFetching]);

  useEffect(() => {
    const newAnswerIds =
      answersQuery?.pages.flatMap((page) => page?.data.map((d) => d.id)) ?? [];
    setFilteredAnswers(() => {
      const newSet = new Set<string>();
      newAnswerIds.forEach((answerId) => {
        if (answerId) {
          newSet.add(answerId);
        }
      });
      return newSet;
    });
  }, [answersQuery?.pages]);

  return (
    <>
      <DataTablePanel
        storageKey={tableConfigStorageKey}
        filterComponent={
          <FormSubmissionsTableFilterArea
            filterConfigStorageKey={filterConfigStorageKey}
            filterDrawerContentSlot={
              <FormSubmissionsTableFilterAreaDrawerContent
                hasStatusFilter
                hasSubmittedByFilter
                formSchemaId={formSchemaId}
              />
            }
          />
        }
        panelProps={{
          title: isDraft ? "Draft Form Submissions" : "Form Submissions",
        }}
        actionRowWhenRowsSelected={actionRowWhenRowsSelected}
        actionRowWhenNoRowsSelected={actionRowWhenNoRowsSelected}
        dataTableProps={{
          headers: submissionsHeaders,
          isLoading:
            formSubmissionsQuery?.isLoading ||
            (hasFormattedAnswerFilters && answersQueryIsLoading),
          isBusy:
            formSubmissionsQuery.isFetching ||
            (hasFormattedAnswerFilters && answersQueryIsFetching),
          items: formSubmissionsQuery?.data?.data ?? [],
          pagination: {
            ...pagination,
            total: formSubmissionsQuery.data?.total_entries,
          },
          sorting: {
            sortBy,
            sortDirection,
          },
          selected: selectedFormSubmissions,
          onSortChange: setTableState,
          onPaginationChange: setTableState,
          getItemId: canDeleteFormSubmissions ? (item) => item.id : undefined,
          onSelectionChange: setSelectedFormSubmissions,
          getItemActions: ({ item }: { item: FormSubmissionType }) => [
            ...(item?.status !== FormSubmissionStatus.DRAFT &&
            item?.form_schema?.form_submission_editable &&
            canEditFormSubmissions
              ? [
                  {
                    label: "Edit",
                    buttonProps: {
                      icon: "pencil-simple",
                      onClick: () =>
                        navigate({
                          pathname: linkToUpdateFormSubmission(
                            item?.form_schema?.form_category_id,
                            item?.form_schema?.id,
                            item?.id
                          ),
                        }),
                    },
                  },
                ]
              : []),
            {
              label: "Export as PDF",
              buttonProps: {
                icon: "share",
                isLoading: exportPDFMutation.isLoading,
                onClick: () => {
                  exportPDFMutation.mutate(item);
                },
              },
            },
          ],
        }}
      />
      <BulkDeleteFormSubmissionDialog
        isOpen={showDeleteDialog}
        onClose={() => {
          setShowDeleteDialog(false);
        }}
        handleBulkDelete={handleBulkDelete}
        formSubmissionsCount={Object.keys(selectedFormSubmissions).length}
      />
    </>
  );
};
