import {
  DEFAULT_QUERY_OPTIONS,
  useCreateOne,
  useDeleteOne,
} from "#hooks/adapters/adapterUtils";
import { useQuery } from "@tanstack/react-query";
import {
  DefaultRecordValueConfigurationDomain,
  DefaultRecordValueConfigurationType,
  Resources,
} from "@validereinc/domain";

export const DRVC_QUERY_KEY = Resources.DEFAULT_RECORD_VALUE_CONFIGURATION;

export const useGetOneDRVC = (
  params: {
    drvcId: string;
  },
  options: { enabled?: boolean } = {}
) =>
  useQuery({
    queryKey: [DRVC_QUERY_KEY, "getOne", params],
    queryFn: () => DefaultRecordValueConfigurationDomain.getOne(params),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  });

export const useCreateDefaultRecordValueConfiguration =
  useCreateOne<DefaultRecordValueConfigurationType>(
    DefaultRecordValueConfigurationDomain.createOne,
    DRVC_QUERY_KEY
  );

export const useDeleteDefaultRecordValueConfiguration =
  useDeleteOne<DefaultRecordValueConfigurationType>(
    DefaultRecordValueConfigurationDomain.deleteOne,
    DRVC_QUERY_KEY
  );
