import {
  BaseError,
  TimeMetricUnits,
  type AttributeDataTypeType,
  type FormSchemaQuestionType,
  type FormSchemaType,
  type FormSubmissionAnswersBySectionMapType,
  type TimeUnitsType,
} from "@validereinc/domain";
import {
  isDateRangeValue,
  formatNumberToFixedPrecise,
} from "@validereinc/utilities";
import isValidDate from "date-fns/isValid";
import minutesToHours from "date-fns/minutesToHours";
import minutesToMilliseconds from "date-fns/minutesToMilliseconds/index";
import minutesToSeconds from "date-fns/minutesToSeconds";
import parse from "date-fns/parse/index";
import parseISO from "date-fns/parseISO/index";
import Mexp from "math-expression-evaluator";
import { findAnswerForQuestion } from "./util";

export class CalculatedFieldService {
  static hasValidCharacters(equation: string) {
    // Check for word characters, decimal numbers, arithmetic symbols and whitespace
    return /^[\w\-+*/\s().]+$/i.test(equation);
  }

  static getVariables(equation: string) {
    if (typeof equation !== "string") return [];

    const matches = equation.matchAll(/\(*([\w.]+)\)*[-+*/]|\(*([\w.]+)\)*/g);
    const elements = Array.from(matches, (match) =>
      match[1] ? match[1] : match[2]
    );
    const variables = [];

    for (const element of elements) {
      const asNumber = Number(element);

      if (Number.isFinite(asNumber)) {
        continue;
      }

      variables.push(element);
    }

    return variables;
  }

  static removeWhitespace(equation: string) {
    // Remove all whitespace
    return equation.replace(/\s+/g, "");
  }

  static hasValidSyntax(equation: string) {
    const variables = this.getVariables(equation);
    const inputs: Record<string, number> = {};
    variables.forEach((variable) => {
      inputs[variable] = 1;
    });
    const equationToParse = CalculatedFieldService.substitute(equation, inputs);
    const mexp = new Mexp();
    try {
      mexp.lex(equationToParse, []);
      return true;
    } catch {
      return false;
    }
  }

  static evaluate(equation: string, inputs: Record<string, number>) {
    const equationToEvaluate = CalculatedFieldService.substitute(
      equation,
      inputs
    );
    const mexp = new Mexp();
    return mexp.eval(equationToEvaluate, [], {});
  }

  static substitute(equation: string, inputs: Record<string, number>) {
    let updated = equation;

    for (const [symbol, value] of Object.entries(inputs)) {
      // regex to match whole symbol with no additional word characters or numbers before or after
      const regex = new RegExp(`(?<![\\w.])${symbol}(?![\\w.])`, "g");
      updated = updated.replaceAll(regex, formatNumberToFixedPrecise(value));
    }

    return updated;
  }

  /** Returns a string ready to be rendered by MathDisplay if asAscii is true */
  static substituteLabels(
    equation: string,
    inputs: Record<string, string>,
    asAscii = false
  ) {
    let updated = equation;
    const quoteChar = asAscii ? '"' : "";

    for (const [symbol, value] of Object.entries(inputs)) {
      // regex to match whole symbol with no additional word characters or numbers before or after
      const regex = new RegExp(`(?<![\\w.])${symbol}(?![\\w.])`, "g");
      updated = updated.replaceAll(
        regex,
        ` (${quoteChar}${value}${quoteChar}) `
      );
    }

    return asAscii ? `\`${updated}\`` : updated;
  }

  static isEvaluatableValue(value: unknown): boolean {
    if (
      (typeof value === "string" && value.trim() === "") ||
      value === null ||
      value === undefined
    )
      return false;

    return typeof value === "string" || typeof value === "number"
      ? Number.isFinite(Number(value))
      : false;
  }

  /**
   * Get all the tokens for a given equation by performing lexical analysis.
   * lexing is a time expensive operation - use sparingly!
   * @param equation equation to lex
   * @param variablesAndValues record of equation variables and their values
   * @returns all the tokens of the equation, with custom tokens added for provided variables
   */
  static getTokens(
    equation: string,
    variablesAndValues: Record<string, { value: number | null; unit?: string }>
  ) {
    const mexp = new Mexp();

    mexp.addToken(
      Object.entries(variablesAndValues).map(
        ([variableName, variableValueAndUnit]) => ({
          precedence: 0,
          type: 3,
          token: variableName,
          show: `$${variableName}`,
          value: variableValueAndUnit.value,
        })
      )
    );

    const lexicalTokens = mexp.lex(equation);

    return {
      lexicalTokens,
    };
  }
}

export class FormCalculatedFieldService {
  /**
   * Analyze all the equations of a form schema to understand how to evaluate
   * them
   * @param questionIds the list of question IDs within one section
   * @param questionsConfig complete form schema questions config
   * @returns the overall evaluation order of questions, map of question IDs to
   * question IDs with equations that require that question, and map of question
   * IDs with equations to list of question IDs referenced in each equation
   */
  static analyzeEquationsOfQuestionsInSection(
    questionIds: string[],
    questionsConfig: FormSchemaType["config"]["questions"],
    {
      parser,
      skip,
    }: {
      /** predicate to run to extract a list of referenced question IDs from an equation */
      parser: (
        equation: NonNullable<FormSchemaQuestionType["equation"]>
      ) => string[];
      /** predicate to run to determine if a question should be skipped for analysis */
      skip: (question: FormSchemaQuestionType) => boolean;
    }
  ) {
    // Map of question IDs to question IDs with equations that require that question
    const equationDependents: Record<string, string[]> = {};
    // Map of question IDs with or without equations to # of other questions it depends on. 0 if it doesn't have an equation. > 0 if it does have an equation.
    const equationRequirements: Record<string, number> = {};
    // Map of question IDs with equations to list of question IDs referenced in each equation
    const equationDependencies: Record<string, string[]> = {};
    // Generate an evaluation order such that questions are evaluated after their equation inputs
    // NOTE: this may include questions in different sections (including sections yet to be validated)
    const evaluationOrder: string[] = [];

    questionIds.forEach((questionId) => {
      const question = questionsConfig[questionId];

      if (skip(question) || !question.equation) {
        evaluationOrder.push(questionId);
        return;
      }

      equationRequirements[questionId] = 0;

      const referencedQuestions = parser(question.equation);

      equationDependencies[questionId] = referencedQuestions;

      referencedQuestions.forEach((inputQuestionId) => {
        // current parsed question is referenced, but doesn't depend on anything
        // yet (will be updated probably when we get to this question and it
        // also has an equation)
        if (equationRequirements[inputQuestionId] === undefined) {
          equationRequirements[inputQuestionId] = 0;
        }

        // current question depends on one other question (the current parsed question)
        equationRequirements[questionId] += 1;

        if (equationDependents[inputQuestionId] === undefined) {
          equationDependents[inputQuestionId] = [];
        }

        // current parsed question is referenced by equation in current question
        equationDependents[inputQuestionId].push(questionId);
      });
    });

    // Process inputs and dependencies until all equations are processed
    // Start with questions that don't depend on any other
    const questions = Object.keys(equationRequirements).filter(
      (qId) => equationRequirements[qId] === 0
    );

    while (questions.length > 0) {
      const questionId = questions.shift();

      if (questionId === undefined) break;
      if (!evaluationOrder.includes(questionId))
        evaluationOrder.push(questionId);

      delete equationRequirements[questionId];

      for (const nextQuestionId of equationDependents[questionId] ?? []) {
        equationRequirements[nextQuestionId] -= 1;

        if (equationRequirements[nextQuestionId] == 0) {
          // nextQuestionId has no more requirements so push it to processing queue
          questions.push(nextQuestionId);
        }
      }
    }

    if (questions.length > 0 || Object.keys(equationRequirements).length > 0) {
      throw new BaseError(
        "Form configuration contains cyclic equation references."
      );
    }

    return {
      evaluationOrder,
      equationDependents,
      equationDependencies,
    };
  }

  /**
   * Can an evaluatuable value be extracted from a given question's answer?
   * @param value form question answer
   * @returns true if that's possible, false if not
   */
  static canGetEvaluatuableValueFromAnswer(value: unknown): boolean {
    if (
      (typeof value === "string" && value.trim() === "") ||
      value === null ||
      value === undefined
    )
      return false;

    if (typeof value === "number" || Number.isFinite(Number(value)))
      return true;
    if (value instanceof Date || isValidDate(value)) return true;
    if (
      typeof value === "string" &&
      (isValidDate(parse(value, "yyyy-MM-dd", new Date())) ||
        isValidDate(parseISO(value)))
    )
      return true;
    if (isDateRangeValue(value)) return true;

    return false;
  }

  /**
   * Get the evaluatable value from a form question's answer value
   * @see {@link convertEvaluatedValueIfNecessary()} logic within this function is affected by this function's output
   * @param value the question's answer value
   * @param dataType the data type of the question
   * @returns evaluatable value (i.e. value that can be used for calculations)
   */
  static getEvaluatableValueFromAnswer(
    value: unknown,
    dataType: AttributeDataTypeType
  ): number | null {
    let evaluatableValue = NaN;

    switch (dataType) {
      case "date-time": {
        if (value instanceof Date || typeof value === "string") {
          evaluatableValue = new Date(value).getTime() / (1000 * 60);
          break;
        }

        break;
      }
      case "date": {
        if (value instanceof Date || typeof value === "string") {
          evaluatableValue = new Date(value).getTime() / (1000 * 60);
          break;
        }

        break;
      }
      case "date-time-range": {
        if (!isDateRangeValue(value)) return null;

        evaluatableValue =
          value.to && value.from
            ? Math.abs(
                new Date(value.to).getTime() - new Date(value.from).getTime()
              ) /
              (1000 * 60)
            : value.from
              ? new Date(value.from).getTime() / (1000 * 60)
              : NaN;
        break;
      }
      case "number": {
        const castValue = Number(value);

        if (castValue === null || !Number.isFinite(castValue)) return null;

        evaluatableValue = castValue;
        break;
      }
      case "integer": {
        const castValue = parseInt(String(value), 10);

        if (!Number.isInteger(castValue)) return null;

        evaluatableValue = castValue;
        break;
      }
    }

    return CalculatedFieldService.isEvaluatableValue(evaluatableValue)
      ? evaluatableValue
      : null;
  }

  /**
   * Convert an evaluated calculated field value to a value ready to be stored.
   * This is because evaluatable values might not be in the same units as the
   * calculated field unit - so it must be converted.
   * @param value evaluated calculated field value
   * @param questionConfig the calculated field question configuration
   * @param derivedTimeUnit the time unit of the calculated field value if it was derived from a measurement with a time unit (e.g. leak volume is derived from leak rate)
   * @returns final calculated field value
   */
  static convertEvaluatedValueIfNecessary(
    value: number,
    questionConfig: FormSchemaQuestionType,
    opts?: { unitOverride?: string }
  ) {
    switch (questionConfig.type) {
      case "measurement": {
        if (!questionConfig.equation) return value;

        if (opts?.unitOverride || questionConfig.measurement_type === "time") {
          /**
           * Note, this is fully dependent on the fact that
           * {@link getEvaluatableValueFromAnswer()} evaluates all date values
           * in the UNIX timestamp as minutes (rather than milliseconds), and
           * that the only valid operation on dates is a difference. i.e. the
           * value here should be a duration value in minutes.
           */
          switch (
            opts?.unitOverride ??
            (questionConfig.measurement_unit as TimeUnitsType)
          ) {
            case TimeMetricUnits.ms:
              return minutesToMilliseconds(value);
            case TimeMetricUnits.s:
              return minutesToSeconds(value);
            case TimeMetricUnits.h:
              return minutesToHours(value);
            case TimeMetricUnits.month: {
              const days = minutesToHours(value) / 24;

              // 30.44 days avg per month, accounting for leap years
              return days / 30.44;
            }
            case TimeMetricUnits.year: {
              const days = minutesToHours(value) / 24;

              // 365.25 days avg per year, accounting for leap years
              return days / 365.25;
            }
            case TimeMetricUnits.d: {
              const hours = minutesToHours(value);
              return hours / 24;
            }
            case TimeMetricUnits.min:
            default:
              return value;
          }
        }

        return value;
      }
      case "question":
      default:
        return value;
    }
  }

  /**
   * Get the unit of measurement for a given form question
   * @param question form question
   * @param isEvaluatableValue is the value for this question an evaluatable
   * value for a calculated field? An evaluatable value is the return value from
   * {@link getEvaluatableValueFromAnswer()}
   * @returns unit of measurement if specified
   */
  static getMeasurementUnitFromQuestion(
    question: FormSchemaQuestionType,
    isEvaluatableValue = false
  ) {
    switch (question.type) {
      case "question": {
        if (isEvaluatableValue) {
          return question.data_type === "date-time" ||
            question.data_type === "date"
            ? "minutes since UNIX epoch"
            : question.units;
        }

        return question.units;
      }
      case "measurement": {
        return question.measurement_unit;
      }
      default:
        return;
    }
  }

  /**
   * Get all the variables of an equation with their corresponding values,
   * sourcing the values from a form submission
   * @param equation the calculated field equation
   * @param questionSectionId the section ID the calculated field question is
   * from
   * @param questionSectionIdx the section index the calculated question is from
   * @param answers form submission answers
   * @param questionsConfig form schema questions
   * @returns a record of every equation variable name with the corresponding
   * value and unit in a submission
   */
  static getEquationVariablesAndValuesFromSubmission(
    equation: string,
    questionSectionId: string,
    questionSectionIdx: number,
    answers: FormSubmissionAnswersBySectionMapType,
    questionsConfig: FormSchemaType["config"]["questions"]
  ) {
    const variables = CalculatedFieldService.getVariables(equation);

    return (
      variables.reduce<Record<string, { value: number | null; unit?: string }>>(
        (map, fieldName) => {
          const q = questionsConfig[fieldName];
          const value =
            FormCalculatedFieldService.getEvaluatableValueFromAnswer(
              findAnswerForQuestion(
                answers,
                fieldName,
                questionSectionId,
                questionSectionIdx,
                {
                  ignoreRepeatedSections: false,
                }
              )?.value,
              q.type === "question" ? q.data_type : "number"
            );

          map[fieldName] = {
            value,
            unit: FormCalculatedFieldService.getMeasurementUnitFromQuestion(
              q,
              true
            ),
          };

          return map;
        },
        {}
      ) ?? {}
    );
  }

  /**
   * Get all the data necessary to display an equation
   * @param equation the calculated field question
   * @param formSchemaQuestionConfig form schema questions
   * @param equationVariablesAndValues equation variables and their
   * corresponding values (and units)
   * @param getTokens get all lexical tokens in the equation? Has a significant
   * performance impact - should not be used if the equation variables and
   * values might be dynamic.
   * @returns a consolidated string version of the equation ready for
   * displaying, a variables to their labels record, and token data if getTokens
   * was set to true
   */
  static getEquationDisplayConfig(
    equation: string,
    formSchemaQuestionConfig: FormSchemaType["config"]["questions"],
    equationVariablesAndValues: Record<
      string,
      { value: number | null; unit?: string }
    >,
    getTokens = false
  ): {
    /** equation ready to be displayed with a DataDisplay component like MathDisplay */
    displayEquation: string;
    /** equation ready to be displayed directly as text */
    plainTextEquation: string;
    /** equation variables to their corresponding display labels */
    variablesToLabelsMap: Record<string, string>;
  } & Partial<ReturnType<typeof CalculatedFieldService.getTokens>> {
    const variablesToLabelsMap = Object.keys(equationVariablesAndValues).reduce<
      Record<string, string>
    >((map, questionId) => {
      if (formSchemaQuestionConfig[questionId]) {
        map[questionId] = formSchemaQuestionConfig[questionId].prompt;
        return map;
      }

      return map;
    }, {});
    const tokens = getTokens
      ? CalculatedFieldService.getTokens(equation, equationVariablesAndValues)
      : {};

    return {
      displayEquation: CalculatedFieldService.substituteLabels(
        equation,
        variablesToLabelsMap,
        true
      ),
      plainTextEquation: CalculatedFieldService.substituteLabels(
        equation,
        variablesToLabelsMap
      ),
      variablesToLabelsMap,
      ...tokens,
    };
  }
}
