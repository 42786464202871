import {
  DEFAULT_INVALIDATE_OPTIONS,
  DEFAULT_QUERY_OPTIONS,
  NON_INVALIDATING_QUERY_OPTIONS,
  UseMutationCallbackType,
  UseQueryOptionsType,
} from "#hooks/adapters/adapterUtils";
import { useAuthenticatedContext } from "#src/contexts/AuthenticatedContext.helpers";
import { ExceptionUtils } from "#src/utils/exception";
import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { PillProps, useToast } from "@validereinc/common-components";
import {
  BaseError,
  EditFlowType,
  FlowDomain,
  FlowStatus,
  FlowStatusType,
  FlowType,
} from "@validereinc/domain";
import { dateFormatter, downloadLink } from "@validereinc/utilities";

export const FLOW_QUERY_KEY = "flows";

export const FlowStatusPillVariants: Record<
  FlowStatusType,
  PillProps["variant"]
> = {
  [FlowStatus.ACTIVE]: "success",
  [FlowStatus.INACTIVE]: "default",
};

export const useGetOneFlow = (
  params: Parameters<typeof FlowDomain.getFlow>[0],
  options: UseQueryOptionsType<FlowType> = {}
) =>
  useQuery({
    queryKey: [FLOW_QUERY_KEY, params] as const,
    queryFn: ({ queryKey: [_, params] }) => FlowDomain.getFlow(params),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  });

export const useListFlows = (
  apiParams: Parameters<typeof FlowDomain.getFlows>[0] = {},
  options: {
    select?: any;
    enabled?: boolean;
  } = {}
) =>
  useQuery({
    queryKey: [FLOW_QUERY_KEY, "getFlows", apiParams] as const,
    queryFn: ({ queryKey: [_, __, params] }) => FlowDomain.getFlows(params),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  });

export const useGetManySingleUpstreamFlow = (
  { ids }: { ids: string[] },
  options: UseQueryOptionsType = {}
) =>
  useQueries({
    queries: ids.map((id) => {
      const params = {
        filters: { "downstream_flows.id": id },
        page_size: 1,
        page: 1,
      };
      return {
        queryKey: [FLOW_QUERY_KEY, "getFlows", params],
        queryFn: () => FlowDomain.getFlows(params),
        select: (resp) => resp?.data[0],
        ...DEFAULT_QUERY_OPTIONS,
        ...options,
      };
    }),
  });

export const useGetManySingleDownstreamFlow = (
  { ids }: { ids: string[] },
  options: UseQueryOptionsType = {}
) =>
  useQueries({
    queries: ids.map((id) => {
      const params = {
        filters: { "upstream_flows.id": id },
        page_size: 1,
        page: 1,
      };
      return {
        queryKey: [FLOW_QUERY_KEY, "getFlows", params],
        queryFn: () => FlowDomain.getFlows(params),
        select: (resp) => resp?.data[0],
        ...DEFAULT_QUERY_OPTIONS,
        ...options,
      };
    }),
  });

export const useCreateFlow = ({ onSuccess }: UseMutationCallbackType = {}) => {
  const { toast } = useToast();
  const { invalidate } = useClearFlowsCache();

  return useMutation({
    mutationFn: (data: Parameters<typeof FlowDomain.createFlow>[0]) =>
      FlowDomain.createFlow(data),
    onSuccess: (data, variables, context) => {
      toast.push({
        intent: "success",
        description: "Successfully created flow",
      });
      invalidate();
      onSuccess?.(data, variables, context);
    },
    onError: () => {
      toast.push({
        intent: "error",
        description: "Failed to create flow",
      });
    },
  });
};

export const useUpdateFlow = ({ onSuccess }: UseMutationCallbackType = {}) => {
  const { toast } = useToast();
  const { invalidate } = useClearFlowsCache();

  return useMutation({
    mutationFn: ({ flowId, ...values }: EditFlowType & { flowId: string }) =>
      FlowDomain.updateFlow({ flowId }, values),
    onSuccess: (data, variables, context) => {
      toast.push({
        intent: "success",
        description: "Successfully updated flow",
      });
      invalidate();
      onSuccess?.(data, variables, context);
    },
    onError: () => {
      toast.push({
        intent: "error",
        description: "Failed to update flow",
      });
    },
  });
};

export const useListFlowTypes = () =>
  useQuery({
    queryKey: [FLOW_QUERY_KEY, "getFlowTypes"],
    queryFn: () => FlowDomain.getFlowTypes(),
    ...NON_INVALIDATING_QUERY_OPTIONS,
  });

export const useListFlowProductTypes = () =>
  useQuery({
    queryKey: [FLOW_QUERY_KEY, "getFlowProductTypes"],
    queryFn: () => FlowDomain.getFlowProductTypes(),
    ...NON_INVALIDATING_QUERY_OPTIONS,
  });

export const useListFlowProductCategories = () =>
  useQuery({
    queryKey: [FLOW_QUERY_KEY, "getFlowProductCategories"],
    queryFn: () => FlowDomain.getFlowProductCategories(),
    ...NON_INVALIDATING_QUERY_OPTIONS,
  });

export const useDeleteFlow = () => {
  const { toast } = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (flowId: string) => FlowDomain.deleteFlow({ flowId }),
    onSuccess: () => {
      toast.push({
        intent: "success",
        description: `Successfully deleted flow`,
      });
      return queryClient.invalidateQueries([FLOW_QUERY_KEY]);
    },
  });
};

export const useExportFlows = (apiParams = {}) => {
  const { toast } = useToast();
  const {
    v2: { companyInfo },
  } = useAuthenticatedContext();

  const getFileName = () =>
    [companyInfo?.company?.name, "Flows_List", dateFormatter(new Date())]
      .filter((part) => !!part)
      .join("_");

  return useMutation({
    mutationFn: async () => {
      const report = await FlowDomain.exportFlows(apiParams);
      if (!report?.s3_download_link) {
        throw new BaseError(`Could not fetch download URL for flows export`, {
          cause: report,
        });
      }
      downloadLink(report.s3_download_link, getFileName());
    },
    onError: (error) => {
      ExceptionUtils.reportException(error, "error");
      toast.push({
        intent: "error",
        description: "Failed to export flows list.",
      });
    },
  });
};

export const useClearFlowsCache = () => {
  const queryClient = useQueryClient();
  return {
    invalidate: () => {
      queryClient.invalidateQueries({
        queryKey: [FLOW_QUERY_KEY],
        ...DEFAULT_INVALIDATE_OPTIONS,
      });
    },
  };
};
