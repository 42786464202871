import { NetworksViewFilterArea } from "#src/batteries-included-components/FilterAreas/AssetsFilterAreas";
import { Link, StorageKeys } from "@validereinc/common-components";
import classNames from "classnames/bind";
import React, { useState } from "react";
import { NetworkDetailsPanel } from "./NetworkDetailsPanel";
import styles from "./NetworkDetailsTab.module.scss";
import { NetworkEntityTablePanel } from "./NetworkEntityTablePanel/NetworkEntityTablePanel";
import { NetworkFilterPanel } from "./NetworkFilterPanel";
import { NetworkMapPanel } from "./NetworkMapPanel";
import { IsFeatureAvailable } from "#src/batteries-included-components/Layouts/Authorization/IsFeatureAvailable";
import { TemplatedConfigurationRunDrawer } from "#src/batteries-included-components/Drawers/TemplatedConfigurationRunDrawer";
import { NetworkDetailPageParameters } from "..";
import { useParams } from "react-router";
import {
  Resources,
  TemplatedConfigurationRunAdapter,
} from "@validereinc/domain";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import { useQuery } from "@tanstack/react-query";

const cx = classNames.bind(styles);

export const NetworkDetailsTab = ({
  viewConfigStorageKey,
}: Pick<StorageKeys, "viewConfigStorageKey">) => {
  const { networkId } = useParams<NetworkDetailPageParameters>();

  const [isTemplatedConfigsAvailable] = useIsFeatureAvailable({
    featureFlagQuery: "core:templated_configurations",
    permissionQuery: "templated_configurations:read",
  });

  const templatedConfigurationRunDependencyGraphQuery = useQuery({
    queryKey: [
      Resources.TEMPLATED_CONFIGURATION_RUN,
      "dependencyGraph",
      networkId,
    ],
    queryFn: () =>
      TemplatedConfigurationRunAdapter.dependencyGraph.getOne({
        id: networkId,
      }),
    enabled: isTemplatedConfigsAvailable,
  });

  const [
    isTemplatedConfigurationRunDrawerOpen,
    setIsTemplatedConfigurationRunDrawerOpen,
  ] = useState(false);

  const templatedConfigurationRunDependencyGraph =
    templatedConfigurationRunDependencyGraphQuery?.data?.data;

  const templatedConfigurationLink =
    templatedConfigurationRunDependencyGraph?.name ? (
      <Link
        onClick={() => setIsTemplatedConfigurationRunDrawerOpen(true)}
        label={templatedConfigurationRunDependencyGraph.name}
      />
    ) : undefined;

  return (
    <>
      <NetworksViewFilterArea viewConfigStorageKey={viewConfigStorageKey} />
      <div className={cx("container")}>
        <div className={cx("detailsContainer")}>
          <NetworkDetailsPanel
            viewConfigStorageKey={viewConfigStorageKey}
            templatedConfigurationLink={templatedConfigurationLink}
          />
          <NetworkMapPanel viewConfigStorageKey={viewConfigStorageKey} />
        </div>

        <div className={cx("entityTableContainer")}>
          <NetworkFilterPanel />
          <NetworkEntityTablePanel
            viewConfigStorageKey={viewConfigStorageKey}
          />
        </div>
      </div>
      <IsFeatureAvailable
        featureFlagQuery="core:templated_configurations"
        permissionQuery="templated_configurations:read"
        fallbackChildren={null}
      >
        <TemplatedConfigurationRunDrawer
          isOpen={isTemplatedConfigurationRunDrawerOpen}
          onClose={() => setIsTemplatedConfigurationRunDrawerOpen(false)}
          templatedConfigurationRunDependencyGraph={
            templatedConfigurationRunDependencyGraph
          }
          resourceId={networkId}
        />
      </IsFeatureAvailable>
    </>
  );
};
