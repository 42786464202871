import {
  SummaryInformation,
  SummaryInformationContainer,
} from "#components/Common/SummaryInformation";
import DataMappingService from "#components/Services/DataMappingService";
import { getBreadcrumbsObject } from "#routers/breadcrumbsHelper";
import { useNavigate, useSearchParams } from "#routers/hooks";
import { linkToDataMapping } from "#routers/links";
import poll from "#src/hooks/usePoll";
import useTableState from "#src/hooks/useTableState";
import { getFrontendTableState } from "#utils/frontendTableActions";
import {
  Button,
  DataTable,
  Page,
  Panel,
  Pill,
  SortingType,
  useToast,
} from "@validereinc/common-components";
import { SortDirection } from "@validereinc/domain";
import classNames from "classnames/bind";
import get from "lodash/get";
import startCase from "lodash/startCase";
import * as PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./CreateDataMapping.module.scss";

const cx = classNames.bind(styles);

const POLL_INTERVAL = 1000; // 1 second
const MAX_ATTEMPTS = 30;

const sorting: SortingType = {
  sortBy: "op_type",
  sortDirection: SortDirection.ASCENDING,
};

const ValidationForm = ({
  breadcrumbs,
  title,
  successfulMessage,
  actionRow,
}) => {
  // Expect data mapping id and task id to be provided
  const [searchParams] = useSearchParams();
  const [isPlanFinished, setIsPlanFinished] = useState(false);
  const [formState, setFormState] = useState("loaded");
  const navigate = useNavigate();
  const { toast } = useToast();
  const [status, setStatus] = useState({});
  const [entityCategories, setEntityCategories] = useState([]);

  const { taskId } = searchParams;

  const onFetchData = useCallback(
    async ({ fetchPlanPreview = false, ...newSearchParams }) => {
      let newResponse;

      if (fetchPlanPreview) {
        const previewResponse = await DataMappingService.getTaskPlanPreview({
          taskId,
        });

        newResponse = {
          ...previewResponse,
          data: {
            data: previewResponse.data.entity_list.map(
              ({ entity_data, op_type }) => ({
                ...JSON.parse(entity_data),
                op_type,
              })
            ),
          },
        };
      }

      const searchParamsWithDefaults = {
        ...newSearchParams,
        sort: newSearchParams.sort ?? sorting.header,
        sortDirection: newSearchParams.sortDirection ?? sorting.direction,
      };

      return getFrontendTableState({
        data: newResponse,
        itemsKey: "data.data",
        query: searchParamsWithDefaults,
      });
    },
    [isPlanFinished]
  );

  const { data, tableProps, loading, fetchData } = useTableState({
    onFetchData,
    initialSort: sorting,
  });

  const handleOnNextClick = async () => {
    setFormState("loading");
    try {
      await DataMappingService.applyTaskPlan(taskId);

      toast.push({
        intent: "success",
        description: successfulMessage,
      });
      navigate({ pathname: linkToDataMapping() });
    } finally {
      setFormState("loaded");
    }
  };

  const handleOnCancelClick = () => {
    navigate({ pathname: linkToDataMapping() });
  };

  const fetchPlanPreview = () => {
    fetchData({ fetchPlanPreview: true });
  };

  useEffect(() => {
    if (!taskId) {
      return;
    }

    const validate = ({ data }) => data?.task_status === "PLAN_COMPLETED";

    const fetch = async () => {
      return await DataMappingService.getDataMappingTaskStatus(taskId);
    };

    (async () => {
      try {
        await poll({
          fetch,
          validate,
          maxAttempts: MAX_ATTEMPTS,
          interval: POLL_INTERVAL,
        });

        fetchPlanPreview();
      } catch (error) {
        toast.push({
          intent: "warning",
          description:
            "Creating Data Mapping is taking more time. Please refresh the page.",
        });
      }
      setIsPlanFinished(true);
    })();
  }, [taskId]);

  useEffect(() => {
    (async () => {
      const { data } =
        await DataMappingService.getDataMappingTaskStatus(taskId);

      setStatus(data);
    })();
  }, []);

  useEffect(() => {
    if (!status.mapping_entity) {
      return;
    }

    (async () => {
      const { data } = await DataMappingService.getEntityCategories({
        entityCategory: status.mapping_entity,
        dataMappingId: status.data_mapping_id,
      });

      setEntityCategories(data.attrs);
    })();
  }, [status]);

  const isDataMappingPreviewLoading = !isPlanFinished || loading;

  const footer = (
    <div className={cx("footer")}>
      <Button onClick={handleOnCancelClick}>Cancel</Button>

      <div className={cx("stepActionContainer")}>
        <Button
          variant="primary"
          onClick={handleOnNextClick}
          disabled={isDataMappingPreviewLoading || formState !== "loaded"}
        >
          Ingest Data
        </Button>
      </div>
    </div>
  );

  const entityHeaders = useMemo(() => {
    return entityCategories.map(({ field_name }) => ({
      label: startCase(field_name),
      key: field_name,
      renderComponent: ({ item }) => {
        const value = get(item, field_name);

        switch (typeof value) {
          case "object":
            if (Array.isArray(value)) {
              if (typeof value?.[0] === "string") {
                return value.join(", ");
              } else if (typeof value?.[0] === "object") {
                // format: { key1: value1, ... }, { ...value[1] }, ...
                return value.reduce((finalString, current) => {
                  const currentString = Object.entries(current)
                    .map(([key, value]) => `${key}: ${value}`)
                    .join(", ");

                  return `${
                    finalString.length ? `${finalString}, ` : ""
                  }{${currentString}}`;
                }, "");
              }
            }

            return Object.entries(value)
              .map(([key, value]) => `${key}: ${value}`)
              .join(", ");
          default:
            return value ?? "-";
        }
      },
    }));
  }, [entityCategories]);

  const headers = useMemo(
    () => [
      {
        label: "Row Status",
        key: "op_type",
        renderComponent: ({ item }) => (
          <Pill variant={item.op_type === "new" ? "success" : "error"}>
            {item.op_type === "new" ? "Created" : "Skipped"}
          </Pill>
        ),
        isSortable: true,
      },

      ...entityHeaders,
    ],
    [entityHeaders]
  );

  const overviewData = useMemo(() => {
    if (!data?.allItems?.length || !status?.file_path) {
      return {};
    }

    let rowsCreated = 0;
    let rowsSkipped = 0;

    data.allItems.forEach((item) => {
      if (item.op_type === "new") {
        rowsCreated++;
      } else {
        rowsSkipped++;
      }
    });

    return {
      file: status?.file_path.split("/").pop(),
      rowsCreated,
      rowsSkipped,
    };
  }, [status, data]);

  return (
    <Page
      title={title}
      breadcrumbs={getBreadcrumbsObject(breadcrumbs)}
      actionRow={actionRow}
      footer={footer}
      footerAlign="left"
    >
      <Panel title="Validation Overview">
        <SummaryInformationContainer>
          <SummaryInformation
            title="File"
            value={overviewData?.file ?? "-"}
            type="vertical"
            isLoading={isDataMappingPreviewLoading}
          />
          <SummaryInformation
            title="Rows Created"
            value={overviewData?.rowsCreated}
            type="vertical"
            isLoading={isDataMappingPreviewLoading}
          />
          <SummaryInformation
            title="Rows Skipped"
            value={overviewData?.rowsSkipped}
            type="vertical"
            isLoading={isDataMappingPreviewLoading}
          />
        </SummaryInformationContainer>
      </Panel>

      <Panel>
        <DataTable
          headers={headers}
          {...tableProps}
          isLoading={isDataMappingPreviewLoading}
        />
      </Panel>
    </Page>
  );
};

ValidationForm.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  successfulMessage: PropTypes.string.isRequired,
  actionRow: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default ValidationForm;
