import { TemplatedReportGeneratedReportsTablePanel } from "#src/routes/reports/templated-reports/TemplatedReportGeneratedReports/TemplatedReportGeneratedReportsTablePanel";
import React from "react";

export const TemplatedReportGeneratedReportsTab = () => {
  return (
    <>
      <TemplatedReportGeneratedReportsTablePanel />
    </>
  );
};
