import { signOut } from "#redux/actions/authentication";
import UserService from "#services/UserService";
import { AccountNavBar } from "#src/batteries-included-components/Layouts/Authentication/AccountNavBar";
import { AccessDeniedLayout } from "#src/batteries-included-components/Layouts/Authorization/AccessDenied";
import { PageErrorContent } from "#src/batteries-included-components/Layouts/Errors/PageError";
import { UnsupportedMediaError } from "#src/batteries-included-components/Layouts/Errors/UnsupportedMediaError";
import { AuthenticatedContextAuthVersionType } from "#src/contexts/AuthenticatedContext";
import {
  useAuthenticatedContext,
  useGetHolisticAuthorizationEvaluators,
} from "#src/contexts/AuthenticatedContext.helpers";
import { ExceptionUtils } from "#src/utils/exception";
import { useMediaBreakpoint } from "#src/utils/useMediaBreakpoint";
import classNames from "classnames/bind";
import React, { useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import PageConfigs from "../../Routers/PageConfigs";
import AlertMessages from "../AlertMessages/AlertMessages";
import { isFeatureEnabled } from "../Redux/reducers/featureFlags";
import { havePermission } from "../Redux/reducers/permissions";
import ContactSupportModal from "./ContactSupportModal";
import styles from "./Layout.module.css";
import { useGetSidebarTabsAvailable } from "./LayoutContentHelpers";
import SideBar from "./SideBar/SideBar";

const cx = classNames.bind(styles);

const mapStateToProps = (state) => {
  return {
    role: state.profiles.data.role,
    havePermission: havePermission(state.permissions),
    checkIfFeatureEnabled: isFeatureEnabled(state.featureFlags),
    profile: state.profiles.data,
  };
};

const mapDispatchToProps = {
  signOut,
};

// IMPROVE: this component and all the related logic and code in this file needs
// another refactor pass after the first major overhaul deployed on March 21st,
// 2024. will be tackled in the routing engine overhaul to come.
//       (https://validere.atlassian.net/browse/CHB-2026) we also need to rip
//       out Redux at some point.
export const LayoutContent = (props) => {
  const {
    appVersion,
    activeAuthVersion,
    v2: {
      companyInfo: { checkIsFeatureEnabled },
    },
  } = useAuthenticatedContext();
  const { hasPermissionsWaterfall, isFeatureEnabledWaterfall } =
    useGetHolisticAuthorizationEvaluators();
  const [showContactUsModal, setShowContactUsModal] = useState(false);
  const { currentBreakpointWidth, allBreakpoints } = useMediaBreakpoint();

  const onContactSupportModalClose = () => {
    setShowContactUsModal(false);
  };

  const onContactSupportModalOpen = () => {
    setShowContactUsModal(true);
  };

  const [sidebarTabsAvailable] = useGetSidebarTabsAvailable({
    onClickBindings: {
      contact: {
        id: "contact",
        link: onContactSupportModalOpen,
      },
    },
  });

  const onSignOutClick = () => {
    UserService.reportLogout().finally(() => {
      props.signOut();
    });
  };

  const isDualAuth =
    activeAuthVersion !== "unknown" &&
    Array.isArray(activeAuthVersion) &&
    activeAuthVersion.length === 2 &&
    (["v1", "v2"] satisfies AuthenticatedContextAuthVersionType[]).every((v) =>
      activeAuthVersion.includes(v)
    );
  const isV2AuthOnly =
    activeAuthVersion !== "unknown" &&
    Array.isArray(activeAuthVersion) &&
    activeAuthVersion.length === 1 &&
    activeAuthVersion.includes("v2");
  const isAccountNavBarEnabled =
    (isDualAuth && !checkIsFeatureEnabled("ops:auth")) || isV2AuthOnly;

  return (
    <div
      className={cx(
        "page-container",
        "with-sidebar-sibling",
        isAccountNavBarEnabled && "with-account-nav-bar"
      )}
    >
      {currentBreakpointWidth <= allBreakpoints.xs ? (
        <UnsupportedMediaError targetBreakpointWidth={allBreakpoints.sm} />
      ) : null}
      <SideBar
        tabs={sidebarTabsAvailable}
        onSignOutClick={onSignOutClick}
        name={props.profile?.name}
        version={appVersion ?? ""}
        hideFooter={isAccountNavBarEnabled}
      />
      <div>
        {isAccountNavBarEnabled ? <AccountNavBar /> : null}
        <ErrorBoundary
          fallbackRender={({ error }) => <PageErrorContent error={error} />}
          onError={(error, { componentStack }) => {
            ExceptionUtils.reportException(error, "fatal", {
              sourceComponent: componentStack,
              hint: "Captured by LayoutContent error boundary",
            });
          }}
        >
          {Object.entries(PageConfigs).flatMap(
            ([pageConfigKey, pageConfig], pageIndex) =>
              pageConfig?.routes.map((route, routeIndex) => {
                // IMPROVE: this renders the <Route /> twice with PageConfig
                // usage pattern. this is one smell within the larger legacy
                // routing system. this can't be performant. Must be
                // overhauled in the routing engine refactor to come.
                return (
                  <Route
                    key={`${pageIndex}-${routeIndex}`}
                    path={route.path}
                    exact={route.exact}
                    render={({ match }) => {
                      // evaluate feature flags and permissions ()
                      const isFeatureEnabled = isFeatureEnabledWaterfall({
                        featureFlagQuery: pageConfig.get("featureFlagsQuery"),
                        legacyFeatureFlagQuery: pageConfig.get(
                          "legacyFeatureFlagsQuery"
                        ),
                        legacyNotFeatureFlagQuery: pageConfig.get(
                          "legacyInverseFeatureFlagQuery"
                        ),
                        featureId: pageConfigKey,
                      });
                      const hasPermissions = hasPermissionsWaterfall({
                        permissionsQuery: pageConfig.get("permissionsQuery"),
                        legacyPermissionsQuery: pageConfig.get(
                          "legacyPermissionsQuery"
                        ),
                        shouldCheckPermissions: pageConfig.get(
                          "shouldCheckPermissions"
                        ),
                      });

                      // any feature flag override set on the route will be evaluated too
                      const isFeatureFlagOverrideSet = Boolean(
                        match.path === route.path && route.featureFlagsQuery
                      );
                      const featureFlagOverrideAccessResult =
                        isFeatureEnabledWaterfall({
                          featureFlagQuery: route.featureFlagsQuery,
                        });

                      // any permission override set on the route will be evaluated too
                      const isPermissionOverrideSet = Boolean(
                        match.path === route.path &&
                          (typeof route.shouldCheckPermissions !==
                            "undefined" ||
                            route.legacyPermissionsQuery ||
                            route.permissionsQuery)
                      );
                      const permissionOverrideAccessResult =
                        hasPermissionsWaterfall({
                          shouldCheckPermissions: isPermissionOverrideSet,
                          legacyPermissionsQuery: route.legacyPermissionsQuery,
                          permissionsQuery: route.permissionsQuery,
                        });

                      const isAvailable =
                        (isFeatureFlagOverrideSet
                          ? featureFlagOverrideAccessResult
                          : isFeatureEnabled) &&
                        (isPermissionOverrideSet
                          ? permissionOverrideAccessResult
                          : hasPermissions);

                      // if this route's associated navigation entry has
                      // been rendered or any override permissions also resulted in
                      // an allow, the route will render
                      if (isAvailable) {
                        return route.main({
                          pageConfig,
                          route,
                          havePermission: props.havePermission,
                          height: window.innerHeight,
                          breadcrumbs: route.breadcrumbs,
                          onContactSupportModalOpen: onContactSupportModalOpen,
                          ...props,
                        });
                      }

                      // in all other cases, deny access (principle of least privelege)
                      return <AccessDeniedLayout />;
                    }}
                    {...props}
                  />
                );
              })
          )}
        </ErrorBoundary>
      </div>

      {showContactUsModal && (
        <ContactSupportModal onHide={onContactSupportModalClose} />
      )}

      <AlertMessages />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutContent);
