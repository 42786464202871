import { RootRoute } from "#src/routes/root";
import { RoutePath } from "#src/utils/route";

export const ReportsRoutePath = RootRoute.concat(
  new RoutePath({
    title: "Reports",
    path: "/reports",
    isPresentational: true,
  })
);
