import { useNavigate, useSearchParams } from "#routers/hooks";
import useTableState, { SortingType } from "#src/hooks/useTableState";
import { linkToInsightsDetails } from "#src/routes/dashboard/insights/[insightsId]/details";
import {
  CardGrid,
  FilterPanel,
  TextInput,
  useToast,
} from "@validereinc/common-components";
import { Insights } from "@validereinc/domain";
import { dateFormatter, toFlattenedObject } from "@validereinc/utilities";
import React, { useCallback, useState } from "react";

const INITIAL_SORT: SortingType = {
  header: "name",
  direction: "asc",
};

type FilterType = Record<string, string | Record<string, string> | undefined>;

export const InsightsDashboardTab = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { toast } = useToast();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const onFetchData = useCallback(async (newSearchParams) => {
    const { sort, sortDirection, ...restFilters } = newSearchParams;
    const { name } = restFilters;

    try {
      const list = await Insights.getDashboards({
        name: name,
        sortBy: sort ?? INITIAL_SORT.header,
        sortDirection: sortDirection ?? INITIAL_SORT.direction,
      });

      return { data: { data: list } };
    } catch (error) {
      toast.push({
        intent: "error",
        description:
          "There was an error fetching your insights dashboard list. Please try again",
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  const onFiltersChange = (filters: FilterType) => {
    setSearchParams(toFlattenedObject(filters));
  };

  const onResetFilters = (filters: FilterType) => {
    setSearchParams(
      toFlattenedObject(filters, { transformObjectValue: (val) => val?.id })
    );
  };

  const onPrimaryCardClick = (id: string) => {
    navigate({ pathname: linkToInsightsDetails(id) });
  };

  const { tableProps } = useTableState({
    onFetchData,
    initialSort: INITIAL_SORT,
    pageSizeText: "cards per page",
  });

  // TODO: This should be removed once the backend is ready to handle the search bar
  const isSearchBarVisible = false;

  return (
    <>
      {isSearchBarVisible ? (
        <FilterPanel
          value={searchParams}
          onChange={onFiltersChange}
          onReset={onResetFilters}
          defaultActiveKeys={["Dashboards"]}
          filters={[
            {
              component: (
                <TextInput
                  key="name"
                  name="name"
                  placeholder="Search Dashboards..."
                  type="search"
                  isInline
                />
              ),
            },
          ]}
        />
      ) : null}

      <CardGrid
        {...tableProps}
        onPrimaryCardClick={onPrimaryCardClick}
        hasCta={false}
        isLoading={isLoading}
        getMeta={({ updatedAt }: { updatedAt?: Date }) => {
          if (updatedAt) {
            return `Last updated: ${dateFormatter(updatedAt)}`;
          }

          return "";
        }}
      />
    </>
  );
};
