import {
  getQueueUniqueId,
  ReImportDataActionStatusWithQueue,
} from "#batteries-included-components/Banners/ReImportDataActionStatus";
import {
  useGetOneTransactionDetail,
  useInvalidateTransaction,
  useOriginalSubmissionFileDownload,
} from "#hooks/adapters/useTransactions";
import { DataSetSubmissionDetailRoutePath } from "#routes/data-tools/submissions/datasets/[transactionId]/detail";
import {
  extractItemsFromTransactionDetails,
  getTransactionTitle,
  showErrorDetails,
  useDataSetDetail,
} from "#routes/data-tools/submissions/datasets/[transactionId]/detail/DataSetSubmissionDetail.helpers";
import { DataSetSubmissionDetailsPanel } from "#routes/data-tools/submissions/datasets/[transactionId]/detail/DataSetSubmissionDetailsPanel";
import { DataSetSubmissionRowTable } from "#routes/data-tools/submissions/datasets/[transactionId]/detail/DataSetSubmissionRowTable";
import { useActionDetails } from "#src/components/hooks/useActionDetails";
import { useQueueUnique } from "#src/hooks/useQueue";
import { DataSetSubmissionErrorPanel } from "#src/routes/data-tools/submissions/datasets/[transactionId]/detail/DataSetSubmissionErrorPanel";
import { ExceptionUtils } from "#src/utils/exception";
import { useBreadcrumbsFromRoute } from "#src/utils/route";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Column,
  Dialog,
  Page,
  Pill,
  Row,
  useToast,
} from "@validereinc/common-components";
import {
  Resources,
  TransactionAdapter,
  TransactionStatus,
  type TransactionType,
} from "@validereinc/domain";
import React, { useState } from "react";
import { useParams } from "react-router";
import {
  getStatusDisplayLabel,
  getTransactionStatusVariant,
} from "../../DataSetSubmissionsList.helpers";

export const DataSetSubmissionDetailPage = () => {
  const { toast } = useToast();
  const { transactionId } =
    useParams<
      Exclude<typeof DataSetSubmissionDetailRoutePath.pathParams, undefined>
    >();
  const { firstTransactionState, datasetQuery, finalTransactionState } =
    useDataSetDetail();
  const { invalidate } = useInvalidateTransaction();
  const queryClient = useQueryClient();
  const { data: transactionDetailData } = useGetOneTransactionDetail();
  const [isResubmitConfirmationOpen, setIsResubmitConfirmationOpen] =
    useState(false);
  const showErrorInstances = showErrorDetails(
    extractItemsFromTransactionDetails(transactionDetailData)
  );
  const title = getTransactionTitle(
    finalTransactionState,
    datasetQuery.data?.data
  );
  const submittedInfo = useActionDetails({
    type: "submit",
    date: firstTransactionState?.created_at,
    byUserId: firstTransactionState?.user_id,
  });
  const completeInfo = useActionDetails({
    type: "complete",
    date: finalTransactionState?.created_at,
  });
  const metaSegments = [
    submittedInfo.description,
    completeInfo.description,
  ].filter((s) => s !== undefined);
  const [breadcrumbs] = useBreadcrumbsFromRoute(
    DataSetSubmissionDetailRoutePath,
    {
      "/detail": {
        title,
      },
    }
  );
  const downloadOriginalSubmission = useOriginalSubmissionFileDownload();
  const [queue, setQueue] = useQueueUnique<TransactionType>(
    [],
    getQueueUniqueId(transactionId!),
    (tx) => tx.transaction_id
  );
  const retryTransaction = useMutation({
    mutationFn: (
      params: Parameters<typeof TransactionAdapter.retry.createOne>[0]
    ) => {
      return TransactionAdapter.retry.createOne(params);
    },
    onSuccess: (data) => {
      toast.push("Successfully started re-submission. Fetching details...");
      invalidate();
      queryClient
        .refetchQueries({ queryKey: [Resources.TRANSACTION] })
        .then(() => setQueue(queue.insert(data.data[0])));
    },
    onError: (err) => {
      ExceptionUtils.reportException(err, "error", {
        hint: "Failed to re-submit data in dataset submissions details page",
      });
      toast.push({
        description: "Failed to re-submit data.",
        intent: "error",
      });
    },
  });

  const actionRow = [
    showErrorInstances ||
    finalTransactionState?.status === TransactionStatus.FAILED ? (
      <Button
        key="resubmit"
        variant="outline"
        onClick={() => setIsResubmitConfirmationOpen(true)}
        isLoading={retryTransaction.isLoading}
        disabled={finalTransactionState?.status === TransactionStatus.PENDING}
      >
        Re-Submit
      </Button>
    ) : null,
    <Button
      key="download-original-submission"
      variant="outline"
      onClick={() =>
        downloadOriginalSubmission.mutate({
          transactionId: firstTransactionState?.transaction_id ?? "",
          fileName: firstTransactionState?.original_file_name ?? "",
        })
      }
      isLoading={downloadOriginalSubmission.isLoading}
    >
      Download Original Submission
    </Button>,
  ];

  return (
    <Page
      title={
        title ? (
          <>
            {title}&nbsp;
            <Pill
              variant={getTransactionStatusVariant(
                finalTransactionState?.status
              )}
              hasDot={false}
            >
              {getStatusDisplayLabel(finalTransactionState?.status)}
            </Pill>
          </>
        ) : (
          DataSetSubmissionDetailRoutePath.title
        )
      }
      category={DataSetSubmissionDetailRoutePath.previous?.title}
      actionRow={actionRow}
      breadcrumbs={breadcrumbs}
      renderMeta={
        metaSegments.length === 0
          ? undefined
          : ({ MetaSegments }) => <MetaSegments values={metaSegments} />
      }
    >
      {firstTransactionState?.transaction_id ? (
        <ReImportDataActionStatusWithQueue
          transactionId={firstTransactionState?.transaction_id}
        />
      ) : null}
      <DataSetSubmissionDetailsPanel />
      <Row>
        <Column variant={showErrorInstances ? 18 : 24}>
          <DataSetSubmissionRowTable />
        </Column>
        {showErrorInstances ? (
          <Column variant={6}>
            <DataSetSubmissionErrorPanel />
          </Column>
        ) : null}
      </Row>
      <Dialog
        isOpen={isResubmitConfirmationOpen}
        onClose={() => setIsResubmitConfirmationOpen(false)}
        title="Re-Submit Data"
        actionRow={[
          <Button
            key="resubmit"
            variant="primary"
            onClick={() => {
              if (!firstTransactionState?.transaction_id) return;

              retryTransaction.mutate({
                transactionId: firstTransactionState?.transaction_id,
              });
              setIsResubmitConfirmationOpen(false);
            }}
          >
            Re-Submit
          </Button>,
        ]}
      >
        Resubmitting a partial or failed dataset will create new rows where data
        failed in the previous submission and will not change successful rows.
      </Dialog>
    </Page>
  );
};
