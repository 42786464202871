import {
  AssetType,
  AssetTypeType,
  ReportWithDownloadV2Type,
} from "../../schemas";
import {
  FilterObjectType,
  GetListResponseType,
  PaginatedListRequestType,
  getFilterObject,
} from "../../util";
import {
  EstimationMethodConfiguration,
  EstimationMethodConfigurationDomainType,
} from "../EstimationMethodDomain/EstimationMethodConfiguration";
import {
  EstimationMethodDefaultRecordDomain,
  EstimationMethodDefaultRecordDomainType,
} from "../EstimationMethodDomain/EstimationMethodDefaultRecord";
import {
  EstimationMethodInputSourceDomain,
  EstimationMethodInputSourceDomainType,
} from "../EstimationMethodDomain/EstimationMethodInputSource";
import {
  EstimationMethodRun,
  EstimationMethodRunDomainType,
} from "../EstimationMethodDomain/EstimationMethodRun";
import { CreateReportingGroupType } from "../ReportingGroupDomain";
import { restAPI } from "../api";

export const EstimationMethodStatus = {
  ACTIVE: "active",
  INACTIVE: "inactive",
} as const;

export type EstimationMethodStatusType =
  (typeof EstimationMethodStatus)[keyof typeof EstimationMethodStatus];

export type EstimationMethodType = {
  id: string;
  company_id: string;
  status: EstimationMethodStatusType;
  entity_type: AssetTypeType;
  entity_id: string;
  analytics_library_id: string;
  analytics_calculator_id: string;
  name: string;
  created_at: string;
  updated_at: string;
  created_by: string;
  updated_by: string;
  default_values: Record<string, EstimationMethodInputType>;
  default_asset_values: Record<
    string,
    Record<string, EstimationMethodInputType>
  >;
  reporting_groups?: CreateReportingGroupType[];
};

export type EstimationMethodInputType = {
  value: number | string;
  unit: string;
  measurement_type?: string;
  measurement_quantity?: string;
};

export type GetListEstimationMethodFiltersType = {
  /** the entity ID in the back-end */
  entity_id?: string;
  /** the type of entity. default is "equipment" */
  entity_type: AssetTypeType;
};

export type GetOneEstimationMethodMetaType = {
  /** the entity type under which this estimation method exists. Default is "equipment" */
  entityType: AssetTypeType;
};

export type GetOneEstimationMethodQueryType = {
  /** the period under which this estimation method exists. */
  period?: string;
};

export type CreateEstimationMethodDataType = {
  /** the entity under which to create this method */
  entity_id: string;
  /** the ID of the library to associate to this method (and under which the calculator exists) */
  analytics_library_id: string;
  /** the ID of the calculator to associate to this method */
  analytics_calculator_id: string;
  /** the custom name given to this estimation method */
  name: string;
  /** the default values for calculator input parameters */
  default_values: Record<string, EstimationMethodInputType>;
  default_asset_values: Record<
    string,
    Record<string, EstimationMethodInputType>
  >;
};

export type CreateEstimationMethodMetaType = {
  /** the entity type under which this estimation method exists. Default is "equipment" */
  entityType: AssetTypeType;
};

export type UpdateEstimationMethodDataType = {
  /** the entity under which to create this method */
  entity_id: string;
  /** the custom name given to this estimation method */
  name: string;
  /** the default values for calculator input parameters */
  default_values: Record<string, EstimationMethodInputType>;
  default_asset_values: Record<
    string,
    Record<string, EstimationMethodInputType>
  >;
  status: EstimationMethodStatusType;
  start_date?: string;
  end_date?: string;
  delete_configurations_and_results?: boolean;
};

export type DeleteEstimationMethodMetaType = {
  /** the entity type under which this estimation method exists. Default is "equipment" */
  entityType: AssetTypeType;
};

const EstimationMethod: EstimationMethodDomainInternalType = {
  getList: ({
    filters,
    pageSize,
    sortBy,
    sortDirection,
    isExcelFile,
    period,
    ...rest
  }: PaginatedListRequestType<any> & {
    isExcelFile?: boolean;
    period?: string;
  }) => {
    return restAPI.nodeAPI.POST({
      endpoint: `/estimation_methods/search`,
      body: {
        page_size: pageSize,
        sort_by: sortBy,
        sort_direction: sortDirection,
        ...rest,
        filter: {
          ...getFilterObject(filters),
        },
      },
      ...(period ? { query: { period } } : {}),
      ...(isExcelFile
        ? {
            headers: {
              Accept:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            },
            responseType: "arraybuffer",
          }
        : {}),
    });
  },
  getOne: ({
    id,
    data,
    meta = {
      entityType: AssetType.EQUIPMENT,
    },
  }) =>
    restAPI.nodeAPI.GET({
      endpoint: `/estimation_methods/${meta.entityType}/${id}`,
      ...(data
        ? {
            query: data,
          }
        : {}),
    }),
  create: ({
    data,
    meta = {
      entityType: AssetType.EQUIPMENT,
      body: data,
    },
  }) =>
    restAPI.nodeAPI.POST({
      endpoint: `/estimation_methods/${meta.entityType}`,
      body: data,
    }),
  update: ({
    id,
    data,
    meta = {
      entityType: AssetType.EQUIPMENT,
    },
  }) =>
    restAPI.nodeAPI.PUT({
      endpoint: `/estimation_methods/${meta.entityType}/${id}`,
      body: data,
    }),
  delete: ({
    id,
    meta = {
      entityType: AssetType.EQUIPMENT,
    },
  }) =>
    restAPI.nodeAPI.DELETE({
      endpoint: `/estimation_methods/${meta.entityType}/${id}`,
    }),
  exportList: ({ filters, sortBy, sortDirection }) => {
    return restAPI.nodeAPI.POST<ReportWithDownloadV2Type>({
      endpoint: "/estimation_methods/export",
      body: {
        sort_by: sortBy,
        sort_direction: sortDirection,
        filter: getFilterObject(filters),
      },
    });
  },
};

/**
 * API methods related to Estimation Methods
 */
export const EstimationMethodDomain: EstimationMethodDomainType = {
  ...EstimationMethod,
  configuration: EstimationMethodConfiguration,
  run: EstimationMethodRun,
  inputSource: EstimationMethodInputSourceDomain,
  defaultRecords: EstimationMethodDefaultRecordDomain,
};

interface EstimationMethodDomainInternalType {
  /**
   * Get a list of estimation methods based on filters
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/estimation_methods/search_estimation_methods
   * @returns the standard wrapped API response of this endpoint
   */
  getList: (
    params: PaginatedListRequestType<
      FilterObjectType<GetListEstimationMethodFiltersType>
    >
  ) => Promise<GetListResponseType<EstimationMethodType>>;
  /**
   * Get an estimation method under an entity
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/estimation_methods/get_estimation_method
   * @returns the standard wrapped API response of this endpoint
   */
  getOne: (params: {
    id: string;
    data?: GetOneEstimationMethodQueryType;
    meta?: GetOneEstimationMethodMetaType;
  }) => Promise<EstimationMethodType>;
  /**
   * Create an estimation method under an entity
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/estimation_methods/add_equipment_estimation_method
   * @returns the standard wrapped API response of this endpoint
   */
  create: (params: {
    data: CreateEstimationMethodDataType;
    meta?: CreateEstimationMethodMetaType;
  }) => Promise<EstimationMethodType>;
  /**
   * Update an estimation method under an entity
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/estimation_methods/delete_estimation_method
   * @returns the standard wrapped API response of this endpoint
   */
  update: (params: {
    id: string;
    data: Partial<UpdateEstimationMethodDataType>;
    meta?: GetOneEstimationMethodMetaType;
  }) => Promise<EstimationMethodType>;
  /**
   * Delete an estimation method under an entity
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/estimation_methods/delete_estimation_method
   * @returns the standard wrapped API response of this endpoint
   */
  delete: (params: {
    id: string;
    meta?: DeleteEstimationMethodMetaType;
  }) => Promise<void>;
  isExcelFile?: boolean;
  /**
   * Get a exported list of estimation methods based on filters
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/estimation_methods/export_estimation_methods
   */
  exportList: (
    params: PaginatedListRequestType<
      FilterObjectType<GetListEstimationMethodFiltersType>
    >
  ) => Promise<ReportWithDownloadV2Type>;
}

export interface EstimationMethodDomainType
  extends EstimationMethodDomainInternalType {
  configuration: EstimationMethodConfigurationDomainType;
  run: EstimationMethodRunDomainType;
  inputSource: EstimationMethodInputSourceDomainType;
  defaultRecords: EstimationMethodDefaultRecordDomainType;
}
