import {
  UserPermissionsTab,
  UserRolesTab,
  UserSummaryTab,
} from "#batteries-included-components/Tabs";
import { useNavigate, useParams } from "#routers/hooks";
import { useBreadcrumbs } from "#src/Routers/breadcrumbsHelper";
import { useSearchParams } from "#src/Routers/hooks";
import { AccessDeniedLayout } from "#src/batteries-included-components/Layouts/Authorization/AccessDenied";
import { useCreateEditMetaSegments } from "#src/components/hooks/useCreateEditMetaSegments";
import {
  useAuthenticatedContext,
  useHasPermission,
  useIsFeatureAvailable,
  useIsOpsHubExperience,
} from "#src/contexts/AuthenticatedContext.helpers";
import { USERS_LIST_BREADCRUMB } from "#src/routes/settings/users";
import { linkToUserEditPage } from "#src/routes/settings/users/edit";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Dialog,
  DropdownMenu,
  Icon,
  Page,
  Pill,
  Tab,
  useToast,
  type DropdownMenuProps,
} from "@validereinc/common-components";
import { UserType, UsersAdapter } from "@validereinc/domain";
import { copyTextToClipboard } from "@validereinc/utilities";
import React, { useContext, useState } from "react";
import { USER_DETAILS_BREADCRUMB } from ".";
import { SETTINGS_BREADCRUMB } from "../..";
import { UserDetailContext, UserDetailProvider } from "./UserDetailContext";

const DEFAULT_TAB_KEY = "profile";

const UserDetailPageContent = () => {
  const {
    claims: {
      user: { id: currentUserId },
    },
  } = useAuthenticatedContext();
  const [hasWritePermission] = useHasPermission("users:write");
  const { userDetails } = useContext(UserDetailContext) ?? {};
  const { toast } = useToast();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const queryClient = useQueryClient();
  const updateUserStatusMutation = useMutation({
    mutationFn: ({
      id,
      status,
    }: Pick<UserType, "status" | "id"> & { previousData: UserType }) => {
      return UsersAdapter.updateOne({
        id: id,
        data: {
          status: status,
        },
      });
    },
    onSuccess: (updatedUser) => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
      toast.push({
        intent: "success",
        description:
          updatedUser.data.status === "disabled"
            ? `Successfully disabled account for ${
                updatedUser.data.name ?? "User"
              }.`
            : `Successfully enabled account for ${
                updatedUser.data.name ?? "User"
              }`,
      });
    },
    onError: (_, mutationData) => {
      toast.push({
        intent: "error",
        description:
          mutationData.status === "disabled"
            ? `Failed to disable account for ${
                mutationData.previousData.name ?? "User"
              }.`
            : `Failed to enable account for ${
                mutationData.previousData.name ?? "User"
              }`,
      });
    },
    onMutate: () => {
      setIsModalOpen(false);
    },
  });

  const isThisUserCurrentUser = currentUserId === userDetails?.data?.id;

  const handleActiveTabKeyChange = (tabKey) => {
    setSearchParams({ tab: tabKey });
  };

  const handleOnEdit = () => {
    if (!userDetails?.data?.id) {
      return;
    }

    navigate({ pathname: linkToUserEditPage(userDetails.data?.id) });
  };

  const createEditMetaSegments = useCreateEditMetaSegments({
    editedAt: userDetails?.data?.updated_at,
    editedBy: userDetails?.data?.updated_by,
    createdAt: userDetails?.data?.created_at,
    createdBy: userDetails?.data?.created_by,
  });

  const breadcrumbs = useBreadcrumbs(
    [SETTINGS_BREADCRUMB, USERS_LIST_BREADCRUMB, USER_DETAILS_BREADCRUMB],
    {
      2: userDetails?.data?.name,
    }
  );

  const getExtraPageActions = (
    userDetails?: UserType,
    isThisUserCurrentUser = false
  ): DropdownMenuProps["options"] => {
    return [
      {
        label: userDetails?.status !== "disabled" ? "Deactivate" : "Reactivate",
        slotLeft: ({ iconProps }) => (
          <Icon
            {...iconProps}
            variant="power"
          />
        ),
        isDisabled: !hasWritePermission && !isThisUserCurrentUser,
        onClick: () => setIsModalOpen(true),
        variant: userDetails?.status !== "disabled" ? "danger" : "warning",
      },
    ];
  };

  return (
    <>
      <Page
        title={
          userDetails?.data ? (
            <>
              {`${userDetails?.data?.name}${
                isThisUserCurrentUser ? " (You)" : ""
              }`}
              &nbsp;
              <Pill
                variant={
                  userDetails.data.status === "active" ? "success" : "default"
                }
                hasDot={false}
              >
                {userDetails.data.status}
              </Pill>
            </>
          ) : (
            "Role Details"
          )
        }
        category="User"
        breadcrumbs={breadcrumbs}
        activeTabKey={searchParams?.tab ?? DEFAULT_TAB_KEY}
        onActiveTabKeyChange={handleActiveTabKeyChange}
        isLoading={userDetails?.isLoading}
        actionRow={[
          <Button
            key="edit"
            variant="outline"
            icon="pencil-simple"
            disabled={!hasWritePermission && !isThisUserCurrentUser}
            onClick={() => handleOnEdit()}
          >
            Edit
          </Button>,
          <Button
            key="edit"
            variant="outline"
            icon="copy-simple"
            disabled={!userDetails?.data?.id}
            onClick={() => {
              if (!userDetails?.data?.id) return;

              copyTextToClipboard(userDetails.data.id).then(() =>
                toast.push({
                  intent: "success",
                  description: "Successfully copied user ID to your clipboard.",
                })
              );
            }}
          >
            Copy ID
          </Button>,
          <DropdownMenu
            key="additional"
            options={getExtraPageActions(
              userDetails?.data,
              isThisUserCurrentUser
            )}
          >
            <Button
              variant="outline"
              icon="dots-three-vertical"
            />
          </DropdownMenu>,
        ]}
        renderMeta={
          createEditMetaSegments.length === 0
            ? undefined
            : ({ MetaSegments }) => (
                <MetaSegments values={createEditMetaSegments} />
              )
        }
      >
        <Tab
          tabKey="profile"
          title="Profile"
        >
          <UserSummaryTab />
        </Tab>
        <Tab
          tabKey="roles"
          title="Roles"
        >
          <UserRolesTab />
        </Tab>
        <Tab
          tabKey="permissions"
          title="Permissions"
        >
          <UserPermissionsTab />
        </Tab>
      </Page>

      {/* IMPROVE: can be a BIC */}
      <Dialog
        key="deactivate-user"
        title={
          currentUserId === userDetails?.data?.id
            ? `Deactivate your account?`
            : `Deactivate account for ${userDetails?.data?.name}?`
        }
        isOpen={isModalOpen && userDetails?.data?.status === "active"}
        onClose={() => setIsModalOpen(false)}
        actionRow={[
          <Button
            key="deactivate-dialog-action"
            variant="error"
            onClick={() => {
              if (!userDetails?.data) {
                return;
              }

              updateUserStatusMutation.mutate({
                id: userDetails.data.id,
                status: "disabled",
                previousData: userDetails.data,
              });
            }}
            disabled={Boolean(
              !userDetails?.data || updateUserStatusMutation.isLoading
            )}
          >
            Deactivate
          </Button>,
        ]}
      >
        {currentUserId === userDetails?.data?.id
          ? "Are you sure you want to deactivate your account? You will lose access to the app entirely. Only a system administrator can enable your account if you'd like access again."
          : "Are you sure you want to disable this user? Disabling this user will remove any level of access that has been assigned to them."}
      </Dialog>

      {/* IMPROVE: can be a BIC */}
      <Dialog
        key="enable-user"
        title={`Reactivate account for ${userDetails?.data?.name}?`}
        isOpen={isModalOpen && userDetails?.data?.status === "disabled"}
        onClose={() => setIsModalOpen(false)}
        actionRow={[
          <Button
            key="enable-dialog-action"
            variant="primary"
            onClick={() => {
              if (!userDetails?.data) {
                return;
              }

              updateUserStatusMutation.mutate({
                id: userDetails.data.id,
                status: "active",
                previousData: userDetails.data,
              });
            }}
            disabled={Boolean(
              !userDetails?.data || updateUserStatusMutation.isLoading
            )}
          >
            Reactivate
          </Button>,
        ]}
      >
        Are you sure you want to reactivate this user? Activating this user will
        enable any level of access that has been assigned.
      </Dialog>
    </>
  );
};

export const UserDetailPage = () => {
  const { userId } = useParams<{ userId: string }>();
  const {
    claims: {
      user: { id: currentUserId },
    },
  } = useAuthenticatedContext();
  const [isPageAvailable] = useIsFeatureAvailable({
    featureFlagQuery: "core:roles_permissions",
  });
  const [isOpsExperienceEnabled] = useIsOpsHubExperience();

  const isThisUserCurrentUser = currentUserId === userId;

  if (!isThisUserCurrentUser && (!isPageAvailable || isOpsExperienceEnabled)) {
    return <AccessDeniedLayout />;
  }

  return (
    <UserDetailProvider userId={userId}>
      <UserDetailPageContent />
    </UserDetailProvider>
  );
};
