import { linkToDataMappingUploadDetail } from "#routers/links";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import config from "#src/config";
import { getTimeStringFromDate } from "#utils/timeFormatter";
import {
  DataTable,
  HeaderType,
  Panel,
  Pill,
} from "@validereinc/common-components";
import * as PropTypes from "prop-types";
import React from "react";

const STATUS_MAPPING = {
  APPLY_COMPLETED: {
    type: "success",
    value: "Uploaded",
  },
};

const UploadHistoryPanel = ({ tableProps, dataMappingId }) => {
  const headers: Array<HeaderType<any>> = [
    {
      label: "Upload Date",
      key: "date_created",
      isSortable: true,
      renderComponent: ({ item }) => (
        <RoutingLink
          to={linkToDataMappingUploadDetail(dataMappingId, item?.task_id)}
        >
          {getTimeStringFromDate(item.date_created, config.DATEMONTH_FORMAT)}
        </RoutingLink>
      ),
    },
    {
      label: "Uploaded By",
      key: "uploaded_by",
      isSortable: true,
    },
    {
      label: "File",
      key: "file_name",
      isSortable: true,
    },
    {
      label: "Row Skipped",
      key: "rows_skipped",
      isSortable: true,
    },
    {
      label: "Rows Updated",
      key: "rows_created",
      isSortable: true,
    },
    {
      label: "Status",
      key: "task_status",
      renderComponent: ({ item }) => (
        <Pill variant={STATUS_MAPPING[item.task_status]?.type ?? "info"}>
          {STATUS_MAPPING[item.task_status]?.value ?? "Uploading"}
        </Pill>
      ),
    },
  ];

  return (
    <Panel title="Upload History">
      <DataTable
        {...tableProps}
        headers={headers}
      />
    </Panel>
  );
};

UploadHistoryPanel.propTypes = {
  tableProps: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  dataMappingId: PropTypes.string.isRequired,
};

export default UploadHistoryPanel;
