import { useAuthenticatedContext } from "#src/contexts/AuthenticatedContext.helpers";
import { Button, ButtonProps, Tooltip } from "@validereinc/common-components";
import type { AuthQueryType } from "@validereinc/domain-controllers";
import React from "react";

type HasPermissionButtonProps = {
  children: React.ReactNode;
  permissions: AuthQueryType;
} & ButtonProps;

export const HasPermissionButton = ({
  permissions,
  children,
  ...props
}: HasPermissionButtonProps) => {
  const {
    v2: {
      userInfo: { checkHasPermissions },
    },
  } = useAuthenticatedContext();
  const tooltipContent =
    typeof children === "string"
      ? `You do not have permission to ${children}`
      : "You do not have the right permissions";

  if (checkHasPermissions(permissions)) {
    return <Button {...props}>{children}</Button>;
  }

  return (
    <Tooltip
      key="button-tooltip"
      content={tooltipContent}
    >
      <Button
        {...props}
        disabled
        icon="lock"
      >
        {children}
      </Button>
    </Tooltip>
  );
};
