import { TemplatedReportsCategoriesListRoutePath } from "#src/routes/reports/templated-reports/categories";
import { RoutePath } from "#src/utils/route";

export const TemplatedReportsCategoriesDetailRoutePath =
  TemplatedReportsCategoriesListRoutePath.concat(
    new RoutePath<"categoryId">({
      path: "/:categoryId",
      title: "Category Detail",
    })
  );
