import type { DeepPick } from "ts-deep-pick";
import {
  ReportWithDownloadV2Type,
  TemplatedReportStatusType,
  type CreateReportV3Type,
  type ReportEmbeddedRowType,
  type ReportEmbeddedSheetType,
  type ReportInputValuesV3Type,
  type ReportV3Type,
  type ReportVersionV3Type,
  type ReportWithVersionsV3Type,
  type ReportWithVersionV3Type,
  type TemplatedReportType,
} from "../schemas";
import {
  fetchAndCollate,
  getFilterObject,
  GetListRequestType,
  GetListResponseType,
  ResourceServiceType,
  type CreateOneRequestType,
  type CreateOneResponseType,
  type GetOneRequestType,
  type GetOneResponseType,
} from "../util";
import { restAPI } from "./api";

interface ReportV2AdapterType
  extends Pick<
    ResourceServiceType<ReportWithDownloadV2Type>,
    "getOne" | "getList"
  > {
  getList: (
    params: GetListRequestType<
      Pick<
        ReportWithDownloadV2Type,
        | "id"
        | "name"
        | "templated_report_name"
        | "status"
        | "created_at"
        | "updated_at"
        | "updated_by"
        | "created_by"
      > & {
        "templated_report.display_name": string;
        "templated_report.status": TemplatedReportStatusType;
      }
    >
  ) => Promise<GetListResponseType<ReportWithDownloadV2Type>>;
}

export const ReportV2Adapter: ReportV2AdapterType = {
  /**
   * Get a report object with download link
   * @see {@link https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/reports/get_report_v2}
   * @returns a report object
   */
  getOne: ({ id }) =>
    restAPI.nodeAPI
      .GET<ReportWithDownloadV2Type>({
        endpoint: `/reports/${id}`,
        version: 2,
      })
      .then((resp) => ({
        data: resp,
      })),
  getList: ({ page, pageSize, sortBy, sortDirection, filters }) =>
    fetchAndCollate(
      ({ page, pageSize }) =>
        restAPI.nodeAPI.POST({
          version: 2,
          endpoint: "/reports/search",
          body: {
            page,
            page_size: pageSize,
            sort_by: sortBy,
            sort_direction: sortDirection,
            filter: getFilterObject(filters),
          },
        }),
      page,
      pageSize
    ),
};

interface ReportV3AdapterType
  extends Pick<
    ResourceServiceType<ReportV3Type>,
    "getOne" | "getList" | "createOne" | "updateOne" | "deleteOne"
  > {
  getList: (
    params: GetListRequestType<
      Pick<
        ReportV3Type,
        | "id"
        | "name"
        | "templated_report_name"
        | "status"
        | "input"
        | "created_at"
        | "updated_at"
        | "created_by"
        | "updated_by"
      > & {
        "templated_report.name": TemplatedReportType["name"];
        "templated_report.display_name": TemplatedReportType["display_name"];
        "templated_report.status": TemplatedReportType["status"];
        "templated_report.created_at": TemplatedReportType["created_at"];
        "templated_report.updated_at": TemplatedReportType["updated_at"];
        "latest_version.status": DeepPick<
          ReportV3Type,
          "latest_version.status"
        >["latest_version"]["status"];
        "latest_version.created_at": DeepPick<
          ReportV3Type,
          "latest_version.created_at"
        >["latest_version"]["created_at"];
        "latest_version.created_by": DeepPick<
          ReportV3Type,
          "latest_version.created_by"
        >["latest_version"]["created_by"];
      }
    >
  ) => Promise<GetListResponseType<ReportV3Type>>;
  createOne: (
    params: CreateOneRequestType<
      CreateReportV3Type,
      {
        enableNewVersion?: boolean;
      }
    >
  ) => Promise<CreateOneRequestType<ReportV3Type>>;
  inputs: {
    getMany: (params: {
      "templated_report.name": ReportV3Type["templated_report_name"];
    }) => Promise<GetOneResponseType<ReportInputValuesV3Type>>;
  };
  versions: {
    createOne: (
      params: CreateOneRequestType<Pick<ReportV3Type, "id">>
    ) => Promise<CreateOneResponseType<ReportV3Type>>;
    getList: (
      params: GetOneRequestType
    ) => Promise<GetOneResponseType<ReportWithVersionsV3Type>>;
    getOne: (
      params: GetOneRequestType<Pick<ReportVersionV3Type, "version">>
    ) => Promise<GetOneResponseType<ReportWithVersionV3Type>>;
  };
  embedded: {
    rows: {
      getList: (
        params: GetListRequestType<
          {
            report_version: number;
            sheet_name: string;
          },
          {
            report_id: string;
          }
        >
      ) => Promise<GetListResponseType<ReportEmbeddedRowType>>;
    };
    sheets: {
      getList: (
        params: GetListRequestType<
          never,
          { report_version: number; report_id: string }
        >
      ) => Promise<GetListResponseType<ReportEmbeddedSheetType>>;
    };
  };
}

export const ReportV3Adapter: ReportV3AdapterType = {
  /**
   * search reports with the latest version
   * @see {@link https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/reports/search_reports_v3}
   */
  getList: ({ page, pageSize, sortBy, sortDirection, filters }) =>
    fetchAndCollate(
      ({ page, pageSize }) =>
        restAPI.nodeAPI.POST({
          version: 3,
          endpoint: "/reports/search",
          body: {
            page,
            page_size: pageSize,
            sort_by: sortBy,
            sort_direction: sortDirection,
            // IMPROVE: the filter function will be reworked soon
            // @ts-expect-error the function is badly typed
            filter: getFilterObject(filters),
          },
        }),
      page,
      pageSize
    ),
  /**
   * get a single report with the latest version
   * @see {@link https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/reports/get_report_with_latest_version_v3}
   */
  getOne: ({ id }) =>
    restAPI.nodeAPI
      .GET<ReportV3Type>({
        version: 3,
        endpoint: `/reports/${id}`,
      })
      .then((resp) => ({ data: resp })),
  /**
   * generate a report
   * @see {@link https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/reports/trigger_report_v3}
   */
  createOne: ({ data, meta }) =>
    restAPI.nodeAPI
      .POST<ReportV3Type>({
        version: 3,
        endpoint: "/reports",
        body: {
          ...data,
          ...(meta?.enableNewVersion !== undefined
            ? { enable_new_version: meta.enableNewVersion }
            : {}),
        },
      })
      .then((resp) => ({ data: resp })),
  /**
   * update the status of a report
   * @see {@link https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/reports/update_report}
   */
  updateOne: ({ id, data }) =>
    restAPI.nodeAPI
      .PUT<ReportV3Type>({
        version: 3,
        endpoint: `/reports/${id}`,
        body: {
          status: data.status,
        },
      })
      .then((resp) => ({ data: resp })),
  /**
   * delete a report
   * @see {@link https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/reports/delete_report}
   */
  deleteOne: ({ id }) =>
    restAPI.nodeAPI.DELETE({
      version: 3,
      endpoint: `/reports/${id}`,
    }),
  inputs: {
    /**
     * get all the values across all generated reports for all the inputs of a particular report
     * @see {@link https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/reports/get_existing_report_inputs}
     */
    getMany: (params) =>
      restAPI.nodeAPI
        .GET<ReportInputValuesV3Type>({
          version: 3,
          endpoint: "/reports/inputs",
          query: {
            templated_report_name: params["templated_report.name"],
          },
        })
        .then((resp) => ({ data: resp })),
  },
  versions: {
    /**
     * create a new version of a report
     * @see {@link https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/reports/trigger_report_next_version_v3}
     */
    createOne: ({ data }) =>
      restAPI.nodeAPI
        .POST<ReportV3Type>({
          version: 3,
          endpoint: `/reports/${data.id}/versions`,
        })
        .then((resp) => ({ data: resp })),
    /**
     * get all versions of a report
     * @see {@link https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/reports/get_report_versions_v3}
     */
    getList: ({ id }) =>
      restAPI.nodeAPI
        .GET<ReportWithVersionsV3Type>({
          version: 3,
          endpoint: `/reports/${id}/versions`,
        })
        .then((resp) => ({
          data: resp,
        })),
    /**
     * get a specific version of a report
     * @see {@link https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/reports/get_report_version_v3}
     */
    getOne: ({ id, meta }) =>
      restAPI.nodeAPI
        .GET<ReportWithVersionV3Type>({
          version: 3,
          endpoint: `/reports/${id}/versions/${meta?.version}`,
        })
        .then((resp) => ({ data: resp })),
  },
  embedded: {
    rows: {
      /**
       * search and list embedded report rows
       * @see {@link https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/reports/search_embedded_report_rows}
       */
      getList: ({ page, pageSize, sortBy, sortDirection, filters, meta }) =>
        fetchAndCollate(
          ({ page, pageSize }) =>
            restAPI.nodeAPI.POST<GetListResponseType<ReportEmbeddedRowType>>({
              version: 3,
              endpoint: `/reports/${meta?.report_id}/embedded/search`,
              body: {
                page,
                page_size: pageSize,
                sort_by: sortBy,
                sort_direction: sortDirection,
                filter: filters,
              },
            }),
          page,
          pageSize
        ),
    },
    sheets: {
      /**
       * get and list embedded report sheets
       * @see {@link https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/reports/get_embedded_report_sheets}
       */
      getList: ({ meta }) =>
        restAPI.nodeAPI.GET({
          version: 3,
          endpoint: `/reports/${meta?.report_id}/versions/${meta?.report_version}/embedded/sheets`,
        }),
    },
  },
};
