import { useListFlowTypes } from "#hooks/adapters/useFlows";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import {
  useClientSideSortingAndPagination,
  useTableSortingAndPagination,
} from "#src/components/Redux/reducers/tableStateReducer";
import { DataTable } from "@validereinc/common-components";
import type { FlowType } from "@validereinc/domain";
import React, { useMemo } from "react";
import { FlowDetailRoute } from "../.";

export const FlowFlowsTable = ({
  variant,
  flow,
  isLoading,
}: {
  variant: "upstream" | "downstream";
  flow?: FlowType;
  isLoading: boolean;
}) => {
  const flowData = useMemo(() => flow?.[`${variant}_flows`], [flow]);
  const { data: flowTypes } = useListFlowTypes();
  const [tableState, updateTableState] = useTableSortingAndPagination();
  const { items, pagination, sorting } = useClientSideSortingAndPagination(
    flowData ?? [],
    tableState
  );

  return (
    <DataTable
      isLoading={isLoading}
      items={items}
      headers={[
        {
          label: "Name",
          key: "name",
          renderComponent: ({ item: { id, name } }: { item: FlowType }) => (
            <RoutingLink
              to={FlowDetailRoute.toLink({ pathParams: { flowId: id } })}
            >
              {name}
            </RoutingLink>
          ),
        },
        {
          label: "Type",
          key: "type",
          renderComponent: ({ item: { type } }: { item: FlowType }) =>
            flowTypes?.find(({ id }) => id === type)?.name ?? "-",
        },
      ]}
      pagination={{
        ...pagination,
        showPageSizeControls: false,
      }}
      sorting={sorting}
      onPaginationChange={updateTableState}
      onSortChange={updateTableState}
    />
  );
};
