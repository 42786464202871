import { RoutingLink } from "#batteries-included-components/RoutingLink";
import {
  FlowStatusPillVariants,
  useListFlowProductCategories,
  useListFlowProductTypes,
  useListFlowTypes,
} from "#hooks/adapters/useFlows";
import { linkToEquipmentDetail } from "#routes/organization/equipment/[equipmentId]";
import { linkToFacilityDetail } from "#routes/organization/facilities/[facilityId]";
import { FlowDetailRoute } from "#routes/organization/flows/[flowId]/detail";
import useLocalization from "#src/hooks/useLocalization";
import { Pill } from "@validereinc/common-components";
import { FlowStatusType, FlowType } from "@validereinc/domain";
import { toStartCaseString } from "@validereinc/utilities";
import get from "lodash/get";
import React from "react";

export const FLOW_ATTRIBUTES = {
  ID: {
    key: "id",
    label: "ID",
  },
  NAME: {
    key: "name",
    label: "Flow",
  },
  TYPE: {
    key: "type",
    label: "Type",
  },
  STATUS: {
    key: "status",
    label: "Status",
  },
  FACILITY: {
    key: "associated_facility_id",
    label: "Facility",
  },
  FACILITY_NAME: {
    key: "associated_facility.name",
    label: "Facility",
  },
  EQUIPMENT: {
    key: "associated_equipment_id",
    label: "Equipment",
  },
  EQUIPMENT_NAME: {
    key: "associated_equipment.name",
    label: "Equipment",
  },
  PRODUCT_CATEGORY: {
    key: "product_category",
    label: "Product Category",
  },
  PRODUCT_TYPE: {
    key: "product_type",
    label: "Product Type",
  },
};

export const useFlowHeaders = <ItemType = FlowType,>({
  prefix,
  keySubstitutions,
  sortSubstitutions,
}: {
  prefix?: string;
  keySubstitutions?: Record<string, string>;
  sortSubstitutions?: Record<string, string>;
} = {}) => {
  const { localize } = useLocalization();

  const withSortSubstitution = (key: string) =>
    sortSubstitutions?.[key] ?? (prefix ? `${prefix}.${key}` : key);
  const withKeySubstitution = (key: string) =>
    keySubstitutions?.[key] ?? (prefix ? `${prefix}.${key}` : key);

  const { data: flowTypes } = useListFlowTypes();
  const { data: productCategories } = useListFlowProductCategories();
  const { data: productTypes } = useListFlowProductTypes();

  return [
    {
      key: withSortSubstitution(FLOW_ATTRIBUTES.NAME.key),
      label: localize(FLOW_ATTRIBUTES.NAME.label),
      isSortable: true,
      renderComponent: ({ item }: { item: ItemType }) => {
        const id = get(item, withKeySubstitution(FLOW_ATTRIBUTES.ID.key));
        const name = get(item, withKeySubstitution(FLOW_ATTRIBUTES.NAME.key));

        return name && id ? (
          <RoutingLink
            to={FlowDetailRoute.toLink({ pathParams: { flowId: id } })}
          >
            {name}
          </RoutingLink>
        ) : (
          "-"
        );
      },
    },
    {
      key: withSortSubstitution(FLOW_ATTRIBUTES.TYPE.key),
      label: FLOW_ATTRIBUTES.TYPE.label,
      isSortable: true,
      renderComponent: ({ item }: { item: ItemType }) => {
        const type = get(item, withKeySubstitution(FLOW_ATTRIBUTES.TYPE.key));
        return flowTypes?.find(({ id }) => id === type)?.name ?? "-";
      },
    },
    {
      key: withSortSubstitution(FLOW_ATTRIBUTES.FACILITY.key),
      label: localize(FLOW_ATTRIBUTES.FACILITY.label),
      isSortable: true,
      renderComponent: ({ item }: { item: ItemType }) => {
        const facilityId = get(
          item,
          withKeySubstitution(FLOW_ATTRIBUTES.FACILITY.key)
        );
        const facilityName = get(
          item,
          withKeySubstitution(FLOW_ATTRIBUTES.FACILITY_NAME.key)
        );
        return facilityId && facilityName ? (
          <RoutingLink to={linkToFacilityDetail(facilityId)}>
            {facilityName}
          </RoutingLink>
        ) : (
          "-"
        );
      },
    },
    {
      key: withSortSubstitution(FLOW_ATTRIBUTES.EQUIPMENT.key),
      label: localize(FLOW_ATTRIBUTES.EQUIPMENT.label),
      isSortable: true,
      renderComponent: ({ item }: { item: ItemType }) => {
        const equipmentId = get(
          item,
          withKeySubstitution(FLOW_ATTRIBUTES.EQUIPMENT.key)
        );
        const equipmentName = get(
          item,
          withKeySubstitution(FLOW_ATTRIBUTES.EQUIPMENT_NAME.key)
        );
        return equipmentId && equipmentName ? (
          <RoutingLink to={linkToEquipmentDetail(equipmentId)}>
            {equipmentName}
          </RoutingLink>
        ) : (
          "-"
        );
      },
    },
    {
      key: withSortSubstitution(FLOW_ATTRIBUTES.STATUS.key),
      label: FLOW_ATTRIBUTES.STATUS.label,
      isSortable: true,
      renderComponent: ({ item }: { item: ItemType }) => {
        const status = get(
          item,
          withKeySubstitution(FLOW_ATTRIBUTES.STATUS.key)
        ) as FlowStatusType;
        return (
          <Pill variant={FlowStatusPillVariants[status]}>
            {toStartCaseString(status)}
          </Pill>
        );
      },
    },
    {
      key: withSortSubstitution(FLOW_ATTRIBUTES.PRODUCT_CATEGORY.key),
      label: FLOW_ATTRIBUTES.PRODUCT_CATEGORY.label,
      isSortable: true,
      renderComponent: ({ item }: { item: ItemType }) => {
        const productCategory = get(
          item,
          withKeySubstitution(FLOW_ATTRIBUTES.PRODUCT_CATEGORY.key)
        );
        return (
          productCategories?.find(({ id }) => id === productCategory)?.name ??
          "-"
        );
      },
    },
    {
      key: withSortSubstitution(FLOW_ATTRIBUTES.PRODUCT_TYPE.key),
      label: FLOW_ATTRIBUTES.PRODUCT_TYPE.label,
      isSortable: true,
      renderComponent: ({ item }: { item: ItemType }) => {
        const productType = get(
          item,
          withKeySubstitution(FLOW_ATTRIBUTES.PRODUCT_TYPE.key)
        );
        return productTypes?.find(({ id }) => id === productType)?.name ?? "-";
      },
    },
  ];
};
