import { TemplatedReportsTemplateDetailRoutePath } from "#src/routes/reports/templated-reports/template/[templateName]";
import { RoutePath } from "#src/utils/route";

export const TemplatedReportsGeneratedReportsRoutePath =
  TemplatedReportsTemplateDetailRoutePath.concat(
    new RoutePath({
      title: "Reports",
      path: "/generated",
      isPresentational: true,
    })
  );
