import { formatNumber } from "@validereinc/utilities";

export function getProductionReportTitle(productionReport) {
  return productionReport?.name ?? "Report Details";
}

export function getPropertyValue(property) {
  if (!property) {
    return `-`;
  }

  if (property.unit) {
    const formattedNumber = formatNumber(property.value);

    return formattedNumber !== null
      ? `${formattedNumber} ${property.unit}`
      : "-";
  }

  return property.value;
}

export function getComponentsHeaders(
  productionReportDetailSummary,
  cellRenderer
) {
  const components = Object.keys(
    productionReportDetailSummary?.components?.differences ?? {}
  );

  return components.map((component) => {
    return {
      label: component,
      key: component,
      width: 150,
      cellRenderer,
      rightAlign: true,
    };
  });
}
