import { getPropertyAsMap } from "#utils/objectFormatter";
import { getRoundedValue } from "#utils/stringFormatter";
import { formatNumber } from "@validereinc/utilities";

export function getInventoryList(sites, streams, inventories) {
  const sitesNameMap = getPropertyAsMap(sites, "name");

  const streamsMap = getPropertyAsMap(streams);

  return inventories.map((inventory) => ({
    ...inventory,
    stream: streamsMap[inventory.active_stream_id]?.name,
    siteName: sitesNameMap[inventory.site_id],
    productType: streamsMap[inventory.active_stream_id]?.product_type,
  }));
}

export function getCapacityPercentage(volume, capacity) {
  if (isNaN(volume) || isNaN(capacity)) {
    return "-";
  }

  const capacityPercentage = (volume / capacity) * 100;
  const roundedPercentage = getRoundedValue(capacityPercentage, 2);

  return roundedPercentage === "-"
    ? "-"
    : formatNumber(roundedPercentage, { fractionDigits: 2 }) ?? "-";
}

export function sortByPercentage(list) {
  return [...list].sort((a, b) => {
    const aValue =
      getCapacityPercentage(a.volume.value, a.capacity.value) ?? -1;
    const bValue =
      getCapacityPercentage(b.volume.value, b.capacity.value) ?? -1;

    return aValue - bValue;
  });
}
