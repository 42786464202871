import type {
  CreateOneRequestType,
  DeleteOneRequestType,
  GetListResponseType,
  PaginatedListRequestType,
  ResourceServiceType,
} from "../util";
import { getFilterObject } from "../util";
import {
  DefaultRecordValueConfigurationFilterType,
  DefaultRecordValueConfigurationType,
} from "../schemas";
import { restAPI } from "./api";

export type DefaultRecordValueConfigurationLookupType = { drvcId: string };

export type DefaultRecordValueConfigurationServiceType = Pick<
  ResourceServiceType<DefaultRecordValueConfigurationType>,
  "createOne" | "deleteOne"
>;

export const DefaultRecordValueConfigurationDomain = {
  /** Create a default record value configuration
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/default_record_value_configuration/upsert_default_record_value_configuration
   */
  createOne: (
    data: CreateOneRequestType<DefaultRecordValueConfigurationType>
  ) =>
    restAPI.nodeAPI
      .POST<DefaultRecordValueConfigurationType>({
        endpoint: `/default_record_value_configurations`,
        body: data,
      })
      .then((resp) => ({ data: resp })),

  /** Create a default record value configuration
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/default_record_value_configuration/delete_default_record_value_configuration
   */
  deleteOne: ({
    id,
  }: DeleteOneRequestType<DefaultRecordValueConfigurationType>) =>
    restAPI.nodeAPI
      .DELETE<DefaultRecordValueConfigurationType>({
        endpoint: `/default_record_value_configurations/${id}`,
      })
      .then(() => ({ status: 200 })),

  getList: async ({
    page,
    pageSize,
    sortBy,
    sortDirection,
    groupBy,
    filters,
    ...restProps
  }: PaginatedListRequestType<DefaultRecordValueConfigurationFilterType>) => {
    const formattedFilters = getFilterObject(filters);

    return restAPI.nodeAPI.POST<
      GetListResponseType<DefaultRecordValueConfigurationType>
    >({
      endpoint: "/default_record_value_configurations/search",
      body: {
        ...restProps,
        page,
        page_size: pageSize,
        sort_by: sortBy,
        sort_direction: sortDirection,
        filter: formattedFilters,
        group_by: groupBy,
      },
    });
  },

  getOne: async ({ drvcId }: DefaultRecordValueConfigurationLookupType) =>
    restAPI.nodeAPI.GET<DefaultRecordValueConfigurationType>({
      endpoint: `/default_record_value_configurations/${drvcId}`,
    }),
};
