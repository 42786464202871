import { Column, Link, Row, StorageKeys } from "@validereinc/common-components";
import {
  Resources,
  TemplatedConfigurationRunAdapter,
  type FlowType,
} from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { useState, ReactNode } from "react";
import styles from "./FlowDetailPage.module.scss";
import { FlowDetailsPanel } from "./FlowDetailsPanel";
import { FlowDevicesPanel } from "./FlowDevicesPanel";
import { FlowDownstreamPanel } from "./FlowDownstreamPanel";
import { FlowUpstreamPanel } from "./FlowUpstreamPanel";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import { useQuery } from "@tanstack/react-query";
import { IsFeatureAvailable } from "#src/batteries-included-components/Layouts/Authorization/IsFeatureAvailable";
import { TemplatedConfigurationRunDrawer } from "#src/batteries-included-components/Drawers/TemplatedConfigurationRunDrawer";

const cx = classNames.bind(styles);

export const FlowOverviewTab = ({
  flow,
  isLoading,
  viewFilterAreaSlot,
}: Pick<StorageKeys, "viewConfigStorageKey"> & {
  viewFilterAreaSlot: ReactNode;
  flow?: FlowType;
  isLoading: boolean;
}) => {
  if (!flow?.id) {
    return null;
  }

  const [isTemplatedConfigsAvailable] = useIsFeatureAvailable({
    featureFlagQuery: "core:templated_configurations",
    permissionQuery: "templated_configurations:read",
  });

  const templatedConfigurationRunDependencyGraphQuery = useQuery({
    queryKey: [
      Resources.TEMPLATED_CONFIGURATION_RUN,
      "dependencyGraph",
      flow.id,
    ],
    queryFn: () =>
      TemplatedConfigurationRunAdapter.dependencyGraph.getOne({
        id: flow.id,
      }),
    enabled: isTemplatedConfigsAvailable,
  });

  const [
    isTemplatedConfigurationRunDrawerOpen,
    setIsTemplatedConfigurationRunDrawerOpen,
  ] = useState(false);

  const templatedConfigurationRunDependencyGraph =
    templatedConfigurationRunDependencyGraphQuery?.data?.data;

  const templatedConfigurationLink =
    templatedConfigurationRunDependencyGraph?.name ? (
      <Link
        onClick={() => setIsTemplatedConfigurationRunDrawerOpen(true)}
        label={templatedConfigurationRunDependencyGraph.name}
      />
    ) : undefined;

  return (
    <>
      {viewFilterAreaSlot}
      <div className={cx("container")}>
        <div className={cx("contentContainer")}>
          <FlowDetailsPanel
            flow={flow}
            isLoading={isLoading}
            templatedConfigurationLink={templatedConfigurationLink}
          />
          <Row>
            <Column variant={9}>
              <FlowUpstreamPanel
                flow={flow}
                isLoading={isLoading}
              />
            </Column>

            <Column variant={9}>
              <FlowDownstreamPanel
                flow={flow}
                isLoading={isLoading}
              />
            </Column>
          </Row>

          <FlowDevicesPanel
            flowId={flow?.id}
            isLoading={isLoading}
          />
        </div>
      </div>
      <IsFeatureAvailable
        featureFlagQuery="core:templated_configurations"
        permissionQuery="templated_configurations:read"
        fallbackChildren={null}
      >
        <TemplatedConfigurationRunDrawer
          isOpen={isTemplatedConfigurationRunDrawerOpen}
          onClose={() => setIsTemplatedConfigurationRunDrawerOpen(false)}
          templatedConfigurationRunDependencyGraph={
            templatedConfigurationRunDependencyGraph
          }
          resourceId={flow.id}
        />
      </IsFeatureAvailable>
    </>
  );
};
