import { ExceptionUtils } from "#src/utils/exception";
import { useToast } from "@validereinc/common-components";
import { useEffect, useState } from "react";
import { validereApi } from "../Services/ServiceHelper";

/* eslint-disable no-console */

export function useAxiosInterceptors(
  signOut,
  auth0Instance,
  axiosInstance = validereApi
) {
  const { toast } = useToast();
  const [isMounting, setIsMounting] = useState(true);

  useEffect(() => {
    const responseSuccessHandler = undefined;

    const responseErrorHandler = (error) => {
      if (error.response) {
        switch (error.response.status) {
          case 401: {
            console.log("Not authenticated. Signing out...");
            signOut();
            break;
          }
          case 403: {
            toast.push({
              intent: "error",
              description:
                "You do not have the necessary permissions to complete the action.",
            });
            break;
          }
          case 404: {
            // intentionally no toast messaging for 404 errors
            break;
          }
          case 409: {
            // 409 conflict only happens on /POST inline_measurements
            // handled by asking for a prompt to overwrite
            break;
          }
          case 500: {
            toast.push({
              intent: "warning",
              description:
                "There was an issue with the server. Please refresh the page.",
            });
            break;
          }
          default: {
            const errorMessage = error.response?.data?.message;

            toast.push({
              intent: "warning",
              description:
                errorMessage ||
                "There was an unknown error. Please refresh the page.",
            });
            break;
          }
        }
      } else if (error.message !== "canceled") {
        toast.push({
          intent: "warning",
          description: `${error.message}. Please refresh the page.`,
        });
      }

      return Promise.reject(error);
    };

    const requestSuccessHandler = function (config) {
      return auth0Instance
        .getTokenSilently()
        .then((accessToken) => {
          config.headers.Authorization = `Bearer ${accessToken}`;
          return config;
        })
        .catch((err) => {
          ExceptionUtils.reportException(err, "fatal", {
            hint: "Axios interceptor could not be bootstrapped",
          });
          signOut();
          return Promise.reject(err);
        });
    };

    const requestErrorHandler = undefined;

    const requestInterceptorId = axiosInstance.interceptors.request.use(
      requestSuccessHandler,
      requestErrorHandler
    );

    const responseInterceptorId = axiosInstance.interceptors.response.use(
      responseSuccessHandler,
      responseErrorHandler
    );

    setIsMounting(false);

    return function cleanup() {
      axiosInstance.interceptors.response.eject(responseInterceptorId);
      axiosInstance.interceptors.request.eject(requestInterceptorId);
    };
  }, [auth0Instance]);

  return isMounting;
}
