import ColumnSelect from "#components/Common/ColumnSelect/ColumnSelect";
import RecordOfQualityService from "#components/Services/RecordOfQualityService";
import {
  ensureStreamListIsFetched,
  fetchSiteList,
  fetchStreamList,
} from "#redux/actions/index";
import {
  getFormattedMeasurementValue,
  getMeasurementType,
  measurementNames,
} from "#redux/reducers/measurements";
import { getBreadcrumbsObject } from "#routers/breadcrumbsHelper";
import { useNavigate, useSearchParams } from "#routers/hooks";
import SampleService from "#services/SampleService";
import { linkToSampleDetail } from "#src/Routers/links";
import useTableState from "#src/hooks/useTableState";
import {
  Button,
  DataTable,
  Filters,
  Page,
  Panel,
  useToast,
} from "@validereinc/common-components";
import { downloadLink, localStorageToJSON } from "@validereinc/utilities";
import * as PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getAlerts } from "./SampleComparisonTableHelper";
import SampleExportModal from "./SampleExportModal";
import useSampleConfig from "./useSamplesConfig";

const SAMPLES_LOCAL_STORAGE_KEY = "samples_selection_headers";
const SAMPLE_DOWNLOAD_FILES = [
  { title: "Download Crude Analysis", id: "crude_analysis" },
  {
    title: "Download Full Compositional Analysis",
    id: "compositional_analysis",
  },
  { title: "Download Violation Letter", id: "violation_letter" },
];

const mapStateToProps = ({ sites, streams, measurements }) => {
  return {
    sites: sites.data?.toJS() ?? [],
    streams: streams.data?.toJS() ?? [],
    measurementType: getMeasurementType(measurements),
    getFormattedMeasurementValue: (measurementKey, measurementValue) =>
      getFormattedMeasurementValue(measurements)(
        measurementKey,
        measurementValue
      ),
    supportedMeasurementTypes: measurementNames(measurements),
  };
};

const mapDispatchToProps = {
  ensureStreamListIsFetched,
  fetchStreamList,
  fetchSiteList,
};

export const Samples = ({
  sites,
  streams,
  ensureStreamListIsFetched,
  fetchSiteList,
  breadcrumbs,
  measurementType,
  getFormattedMeasurementValue,
  supportedMeasurementTypes,
}) => {
  const [showSampleExportModal, setShowSampleExportModal] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const samplesListPageBreadcrumbs = getBreadcrumbsObject(breadcrumbs);

  const { toast } = useToast();

  const onFetchData = useCallback(async (newSearchParams) => {
    const { page, rowPerPage, sort, sortDirection, ...restQuery } =
      newSearchParams;

    return await SampleService.getSamplesForValidation(
      {
        page,
        rowPerPage,
        sort: { [sort]: sortDirection },
      },
      restQuery
    );
  }, []);

  const { tableProps } = useTableState({
    onFetchData,
    itemsKey: "data.data",
    subscription: [
      "page",
      "rowPerPage",
      "sort",
      "sortDirection",
      "from",
      "to",
      "status",
      "stream",
      "site",
      "samplePoint",
    ],
  });

  const { filters, quickFilters, headers } = useSampleConfig({
    streams,
    sites,
    items: tableProps?.items,
    measurementType,
    getFormattedMeasurementValue,
    supportedMeasurementTypes,
  });

  const getHeaders = () => {
    let defaultSelectedHeaders = localStorageToJSON(SAMPLES_LOCAL_STORAGE_KEY);

    if (defaultSelectedHeaders?.length) {
      defaultSelectedHeaders = defaultSelectedHeaders.map(({ key }) => key);

      return headers.filter(({ key }) => defaultSelectedHeaders.includes(key));
    } else {
      return headers;
    }
  };

  const [displayHeaders, setDisplayHeaders] = useState(getHeaders);

  useEffect(() => {
    setDisplayHeaders(getHeaders());
  }, [headers]);

  useEffect(() => {
    ensureStreamListIsFetched();
    fetchSiteList();
  }, []);

  const onRowClick = ({ id }) => {
    navigate({
      pathname: linkToSampleDetail(id),
    });
  };

  const onFiltersChange = (values) => {
    const { quickFilters, samplePoint, site, stream, date } = values;

    const streamIds = stream?.map(({ id }) => id);
    const siteIds = site?.map(({ id }) => id);

    setSearchParams({
      ...searchParams,
      ...date,
      stream: streamIds,
      site: siteIds,
      samplePoint,
      status: quickFilters,
    });
  };

  const onExportButtonClick = () => {
    setShowSampleExportModal(true);
  };

  const createSuccessfulDownloadAlertMessage = () => {
    toast.push({
      intent: "success",
      description: "Your files will start downloading shortly.",
    });
  };

  const onDownloadLinkClick = (sampleId, type) => {
    RecordOfQualityService.getDownloadSampleCompositionalLink(
      sampleId,
      type
    ).then(({ data }) => {
      downloadLink(data.url);
      createSuccessfulDownloadAlertMessage();
    });
  };

  const onLabResultDownloadLinkClick = (labReportId) => {
    SampleService.getLabReportFile(labReportId).then(({ data }) => {
      downloadLink(data.url);
      createSuccessfulDownloadAlertMessage();
    });
  };

  const getItemActions = useCallback(({ item }) => {
    const actionList = [];

    if (item?.tests) {
      const alerts = getAlerts(item);

      SAMPLE_DOWNLOAD_FILES.forEach((file) => {
        if (file.id !== "violation_letter" || alerts.length) {
          actionList.push({
            isOverflow: true,
            label: file.title,
            buttonProps: {
              onClick: () => onDownloadLinkClick(item.id, file.id),
            },
          });
        }
      });

      item.tests
        .filter((test) => test.lab_report?.id)
        .forEach((test) => {
          actionList.push({
            isOverflow: true,
            label: `Download Lab Report`,
            buttonProps: {
              onClick: () => onLabResultDownloadLinkClick(test.lab_report.id),
            },
          });
        });

      return actionList;
    }
  }, []);

  return (
    <Page
      title="Samples"
      breadcrumbs={samplesListPageBreadcrumbs}
    >
      <Filters
        onChange={onFiltersChange}
        filters={filters}
        quickFilters={quickFilters}
        actions={[
          <ColumnSelect
            key={"column-select"}
            options={headers}
            onChange={setDisplayHeaders}
            value={displayHeaders}
            localStorageKey={SAMPLES_LOCAL_STORAGE_KEY}
          />,
          <Button
            key="export-button"
            variant="primary"
            size="small"
            onClick={onExportButtonClick}
          >
            Export
          </Button>,
        ]}
      />

      <Panel>
        <DataTable
          headers={displayHeaders}
          {...tableProps}
          onRowClick={onRowClick}
          getItemActions={getItemActions}
          isFluid={false}
        />
      </Panel>

      <SampleExportModal
        show={showSampleExportModal}
        setShowSampleExportModal={setShowSampleExportModal}
        sites={sites}
        streams={streams}
      />
    </Page>
  );
};

Samples.propTypes = {
  streams: PropTypes.array.isRequired,
  sites: PropTypes.array.isRequired,
  ensureStreamListIsFetched: PropTypes.func.isRequired,
  fetchSiteList: PropTypes.func.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
  supportedMeasurementTypes: PropTypes.array.isRequired,
  measurementType: PropTypes.func.isRequired,
  getFormattedMeasurementValue: PropTypes.func.isRequired,
};

const SamplesContainer = connect(mapStateToProps, mapDispatchToProps)(Samples);

export default SamplesContainer;
