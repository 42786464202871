import { DeleteCommentDialog } from "#src/batteries-included-components/Dialogs/DeleteCommentDialog";
import { CommentForm } from "#src/batteries-included-components/Forms/CommentForm";
import { useGetOneCommentFeed } from "#src/components/hooks/adapters/useComments";
import { useListWorkflowActions } from "#src/components/hooks/adapters/useWorkflowTasks";
import {
  useCheckCommentPermissions,
  useCommentsStore,
  useDeleteComment,
  useScrollToComment,
} from "#src/components/hooks/useComments";
import { useAuthenticatedContext } from "#src/contexts/AuthenticatedContext.helpers";
import {
  Accordion,
  ButtonToggleGroup,
  EmptyState,
  Icon,
  Message,
  Pill,
  TimelinePanel,
} from "@validereinc/common-components";
import {
  CommentEntity,
  CommentSchemaType,
  SortDirection,
  WorkflowTaskAdapter,
  WorkflowType,
} from "@validereinc/domain";
import { datetimeFormatter } from "@validereinc/utilities";
import classNames from "classnames/bind";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import React, { useEffect, useMemo, useState } from "react";
import styles from "./WorkflowCommentsDrawer.module.css";

const cx = classNames.bind(styles);

const { AccordionPanel } = Accordion;

const WorkflowCommentsDrawer = ({
  workflow,
  drawerKey,
}: {
  workflow: WorkflowType;
  drawerKey: string;
}) => {
  const workflowId = workflow.id;
  const { workflow_template } = workflow ?? {};

  const {
    v2: {
      userInfo: { user },
    },
  } = useAuthenticatedContext();
  const {
    canReadComments,
    canWriteComments,
    canDeleteCommentsAsAdmin,
    canReadAssetType,
    canWriteAssetType,
  } = useCheckCommentPermissions(workflow.asset?.asset_type);
  const {
    commentsEndRef,
    shouldScrollToBottom,
    newlyCreatedCommentId,
    handleCreateComment,
  } = useScrollToComment();
  const {
    handleDeleteComment,
    showDeleteCommentDialog,
    setShowDeleteCommentDialog,
    commentIdToDelete,
    setCommentIdToDelete,
  } = useDeleteComment();
  const { setCommentCounts } = useCommentsStore();

  const [commentPanelActiveKey, setCommentPanelActiveKey] = useState("");

  const { data: workflowCommentsData } = useGetOneCommentFeed(
    {
      id: workflowId,
      meta: {
        entity_type: CommentEntity.WORKFLOW,
        additional_identifier: "",
        created_before: "",
      },
    },
    {
      enabled: Boolean(workflowId) && canReadComments && canReadAssetType,
    }
  );

  const workflowComments = useMemo(
    () =>
      workflowCommentsData?.data
        .filter(
          (workflowComment) =>
            !workflowComment.is_deleted &&
            !workflowComment.additional_identifier
        )
        // The API sends the comments in descending order by commented_at
        .reverse() ?? [],
    [workflowCommentsData]
  );

  const filters: Parameters<typeof WorkflowTaskAdapter.getList>[0]["filters"] =
    {
      workflow_id: workflowId,
    };
  const { data: tasksData } = useListWorkflowActions(
    {
      filters,
      ...{
        sortBy: "created_at",
        sortDirection: SortDirection.ASCENDING,
      },
    },
    {
      enabled: !isEmpty(workflow_template),
    }
  );
  const tasks =
    tasksData?.data.map((task) => {
      return { id: task.id, name: task.name };
    }) ?? [];
  const tasksWithComments = useMemo(() => {
    if (!tasks || !workflowCommentsData?.data) return [];

    const commentsByTask = workflowCommentsData.data.reduce(
      (comments, comment) => {
        if (comment.is_deleted || !comment.additional_identifier) {
          return comments;
        }

        comments[comment.additional_identifier] =
          comments[comment.additional_identifier] || [];
        comments[comment.additional_identifier].push(comment);

        return comments;
      },
      {} as Record<string, CommentSchemaType[]>
    );

    return tasks.map((task) => ({
      id: task.id,
      name: task.name,
      comments: (commentsByTask[task.id] || []).reverse(),
    }));
  }, [tasks, workflowCommentsData]);

  const taskCommentsCount = useMemo(
    () =>
      tasksWithComments.reduce(
        (totalComments, task) => totalComments + task.comments.length,
        0
      ),
    [tasksWithComments]
  );

  useEffect(() => {
    if (commentsEndRef.current && shouldScrollToBottom) {
      commentsEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [workflowComments, shouldScrollToBottom, commentsEndRef]);

  useEffect(() => {
    setCommentCounts(
      `workflowComments-${workflowId}`,
      workflowComments.length + taskCommentsCount
    );
  }, [workflowComments.length, taskCommentsCount]);

  const canCreateComments = canWriteComments && canWriteAssetType;
  const hasTaskComments = tasksWithComments.some(
    (taskWithComments) => taskWithComments.comments.length !== 0
  );

  return (
    <>
      <TimelinePanel.Drawer
        shouldRenderPortal={false}
        drawerKey={drawerKey}
      >
        <TimelinePanel.Header title="Comments" />
        <TimelinePanel.Content
          className={cx(
            "comment-panel-content",
            ((commentPanelActiveKey === "workflow" &&
              !workflowComments?.length) ||
              (commentPanelActiveKey === "task" && !hasTaskComments)) &&
              "no-overflow"
          )}
        >
          <div className={cx("comment-panel-button-toggle-group-container")}>
            <ButtonToggleGroup
              className={cx("comment-panel-button-toggle-group")}
              items={[
                {
                  label: "Workflow",
                  dataKey: "workflow",
                  rightSlot: workflowComments && (
                    <Pill variant="default">{workflowComments.length}</Pill>
                  ),
                },
                {
                  label: "Task",
                  dataKey: "task",
                  rightSlot: workflowComments && (
                    <Pill variant="default">{taskCommentsCount}</Pill>
                  ),
                },
              ]}
              activeKey={commentPanelActiveKey ?? "workflow"}
              onChange={(newActiveKey) =>
                setCommentPanelActiveKey(newActiveKey)
              }
            />
          </div>

          {commentPanelActiveKey === "workflow" ? (
            <>
              {workflowComments && workflowComments.length > 0 ? (
                <>
                  {workflowComments.map((workflowComment) => (
                    <Message
                      className={cx(
                        workflowComment.id === newlyCreatedCommentId &&
                          "play-flash-animation"
                      )}
                      key={workflowComment.id}
                      name={workflowComment.user?.name}
                      meta={datetimeFormatter(
                        new Date(workflowComment.commented_at ?? "")
                      )}
                      textContent={workflowComment.text}
                      {...(canDeleteCommentsAsAdmin ||
                      (canWriteComments &&
                        user?.id === workflowComment.user?.id)
                        ? {
                            actions: [
                              {
                                label: "Delete",
                                onClick: () => {
                                  setShowDeleteCommentDialog(true);
                                  setCommentIdToDelete(workflowComment.id);
                                },
                                slotLeft: () => <Icon variant="trash" />,
                                variant: "danger",
                              },
                            ],
                          }
                        : undefined)}
                    />
                  ))}
                  <div ref={commentsEndRef} />
                </>
              ) : (
                <EmptyState
                  className={cx("comments-empty-state")}
                  title="No comments yet."
                  icon={<Icon variant="chat-text" />}
                />
              )}
            </>
          ) : null}
          {commentPanelActiveKey === "task" ? (
            tasksWithComments &&
            tasksWithComments.length > 0 &&
            hasTaskComments ? (
              tasksWithComments.map((task) =>
                task.comments.length > 0 ? (
                  <Accordion
                    key={task.id}
                    defaultActiveKeys={[task.id]}
                  >
                    <AccordionPanel
                      key={task.id}
                      dataKey={task.id}
                      title={
                        <div>
                          <p className={cx("comment-panel-task-title")}>
                            {task.name}
                          </p>
                        </div>
                      }
                      className={cx("comment-panel-task-accordion")}
                      iconVariant="triangle-down"
                      iconProps={{ size: 12 }}
                    >
                      <>
                        {task.comments.map((taskComment: CommentSchemaType) => (
                          <Message
                            className={cx(
                              taskComment.id === newlyCreatedCommentId &&
                                "play-flash-animation"
                            )}
                            key={taskComment.id}
                            name={taskComment.user?.name}
                            meta={datetimeFormatter(
                              new Date(taskComment.commented_at ?? "")
                            )}
                            textContent={taskComment.text}
                            {...(canDeleteCommentsAsAdmin ||
                            (canWriteComments &&
                              user?.id === taskComment.user?.id)
                              ? {
                                  actions: [
                                    {
                                      label: "Delete",
                                      onClick: () => {
                                        setShowDeleteCommentDialog(true);
                                        setCommentIdToDelete(taskComment.id);
                                      },
                                      slotLeft: () => <Icon variant="trash" />,
                                      variant: "danger",
                                    },
                                  ],
                                }
                              : undefined)}
                          />
                        ))}
                        {canCreateComments ? (
                          <div
                            className={cx(
                              "comment-panel-task-comment-form-container"
                            )}
                          >
                            <CommentForm
                              entity_type={CommentEntity.WORKFLOW}
                              entity_id={workflowId}
                              additional_identifier={task.id}
                              onCreateCallback={handleCreateComment}
                              canCreateComments={canCreateComments}
                            />
                          </div>
                        ) : null}
                      </>
                    </AccordionPanel>
                  </Accordion>
                ) : null
              )
            ) : (
              <EmptyState
                className={cx("comments-empty-state")}
                title="No comments yet."
                icon={<Icon variant="chat-text" />}
              />
            )
          ) : null}
        </TimelinePanel.Content>
        {commentPanelActiveKey === "workflow" && canCreateComments ? (
          <TimelinePanel.Footer className={cx("comment-panel-footer")}>
            <CommentForm
              entity_type={CommentEntity.WORKFLOW}
              entity_id={workflowId}
              onCreateCallback={handleCreateComment}
              canCreateComments={canCreateComments}
            />
          </TimelinePanel.Footer>
        ) : null}
      </TimelinePanel.Drawer>
      <DeleteCommentDialog
        isOpen={showDeleteCommentDialog}
        onClose={() => {
          setShowDeleteCommentDialog(false);
        }}
        commentIdToDelete={commentIdToDelete ?? ""}
        handleDelete={handleDeleteComment}
      />
    </>
  );
};

export default React.memo(WorkflowCommentsDrawer, isEqual);
