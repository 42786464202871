import { DeviceConfigurationFilterPanel } from "#batteries-included-components/Layouts/RecordConfiguration/DeviceConfigurationFilterPanel";
import { FunctionSelector } from "#batteries-included-components/Layouts/RecordConfiguration/FunctionSelector";
import {
  DeviceConfigurationFilterType,
  RecordValueConfigurationContext,
} from "#batteries-included-components/Layouts/RecordConfiguration/RecordConfigurationContext";
import { useParams } from "#routers/hooks";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import { useStorageKey } from "#src/hooks/useStorageKey";
import useTableState from "#src/hooks/useTableState";
import { getFrontendTableState } from "#utils/frontendTableActions";
import { linkToAssetDetailPage } from "#utils/links";
import {
  getAssetIdsByType,
  getSubjectIdFromSearchParams,
} from "#utils/recordUtils";
import { useQuery } from "@tanstack/react-query";
import {
  Column,
  DataTable,
  DataTablePanel,
  HeaderType,
  Row,
  useFilters,
} from "@validereinc/common-components";
import {
  AssetType,
  AssetTypeType,
  EquipmentDomain,
  FacilityDomain,
  FlowDomain,
  MeasurementSeriesAdapter,
  MeasurementsDomain,
  SeriesResponseDataItemType,
} from "@validereinc/domain";
import { isAfter } from "date-fns";
import React, { useCallback, useContext, useEffect } from "react";

export const DeviceConfiguration = () => {
  const { getUnitName, getPrecisionByType } = useMeasurementTypes();
  const { measurementType, form } =
    useContext(RecordValueConfigurationContext) || {};
  const params = useParams<{ measurementType: string }>();

  const { filterConfigStorageKey, tableConfigStorageKey } = useStorageKey(
    "record-source-configuration-panel-device-tab"
  );
  const [storedFilters] = useFilters<DeviceConfigurationFilterType>(
    filterConfigStorageKey
  );

  const subjectType = storedFilters.asset_type;
  const subjectId = getSubjectIdFromSearchParams(storedFilters);

  const measurementSearchParams = {
    measurementType: params.measurementType,
    device_id: storedFilters.device,
    ...(subjectType && subjectId ? { subjectType, subjectId } : {}),
    start: storedFilters.from,
    end: storedFilters.to,
    include_forms: false,
  };

  const measurementsQuery = useQuery({
    queryKey: ["measurementsPlot", measurementSearchParams],
    queryFn: async () =>
      MeasurementsDomain.getMeasurementPlotByMeasurementType(
        measurementSearchParams
      ),
    enabled:
      !!storedFilters.device && !!storedFilters.from && !!storedFilters.to,
  });

  /* We actually get a list of measurement series back, but there's no way to save more than one */
  /* Default to the first one */
  const items = measurementsQuery.data?.series?.[0]?.data ?? [];
  const seriesId = measurementsQuery.data?.series?.[0]?.measurement_series_id;
  useEffect(() => {
    form?.setValue("measurement_series_id", seriesId);
  }, [seriesId]);

  const measurementSeriesQuery = useQuery({
    queryKey: ["measurementSeries", seriesId],
    queryFn: () => MeasurementSeriesAdapter.getOne({ id: seriesId ?? "" }),
    enabled: !!seriesId,
  });

  const assets =
    measurementSeriesQuery.data?.subjects?.map(
      ({ entity_id: id, entity_type: type }) => ({ id, type })
    ) ?? [];

  const equipmentParams = {
    filters: {
      id: getAssetIdsByType(assets, AssetType.EQUIPMENT),
    },
  };
  const equipmentQuery = useQuery({
    queryKey: ["equipment", equipmentParams],
    queryFn: async () => EquipmentDomain.getEquipment(equipmentParams),
    enabled: !!equipmentParams.filters.id.length,
  });

  const facilityParams = {
    filters: { id: getAssetIdsByType(assets, AssetType.FACILITY) },
  };
  const facilityQuery = useQuery({
    queryKey: ["facilities", facilityParams],
    queryFn: async () => FacilityDomain.getList(facilityParams),
    enabled: !!facilityParams.filters.id.length,
  });

  const flowParams = {
    filters: { id: getAssetIdsByType(assets, AssetType.FLOW) },
  };
  const flowQuery = useQuery({
    queryKey: ["flows", flowParams],
    queryFn: async () => FlowDomain.getFlows(flowParams),
    enabled: !!flowParams.filters.id.length,
  });

  const getAsset = (assetType: AssetTypeType, assetId: string) => {
    switch (assetType) {
      case AssetType.EQUIPMENT:
        return equipmentQuery.data?.data.find(({ id }) => assetId === id);
      case AssetType.FACILITY:
        return facilityQuery.data?.data.find(({ id }) => assetId === id);
      case AssetType.FLOW:
      default:
        return flowQuery.data?.data.find(({ id }) => assetId === id);
    }
  };

  const isLoading =
    (!!storedFilters.device && measurementsQuery.isLoading) ||
    (!!seriesId && measurementSeriesQuery.isLoading) ||
    (!!equipmentParams.filters.id.length && equipmentQuery.isLoading) ||
    (!!facilityParams.filters.id.length && facilityQuery.isLoading) ||
    (!!flowParams.filters.id.length && flowQuery.isLoading);

  const getSubject = (time: string) => {
    const subject = measurementSeriesQuery.data?.subjects.find(
      ({ start_time }) => isAfter(new Date(time), new Date(start_time))
    );
    if (!subject) {
      return;
    }
    const asset = getAsset(subject.entity_type, subject.entity_id);
    return {
      ...subject,
      name: asset?.name,
    };
  };

  const headers: Array<HeaderType<SeriesResponseDataItemType>> = [
    {
      key: "measure_value",
      label: "Measurement",
      renderComponent: ({ item }) => {
        const precision = getPrecisionByType(measurementType?.id ?? "");

        return (
          <DataTable.DataRow.NumberCell
            value={item.measure_value}
            unit={getUnitName(measurementSeriesQuery.data?.unit ?? "")}
            formattingOpts={
              precision
                ? {
                    fractionDigits: precision,
                  }
                : {}
            }
          />
        );
      },
    },
    {
      key: "entity_id",
      label: "Asset",
      renderComponent: ({ item }: { item: SeriesResponseDataItemType }) => {
        const subject = getSubject(item.time);
        return subject ? (
          <RoutingLink
            to={linkToAssetDetailPage(subject.entity_type, subject.entity_id)}
          >
            {subject.name}
          </RoutingLink>
        ) : (
          "-"
        );
      },
    },
    {
      key: `measurement_type`,
      label: "Measurement Type",
      renderComponent: () => measurementType?.name,
    },
    {
      key: `time`,
      label: "Date",
      renderComponent: ({ item }) => (
        <DataTable.DataRow.DateCell
          value={item.time}
          withTime={true}
        />
      ),
    },
  ];

  const onFetchData = useCallback(
    (newSearchParams) =>
      getFrontendTableState({
        data: { data: items },
        itemsKey: "data",
        query: newSearchParams,
        filterMapping: {},
      }).data,
    [items]
  );

  const { tableProps } = useTableState({
    onFetchData,
    isEnabled: !isLoading,
    itemsKey: "data",
  });

  return (
    <>
      <DeviceConfigurationFilterPanel storageKey={filterConfigStorageKey} />
      <Row>
        <Column variant={18}>
          <DataTablePanel
            panelProps={{ title: "Device Measurements" }}
            dataTableProps={{
              ...tableProps,
              // REVIEW: is this an accurate unique identifier?
              getItemId: (item: any) => item.estimation_method_id,
              headers: items.length ? headers : [],
              emptyStateProps: {
                title: `There are no measurements of type ${measurementType?.name} in this period on this device`,
                suggestion:
                  "Try changing devices, time periods, or your source type",
              },
              isLoading,
            }}
            storageKey={tableConfigStorageKey}
          />
        </Column>
        <Column variant={6}>
          <FunctionSelector
            values={items.map(({ measure_value }) => measure_value ?? 0)}
            isLoading={isLoading}
          />
        </Column>
      </Row>
    </>
  );
};
