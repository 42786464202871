import {
  type CompanyType,
  type CreateOneTemplatedReportCategoryType,
  type ReportV2Type,
  type TemplatedReportCategoryType,
  type TemplatedReportType,
  type TemplatedReportWithCategoryType,
  type UpdateOneTemplatedReportCategoryType,
  type UpdateOneTemplatedReportType,
} from "../schemas";
import {
  fetchAndCollate,
  getFilterObject,
  ResourceServiceType,
  type CreateOneRequestType,
  type CreateOneResponseType,
  type DeleteOneRequestType,
  type GetListRequestType,
  type GetListResponseType,
  type GetOneRequestType,
  type GetOneResponseType,
  type ResponseErrorType,
  type ResponseStatusType,
  type UpdateOneRequestType,
  type UpdateOneResponseType,
} from "../util";
import { restAPI } from "./api";

type TemplatedReportServiceType = {
  getList: (
    params: GetListRequestType<
      Pick<TemplatedReportType, "name" | "display_name" | "status"> & {
        "category.id": TemplatedReportCategoryType["id"];
        "category.name": TemplatedReportCategoryType["name"];
        "category.description": TemplatedReportCategoryType["description"];
        "category.company_id": TemplatedReportCategoryType["company_id"];
        "category.created_by": TemplatedReportCategoryType["created_by"];
        "category.created_at": TemplatedReportCategoryType["created_at"];
        "category.updated_by": TemplatedReportCategoryType["updated_by"];
        "category.updated_at": TemplatedReportCategoryType["updated_at"];
      }
    >
  ) => Promise<GetListResponseType<TemplatedReportWithCategoryType>>;
  getOne: (
    params: GetOneRequestType
  ) => Promise<GetOneResponseType<TemplatedReportWithCategoryType>>;
  generate: (params: {
    name: string;
    templatedReportName: string;
    input: Record<string, string | number>;
  }) => Promise<ReportV2Type>;
  company: Pick<
    ResourceServiceType<TemplatedReportWithCategoryType>,
    "getList"
  > & {
    getList: (
      params: GetListRequestType<
        Pick<
          TemplatedReportWithCategoryType,
          "name" | "display_name" | "status"
        > & {
          "category.id": TemplatedReportCategoryType["id"];
          "category.name": TemplatedReportCategoryType["name"];
          "category.description": TemplatedReportCategoryType["description"];
          "category.company_id": TemplatedReportCategoryType["company_id"];
          "category.created_by": TemplatedReportCategoryType["created_by"];
          "category.created_at": TemplatedReportCategoryType["created_at"];
          "category.updated_at": TemplatedReportCategoryType["updated_at"];
          "category.updated_by": TemplatedReportCategoryType["updated_by"];
        }
      >
    ) => Promise<GetListResponseType<TemplatedReportWithCategoryType>>;
    updateOne: (
      params: UpdateOneRequestType<
        UpdateOneTemplatedReportType,
        { company_id: CompanyType["id"] }
      >
    ) => Promise<ResponseStatusType | ResponseErrorType>;
    deleteOne: (
      params: DeleteOneRequestType<
        TemplatedReportType,
        { company_id: CompanyType["id"] }
      >
    ) => Promise<ResponseStatusType | ResponseErrorType>;
  };
  categories: Pick<
    ResourceServiceType<TemplatedReportCategoryType>,
    "getList" | "getOne" | "createOne" | "updateOne" | "deleteOne"
  > & {
    getList: (
      params: GetListRequestType<Partial<TemplatedReportCategoryType>>
    ) => Promise<GetListResponseType<TemplatedReportCategoryType>>;
    createOne: (
      params: CreateOneRequestType<CreateOneTemplatedReportCategoryType>
    ) => Promise<CreateOneResponseType<TemplatedReportCategoryType>>;
    updateOne: (
      params: UpdateOneRequestType<UpdateOneTemplatedReportCategoryType>
    ) => Promise<UpdateOneResponseType<TemplatedReportCategoryType>>;
  };
};

export const TemplatedReportAdapter: TemplatedReportServiceType = {
  getOne: async ({ id }) => {
    const data = await restAPI.nodeAPI.GET<TemplatedReportWithCategoryType>({
      endpoint: `/templated_reports/${id}`,
    });
    return { data };
  },
  getList: ({ page, pageSize, sortBy, sortDirection, filters }) =>
    restAPI.nodeAPI.POST({
      endpoint: "/templated_reports/search_company",
      body: {
        page,
        page_size: pageSize,
        sort_by: sortBy,
        sort_direction: sortDirection,
        filter: getFilterObject(filters),
      },
    }),
  generate: ({ name, templatedReportName, input }) =>
    restAPI.nodeAPI.POST({
      version: 2,
      endpoint: "/reports",
      body: {
        name,
        templated_report_name: templatedReportName,
        input,
      },
    }),
  company: {
    /**
     * search templated reports under a company
     * @see {@link https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/reports/search_company_templated_report}
     */
    getList: ({ page, pageSize, sortBy, sortDirection, filters }) =>
      fetchAndCollate(
        ({ page, pageSize }) =>
          restAPI.nodeAPI.POST({
            version: 1,
            endpoint: "/templated_reports/search_company",
            body: {
              page,
              page_size: pageSize,
              sort_by: sortBy,
              sort_direction: sortDirection,
              filter: getFilterObject(filters),
            },
          }),
        page,
        pageSize
      ),
    /**
     * add a templated report to a company
     * @see {@link https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/reports/add_templated_report_to_company}
     */
    updateOne: ({ id, data, meta }) =>
      restAPI.nodeAPI.PUT<ResponseStatusType | ResponseErrorType>({
        version: 1,
        endpoint: `/templated_reports/${id}/company/${meta?.company_id}`,
        body: data,
      }),
    /**
     * remove a templated report from a company
     * @see {@link https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/reports/remove_templated_report_from_company}
     */
    deleteOne: ({ id, meta }) =>
      restAPI.nodeAPI.DELETE({
        version: 1,
        endpoint: `/templated_reports/${id}/company/${meta?.company_id}`,
      }),
  },
  categories: {
    /**
     * search templated report categories
     * @see {@link https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/reports/search_company_templated_report_categories}
     */
    getList: ({ page, pageSize, sortBy, sortDirection, filters }) =>
      fetchAndCollate(
        ({ page, pageSize }) =>
          restAPI.nodeAPI.POST({
            version: 2,
            endpoint: "/templated_report_categories/search",
            body: {
              page,
              page_size: pageSize,
              sort_by: sortBy,
              sort_direction: sortDirection,
              filter: getFilterObject(filters),
            },
          }),
        page,
        pageSize
      ),
    /**
     * get a templated report category
     * @see {@link https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/reports/get_templated_report_category}
     */
    getOne: ({ id }) =>
      restAPI.nodeAPI
        .GET<TemplatedReportCategoryType>({
          version: 2,
          endpoint: `/templated_report_categories/${id}`,
        })
        .then((resp) => ({ data: resp })),
    /**
     * create a templated report category
     * @see {@link https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/reports/create_templated_report_category}
     */
    createOne: ({ data }) =>
      restAPI.nodeAPI
        .POST<TemplatedReportCategoryType>({
          version: 2,
          endpoint: "/templated_report_categories",
          body: data,
        })
        .then((resp) => ({ data: resp })),
    /**
     * update a templated report category
     * @see {@link https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/reports/update_templated_report_category}
     */
    updateOne: ({ id, data }) =>
      restAPI.nodeAPI
        .PUT<TemplatedReportCategoryType>({
          version: 2,
          endpoint: `/templated_report_categories/${id}`,
          body: data,
        })
        .then((resp) => ({ data: resp })),
    /**
     * delete a templated report category
     * @see {@link https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/reports/delete_templated_report_category}
     */
    deleteOne: ({ id }) =>
      restAPI.nodeAPI.DELETE({
        version: 2,
        endpoint: `/templated_report_categories/${id}`,
      }),
  },
};
