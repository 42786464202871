import styles from "#batteries-included-components/Banners/ImportDataActionStatus/ImportDataActionStatus.module.scss";
import { RoutingLink } from "#batteries-included-components/RoutingLink";
import { getPollingOptions } from "#hooks/adapters/adapterUtils";
import {
  useClearReportsCache,
  useDownloadReport,
  useGetOneReport,
} from "#hooks/adapters/useReports";
import { TEMPLATED_REPORT_EXPORT_KEY } from "#hooks/adapters/useTemplatedReports";
import { TemplatedReportsListRoutePath } from "#routes/reports/templated-reports";
import { useStickyState } from "#src/hooks/useStickyState";
import {
  CustomStorageKeyTypes,
  useCustomStorageKey,
} from "#src/hooks/useStorageKey";
import { Banner, useToast } from "@validereinc/common-components";
import { ReportGeneratedStatus } from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { useEffect } from "react";

const cx = classNames.bind(styles);

export const TemplatedReportProgressBanner = () => {
  const { toast } = useToast();
  const reportStorageKey = useCustomStorageKey(
    TEMPLATED_REPORT_EXPORT_KEY,
    CustomStorageKeyTypes.REPORT
  );
  const [reportId, setReportId] = useStickyState<string | undefined>(
    undefined,
    reportStorageKey
  );

  const { data, error } = useGetOneReport(
    { id: reportId },
    getPollingOptions([
      ReportGeneratedStatus.SUCCESS,
      ReportGeneratedStatus.FAILED,
    ])
  );

  const report = data?.data;

  const downloadReport = useDownloadReport();
  const { invalidate } = useClearReportsCache();

  useEffect(() => {
    if (report?.status === ReportGeneratedStatus.SUCCESS) {
      toast.push({
        intent: "success",
        description: "Successfully downloaded report",
      });
      downloadReport.mutate({
        id: reportId,
      });
      setReportId(undefined);
      invalidate();
    }

    if (error || report?.status === ReportGeneratedStatus.FAILED) {
      toast.push({
        intent: "error",
        description: "Failed to download report",
      });
      setReportId(undefined);
      invalidate();
    }
  }, [report?.status]);

  return reportId ? (
    <Banner
      className={cx("banner")}
      titleText="Exporting Report"
      actionContent={
        <>
          You can view the exported report in the{" "}
          <RoutingLink
            to={TemplatedReportsListRoutePath.toLinkParts({
              queryParams: {
                tab: "history",
              },
            })}
          >
            Export History
          </RoutingLink>
        </>
      }
      color="loading"
      variant="loading"
    />
  ) : null;
};
