import { useEmissionReportSettings } from "#hooks/useEmissionReports";
import useEquipments from "#hooks/useEquipments";
import { useFacilities } from "#hooks/useFacilities";
import { ensureStreamListIsFetched } from "#redux/actions/index";
import { getBreadcrumbsObject } from "#routers/breadcrumbsHelper";
import history from "#routers/history";
import { linkToEmissionReports } from "#routers/links";
import EmissionService from "#services/EmissionService";
import { getPropertyAsMap } from "#utils/objectFormatter";
import {
  Button,
  Form,
  Page,
  Panel,
  useForm,
  useToast,
} from "@validereinc/common-components";
import classNames from "classnames/bind";
import differenceBy from "lodash/differenceBy";
import * as PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import styles from "./CreateEmissionReports.module.scss";
import DeleteEmissionReportModal from "./DeleteEmissionReportModal";
import EmissionReportsForm from "./EmissionReportsForm";
import EquipmentModal from "./EquipmentModal";

const cx = classNames.bind(styles);

const mapStateToProps = ({ streams }) => ({
  streams: streams?.data?.toJS() ?? [],
});

const mapDispatchToProps = {
  ensureStreamListIsFetched,
};

const CreateEmissionReports = ({
  breadcrumbs,
  match,
  streams,
  ensureStreamListIsFetched,
  height,
}) => {
  const emissionReportId = match.params.id ?? null;

  const [formState, setFormState] = useState("enabled");
  const [isEquipmentModalVisible, setIsEquipmentModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const [facilities, facilitiesLoadingState] = useFacilities();
  const [equipments, equipmentsLoadingState] = useEquipments();

  const [emissionReportSettings, emissionReportSettingsLoadingState] =
    useEmissionReportSettings(emissionReportId);

  const form = useForm();

  const { toast } = useToast();

  const onSubmit = (formValues) => {
    setFormState("disabled");

    EmissionService.postEmissionReport(formValues)
      .then(() => {
        toast.push({
          intent: "success",
          description: `Emission Report "${formValues.name}" successfully created.`,
        });

        history.push(linkToEmissionReports());
      })
      .finally(() => setFormState("enabled"));
  };

  const handleEquipmentSubmit = ({ equipments }) => {
    form.setValue("equipment", equipments);

    setIsEquipmentModalVisible(false);
  };

  const handleEquipmentDelete = (equipment) => {
    const equipmentList = form.getValues("equipment");
    const newEquipmentList = differenceBy(equipmentList, [equipment], "id");

    form.setValue("equipment", newEquipmentList, { shouldValidate: true });
  };

  const handleDeleteClick = () => {
    setFormState("disabled");

    EmissionService.deleteEmissionReport(emissionReportId)
      .then(() => {
        toast.push({
          intent: "success",
          description: `Emission Report ${
            emissionReportSettings?.name ?? ""
          } successfully deleted.`,
        });

        history.push(linkToEmissionReports());
      })
      .finally(() => setFormState("enabled"));
  };

  const equipmentsWithFacilityData = useMemo(() => {
    if (equipments.length && facilities.length) {
      const facilityMap = getPropertyAsMap(facilities);

      return equipments.map((equipment) => ({
        ...equipment,
        facility: facilityMap?.[equipment.facility_id]?.name,
        facility_code: facilityMap?.[equipment.facility_id]?.facility_code,
      }));
    }

    return equipments;
  }, [equipments, facilities]);

  useEffect(() => {
    ensureStreamListIsFetched();
  }, []);

  useEffect(() => {
    if (
      emissionReportSettings?.name &&
      equipmentsWithFacilityData.length &&
      facilities.length &&
      streams.length
    ) {
      const facilityMap = getPropertyAsMap(facilities);
      const equipmentMap = getPropertyAsMap(equipmentsWithFacilityData);
      const streamsMap = getPropertyAsMap(streams);

      const { name, equipment_ids, facility_ids, sales_product_stream_ids } =
        emissionReportSettings;

      form.reset({
        name: name,
        equipment: equipment_ids?.map((id) => equipmentMap[id]) ?? [],
        facilities: facility_ids?.map((id) => facilityMap[id]) ?? [],
        streams: sales_product_stream_ids?.map((id) => streamsMap[id]) ?? [],
      });
    }
  }, [emissionReportSettings, equipmentsWithFacilityData, facilities, streams]);

  const pageBreadcrumbs = useMemo(() => {
    const pageBreadcrumbs = getBreadcrumbsObject(breadcrumbs, match.params);

    if (pageBreadcrumbs[1] && emissionReportSettings?.name) {
      pageBreadcrumbs[1] = {
        ...pageBreadcrumbs[1],
        title: emissionReportSettings?.name,
      };
    }

    return pageBreadcrumbs;
  }, [breadcrumbs, match.params, emissionReportSettings]);

  return (
    <Page
      title={
        emissionReportId
          ? `Update ${emissionReportSettings?.name ?? "Report"}`
          : "Create Report"
      }
      breadcrumbs={pageBreadcrumbs}
    >
      <Panel
        loaded={
          facilitiesLoadingState === "loaded" &&
          equipmentsLoadingState === "loaded"
        }
        className={cx("panel")}
        style={{ minHeight: height }}
      >
        <Form
          onSubmit={onSubmit}
          {...form}
        >
          <EmissionReportsForm
            form={form}
            streams={streams}
            formState={formState}
            facilities={facilities}
            openEquipmentModal={() => setIsEquipmentModalVisible(true)}
            equipments={equipmentsWithFacilityData}
            handleEquipmentDelete={handleEquipmentDelete}
          />

          <div className={cx("buttonRow")}>
            {emissionReportSettingsLoadingState === "loaded" && (
              <Button
                className={cx("deleteButton")}
                variant="error-outline"
                disabled={formState !== "enabled"}
                onClick={() => setIsDeleteModalVisible(true)}
              >
                Delete Report
              </Button>
            )}

            <Button
              variant="primary"
              type="submit"
              disabled={formState !== "enabled"}
            >
              {emissionReportId ? "Update Report" : "Create Report"}
            </Button>
          </div>
        </Form>

        {isEquipmentModalVisible && (
          <EquipmentModal
            onClose={() => setIsEquipmentModalVisible(false)}
            onSubmit={handleEquipmentSubmit}
            equipments={equipmentsWithFacilityData}
            selectedEquipment={form.watch("equipment")}
          />
        )}

        {isDeleteModalVisible && (
          <DeleteEmissionReportModal
            onClose={() => setIsDeleteModalVisible(false)}
            formState={formState}
            onDeleteClick={handleDeleteClick}
          />
        )}
      </Panel>
    </Page>
  );
};

CreateEmissionReports.propTypes = {
  breadcrumbs: PropTypes.array,
  match: PropTypes.object,
  streams: PropTypes.array.isRequired,
  ensureStreamListIsFetched: PropTypes.func.isRequired,
  height: PropTypes.number,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateEmissionReports);
