import React from "react";
import * as PropTypes from "prop-types";
import Map from "#components/Map/Map";
import Marker from "#components/Map/Marker/Marker";

const AlertMapPanel = ({ deviceDetail, isLoading, devicesAtFacility }) => {
  const deviceLocation = deviceDetail?.attributes?.location;
  const initialViewState = {
    latitude: deviceLocation?.[0],
    longitude: deviceLocation?.[1],
  };

  return (
    <>
      {deviceLocation && !isLoading ? (
        <div style={{ height: "500px", width: "100%" }}>
          <Map initialViewState={initialViewState}>
            <Marker
              latitude={initialViewState.latitude}
              longitude={initialViewState.longitude}
              colorVariant="poppy"
              label={deviceDetail.name}
            />
            {devicesAtFacility
              .filter((device) => device.id !== deviceDetail?.id)
              .map((device) => {
                return device?.attributes?.location?.[0] ? (
                  <Marker
                    latitude={device?.attributes?.location[0]}
                    longitude={device?.attributes?.location[1]}
                    label={device.name}
                  />
                ) : null;
              })}
          </Map>
        </div>
      ) : null}
    </>
  );
};

AlertMapPanel.propTypes = {
  deviceDetail: PropTypes.object,
  devicesAtFacility: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default AlertMapPanel;
