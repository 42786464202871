import { useListEquipmentTypes } from "#src/components/hooks/adapters/useEquipment";
import useLocalization from "#src/hooks/useLocalization";
import { useMultiStepFormContext } from "#src/hooks/useMultiStepForm";
import {
  Banner,
  Card,
  DropdownInput,
  Form,
  Icon,
  Panel,
  Switch,
  useForm,
} from "@validereinc/common-components";
import classNames from "classnames/bind";
import React, { useEffect } from "react";
import styles from "./EquipmentFormPanel.module.scss";
import { useListTemplatedConfigurations } from "#src/components/hooks/adapters/useTemplatedConfigurations";
import {
  EquipmentSchema,
  ResourceDefinitions,
  Resources,
} from "@validereinc/domain";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import TemplatedConfigurationResourcePills from "#src/batteries-included-components/PillsCollection/TemplatedConfigurationResourcePills/TemplatedConfigurationResourcePills";
const cx = classNames.bind(styles);

const EquipmentOverviewFormStep = () => {
  const { currentStep, updateStep } = useMultiStepFormContext();
  const STEP_NUMBER = 1;

  const [canUserUseTemplatedConfigurations] = useIsFeatureAvailable({
    featureFlagQuery: "core:templated_configurations",
    permissionQuery: {
      $and: [
        "templated_configuration:read",
        "templated_configuration_runs:write",
      ],
    },
  });

  const equipmentTypesQuery = useListEquipmentTypes({});

  const { localize } = useLocalization();

  const form = useForm<{
    type_id: string;
    template_name?: string;
    should_use_template: boolean;
  }>({
    defaultValues: {
      type_id: "",
      template_name: "",
      should_use_template: false,
    },
  });

  const formValues = form.watch();

  useEffect(() => {
    updateStep(STEP_NUMBER, {
      getValues: () => form.getValues(),
      getFormState: () => ({
        isSubmitting: false,
        isValid:
          !!formValues.type_id &&
          (formValues.should_use_template ? !!formValues.template_name : true),
      }),
    });
  }, [JSON.stringify(formValues)]);

  useEffect(() => {
    if (!formValues.should_use_template)
      form.setValue("template_name", undefined);
  }, [formValues.should_use_template]);

  const isTemplateQueryDisabled =
    !formValues.should_use_template || !formValues.type_id;

  const availableTemplatesQuery = useListTemplatedConfigurations(
    {
      filters: {
        primary_resource_type: Resources.EQUIPMENT,
        equipment_type_id: formValues.type_id,
      },
    },
    { enabled: !isTemplateQueryDisabled }
  );

  const selectedTemplatedConfiguration = (
    availableTemplatesQuery.data?.data ?? []
  ).find((template) => template.name === formValues.template_name);

  const resourcesCreatedUsingSelectedTemplate = [
    {
      isPrimary: true,
      resourceType: selectedTemplatedConfiguration?.primary_resource_type,
    },
    ...(selectedTemplatedConfiguration?.other_resource_types ?? []).map(
      (resourceType) => ({
        isPrimary: false,
        resourceType,
      })
    ),
  ].filter((resource) => !!resource.resourceType);

  const isTemplatePickerDisabled =
    isTemplateQueryDisabled ||
    availableTemplatesQuery.isLoading ||
    availableTemplatesQuery.data?.total_entries === 0;

  if (currentStep !== STEP_NUMBER) return null;

  return (
    <Panel>
      <Form {...form}>
        <h4 className={cx("section-title")}>Overview</h4>
        <div className={cx("basic-info-container")}>
          <DropdownInput
            label="Type"
            name={EquipmentSchema.keyof().Enum.type_id}
            options={equipmentTypesQuery.data?.data ?? []}
            isSortedAlphabetically
            valueKey="id"
            labelKey="name"
            isRequired
            isFluid
            isLoading={equipmentTypesQuery.isLoading}
          />

          {canUserUseTemplatedConfigurations && (
            <>
              {!!formValues.type_id && (
                <Switch
                  label="Create using Configuration Template"
                  value={formValues.should_use_template}
                  onChange={(newValue) =>
                    form.setValue("should_use_template", newValue)
                  }
                  id="should-use-template-switch-id"
                  name="should-use-template"
                  isDisabled={!formValues.type_id}
                />
              )}
              {formValues.should_use_template && (
                <Card
                  colorVariant="grey"
                  className={cx("template-picker-box")}
                >
                  <DropdownInput
                    label="Configuration Template"
                    name="template_name"
                    options={availableTemplatesQuery.data?.data ?? []}
                    isSortedAlphabetically
                    valueKey="name"
                    labelKey="display_name"
                    isFluid
                    isLoading={availableTemplatesQuery.isLoading}
                    isDisabled={isTemplatePickerDisabled}
                    isRequired
                  />

                  {selectedTemplatedConfiguration && (
                    <>
                      {selectedTemplatedConfiguration.description && (
                        <>
                          <div className={cx("title")}>
                            Template Description
                          </div>
                          <div className={cx("description")}>
                            {selectedTemplatedConfiguration.description}
                          </div>
                        </>
                      )}
                      <div className={cx("title")}>Resource Types</div>
                      <TemplatedConfigurationResourcePills
                        templatedConfiguration={selectedTemplatedConfiguration}
                      />
                    </>
                  )}

                  {availableTemplatesQuery.data?.total_entries === 0 && (
                    <Banner
                      titleText={`There are no ${ResourceDefinitions.templated_configuration.label.plural} available for this type of ${localize("equipment")}`}
                      color="generic"
                      icon={<Icon variant="placeholder" />}
                    />
                  )}
                </Card>
              )}
            </>
          )}
        </div>
      </Form>
    </Panel>
  );
};

export default EquipmentOverviewFormStep;
