import { Brandmark } from "#src/components/Presentational/Logos/Brandmark";
import {
  Icon,
  LoadingState,
  PageFullScreenContainer,
} from "@validereinc/common-components";
import {
  Background,
  BackgroundVariant,
  ControlButton,
  Controls,
  MiniMap,
  ReactFlow,
  ReactFlowProvider,
  useReactFlow,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import classNames from "classnames/bind";
import React, { useRef } from "react";
import WorkflowGraphEdgeMarkers from "../WorkflowGraphEdge/WorkflowGraphEdgeMarkers";
import styles from "./WorkflowGraphCanvas.module.scss";
import { useWorkflowGraph } from "./useWorkflowGraph";
const cx = classNames.bind(styles);

export type WorkflowVisualizationProp = {
  mode: "workflow-template";
  templateId: string;
};

const MiniMapNode = ({ x, y }: { x: number; y: number }) => {
  return (
    <circle
      cx={x}
      cy={y}
      r="32"
      fill="#4d6670"
    />
  );
};

// This ID will be set as the outer container of the graph.
// It's also used in the Edge component, to bound its tooltip
// to an element with this ID.
export const WorkflowGraphCanvasOuterBoundaryId = "workflow-graph-container";

export const WorkflowGraphCanvasContent = (
  props: WorkflowVisualizationProp
) => {
  const {
    nodeTypes,
    edgeTypes,
    nodes,
    onNodesChange,
    edges,
    onEdgesChange,
    onEdgeMouseEnter,
    onEdgeMouseLeave,
    onNodeMouseEnter,
    onNodeMouseLeave,
    onLayout,
    isLoading,
  } = useWorkflowGraph(props);

  const ref = useRef<HTMLDivElement | null>(null);

  const { zoomIn, zoomOut, fitView } = useReactFlow();

  const actionButtons = (
    <>
      <div className={cx("top-controls")}>
        <ControlButton
          onClick={() => {
            onLayout("TB");
          }}
          className={cx("control-button")}
          title="Auto Layout"
          aria-label="auto layout"
        >
          <Icon
            variant="squares-four"
            size={22}
          />
        </ControlButton>
      </div>
      <div className={cx("side-controls")}>
        <ControlButton
          onClick={() => {
            zoomIn();
          }}
          className={cx("control-button", "zoom-in")}
          title="Zoom In"
          aria-label="zoom in"
        >
          <Icon
            variant="plus-circle"
            size={20}
          />
        </ControlButton>
        <ControlButton
          onClick={() => {
            zoomOut();
          }}
          className={cx("control-button", "zoom-out")}
          title="Zoom Out"
          aria-label="zoom out"
        >
          <Icon
            variant="minus-circle"
            size={20}
          />
        </ControlButton>
        <ControlButton
          onClick={() => {
            fitView();
          }}
          className={cx("control-button")}
          title="Fit Graph to View"
          aria-label="fit to view"
        >
          <Icon
            variant="corners-out"
            size={20}
          />
        </ControlButton>
      </div>
    </>
  );

  if (isLoading)
    return (
      <PageFullScreenContainer className={cx("fullscreen-loading")}>
        <LoadingState
          label="Loading..."
          size="normal"
          indicator={<Brandmark isAnimated />}
        />
      </PageFullScreenContainer>
    );

  return (
    <ReactFlow
      ref={ref}
      snapToGrid
      snapGrid={[16, 16]}
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onEdgeMouseEnter={onEdgeMouseEnter}
      onEdgeMouseLeave={onEdgeMouseLeave}
      onNodeMouseEnter={onNodeMouseEnter}
      onNodeMouseLeave={onNodeMouseLeave}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      style={{ background: "#f9fafb" }}
      fitView
    >
      <Controls
        position="top-right"
        showInteractive={false}
        showZoom={false}
        showFitView={false}
        className={cx("controls")}
      >
        {actionButtons}
      </Controls>
      <MiniMap nodeComponent={MiniMapNode} />
      <Background
        variant={BackgroundVariant.Dots}
        gap={16}
        size={2}
        color="#c4cbd0"
      />
    </ReactFlow>
  );
};

export const WorkflowGraphCanvas = (props) => (
  <ReactFlowProvider>
    <WorkflowGraphCanvasContent {...props} />
    <WorkflowGraphEdgeMarkers />
  </ReactFlowProvider>
);
