import React, { useContext } from "react";

import { DataTable, Pill } from "@validereinc/common-components";

import { linkToAlertDetail } from "#src/Routers/links";

import { EventContext } from "#src/contexts/EventContext";
import { EventsDomain } from "@validereinc/domain";
import { RoutingLink } from "../RoutingLink";

export const EventAlertsTable = () => {
  const { event, isLoading, onRefetchEvent } = useContext(EventContext) || {};

  const onRemove = async ({ alertId }: { alertId?: string }) => {
    if (!event?.id || !alertId) return;

    await EventsDomain.alerts.removeOne({ alertId, eventId: event?.id });

    onRefetchEvent?.();
  };

  return (
    <DataTable
      headers={[
        {
          key: "monitor.name",
          label: "Name",
          renderComponent: ({ item }) => (
            <RoutingLink to={linkToAlertDetail(item?.id)}>
              {item.monitor?.name}
            </RoutingLink>
          ),
        },
        {
          key: "inserted_at",
          label: "Date Triggered",
          renderComponent: ({ item }) => (
            <DataTable.DataRow.DateCell
              value={item.inserted_at}
              withTime
            />
          ),
        },
        {
          key: "resolved",
          label: `Status`,
          renderComponent: ({ item }) => (
            <Pill variant={item.resolved ? "default" : "error"}>
              {item.resolved ? "Closed" : "Open"}
            </Pill>
          ),
        },
        {
          key: "condition_descriptions",
          label: "Alert Condition Satisfied",
          renderComponent: ({ item }) =>
            item.condition_descriptions?.join(", "),
        },
      ]}
      items={event?.alerts?.data ?? []}
      isLoading={isLoading}
      getItemActions={({ item: { id } }) => [
        {
          label: "Remove",
          buttonProps: {
            onClick: () => {
              onRemove({ alertId: id });
            },
            icon: "trash",
          },
        },
      ]}
    />
  );
};
