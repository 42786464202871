import { useCustomAttributeFilters } from "#hooks/FilterPanel/useCustomAttributeFilters";
import {
  useClearEquipmentCache,
  useExportEquipment,
  useListEquipment,
} from "#hooks/adapters/useEquipment";
import { useTableSortingAndPagination } from "#redux/reducers/tableStateReducer";
import { linkToEquipmentDetail, linkToFacilities } from "#routers/links";
import { ImportDataActionStatusWithQueue } from "#src/batteries-included-components/Banners/ImportDataActionStatus";
import { TemplatedConfigurationRunStatusWithQueue } from "#src/batteries-included-components/Banners/TemplatedConfigurationRunStatus";
import { ImportDataAction } from "#src/batteries-included-components/Buttons/ImportDataAction";
import { getImportDataActionQueueKey } from "#src/batteries-included-components/Buttons/ImportDataAction/ImportDataAction.helpers";
import PermissionAwareExportButton from "#src/batteries-included-components/Buttons/PermissionAwareExportButton/PermissionAwareExportButton";
import EditEquipmentDialog from "#src/batteries-included-components/Dialogs/EditEquipmentDialog";
import {
  EquipmentTableFilterArea,
  EquipmentViewFilterArea,
} from "#src/batteries-included-components/FilterAreas/AssetsFilterAreas";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink/RoutingLink";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import useLocalization from "#src/hooks/useLocalization";
import { useSessionStickyState } from "#src/hooks/useStickyState";
import { useStorageKey } from "#src/hooks/useStorageKey";
import {
  Button,
  DataTable,
  DataTablePanel,
  Dialog,
  HeaderType,
  IconVariants,
  Pill,
  useToast,
} from "@validereinc/common-components";
import {
  AssetType,
  EquipmentDomain,
  EquipmentStatus,
  EquipmentType,
  ResourceDefinitions,
  Resources,
  SortDirection,
} from "@validereinc/domain";
import {
  getYearMonthFromDateRange,
  toStartCaseString,
} from "@validereinc/utilities";
import React, { useMemo, useState } from "react";

const importDataActionQueueKey = getImportDataActionQueueKey({
  resource: ResourceDefinitions.equipment,
});

export const EquipmentList = () => {
  const [isDataIngestionEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:data_pipeline",
  });
  const assetsSharedStorageKeys = useStorageKey("assets-shared");
  const storageKeys = useStorageKey("equipment");
  const { toast } = useToast();
  const [assetIdToEdit, setAssetIdToEdit] = useState<string | undefined>();
  const [equipmentToDelete, setEquipmentToDelete] = useState<EquipmentType>();
  const { localize, isLoading: isMappingLoading } = useLocalization();
  const [viewFilters] = useSessionStickyState(
    {},
    assetsSharedStorageKeys.viewConfigStorageKey
  );
  const [tableFilters] = useSessionStickyState(
    {},
    storageKeys.filterConfigStorageKey
  );
  const rawFilters = useMemo(
    () => ({ ...tableFilters, ...viewFilters }),
    [tableFilters, viewFilters]
  );

  const { period, ...filters } = rawFilters;

  const [tableState, updateTableState] = useTableSortingAndPagination(
    {
      sortBy: "facility_name",
      sortDirection: SortDirection.ASCENDING,
    },
    {
      ...filters,
      period,
    }
  );

  const { customAttributeHeaders: equipmentCustomAttributeHeaders } =
    useCustomAttributeFilters({
      assetType: AssetType.EQUIPMENT,
      section: `${localize("Equipment")}`,
    });

  const params = {
    page: tableState.page,
    pageSize: tableState.pageSize,
    sortBy: tableState.sortBy,
    sortDirection: tableState.sortDirection,
    filters,
    period: getYearMonthFromDateRange(period),
  };
  const { data, isLoading, refetch } = useListEquipment(params);
  const { invalidate } = useClearEquipmentCache();

  const { mutate: handleExport, isLoading: isExporting } = useExportEquipment({
    filters,
    period: getYearMonthFromDateRange(period),
    sortBy: params.sortBy,
    sortDirection: params.sortDirection,
  });

  const deleteEquipmentOnClick = async () => {
    try {
      if (equipmentToDelete) {
        await EquipmentDomain.delete({
          id: equipmentToDelete.id,
        });

        toast.push({
          intent: "success",
          description: `Successfully deleted '${equipmentToDelete.name}'`,
        });
      }
    } finally {
      invalidate();
      setEquipmentToDelete(undefined);
    }
  };

  const rowActionItems = ({ item }: { item: EquipmentType }) => [
    {
      label: "Edit",
      buttonProps: {
        onClick: () => setAssetIdToEdit(item.id),
        icon: "pencil-simple" as IconVariants,
      },
    },
    {
      label: "Delete",
      buttonProps: {
        onClick: () => setEquipmentToDelete(item),
        icon: "trash" as IconVariants,
      },
    },
  ];

  const fixedHeaders: Array<HeaderType<EquipmentType>> = useMemo(
    () => [
      {
        label: `${localize("Equipment")} Name`,
        key: "name",
        isSortable: true,
        renderComponent: ({ item }: { item: EquipmentType }) => (
          <RoutingLink to={linkToEquipmentDetail(item?.id)}>
            {item.name}
          </RoutingLink>
        ),
      },
      {
        label: "Status",
        key: "status",
        isSortable: true,
        variant: "pill",
        renderComponent: ({ item }: { item: EquipmentType }) => (
          <Pill
            variant={
              item.status === EquipmentStatus.ACTIVE ? "success" : "default"
            }
          >
            {toStartCaseString(item.status)}
          </Pill>
        ),
      },
      {
        label: `${localize("Equipment")} Type`,
        key: "type_id",
        isSortable: true,
        renderComponent: ({ item }: { item: EquipmentType }) =>
          item?.type?.name ?? "-",
      },
      {
        label: `${localize("Facility")} Name`,
        key: "facility_name",
        isSortable: true,
        renderComponent: ({ item }: { item: EquipmentType }) => (
          <RoutingLink to={linkToFacilities(item?.facility_id)}>
            {item?.facility?.name ?? ""}
          </RoutingLink>
        ),
      },
      {
        label: "Effective Date",
        key: "effective_date",
        renderComponent: ({ item }) => (
          <DataTable.DataRow.DateCell value={item.effective_date} />
        ),
        isSortable: true,
      },
    ],
    [isMappingLoading]
  );
  const headers = useMemo(
    () => [...fixedHeaders, ...equipmentCustomAttributeHeaders],
    [fixedHeaders, equipmentCustomAttributeHeaders]
  );
  const items = data?.data ?? [];

  const actionRow = (
    <>
      <PermissionAwareExportButton
        key="export-equipment"
        onClick={handleExport}
        isExporting={isExporting}
      />
      {isDataIngestionEnabled ? (
        <ImportDataAction
          key="import-equipment"
          modes={["single-entity", "templated-configuration"]}
          resource={{
            ...ResourceDefinitions.equipment,
            label: {
              singular: localize(ResourceDefinitions.equipment.label.singular),
              plural: localize("equipment_plural"),
            },
          }}
        />
      ) : null}
    </>
  );

  return (
    <>
      {isDataIngestionEnabled ? (
        <ImportDataActionStatusWithQueue queueKey={importDataActionQueueKey} />
      ) : null}
      <TemplatedConfigurationRunStatusWithQueue
        primaryResourceType={Resources.EQUIPMENT}
      />
      <EquipmentViewFilterArea
        viewConfigStorageKey={assetsSharedStorageKeys.viewConfigStorageKey}
      />
      <DataTablePanel
        storageKey={storageKeys.tableConfigStorageKey}
        panelProps={{
          actionRow,
        }}
        dataTableProps={{
          variant: "simplicity-first",
          isLoading,
          items,
          sorting: {
            sortBy: tableState.sortBy,
            sortDirection: tableState.sortDirection,
          },
          headers,
          onSortChange: updateTableState,
          onPaginationChange: updateTableState,
          pagination: {
            page: tableState.page,
            pageSize: tableState.pageSize,
            total: data?.total_entries,
          },
          isFluid: false,
          getItemActions: rowActionItems,
        }}
        filterComponent={
          <EquipmentTableFilterArea
            filterConfigStorageKey={storageKeys.filterConfigStorageKey}
          />
        }
      />
      <Dialog
        isOpen={!!equipmentToDelete}
        onClose={() => setEquipmentToDelete(undefined)}
        title={`Delete "${equipmentToDelete?.name}"`}
        actionRow={[
          <Button
            key="delete-dialog-action"
            onClick={deleteEquipmentOnClick}
            variant="error"
          >
            Delete
          </Button>,
        ]}
      >
        <div>
          Are you sure you want to delete this {localize("equipment")}? This
          action cannot be undone.
        </div>
      </Dialog>
      <EditEquipmentDialog
        defaultPeriod={getYearMonthFromDateRange(period) ?? ""}
        onClose={() => setAssetIdToEdit(undefined)}
        equipmentId={assetIdToEdit}
        onEdit={() => {
          refetch();
        }}
      />
    </>
  );
};
