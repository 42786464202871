import { useNavigate } from "#routers/hooks";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Dialog, useToast } from "@validereinc/common-components";
import { WorkflowAdapter } from "@validereinc/domain";
import React from "react";
import { WorkflowTemplateDetailsRoutePath } from "../../templates/[workflowTemplateId]";

export const WorkflowDetailDeleteDialog = ({
  setIsDeleteModalOpen,
  isDeleteModalOpen,
  workflowId,
  workflowTemplateId,
}: {
  setIsDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isDeleteModalOpen: boolean;
  workflowId: string;
  workflowCategoryId: string;
  workflowTemplateId: string;
}) => {
  const navigate = useNavigate();
  const { toast } = useToast();

  const queryClient = useQueryClient();
  const deleteValue = useMutation({
    mutationFn: (workflowId: string) => {
      if (!workflowId) {
        throw new Error("Missing workflow id to delete");
      }

      return WorkflowAdapter.deleteOne({ id: workflowId });
    },

    onSuccess: () => {
      setIsDeleteModalOpen(false);
      queryClient.invalidateQueries(["workflows"]);
      navigate(
        WorkflowTemplateDetailsRoutePath.toLinkParts({
          pathParams: { workflowTemplateId },
        })
      );
      toast.push({
        intent: "success",
        description: "Deleted property workflow",
      });
    },
    onError: (error) => {
      console.error(error);
      toast.push({
        intent: "error",
        description: "Unable to delete property workflow",
      });
    },
  });

  return (
    <Dialog
      isOpen={isDeleteModalOpen}
      onClose={() => setIsDeleteModalOpen(false)}
      title="Delete Workflow?"
      actionRow={[
        <Button
          key="delete-dialog-action"
          onClick={() => deleteValue.mutate(workflowId)}
          variant="error"
        >
          Delete
        </Button>,
      ]}
    >
      <div>
        Deleting the workflow will delete all associated tasks. This action
        cannot be undone.
      </div>
    </Dialog>
  );
};
