import { CalculationsProgressBanner } from "#batteries-included-components/Banners/CalculationsProgressBanner";
import { ConfigurationProgressBanner } from "#batteries-included-components/Banners/ConfigurationProgressBanner";
import { ImportDataActionStatusWithQueue } from "#batteries-included-components/Banners/ImportDataActionStatus";
import { EMISSIONS_MEASUREMENT_TYPES } from "#routes/emissions/constants";
import {
  CalculationsSummaryPanel,
  CalculationsTablePanel,
} from "#src/batteries-included-components";
import { getImportDataActionQueueKey } from "#src/batteries-included-components/Buttons/ImportDataAction/ImportDataAction.helpers";
import { CalculationsViewFilterArea } from "#src/batteries-included-components/FilterAreas/CalculationsFilterAreas";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { AssetType, MeasurementTypeType } from "@validereinc/domain";
import React from "react";

const importDataActionQueueKey = getImportDataActionQueueKey({
  resource: {
    id: "estimation_method_config",
    description: "",
    label: {
      singular: "Emissions Calculation",
      plural: "Emissions Calculations",
    },
  },
});

export const CalculatorsEmissionsTab = () => {
  const [isDataIngestionEnabled] = useIsFeatureAvailable({
    featureFlagQuery: "core:data_pipeline",
  });

  const calcsAndRecordsSharedStorageKeys = useStorageKey(
    "calculations-records-shared"
  );
  const emissionsStorageKeys = useStorageKey("calculations-emissions");
  const emissionsCalculationJobStorageKey = useStorageKey(
    "calculations-emissions-bulk-calculate"
  ).tableConfigStorageKey;
  const emissionsConfigurationJobStorageKey = useStorageKey(
    "calculations-emissions-bulk-configure"
  ).tableConfigStorageKey;

  const measurementTypeFilter = ({ quantity }: MeasurementTypeType) =>
    quantity !== "volume";

  return (
    <>
      {isDataIngestionEnabled ? (
        <ImportDataActionStatusWithQueue queueKey={importDataActionQueueKey} />
      ) : null}
      <CalculationsProgressBanner
        jobStorageKey={emissionsCalculationJobStorageKey}
      />
      <ConfigurationProgressBanner
        jobStorageKey={emissionsConfigurationJobStorageKey}
      />
      <CalculationsViewFilterArea
        viewConfigStorageKey={
          calcsAndRecordsSharedStorageKeys.viewConfigStorageKey
        }
        isPeriodDateRange
      />
      <CalculationsSummaryPanel
        assetType={AssetType.EQUIPMENT}
        defaultMeasurementTypes={EMISSIONS_MEASUREMENT_TYPES}
        measurementTypeFilter={measurementTypeFilter}
        filterConfigStorageKey={emissionsStorageKeys.filterConfigStorageKey}
        viewConfigStorageKey={
          calcsAndRecordsSharedStorageKeys.viewConfigStorageKey
        }
      />
      <CalculationsTablePanel
        assetType={AssetType.EQUIPMENT}
        defaultMeasurementTypes={EMISSIONS_MEASUREMENT_TYPES}
        measurementTypeFilter={measurementTypeFilter}
        calculationJobStorageKey={emissionsCalculationJobStorageKey}
        configurationJobStorageKey={emissionsConfigurationJobStorageKey}
        {...emissionsStorageKeys}
        viewConfigStorageKey={
          calcsAndRecordsSharedStorageKeys.viewConfigStorageKey
        }
        showEstimationMethodStatusFilter
      />
    </>
  );
};
