import { FormCategoryType } from "../schemas";
import {
  fetchAndCollate,
  GetListRequestType,
  GetListResponseType,
} from "../util";
import { restAPI } from "./api";

export const FormCategoryAdapter = {
  /**
   * Get a list of form categories
   * @see {@link https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/form_categories/list_form_category}
   */
  getList: ({
    page,
    pageSize,
    sortBy,
    sortDirection,
    filters,
  }: GetListRequestType<
    {
      related_entity_id?: string;
      measurement_type?: string;
    } & Partial<Pick<FormCategoryType, "name" | "archived">>
  >) =>
    fetchAndCollate(
      ({ page, pageSize }) => {
        const { name, archived, related_entity_id, measurement_type } =
          filters ?? {};

        return restAPI.nodeAPI.GET<GetListResponseType<FormCategoryType>>({
          endpoint: "/form_categories",
          query: {
            page,
            page_size: pageSize,
            sort_by: sortBy,
            sort_direction: sortDirection,
            ...(name ? { name } : {}),
            ...(archived ? { archived: "true" } : {}),
            ...(related_entity_id ? { related_entity_id } : {}),
            ...(measurement_type ? { measurement_type } : {}),
          },
        });
      },
      page,
      pageSize
    ),
  /**
   * Get a single form category
   * @see {@link https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/form_categories/get_form_category}
   */
  getOne: ({ id }: { id: string }) =>
    restAPI.nodeAPI.GET<FormCategoryType>({
      endpoint: `/form_categories/${id}`,
    }),
  /**
   * Update a single form category
   * @see {@link https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/form_categories/put_form_category}
   */
  updateOne: ({ id, data }: { id: string; data: Partial<FormCategoryType> }) =>
    restAPI.nodeAPI.PUT<FormCategoryType>({
      endpoint: `/form_categories/${id}`,
      body: data,
    }),
  /**
   * Create a single form category
   * @see {@link https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/form_categories/create_form_category}
   */
  createOne: ({ data }: { data: Pick<FormCategoryType, "name"> }) =>
    restAPI.nodeAPI.POST<FormCategoryType>({
      endpoint: `/form_categories`,
      body: data,
    }),
  /**
   * Delete a single form category
   * @see {@link https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/form_categories/delete_form_category}
   */
  deleteOne: ({ id }: { id: string }) =>
    restAPI.nodeAPI.DELETE<FormCategoryType>({
      endpoint: `/form_categories/${id}`,
    }),
};
