import Map from "#components/Map/Map";
import Marker from "#components/Map/Marker/Marker";
import { TemplatedConfigurationRunDrawer } from "#src/batteries-included-components/Drawers/TemplatedConfigurationRunDrawer";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import { useParams } from "#src/Routers/hooks";
import { useQuery } from "@tanstack/react-query";
import { colours, Link } from "@validereinc/common-components";
import {
  Resources,
  TemplatedConfigurationRunAdapter,
} from "@validereinc/domain";
import classNames from "classnames/bind";
import React, { useState } from "react";
import styles from "./FacilityDetailOverviewTab.module.scss";
import OverviewPanel from "./OverviewPanel";

const cx = classNames.bind(styles);

const FacilityDetailOverviewTab = ({ facilityDetail }) => {
  const { facilityId } = useParams<{ facilityId: string }>();
  const [isTemplatedConfigsAvailable] = useIsFeatureAvailable({
    featureFlagQuery: "core:templated_configurations",
    permissionQuery: "templated_configurations:read",
  });
  const [
    isTemplatedConfigurationRunDrawerOpen,
    setIsTemplatedConfigurationRunDrawerOpen,
  ] = useState(false);

  const templatedConfigurationRunDependencyGraphQuery = useQuery({
    queryKey: [
      Resources.TEMPLATED_CONFIGURATION_RUN,
      "dependencyGraph",
      facilityId,
    ],
    queryFn: () =>
      TemplatedConfigurationRunAdapter.dependencyGraph.getOne({
        id: facilityId,
      }),
    enabled: isTemplatedConfigsAvailable && !!facilityId,
  });

  const templatedConfigurationRunDependencyGraph =
    templatedConfigurationRunDependencyGraphQuery?.data?.data;

  const templatedConfigurationLink =
    templatedConfigurationRunDependencyGraph?.name ? (
      <Link
        onClick={() => setIsTemplatedConfigurationRunDrawerOpen(true)}
        label={templatedConfigurationRunDependencyGraph.name}
      />
    ) : undefined;

  const initialMapCoords: { latitude?: number; longitude?: number } = {
    latitude: facilityDetail?.latitude,
    longitude: facilityDetail?.longitude,
  };

  const shouldDisplayMap =
    facilityDetail?.latitude !== undefined &&
    facilityDetail?.longitude !== undefined;

  if (!facilityDetail) {
    return null;
  }

  return (
    <div className={cx("overviewAndMapSection")}>
      <OverviewPanel
        facilityDetail={facilityDetail}
        isDisplayMap={shouldDisplayMap}
        templatedConfigurationLink={templatedConfigurationLink}
      />
      {shouldDisplayMap ? (
        <Map
          initialViewState={initialMapCoords}
          subtractFromHeightOnResize={16}
        >
          <Marker
            latitude={initialMapCoords.latitude!}
            longitude={initialMapCoords.longitude!}
            colorVariant="navy"
            label={facilityDetail.name}
          />
        </Map>
      ) : null}
      <TemplatedConfigurationRunDrawer
        isOpen={isTemplatedConfigurationRunDrawerOpen}
        onClose={() => setIsTemplatedConfigurationRunDrawerOpen(false)}
        templatedConfigurationRunDependencyGraph={
          templatedConfigurationRunDependencyGraph
        }
        resourceId={facilityId}
      />
    </div>
  );
};

export default FacilityDetailOverviewTab;
