import { parseCustomReportConfiguration } from "#batteries-included-components/Layouts/CustomReport/CustomReportConfigurationPanel/CustomReportConfigurationPanel.helpers";
import {
  CustomReportVariantType,
  CustomReportVariants,
  getCustomReportFilters,
  useCustomReportUtils,
} from "#batteries-included-components/Layouts/CustomReport/CustomReportDetailLayout.helpers";
import { useListCalculatorResults } from "#hooks/adapters/useCalculatorResults";
import { useListRecords } from "#hooks/adapters/useRecords";
import { useMeasurementTypes } from "#src/contexts/MeasurementTypeContext";
import {
  EmptyState,
  MetricTileGrid,
  NumberDataDisplay,
  Panel,
  useFilters,
  type MetricTileGridProps,
} from "@validereinc/common-components";
import { type Unpacked } from "@validereinc/utilities";
import { Stack } from "@phosphor-icons/react";
import React from "react";

export const CustomReportSummaryPanel = ({
  configStorageKey,
  filterConfigStorageKey,
  variant,
}: {
  configStorageKey: string;
  filterConfigStorageKey: string;
  variant: CustomReportVariantType;
}) => {
  const { getTypeName, getUnitByType, getUnitName, getPrecisionByType } =
    useMeasurementTypes();
  const [config] = useFilters(configStorageKey);
  const [filters] = useFilters(filterConfigStorageKey);

  const { measurementTypeFilter } = useCustomReportUtils(variant);

  const { outputs, rows } = parseCustomReportConfiguration(config);

  const isEnabled = !!(outputs?.length && rows?.length);

  const params = { filters: getCustomReportFilters(variant, config, filters) };

  const resultQuery = useListCalculatorResults(params, {
    enabled: variant === CustomReportVariants.EMISSIONS && isEnabled,
  });

  const recordQuery = useListRecords(params, {
    enabled: variant === CustomReportVariants.VOLUMETRIC && isEnabled,
  });

  let measurements: Array<{
    value: number;
    type: string;
  }> = [];
  let isLoading = false;

  switch (variant) {
    case CustomReportVariants.EMISSIONS:
      isLoading = resultQuery.isLoading;
      measurements =
        resultQuery.data?.data.flatMap((result) =>
          Object.entries(result?.measurement ?? {}).map(([type, value]) => ({
            type,
            value,
          }))
        ) ?? [];
      break;
    case CustomReportVariants.VOLUMETRIC:
      isLoading = recordQuery.isLoading;
      measurements =
        recordQuery.data?.data.flatMap((record) =>
          record.values.map(({ measurement_type: type, value }) => ({
            type,
            value,
          }))
        ) ?? [];
      break;
  }

  const aggregatedMeasurements = measurements
    .filter(({ type }) => measurementTypeFilter(type))
    .reduce(
      (total, { type, value }) => {
        if (total[type]) {
          total[type].value += value;
        } else {
          total[type] = {
            unit: getUnitName(getUnitByType(type)),
            value,
          };
        }
        return total;
      },
      {} as Record<
        string,
        {
          value: number;
          unit: string;
        }
      >
    );

  const summaryEntries = Object.keys(aggregatedMeasurements).map<
    Unpacked<MetricTileGridProps["data"]>
  >((measurementKey) => ({
    title: measurementKey,
    value: (props) => {
      const precision = getPrecisionByType(measurementKey);

      return (
        <NumberDataDisplay
          {...props}
          value={aggregatedMeasurements[measurementKey]?.value}
          formattingOpts={precision ? { fractionDigits: precision } : {}}
        />
      );
    },
    unit: getUnitName(
      aggregatedMeasurements[measurementKey]?.unit,
      aggregatedMeasurements[measurementKey]?.value
    ),
  }));

  const metricTileData =
    summaryEntries?.length && outputs?.length
      ? summaryEntries
          .filter(({ title }) => outputs?.includes(title))
          .map((entry) => ({
            ...entry,
            title: `Total ${getTypeName(entry.title)}`,
          }))
      : [];

  const hasData = (isEnabled && isLoading) || !!metricTileData.length;

  return (
    <Panel
      title="Summary"
      loaded={!isLoading}
    >
      {hasData ? (
        <MetricTileGrid
          data={metricTileData}
          isLoading={isLoading}
        />
      ) : (
        <EmptyState
          title="There is no data to display"
          suggestion="Modify rows and outputs or filters to view the results"
          icon={<Stack />}
        />
      )}
    </Panel>
  );
};
