import { RoleListTab } from "#src/batteries-included-components";
import { RolePermissionsActivityLogFilterArea } from "#src/batteries-included-components/FilterAreas/RolePermissionsActivityLogFilterAreas.tsx/RolePermissionsActivityLogFilterAreas";
import { AccessDeniedLayout } from "#src/batteries-included-components/Layouts/Authorization/AccessDenied";
import {
  useIsFeatureAvailable,
  useIsOpsHubExperience,
} from "#src/contexts/AuthenticatedContext.helpers";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { useSearchParams } from "#src/Routers/hooks";
import { Page, Tab } from "@validereinc/common-components";
import React from "react";
import { ROLES_AND_PERMISSIONS_BREADCRUMB } from ".";
import { SETTINGS_BREADCRUMB } from "..";
import { RolePermissionsActivityLogTablePanel } from "../../../batteries-included-components/Panels/TablePanels/RolePermissionsActivityLogTablePanel";

export const RolePermissionsPageTabs = {
  RolesTab: "roles",
  RolesChangeLogTab: "roles-change-log",
} as const;
const DEFAULT_TAB_KEY = RolePermissionsPageTabs.RolesTab;

export const RolesAndPermissionsPage = () => {
  const { viewConfigStorageKey } = useStorageKey("user-groups-activity-log");
  const [searchParams, setSearchParams] = useSearchParams();
  const [isOpsExperienceEnabled] = useIsOpsHubExperience();
  const [isChangeLogAvailable] = useIsFeatureAvailable({
    featureFlagQuery: "core:activity_logs",
    permissionQuery: { $and: ["activities:read", "reports:read"] },
  });

  if (isOpsExperienceEnabled) {
    return <AccessDeniedLayout />;
  }

  return (
    <Page
      title="Roles & Permissions"
      breadcrumbs={[SETTINGS_BREADCRUMB, ROLES_AND_PERMISSIONS_BREADCRUMB]}
      activeTabKey={searchParams?.tab ?? DEFAULT_TAB_KEY}
      onActiveTabKeyChange={(tabKey: string) => {
        setSearchParams({ tab: tabKey });
      }}
    >
      <Tab
        title="Roles"
        tabKey={RolePermissionsPageTabs.RolesTab}
      >
        <RoleListTab />
      </Tab>
      {isChangeLogAvailable ? (
        <Tab
          title="Change Log"
          tabKey={RolePermissionsPageTabs.RolesChangeLogTab}
        >
          <RolePermissionsActivityLogFilterArea
            viewConfigStorageKey={viewConfigStorageKey}
          />
          <RolePermissionsActivityLogTablePanel
            viewConfigStorageKey={viewConfigStorageKey}
          />
        </Tab>
      ) : null}
    </Page>
  );
};
