import startCase from "lodash/startCase";
import {
  FacilityFilterType,
  FacilityStatus,
  FacilityType,
  FacilityWithEquipmentType,
  ReportWithDownloadV2Type,
} from "../schemas";
import {
  GetListRequestType,
  GetListResponseType,
  GetOneRequestType,
  ResourceServiceType,
  UpdateOneRequestType,
  fetchAndCollate,
  getFilterObject,
} from "../util";
import { restAPI } from "./api";
import { SavedFilterServiceType, SavedFiltersDomain } from "./composable";

type FacilityServiceType = Pick<
  ResourceServiceType<FacilityType>,
  "getOne" | "getList" | "updateOne" | "deleteOne" | "exportList"
> & {
  savedFilters: SavedFilterServiceType<FacilityFilterType>;
  statuses: {
    getList: ({
      page,
    }: {
      page?: number;
      pageSize?: number;
      filters?: { name?: string };
    }) => {
      data: Array<{ id: string; name: string }>;
    };
  };
};

export const FacilityDomain: FacilityServiceType = {
  /**
   * Get Facility API
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/facilities/get_facility
   */
  getOne: ({ id, meta }: GetOneRequestType<{ period: string }>) =>
    restAPI.nodeAPI
      .GET<FacilityWithEquipmentType>({
        endpoint: `/facilities/${id}`,
        query: meta?.period ? { period: meta.period } : {},
      })
      .then((resp) => ({
        data: resp,
      })),
  /**
   * Facility Search API
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/facilities/searchFacility
   */
  getList: ({
    filters,
    period,
    page,
    pageSize,
    sortBy,
    sortDirection,
    isExcelFile = false,
  }: GetListRequestType & { period?: string; isExcelFile?: boolean }) => {
    if (isExcelFile) {
      return restAPI.nodeAPI.POST<GetListResponseType<FacilityType>>({
        endpoint: "/facilities/search",
        query: period ? { period } : undefined,
        body: {
          page,
          page_size: pageSize,
          sort_by: sortBy,
          sort_direction: sortDirection,
          filter: getFilterObject(filters),
        },
        headers: {
          Accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "arraybuffer",
      });
    } else {
      return fetchAndCollate(
        ({ page, pageSize }) =>
          restAPI.nodeAPI.POST<GetListResponseType<FacilityType>>({
            endpoint: "/facilities/search",
            query: period ? { period } : undefined,
            body: {
              page,
              page_size: pageSize,
              sort_by: sortBy,
              sort_direction: sortDirection,
              filter: getFilterObject(filters),
            },
          }),
        page,
        pageSize
      );
    }
  },
  /**
   * Facility Export API
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/facilities/exportFacilities
   */
  exportList: ({
    filters,
    period,
    sortBy,
    sortDirection,
  }: GetListRequestType & { period?: string }) => {
    return restAPI.nodeAPI.POST<ReportWithDownloadV2Type>({
      endpoint: "/facilities/export",
      query: period ? { period } : undefined,
      body: {
        sort_by: sortBy,
        sort_direction: sortDirection,
        filter: getFilterObject(filters),
      },
    });
  },
  /**
   * Update Facility API
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/facilities/update_facility
   */
  updateOne: ({ id, data }: UpdateOneRequestType<FacilityType>) =>
    restAPI.nodeAPI
      .PUT<FacilityType>({
        endpoint: `/facilities/${id}`,
        body: data,
      })
      .then((resp) => ({ data: resp })),
  /**
   * Delete Facility API
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/facilities/delete_facility
   */
  deleteOne: ({ id }: { id: string }) =>
    restAPI.nodeAPI.DELETE({
      endpoint: `/facilities/${id}`,
    }),

  /**
   * Facility Saved Filter APIs
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/facilities/get_facilities_filter
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/facilities/post_facility_filter
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/facilities/put_facilities_filter
   * @see https://staging-carbon-hub-api.s3.us-west-2.amazonaws.com/openapi/index.html#/facilities/delete_facilities_filter
   */
  savedFilters: SavedFiltersDomain({ endpoint: "/facilities/filters" }),

  statuses: {
    getList: ({ page = 1, pageSize = 20, filters: { name } = {} }) => {
      const totalData = Object.values(FacilityStatus);

      const minIndex = (page - 1) * pageSize;
      const maxIndex = page * pageSize;

      const data = totalData.reduce(
        (total: Array<{ id: string; name: string }>, status, index) => {
          if (index > maxIndex || index < minIndex) {
            return total;
          }

          if (!name || status.toLowerCase().includes(name.toLowerCase())) {
            return [...total, { id: status, name: startCase(status) }];
          }

          return total;
        },
        []
      );

      return {
        data,
        total_entries: totalData.length,
        total_pages: Math.ceil(totalData.length / pageSize),
      };
    },
  },
};
