import {
  DEFAULT_QUERY_OPTIONS,
  UseMutationCallbackType,
  UseQueryOptionsType,
} from "#hooks/adapters/adapterUtils";
import { useClearReportsCache } from "#hooks/adapters/useReports";
import { useStickyState } from "#src/hooks/useStickyState";
import {
  CustomStorageKeyTypes,
  useCustomStorageKey,
} from "#src/hooks/useStorageKey";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useToast } from "@validereinc/common-components";
import { TemplatedReportAdapter } from "@validereinc/domain";

export const TEMPLATED_REPORT_QUERY_KEY = "templatedReports";
export const TEMPLATED_REPORT_EXPORT_KEY = "templated-report-export";

export const useGetOneTemplatedReport = (
  params: Parameters<typeof TemplatedReportAdapter.getOne>[0],
  options: UseQueryOptionsType = {}
) =>
  useQuery({
    queryKey: [TEMPLATED_REPORT_QUERY_KEY, "getOne", params] as const,
    queryFn: ({ queryKey: [_, __, params] }) =>
      TemplatedReportAdapter.getOne(params),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  });

export const useListTemplatedReports = (
  params: Parameters<typeof TemplatedReportAdapter.getList>[0],
  options: UseQueryOptionsType = {}
) =>
  useQuery({
    queryKey: [TEMPLATED_REPORT_QUERY_KEY, "getList", params] as const,
    queryFn: ({ queryKey: [_, __, params] }) =>
      TemplatedReportAdapter.getList(params),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  });

export const useGenerateTemplatedReport = ({
  onSuccess,
}: UseMutationCallbackType = {}) => {
  const { toast } = useToast();
  const { invalidate } = useClearReportsCache();

  const reportStorageKey = useCustomStorageKey(
    TEMPLATED_REPORT_EXPORT_KEY,
    CustomStorageKeyTypes.REPORT
  );
  const [_, setReportId] = useStickyState<string | undefined>(
    undefined,
    reportStorageKey
  );

  return useMutation({
    mutationFn: (
      params: Parameters<typeof TemplatedReportAdapter.generate>[0]
    ) => TemplatedReportAdapter.generate(params),
    onSuccess: (data, variables, context) => {
      setReportId(data?.id);
      invalidate();
      onSuccess?.(data, variables, context);
    },
    onError: () => {
      toast.push({
        intent: "error",
        description: "Failed to generate templated report",
      });
    },
  });
};
