import { useGetOneWorkflowTemplate } from "#src/components/hooks/adapters/useWorkflowTemplates";
import useLocalization from "#src/hooks/useLocalization";
import { ExceptionUtils } from "#src/utils/exception";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  CheckboxInput,
  DateSelectorInput,
  DateVariant,
  Dialog,
  DialogProps,
  DropdownInput,
  Form,
  TextInput,
  useForm,
  useToast,
} from "@validereinc/common-components";
import {
  AssetAdapter,
  TriggerWorkflowFormSchema,
  TriggerWorkflowFormType,
  TriggerWorkflowVariablesType,
  WorkflowAdapter,
} from "@validereinc/domain";
import { DateFormats, isFiniteNumber } from "@validereinc/utilities";
import classNames from "classnames/bind";
import { add, format } from "date-fns";
import startCase from "lodash/startCase";
import React, { useEffect, useMemo } from "react";
import styles from "./CreateWorkflowDialog.module.scss";

const cx = classNames.bind(styles);

const CREATE_WORKFLOW_DIALOG_TITLE = "Create Workflows";
const WORKFLOW_DIALOG_DESCRIPTION =
  "Workflows created manually will inherit all configurations set in the workflow's template including facility assignment and due date definition.";
const WORKFLOW_DIALOG_FORECAST_LABEL = "Forecast Workflow(s) Due Date";
const WORKFLOW_DIALOG_DUE_DATE_LABEL = "Workflow(s) Due Date";
const WORKFLOW_DUE_DATE_OPTIONS = [
  { label: "Hour(s) from creation", value: "hours" },
  { label: "Day(s) from creation", value: "days" },
  { label: "Week(s) from creation", value: "weeks" },
  { label: "Month(s) from creation", value: "months" },
];

export const CreateWorkflowDialog = ({
  onClose,
  templateId,
}: Pick<DialogProps, "onClose"> & {
  templateId: string;
}) => {
  const { toast } = useToast();
  const { localize } = useLocalization();
  const queryClient = useQueryClient();
  const form = useForm<TriggerWorkflowFormType>();

  const { data } = useGetOneWorkflowTemplate(
    {
      id: templateId,
    },
    { enabled: Boolean(templateId) }
  );

  const templateDetails = data?.data;

  const templateDueDate = templateDetails?.triggers[0]?.due_date;
  useEffect(() => {
    form.setValue("has_due_date", !!templateDueDate);
  }, [templateDueDate]);

  const manualVariables = templateDetails?.initial_variables ?? {};

  const doManualVariablesExistOnTemplate =
    Object.keys(manualVariables).length > 0;

  const mutation = useMutation({
    mutationFn: ({
      asset_ids,
      has_due_date,
      time_period,
      duration,
      initial_variables,
    }: TriggerWorkflowFormType) => {
      const workflowsToCreate = asset_ids.map((asset_id) => {
        return {
          asset_id,
          time_period,
          duration,
        };
      });

      if (!templateDetails) return;

      const sanitizedVariables = Object.fromEntries(
        Object.entries(initial_variables ?? {}).map(
          ([variableName, variableValue]) => {
            const variableDefinition =
              templateDetails.initial_variables[variableName];

            let sanitizedValue;

            if (variableDefinition.data_type === "date") {
              sanitizedValue = variableValue.from.toISOString();
            }

            if (sanitizedValue !== undefined) {
              return [
                variableName,
                {
                  value: sanitizedValue,
                  format: variableDefinition.format,
                  data_type: variableDefinition.data_type,
                },
              ];
            }
            return [variableName, ""];
          }
        )
      ) as TriggerWorkflowVariablesType;

      const promises = workflowsToCreate.map((workflow) =>
        WorkflowAdapter.trigger({
          asset_id: workflow.asset_id,
          time_period: has_due_date ? workflow.time_period : null,
          duration:
            workflow.duration &&
            isFiniteNumber(workflow.duration) &&
            has_due_date
              ? Number(workflow.duration)
              : null,
          workflow_template_id: templateId,
          ...(doManualVariablesExistOnTemplate &&
          initial_variables &&
          has_initial_variables
            ? { variables: sanitizedVariables }
            : {}),
        })
      );

      return Promise.all(promises);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["workflows", "getList"],
      });
      setTimeout(() => {
        queryClient.refetchQueries({
          queryKey: ["workflows", "getList"],
        });
      }, 4000);
      toast.push({
        intent: "success",
        description: "Successfully created workflow(s).",
      });

      onCloseCallback();
    },
    onError: (error: unknown) => {
      ExceptionUtils.reportException(error, "error", {
        hint: "Error creating workflow(s)",
      });
      toast.push({
        intent: "error",
        description: "Failed to create workflow(s).",
      });
    },
  });

  const assetTypeForDisplay = localize(startCase(templateDetails?.asset_type));

  const onCloseCallback = () => {
    form.reset();

    onClose();
  };

  const onSubmit = form.handleSubmit((formValues) => {
    mutation.mutate(formValues);
  });

  const onFetchData = async ({
    value,
    pageSize,
    page,
    searchTerm,
  }: {
    value: string[];
    pageSize: number;
    page: number;
    searchTerm?: string;
  }) => {
    const filters = {
      $and: [
        templateDetails?.asset_filter,
        {
          ...(searchTerm && {
            [templateDetails?.asset_type + ".name"]: { $like: searchTerm },
          }),
          ...(value?.length > 0 && { id: { $in: value } }),
        },
      ],
      isAlreadyFormatted: true,
    };

    const { data } = await AssetAdapter.getList({
      pageSize,
      page,
      filters,
      sortBy: "name",
      sortDirection: "asc",
    });
    return data;
  };
  const {
    has_due_date,
    duration,
    time_period: timePeriod,
    has_initial_variables,
  } = form.watch();

  const sortedManualVariables = useMemo(() => {
    const sorted = Object.entries(manualVariables);
    sorted.sort((a, b) => a[1].order - b[1].order);
    return sorted;
  }, [manualVariables]);

  const renderManualVariables = () => {
    if (!doManualVariablesExistOnTemplate || !has_initial_variables) {
      return null;
    }

    return sortedManualVariables.map(([key, manualVariable]) => {
      if (manualVariable.data_type === "date") {
        let variant: DateVariant = "day";

        if (manualVariable.format === "YYYYMM") variant = "month";
        if (manualVariable.format === "YYYY") variant = "year";

        return (
          <DateSelectorInput
            key={key}
            description={manualVariable.description}
            isRequired={manualVariable.is_required}
            name={`initial_variables.${key}`}
            label={manualVariable.display_name}
            variant={variant}
          />
        );
      }
      return null;
    });
  };

  return (
    <Dialog
      isOpen={!!templateId}
      onClose={onCloseCallback}
      title={CREATE_WORKFLOW_DIALOG_TITLE}
      actionRow={[
        <Button
          key="create-form-category-action"
          onClick={onSubmit}
          variant="primary"
        >
          {CREATE_WORKFLOW_DIALOG_TITLE}
        </Button>,
      ]}
    >
      <Form {...form}>
        <p>{WORKFLOW_DIALOG_DESCRIPTION}</p>
        <DropdownInput
          placeholder={"Select " + assetTypeForDisplay + "..."}
          label={assetTypeForDisplay}
          onFetchData={onFetchData}
          name={TriggerWorkflowFormSchema.keyof().Enum.asset_ids}
          inputId={TriggerWorkflowFormSchema.keyof().Enum.asset_ids}
          labelKey="name"
          valueKey="id"
          isRequired
          isMulti
        />
        <div className={cx("checkbox-container")}>
          <CheckboxInput
            name={TriggerWorkflowFormSchema.keyof().Enum.has_due_date}
            label="Workflow has a due date"
            isLabelShown={false}
          />
        </div>
        {has_due_date && (
          <>
            <label htmlFor="workflow-due-date">
              <strong id="workflow-due-date">
                {WORKFLOW_DIALOG_DUE_DATE_LABEL}
              </strong>
            </label>
            <div className={cx("dropdown-input-container")}>
              <TextInput
                className={cx("text-input")}
                defaultValue={templateDueDate ? templateDueDate.duration : 1}
                name={TriggerWorkflowFormSchema.keyof().Enum.duration}
                type="number"
                formatType="integer"
                isRequired
              />
              <DropdownInput
                className={cx("dropdown-input")}
                placeholder={WORKFLOW_DUE_DATE_OPTIONS[0].label}
                defaultValue={
                  templateDueDate
                    ? templateDueDate.time_period
                    : WORKFLOW_DUE_DATE_OPTIONS[0].value
                }
                options={WORKFLOW_DUE_DATE_OPTIONS}
                isSearchable={false}
                name={TriggerWorkflowFormSchema.keyof().Enum.time_period}
                valueKey="value"
                labelKey="label"
                isRequired
              />
            </div>
            <label htmlFor="workflow-forecast-due-date">
              {WORKFLOW_DIALOG_FORECAST_LABEL}
              <p id="workflow-forecast-due-date">
                <strong>
                  {format(
                    add(new Date(), {
                      [timePeriod ?? WORKFLOW_DUE_DATE_OPTIONS[0].value]:
                        duration,
                    }),
                    DateFormats.DATE_TIME
                  )}
                </strong>
              </p>
            </label>
          </>
        )}

        {doManualVariablesExistOnTemplate && (
          <div className={cx("checkbox-container")}>
            <CheckboxInput
              name={
                TriggerWorkflowFormSchema.keyof().Enum.has_initial_variables
              }
              label="Define workflow variables"
              isLabelShown={false}
            />
          </div>
        )}

        {renderManualVariables()}
      </Form>
    </Dialog>
  );
};
