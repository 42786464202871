import { z } from "zod";
import {
  AttributeDateSchema,
  AttributeSchema,
  ResourceLookup,
} from "./AttributeSchemas";
import {
  DomainModelMetaExtendedSchema,
  DomainModelMetaSchema,
  DomainModelSchema,
} from "./DomainModelSchemas";

export const TemplatedReportStatus = {
  ACTIVE: "active",
  ARCHIVED: "archived",
} as const;
export type TemplatedReportStatusType =
  (typeof TemplatedReportStatus)[keyof typeof TemplatedReportStatus];

// IMPROVE: make this a discriminated union when Zod supports nested discriminated unions better (see AttributeSchemas.ts)
export const TemplatedReportInputSchema = z
  .object({
    display_name: z.string(),
    order: z.number().default(0),
    lookup_entity_type: z
      .enum([
        ResourceLookup.ASSET_GROUP,
        ResourceLookup.DEVICE,
        ResourceLookup.EQUIPMENT,
        ResourceLookup.FACILITY,
        ResourceLookup.FLOW,
        ResourceLookup.FORM_SUBMISSION,
      ])
      .optional(),
    lookup_entity_attribute: z.string().optional(),
    date_resolution: AttributeDateSchema.shape.date_resolution,
  })
  .merge(
    AttributeSchema.pick({
      data_type: true,
      description: true,
      pick_list_values: true,
      is_required: true,
    })
  );
export type TemplatedReportInputType = z.infer<
  typeof TemplatedReportInputSchema
>;

export const TemplatedReportSchema = z
  .object({
    name: z.string(),
    display_name: z.string(),
    status: z.enum([
      TemplatedReportStatus.ACTIVE,
      TemplatedReportStatus.ARCHIVED,
    ]),
    input_schema: z.record(z.string(), TemplatedReportInputSchema),
    is_embedded: z.boolean().default(false),
    description: z.string().max(256),
    row_styling_headers: z.record(
      z
        .string()
        .min(3)
        .max(64)
        .regex(/^[A-Za-z0-9_]+$/)
    ),
    deduplication_columns: z.record(
      z
        .array(
          z
            .string()
            .min(3)
            .max(64)
            .regex(/^[A-Za-z0-9_]+$/)
        )
        .max(20)
    ),
  })
  .merge(DomainModelMetaSchema);
export type TemplatedReportType = z.infer<typeof TemplatedReportSchema>;
export type TemplatedReportFilterType = Partial<TemplatedReportType>;

export const UpdateOneTemplatedReportSchema = z.object({
  category_id: z.string().uuid(),
});
export type UpdateOneTemplatedReportType = z.infer<
  typeof UpdateOneTemplatedReportSchema
>;

export const TemplatedReportCategorySchema = z
  .object({
    name: z
      .string()
      .min(3)
      .max(64)
      .regex(/^[a-zA-Z0-9 \-_]*$/),
    company_id: z.string().uuid(),
    description: z.string().max(256),
  })
  .merge(DomainModelSchema)
  .merge(DomainModelMetaSchema)
  .merge(DomainModelMetaExtendedSchema);
export type TemplatedReportCategoryType = z.infer<
  typeof TemplatedReportCategorySchema
>;

export const CreateOneTemplatedReportCategorySchema = z
  .object({})
  .merge(
    TemplatedReportCategorySchema.pick({
      name: true,
    }).required()
  )
  .merge(
    TemplatedReportCategorySchema.pick({
      description: true,
    }).partial()
  );
export type CreateOneTemplatedReportCategoryType = z.infer<
  typeof CreateOneTemplatedReportCategorySchema
>;

export const UpdateOneTemplatedReportCategorySchema =
  TemplatedReportCategorySchema.pick({
    name: true,
    description: true,
  }).partial();
export type UpdateOneTemplatedReportCategoryType = z.infer<
  typeof UpdateOneTemplatedReportCategorySchema
>;

export const TemplatedReportWithCategorySchema = TemplatedReportSchema.merge(
  z.object({
    category: TemplatedReportCategorySchema.pick({
      id: true,
      name: true,
    }).nullable(),
  })
);
export type TemplatedReportWithCategoryType = z.infer<
  typeof TemplatedReportWithCategorySchema
>;
