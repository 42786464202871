import DataMappingService from "#components/Services/DataMappingService";
import { getBreadcrumbsObject } from "#routers/breadcrumbsHelper";
import { useNavigate } from "#routers/hooks";
import { linkToCreateDataMappingUploadData } from "#routers/links";
import {
  Form,
  FormButton,
  Page,
  Panel,
  SelectInput,
  Stepper,
  TextInput,
  useForm,
  useToast,
} from "@validereinc/common-components";
import classNames from "classnames/bind";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { DATA_MAPPING_METHOD, STEPS, TITLE } from "../DataMappingConstants";
import styles from "./BasicInfoForm.module.scss";

const cx = classNames.bind(styles);

const CURRENT_STEP = 1;

const DEFAULT_INPUTS = {
  mapping_name: "",
  mapping_strategy: "",
  mapping_entity: "",
};

const mapStateToProps = ({ profiles }) => ({
  profile: profiles.data,
});

const BasicInfoForm = ({ breadcrumbs, profile }) => {
  const form = useForm({ defaultValues: DEFAULT_INPUTS });
  const navigate = useNavigate();
  const { toast } = useToast();

  const [entities, setEntities] = useState([]);
  const [formState, setFormState] = useState("loading");

  const handleSubmitClick = (values) => {
    return DataMappingService.createDataMapping(values, profile).then(
      ({ data }) => {
        toast.push({
          intent: "success",
          description: `Successfully created ${form.getValues("mapping_name")} map`,
        });

        navigate({
          pathname: linkToCreateDataMappingUploadData(),
          query: { dataMappingId: data.data_mapping_id },
        });
      }
    );
  };

  useEffect(() => {
    setFormState("loading");

    DataMappingService.getEntities().then(({ data }) => {
      setEntities(data);
      setFormState("loaded");
    });
  }, []);

  const actionRow = (
    <Stepper
      steps={STEPS}
      activeStep={CURRENT_STEP}
    />
  );

  const footer = (
    <FormButton
      variant="primary"
      type="submit"
      isLoading={formState === "loading"}
      disabled={formState !== "loaded" || !profile.company_id}
    >
      Next
    </FormButton>
  );

  const shareProps = {
    isLoading: formState === "loading",
    isDisabled: formState !== "loaded" || form.formState.isSubmitting,
  };

  return (
    <Form
      {...form}
      onSubmit={handleSubmitClick}
    >
      <Page
        title={TITLE}
        breadcrumbs={getBreadcrumbsObject(breadcrumbs)}
        actionRow={actionRow}
        footer={footer}
      >
        <Panel title="Basic Info">
          <p>
            Assign a name to the mapping, select the update method, and select
            which entity categories to map to.
          </p>

          <div className={cx("formContainer")}>
            <TextInput
              {...shareProps}
              name="mapping_name"
              label="Mapping Name"
              type="text"
              placeholder="Name"
              showIcon={true}
              isRequired
            />
            <SelectInput
              {...shareProps}
              name="mapping_strategy"
              label="Update Method"
              labelKey="name"
              options={DATA_MAPPING_METHOD}
              isRequired
            />
            <SelectInput
              {...shareProps}
              name="mapping_entity"
              label="Entity Categories"
              labelKey="name"
              options={entities}
              isRequired
            />
          </div>
        </Panel>
      </Page>
    </Form>
  );
};

BasicInfoForm.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
  profile: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(BasicInfoForm);
