import { EXPORT_SIZE } from "#constants";
import {
  DEFAULT_INVALIDATE_OPTIONS,
  DEFAULT_QUERY_OPTIONS,
  UseMutationCallbackType,
} from "#hooks/adapters/adapterUtils";
import { RoutingLink } from "#src/batteries-included-components/RoutingLink";
import {
  useAuthenticatedContext,
  useIsFeatureAvailable,
} from "#src/contexts/AuthenticatedContext.helpers";
import { ExceptionUtils } from "#src/utils/exception";
import {
  enhanceSource,
  getSourceName,
  getSourcePath,
} from "#src/utils/recordUtils";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { HeaderType, useToast } from "@validereinc/common-components";
import {
  AggregationFunctionTypeNames,
  AssetType,
  BaseError,
  CreateOneRequestType,
  LockRecordValuesBySearchInputType,
  RecordDomain,
  RecordFilterType,
  RecordLookupType,
  RecordType,
  RecordValueConfigurationSourceType,
  RecordValueConfigurationTypeNames,
  RecordValueConfigurationTypeType,
  RecordValueStatusType,
  SavedFilterType,
  UpdateOneRequestType,
} from "@validereinc/domain";
import { dateFormatter, downloadLink } from "@validereinc/utilities";
import React, { useMemo } from "react";
import { useListEstimationMethodConfiguration } from "./useEstimationMethods";

export const RECORD_QUERY_KEY = "records";

export const useListRecords = (
  params = {},
  options: { enabled?: boolean } = {}
) =>
  useQuery({
    queryKey: [RECORD_QUERY_KEY, "getList", params],
    queryFn: ({ queryKey: [_, __, params] }) => RecordDomain.getList(params),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  });

export const useListRecordSavedFilters = (
  params = {},
  options: { enabled?: boolean } = {}
) =>
  useQuery({
    queryKey: [RECORD_QUERY_KEY, "savedFilters", "getList", params],
    queryFn: ({ queryKey: [_, __, ___, apiParams] }) =>
      RecordDomain.savedFilters.getList(apiParams),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  });

export const useExportRecords = (apiParams = {}) => {
  const { toast } = useToast();
  const {
    v2: { companyInfo },
  } = useAuthenticatedContext();

  const params = {
    ...apiParams,
    isExcelFile: true,
    page: 1,
    pageSize: EXPORT_SIZE,
  };

  const getFileName = () =>
    [companyInfo?.company?.name, "records_list", dateFormatter(new Date())]
      .filter((part) => !!part)
      .join("_");

  return useMutation({
    mutationFn: async () => {
      const recordReport = await RecordDomain.exportList(params);
      if (!recordReport?.s3_download_link) {
        throw new BaseError(`Could not fetch download URL for records export`, {
          cause: recordReport,
        });
      }
      downloadLink(recordReport.s3_download_link, getFileName());
    },
    onError: (error) => {
      ExceptionUtils.reportException(error, "error");
      toast.push({
        intent: "error",
        description: "Failed to export records list.",
      });
    },
  });
};

export const useCreateRecordSavedFilter = ({
  onSuccess,
}: UseMutationCallbackType = {}) => {
  const { toast } = useToast();
  const { invalidateSavedFilters } = useClearRecordCache();

  return useMutation({
    mutationFn: (data: CreateOneRequestType["data"]) =>
      RecordDomain.savedFilters.createOne({ data }),
    onSuccess: (data, variables, context) => {
      toast.push({
        intent: "success",
        description: "Successfully saved custom report",
      });
      invalidateSavedFilters();
      onSuccess?.(data, variables, context);
    },
    onError: () => {
      toast.push({
        intent: "error",
        description: "Failed to save custom report",
      });
    },
  });
};

export const useUpdateRecordSavedFilter = ({
  onSuccess,
}: UseMutationCallbackType = {}) => {
  const { toast } = useToast();
  const { invalidateSavedFilters } = useClearRecordCache();

  return useMutation({
    mutationFn: (
      params: UpdateOneRequestType<SavedFilterType<RecordFilterType>>
    ) => RecordDomain.savedFilters.updateOne(params),
    onSuccess: (data, variables, context) => {
      toast.push({
        intent: "success",
        description: "Successfully updated custom report",
      });
      invalidateSavedFilters();
      onSuccess?.(data, variables, context);
    },
    onError: () => {
      toast.push({
        intent: "error",
        description: "Failed to update custom report",
      });
    },
  });
};

export const useDeleteRecordSavedFilter = ({
  onSuccess,
}: UseMutationCallbackType = {}) => {
  const { toast } = useToast();
  const { invalidateSavedFilters } = useClearRecordCache();

  return useMutation({
    mutationFn: (savedFilterId: string) =>
      RecordDomain.savedFilters.deleteOne({ id: savedFilterId }),
    onSuccess: (data, variables, context) => {
      toast.push({
        intent: "success",
        description: "Successfully deleted custom report",
      });
      invalidateSavedFilters();
      onSuccess?.(data, variables, context);
    },
    onError: () => {
      toast.push({
        intent: "error",
        description: "Failed to delete custom report",
      });
    },
  });
};

export const useClearRecordCache = () => {
  const queryClient = useQueryClient();
  return {
    invalidate: () => {
      queryClient.invalidateQueries({
        queryKey: [RECORD_QUERY_KEY],
        ...DEFAULT_INVALIDATE_OPTIONS,
      });
    },
    invalidateSavedFilters: () => {
      queryClient.invalidateQueries({
        queryKey: [RECORD_QUERY_KEY, "savedFilters"],
        ...DEFAULT_INVALIDATE_OPTIONS,
      });
    },
  };
};

export const useGetOneRecord = (
  params: {
    recordId: string;
  },
  options: { enabled?: boolean } = {}
) =>
  useQuery({
    queryKey: [RECORD_QUERY_KEY, "getOne", params],
    queryFn: () => RecordDomain.getOne(params),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  });

export const useEnhanceRecordResources = (
  recordsToEnhance?: RecordType[] | RecordType,
  options: { enabled?: boolean } = { enabled: false }
) => {
  const records = useMemo(
    () =>
      recordsToEnhance
        ? Array.isArray(recordsToEnhance)
          ? recordsToEnhance
          : [recordsToEnhance]
        : [],
    [recordsToEnhance]
  );

  // Fetching estimations:
  const yearMonths = Array.from(
    new Set(records.map((item) => item?.year_month))
  );
  const ids = Array.from(
    new Set(
      records
        .map((record) =>
          record?.values
            .filter(
              ({ configuration: { configuration_type } }) =>
                configuration_type ===
                RecordValueConfigurationTypeType.CALCULATION_RESULT
            )
            .flatMap(({ configuration: { sources } }) => sources)
        )
        .flat(1)
        .map(
          (item?: RecordValueConfigurationSourceType) =>
            item?.estimation_method_id
        )
    )
  );
  const estimationMethodParams = {
    filters: {
      year_month: yearMonths,
      id: ids,
    },
    pageSize: Math.max(records.length, 10),
  };

  const estimationMethodQueryEnabled =
    estimationMethodParams.filters.id.length > 0 && options.enabled;

  const estimationMethodQuery = useListEstimationMethodConfiguration(
    estimationMethodParams,
    {
      enabled: estimationMethodQueryEnabled,
      select: ({ data }) => data,
    }
  );

  const recordsWithSources = records.map((record) =>
    record
      ? {
          ...record,
          values: record?.values?.map((value) => ({
            ...value,
            configuration: {
              ...value.configuration,
              sources: value.configuration.sources?.map(
                (source: RecordValueConfigurationSourceType) =>
                  enhanceSource(source, estimationMethodQuery.data ?? [])
              ),
            },
          })),
        }
      : undefined
  );

  const recordOrRecords = Array.isArray(recordsToEnhance)
    ? recordsWithSources
    : recordsWithSources[0];

  const isLoading =
    estimationMethodQueryEnabled && estimationMethodQuery.isLoading;

  return { recordOrRecords, isLoading };
};

export const useRecordMeasurementTypeSpecificTableHeader = (
  measurementType?: string
): Array<HeaderType<RecordType>> => {
  if (!measurementType) return [];

  const findValue = (item: RecordType) =>
    item?.values?.find(
      ({ measurement_type }: { measurement_type: string }) =>
        measurement_type === measurementType
    );

  return [
    {
      key: "source_type",
      label: "Source Type",
      isSortable: true,
      renderComponent: ({ item }: { item: RecordType }) =>
        findValue(item)
          ? RecordValueConfigurationTypeNames[
              findValue(item)?.configuration.configuration_type ?? ""
            ]
          : "-",
    },
    {
      key: "source_source",
      label: "Source",
      renderComponent: ({ item }: { item: RecordType }) => {
        const find = findValue(item);

        const configurationType = find?.configuration?.configuration_type ?? "";
        const sources = find?.configuration?.sources ?? [];

        if (!find || !sources.length) return "-";
        if (sources.length > 1) return "Many";

        const link = {
          pathname: getSourcePath(configurationType, sources[0]) ?? "",
          query:
            sources[0]?.estimation_method_entity_type === AssetType.ASSET_GROUP
              ? {
                  tab: "calculations",
                  period: sources[0].year_month ?? "",
                }
              : ({} as Record<string, string>),
        };

        return (
          <RoutingLink
            to={{
              pathname: link.pathname,
              search: `?${new URLSearchParams(link.query).toString()}`,
            }}
          >
            {getSourceName(configurationType, sources[0])}
          </RoutingLink>
        );
      },
    },
    {
      key: "source_aggregate_function",
      label: "Aggregate Function",
      renderComponent: ({ item }: { item: RecordType }) =>
        AggregationFunctionTypeNames[
          findValue(item)?.configuration?.aggregate_function ?? ""
        ] ?? "-",
    },
  ];
};

export const useUpdateStatusOfValuesOfRecord = ({
  onSuccess,
}: UseMutationCallbackType = {}) => {
  const { toast } = useToast();
  const { invalidate } = useClearRecordCache();

  return useMutation({
    mutationFn: (
      data: RecordLookupType & {
        measurementTypes: string[];
        status: RecordValueStatusType;
      }
    ) =>
      RecordDomain.updateStatusOfValues({
        recordId: data.recordId,
        measurement_types: data.measurementTypes,
        status: data.status,
      }),
    onSuccess: (data, variables, context) => {
      toast.push({
        intent: "success",
        description: `Successfully ${variables.status} ${variables.measurementTypes.length} record value(s)`,
      });
      invalidate();
      onSuccess?.(data, variables, context);
    },
    onError: () => {
      toast.push({
        intent: "error",
        description: `Failed to lock/unlock record value(s)`,
      });
    },
  });
};

export const useUpdateStatusBySearch = ({
  onSuccess,
}: UseMutationCallbackType = {}) => {
  const { toast } = useToast();
  const { invalidate } = useClearRecordCache();

  return useMutation({
    mutationFn: (data: LockRecordValuesBySearchInputType) =>
      RecordDomain.updateStatusBySearch({
        filter: data.filter,
        status: data.status,
      }),
    onSuccess: (data, variables, context) => {
      toast.push({
        intent: "success",
        description: `Successfully ${variables.status} ${data.affected} record value(s)`,
      });
      invalidate();
      onSuccess?.(data, variables, context);
    },
    onError: () => {
      toast.push({
        intent: "error",
        description: `Failed to lock/unlock record value(s)`,
      });
    },
  });
};

export const useHasRecordLockingPermission = () => {
  const [hasLockingPermission] = useIsFeatureAvailable({
    featureFlagQuery: {
      $or: [
        {
          $and: ["core:flows", "core:records"],
        },
        {
          $and: ["core:facilities", "core:records"],
        },
        {
          $and: ["core:equipment", "core:records"],
        },
      ],
    },
    permissionQuery: "records:lock",
  });

  return hasLockingPermission;
};
